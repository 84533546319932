import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "../../../../../../localization/translate";
import { useGetPollResultsQuery } from "../../../../services/events.api";
import { Recommendations } from "../../Recommendations";
import styles from "./styles.module.css";
import { Metrics } from "../../../../../../utils/metrics";

export const PollCompleted = ({ pollId }) => {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const { data: results } = useGetPollResultsQuery(pollId);

	useEffect(() => {
		if (results) {
			dispatch({
				type: "eventsApi/invalidateTags",
				payload: ["Polls"],
			});

			dispatch({
				type: "eventsApi/invalidateTags",
				payload: ["PollId"],
			});

			Metrics.gtmEvent("complete_poll", { pollId });
		}
	}, [results]);

	return (
		<div className={styles.poll_completed}>
			<div className={styles.header}>
				<div className={styles.title}>{translate("polls.completed.title")}</div>
			</div>

			<div className={styles.subtitle}>
				<span>{translate("polls.completed.subtitle")}</span>
			</div>

			{results?.text && (
				<div
					className={styles.recommendation_text}
					dangerouslySetInnerHTML={{ __html: results.text }}
				/>
			)}

			{results?.recommendations?.length > 0 && (
				<div className={styles.recommendations}>
					<div className={styles.recommendations_title}>
						<span>{translate("polls.completed.informationTitle")}</span>
					</div>

					<div>
						<Recommendations
							isCompleted
							recommendations={results.recommendations}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
