import { ContentModal } from "../../../../components/custom-modal/content.modal";
import Button from "../../../../components/button/button";
import { useTranslate } from "../../../../localization/translate";
import { useDispatch, useSelector } from "react-redux";
import {
	selectCreateModalOpened,
	setCreateModalState,
} from "../../services/profile.slice";
import Input from "../../../../components/input";
import styles from "./personal-data.create.module.css";
import classNames from "classnames";
import { useEffect, useState } from "react";
import moment from "moment";
import Checkbox from "../../../../components/input/checkbox/Checkbox";
import {
	useCreateMedCardMutation,
	useGetProfileQuery,
} from "../../services/profile.api";
import Loader from "../../../../components/loader";
import { getErrorMessageFromError } from "../../../../app/error-handler.api";
import Agreements from "../../../agreements/agreements";
import { CODES_ENUM } from "../../../agreements/constants";

export default function PersonalDataCreate() {
	//Компонент пустышка, нужен исключительно для того, чтобы сбросить все к исходному состоянию, при закрытии модалки
	const isOpen = useSelector(selectCreateModalOpened);

	return isOpen ? <PersonalDataCreateModal isOpen={isOpen} /> : <></>;
}

export function PersonalDataCreateModal({
	isOpen,
	isPublic = false,
	onCreateProfile = null,
	onClose,
}) {
	const [type, setType] = useState(null);
	const translate = useTranslate();
	const dispatch = useDispatch();
	const { data: profile } = useGetProfileQuery(null, { skip: isPublic });

	const [form, setForm] = useState({
		lastName: {
			required: true,
			value: "",
			type: "text",
			apiName: "last_name",
		},
		firstName: {
			required: true,
			type: "text",
			value: "",
			apiName: "first_name",
		},
		middleName: {
			value: "",
			type: "text",
			apiName: "middle_name",
		},
		dateOfBirth: {
			required: true,
			value: "",
			apiName: "date_of_birth",
			type: "date",
			validateFinish: (val) => {
				if (
					moment(val, "DD.MM.YYYY", true).isValid() &&
					moment(val, "DD.MM.YYYY").isBefore(moment())
				) {
					return true;
				}

				return false;
			},
			errorMsg: "profile.personalData.invalidDateOfBirth",
		},
		email: {
			required: true,
			regexp: /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/i,
			errorMsg: "profile.personalData.invalidEmail",
			value: profile?.email || "",
			type: "text",
			apiName: "email",
		},
	});

	const [errors, setErrors] = useState({});
	const handleChange = (val, key) => {
		form[key].value = val;
		setForm(Object.assign({}, form));
		clearError(key);
	};

	const formIsValid = () => {
		const errors = {}; // При каждой отправке все ошибки сбросяться

		Object.keys(form).forEach((key) => {
			if (form[key].required && form[key].value.length === 0) {
				errors[key] = translate("app.forms.required", true);
			}

			if (form[key].regexp && form[key].value.length > 0) {
				const regExp = new RegExp(form[key].regexp);
				if (!regExp.test(form[key].value)) {
					errors[key] = translate(form[key].errorMsg, true);
				}
			}

			if (
				form[key].validateFinish &&
				form[key].value.length > 0 &&
				!form[key].validateFinish(form[key].value)
			) {
				errors[key] = translate(form[key].errorMsg, true);
			}
		});

		if (!termsOfUseAcception) {
			errors.termsOfUseAcception = translate(
				"profile.personalData.checkboxError",
				true
			);
		}
		if (!privacyPolicyAcception) {
			errors.privacyPolicyAcception = translate(
				"profile.personalData.checkboxError",
				true
			);
		}

		if (Object.keys(errors).length === 0) {
			return true;
		} else {
			setErrors(errors);
			return false;
		}
	};
	const [submitForm, { data, isLoading, error }] = useCreateMedCardMutation();
	const submit = () => {
		setErrors({});
		if (formIsValid() && !isLoading) {
			submitForm({
				lastName: form.lastName.value,
				firstName: form.firstName.value,
				dateOfBirth: moment(form.dateOfBirth.value, "DD.MM.YYYY").format(
					"YYYY-MM-DD"
				),
				middleName: form.middleName.value.length ? form.middleName.value : null,
				email: form.email.value,
				termsOfUseAcception,
				privacyPolicyAcception,
			});
		}
	};
	const clearError = (key) => {
		if (errors[key]) {
			delete errors[key];
			setErrors(errors);
		}
	};

	const handleAgreement = (type) => {
		if ([CODES_ENUM.USER_AGREEMENT, CODES_ENUM.PD_POLICY].includes(type)) {
			setTermsOfUseAcception(true);
		} else if ([CODES_ENUM.PD_CONDITIONS].includes(type)) {
			setPrivacyPolicyAcception(true);
		}
		setType(null);
	};

	const [termsOfUseAcception, setTermsOfUseAcception] = useState(false);
	const [privacyPolicyAcception, setPrivacyPolicyAcception] = useState(false);
	const setApiErrors = (clarifications) => {
		if (clarifications && clarifications.length > 0) {
			let _errors = {};

			clarifications.forEach((key) => {
				if (key.target === "terms_of_use_acception") {
					_errors.termsOfUseAcception = translate(
						"profile.personalData.checkboxError",
						true
					);
				} else if (key.target === "privacy_policy_acception") {
					_errors.privacyPolicyAcception = translate(
						"profile.personalData.checkboxError",
						true
					);
				} else {
					const name = Object.keys(form).find((field) => field === key.target);
					_errors[name] = key.message;
				}
			});

			setErrors(Object.assign({}, _errors));
		}
	};

	useEffect(() => {
		if (error) {
			const clarifications = getErrorMessageFromError({
				fromClarifications: true,
				error,
			});
			setApiErrors(clarifications);
		}
	}, [error]);

	useEffect(() => {
		if (data) {
			const clarifications = getErrorMessageFromError({
				fromClarifications: true,
				error: { data: data },
			});
			setApiErrors(clarifications);
			if (!clarifications) {
				dispatch(setCreateModalState(false));
			}
		}
	}, [data]);

	useEffect(() => {
		if (data) onCreateProfile?.(data.data);
	}, [data]);

	return (
		<ContentModal
			isOpen={isOpen}
			closeModal={() => {
				dispatch(setCreateModalState(false));
				onClose?.();
			}}
			styles={{ overlay: { zIndex: 135 } }}
		>
			<div className={styles.create_pd}>
				<div className={classNames(styles.title, styles.input_row)}>
					{translate("profile.personalData.title_2")}
				</div>
				<div className={classNames(styles.helper_text, styles.input_row)}>
					{translate("profile.personalData.helperText")}
				</div>
				{Object.keys(form).map((field) => (
					<div className={styles.input_row} key={field}>
						<Input
							type={form[field].type}
							placeholder={translate(`profile.personalData.${field}`, true)}
							value={form[field].value}
							name={field}
							errorMsg={errors[field]}
							onChange={(val) => handleChange(val, field)}
							containerClass={errors[field] ? "invalid-value-empty-value" : ""}
							maskPlaceholder={translate(
								"profile.personalData.dateOfBirthPlaceholder",
								true
							)}
							onKeyUp={(e) => {
								e.preventDefault();
								if (e.key === "Enter") {
									submit();
								}
							}}
							onKeyDown={form[field]?.onKeyDown}
						/>
					</div>
				))}
				<div className={classNames(styles.input_row, styles.checkbox_group)}>
					<Checkbox
						checked={termsOfUseAcception}
						onChange={() => {
							clearError("termsOfUseAcception");
							setTermsOfUseAcception(!termsOfUseAcception);
						}}
						label={<TermsOfUseAcception setType={setType} />}
						error={errors.termsOfUseAcception}
						className={styles.checkbox}
						labelclass={styles.checkbox_label}
					/>
				</div>
				<div className={classNames(styles.input_row, styles.checkbox_group)}>
					<Checkbox
						className={styles.checkbox}
						checked={privacyPolicyAcception}
						error={errors.privacyPolicyAcception}
						onChange={() => {
							clearError("privacyPolicyAcception");
							setPrivacyPolicyAcception(!privacyPolicyAcception);
						}}
						label={<PrivacyPolicyAcception setType={setType} />}
						labelclass={styles.checkbox_label}
					/>
				</div>
				<Button onClick={submit} disabled={isLoading}>
					{isLoading ? (
						<Loader
							containerStyle={{ margin: "-8px 0" }}
							width={30}
							height={30}
						/>
					) : (
						translate("app.forms.save", true)
					)}
				</Button>
				<Agreements
					isOpen={!!type}
					type={type}
					onClose={() => setType(null)}
					onAgree={handleAgreement}
				/>
			</div>
		</ContentModal>
	);
}

const TermsOfUseAcception = ({ setType }) => {
	const translate = useTranslate();

	return (
		<>
			{translate("profile.personalData.termsOfUseAcception.iamAccept")}
			<span
				className={styles.link}
				onClick={() => setType(CODES_ENUM.USER_AGREEMENT)}
			>
				{translate("profile.personalData.termsOfUseAcception.termsOfUse", true)}
			</span>
			{translate("profile.personalData.termsOfUseAcception.andAgainAgree")}
			<span
				className={styles.link}
				onClick={() => setType(CODES_ENUM.PD_POLICY)}
			>
				{translate(
					"profile.personalData.termsOfUseAcception.PersonalDataProcessingPolicy",
					true
				)}
			</span>
		</>
	);
};

const PrivacyPolicyAcception = ({ setType }) => {
	const translate = useTranslate();

	return (
		<>
			{translate("profile.personalData.privacyPolicyAcception.iAmAgree")}
			<span
				className={styles.link}
				onClick={() => setType(CODES_ENUM.PD_CONDITIONS)}
			>
				{translate("profile.personalData.privacyPolicyAcception.termsOfPDA")}
			</span>
			{translate("profile.personalData.privacyPolicyAcception.according")}
			<span
				className={styles.link}
				onClick={() => setType(CODES_ENUM.PD_CONDITIONS)}
			>
				{translate(
					"profile.personalData.privacyPolicyAcception.PolicyRegardingPDA",
					true
				)}
			</span>
		</>
	);
};
