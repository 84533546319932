import styles from "./speciality-schedule.module.css";
import { NavLink, useLocation, useParams } from "react-router-dom";
import {
	useGetSpecialityQuery,
	useGetSpecialityScheduleMutation,
} from "../../services/appointment.api";
import { useEffect } from "react";
import { useTranslate } from "../../../../localization/translate";
import Schedule from "../schedule/schedule";
import { Metrics } from "../../../../utils/metrics";
import { useDispatch, useSelector } from "react-redux";
import {
	selectDate,
	selectDateRange,
	setDate,
} from "../../services/appointment.slice";
import moment from "moment";

export default function SpecialitySchedule({
	isPublic = false,
	isWidget = false,
}) {
	const params = useParams();
	const dispatch = useDispatch();
	const location = useLocation();
	const activeDate = useSelector(selectDate);
	const dateRange = useSelector(selectDateRange);

	const [getSpecialitySchedule, { data, isLoading, error }] =
		useGetSpecialityScheduleMutation();
	const { data: specialityList } = useGetSpecialityQuery();
	const currentSpeciality = (specialityList ? specialityList : []).find(
		(speciality) => speciality.id == params.specialityId
	);
	const translate = useTranslate();

	useEffect(() => {
		if (
			!isLoading &&
			!error &&
			!!dateRange.from &&
			!!dateRange.to &&
			moment(dateRange.to).isAfter(dateRange.from, "d")
		) {
			getSpecialitySchedule({
				specialityId: +params.specialityId,
				dateFrom: dateRange.from,
				dateTo: dateRange.to,
			});
		}
	}, [params, dateRange]);


	const onSetDate = (date) => {
		dispatch(setDate(date));

		Metrics.gtmEvent("view_schedule_speciality", {
			specialityId: params.specialityId,
			date,
		});
	};

	return (
		<Schedule
			isPublic={isPublic}
			breadcrumbs={
				<div className={styles.breadcrumbs}>
					<span className={styles.root}>
						<NavLink
							to={
								`/${
									isPublic || isWidget ? "schedule" : "appointment"
								}/speciality/` + location.search
							}
						>
							{translate("appointment.specialities.specialities")}
						</NavLink>
						&nbsp;/&nbsp;
					</span>
					<span className={styles.current}>
						{currentSpeciality && currentSpeciality.name}
					</span>
				</div>
			}
			data={data}
			isLoading={isLoading}
			activeDate={activeDate}
			setDate={onSetDate}
		/>
	);
}
