import { useEffect, useMemo, useRef } from "react";
import QRCode from "qrcode";
import { useTranslate } from "../../../../../../localization/translate";
import Modal from "../../../../../../components/custom-modal/modal-adapter";
import { defaultOptions } from "../../constants";
import styles from "./style.module.css";

export default function QrModal({ isOpen, onClose, uid = {}, options = {} }) {
	const translate = useTranslate();
	const ref = useRef(null);

	const extraOptions = useMemo(
		() => ({ ...defaultOptions, ...options }),
		[options]
	);

	useEffect(() => {
		QRCode.toCanvas(uid, extraOptions)
			.then((canvas) => ref.current.prepend(canvas))
			.catch(console.warn);
	}, []);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title={translate("profile.qr.modalTitle")}
		>
			<div ref={ref} className={styles.content}>
				<div className={styles.title}>
					{translate("profile.qr.qrModalSubtitle")}
				</div>

				<div className={styles.description}>
					{translate("profile.qr.qrModalDescription")}
				</div>
			</div>
		</Modal>
	);
}
