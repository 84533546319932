import { useState, useEffect } from "react";

const SLEEP_MS = 500;

const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const useCalculateApplication = (basePrice, selectedOptions) => {
	const [amount, setAmount] = useState(null);
	const [isCalculating, setIsCalculating] = useState(false);

	useEffect(() => {
		const calculate = async () => {
			setIsCalculating(true);

			const calcedAmount =
				selectedOptions &&
				basePrice &&
				Object.values(selectedOptions).reduce((acc, curr) => {
					if (curr.impact === "surcharge") return acc + curr.value;
					else if (curr.impact === "discount") return acc - curr.value;
					else return acc;
				}, basePrice);

			await sleep(SLEEP_MS);

			setAmount(Math.round(calcedAmount));
			setIsCalculating(false);
		};

		calculate();
	}, [basePrice, selectedOptions]);

	return { amount, isCalculating };
};
