import { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";
import { useTranslate } from "../../../../localization/translate";
import ListItemRow from "../../../../components/list-item-row";
import Loader from "../../../../components/loader";
import { useGetBonusAccountByIdQuery } from "../../services/profile.api";
import { Empty } from "./components/PromoCodes";
import InfoBlockWrapper from "./components/InfoBlockWrapper";
import OperationModal from "./components/OperationModal";
import {
	convertDate,
	moscowTimeZone,
} from "../../../../utils/dates/timezone-converter";
import styles from "./bonus-account.module.css";
import { Metrics } from "../../../../utils/metrics";

const formatTitle = ({ type, amount }) =>
	`${type === "accrual" ? "+" : "-"} ${amount}`;

const formatDate = (date) => {
	const mask = moment(date).isBefore(new Date(), "year")
		? "DD MMMM YYYY, HH:mm"
		: "DD MMMM, HH:mm";

	return moment(date).format(mask);
};

const BonusAccount = () => {
	const [currentOperation, setCurrentOperation] = useState(null);
	const { id } = useParams();
	const translate = useTranslate();

	const { data: bonusAccount, isLoading } = useGetBonusAccountByIdQuery(
		Number(id)
	);

	const operations = useMemo(
		() =>
			bonusAccount &&
			bonusAccount.operations.map((item) => ({
				title: formatTitle(item),
				description: `${formatDate(item.date)}${
					moscowTimeZone() ? "" : translate("app.byMskTime", true)
				}`,
				...item,
			})),
		[bonusAccount]
	);

	useEffect(() => {
		if (bonusAccount)
			Metrics.gtmEvent("view_discount_item", {
				discount_type: "bonus-account",
			});
	}, [bonusAccount]);

	return (
		<div className={styles.bonus_account}>
			{isLoading && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}

			{bonusAccount && (
				<>
					<div className={classNames("h3", styles.title)}>
						{translate("profile.discounts.bonusAccount.title")}
					</div>

					<div className={styles.content_block}>
						<div className={styles.content_title}>
							{translate("profile.discounts.bonusAccount.myBalance")}
						</div>

						<ListItemRow
							title={`${bonusAccount.balance} ${translate(
								"profile.discounts.bonuses",
								true
							)}`}
							showArrow={false}
						/>
					</div>

					<div className={styles.content_block}>
						<div className={styles.content_title}>
							{translate("profile.discounts.bonusAccount.accrualHistory")}
						</div>

						{operations?.length ? (
							<>
								{operations.map((operation) => (
									<ListItemRow
										key={operation.date}
										title={operation.title}
										description={operation.description}
										onClick={() => setCurrentOperation(operation)}
									/>
								))}
							</>
						) : (
							<Empty
								title={translate("profile.discounts.bonusAccount.hasNoHistory")}
							/>
						)}
					</div>

					<div className={styles.content_block}>
						<InfoBlockWrapper
							title={translate("profile.discounts.bonusAccount.whatIt")}
							list={translate("profile.discounts.bonusAccount.infoList")}
							learMoreLink="https://www.medicina.ru/patsientam/diskontnye-programmy/"
						/>
					</div>
				</>
			)}

			<OperationModal
				isOpen={Boolean(currentOperation)}
				operation={currentOperation}
				closeModal={() => setCurrentOperation(null)}
			/>
		</div>
	);
};

export default BonusAccount;
