import moment from "moment";

export const mapIssueHistory = (collection) =>
	collection.reduce((acc, curr) => {
		const date = moment(curr.creationDate).format("DD MMMM YYYY");

		if (acc[date]) {
			acc[date].push(curr);
			return acc;
		}

		return {
			...acc,
			[date]: [curr],
		};
	}, {});
