import moment from "moment";
import { useTranslate } from "../../../../../../localization/translate";
import { ContentModal } from "../../../../../../components/custom-modal/content.modal";
import { moscowTimeZone } from "../../../../../../utils/dates/timezone-converter";
import { convertDate } from "../../../../../../utils/dates/timezone-converter";
import styles from "./style.module.css";

const modalStyles = {
	content: {
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
		justifyContent: "space-between",
	},
};

const OperationModal = ({ isOpen, operation, closeModal }) => {
	const translate = useTranslate();

	if (!operation) return null;

	return (
		<ContentModal
			isOpen={isOpen}
			closeModal={closeModal}
			className={styles.operation_modal}
			styles={modalStyles}
		>
			<div className={styles.content}>
				<div className={styles.title}>
					{translate("profile.discounts.bonusAccount.accrualHistory")}
				</div>

				<div className={styles.amount}>
					{operation.title}&nbsp;
					{translate("app.currencySign")}
				</div>

				<div className={styles.operation_type}>
					{translate(`profile.discounts.bonusAccount.${operation.type}`)}
				</div>

				<div className={styles.datetime}>
					<div className={styles.label}>
						{translate("profile.discounts.bonusAccount.date")}
					</div>

					<div className={styles.value}>
						{moment(operation.date).format("DD MMMM YYYY")}
					</div>
				</div>

				<div className={styles.datetime}>
					<div className={styles.label}>
						{translate("profile.discounts.bonusAccount.time")}
					</div>

					<div className={styles.value}>
						{moment(operation.date).format("HH:mm")}
						{moscowTimeZone() ? "" : translate("app.mskTime")}
					</div>
				</div>
			</div>
		</ContentModal>
	);
};

export default OperationModal;
