import React, { useEffect, useMemo } from "react";
import { useTranslate } from "../../../../../../localization/translate";
import { QuestionResult } from "../QuestionResult/QuestionResult";
import { associateQuestions, getParentQuestion, getUserAnswer } from "../utils";
import styles from "./styles.module.css";
import Button from "../../../../../../components/button/button";
import { Metrics } from "../../../../../../utils/metrics";

export const PollResults = ({
	poll,
	pollId,
	setQuestion = () => {},
	setActiveAnswer = () => {},
	onSaveResults = () => {},
}) => {
	const translate = useTranslate();

	const associatedQuestions = useMemo(
		() => associateQuestions(poll.questions, poll.answersUser),
		[poll]
	);

	const handleChangeQuestion = (question) => {
		setQuestion(question);
		if (question.isAdditional)
			setActiveAnswer(
				getUserAnswer(
					getParentQuestion(poll.questions, question),
					poll.answersUser
				)
			);
	};

	useEffect(() => setActiveAnswer(null), []);

	useEffect(() => {
		if (associatedQuestions?.every(({ answer }) => answer?.id))
			Metrics.gtmEvent("view_poll", { pollId });
	}, [pollId]);

	return (
		<div className={styles.poll_results}>
			<div className={styles.header}>
				<div className={styles.chip}>
					<span>
						{translate("polls.proactiveSurveillance.additionalTitle")}
					</span>
				</div>

				<div className={styles.label}>
					{translate("polls.selected.startPollTabBtn")}
				</div>
			</div>

			<div className={styles.title}>
				<span>{translate("polls.results.title")}</span>
			</div>

			<div className={styles.subtitle}>
				<span>{translate("polls.results.subtitle")}</span>
			</div>

			<div className={styles.questions}>
				{associatedQuestions.map((question) => (
					<QuestionResult
						key={`${question.id}-${question.isAdditional}`}
						question={question.title}
						answer={question.answer}
						additional={question.additional}
						onUpdate={() => handleChangeQuestion(question)}
					/>
				))}
			</div>

			<div className={styles.actions}>
				<Button className={styles.send_btn} onClick={onSaveResults}>
					{translate("polls.results.sendToDoctor")}
				</Button>
			</div>
		</div>
	);
};
