import classNames from "classnames";
import arrow from "../../icons/arrow_down_24x24.svg";
import styles from "./style.module.css";

const ListItemRow = ({
	title,
	description,
	theme = "default",
	size = "default",
	showDivider = true,
	showArrow = true,
	renderArrow,
	disabled = false,
	error = false,
	nonInteract = false,
	onClick = () => ({}),
}) => (
	<>
		<div
			className={classNames(styles.list_item, {
				[styles.hide_divider]: !showDivider,
				[styles.has_error]: !!error,
				[styles.disabled]: disabled,
				[styles.non_interact]: nonInteract,
				[styles[`theme_${theme}`]]: theme,
				[styles[`size_${size}`]]: size,
			})}
			disabled={disabled}
			onClick={() => !disabled && onClick()}
		>
			<div className={styles.content}>
				<div className={styles.title}>{title}</div>

				{description && <div className={styles.description}>{description}</div>}
			</div>

			{showArrow && (
				<div className={styles.arrow_wrapper}>
					{renderArrow ? (
						renderArrow()
					) : (
						<div className={styles.arrow}>
							<img alt="arrow" src={arrow} />
						</div>
					)}
				</div>
			)}
		</div>
		{error && <div className={styles.error}>{error}</div>}
	</>
);

export default ListItemRow;
