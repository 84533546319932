import styles from "./short_code.module.css";
import Promo from "../promo";
import SetupSC from "./setup";
import ShortCodeLogin from "../login";
import { useSelector } from "react-redux";
import {
	selectBiometryAllowed,
	selectExpired,
} from "../../services/login.slice";
import { isAndroidApp, isBiometryAvailable, isIOSApp } from "../../../../utils";
import BiometryRecovery from "../biometry/recovery";

export default function ShortCode() {
	const expired = useSelector(selectExpired);
	const isBiometryAllowed = useSelector(selectBiometryAllowed);
	const isMobileApp = isAndroidApp || isIOSApp;

	if (isMobileApp && isBiometryAvailable && isBiometryAllowed && expired) {
		return <BiometryRecovery />;
	}

	return (
		<div className={styles.container}>
			<Promo />
			<div className={styles.rightpad}>
				{expired ? <ShortCodeLogin /> : <SetupSC />}
			</div>
		</div>
	);
}
