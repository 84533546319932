import CreditWaiting from "../components/credit-waiting/credit-waiting";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useCoffeeStatusMutation, useGetProfileQuery} from "../../../services/profile.api";
import {Metrics} from "../../../../../utils/metrics";

export default function CoffeeStatus () {
  const {transaction} = useParams();
  const [
    requestStatusCoffee,
    {
      data: statusData,
      error: statusError,
      isUninitialized
    }
  ] = useCoffeeStatusMutation();
  const {data: profile} = useGetProfileQuery();
  const navigate = useNavigate();
  const [timeStart, setTime] = useState();

  useEffect(() => {
    const gtmMapStatus = {
      "Failed": "error",
      "In Proccess": "timeout",
      "Success": "success"
    };

    if (statusData) {
      let gtmResultStatus = gtmMapStatus[statusData.status];
      if (statusData.status === "Failed") {
        navigate("/coffee/failure/result");
      }
      if (statusData.status === "In Proccess" && transaction) {//Если статус в процессе, то выполним запрос еще раз через 3 сек
        if ( (new Date()).getTime() - timeStart >= 120000) {
          //Если ожидание результата более 2х минут
          navigate("/coffee/failure/result");
          gtmResultStatus = "error";
        } else {
          setTimeout(() => requestStatusCoffee({transactionId: transaction}), 3000);
        }
      }
      if (statusData.status === "Success") {
        navigate("/coffee/success/result");
      }
      Metrics.gtmEvent("get_vending_drink" , {
        card: profile?.medCardNumber,
        status: gtmResultStatus
      });
      if (window.isDevMode) {
        console.log("get_vending_drink" , {
          card: profile?.medCardNumber,
          status: gtmResultStatus
        });
      }
    }
  }, [statusData]);

  useEffect(() => {
    if ( statusError) {
      navigate("/coffee/failure/result");
    }
  }, [ statusError]);

  useEffect(() => {
    if (isUninitialized && transaction) {//если id транзакзакции и еще было первого запроса
      setTime((new Date()).getTime());
      requestStatusCoffee({transactionId: transaction});
    }
  }, [isUninitialized, transaction]);

  return <CreditWaiting/>
}