import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isOpen: false,
	PaymentModal: {
		type: null,
		subtype: null,
		purposeId: null,
		scheduleIdMis: null,
		medCardNumber: null,
	},
	PaymentTarget: null,
	InitInvoice: null,
	InitAmount: null,
	InitDiscount: null,
	InitMedCardNumber: null,
	InitDate: null,
};

export const paymentSlice = createSlice({
	name: "payments",
	initialState,
	reducers: {
		setPaymentData: (state, action) => {
			state.isOpen = true;
			state.PaymentModal = action.payload.PaymentModal;
			state.PaymentTarget = action.payload.PaymentTarget;
			state.InitInvoice = action.payload.InitInvoice || null;
			state.InitAmount = action.payload.InitAmount || null;
			state.InitDiscount = action.payload.InitDiscount || null;
			state.InitMedCardNumber = action.payload.InitMedCardNumber || null;
			state.InitDate = action.payload.InitDate || null;
		},
		resetPaymentData: (state) => {
			state.isOpen = false;
			state.InitInvoice = null;
			state.PaymentTarget = null;
			state.PaymentModal = initialState.PaymentModal;
			state.InitAmount = null;
			state.InitDiscount = null;
			state.InitMedCardNumber = null;
			state.InitDate = null;
		},
	},
});

export const { setPaymentData, resetPaymentData } = paymentSlice.actions;

export const paymentData = (state) => state.payments.PaymentModal;
export const paymentTarget = (state) => state.payments.PaymentTarget;
export const initInvoice = (state) => state.payments.InitInvoice;
export const initAmount = (state) => state.payments.InitAmount;
export const initDiscount = (state) => state.payments.InitDiscount;
export const initMedCardNumber = (state) => state.payments.InitMedCardNumber;
export const initDate = (state) => state.payments.InitDate;
export const isOpenPaymentModal = (state) => state.payments.isOpen;
