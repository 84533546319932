import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import Button from "../../../../components/button/button";
import Loader from "../../../../components/loader";
import ListItemRow from "../../../../components/list-item-row";
import InfoBanner from "../../../../components/info-banner";
import { getErrorMessageFromError } from "../../../../app/error-handler.api";
import { useTranslate } from "../../../../localization/translate";
import { PAYMENT_TYPES } from "../../../payments/helpers/constants";
import { setPaymentData } from "../../../payments/services/payments.slice";
import {
	useGetCatalogProgramByIdQuery,
	useProgramApplicationMutation,
} from "../../services/profile.api";
import ProgramApplication from "./components/program-application";
import styles from "./catalog-program-detail.module.css";
import { useEffect } from "react";
import { Metrics } from "../../../../utils/metrics";
import {getSiteSourceName} from "../../../../utils";

const getValue = (value, toLocaleString) => {
	if (!value) return "-";
	if (toLocaleString) return value.toLocaleString();
	return value;
};

const CatalogProgramDetail = () => {
	const translate = useTranslate();
	const dispatch = useDispatch();

	const { id } = useParams();
	const { data: program, isLoading, error } = useGetCatalogProgramByIdQuery(id);
	const [create, { isLoading: isCreating, error: creatingError }] =
		useProgramApplicationMutation();

	const [isOpen, setIsOpen] = useState(false);

	const hasRatios = useMemo(
		() =>
			Boolean(
				program?.ageRatios.length ||
					program?.distanceRatios.length ||
					program?.yearRatios.length
			),
		[program]
	);

	const parsedError = useMemo(
		() =>
			getErrorMessageFromError({
				fromClarifications: true,
				error: creatingError,
			}),
		[creatingError]
	);

	useEffect(() => {
		if (program)
			Metrics.gtmEvent("view_item", {
				ItemId: program.id,
				name: program.name,
				price: program.basePrice,
				site_source_domain: getSiteSourceName(),
				site_source_path: window.location.pathname
			});
	}, [program]);

	const createApplication = ({ amount, programId, options }) => {
		return create({
			amount,
			programId,
			distanceRatioId: options?.distanceRatios.id || null,
			ageRatioId: options?.ageRatios.id || null,
			yearRatioId: options?.yearRatios.id || null,
		}).then((res) => {
			Metrics.gtmEvent("purchase", {
				ItemId: program.id,
				name: program.name,
				price: program.basePrice,
				site_source_domain: getSiteSourceName(),
				site_source_path: window.location.pathname
			});
			return res;
		});
	};

	const handleCreateApplication = () => {
		Metrics.gtmEvent("begin_checkout", {
			ItemId: program.id,
			name: program.name,
			price: program.basePrice,
			site_source_domain: getSiteSourceName(),
			site_source_path: window.location.pathname
		});

		if (hasRatios) {
			setIsOpen(true);
		} else {
			createApplication({
				amount: program.basePrice,
				programId: program.id,
			}).then((res) => {
				if (res.data && !res.error) {
					dispatch(
						setPaymentData({
							PaymentModal: {
								type: PAYMENT_TYPES.program,
								purposeId: res.data.applicationId,
							},
							PaymentTarget: program,
						})
					);
				}
			});
		}
	};

	const handleCreateApplicationWithParams = ({
		amount,
		selectedParams: options,
	}) => {
		Metrics.gtmEvent("begin_checkout", {
			ItemId: program.id,
			name: program.name,
			price: program.basePrice,
			site_source_domain: getSiteSourceName(),
			site_source_path: window.location.pathname
		});

		return createApplication({ amount, options, programId: program.id }).then(
			(res) => {
				if (res.data && !res.error) {
					setIsOpen(false);

					dispatch(
						setPaymentData({
							PaymentModal: {
								type: PAYMENT_TYPES.program,
								purposeId: res.data.applicationId,
							},
							PaymentTarget: program,
						})
					);
				}
			}
		);
	};

	return (
		<>
			{isLoading && (
				<div className={styles.loader}>
					<Loader />
				</div>
			)}

			{error && (
				<InfoBanner
					title={translate("app.failedToFetch", true)}
					theme="plain"
				/>
			)}

			{program && (
				<div className={styles.catalog_program}>
					<div className={styles.title}>
						<h3 className={classNames("h3", styles.title_text)}>
							{program.name}
						</h3>
					</div>

					<div className={styles.content_block}>
						<div className={styles.description}>
							<p
								className={styles.text}
								dangerouslySetInnerHTML={{ __html: program.description }}
							></p>
						</div>
					</div>

					{program.services.length > 0 && (
						<div className={styles.content_block}>
							<div className={styles.includes_title}>
								{translate("profile.programs.includesTitle")}
							</div>

							<div className={styles.includes_list}>
								{program.services.map((service) => (
									<ListItemRow
										key={service.id}
										title={service.name}
										showArrow={false}
									/>
								))}
							</div>
						</div>
					)}

					<div
						className={classNames(styles.content_block, styles.actions_block)}
					>
						<Button
							className={styles.payment_btn}
							onClick={handleCreateApplication}
						>
							{isCreating ? (
								<Loader
									width={20}
									height={20}
									white
									containerStyle={{ display: "flex", justifyContent: "center" }}
								/>
							) : (
								<>
									{translate("profile.programs.payment", true)}:{" "}
									{hasRatios && <>{translate("app.from", true)}&nbsp;</>}
									{getValue(program.basePrice, true)}&nbsp;
									{translate("app.currencySign", true)}
								</>
							)}
						</Button>

						{program.pdfDescriptionUrl && (
							<a
								rel="noopener noreferrer"
								target="_blank"
								href={program.pdfDescriptionUrl}
							>
								<Button light className={styles.about_btn}>
									{translate("profile.programs.aboutProgram")}
								</Button>
							</a>
						)}
					</div>

					<ProgramApplication
						isOpen={isOpen}
						program={program}
						isCreating={isCreating}
						errors={parsedError}
						onCreateApplication={handleCreateApplicationWithParams}
						closeModal={() => setIsOpen(false)}
					/>
				</div>
			)}
		</>
	);
};

export default CatalogProgramDetail;
