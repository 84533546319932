import { useContext } from "react";
import PaymentContext from "../../contexts/payment-context";
import Results from "../../components/Results";

export default function ResultWrapper({ type, target }) {
	const {
		payment: { results },
		handleCloseModal,
	} = useContext(PaymentContext);

	const handleClose = () => {
		handleCloseModal();
	};

	return (
		<Results
			paymentType={type}
			type={results.type}
			orderNumber={results.orderNumber}
			isLink={results.isLink}
			target={target}
			onClick={handleClose}
			reserveId={results.reserveId}
		/>
	);
}
