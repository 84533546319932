import React from "react";
import event_list from "../../../../icons/event-list.svg";
import styles from "./empty-event.module.css";
import { useTranslate } from "../../../../localization/translate";

export default function Empty({ title, text, link, noMargin = false }) {
	const translate = useTranslate();

	return (
		<div className={styles.event_block}>
			<div
				className={styles.logo_block}
				style={{ ...(noMargin && { marginTop: 0 }) }}
			>
				<img src={event_list} />
			</div>
			<div className={styles.title}>{title}</div>
			<div className={styles.text}>{text}</div>
			{link}
		</div>
	);
}
