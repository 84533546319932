import { useRef } from "react";
import styles from "./style.module.css";

export default function SliderItem({
	title,
	image,
	link = null,
	onClick = null,
}) {
	const ref = useRef();

	const Component = link ? "a" : "div";
	const props = link
		? {
				target: link.startsWith("/") || link.startsWith(window.location.origin) ? "_self" : "_blank",
				rel: "noopener noreferrer",
				href: link,
		  }
		: {};

	return (
		<Component
			ref={ref}
			className={styles.slider_item}
			onClick={onClick}
			{...props}
		>
			{title && <div className={styles.title}>{title}</div>}
			<img alt={title} src={image} className={styles.image} />
		</Component>
	);
}
