import styles from "./Close.module.css";
import classNames from "classnames";

export default function CloseModalButton({ closeModal, className = "" }) {
	return (
		<button
			onClick={closeModal}
			className={classNames(styles.closeBtn, className)}
		>
			<svg
				width="12"
				height="12"
				viewBox="0 0 12 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0.921875 11.4531C1.30469 11.8437 1.96875 11.8281 2.34375 11.4609L6 7.79684L9.65625 11.4531C10.0391 11.8359 10.6875 11.8359 11.0781 11.4453C11.4688 11.0625 11.4609 10.4062 11.0859 10.0312L7.42969 6.36716L11.0859 2.71091C11.4609 2.33591 11.4688 1.67966 11.0781 1.29684C10.6875 0.906219 10.0391 0.914032 9.65625 1.28903L6 4.94528L2.34375 1.28122C1.96875 0.914032 1.30469 0.898407 0.921875 1.28903C0.53125 1.67966 0.546875 2.34372 0.914062 2.71091L4.57812 6.36716L0.914062 10.0312C0.546875 10.3984 0.53125 11.0625 0.921875 11.4531Z"
					fill="#3C3C43"
					fillOpacity="0.6"
				/>
			</svg>
		</button>
	);
}
