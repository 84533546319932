import React from "react";
import styles from "./style.module.css";

export const TextField = ({
	limit = null,
	value,
	placeholder,
	onChange,
	onKeyDown,
}) => {
	const handleChange = (event) =>
		limit && event.target.value?.length > limit
			? null
			: onChange?.(event.target.value);

	const handleKeyDown = (event) => onKeyDown?.(event.key);

	return (
		<input
			value={value ?? ""}
			placeholder={placeholder}
			className={styles.text_field}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
		/>
	);
};
