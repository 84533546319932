import styles from "./frequent-queries.module.css";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { useTranslate } from "../../../../localization/translate";
import { useGetFrequentHintsQuery } from "../../services/appointment.api";
import { useDispatch, useSelector } from "react-redux";
import {
	selectedHistorySearch,
	setHistorySearch,
	setSelectedDoctor,
} from "../../services/appointment.slice";
import { useLocalStorage } from "beautiful-react-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import SearchItem from "./search-results";
import { Metrics } from "../../../../utils/metrics";
import {getSiteSourceName} from "../../../../utils";

export default function FrequentQuery({
	className,
	onSelectItem,
	isPublic = false,
	isWidget = false,
}) {
	const translate = useTranslate();
	const location = useLocation();
	const { data } = useGetFrequentHintsQuery();
	const historySearch = useSelector(selectedHistorySearch);
	const [localHistory, setLocalHistory] = useLocalStorage("history-search", []);
	const dispatch = useDispatch();
	const clearHistory = () => {
		setLocalHistory([]);
		dispatch(setHistorySearch([]));
	};
	const navigate = useNavigate();
	const [frequentExpanded, setFrequentExpanded] = useState(false);
	const [historyExpanded, setHistoryExpanded] = useState(false);


	useEffect(() => {
		const historyDoctors = historySearch.filter((obj) => {
			return "shortname" in obj
		})

		if(!!historyDoctors.length) {
			Metrics.gtmEvent("view_item_list", {
				item_list_id: "search_history",
				item_list_name: "История поиска",
				items: historyDoctors.map((doctor) => {
					return {
						item_id: doctor.id,
						item_name: doctor.shortname,
						price: doctor.minPrice,
						quantity: 1,
						item_list_id: "search_history",
						item_list_name: "История поиска"
					}
				}),
				site_source_domain: getSiteSourceName(),
				site_source_path: location.pathname
			});
		}
	
	}, []);

	return (
		<div className={className}>
			{historySearch.length > 0 && (
				<div className={styles.title}>
					{translate("appointment.history_search")}
					<span onClick={clearHistory} className={styles.action}>
						{translate("app.clear", true)}
					</span>
				</div>
			)}
			{historySearch
				.slice(0, historyExpanded ? historySearch.length : 4)
				.map((item, i) => (
					<SearchItem
						isPublic={isPublic}
						item={item}
						isLast={i + 1 === historySearch.length}
						onSelectItem={onSelectItem}
						key={JSON.stringify(item)}
					/>
				))}
			{historySearch.length > 4 && (
				<div
					className={styles.show_more}
					onClick={() => setHistoryExpanded(!historyExpanded)}
				>
					{historyExpanded && translate("app.showLess", true)}
					{!historyExpanded && translate("app.showMore", true)}
				</div>
			)}

			{data && data.length > 0 && (
				<div className={styles.title}>
					{translate("appointment.frequent_queries")}
				</div>
			)}
			{data &&
				data.slice(0, frequentExpanded ? data.length : 4).map((item, i) => {
					let name;
					let onClick;
					if (item.type === "service") {
						name = item.hint.service.name;
						onClick = () => {
							onSelectItem && onSelectItem();
							navigate(
								`/${
									isPublic || isWidget ? "schedule" : "appointment"
								}/services/${item.hint.researchId}/${item.hint.service.id}${
									location.search
								}`
							);
						};
					}
					if (item.type === "doctor") {
						onClick = () => {

							onSelectItem && onSelectItem();
							dispatch(setSelectedDoctor({ id: item.hint.id }));
						};
						name = item.hint.shortname;
					}
					if (item.type === "speciality") {
						onClick = () => {
							onSelectItem && onSelectItem();
							navigate(
								`/${
									isPublic || isWidget ? "schedule" : "appointment"
								}/speciality/${item.hint.id}${location.search}`
							);
						};
						name = item.hint.name;
					}

					return (
						<div
							onClick={onClick}
							className={classNames(styles.row, {
								[styles.last]: i + 1 === historySearch.length,
							})}
							key={`hint-${item.type}-${item.hint.id}`}
						>
							{name}
						</div>
					);
				})}
			{data && data.length > 4 && (
				<div
					className={styles.show_more}
					onClick={() => setFrequentExpanded(!frequentExpanded)}
				>
					{frequentExpanded && translate("app.showLess", true)}
					{!frequentExpanded && translate("app.showMore", true)}
				</div>
			)}
		</div>
	);
}
