import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "../../../../../../localization/translate";
import ListItemRow from "../../../../../../components/list-item-row";
import styles from "./style.module.css";

function isInt(value) {
	return (
		!isNaN(value) &&
		parseInt(Number(value)) == value &&
		!isNaN(parseInt(value, 10))
	);
}

const DisplayDescriptionFactory = (translate) => (entity) => {
	if (entity.type === "discountCards" && isInt(entity.totalDiscountValue)) {
		return `-${entity.totalDiscountValue}%`;
	} else if (entity.type === "bonusAccounts" && isInt(entity.balance)) {
		return `${translate("profile.discounts.available", true)} ${
			entity.balance
		} ${translate("profile.discounts.bonuses", true)}`;
	} else if (entity.type === "couponBooks") {
		return `${translate("profile.discounts.available", true)} ${
			entity.coupons.length
		} ${translate("profile.discounts.pieces", true)}`;
	} else if (entity.type === "giftCertificates" && isInt(entity.balance)) {
		return `${translate("profile.discounts.giftCertificate.balance", true)} ${
			entity.balance
		} ${translate("app.currencySign", true)}`;
	}

	return null;
};

const getRouterPath = (entity) => {
	if (entity.type === "discountCards") {
		return `/profile/discounts/discount-card/${entity.number}`;
	} else if (entity.type === "giftCertificates") {
		return `/profile/discounts/gift-certificate`;
	} else if (entity.type === "bonusAccounts") {
		return `/profile/discounts/bonus-account/${entity.id}`;
	} else if (entity.type === "couponBooks") {
		return `/profile/discounts/coupon-book/${entity.number}`;
	}

	return null;
};

const AvailableDiscounts = ({ discounts }) => {
	const translate = useTranslate();
	const navigate = useNavigate();

	const getDiscplayDescription = useMemo(
		() => DisplayDescriptionFactory(translate),
		[translate]
	);

	const flattenDiscounts = useMemo(
		() =>
			Object.keys(discounts)
				.map((key) =>
					discounts[key].map((item) => ({
						type: key,
						reactKey: `${key}-${item.id ?? item.number}`,
						displayDescription: getDiscplayDescription({ type: key, ...item }),
						routerPath: getRouterPath({ type: key, ...item }),
						...item,
					}))
				)
				.reduce((acc, curr) => acc.concat(curr), []),
		[discounts]
	);

	return (
		<>
			<div className={styles.title}>
				{translate("profile.discounts.availableDiscounts")}
			</div>

			<div className={styles.discounts_list}>
				{flattenDiscounts.map((item) => (
					<ListItemRow
						key={item.reactKey}
						title={item.name}
						description={item.displayDescription}
						onClick={() => item.routerPath && navigate(item.routerPath)}
					/>
				))}
			</div>
		</>
	);
};

export default AvailableDiscounts;
