import { createApi } from "@reduxjs/toolkit/query/react";
import baseConfig from "../../../app/config.api";
import { setAppointmentExaminationResult } from "./appointment.slice";

export const appointmentApi = createApi({
	reducerPath: "appointmentApi",
	baseQuery: baseConfig,
	tagTypes: ["DoctorByID", "Doctors"],
	endpoints: (builder) => ({
		getDoctors: builder.query({
			query: () => "/user/doctors",
			providesTags: ["Doctors"],
			transformResponse: (response) => response.data,
		}),
		getSpeciality: builder.query({
			query: () => "/speciality",
			transformResponse: (response) => response.data,
		}),
		getDoctorSchedule: builder.mutation({
			query: (body) => ({
				url: "/schedule/doctor",
				method: "POST",
				body,
			}),
			transformResponse: (response) => response.data,
		}),
		getSpecialitySchedule: builder.mutation({
			query: (body) => ({
				url: "/schedule/speciality/",
				method: "POST",
				body,
			}),
			transformResponse: (response) => response.data,
		}),
		getDoctorById: builder.query({
			query: ({ doctorId, isMis = false }) => {
				const request = { url: `/doctor/${doctorId}` };

				if (isMis) {
					request.headers = { "X-Features": "id_mis" };
				}

				return request;
			},
			providesTags: ["DoctorByID"],
			transformResponse: (response) => response.data,
		}),
		getResearch: builder.query({
			query: () => `/research`,
			transformResponse: (response) => response.data,
		}),
		getResearchSchedule: builder.mutation({
			query: (body) => ({
				url: "/schedule/research/",
				method: "POST",
				body,
			}),
			transformResponse: (response) => response.data,
		}),
		subscribe: builder.mutation({
			query: (body) => ({
				url: "/schedule/subscribe/",
				method: "POST",
				body,
			}),
			transformResponse: (response) => response.data,
		}),
		appointmentDiagnostic: builder.mutation({
			query: (body) => ({
				url: "/appointment/diagnostic/",
				method: "POST",
				body,
			}),
		}),
		appointmentExamination: builder.mutation({
			query: (body) => ({
				url: "/appointment/examination/",
				method: "POST",
				body,
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const data = await queryFulfilled;
					dispatch(setAppointmentExaminationResult(data));
				} catch (error) {
					console.log(error.error?.message || error.message || error);
				}
			},
		}),
		getFrequentHints: builder.query({
			query: () => "/search/hints/frequent",
			transformResponse: (response) => response.data.hints,
		}),
		getHints: builder.mutation({
			query: (string) => `/search/hints/?q=${string}`,
			transformResponse: (response) => response.data,
		}),
		addToFavorite: builder.mutation({
			query: (doctorId) => ({
				url: "/user/doctors",
				method: "POST",
				body: {
					doctorId,
				},
			}),
			invalidatesTags: ["Doctors", "DoctorByID"],
		}),
		removeFromFavorite: builder.mutation({
			query: (doctorId) => ({
				url: `/user/doctors`,
				method: "DELETE",
				body: { doctorId },
			}),
			invalidatesTags: ["Doctors"],
		}),
		submitReview: builder.mutation({
			query: (body) => ({
				url: `/appointment/review/`,
				method: "POST",
				body: body,
			}),
			invalidatesTags: ["DoctorByID"],
		}),
		checkForReview: builder.query({
			query: (doctorId) => `/doctor/appointment_for_review/${doctorId}`,
		}),
		getOpinionSubject: builder.query({
			query: () => `/opinion_subject/`,
			transformResponse: (response) => response.data,
		}),
		submitOpinion: builder.mutation({
			query: (body) => {
				return {
					url: "/appointment/opinion",
					method: "POST",
					body,
				};
			},
		}),
		exportCalendarEvent: builder.mutation({
			query: (body) => ({
				url: "/appointment/plan/calendar_event/",
				method: "POST",
				body: body,
				responseHandler: "text",
			}),
		}),
		scheduleFeedback: builder.mutation({
			query: (body) => ({
				url: "/schedule/feedback",
				method: "POST",
				body,
			}),
		}),
		scheduleFeedbackSms: builder.mutation({
			query: ({ phone }) => ({
				url: "/schedule/feedback/sms",
				method: "POST",
				body: { phone },
			}),
			transformResponse: (response) => response.data,
		}),
		confirmChat: builder.mutation({
			query: ({ doctorId, specialityId }) => ({
				url: "/chat_confirmation/",
				method: "POST",
				body: { doctorId, specialityId },
			}),
			transformResponse: (response) => response.data,
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					// dispatch(setChatPurchaseInfo(data));
				} catch (error) {
					console.log(error);
				}
			},
		}),
		skipChatPayment: builder.mutation({
			query: (body) => ({
				url: "/skip_payment/",
				method: "POST",
				body,
			}),
			transformResponse: (response) => response.data,
		}),
		getDoctorReviews: builder.query({
			query: ({ id, page }) => ({
				url: `/doctor/${id}/reviews/`,
				params: { page },
			}),
			transformResponse: (response) => response.data,
		}),
	}),
});

export const {
	useExportCalendarEventMutation,
	useSubmitOpinionMutation,
	useGetOpinionSubjectQuery,
	useCheckForReviewQuery,
	useSubmitReviewMutation,
	useGetHintsMutation,
	useAddToFavoriteMutation,
	useRemoveFromFavoriteMutation,
	useGetFrequentHintsQuery,
	useGetDoctorsQuery,
	useGetSpecialityQuery,
	useGetDoctorScheduleMutation,
	useGetDoctorByIdQuery,
	useGetSpecialityScheduleMutation,
	useGetResearchQuery,
	useGetResearchScheduleMutation,
	useSubscribeMutation,
	useAppointmentExaminationMutation,
	useAppointmentDiagnosticMutation,
	useScheduleFeedbackMutation,
	useScheduleFeedbackSmsMutation,
	useConfirmChatMutation,
	useSkipChatPaymentMutation,
	useGetDoctorReviewsQuery,
	useLazyGetDoctorReviewsQuery
} = appointmentApi;
