import { Mutex } from "async-mutex";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import {
	setAuthInfo,
	setAuthInfoToLS,
	setExpired,
	setLastUrl,
} from "../features/login/services/login.slice";
import { setApplicationError, setShowSlowInternetScreen } from "./app.slice";
import { VERSION } from "../utils";

const mutex = new Mutex();

export const loadBaseUrl = () => {
	if (process.env && process.env.REACT_APP_BACKEND_API) {
		return process.env.REACT_APP_BACKEND_API;
	}

	return "https://backend-dev-app2.medicina.ru";
};

const baseQuery = fetchBaseQuery({
	baseUrl: `${loadBaseUrl()}/api`,
	prepareHeaders: (headers) => {
		const token = localStorage.getItem("accessToken");

		if (token) {
			headers.set("X-Access-Token", `Bearer ${token}`);
			// headers.set("X-Web-App-Version", VERSION);
		}

		return headers;
	},
	timeout: 15000,
});

export default async (args, api, extraOptions) => {
	await mutex.waitForUnlock();
	let result = await baseQuery(args, api, extraOptions);

	const logout = () => {
		api.dispatch(setAuthInfo(null));
		api.dispatch(setExpired(false));
		api.dispatch(setLastUrl(null));
		/*
		 * TODO: remove this cruch
		 * */
		setTimeout(() => window.location.reload(), 500);
	};

	if (
		result.error &&
		(result.error.status === "TIMEOUT_ERROR" || result.error.status === 504)
	) {
		api.dispatch(setShowSlowInternetScreen(true));
	}

	if (
		result.error &&
		(result.error.status === 401 || result.error.status === 403)
	) {
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();
			const refresh = api.getState().authentication.refreshToken;

			if (refresh) {
				const refreshResult = await baseQuery(
					{ url: "/auth/refresh/", body: { refresh }, method: "POST" },
					api,
					extraOptions
				);

				if (refreshResult.data) {
					api.dispatch(
						setAuthInfo({
							accessToken: refreshResult.data.data.access,
							refreshToken: refreshResult.data.data.refresh,
						})
					);
					api.dispatch(setAuthInfoToLS());

					result = await baseQuery(args, api, extraOptions);
				} else if (
					refreshResult.error &&
					(refreshResult.error.status === 401 ||
						refreshResult.error.status === 403)
				) {
					const guestMode = api?.getState()?.authentication.isGuest;
					if (guestMode && api) {
						const guestAuth = await baseQuery(
							{ url: "/auth/guest/", body: {}, method: "POST" },
							api,
							extraOptions
						);

						if (guestAuth.data) {
							api.dispatch(
								setAuthInfo({
									accessToken: guestAuth.data.data.access,
									refreshToken: guestAuth.data.data.refresh,
								})
							);
							api.dispatch(setAuthInfoToLS());

							result = await baseQuery(args, api, extraOptions);
						}
					} else {
						logout();
					}
				} else {
					api.dispatch(setApplicationError({ status: 500, error: "" }));
				}
			} else {
				logout();
			}

			await release();
		} else {
			await mutex.waitForUnlock();
			result = await baseQuery(args, api, extraOptions);
		}
	}

	return result;
};
