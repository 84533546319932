import css from "./result.module.css";
import {useTranslate} from "../../../../../localization/translate";
import Button from "../../../../../components/button/button";
import {useNavigate, useParams} from "react-router-dom";
import {useState} from "react";

export default function Result() {
  const {status} = useParams();
  const t = useTranslate();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return <div className={css.wrapper}>
    <div className={css.container}>
      {
        status === "failure" && <img src="/images/coffee/failure.svg" alt=""/>
      }
      {
        status === "success" && <img src="/images/coffee/success.svg" alt=""/>
      }
      <div className={css.title}>
        {
          status === "failure" && t("profile.coffee.failureTitle")
        }
        {
          status === "success" && t("profile.coffee.successTitle")
        }
      </div>
      <div className={css.text}>
        {
          status === "failure" && t("profile.coffee.failureText")
        }
        {
          status === "success" && t("profile.coffee.successText")
        }
      </div>
      <Button
        onClick={() => {
          setLoading(true);
          if (status === "failure") {
            navigate("/coffee");
          }
          if (status === "success") {
            navigate("/profile");
          }
        }}
        className={css.button}
        isLoading={loading}
      >
        {t("app.close")}
      </Button>
    </div>
  </div>
}