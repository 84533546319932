import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { RadioButton, TextField } from "../Inputs";
import { isClose, isOpen } from "../utils";
import styles from "./styles.module.css";

export const INPUT_TYPE = {
	CLOSE: "CLOSE",
	OPEN: "OPEN",
};

export const Answer = ({
	active: { answer: active, additional },
	answer,
	onChange,
	onChangeAdditional,
}) => {
	const [openFieldValue, setOpenFieldValue] = useState(null);

	const {
		id,
		title,
		placeholder,
		additionalAnswers,
		isAdditionalQuestion,
		titleAdditional,
	} = answer;
	const isActive = useMemo(() => active?.id === id, [active, id]);

	const showAdditionalAnswers = useMemo(
		() => isActive && !isAdditionalQuestion && additionalAnswers?.length > 0,
		[isActive, isAdditionalQuestion, additionalAnswers]
	);

	const handleChangeClose = () => onChange(answer);

	const handleChangeOpen = (value) => {
		setOpenFieldValue(value);
		onChange({ ...answer, ...(value.length && { value }) });
	};

	const handleChangeInner = (additional) => onChangeAdditional(additional);

	useEffect(() => {
		if (!isActive) {
			setOpenFieldValue(null);
		}
	}, [isActive]);

	return (
		<div className={styles.answer}>
			{isClose(answer) && (
				<div
					className={cx(styles.field, styles.close_field, {
						[styles.open]: showAdditionalAnswers,
					})}
				>
					<RadioButton
						label={title}
						value={id}
						isActive={isActive}
						onChange={handleChangeClose}
					/>

					{showAdditionalAnswers && (
						<div className={styles.additionals_wrapper}>
							{titleAdditional && (
								<div className={styles.title}>
									<span>{titleAdditional}</span>
								</div>
							)}

							<div className={styles.additionals}>
								{additionalAnswers.map((answer) => (
									<div key={answer.id} className={styles.additional}>
										<Answer
											answer={answer}
											active={{ answer: additional }}
											onChange={handleChangeInner}
										/>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			)}

			{isOpen(answer) && (
				<div
					className={cx(styles.field, styles.open_field, {
						[styles.open]: isActive,
					})}
				>
					<RadioButton
						label={title}
						value={id}
						isActive={isActive}
						onChange={handleChangeClose}
					/>

					{isActive && (
						<div className={styles.open_wrapper}>
							<TextField
								limit={300}
								placeholder={placeholder}
								value={openFieldValue}
								onChange={handleChangeOpen}
							/>

							{showAdditionalAnswers && (
								<div className={styles.additionals_wrapper}>
									{titleAdditional && (
										<div className={styles.title}>
											<span>{titleAdditional}</span>
										</div>
									)}

									<div className={styles.additionals}>
										{additionalAnswers.map((answer) => (
											<div key={answer.id} className={styles.additional}>
												<Answer
													answer={answer}
													active={{ answer: additional }}
													onChange={handleChangeInner}
												/>
											</div>
										))}
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};
