import styles from "./program-detail.module.css";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import Loader from "../../../../components/loader";
import arrow from "../../../../icons/arrow_down_24x24.svg";
import { useGetPurchasedProgramQuery } from "../../services/profile.api";
import { useTranslate } from "../../../../localization/translate";
import ProfileNotice from "../personala-data/notice";
import React from "react";
import moment from "moment";
import { convertDate } from "../../../../utils/dates/timezone-converter";
import { pushSystemNotification } from "../../../../app/app.slice";

moment.locale("ru");

export default function Detail() {
	const params = useParams();
	const { data: purchased, isLoading, error } = useGetPurchasedProgramQuery();
	const program = (purchased ?? []).find((item) => item.idMis == params.id);
	const translate = useTranslate();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleClickService = (service) => {
		if (!service.specialityId) {
			dispatch(
				pushSystemNotification({
					type: "error",
					clientMessage: translate("profile.programs.scheduleNotAllowed", true),
				})
			);
		} else {
			navigate(`/appointment/speciality/${service.specialityId}`);
		}
	};

	if (!program && !error) {
		return (
			<div className={styles.wrapper}>
				<div className={styles.loader}>
					<Loader />
				</div>
			</div>
		);
	}

	if (!error) {
		return (
			<div className={styles.wrapper}>
				<div className={classNames("h3", styles.h3)}>{program.name}</div>
				{program.balance && (
					<div className={styles.balance}>
						<div className={styles.amount}>
							{program.balance.toLocaleString()}
							&nbsp;
							{translate("app.currencySign", true)}
						</div>
						<div className={styles.description}>
							{translate("profile.programs.accountBalance")}
						</div>
					</div>
				)}

				<div className={styles.start_date}>
					{translate("profile.programs.startDate")}
					<span>{moment(program.startDate).format("D MMMM YYYY")}</span>
				</div>

				{program.availableServices.length > 0 && (
					<div className={styles.category}>
						<div className={styles.category_title}>
							{translate("profile.programs.availableServices")}
						</div>

						{program.availableServices.map((service) => (
							<div
								key={`catalog-program-${service.id}`}
								className={classNames(styles.program, {
									[styles.clicable]: !!service.specialityId,
								})}
								onClick={() =>
									!!service.specialityId && handleClickService(service)
								}
							>
								<div>{service.name}</div>

								{service.specialityId && (
									<div className={styles.arrow}>
										<img src={arrow} />
									</div>
								)}
							</div>
						))}
					</div>
				)}

				{program.usedServices.length > 0 && (
					<div className={styles.category}>
						<div className={styles.category_title}>
							{translate("profile.programs.usedServices")}
						</div>

						{program.usedServices.map((service) => (
							<div
								key={`catalog-used-service-${service.id}`}
								className={classNames(styles.program, {
									[styles.clicable]: !!service.specialityId,
								})}
								onClick={() =>
									!!service.specialityId && handleClickService(service)
								}
							>
								<div>{service.name}</div>

								{service.specialityId && (
									<div className={styles.arrow}>
										<img src={arrow} />
									</div>
								)}
							</div>
						))}
					</div>
				)}
			</div>
		);
	}

	return (
		<div className={styles.wrapper}>
			<ProfileNotice
				title={translate("app.failedToFetch", true)}
				isGrey
			/>
		</div>
	);
}
