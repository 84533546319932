import { useState, useEffect } from "react";
import { useTranslate } from "../../../../localization/translate";
import { CustomModal } from "../../../../components/custom-modal";
import CloseModalButton from "../../../../components/modal-items/close";
import ProfileNotice from "../../../profile/modules/personala-data/notice";
import Input from "../../../../components/input";
import Button from "../../../../components/button/button";
import Loader from "../../../../components/loader";
import styles from "./styles.module.css";
import { Metrics } from "../../../../utils/metrics";

const HelperBlock = ({ translate }) => (
	<div className={styles.whereToSend}>
		{translate("login.sms.enterCarNumText")}
		&nbsp;
		{/*<a*/}
		{/*	href={`tel:${translate("login.sms.supportContact", true)}`}*/}
		{/*	className={styles.link}*/}
		{/*>*/}
		{/*	{translate("login.sms.supportContact", true)}*/}
		{/*</a>*/}
	</div>
);

const NoticeText = ({ translate }) => (
	<>
		{translate("login.sms.tryAnotherCard")}
		&nbsp;
		<a
			href={`tel:${translate("login.sms.supportContact", true)}`}
			className={styles.link}
		>
			{translate("login.sms.supportContact", true)}
		</a>
	</>
);

export default function MedCardNumberFormView({
																								isOpen,
																								onClose,
																								onRecCard,
																								isLoading,
																								confirmAction,
																								error,
																								isWidget,
																							}) {
	const translate = useTranslate();

	const [cardNumber, setNum] = useState("");
	const [fieldError, setFieldError] = useState(null);
	const [isOpenCreateIssue, setIsOpenCreateIssue] = useState(false);

	const handleSubmit = () => {
		confirmAction(cardNumber);
		Metrics.gtmEvent("send_med_card_number");
	};

	const handleInputKeyDown = (event) => {
		if (event.key === "Enter") handleSubmit();
	};

	useEffect(() => {
		if (/[^+\d]/g.test(cardNumber)) {
			setFieldError(
				translate("login.sms.incorrectCardNumTypeOnlyDigits", true)
			);
		} else {
			setFieldError(null);
		}
	}, [cardNumber]);

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			className={styles.rmodal}
			style={{
				overlay: {
					zIndex: 180,
					display: "flex",
					alignItems: isWidget ? "flex-start" : "center",
				},
			}}
		>
			<div className={styles.modal}>
				<CloseModalButton closeModal={onClose} />

				<div className={`${styles.title} h3`}>
					{translate("login.sms.enterCarNum")}
				</div>

				<HelperBlock translate={translate} />

				<Input
					autofocus
					onKeyDown={handleInputKeyDown}
					type={"text"}
					placeholder={translate("profile.personalData.medCardNumber", true)}
					value={cardNumber}
					errorMsg={fieldError || error}
					inputMode="numeric"
					onChange={setNum}
					containerClass={styles.margin_top}
				/>

				{/*{error && (*/}
				{/*	<ProfileNotice*/}
				{/*		isGrey*/}
				{/*		className={styles.margin_top}*/}
				{/*		title={translate("app.information", true)}*/}
				{/*		message={<NoticeText translate={translate} />}*/}
				{/*	/>*/}
				{/*)}*/}
				{isLoading ? (
					<div className={styles.loader}>
						<Loader />
					</div>
				) : (
					<Button
						disabled={cardNumber.length === 0 || fieldError}
						className={styles.enter_card_login}
						onClick={handleSubmit}
					>
						{translate("login.loginBtn", true)}
					</Button>
				)}
				<div className={styles.forgot_text_link} onClick={onRecCard}>{translate("login.sms.forgotCarNumText")}</div>
			</div>
		</CustomModal>
	);
}
