import styles from "./research-schedule.module.css";
import {
	useGetResearchQuery,
	useGetResearchScheduleMutation,
} from "../../services/appointment.api";
import { useEffect, useState } from "react";
import Schedule from "../schedule/schedule";
import moment from "moment";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useTranslate } from "../../../../localization/translate";
import { Metrics } from "../../../../utils/metrics";
import { useDispatch, useSelector } from "react-redux";
import {
	selectDate,
	selectDateRange,
	setDate,
} from "../../services/appointment.slice";
import classNames from "classnames";

export default function ResearchSchedule({
	isPublic = false,
	isWidget = false,
}) {
	const params = useParams();
	const location = useLocation();
	const dispatch = useDispatch();
	const activeDate = useSelector(selectDate);
	const dateRange = useSelector(selectDateRange);

	const [getSchedule, { data, isLoading, error }] =
		useGetResearchScheduleMutation();
	const { data: researches } = useGetResearchQuery();
	const root = researches
		? researches.find((research) => research.id == params.research_id)
		: null;
	const translate = useTranslate();
	const [serviceInfo, setServiceInfo] = useState(null);

	useEffect(() => {
		if (data) {
			setServiceInfo(data.service);
		}
	}, [data]);

	useEffect(() => {
		if (
			!isLoading &&
			!error &&
			!!dateRange.from &&
			!!dateRange.to &&
			moment(dateRange.to).isAfter(dateRange.from, "d")
		) {
			getSchedule({
				researchId: params.research_id,
				serviceId: params.service_id,
				dateFrom: dateRange.from,
				dateTo: dateRange.to,
			});
		}
	}, [dateRange]);

	const onSetDate = (date) => {
		dispatch(setDate(date));

		Metrics.gtmEvent("view_schedule_research", {
			researchId: params.research_id,
			date,
		});
	};

	return (
		<Schedule
			isPublic={isPublic}
			breadcrumbs={
				<div
					className={classNames(styles.breadcrumbs, {
						[styles.loaded]: root && serviceInfo,
					})}
				>
					{root && serviceInfo && (
						<>
							<span className={classNames(styles.root, styles.extra)}>
								... /{" "}
							</span>
							<span className={styles.root}>
								<NavLink
									to={
										`/${
											isPublic || isWidget ? "schedule" : "appointment"
										}/services` + location.search
									}
								>
									{translate("appointment.navigation.servicesTitle")}
									&nbsp;/&nbsp;
								</NavLink>
							</span>
							<span className={styles.root}>
								<NavLink
									to={
										`/${
											isPublic || isWidget ? "schedule" : "appointment"
										}/services` + location.search
									}
									state={{ id: root.id, name: root.name }}
								>
									{root.name}
									&nbsp;/&nbsp;
								</NavLink>
							</span>
							<span className={styles.current}>{serviceInfo.name}</span>
						</>
					)}
				</div>
			}
			servicePrice={serviceInfo && serviceInfo.price}
			serviceName={serviceInfo && serviceInfo.name}
			data={data}
			isLoading={isLoading}
			activeDate={activeDate}
			setDate={onSetDate}
		/>
	);
}
