import { useEffect, useState, useMemo } from "react";
import Schedule from "./schedule";
import {
	selectDate,
	setDate,
	withoutSchedule,
} from "../../services/appointment.slice";
import About from "./about";
import Feedbacks from "./feedbacks";
import style from "./doctor-modal.module.css";
import { useTranslate } from "../../../../localization/translate";
import { yearsEnd } from "../../../../localization/years";
import Button from "../../../../components/button/button";
import {
	useAddToFavoriteMutation,
	useGetDoctorByIdQuery,
	useGetDoctorScheduleMutation,
	useRemoveFromFavoriteMutation,
} from "../../services/appointment.api";
import classNames from "classnames";
import Dropdown from "../../../../components/dropdown/dropdown";
import { useDispatch, useSelector } from "react-redux";
import InfoBanner from "../../../../components/info-banner";
import ProfileNotice from "../../../profile/modules/personala-data/notice";
import { Metrics } from "../../../../utils/metrics";
import { selectAccessToken } from "../../../login/services/login.slice";
import { tokenPayload } from "../../../../utils";
import moment from "moment";

export const getValue = (value, localeString = false) => {
	if (value === null) {
		return " - ";
	}

	if (value === 0) {
		// return '0';
		return " - ";
	}

	if (value && localeString) {
		return value.toLocaleString();
	}

	return value;
};

export function FavoriteButton({ isFavorite, onClick }) {
	const token = useSelector(selectAccessToken);

	const isAuth = useMemo(() => {
		if (!!token) {
			const { guest } = tokenPayload(token);
			return !guest;
		} else return false;
	}, [token]);

	if (!isAuth) return null;

	return (
		<div className={style.add_to_favorites} onClick={onClick}>
			{isFavorite ? (
				<svg
					width="44"
					height="44"
					viewBox="0 0 44 44"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M22 25.9778L29.5013 31.803C30.1113 32.2766 31 31.8427 31 31.0712V13.8542C31 12.8302 30.1685 12 29.1429 12H14.8571C13.8315 12 13 12.8302 13 13.8542V31.0712C13 31.8427 13.8887 32.2766 14.4987 31.803L22 25.9778Z"
						fill="#7A8CA4"
					/>
				</svg>
			) : (
				<svg
					width="44"
					height="44"
					viewBox="0 0 44 44"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M22 24.0806L29.5 29.9047V13.8542C29.5 13.6573 29.3401 13.4976 29.1429 13.4976H14.8571C14.6599 13.4976 14.5 13.6573 14.5 13.8542V29.9047L22 24.0806ZM22 25.9778L29.5013 31.803C30.1113 32.2766 31 31.8427 31 31.0712V13.8542C31 12.8302 30.1685 12 29.1429 12H14.8571C13.8315 12 13 12.8302 13 13.8542V31.0712C13 31.8427 13.8887 32.2766 14.4987 31.803L22 25.9778Z"
						fill="#7A8CA4"
					/>
				</svg>
			)}
		</div>
	);
}

export function ErrorIcon() {
	return (
		<svg
			width="81"
			height="81"
			viewBox="0 0 81 81"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="40.5" cy="40.5" r="40" fill="#F7236F" />
			<path
				d="M40.5 17.5C38.2909 17.5 36.5 19.2909 36.5 21.5V44.5C36.5 46.7091 38.2909 48.5 40.5 48.5C42.7091 48.5 44.5 46.7091 44.5 44.5V21.5C44.5 19.2909 42.7091 17.5 40.5 17.5Z"
				fill="white"
			/>
			<path
				d="M40.5 63.5C43.2614 63.5 45.5 61.2614 45.5 58.5C45.5 55.7386 43.2614 53.5 40.5 53.5C37.7386 53.5 35.5 55.7386 35.5 58.5C35.5 61.2614 37.7386 63.5 40.5 63.5Z"
				fill="white"
			/>
		</svg>
	);
}

export default function DoctorModalBody({
	doctorInfo,
	setSelectedDoctor,
	isWidget = false,
}) {
	const dispatch = useDispatch();
	const patient = doctorInfo?.patient;
	const activeDate = useSelector(selectDate);
	const [noticesD, setNoticesD] = useState([]);
	const [service, setService] = useState(
		doctorInfo?.service ||
			(doctorInfo?.serviceId
				? { id: doctorInfo.serviceId, name: doctorInfo.serviceName }
				: null)
	);

	const modalWithoutSchedule = useSelector(withoutSchedule);
	let content;
	if (!modalWithoutSchedule) {
		content = {
			Schedule,
			Feedbacks,
		};
	} else {
		content = {
			Feedbacks,
		};
	}

	const fullDoctorInfo = useMemo(
		() => (doctorInfo ? { ...doctorInfo } : null),
		[doctorInfo]
	);

	const [getDoctorSchedule] = useGetDoctorScheduleMutation();
	const { data: doctor, error } = useGetDoctorByIdQuery(
		{ doctorId: fullDoctorInfo ? fullDoctorInfo.id : null },
		{ skip: !fullDoctorInfo }
	);

	if (doctor?.description || doctor?.services?.length > 0) {
		content.About = About;
	}

	const onRequestClose = () => {
		if (setSelectedDoctor) {
			setSelectedDoctor(null);
		}
	};

	const speciality =
		fullDoctorInfo && doctor
			? fullDoctorInfo.specialityId
				? doctor.specialities.find(
						(spec) => spec.id === fullDoctorInfo.specialityId
				  )
				: doctor.specialities.length > 0
				? doctor.specialities[0]
				: null
			: null;

	if (speciality && speciality.appointmentFeature) {
		if (fullDoctorInfo.speciality) {
			fullDoctorInfo.speciality.appointmentFeature =
				speciality.appointmentFeature;
		} else {
			fullDoctorInfo.speciality = {
				appointmentFeature: speciality.appointmentFeature,
			};
		}
	}

	const [specialityExpanded, setExpanded] = useState(false);
	const [contentSelected, setContentSelected] = useState(
		Object.keys(content)[0]
	);
	const Component = content[contentSelected];
	const translate = useTranslate();
	const [addToFavorite] = useAddToFavoriteMutation();
	const [removeFromFavorite] = useRemoveFromFavoriteMutation();
	const [isFavorite, setIsFavorite] = useState(
		doctor ? doctor.favorite : false
	);
	const favoriteAction = () => {
		if (isFavorite) {
			removeFromFavorite(doctor.id);
			Metrics.gtmEvent("remove_favorite_doctor", { doctorId: doctor.id });
		} else {
			addToFavorite(doctor.id);
			Metrics.gtmEvent("add_favorite_doctor", { doctorId: doctor.id });
		}
		setIsFavorite(!isFavorite);
	};

	const toggleSpecialitiesDropdown = () => {
		if (doctor && doctor.specialities.length > 1) {
			setExpanded(!specialityExpanded);
		}
	};

	const getDoctorNotices = async () => {
		const { data } = await getDoctorSchedule({
			doctorId: doctorInfo?.id,
			dateFrom: moment().format("YYYY-MM-DD"),
			dateTo: moment().format("YYYY-MM-DD"),
		});

		if (data) {
			setNoticesD(data?.noticesD);
		}
	};

	useEffect(() => {
		setContentSelected(Object.keys(content)[0]);
	}, [modalWithoutSchedule]);


	useEffect(() => {
		if (error) {
			onRequestClose();
		}
	}, [error]);

	useEffect(() => {
		if (doctorInfo?.id) {
			getDoctorNotices();
		}
	}, []);

	const updateSpeciality = (speciality) => {
		setService(null);
		setSelectedDoctor({
			id: fullDoctorInfo.id,
			specialityId: speciality.id,
			specialityName: speciality.name
		});
	};

	const handleChangeDate = (date) => {
		dispatch(setDate(date));
		Metrics.gtmEvent("view_schedule_doctor", { doctorId: doctorInfo.id, date });
	};

	if (!doctor || doctor?.id !== doctorInfo?.id) return null;

	return (
		<div className={style.doctor}>
			{doctor && !error && (
				<h1 className={style.title}>
					{translate("appointment.doctorModal.title")}
				</h1>
			)}
			{doctor && !error && (
				<div className={style.info}>
					{doctor.photoUrl ? (
						<div className={style.avatar}>
							<img src={doctor.photoUrl} alt="" />
						</div>
					) : (
						<div className={classNames(style.avatar, style.empty)}>
							<svg
								width="26"
								height="30"
								viewBox="0 0 26 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M17.509 8.20317C17.509 5.43587 15.3363 3.19504 12.6532 3.19504C9.97002 3.19504 7.79732 5.43587 7.79732 8.20317C7.79732 10.9705 9.97002 13.2113 12.6532 13.2113C15.3363 13.2113 17.509 10.9705 17.509 8.20317ZM20.2838 8.20317C20.2838 12.551 16.8688 16.0731 12.6532 16.0731C8.43755 16.0731 5.02254 12.551 5.02254 8.20317C5.02254 3.85535 8.43755 0.333252 12.6532 0.333252C16.8688 0.333252 20.2838 3.85535 20.2838 8.20317ZM2.94146 25.5783C2.94146 26.9282 2.83029 26.8048 3.57395 26.8048H22.4261C23.1697 26.8048 23.0586 26.9282 23.0586 25.5783C23.0586 22.3042 18.4746 20.3658 13 20.3658C7.52542 20.3658 2.94146 22.3042 2.94146 25.5783ZM0.166687 25.5783C0.166687 20.0261 6.13095 17.504 13 17.504C19.8691 17.504 25.8334 20.0261 25.8334 25.5783C25.8334 28.4636 24.7497 29.6666 22.4261 29.6666H3.57395C1.25039 29.6666 0.166687 28.4636 0.166687 25.5783Z"
									fill="#54A0FF"
								/>
							</svg>
						</div>
					)}
					<div className={style.detail}>
						<div className={style.main_info}>
							<div className={classNames(style.fio, "h3")}>
								{doctor.surname} {doctor.name} {doctor.middleName}
							</div>
							{speciality ? (
								<div
									className={classNames(
										style.speciality,
										style.row,
										style.without_hr,
										{
											[style.clicable]:
												doctor && doctor.specialities.length > 1,
										}
									)}
								>
									<p
										className={style.speciality_text}
										onClick={toggleSpecialitiesDropdown}
									>
										{speciality.name}
										{doctor && doctor.specialities.length > 1 && (
											<div
												className={classNames(style.speciality_expander, {
													[style.expanded]: specialityExpanded,
												})}
											>
												<svg
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M3.10547 6.44383C3.35742 6.18539 3.76591 6.18539 4.01786 6.44383L9.58317 12.1524L15.1485 6.44383C15.4004 6.18539 15.8089 6.18539 16.0609 6.44383C16.3128 6.70226 16.3128 7.12127 16.0609 7.3797L10.0394 13.5562C9.78742 13.8146 9.37892 13.8146 9.12697 13.5562L3.10547 7.3797C2.85352 7.12127 2.85352 6.70226 3.10547 6.44383Z"
														fill="#7A8CA4"
													/>
												</svg>
											</div>
										)}
										{specialityExpanded && (
											<Dropdown
												className={style.dropdown}
												expanded={specialityExpanded}
												setExpanded={setExpanded}
											>
												{doctor.specialities.map((speciality) => (
													<div
														key={`spec-${speciality.id}`}
														className={style.item}
														onClick={(e) => {
															setExpanded(false);
															updateSpeciality(speciality);
														}}
													>
														{speciality.name}
													</div>
												))}
											</Dropdown>
										)}
									</p>
								</div>
							) : (
								""
							)}
							<FavoriteButton
								isFavorite={isFavorite}
								onClick={favoriteAction}
							/>
						</div>
						{(!!doctor.rating ||
							!!doctor.experienceYearsAll ||
							!!doctor.minPrice) && (
							<div
								className={classNames(
									style.rating_info,
									style.row,
									style.without_hr
								)}
							>
								{!!doctor.rating && (
									<span className={style.rating}>
										<svg
											width="12"
											height="12"
											viewBox="0 0 12 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M2.93108 11.3321C2.85496 11.3321 2.77933 11.3091 2.7152 11.2603C2.60212 11.1732 2.54558 11.027 2.56908 10.8834L3.12273 7.50082L0.77792 5.10817C0.677813 5.00559 0.641833 4.85172 0.684911 4.71324C0.728234 4.5722 0.843026 4.47218 0.981316 4.45167L4.22194 3.95672L5.67117 0.88192C5.7331 0.748567 5.86062 0.666504 6.00037 0.666504C6.14013 0.666504 6.26765 0.748567 6.32958 0.88192L7.7788 3.95672L11.0192 4.45167C11.1575 4.47218 11.2725 4.5722 11.3156 4.71324C11.3589 4.85172 11.3229 5.00559 11.2226 5.10817L8.87802 7.50082L9.43143 10.8834C9.45492 11.027 9.39838 11.1732 9.2853 11.2603C9.17222 11.345 9.02268 11.3578 8.89858 11.2886L6.00037 9.69345L3.10192 11.2886C3.04832 11.3193 2.98958 11.3321 2.93108 11.3321Z"
												fill="#F7236F"
											/>
										</svg>
										&nbsp;
										{getValue(doctor.rating)}
									</span>
								)}
								{!!doctor.experienceYearsAll && (
									<span>
										{translate("appointment.experience", true)}:{" "}
										{getValue(doctor.experienceYearsAll, true)}{" "}
										{translate(yearsEnd(doctor.experienceYearsAll), true)}
									</span>
								)}
								{!!doctor.minPrice && (
									<span>
										{translate("appointment.minPrice", true)}:{" "}
										{getValue(doctor.minPrice, true)}{" "}
										{translate("app.currencySign", true)}
									</span>
								)}
							</div>
						)}
						{typeof doctor.experienceYearsAll === "number" && (
							<div className={classNames(style.row, style.str)}>
								<span>
									{translate("appointment.doctorModal.experienceYearsAll")}
								</span>
								<span>
									{doctor.experienceYearsAll}{" "}
									{translate(yearsEnd(doctor.experienceYearsAll))}
								</span>
							</div>
						)}
						{typeof doctor.experienceYearsClinic === "number" && (
							<div className={classNames(style.row, style.str)}>
								<span>
									{translate("appointment.doctorModal.experienceYearsClinic")}
								</span>
								<span>
									{doctor.experienceYearsClinic}{" "}
									{translate(yearsEnd(doctor.experienceYearsClinic))}
								</span>
							</div>
						)}
						{!!doctor.minPrice && (
							<div className={classNames(style.row, style.str)}>
								<span>{translate("appointment.doctorModal.minPrice")}</span>
								<span>
									{translate("app.from", true)}{" "}
									{getValue(doctor.minPrice, true)}{" "}
									{translate("app.currencySign", true)}
								</span>
							</div>
						)}
					</div>
				</div>
			)}

			{noticesD?.map(({ id, description }) => (
				<div key={id} style={{ paddingTop: 8 }}>
					<ProfileNotice
						isGrey
						important={translate(
							"history.assignment.endoscopyNotice.important",
							true
						)}
						title={<div dangerouslySetInnerHTML={{ __html: description }} />}
						titleClassName={style.notice_message_text}
					/>
				</div>
			))}

			{doctor && !error && (
				<div className={style.content_selector}>
					{Object.keys(content).map((key) => (
						<Button
							key={key}
							className={classNames(style.button, style.button_font)}
							secondary={key !== contentSelected}
							small
							onClick={() => setContentSelected(key)}
						>
							{translate(`appointment.doctorModal.${key.toLowerCase()}`)}
						</Button>
					))}
				</div>
			)}
			{doctor && !error && (
				<div className={classNames(style.content)}>
					{Component && (
						<Component
							doctorInfo={fullDoctorInfo}
							patient={patient}
							speciality={speciality}
							service={service}
							setService={setService}
							updateSpeciality={updateSpeciality}
							activeDate={activeDate}
							setActiveDate={handleChangeDate}
							isWidget={isWidget}
							{...doctor}
						/>
					)}
				</div>
			)}
			{error && (
				<div style={{ maxWidth: 350, margin: "0 auto" }}>
					<InfoBanner
						title={
							error?.data?.messages[0]?.clientMessage ||
							translate("app.somethingWhenWrong")
						}
						layout="vertical"
						theme="plain"
						renderIcon={() => <ErrorIcon />}
					/>
				</div>
			)}
		</div>
	);
}
