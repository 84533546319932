import { useState } from "react";
import { useTranslate } from "../../../../../../localization/translate";
import { CustomModal } from "../../../../../../components/custom-modal";
import Button from "../../../../../../components/button/button";
import { Stars } from "../../../../../appointment/modules/rate-visit/rate-visit";
import { useSupportRateMutation } from "../../../../services/profile.api";
import styles from "./style.module.css";

const Icon = () => (
	<svg
		width="81"
		height="81"
		viewBox="0 0 81 81"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="40.5" cy="40.5" r="40" fill="#54A0FF" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M63.4309 25.7779C64.9343 27.3965 64.8408 29.9275 63.2222 31.4309L37.3822 55.4309C35.8372 56.8658 33.4436 56.8551 31.9116 55.4062L19.7516 43.9062C18.1465 42.3883 18.0759 39.8566 19.5938 38.2516C21.1117 36.6465 23.6434 36.5759 25.2485 38.0938L34.6847 47.0179L57.7779 25.5692C59.3965 24.0658 61.9275 24.1592 63.4309 25.7779Z"
			fill="white"
		/>
	</svg>
);

export default function ReviewClosedRequest({ issueId, isOpen, handleClose }) {
	const translate = useTranslate();
	const [stars, setStars] = useState(0);
	const [errors, setErrors] = useState({});

	const [rate] = useSupportRateMutation();

	const submit = async () => {
		const _errors = {};

		if (!stars)
			_errors.rate = translate(
				"profile.support.reviewClosedModal.rateRequired"
			);

		if (Object.keys(_errors).length === 0) {
			setErrors({});

			await rate({
				issueId,
				rate: stars,
			});

			handleClose();
		} else {
			setErrors(_errors);
		}
	};

	return (
		<CustomModal isOpen={isOpen} onClose={handleClose}>
			<div className={styles.container}>
				<div className={styles.content}>
					<Icon />

					<div className={styles.title}>
						{translate("profile.support.reviewClosedModal.title")}
					</div>

					<div className={styles.description}>
						{translate("profile.support.reviewClosedModal.description")}
					</div>

					<Stars
						name={"requestReview"}
						count={stars}
						setCount={setStars}
						error={errors.rate}
					/>
				</div>

				<div className={styles.actions}>
					<Button onClick={submit} disabled={!stars}>
						{translate("profile.support.reviewClosedModal.review")}
					</Button>

					<Button light className={styles.close_btn} onClick={handleClose}>
						{translate("profile.support.reviewClosedModal.close")}
					</Button>
				</div>
			</div>
		</CustomModal>
	);
}
