import { useMediaQuery } from "beautiful-react-hooks";
import Modal from "../../../../components/modal-safari-wrapper/modal";
import arrow from "../../../../icons/arrow_down_24x24.svg";
import CloseModalButton from "../../../../components/modal-items/close";
import { useDispatch, useSelector } from "react-redux";
import {
	setAttachCardState,
	selectPatientSelectorState,
	setPatientSelectorState,
	setPatient,
} from "../../../profile/services/profile.slice";
import css from "./patient-selector.module.css";
import { useTranslate } from "../../../../localization/translate";
import checkIcon from "../../../../icons/check_icon_blue_18x15.svg";
import { useState } from "react";
import Button from "../../../../components/button/button";

export default function PatientModal({
	canAdd,
	profile,
	currentPatientCard,
	isWidget,
}) {
	const isMobile = useMediaQuery("(max-width: 620px)");
	const modalStyleMobile = {
		content: {
			display: "flex",
			justifyContent: "center",
			padding: "0",
			alignItems: "stretch",
		},
		overlay: {
			zIndex: 145,
			display: "flex",
			alignItems: isWidget ? "flex-start" : "center",
		},
	};
	const modalStyle = Object.assign({}, modalStyleMobile);
	modalStyle.content = {
		...modalStyleMobile.content,
		width: "455px",
		maxWidth: 483,
	};
	if (!isMobile) {
		modalStyle.content.minWidth = 484;
	}
	modalStyle.overlay = {
		...modalStyleMobile.overlay,
	};

	const dispatch = useDispatch();
	const onRequestClose = () => {
		dispatch(setPatientSelectorState(false));
		setUserSelected(currentPatientCard);
	};
	const onApply = () => {
		dispatch(
			setPatient(
				profile.attachedCards.find((card) => card.medCardNumber === userSelect)
			)
		);
		dispatch(setPatientSelectorState(false));
	};
	const translate = useTranslate();
	const isOpen = useSelector(selectPatientSelectorState);
	const [userSelect, setUserSelected] = useState(currentPatientCard);
	return (
		<Modal
			isOpen={isOpen}
			style={isMobile ? modalStyleMobile : modalStyle}
			onRequestClose={onRequestClose}
		>
			<CloseModalButton closeModal={onRequestClose} className={css.close} />
			<div className={css.modal_body}>
				<div className={css.modal_wrapper}>
					<div className={css.modal_title}>
						<div onClick={onApply} className={css.back}>
							<img src={arrow} />
						</div>
						{translate("appointment.confirm.patient")}
					</div>
					<div className={css.list}>
						<Item
							card={profile}
							selected={userSelect === profile.medCardNumber}
							setUserSelected={setUserSelected}
						/>
						{profile.attachedCards.map((card) => (
							<Item
								selected={userSelect == card.medCardNumber}
								key={`card-${card.medCardNumber}`}
								card={card}
								profile={profile}
								setUserSelected={setUserSelected}
							/>
						))}
					</div>
				</div>
				{canAdd && !isWidget && (
					<Button
						className={css.add_new_button}
						onClick={() => dispatch(setAttachCardState({ state: true }))}
					>
						{translate("profile.shareAccess.addNewPatient")}
					</Button>
				)}
			</div>
		</Modal>
	);
}

const Item = ({ card, profile, selected, setUserSelected }) => {
	const translate = useTranslate();

	return (
		<div
			className={css.row}
			onClick={() => setUserSelected(card.medCardNumber)}
		>
			{card.photo ? (
				<img src={card.photo} className={css.avatar} />
			) : (
				<div className={css.no_avatar}>
					{card.firstName && card.firstName[0].toUpperCase()}.{" "}
					{card.lastName && card.lastName[0].toUpperCase()}.
				</div>
			)}
			<div className={css.item_content}>
				<div className={css.info}>
					<div className={css.fio}>
						{profile ? (
							<>
								{card.firstName} {card.lastName}
							</>
						) : (
							translate("app.you")
						)}
					</div>
					<div className={css.card_number}>
						{translate("profile.personalData.medCardNumber")}: &nbsp;
						{card.medCardNumber}
					</div>
				</div>
				<div className={css.icon}>{selected && <img src={checkIcon} />}</div>
			</div>
		</div>
	);
};
