import css from "./share-access.module.css";
import Ricib from "../../../../components/ricib/ricib.v.0.1";
import { useTranslate } from "../../../../localization/translate";
import Loader from "../../../../components/loader";
import Timer from "../../../../components/timer/timer";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import md5 from "md5";
import { resetTimerByID } from "../../../../components/timer/timerSlice";

export default function Confirm({
	medCardNumber,
	setCode,
	request,
	isConfirming,
	setConfirmErrMsg,
	confirmErrMsg,
}) {
	const translate = useTranslate();
	const [resetRicib, setResetRicib] = useState(false);
	const dispatch = useDispatch();
	const [uid, setUID] = useState(md5(Math.random()));
	const START_TIMER = 120;

	useEffect(() => {
		/*
		 * Этот механизм необходим для того чтобы полностью "обнулить" поле <Ricib/>
		 * Компонент просто перерендироться полностью с нуля
		 * */
		if (resetRicib) {
			//если необходимо запросить новый код
			setConfirmErrMsg(null);
			request();
			setResetRicib(false);
		} else {
			dispatch(resetTimerByID(uid));
			setUID(md5(Math.random()));
		}
	}, [resetRicib]);

	return (
		<div className={css.modal_body}>
			<div className={css.modal_wrapper}>
				<div className={css.modal_title}>
					{translate("profile.shareAccess.confirmTitle", true)}
				</div>
				<div className={css.modal_description}>
					{translate("profile.shareAccess.confirmDescription")}
				</div>
				<div className={css.modal_description_text}>{medCardNumber}</div>
				<div className={css.modal_ricib}>
					<Ricib
						onComplete={(code) => {
							setCode(code);
						}}
						length={5}
						onChange={(code) => {
							setConfirmErrMsg(null);

							if (code.length < 5) {
								setCode(null);
							}
						}}
						errorMsg={confirmErrMsg}
					/>
				</div>
				{isConfirming ? (
					<div className={css.loader}>
						<Loader />
					</div>
				) : (
					<>
						<div className={css.timer_text}>
							<Timer
								uid={uid}
								start_timer={START_TIMER}
								timeIsOverString={
									<div
										onClick={() => setResetRicib(true)}
										className={css.request_again}
									>
										{translate("profile.shareAccess.requestAgain", true)}
									</div>
								}
								timerClass={css.timer}
								text={translate("login.sms.canRepeatAfter", true)}
							/>
						</div>
						<div className={css.do_not_receive_code}>
							{translate("login.sms.doNotReceiveCode")}
						</div>
						<div>{translate("login.sms.contactSupport")}</div>
					</>
				)}
			</div>
		</div>
	);
}
