import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "beautiful-react-hooks";
import { useTranslate } from "../../../../localization/translate";
import Loader from "../../../../components/loader";
import InfoBlock from "../../../../components/info-banner";
import NotificationsList from "../notifications-list";
import NotificationBlock from "../notification-block";
import usePagination from "../../hooks/usePagination";
import {
	useDeleteNotificationsMutation,
	useReadNotificationsMutation,
} from "../../services/notifications.api";
import ProfileNotice from "../../../profile/modules/personala-data/notice";
import styles from "./style.module.css";
import { NavLink } from "react-router-dom";

export default function Notifications() {
	const translate = useTranslate();
	const isTablet = useMediaQuery("(max-width: 1440px)");
	const dispatch = useDispatch();

	const [searchValue, setSearchValue] = useState("");
	const [selectedNotification, setSelectedNotification] = useState(null);

	const [read] = useReadNotificationsMutation();
	const [del] = useDeleteNotificationsMutation();

	const {
		collection,
		isEmpty,
		hasMore,
		isLoading,
		isFetching,
		handlePaginate,
		markReaded,
		deleteNotifications,
	} = usePagination(searchValue);

	const handleSelectNotification = (item) => setSelectedNotification(item);

	const handleRead = async (ids) => {
		const { data } = await read(ids);

		if (data) {
			markReaded(ids);

			dispatch({
				type: "profileApi/invalidateTags",
				payload: ["Profile"],
			});
		}
	};

	const handleDelete = async (ids) => {
		const { data } = await del(ids);

		if (data) {
			deleteNotifications(ids);
			setSelectedNotification(null);

			dispatch({
				type: "profileApi/invalidateTags",
				payload: ["Profile"],
			});
		}
	};

	return (
		<div className={styles.notifications}>
			<div className={styles.title}>{translate("notifications.title")}</div>

			{!isEmpty && (
				<div className={styles.content}>
					<div className={styles.collection}>
						<NotificationsList
							collection={collection}
							isTablet={isTablet}
							hasMore={hasMore || isFetching}
							searchValue={searchValue}
							selectedNotification={selectedNotification}
							onSelectNotification={handleSelectNotification}
							onSearchNotification={setSearchValue}
							onScrolledEnd={handlePaginate}
							onRead={handleRead}
							onDelete={handleDelete}
						/>
					</div>

					{!isTablet && selectedNotification && (
						<div className={styles.notification_block}>
							<NotificationBlock
								notification={selectedNotification}
								onDelete={handleDelete}
								onRead={handleRead}
							/>
						</div>
					)}
				</div>
			)}

			{isLoading && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}

			{!isLoading && isEmpty && (
				<>
					<InfoBlock
						title={translate("notifications.emptyList")}
						theme="plain"
						layout="vertical"
						renderIcon={() => (
							<svg
								width="81"
								height="80"
								viewBox="0 0 81 80"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect x="0.5" width="80" height="80" rx="40" fill="#EEF5FF" />
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M31.2716 28.0093C33.0535 25.8845 35.955 24 40.5 24C45.045 24 47.9465 25.8845 49.7284 28.0093C51.4761 30.0936 52.1335 32.3843 52.3406 33.2918C52.4178 33.6298 52.4389 33.956 52.4389 34.2513V37.6602C52.4389 38.5258 52.7763 39.3642 53.3444 40.2419C53.8225 40.9806 54.416 41.6793 55.0447 42.4195C55.1773 42.5757 55.3115 42.7337 55.4465 42.8943C56.0053 43.5589 56.4531 44.4018 56.4965 45.4065C56.5154 45.845 56.4566 46.291 56.3651 46.707C55.7887 49.3259 52.9643 50.5532 50.484 50.5532H30.516C28.0357 50.5532 25.2113 49.3259 24.6349 46.707C24.5434 46.291 24.4846 45.845 24.5035 45.4065C24.5469 44.4018 24.9947 43.5589 25.5535 42.8943C25.6885 42.7337 25.8227 42.5757 25.9553 42.4195C26.584 41.6793 27.1775 40.9806 27.6556 40.2419C28.2237 39.3642 28.5611 38.5258 28.5611 37.6602V34.2513C28.5611 33.956 28.5822 33.6298 28.6594 33.2918C28.8665 32.3843 29.5239 30.0936 31.2716 28.0093ZM33.043 29.2527C31.5877 30.9881 31.0211 32.937 30.8448 33.7093C30.8092 33.8654 30.7937 34.0419 30.7937 34.2513V37.6602C30.7937 39.0288 30.2559 40.2332 29.5766 41.2826C29.0196 42.1433 28.3214 42.9642 27.6911 43.7053C27.5652 43.8533 27.442 43.9982 27.3232 44.1395C26.9654 44.5651 26.7549 45.0118 26.7344 45.4871C26.7246 45.7137 26.7544 45.9896 26.8236 46.3038C27.1094 47.6025 28.6284 48.5106 30.516 48.5106H50.484C52.3716 48.5106 53.8906 47.6025 54.1764 46.3038C54.2456 45.9896 54.2754 45.7137 54.2656 45.4871C54.2451 45.0118 54.0346 44.5651 53.6768 44.1395C53.558 43.9982 53.4348 43.8533 53.309 43.7053C52.6786 42.9642 51.9805 42.1433 51.4234 41.2826C50.7441 40.2332 50.2063 39.0288 50.2063 37.6602V34.2513C50.2063 34.0419 50.1908 33.8654 50.1552 33.7093C49.9789 32.937 49.4123 30.9881 47.957 29.2527C46.5358 27.5578 44.255 26.0426 40.5 26.0426C36.745 26.0426 34.4642 27.5578 33.043 29.2527Z"
									fill="#54A0FF"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M33.4302 48.5106H47.5698V49.5319H46.4535C47.5698 49.5319 47.5698 49.5319 47.5698 49.5319L47.5698 49.5369L47.5697 49.544L47.5694 49.564C47.5692 49.58 47.5686 49.6011 47.5677 49.6271C47.5658 49.679 47.5622 49.7505 47.5553 49.8387C47.5416 50.0148 47.5146 50.2597 47.4616 50.5509C47.3564 51.1284 47.1439 51.914 46.7077 52.712C46.2694 53.5141 45.5944 54.3471 44.5611 54.9774C43.5207 55.612 42.1837 56 40.5 56C38.8163 56 37.4793 55.612 36.4389 54.9774C35.4056 54.3471 34.7306 53.5141 34.2923 52.712C33.8561 51.914 33.6437 51.1284 33.5384 50.5509C33.4854 50.2597 33.4584 50.0148 33.4447 49.8387C33.4378 49.7505 33.4342 49.679 33.4323 49.6271C33.4314 49.6011 33.4308 49.58 33.4306 49.564L33.4303 49.544L33.4302 49.5369L33.4302 49.5342C33.4302 49.5342 33.4302 49.5319 34.5465 49.5319H33.4302V48.5106ZM35.8129 50.5532C35.906 50.9329 36.0545 51.3693 36.2891 51.7986C36.595 52.3583 37.0363 52.8869 37.6773 53.2779C38.3114 53.6646 39.207 53.9574 40.5 53.9574C41.793 53.9574 42.6886 53.6646 43.3227 53.2779C43.9637 52.8869 44.405 52.3583 44.7109 51.7986C44.9455 51.3693 45.094 50.9329 45.1872 50.5532H35.8129Z"
									fill="#54A0FF"
								/>
							</svg>
						)}
					/>
					<div className={styles.notice}>
						<ProfileNotice
							message={
								<span className={styles.notice_text}>
									{translate("notifications.check")}
									&nbsp;
									<NavLink to="/profile/notifications" className={styles.link}>
										{translate("notifications.settings")}
									</NavLink>
									&nbsp;
									{translate("notifications.notificationsText")}
								</span>
							}
							isGrey
						/>
					</div>
				</>
			)}
		</div>
	);
}
