import css from "./list-layout.module.css";
import { useMediaQuery } from "beautiful-react-hooks";

export default function EventsListLayout({
	navigate,
	medication,
	children,
	selected,
	qr,
	filters,
	noRatingAppointment,
}) {
	const isBigDesktop = useMediaQuery("(min-width: 1440px)");

	return (
		<div className={css.layout}>
			{!isBigDesktop && medication && (
				<div className={css.layout_med}>{medication}</div>
			)}

			{!isBigDesktop && <div className={css.layout__navigate}>{navigate}</div>}

			<div className={css.layout__wrapper}>
				<div className={css.layout__container}>
					{(filters || navigate) && isBigDesktop && (
						<div className={css.layout__containerFilters}>
							{isBigDesktop && medication && (
								<div className={css.layout_med}>{medication}</div>
							)}

							{isBigDesktop && navigate && (
								<div className={css.layout__navigate}>{navigate}</div>
							)}
							{filters}
						</div>
					)}

					{noRatingAppointment}

					{qr && <div className={css.layout__container_qr}>{qr}</div>}

					{((!selected && !isBigDesktop) || isBigDesktop) && (
						<div className={css.layout__containerList}>{children}</div>
					)}
				</div>

				{selected && <div className={css.layout__selected}>{selected}</div>}
			</div>
		</div>
	);
}
