import { useState } from "react";
import classNames from "classnames";
import ReactSwitch from "react-switch";
import { useTranslate } from "../../../../../../localization/translate";
import { ContentModal } from "../../../../../../components/custom-modal/content.modal";
import Checkbox from "../../../../../../components/input/checkbox/Checkbox";
import Button from "../../../../../../components/button/button";
import Loader from "../../../../../../components/loader";
import InfoBanner from "../../../../../../components/info-banner";
import Textarea from "../../../../../../components/input/Textarea/textarea";
import { useServiceReviewMutation } from "../../../../services/profile.api";
import Agreements from "../../../../../agreements/agreements";
import { CODES_ENUM } from "../../../../../agreements/constants";
import styles from "./style.module.css";

export default function ReviewModal({ isOpen, handleClose }) {
	const translate = useTranslate();
	const [type, setType] = useState(null);
	const [comment, setComment] = useState("");
	const [publicationAcception, setPublicationAcception] = useState(false);
	const [privacyPolicyAcception, setPrivacyPolicyAcception] = useState(false);
	const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState(false);

	const handleRequestClose = () => {
		setComment("");
		setPublicationAcception(false);
		setPrivacyPolicyAcception(false);
		setSuccess(false);
		setErrors({});
		handleClose();
	};

	const [review, { isLoading }] = useServiceReviewMutation();

	const handleAgreement = (type) => {
		if ([CODES_ENUM.PD_POLICY].includes(type)) {
			setPrivacyPolicyAcception(true);
		}
		setType(null);
	};

	const submit = async () => {
		const newErrors = {};

		if (!publicationAcception) {
			newErrors.publication_acception = translate(
				"profile.personalData.checkboxError",
				true
			);
		}
		if (!privacyPolicyAcception) {
			newErrors.privacy_policy_acception = translate(
				"profile.personalData.checkboxError",
				true
			);
		}
		if (!comment) {
			newErrors.comment = translate(
				"profile.support.reviewModal.commentRequired",
				true
			);
		}

		if (Object.keys(newErrors).length === 0) {
			setErrors({});

			const { error } = await review({
				comment,
				privacyPolicyAcception,
				publicationAcception,
				anonymous: false,
			});

			if (!error) {
				setSuccess(true);
			}
		} else {
			setErrors(newErrors);
		}
	};

	return (
		<ContentModal
			styles={{
				overlay: {
					zIndex: 135,
				},
			}}
			isOpen={isOpen}
			closeModal={handleRequestClose}
		>
			{!success && (
				<div className={styles.container}>
					<div className={styles.title}>
						{translate("profile.support.reviewModal.title")}
					</div>
					<div className={classNames("text", styles.subtitle)}>
						{translate("profile.support.reviewModal.description")}
					</div>

					<Textarea
						error={errors.comment}
						value={comment}
						onChange={setComment}
						placeholder={translate(
							"profile.support.reviewModal.textareaPlaceholder",
							true
						)}
						className={styles.textarea}
					/>

					<div className={styles.checkboxes}>
						<div className={styles.checkbox}>
							<Checkbox
								label={
									<>
										{translate("appointment.rate.agreePDA")}{" "}
										<a
											style={{ cursor: "pointer" }}
											onClick={() => setType(CODES_ENUM.PD_POLICY)}
										>
											{translate("appointment.rate.pda")}
										</a>
									</>
								}
								onChange={setPrivacyPolicyAcception}
								checked={privacyPolicyAcception}
								error={errors.privacy_policy_acception}
							/>
						</div>

						<div className={styles.checkbox}>
							<Checkbox
								label={<>{translate("appointment.rate.iAmAgree")}</>}
								onChange={setPublicationAcception}
								checked={publicationAcception}
								error={errors.publication_acception}
							/>
						</div>
					</div>

					{(errors.doctor || errors.anonymous) && (
						<div
							className={styles.error}
							style={{ transform: "none", bottom: "auto" }}
						>
							{translate("app.tryLater", true)}
						</div>
					)}

					{errors.slot_id_mis && (
						<div
							className={styles.error}
							style={{
								position: "relative",
								transform: "none",
								bottom: "auto",
							}}
						>
							{errors.slot_id_mis}
						</div>
					)}

					<Button
						className={styles.button}
						onClick={submit}
						disabled={isLoading}
					>
						{isLoading ? (
							<Loader
								containerStyle={{ margin: "-8px 0" }}
								width={30}
								height={30}
							/>
						) : (
							translate("appointment.rate.title", true)
						)}
					</Button>

					<Agreements
						isOpen={!!type}
						type={type}
						onClose={() => setType(null)}
						onAgree={handleAgreement}
					/>
				</div>
			)}

			{success && (
				<div className={styles.success}>
					<InfoBanner
						title={translate("profile.support.reviewModal.success")}
						theme="plain"
						layout="vertical"
						renderIcon={() => (
							<svg
								width="81"
								height="81"
								viewBox="0 0 81 81"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="40.5" cy="40.5" r="40" fill="#54A0FF" />
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M63.4309 25.7779C64.9343 27.3965 64.8408 29.9275 63.2222 31.4309L37.3822 55.4309C35.8372 56.8658 33.4436 56.8551 31.9116 55.4062L19.7516 43.9062C18.1465 42.3883 18.0759 39.8566 19.5938 38.2516C21.1117 36.6465 23.6434 36.5759 25.2485 38.0938L34.6847 47.0179L57.7779 25.5692C59.3965 24.0658 61.9275 24.1592 63.4309 25.7779Z"
									fill="white"
								/>
							</svg>
						)}
					/>

					<Button className={styles.back_btn} onClick={handleRequestClose}>
						{translate("profile.support.reviewModal.back")}
					</Button>
				</div>
			)}
		</ContentModal>
	);
}
