import { ContentModal } from "../../../../components/custom-modal/content.modal";
import style from "./rate-visit.module.css";
import { useTranslate } from "../../../../localization/translate";
import classNames from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";
import emptyStar from "../../../../icons/empty-star.svg";
import filledStar from "../../../../icons/filled-star.svg";
import Checkbox from "../../../../components/input/checkbox/Checkbox";
import ReactSwitch from "react-switch";
import Button from "../../../../components/button/button";
import { useSubmitReviewMutation } from "../../services/appointment.api";
import Loader from "../../../../components/loader";
import { getErrorMessageFromError } from "../../../../app/error-handler.api";
import ModalInfo from "../../../../components/custom-modal/info";
import Textarea from "../../../../components/input/Textarea/textarea";
import { moscowTimeZone } from "../../../../utils/dates/timezone-converter";
import Agreements from "../../../agreements/agreements";
import { CODES_ENUM } from "../../../agreements/constants";
import { useDispatch } from "react-redux";
import { Metrics } from "../../../../utils/metrics";
import { setRateInfo } from "../../services/appointment.slice";
moment.locale("ru");

export default function RateVisit({ rateInfo, clearRateInfo }) {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const [serviceRate, setServiceRate] = useState(0);
	const [doctorRate, setDoctorRate] = useState(rateInfo?.rating || 0);
	const [comment, setComment] = useState("");
	const [publicationAcception, setPublicationAcception] = useState(false);
	const [privacyPolicyAcception, setPrivacyPolicyAcception] = useState(false);
	const [anonymous, setAnonymous] = useState(false);
	const [submitReview, { data, isLoading, error, reset }] =
		useSubmitReviewMutation();
	const [errors, setErrors] = useState({});
	const [type, setType] = useState(null);

	const onCloseSuccess = () => {
		setServiceRate(0);
		setDoctorRate(0);
		setComment("");
		setPrivacyPolicyAcception(false);
		setPublicationAcception(false);
		dispatch(setRateInfo(null));
		reset();
	};

	useEffect(() => {
		if (error) {
			const clarifications = getErrorMessageFromError({
				fromClarifications: true,
				error,
			});
			const newErrors = {};
			clarifications.forEach(
				(err) =>
					(newErrors[err.target] = err.message
						? err.message
						: translate("app.forms.required", true))
			);

			setErrors(newErrors);
		}
	}, [error]);

	useEffect(() => {
		if (data) {
			Metrics.gtmEvent("add_review", {
				doctorId: rateInfo.doctorId,
				rating_doctor: doctorRate,
				rating_service: serviceRate,
			});

			clearRateInfo();
		}
	}, [data]);

	useEffect(() => {
		if (rateInfo && rateInfo.rating) {
			setDoctorRate(rateInfo.rating);
		}

		if (!rateInfo) {
			setServiceRate(0);
		}
	}, [rateInfo]);

	if (data) {
		return <Success open={!!data} onClose={onCloseSuccess} />;
	}

	if (!rateInfo) return null;

	const result = {
		slotIdMis: rateInfo.slotIdMis,
		doctor: rateInfo.doctorId,
		doctorRate,
		serviceRate,
		comment,
		publicationAcception,
		privacyPolicyAcception,
		anonymous,
		appointmentDateTime: rateInfo.date
	};

	const submit = () => {
		const newErrors = {};

		if (!publicationAcception) {
			newErrors.publication_acception = translate(
				"profile.personalData.checkboxError",
				true
			);
		}
		if (!privacyPolicyAcception) {
			newErrors.privacy_policy_acception = translate(
				"profile.personalData.checkboxError",
				true
			);
		}

		if (Object.keys(newErrors).length === 0) {
			setErrors({});
			submitReview(result).then(() =>
				dispatch({
					type: "eventsApi/invalidateTags",
					payload: ["History", "Planned"],
				})
			);
		} else {
			setErrors(newErrors);
		}
	};

	const handleAgreement = (type) => {
		if ([CODES_ENUM.PD_POLICY].includes(type)) {
			setPrivacyPolicyAcception(true);
		}
		setType(null);
	};

	return (
		<ContentModal
			styles={{
				overlay: {
					zIndex: 135,
					// alignItems: "baseline"
				},
			}}
			isOpen={!!rateInfo}
			closeModal={clearRateInfo}
		>
			<div className={style.container}>
				<div className={style.title}>{translate("appointment.rate.title")}</div>
				<div className={classNames("text", style.subtitle)}>
					{translate("appointment.rate.subtitle")}
					&nbsp;
					{moment(rateInfo.date).format("D MMMM")}
					{/*convertDate()*/}
					&nbsp;
					{translate("appointment.confirm.atTheTime")}
					&nbsp;
					{moment(rateInfo.date).format("HH:mm")}{" "}
					{moscowTimeZone() ? "" : translate("app.byMskTime")}
					{/*convertDate()*/}
				</div>
				<div className={style.label}>
					{translate("appointment.rate.rateDoctor")}
				</div>
				<Stars
					name={"doctorRate"}
					count={doctorRate}
					setCount={setDoctorRate}
					error={errors.doctor_rate}
				/>
				<div className={style.label}>
					{translate("appointment.rate.rateClinicService")}
				</div>
				<Stars
					name={"serviceRate"}
					count={serviceRate}
					setCount={setServiceRate}
					error={errors.service_rate}
				/>
				<Textarea
					error={errors.comment}
					value={comment}
					onChange={setComment}
					placeholder={translate("appointment.rate.howWasIt", true)}
					className={style.textarea}
					specialLabelClassName={style.textareaLabel}
				/>
				<div className={style.checkboxes}>
					<div className={style.checkbox}>
						<Checkbox
							label={
								<>
									{translate("appointment.rate.agreePDA")}
									<a
										style={{ cursor: "pointer" }}
										onClick={() => setType(CODES_ENUM.PD_POLICY)}
									>
										{translate("appointment.rate.pda")}
									</a>
								</>
							}
							onChange={setPrivacyPolicyAcception}
							checked={privacyPolicyAcception}
							error={errors.privacy_policy_acception}
						/>
					</div>
					<div className={style.checkbox}>
						<Checkbox
							label={<>{translate("appointment.rate.iAmAgree")}</>}
							onChange={setPublicationAcception}
							checked={publicationAcception}
							error={errors.publication_acception}
						/>
					</div>
				</div>
				<div className={classNames("text", style.is_anon)}>
					{translate("appointment.rate.isAnon")}
					<ReactSwitch
						onChange={setAnonymous}
						handleDiameter={18}
						offColor="#D4DDE7"
						onColor="#54A0FF"
						height={22}
						width={36}
						uncheckedIcon={false}
						checkedIcon={false}
						checked={anonymous}
					/>
				</div>
				{(errors.doctor || errors.anonymous) && (
					<div
						className={style.error}
						style={{ transform: "none", bottom: "auto" }}
					>
						{translate("app.tryLater", true)}
					</div>
				)}
				{errors.slot_id_mis && (
					<div
						className={style.error}
						style={{ position: "relative", transform: "none", bottom: "auto" }}
					>
						{errors.slot_id_mis}
					</div>
				)}
				<Button className={style.button} onClick={submit} disabled={isLoading}>
					{isLoading ? (
						<Loader
							containerStyle={{ margin: "-8px 0" }}
							width={30}
							height={30}
						/>
					) : (
						translate("appointment.rate.title", true)
					)}
				</Button>
				<Agreements
					isOpen={!!type}
					type={type}
					onClose={() => setType(null)}
					onAgree={handleAgreement}
				/>
			</div>
		</ContentModal>
	);
}

export const Stars = ({
	count = 0,
	name,
	setCount,
	error,
	containerClass,
	starClass,
}) => {
	const stars = new Array(5).fill("");

	const handleClick = (event, count) => {
		event.stopPropagation();
		setCount?.(count);
	};

	return (
		<div className={classNames(style.stars, containerClass)}>
			{stars.map((star, i) => (
				<div
					key={`key-${name} ${i}`}
					onClick={(event) => handleClick(event, i + 1 === count ? 0 : i + 1)}
					className={classNames(style.star, starClass)}
				>
					{count < i + 1 ? (
						<img src={emptyStar} alt="" />
					) : (
						<img src={filledStar} alt="" />
					)}
				</div>
			))}
			{error && <div className={style.error}>{error}</div>}
		</div>
	);
};

const Success = ({ open, onClose }) => {
	const [isOpened, setOpened] = useState(open);
	const translate = useTranslate();

	useEffect(() => {
		setOpened(open);
	}, [open]);

	const handleClose = () => {
		setOpened(false);
		onClose();
	};

	return (
		<ModalInfo
			iconType={"success"}
			isOpen={isOpened}
			modalStyle={{
				overlay: {
					display: "flex",
					alignItems: "center",
				},
			}}
			subtitle={translate("appointment.rate.thankForReview")}
			onRequestClose={handleClose}
		>
			<Button onClick={handleClose} className={style.success_button}>
				{translate("appointment.rate.back", true)}
			</Button>
		</ModalInfo>
	);
};
