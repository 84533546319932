import { useParams } from "react-router-dom";
import { useGetAccountDetalizationQuery } from "../../services/profile.api";
import css from "./accounts.module.css";
import classNames from "classnames";
import Loader from "../../../../components/loader";
import { useTranslate } from "../../../../localization/translate";
import { getErrorMessageFromError } from "../../../../app/error-handler.api";
import arrow from "../../../../icons/arrow_down_24x24.svg";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { pushSystemNotification } from "../../../../app/app.slice";
import { useDispatch } from "react-redux";
import styles from "../../../events/modules/history/history.module.css";
import Button from "../../../../components/button/button";
import Dropdown from "../../../../components/dropdown/dropdown";
import DatePicker from "../../../../components/datepicker/datepicker";
import header from "../../../history/modules/receptions-history/header.module.css";
import Modal from "../../../../components/modal-safari-wrapper/modal";
import CloseModalButton from "../../../../components/modal-items/close";
import { useMediaQuery } from "beautiful-react-hooks";
import { fileViewer } from "../../../../utils";

moment.locale("ru");

export default function Detalization() {
	const params = useParams();
	const { data, isLoading, error } = useGetAccountDetalizationQuery(params.id);
	const [selectedPdf, setSelectedPdf] = useState();
	const translate = useTranslate();
	const [pdfLoading, setPdfLoading] = useState(false);
	const dispatch = useDispatch();
	const [dateFilterOpened, setOpened] = useState(false);
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;
	const [defaultDates, setMarkedDates] = useState();
	const isMobile = useMediaQuery("(max-width: 620px)");

	useEffect(() => {
		if (data) {
			setMarkedDates(
				data.operations.map((item) => moment(item.date).format("YYYY-MM-DD"))
			);
		}
	}, [data]);

	useEffect(() => {
		if (selectedPdf) {
			setPdfLoading(true);
			const success = () => {
				setSelectedPdf(null);
				setPdfLoading(null);
			};
			const failure = (e) => {
				let message;
				if (typeof e === "string") {
					message = e;
				} else {
					message =
						e.messages && e.messages.length > 0 && e.messages[0].clientMessage
							? e.messages[0].clientMessage
							: translate("app.failedToFetch", true);
				}

				dispatch(
					pushSystemNotification({
						type: "error",
						clientMessage: message,
					})
				);
				setPdfLoading(null);
				setSelectedPdf(null);
			};

			fileViewer(selectedPdf, success, failure);
		}
	}, [selectedPdf]);

	if (isLoading && !error) {
		return (
			<div className={css.wrapper}>
				<div className={classNames("h3", css.h3)}>
					{translate("profile.bills.paymentLog")}
				</div>
				<div className={css.loader}>
					<Loader />
				</div>
			</div>
		);
	}

	if (error) {
		const errMsg = getErrorMessageFromError({ error });

		return (
			<div className={css.wrapper}>
				<div className={classNames("h3", css.h3)}>
					{translate("profile.bills.paymentLog")}
				</div>
				{errMsg ? errMsg.msg : "Failed to fetch"}
			</div>
		);
	}

	const filter = (arr) =>
		arr.filter((item) => {
			let res = true;

			if (startDate && endDate) {
				// если выбран диапазон дат

				if (
					(moment(item.date).isSame(startDate) ||
						moment(item.date).isAfter(startDate)) &&
					(moment(item.date).isSame(
						endDate.hours(23).minutes(59).seconds(59)
					) ||
						moment(item.date).isBefore(
							endDate.hours(23).minutes(59).seconds(59)
						))
				) {
					res = true;
				} else {
					res = false;
				}
			}

			return res;
		});

	return (
		<div className={css.wrapper}>
			<div className={classNames("h3", css.h3)}>
				{translate("profile.bills.paymentLog")}
			</div>
			<div className={css.filters}>
				<Button
					className={css.filter_button}
					onClick={() => setDateRange([null, null])}
					secondary={startDate || endDate}
				>
					{translate("app.allDates")}
				</Button>
				<Button
					secondary={!startDate}
					onClick={() => {
						setOpened(!dateFilterOpened);
					}}
					className={css.filter_button}
				>
					{startDate && endDate ? (
						<>
							{moment(startDate).format("DD.MM")}
							&nbsp;-&nbsp;
							{moment(endDate).format("DD.MM.YYYY")}
							&nbsp;
						</>
					) : (
						translate("history.list.changeDateRange", true)
					)}
					<div className={css.datepicker_expander}>
						{startDate ? (
							<svg
								width="15"
								height="8"
								viewBox="0 0 15 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M1.10547 0.443827C1.35742 0.185392 1.76591 0.185392 2.01786 0.443827L7.58317 6.15236L13.1485 0.443827C13.4004 0.185392 13.8089 0.185392 14.0609 0.443827C14.3128 0.702262 14.3128 1.12127 14.0609 1.3797L8.03937 7.55617C7.78742 7.81461 7.37892 7.81461 7.12697 7.55617L1.10547 1.3797C0.853516 1.12127 0.853516 0.702262 1.10547 0.443827Z"
									fill="white"
								/>
							</svg>
						) : (
							<svg
								width="15"
								height="8"
								viewBox="0 0 15 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M1.10547 0.443827C1.35742 0.185392 1.76591 0.185392 2.01786 0.443827L7.58317 6.15236L13.1485 0.443827C13.4004 0.185392 13.8089 0.185392 14.0609 0.443827C14.3128 0.702262 14.3128 1.12127 14.0609 1.3797L8.03937 7.55617C7.78742 7.81461 7.37892 7.81461 7.12697 7.55617L1.10547 1.3797C0.853516 1.12127 0.853516 0.702262 1.10547 0.443827Z"
									fill="#7A8CA4"
								/>
							</svg>
						)}
					</div>
				</Button>
				{!isMobile && (
					<Dropdown
						expanded={dateFilterOpened}
						closeBtn
						title={translate("history.list.period", true)}
						className={styles.calendar}
						setExpanded={setOpened}
					>
						<DatePicker
							isHolidayActive
							maxDate={moment()}
							markedDates={defaultDates}
							setDateRange={(arg) => {
								setDateRange(arg);
								setOpened(false);
							}}
							dateRange={dateRange}
							openState={setOpened}
						/>
					</Dropdown>
				)}
			</div>
			{filter(data.operations).map((item) => (
				<div
					className={classNames(css.row, css.log, {
						[css.not_active]:
							!item.billUrl || (pdfLoading && item.billUrl === selectedPdf),
					})}
					onClick={() => !pdfLoading && setSelectedPdf(item.billUrl)}
					key={`operation-${item.billUrl}-${item.date}`}
				>
					<div className={css.info}>
						<div className={css.account}>
							{item.type === "accrual" && "+"}
							{item.type === "deduction" && "-"}
							&nbsp;
							{item.amount.toLocaleString()}
							&nbsp;
							{translate("app.currencySign")}
						</div>
						<div className={css.account_info}>
							<span>{moment(item.date).format("D MMMM YYYY, HH:mm")}</span>
							<br />
							<span>{item.paymentSource}</span>
						</div>
					</div>
					{(!pdfLoading || (pdfLoading && item.billUrl !== selectedPdf)) &&
						item.billUrl && (
							<div className={css.arrow}>
								<img src={arrow} alt="" />
							</div>
						)}
					{pdfLoading && item.billUrl === selectedPdf && (
						<Loader height={24} width={24} />
					)}
				</div>
			))}
			{isMobile && (
				<Modal
					isOpen={dateFilterOpened}
					// className={modalClassName}
					onRequestClose={() => setOpened(false)}
					// style={modalStyle}
				>
					<CloseModalButton closeModal={() => setOpened(false)} />
					<div className={header.date_picker_title}>
						{translate("history.list.period", true)}
					</div>
					<DatePicker
						isHolidayActive
						maxDate={moment()}
						markedDates={defaultDates}
						setDateRange={(arg) => {
							setDateRange(arg);
							setOpened(false);
						}}
						dateRange={dateRange}
						openState={dateFilterOpened}
					/>
				</Modal>
			)}
		</div>
	);
}
