import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	createModalOpened: false,
	editModalOpened: false, //РЕдактирование личных данных юзера
	shortCodeEditOpened: false,
	attachCardIsOpen: false, //Открыто ли окно прикрепления карты
	selectedCardToAttach: null, //Здесь будет карта, которая будет подставлена в инпут в модалке при ее открытии, если пользователь вызывал окно прикрепления с карты просмотра наблюдаещей карты
	linkButton: false, //Если параметр false, то кнопка привязки нового пациента отстутствует
	selectedPatient: null, //Копия объекта профиля выбранного пациента. Если null, то текущий пациент = профиль
	patientSelectorIsOpen: false,
	accountInfo: null, //Просмотр деталей счета,
	accountReplenish: null, //Счет, выбранный для пополнения
	addCardOpen: false, //Окно добавления карты. Может использоваться во многих местах
	cardInfo: null, //Просмтр деталей карты
	counters: null,
};

export const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		setCreateModalState: (state, action) => {
			state.createModalOpened = action.payload;
		},
		setPatient: (state, action) => {
			state.selectedPatient = action.payload;
		},
		setEditModalState: (state, action) => {
			state.editModalOpened = action.payload;
		},
		setShortCodeEditOpened: (state, action) => {
			state.shortCodeEditOpened = action.payload;
		},
		allowAddPatient: (state) => {
			state.linkButton = true;
		},
		setPatientSelectorState: (state, action) => {
			if (action.payload === false) {
				state.linkButton = false;
			}

			state.patientSelectorIsOpen = action.payload;
		},
		setAttachCardState: (state, action) => {
			state.selectedCardToAttach = action.payload.savedCard;

			if (action.payload.state === false) {
				state.selectedCardToAttach = null;
			}

			state.attachCardIsOpen = action.payload.state;
		},
		setAccountData: (state, action) => {
			if (state.hasOwnProperty(action.payload.key)) {
				state[action.payload.key] = action.payload.value;
			}
		},
		setCounters: (state, action) => {
			state.counters = action.payload;
		},
	},
});

export const {
	setAccountData,
	setCreateModalState,
	setAttachCardState,
	setPatient,
	allowAddPatient,
	setShortCodeEditOpened,
	setEditModalState,
	setPatientSelectorState,
	setCounters,
} = profileSlice.actions;

export const linkButton = (state) => state.profile.linkButton;
export const selectCreateModalOpened = (state) =>
	state.profile.createModalOpened;
export const selectEditModalOpened = (state) => state.profile.editModalOpened;
export const selectShortCodeEditOpened = (state) =>
	state.profile.shortCodeEditOpened;
export const selectPatient = (state) => state.profile.selectedPatient;
export const selectPatientSelectorState = (state) =>
	state.profile.patientSelectorIsOpen;
export const attachCardIsOpen = (state) => state.profile.attachCardIsOpen;
export const savedCard = (state) => state.profile.selectedCardToAttach;
export const selectedCard = (state) => state.profile.cardInfo;
export const addCardState = (state) => state.profile.addCardOpen;
export const selectedAccount = (state) => state.profile.accountInfo;
export const accountToReplenish = (state) => state.profile.accountReplenish;
export const selectCounters = (state) => state.profile.counters;

export default profileSlice;
