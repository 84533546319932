import { useMemo } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useTranslate } from "../../../../localization/translate";
import { useGetQrQuery } from "../../services/profile.api";
import Loader from "../../../../components/loader";
import ProfileNotice from "../../../profile/modules/personala-data/notice";
import { setCreateModalState } from "../../../profile/services/profile.slice";
import QrCode from "./components/qr-code";
import styles from "./styles.module.css";

export default function QrCodePage() {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const { data, isLoading } = useGetQrQuery();

	const { qr, messages } = useMemo(
		() => ({ qr: data?.data, messages: data?.messages }),
		[data]
	);

	return (
		<div className={styles.qr}>
			<div className={classNames("h3", styles.title)}>
				{translate("profile.qr.title")}
			</div>

			{qr && (
				<div className={styles.content}>
					<QrCode
						uid={qr.qrCode}
						description={translate("profile.qr.description")}
						downloadable
					/>
				</div>
			)}

			{isLoading && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}

			{messages?.length > 0 && (
				<ProfileNotice
					isGrey
					title={translate("profile.personalData.hasNoMedCard", true)}
					message={translate(
						"profile.personalData.hasNoMedCardDescription",
						true
					)}
					btn={{
						onClick: () => dispatch(setCreateModalState(true)),
						text: translate("profile.personalData.specify", true),
					}}
				/>
			)}
		</div>
	);
}
