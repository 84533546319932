import styles from "./TextInput.module.css";
import classNames from "classnames";
import { useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";

export default function ({ props }) {
	const {
		autofocus = false,
		placeholder = "",
		containerClass,
		errorMsg,
		onBlur,
		placeholderWhenFocused = null,
		onFocus,
		maxlength = 255,
		onChange,
		name,
		label,
		id,
		ref,
		onKeyDown = () => {},
		onKeyUp = () => {},
		value = "",
		className,
		isPassword,
		type = "text",
		autocapitalize = "on",
		autocomplete = null,
		inputMode = null,
		disabled = false,
	} = props;
	const inputAttrs = {
		...(inputMode && { inputMode }),
		...(disabled && { disabled }),
		...(autocomplete && { autocomplete }),
	};

	const [isTyping, setIsTyping] = useState(false);
	const containerClassnames = classNames(styles.container, containerClass, {
		"invalid-value": errorMsg && value.length > 0,
		"typing-value": isTyping && !errorMsg,
	});
	const inputClassNames = classNames(
		{
			[styles.initial]: !isTyping && value.length === 0,
		},
		"form-control",
		styles.text_input,
		className
	);
	const [passwordIsVisible, setPasswordIsVisible] = useState(false);
	const textInput = useRef();

	function typeSwitcherClick() {
		textInput.current.focus();
	}

	const TypeSwitcher = () => {
		if (type === "password" || isPassword) {
			const switcherClasses = classNames(styles.type_switcher, {
				[styles.invisible]: !passwordIsVisible,
				[styles.visible]: passwordIsVisible,
			});
			return (
				<div
					className={switcherClasses}
					role="button"
					tabIndex={-1}
					onMouseUp={() => {
						setPasswordIsVisible(!passwordIsVisible);
						typeSwitcherClick();
					}}
				/>
			);
		} else return null;
	};

	const getTypeStr = () => {
		if (type === "password" || isPassword) {
			return passwordIsVisible ? "text" : "password";
		} else return type;
	};
	const [inputPlaceholder, setPlaceholder] = useState(placeholder);

	return (
		<div className={containerClassnames}>
			{label && <label htmlFor={name}>{label}</label>}
			<div className="special-label">
				{value.length !== 0 || isTyping ? placeholder : ""}
			</div>
			<input
				autoFocus={autofocus}
				onFocus={(e) => {
					if (placeholderWhenFocused) {
						setPlaceholder(placeholderWhenFocused);
					}
					setIsTyping(true);
					onFocus && onFocus(e.target.value);
				}}
				onBlur={(e) => {
					if (placeholderWhenFocused) {
						setPlaceholder(placeholder);
					}
					setIsTyping(false);
					onBlur && onBlur(e.target.value);
				}}
				name={name}
				id={id}
				ref={textInput}
				maxLength={maxlength}
				value={value}
				onKeyDown={onKeyDown}
				onKeyUp={onKeyUp}
				onChange={(e) => onChange && onChange(e.target.value)}
				type={getTypeStr()}
				placeholder={inputPlaceholder}
				className={inputClassNames}
				autoCapitalize={autocapitalize}
				{...inputAttrs}
			/>
			<TypeSwitcher />
			{errorMsg && <div className={styles.error_message}>{errorMsg}</div>}
		</div>
	);
}
