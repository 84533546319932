import styles from "./index.module.css";
import cn from 'classnames'

export default function Loader({
	width = 40,
	height = 40,
	white = false,
	containerStyle = {},
	className
}) {
	return (
		<div className={cn(styles.rotating, className)} style={containerStyle}>
			<img
				src={white ? "/images/loader-white.svg" : "/images/loader.svg"}
				style={{ width, height }}
			/>
		</div>
	);
}
