import { useTranslate } from "../../../../localization/translate";
import css from "./share-access.module.css";
import classNames from "classnames";
import Button from "../../../../components/button/button";
import arrow from "../../../../icons/arrow_down_24x24.svg";
import clock from "../../../../icons/clock_blue_16x16.svg";
import cancel from "../../../../icons/cancel_cross_red_16x16.svg";
import list from "../../../../icons/list.svg";
import { useGetProfileQuery } from "../../services/profile.api";
import Loader from "../../../../components/loader";
import { useState, useMemo } from "react";
import ProfileCard from "../personala-data/profile-card";
import ProfileNotice from "../personala-data/notice";
import Delink from "./delink";
import { useNavigate, useParams } from "react-router-dom";
import { setAttachCardState, setPatient } from "../../services/profile.slice";
import { useDispatch } from "react-redux";

export default function ShareAccess() {
	const { data: profile, isLoading } = useGetProfileQuery();
	const dispatch = useDispatch();
	const params = useParams();
	const translate = useTranslate();
	let selectedType = null;
	const selectedProfile =
		profile &&
		(profile.observingCards.find((profile) => {
			if (
				profile.medCardNumber &&
				profile.medCardNumber == params.med_card_number &&
				params.type === "observing"
			) {
				selectedType = "observing";
				return true;
			}
		}) ||
			profile.attachedCards.find((profile) => {
				if (
					profile.medCardNumber &&
					profile.medCardNumber == params.med_card_number &&
					params.type === "attached"
				) {
					selectedType = "attached";

					return true;
				}
			}));

	const [delink, setDelink] = useState(null);
	const navigate = useNavigate();

	const methodType = useMemo(() => {
		if (selectedType === "observing") return "delinkMe";
		else if (selectedType === "attached") return "delink";
		else return null;
	}, [selectedType]);

	if (!profile) {
		return (
			<div className={css.wrapper}>
				<div className={classNames("h3", css.h3)}>
					{translate("profile.shareAccess.title")}
				</div>
			</div>
		);
	}

	return (
		<div className={css.wrapper}>
			<div className={classNames("h3", css.h3)}>
				{translate("profile.shareAccess.title")}
			</div>
			{params.med_card_number && params.type && selectedProfile ? (
				<ProfileCard
					{...selectedProfile}
					className={css.pd}
					hideContractTerm={selectedType === "observing"}
				>
					{params.med_card_number.invoicePayment && (
						<ProfileNotice
							title={translate("app.attention", true)}
							message={translate("profile.shareAccess.hasUnpaidBills", true)}
							btn={{
								onClick: () => console.log("check"),
								text: translate("profile.personalData.goToBilling", true),
							}}
						/>
					)}
					{selectedType === "attached" && (
						<div className={css.card_buttons}>
							<Button
								onClick={() => {
									dispatch(setPatient(selectedProfile));
									navigate("/appointment");
								}}
							>
								{translate("profile.shareAccess.appointPatient")}
							</Button>
							<div
								onClick={() => {
									dispatch(setPatient(selectedProfile));
									navigate("/history/receptions");
								}}
								className={css.light_btn}
							>
								<img src={clock} />
								{translate("history.title")}
							</div>
							<div
								className={css.light_btn}
								onClick={() => {
									setDelink(params.med_card_number);
								}}
							>
								<img src={cancel} />
								{translate("profile.shareAccess.unpinCard")}
							</div>
						</div>
					)}
					{selectedType === "observing" && (
						<div className={css.card_buttons}>
							<Button
								onClick={() => {
									dispatch(
										setAttachCardState({
											state: true,
											savedCard: params.med_card_number,
										})
									);
								}}
							>
								{translate("profile.shareAccess.requestAccess")}
							</Button>
							<div
								className={css.light_btn}
								onClick={() => setDelink(params.med_card_number)}
							>
								<img src={cancel} />
								{translate("profile.shareAccess.selfUnpinAction")}
							</div>
						</div>
					)}
				</ProfileCard>
			) : (
				<>
					<div className={css.list}>
						<div className={css.title}>
							{translate("profile.shareAccess.attachedCards")}
						</div>

						{isLoading ? (
							<Loader />
						) : (
							<div className={css.container}>
								{profile.attachedCards.length === 0 && (
									<div className={css.empty}>
										<img src={list} className={css.icon} />
										<div className={css.text}>
											{translate("profile.shareAccess.emptyAttachedCards")}
										</div>
									</div>
								)}
								{profile.attachedCards.map((card) => (
									<Item
										onClick={() =>
											navigate(
												`/profile/sharing/${card.medCardNumber}/attached`
											)
										}
										card={card}
										key={`card-${card.email + card.medCardNumber + card.phone}`}
									/>
								))}
							</div>
						)}
					</div>
					<div className={css.button}>
						<Button
							onClick={() => dispatch(setAttachCardState({ state: true }))}
						>
							{translate("profile.shareAccess.getAccessToCard")}
						</Button>
					</div>
					<div className={css.list}>
						<div className={css.title}>
							{translate("profile.shareAccess.observingCards")}
						</div>
						{isLoading ? (
							<Loader />
						) : (
							<div className={css.container}>
								{profile.observingCards.length === 0 && (
									<div className={css.empty}>
										<img src={list} className={css.icon} />
										<div className={css.text}>
											{translate("profile.shareAccess.emptyObservingCards")}
										</div>
									</div>
								)}
								{profile.observingCards.map((card) => (
									<Item
										onClick={() =>
											navigate(
												`/profile/sharing/${card.medCardNumber}/observing`
											)
										}
										card={card}
										key={`card-${card.email + card.medCardNumber + card.phone}`}
									/>
								))}
							</div>
						)}
					</div>
				</>
			)}

			{methodType && (
				<Delink
					delinkVal={delink}
					setDelinkVal={setDelink}
					keyVal={methodType}
				/>
			)}
		</div>
	);
}

const Item = ({ card, onClick }) => {
	const translate = useTranslate();
	if (!card) {
		return null;
	}

	return (
		<div className={css.row} onClick={onClick}>
			{card.photo ? (
				<img src={card.photo} className={css.avatar} />
			) : (
				<div className={css.no_avatar}>
					{card.firstName && card.firstName[0].toUpperCase()}.{" "}
					{card.lastName && card.lastName[0].toUpperCase()}.
				</div>
			)}
			<div className={css.info}>
				<div className={css.fio}>
					{card.firstName} {card.lastName}
				</div>
				<div className={css.card_number}>
					{translate("profile.personalData.medCardNumber")}: &nbsp;
					{card.medCardNumber}
				</div>
			</div>
			<img src={arrow} className={css.arrow} />
		</div>
	);
};
