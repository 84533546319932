import styles from "./Checkbox.module.css";
import { useRef, useState } from "react";
import classNames from "classnames";

export default function (props) {
	const [checked, setChecked] = useState(
		typeof props.checked === "undefined" ? false : props.checked
	);
	const wrapperClassNames = classNames(
		{
			[styles.active]:
				typeof props.checked === "undefined" ? checked : props.checked,
			[styles.disabled]: props.disabled,
			[styles.error]: props.error,
		},
		props.className,
		styles.checkbox
	);
	const checkbox = useRef(null);

	return (
		<>
			<input
				{...props}
				ref={checkbox}
				type="checkbox"
				onChange={(e) => {
					if (props.onChange) {
						props.onChange(e.target.checked);
					}
				}}
				onClick={(event) => event.stopPropagation()}
				hidden
			/>
			<span
				className={wrapperClassNames}
				onClick={(event) => {
					event.stopPropagation();

					if (typeof props.checked === "undefined") {
						setChecked(!checked);
					} else {
						checkbox.current.click();
					}
				}}
			/>
			<label className={props.labelclass}>
				{props.label}
				{props.error && (
					<div className={styles.checkbox_error_message}>{props.error}</div>
				)}
			</label>
		</>
	);
}
