import { useDispatch, useSelector } from "react-redux";
import {
	selectNeedRefresh,
	selectAnonLoginAttempts,
	setAnonLoginAttempts,
	setAuthInfo,
	setAuthInfoToLS,
	selectRefreshToken,
	selectAccessToken,
	setGuestMode,
	selectLastUrl,
	setLastUrl,
	updateLastActivity,
	setExpired,
} from "./services/login.slice";
import {
	useAuthGuestMutation,
	useRefreshGuestMutation,
} from "./services/login.api";
import React, { useEffect } from "react";
import Loader from "../../components/loader";
import Error from "../../components/errors/error";

const ALLOW_GUEST_LOGIN_ATTEMPTS = 3; //Сколько раз можно попробовать анонимно авторизоваться, если вдруг в ответ пришла ошибка

export default function AuthenticationProvider({ children }) {
	const needRefresh = useSelector(selectNeedRefresh);
	const attemptsGuestLogin = useSelector(selectAnonLoginAttempts);
	const accessToken = useSelector(selectAccessToken);
	const refreshToken = useSelector(selectRefreshToken);
	const [
		authTrigger,
		{
			data: guestLoginData,
			error: guestLoginError,
			isLoading: isGuestLogining,
		},
	] = useAuthGuestMutation();
	const [
		refreshTrigger,
		{ data: refreshData, error: refreshError, isLoading: refreshing },
	] = useRefreshGuestMutation();
	const dispatch = useDispatch();
	const lastURL = useSelector(selectLastUrl);
	// const navigate = useNavigate();

	useEffect(() => {
		//Выполним гостевую авторизацию
		if (
			/*!expired && */ !accessToken &&
			/*!guestLoginData && */ !isGuestLogining &&
			attemptsGuestLogin < ALLOW_GUEST_LOGIN_ATTEMPTS
		) {
			authTrigger();
		}
	}, [attemptsGuestLogin, accessToken]);

	useEffect(() => {
		if (guestLoginData) {
			//Если гостевая авторизация прошла успешно
			dispatch(
				setAuthInfo({
					accessToken: guestLoginData.data.access,
					refreshToken: guestLoginData.data.refresh,
					isGuest: true,
				})
			);
			dispatch(setAuthInfoToLS());
			dispatch(setGuestMode(true));
		}
		if (guestLoginError) {
			/**
                Если произошла ошибка при гостевой авторизации
                токены в redux state стирать не надо, т.к. их там небыло
            */
			dispatch(setAnonLoginAttempts());
			dispatch(setGuestMode(false));
		}
	}, [guestLoginData, guestLoginError]);

	useEffect(() => {
		//Флаг needRefresh устанавливается в middleware на глобаном уровне, если на люой запрос пришел статус 401
		if (needRefresh && !refreshing) {
			refreshTrigger(refreshToken);
			//TODO: сохранить запрос, который вернул 401, затем повторить его после refresh
		}
	}, [needRefresh]);

	useEffect(() => {
		if (refreshData) {
			//Если произошло успешное обновление токена
			dispatch(
				setAuthInfo({
					accessToken: refreshData.data.access,
					refreshToken: refreshData.data.refresh,
				})
			);
			dispatch(setAuthInfoToLS());
			/*
            dispatch(setNeedRefresh(false));// Если произошло обновление токена, флаг needRefresh переводиться в исходное состояник
            */
		}
		if (refreshError) {
			//TODO: save refresh error redux state
		}
	}, [refreshData]);

	useEffect(() => {
		const listener = (e) => {
			const tabIsActive = document.hasFocus();
			let isWidget = false;

			for (let i = 0; i < window.frames.length; i++) {
				if (window.frames[i].location.href.includes("widget") && !isWidget) {
					isWidget = true;
				}
			}

			isWidget = window.location.href.toLowerCase().includes("widget")
				? true
				: isWidget;

			if (!tabIsActive && isWidget) {
				if (e.key === "isGuest") {
					const oldValue = JSON.parse(e.oldValue);
					const newValue = JSON.parse(e.newValue);

					if ((!oldValue && newValue) || (oldValue && !newValue)) {
						window.location.reload();
					}
				}
			}

			if (!tabIsActive && !isWidget) {
				/**
				 * Если вкладка не активна
				 * */
				if (e.key === "isGuest") {
					/**
					 * делать login/logout синхронно с соответствующими действиями пользователя на активной вкладке
					 * */
					const oldValue = JSON.parse(e.oldValue);
					const newValue = JSON.parse(e.newValue);

					if (!oldValue && newValue) {
						//Если полоьзователь совершил выход на другой вкладке
						if (lastURL) {
							console.log('window.location.pathname = lastURL;');
							window.location.pathname = lastURL;
							dispatch(setLastUrl(null));
						} else {
							console.log('window.location.pathname = "/events";');
							window.location.pathname = "/events";
						}
					} else if (oldValue && !newValue) {
						//Если пользователь авторизовался на другой вкладке
						// dispatch(setLastUrl(window.location.pathname))
						window.location.pathname = "/login";
					}
				}
				if (e.key === "expired") {
					/**
					 * Если на активной вкладке ввели короткий код, то надо убрать это окно на всех неактивных
					 * */
					const oldValue = JSON.parse(e.oldValue);
					const newValue = JSON.parse(e.newValue);
					if (oldValue && !newValue) {
						dispatch(updateLastActivity());
						dispatch(setExpired(false));
					}
				}
			}
		};

		window.addEventListener("storage", listener);
		return () => window.removeEventListener("storage", listener);
	}, []);

	if (isGuestLogining) {
		return <Loader />;
	}

	if (
		(guestLoginError && attemptsGuestLogin === ALLOW_GUEST_LOGIN_ATTEMPTS) ||
		refreshError
	) {
		//Если пользователь не авторизован и превышено количество попыток анон логина, то значит на сервере все сломалось
		return <Error status={500} message={"Internal Server error"} />;
	}

	return children;
}
