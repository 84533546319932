import styles from "./short-code/short_code.module.css";
import { useEffect, useState } from "react";
import Ricib from "../../../components/ricib/ricib.v.0.1";
import Button from "../../../components/button/button";
import md5 from "md5";
import { useTranslate } from "../../../localization/translate";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
	selectShortCode,
	setAuthInfo,
	setExpired,
	setLastUrl,
	updateLastActivity,
} from "../services/login.slice";

const MAX_ALLOW_ATTEMPTS = 4;

export default function ShortCodeLogin() {
	const [error, setError] = useState();
	const [code, setCode] = useState(null);
	const translate = useTranslate();
	const [attempts, setAttempts] = useState(MAX_ALLOW_ATTEMPTS);
	const shortCode = useSelector(selectShortCode);
	const dispatch = useDispatch();

	useEffect(() => {
		if (code) {
			if (md5(code) === shortCode) {
				dispatch(updateLastActivity());
				dispatch(setExpired(false));
			} else if (!shortCode) {
				dispatch(setAuthInfo(null));
				dispatch(setExpired(false));
				dispatch(setLastUrl(null));
			} else if (md5(code) !== shortCode) {
				setError(
					`${translate("login.shortCodeLogin.left", true)} ${
						attempts - 1
					} ${translate("login.shortCodeLogin.attempts", true)}`
				);
			}

			setAttempts(attempts - 1);
		}
	}, [code]);

	useEffect(() => {
		if (attempts === 0) {
			/**
			 * если shortcode введен неправильно несколько раз, то разлогиним
			 * */
			dispatch(setAuthInfo(null));
			dispatch(setExpired(false));
			dispatch(setLastUrl(null));
		}
	}, [attempts]);

	const errorClassNames = classNames(styles.errorMsg, styles.relative);

	return (
		<div className={classNames(styles.wrapper, styles.short_code)}>
			<div className={styles.form}>
				<div className={`${styles.h3} h3`}>
					{translate("login.shortCodeLogin.typeShortCode")}
				</div>
				<div className={`${styles.text} text`}>
					{translate("login.shortCodeLogin.whyFor")}
				</div>
				<div className={errorClassNames}>{error}</div>
				<Ricib
					onComplete={(code) => setCode(code)}
					length={4}
					onChange={(code) => {
						setError(null);
						if (code.length < 4) {
							setCode(null);
						}
					}}
					advInputClass={styles.input}
					type="password"
					errorMsg={error ? " " : null}
				/>
				<Button
					onClick={() => {
						dispatch(setAuthInfo(null));
						dispatch(setExpired(false));
						dispatch(setLastUrl(null));
						setTimeout(() => window.location.reload(), 500);
					}}
					className={styles.changeCode}
					light
				>
					{translate("login.logoutBtn")}
				</Button>
			</div>
		</div>
	);
}
