import Modal from "../../../../components/modal-safari-wrapper/modal";
import CloseModalButton from "../../../../components/modal-items/close";
import s from "./choose-doctor.module.css";
import { useTranslate } from "../../../../localization/translate";
import moment from "moment";
import DoctorCard from "../../../../components/doctor-card/doctor-card";
import { useSelector } from "react-redux";
import { selectExaminationInfo } from "../../services/appointment.slice";
import { useMemo } from "react";

export default function ChooseDoctorModal({
	date,
	timeSlot,
	onClose,
	service,
	allSlots,
	doctors,
	onDoctorClick,
}) {
	const t = useTranslate();
	const examinationInfo = useSelector(selectExaminationInfo);

	const modalStyle = {
		overlay: {
			zIndex: 140,
		},
		content: {},
	};

	const onRequestClose = () => {
		onClose();
	};

	const additionalSlots = timeSlot?.slotDoctors[0].additionalSlots?.map(
		(slot) => slot
	);

	const isOpen = useMemo(() => {
		const isOpen =
			!!timeSlot?.slotDoctors.some((d) => !!d.additionalSlots?.length) &&
			!examinationInfo;

		return isOpen;
	}, [examinationInfo, timeSlot]);

	return (
		!!additionalSlots?.length && (
			<Modal
				isOpen={isOpen}
				onRequestClose={onRequestClose}
				style={modalStyle}
				className={s.modal}
			>
				<CloseModalButton closeModal={onRequestClose} />
				<div className={s.container}>
					<h1 className={s.title}>{t("appointment.slots.title")}</h1>
					{!!service && (
						<div className={s.row}>
							<span className={s.start}>
								{t("appointment.slots.serviceTitle")}
							</span>
							<p>{service}</p>
						</div>
					)}
					<div className={s.row}>
						<span className={s.start}>{t("appointment.slots.dateTime")}</span>
						<p>
							{moment(date).format("DD MMMM")}{" "}
							{moment(
								[
									timeSlot,
									...allSlots.filter((sl) =>
										additionalSlots.includes(sl.idMis)
									),
								].sort((a, b) => a.time.localeCompare(b.time))[0].time
							).format("HH:mm")}
						</p>
					</div>
					<p className={s.helper}>
						{t("appointment.slots.info1")}
						{additionalSlots.length + 1}
						{t("appointment.slots.info2")}
					</p>
					{/* <p className={s.about}>{t("appointment.slots.about")}</p> */}
					<div className={s.slots}>
						{[
							timeSlot,
							...allSlots.filter((sl) => additionalSlots.includes(sl.idMis)),
						]
							.sort((a, b) => a.time.localeCompare(b.time))
							.map((sl, index, array) => (
								<>
									<span className={s.timeslot}>
										{moment(sl.time).format("HH:mm")}
									</span>
									{index + 1 !== array.length && ","}
								</>
							))}
					</div>
					<div className={s.doctors}>
						{doctors.map((doctor) => (
							<DoctorCard
								key={`on-time-${doctor.id}`}
								{...doctor}
								cardClick={() => {
									const additionalSlotsMis =
										timeSlot.slotDoctors.find((sl) => sl.doctorId === doctor.id)
											?.additionalSlots || [];
									const additionalSlots = allSlots
										.filter((sl) => additionalSlotsMis.includes(sl.idMis))
										.map((sl) => ({ idMis: sl.idMis, time: sl.time }));
									onDoctorClick(doctor, additionalSlots);
								}}
							/>
						))}
					</div>
				</div>
			</Modal>
		)
	);
}
