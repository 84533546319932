import { useTranslate } from "../../../../../localization/translate";
import ContractItem from "./contract-item";
import { useGetContractsQuery } from "../../../services/profile.api";
import styles from "./style.module.css";
import Loader from "../../../../../components/loader";

export default function Contracts({}) {
	const translate = useTranslate();
	const { data, isLoading } = useGetContractsQuery();

	return (
		<>
			{isLoading && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}

			{data && data.contracts.length > 0 && (
				<div className={styles.contracts}>
					<div className={styles.title}>
						{translate("profile.personalData.contracts.title")}
					</div>

					<div className={styles.collection}>
						{data.contracts.map((item) => (
							<ContractItem
								key={item.name}
								title={item.name}
								dateTill={item.dateTill}
								files={item.files}
							/>
						))}
					</div>
				</div>
			)}
		</>
	);
}
