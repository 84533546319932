import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
	selectedDoctor: null,
	doctorModalWithoutSchedule: false,
	examinationInfo: null, // Может содаржать поля {slotIdMis*, medCardNumber*, serviceId* || featureIdMis}. Если есть serviceId - значит запись на диагностический прием
	receptionType: null,
	receptionTypeIsOpen: false,
	subscribeModal: null, // timeSlot может  содержать поля {date, specialityId, specialityId, idMis}. Если subscribeModal !== null, то будет открыто <SubscribeModal/>
	historySearch: localStorage.getItem("history-search") || [],
	rateInfo: null,
	subjectOpinion: null, // открыто ли модальное окно второго мнения
	appointmentExaminationResult: null,

	date: null,
	dateRange: { from: null, to: null },

	researchId: null,
};

export const appointmentSlice = createSlice({
	name: "appointment",
	initialState,
	reducers: {
		setDate: (state, { payload }) => {
			state.date = payload;
		},
		setDateFrom: (state, { payload }) => {
			state.dateRange.from = payload;
		},
		setDateTo: (state, { payload }) => {
			state.dateRange.to = payload;
		},
		setDateRange: (state, { payload }) => {
			state.dateRange.from = payload.from;
			state.dateRange.to = payload.to;
		},

		setSelectedDoctor: (state, action) => {
			if (action.payload === null) {
				state.doctorModalWithoutSchedule = false;
			}
			state.selectedDoctor = action.payload;
		},
		setDoctorModalScheduleState: (state, action) => {
			state.doctorModalWithoutSchedule = action.payload;
		},
		setExaminationInfo: (state, action) => {
			state.examinationInfo = action.payload;
		},
		setSubscribeModal: (state, action) => {
			state.subscribeModal = action.payload;
			// state.selectedDoctor = null;
		},
		setReceptionType: (state, action) => {
			state.receptionType = action.payload;
		},
		setReceptionTypeState: (state, action) => {
			state.receptionTypeIsOpen = action.payload;
		},
		setHistorySearch: (state, action) => {
			state.historySearch = action.payload;
		},
		setRateInfo: (state, action) => {
			state.rateInfo = action.payload;
		},
		setSubjectOpinion: (state, action) => {
			state.subjectOpinion = action.payload;
		},
		setAppointmentExaminationResult: (state, { payload }) => {
			state.appointmentExaminationResult = payload;
		},

		setResearchId: (state, { payload }) => {
			state.researchId = payload;
		},
	},
});

export const {
	setSelectedDoctor,
	setReceptionTypeState,
	setRateInfo,
	setSubscribeModal,
	setExaminationInfo,
	setReceptionType,
	setHistorySearch,
	setSubjectOpinion,
	setDoctorModalScheduleState,
	setAppointmentExaminationResult,
	setDate,
	setDateFrom,
	setDateTo,
	setDateRange,
	setResearchId,
} = appointmentSlice.actions;

export const selectDate = (state) => state.appointment.date;
export const selectDateFrom = (state) => state.appointment.dateRange.from;
export const selectDateTo = (state) => state.appointment.dateRange.to;
export const selectDateRange = (state) => state.appointment.dateRange;

export const selectRateInfo = (state) => state.appointment.rateInfo;
export const selectedDoctor = (state) => state.appointment.selectedDoctor;
export const withoutSchedule = (state) =>
	state.appointment.doctorModalWithoutSchedule;
export const selectedHistorySearch = (state) => {
	if (typeof state.appointment.historySearch === "string") {
		return JSON.parse(state.appointment.historySearch);
	}

	return state.appointment.historySearch;
};
export const selectExaminationInfo = (state) =>
	state.appointment.examinationInfo;
export const selectedSubscribeModal = (state) =>
	state.appointment.subscribeModal;
export const selectReceptionType = (state) => state.appointment.receptionType;
export const selectReceptionTypeIsOpen = (state) =>
	state.appointment.receptionTypeIsOpen;
export const selectSubjectOpinion = (state) => state.appointment.subjectOpinion;
export const selectAppointmentExaminationResult = (state) =>
	state.appointment.appointmentExaminationResult;
export const selectResearchId = (state) => state.appointment.researchId;

export default appointmentSlice;
