import styles from "./reception-type-modal.module.css";
import Modal from "../../../../components/modal-safari-wrapper/modal";
import { useEffect } from "react";
import CloseModalButton from "../../../../components/modal-items/close";
import { useTranslate } from "../../../../localization/translate";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
	selectExaminationInfo,
	selectReceptionTypeIsOpen,
	setReceptionType,
	setReceptionTypeState,
} from "../../services/appointment.slice";
import { useNavigate, useParams } from "react-router-dom";

const modalStyle = {
	overlay: {
		zIndex: 140,
	},
};

export default function ReceptionTypeModal({
	isPublic = false,
	isWidget = false,
}) {
	const isOpen = useSelector(selectReceptionTypeIsOpen);
	const dispatch = useDispatch();
	const translate = useTranslate();
	const examinationInfo = useSelector(selectExaminationInfo);
	const params = useParams();
	const navigate = useNavigate();

	const onRequestClose = () => {
		if (isPublic && !isWidget) {
			navigate(`/appointment/${params["*"]}`);
		}

		dispatch(setReceptionTypeState(false));
	};

	return (
		<Modal
			isOpen={isOpen}
			className={styles.modal}
			onRequestClose={onRequestClose}
			style={modalStyle}
		>
			<CloseModalButton closeModal={onRequestClose} />
			<div className={styles.content}>
				<div className={classNames(styles.title)}>
					{translate("appointment.receptionType.title")}
				</div>
				<div className={styles.types}>
					<div
						key={"offline"}
						className={styles.type}
						onClick={(e) => {
							dispatch(setReceptionType("offline"));
							onRequestClose();
						}}
					>
						<div className={styles.wrap}>
							{translate(`appointment.receptionType.offline`)}
							<br />
							{translate(`appointment.receptionType.offlineText`)}
						</div>
						<div className={styles.icon}>
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7.23259 4.72676C6.92247 5.0291 6.92247 5.51929 7.23259 5.82163L14.0828 12.5L7.23259 19.1784C6.92247 19.4807 6.92247 19.9709 7.23259 20.2732C7.54271 20.5756 8.04552 20.5756 8.35564 20.2732L15.7674 13.0474C16.0775 12.7451 16.0775 12.2549 15.7674 11.9526L8.35564 4.72676C8.04552 4.42441 7.54271 4.42441 7.23259 4.72676Z"
									fill="#7A8CA4"
								/>
							</svg>
						</div>
					</div>

					{examinationInfo &&
						examinationInfo.receptionTypes &&
						examinationInfo.receptionTypes.map((type) => (
							<div
								key={`reception-type-${type.id}`}
								className={styles.type}
								onClick={(e) => {
									dispatch(setReceptionType(type.id));
									onRequestClose();
								}}
							>
								<div className={styles.wrap}>
									<span>{type.name}</span>
									<br />
									<span
										dangerouslySetInnerHTML={{ __html: type.description }}
									/>
								</div>
								<div className={styles.icon}>
									<svg
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M7.23259 4.72676C6.92247 5.0291 6.92247 5.51929 7.23259 5.82163L14.0828 12.5L7.23259 19.1784C6.92247 19.4807 6.92247 19.9709 7.23259 20.2732C7.54271 20.5756 8.04552 20.5756 8.35564 20.2732L15.7674 13.0474C16.0775 12.7451 16.0775 12.2549 15.7674 11.9526L8.35564 4.72676C8.04552 4.42441 7.54271 4.42441 7.23259 4.72676Z"
											fill="#7A8CA4"
										/>
									</svg>
								</div>
							</div>
						))}
				</div>
			</div>
		</Modal>
	);
}
