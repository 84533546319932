import classNames from "classnames";
import styles from "./style.module.css";

const InfoIcon = () => (
	<svg
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.0003 24.8307C19.9834 24.8307 24.8337 19.9805 24.8337 13.9974C24.8337 8.01431 19.9834 3.16406 14.0003 3.16406C8.01724 3.16406 3.16699 8.01431 3.16699 13.9974C3.16699 19.9805 8.01724 24.8307 14.0003 24.8307ZM14.0003 27.3307C21.3641 27.3307 27.3337 21.3612 27.3337 13.9974C27.3337 6.6336 21.3641 0.664062 14.0003 0.664062C6.63653 0.664062 0.666992 6.6336 0.666992 13.9974C0.666992 21.3612 6.63653 27.3307 14.0003 27.3307Z"
			fill="#54A0FF"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.0003 11.0807C14.6907 11.0807 15.2503 11.6404 15.2503 12.3307V20.6641C15.2503 21.3544 14.6907 21.9141 14.0003 21.9141C13.31 21.9141 12.7503 21.3544 12.7503 20.6641V12.3307C12.7503 11.6404 13.31 11.0807 14.0003 11.0807Z"
			fill="#54A0FF"
		/>
		<path
			d="M15.667 7.33073C15.667 8.2512 14.9208 8.9974 14.0003 8.9974C13.0798 8.9974 12.3337 8.2512 12.3337 7.33073C12.3337 6.41025 13.0798 5.66406 14.0003 5.66406C14.9208 5.66406 15.667 6.41025 15.667 7.33073Z"
			fill="#54A0FF"
		/>
	</svg>
);

const InfoBanner = ({
	title,
	theme = "default",
	layout = "default",
	renderIcon,
	renderActions,
	children,
}) => (
	<div
		className={classNames(styles.info, {
			[styles[theme]]: theme,
			[styles[layout]]: layout,
		})}
	>
		<div className={styles.icon}>
			{renderIcon ? renderIcon() : <InfoIcon />}
		</div>

		<div className={styles.content}>
			<div className={styles.title}>{title}</div>

			{children && <div className={styles.inner}>{children}</div>}

			{renderActions && <div className={styles.actions}>{renderActions()}</div>}
		</div>
	</div>
);

export default InfoBanner;
