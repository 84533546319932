import css from "./selectors.module.css";
import moment from "moment";
import { useTranslate } from "../../../../localization/translate";
import classNames from "classnames";
import { useGetProfileQuery } from "../../../profile/services/profile.api";
import styles from "../../../profile/modules/personala-data/personal-data.module.css";
import NumberFormat from "react-number-format";
import Button from "../../../../components/button/button";
import { setSubjectOpinion } from "../../services/appointment.slice";
import { useDispatch, useSelector } from "react-redux";
import {
	selectPatient,
	allowAddPatient,
	setPatientSelectorState,
} from "../../../profile/services/profile.slice";
import { convertDate } from "../../../../utils/dates/timezone-converter";

export default function Confirm() {
	const dispatch = useDispatch();
	const onClick = () => {
		dispatch(setSubjectOpinion("SubjectOpinionForm"));
	};
	const translate = useTranslate();
	const { data: profile } = useGetProfileQuery();
	const selectedPatient = useSelector(selectPatient);
	const currentPatient = selectedPatient ? selectedPatient : profile;

	const handleSelectPatient = () => {
		dispatch(allowAddPatient());
		dispatch(setPatientSelectorState(true));
	};

	return (
		<div className={css.list}>
			<div className={classNames("text")}>
				{translate("appointment.subjectOpinion.confirmText")}
			</div>
			{currentPatient && (
				<>
					<div className={classNames("h3", css.subtitle)}>
						{currentPatient.firstName}
						&nbsp;
						{currentPatient.lastName}
					</div>
					<div className={css.rows}>
						<div className={css.row}>
							{translate("profile.personalData.medCardNumber")}
							<span>{currentPatient.medCardNumber}</span>
						</div>
						<div className={css.row}>
							{translate("profile.personalData.dateOfBirth")}
							<span>
								{moment(currentPatient.dateOfBirth).format("DD.MM.YYYY")}
								{/*convertDate()*/}
							</span>
						</div>
						<div className={css.row}>
							{translate("profile.personalData.phone")}
							<NumberFormat
								value={currentPatient.phone}
								displayType={"text"}
								renderText={(value, props) => (
									<span {...props} className={styles.value}>
										{value}
									</span>
								)}
								format="+# (###) ###-##-##"
							/>
						</div>
						<div className={css.row}>
							{translate("profile.personalData.email")}
							<span>
								{currentPatient.email && currentPatient.email.length > 0
									? currentPatient.email
									: translate("profile.personalData.notSpecify")}
							</span>
						</div>
					</div>
					<div className={css.buttons}>
						<Button light onClick={handleSelectPatient}>
							{translate("appointment.subjectOpinion.changePatient")}
						</Button>
						<Button onClick={onClick}>{translate("app.continue")}</Button>
					</div>
				</>
			)}
		</div>
	);
}
