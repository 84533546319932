import styles from "./Login.module.css";
import { useTranslate } from "../../../localization/translate";
import LoginByPhone from "./phone-login/phone-login";
import Promo from "./promo";
import ByLogin from "./username-login";
import ByEmail from "./email-login";
import InvalidMagicLink from "./magic-link/invalid-link";
import NewPasswordModal from "./reset-password/new-password.modal";
import { NavLink } from "react-router-dom";
import ServiceCard from "../../../components/service-card";

export const forms = {
	byPhone: <LoginByPhone />,
	byLogin: <ByLogin />,
	byEmail: <ByEmail />,
};

export default function LoginPage() {
	const translate = useTranslate();

	return (
		<div className={styles.container}>
			<Promo />

			<div className={styles.rightpad}>
				<div className={styles.form}>
					<div className={styles.input_group}>
						<div className={styles.schedule_links}>
							<NavLink className={() => styles.nav_item} to="/auth">
								<ServiceCard
									icon="login"
									title={translate("login.navigation.login.title", true)}
									text={translate("login.navigation.login.text", true)}
								/>
							</NavLink>

							<NavLink
								className={() => styles.nav_item}
								to="/schedule/speciality"
							>
								<ServiceCard
									icon="doctor"
									title={translate("login.navigation.doctor.title", true)}
									text={translate("login.navigation.doctor.text", true)}
								/>
							</NavLink>

							<NavLink
								className={() => styles.nav_item}
								to="/schedule/services"
							>
								<ServiceCard
									icon="services"
									title={translate("login.navigation.services.title", true)}
									text={translate("login.navigation.services.text", true)}
								/>
							</NavLink>
						</div>
					</div>
				</div>
			</div>
			<InvalidMagicLink />
			<NewPasswordModal />
		</div>
	);
}
