import { useRef, useState, useEffect } from "react";
import { useTranslate } from "../../../../../localization/translate";
import CustomModal from "../../../../../components/custom-modal/modal-adapter";
import Button from "../../../../../components/button/button";
import { useUploadMutation } from "../../../../../app/applcation.api";
import { useUpdateProfileMutation } from "../../../services/profile.api";
import styles from "./style.module.css";

const ALLOWED_FILE_TYPES = [".jpg", ".jpeg", ".png"];

export default function AvatarModal({
	photo,
	isOpen,
	onClose,
	renderAvatar = () => null,
}) {
	const translate = useTranslate();

	const fileInput = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [submitFile, { data }] = useUploadMutation();
	const [updateProfile] = useUpdateProfileMutation();

	useEffect(() => {
		if (data?.data?.fileName) {
			updateProfile({ photo: data.data.fileName });
		}
	}, [data]);

	useEffect(() => {
		if (isLoading) {
			setIsLoading(false);
		}
	}, [photo]);

	const handleChange = (e) => {
		const file = e.target.files[0];
		if (file.type.includes("image/")) {
			setIsLoading(true);
			submitFile(file);
		}
	};

	const handleDeleteAvatar = () => {
		setIsLoading(true);
		updateProfile({ photo: null });
	};

	const handleUploadAvatar = () => fileInput.current.click();

	return (
		<CustomModal isOpen={isOpen} onClose={onClose}>
			<div className={styles.content}>
				<div className={styles.avatar}>
					{renderAvatar({ photo, isLoading })}
				</div>

				<div className={styles.actions}>
					<Button
						small
						light
						onClick={handleUploadAvatar}
						className={styles.upload_btn}
					>
						{translate("profile.personalData.uploadAvatar")}
					</Button>

					{photo && (
						<Button
							small
							light
							className={styles.delete_btn}
							onClick={handleDeleteAvatar}
						>
							{translate("profile.personalData.deleteAvatar")}
						</Button>
					)}

					<Button onClick={onClose}>
						{translate("profile.personalData.doneAvatar")}
					</Button>
				</div>
			</div>

			<input
				hidden
				type="file"
				ref={fileInput}
				onChange={handleChange}
				accept={ALLOWED_FILE_TYPES.join(",")}
			/>
		</CustomModal>
	);
}
