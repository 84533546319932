import SelectorModal from "../../../../components/item-selector/modal-selector";
import { useTranslate } from "../../../../localization/translate";
import css from "../payment-accounts/accounts.module.css";
import Button from "../../../../components/button/button";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectedAccount, setAccountData } from "../../services/profile.slice";
import { setPaymentData } from "../../../payments/services/payments.slice";
import {
	DEPOSIT_TYPES,
	PAYMENT_TYPES,
} from "../../../payments/helpers/constants";

export default function AccountInfo() {
	const selected = useSelector(selectedAccount);
	const dispatch = useDispatch();
	const onApply = () => {
		dispatch(
			setPaymentData({
				PaymentModal: {
					type: PAYMENT_TYPES.deposit,
					subtype: DEPOSIT_TYPES.refill,
					purposeId: selected.id,
				},
				PaymentTarget: selected,
			})
		);
		dispatch(
			setAccountData({
				key: "accountInfo",
				value: null,
			})
		);
	};
	const translate = useTranslate();
	const isOpen = !!selected;
	const navigate = useNavigate();

	return (
		<SelectorModal
			applyBtn={{
				text: translate("profile.bills.topUpBalance", true),
				onClick: onApply,
			}}
			advancedButtons={
				<>
					{/*<Button light>Перевод между счетами</Button>*/}
					<Button
						light
						onClick={() => {
							dispatch(setAccountData({ key: "accountInfo", value: null }));
							navigate(`/profile/payment-accounts/${selected.id}`);
						}}
					>
						{translate("profile.bills.paymentLog")}
					</Button>
				</>
			}
			title={translate("profile.bills.personalAccount")}
			onRequestClose={() =>
				dispatch(setAccountData({ key: "accountInfo", value: null }))
			}
			isOpen={isOpen}
		>
			{isOpen && (
				<div className={css.modal_container}>
					<div className={classNames(css.row, css.not_active)}>
						<div className={css.info}>
							<div className={css.account}>{selected.name}</div>
							<div className={css.account_info}>
								{translate("profile.bills.balance", true)}: &nbsp;
								{parseFloat(selected.balance).toLocaleString(undefined, {
									minimumFractionDigits: 2,
								})}
								&nbsp;
								{translate("app.currencySign", true)}
							</div>
							{selected.owner && (
								<div className={css.account_info}>
									{translate("profile.bills.owner", true)}: &nbsp;
									{selected.owner}
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</SelectorModal>
	);
}
