/**
 * Валидирует логин
 *
 * @param {string} text - Строка
 *
 * @return {Array.<{valid: Boolean, messages: Array.<string[]> }>} массив ошибок с ключами для локализации
 */
export const loginValidation = (string) => {
	const err = [];
	if (string.length < 2) {
		err.push("login.loginCouple.toShort");
	}

	/*
    const reg = new RegExp(/^[a-z0-9]+$/i)
    if (!reg.test(string)) {
        err.push("login.loginCouple.notAllowedChars");
    }
    */

	return res(err);
};

/**
 * Валидирует емейл
 *
 * @param {string} text -  Строка
 *
 * @return {Array.<{valid: Boolean, messages: Array.<string[]> }>} массив ошибок с ключами для локализации
 */
export const emailValidation = (string) => {
	const err = [];

	const reg = new RegExp(/^([\w\.\-]+)@([\w\-]+)((\.(\w){2,4})+)$/i);
	if (!reg.test(string)) {
		err.push("login.loginCouple.wrongEmail");
	}

	return res(err);
};

export const magicLinkValidation = (string) => {
	const err = [];

	if (string.length < 1) {
		err.push("login.magicLink.requestLink.tooShort");
	}

	return res(err);
};

export const passwordValidation = (string) => {
	const err = [];

	if (string.length < 1) {
		err.push("login.resetPassword.set.tooShort");
	}

	return res(err);
};

const res = (errorsArray) => {
	if (errorsArray.length > 0) {
		return {
			valid: false,
			messages: errorsArray,
		};
	} else {
		return { valid: true };
	}
};
