import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "beautiful-react-hooks";
import classNames from "classnames";
import { useTranslate } from "../../../../localization/translate";
import Loader from "../../../../components/loader";
import Button from "../../../../components/button/button";
import SelectorModal from "../../../../components/item-selector/modal-selector";
import Patient from "../../../events/modules/history/history-card/patient-dropdown";
import Results from "../../../payments/components/Results";
import { setPaymentData } from "../../../payments/services/payments.slice";
import { PAYMENT_TYPES } from "../../../payments/helpers/constants";
import {
	useDeleteReceivedBillMutation,
	useGetProfileQuery,
} from "../../../profile/services/profile.api";
import { useGetReceivedBillQuery } from "../../services/received-bills.api";
import { pushSystemNotification } from "../../../../app/app.slice";
import { fileViewer } from "../../../../utils";
import styles from "./style.module.css";
import { useMemo } from "react";

export default function BillModal({ invoice, onClose }) {
	const translate = useTranslate();
	const dispatch = useDispatch();

	const [pdfLoading, setPdfLoading] = useState(false);
	const isMobile = useMediaQuery("(max-width: 620px)");
	const [patientIsOpen, patientSetIsOpen] = useState();

	const { data: profile } = useGetProfileQuery();
	const { data, isLoading, error } = useGetReceivedBillQuery(invoice);
	const [
		deleteReceivedBill,
		{
			data: deleteReceivedBillResponse,
			isLoading: isDeleting,
			error: deleteError,
		},
	] = useDeleteReceivedBillMutation();

	const isMe = useMemo(
		() =>
			data?.payer &&
			profile?.medCardNumber &&
			data?.payer?.medCardNumber === profile?.medCardNumber,
		[profile, data]
	);

	useEffect(() => {
		if (deleteReceivedBillResponse) {
			onClose();
		}
	}, [deleteReceivedBillResponse]);

	useEffect(() => {
		if (deleteError) {
			dispatch(
				pushSystemNotification({
					type: "error",
					clientMessage:
						deleteError.messages &&
						deleteError.messages.length > 0 &&
						deleteError.messages[0].clientMessage
							? deleteError.messages[0].clientMessage
							: translate("app.failedToFetch", true),
				})
			);
		}
	}, [deleteError]);

	return (
		<SelectorModal
			isOpen={!!invoice}
			onRequestClose={onClose}
			title={translate("profile.bills.invoice")}
			wrapperClassName={classNames(styles.wrapper_class_name, {
				[styles.full]: !!error,
			})}
			applyBtn={
				data?.invoiceNumber
					? {
							onClick: () => {
								if (data.isPaid) {
									setPdfLoading(true);
									fileViewer(
										data.billUrl,
										() => setPdfLoading(false),
										(e) => {
											let message;
											if (typeof e === "string") {
												message = e;
											} else {
												message =
													e.messages &&
													e.messages.length > 0 &&
													e.messages[0].clientMessage
														? e.messages[0].clientMessage
														: translate("app.failedToFetch", true);
											}

											dispatch(
												pushSystemNotification({
													type: "error",
													clientMessage: message,
												})
											);
											setPdfLoading(false);
										}
									);
								} else {
									if (data.invoiceNumber || data.id) {
										dispatch(
											setPaymentData({
												PaymentModal: {
													type: data.date
														? PAYMENT_TYPES.invoice
														: PAYMENT_TYPES.test,
													purposeId: data.invoiceNumber || data.id,
												},
												PaymentTarget: data,
												InitInvoice: !data.date ? data.invoiceNumber : null,
											})
										);
										onClose();
									} else
										throw new Error(
											"Selected Order does not have InvoiceNumber"
										);
								}
							},
							text: translate(`app.${data.isPaid ? "showPDF" : "pay"}`, true),
							disabled: pdfLoading,
					  }
					: {}
			}
			advancedButtons={
				<>
					{data && !isMe && (
						<Button
							light
							disabled={isDeleting}
							onClick={() => {
								deleteReceivedBill(data.invoiceNumber);
							}}
							variant={"error"}
							className={styles.adv_btn}
						>
							{translate("profile.bills.removeInvoice", true)}
						</Button>
					)}
				</>
			}
		>
			{isLoading && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}

			{data && !error && (
				<div className={styles.body}>
					<div className={styles.amount}>
						{(data.amountDiscounted || data.amount)?.toLocaleString()}
						&nbsp;
						{translate("app.currencySign")}
					</div>
					<div className={styles.description}>
						{translate("profile.bills.invoiceFor")}
						{data.id || data.invoiceNumber}
					</div>
					<div className={styles.info}>
						<div className={styles.item}>
							{translate("appointment.confirm.patient")}
							{isMe ? (
								<div className={styles.me}>
									{translate("appointment.confirm.me")}
								</div>
							) : (
								<div
									className={styles.link}
									onClick={() => patientSetIsOpen(true)}
								>
									{data.payer.firstName} {data.payer.lastName}
									{!isMobile && (
										<Patient
											isOpen={patientIsOpen}
											setIsOpen={patientSetIsOpen}
											patient={data.payer}
											showBtns={!!data?.id}
											className={styles.patiend_dropdown}
										/>
									)}
								</div>
							)}
						</div>
						{data?.amountTotal !== data?.amountDiscounted && (
							<div className={styles.item}>
								{translate("profile.bills.withoutDiscount")}
								<span>
									{data.amountTotal?.toLocaleString()}{" "}
									{translate("app.currencySign")}
								</span>
							</div>
						)}
					</div>
				</div>
			)}

			{error && <Results type="fail" />}
		</SelectorModal>
	);
}
