import styles from "./Login.module.css";
import { useTranslate } from "../../../localization/translate";
import Footer from "../../../components/footer";
import {isAndroidApp, isIOSApp} from "../../../utils";
import stores from "../../../appstores.json";

const Promo = () => {
	const translate = useTranslate();

	return (
		<div className={styles.promo}>
			<div className={styles.wrapper}>
				<div>
					<div className={styles.head}>
						<img
							src="/images/logo.svg"
							alt=""
							className={styles.desktop_logo}
						/>
						<h2 className={"h2"}>{translate("app.name")}</h2>
					</div>
					<div className={styles.description}>
						{translate("app.description")}
					</div>
					<div className={styles.hr} />
				</div>
				<div>
					<h3 className="h3">{translate("login.text1")}</h3>
					<div className={styles.list}>
						<div className={styles.point}>
							<img src="/images/login.promo.1.svg" alt="" />
							<p>{translate("login.text2")}</p>
						</div>
						<div className={styles.point}>
							<img src="/images/login.promo.2.svg" alt="" />
							<p>{translate("login.text3")}</p>
						</div>
						<div className={styles.point}>
							<img src="/images/login.promo.3.svg" alt="" />
							<p>{translate("login.text4")}</p>
						</div>
					</div>
					{
						!isIOSApp
						&& !isAndroidApp
						&& <div className={styles.stores}>
							<a
								href={stores.apple_store}
								target="_blank"
							>
								<img src="/images/app-store.png" alt="" />
							</a>
							<a
								href={stores.play_google}
								target="_blank"
							>
								<img src="/images/google-play.png" alt="" />
							</a>
						</div>
					}

					<div style={{ marginTop: 40 }}>
						<Footer isAuth />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Promo;
