import styles from "./doctor-card.module.css";
import classNames from "classnames";
import { useTranslate } from "../../localization/translate";
import { useDispatch } from "react-redux";
import { yearsEnd } from "../../localization/years";
import { setSelectedDoctor } from "../../features/appointment/services/appointment.slice";

export const NoAvatarIcon = () => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.2796 11.0569C19.2796 9.04434 17.6995 7.41464 15.7481 7.41464C13.7967 7.41464 12.2165 9.04434 12.2165 11.0569C12.2165 13.0695 13.7967 14.6992 15.7481 14.6992C17.6995 14.6992 19.2796 13.0695 19.2796 11.0569ZM21.2976 11.0569C21.2976 14.219 18.814 16.7805 15.7481 16.7805C12.6822 16.7805 10.1985 14.219 10.1985 11.0569C10.1985 7.89487 12.6822 5.33334 15.7481 5.33334C18.814 5.33334 21.2976 7.89487 21.2976 11.0569ZM8.68501 23.6934C8.68501 24.6751 8.60416 24.5854 9.145 24.5854H22.8557C23.3965 24.5854 23.3156 24.6751 23.3156 23.6934C23.3156 21.3122 19.9819 19.9024 16.0003 19.9024C12.0188 19.9024 8.68501 21.3122 8.68501 23.6934ZM6.66699 23.6934C6.66699 19.6554 11.0046 17.8211 16.0003 17.8211C20.996 17.8211 25.3337 19.6554 25.3337 23.6934C25.3337 25.7918 24.5455 26.6667 22.8557 26.6667H9.145C7.45514 26.6667 6.66699 25.7918 6.66699 23.6934Z"
			fill="#54A0FF"
		/>
	</svg>
);

export default function DoctorCard({
	className,
	photoUrl,
	shortname,
	specialities = [],
	rating = "0",
	minPrice = "0",
	id,
	experienceYearsAll,
	showFooter = true,
	cardClick,
	advCallback = () => {},
	speciality,
}) {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const infoClick = (e) => {
		e.stopPropagation();
		advCallback();
		dispatch(
			setSelectedDoctor({
				id,
				specialityId: (!!speciality && +speciality) || specialities[0]?.id,
			})
		);
	};

	return (
		<div
			className={classNames(styles.card, className)}
			onClick={(e) => {
				if (cardClick && typeof cardClick === "function") {
					cardClick();
				} else {
					infoClick(e);
				}
			}}
		>
			<div className={styles.content}>
				<div className={styles.avatar}>
					{photoUrl ? (
						<img src={photoUrl} />
					) : (
						<div className={styles.no_avatar}>
							<NoAvatarIcon />
						</div>
					)}

					<div className={styles.info} onClick={infoClick}>
						<svg
							width="14"
							height="14"
							viewBox="0 0 14 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M6.99967 12.4168C9.99122 12.4168 12.4163 9.9917 12.4163 7.00016C12.4163 4.00862 9.99122 1.5835 6.99967 1.5835C4.00813 1.5835 1.58301 4.00862 1.58301 7.00016C1.58301 9.9917 4.00813 12.4168 6.99967 12.4168ZM6.99967 13.6668C10.6816 13.6668 13.6663 10.6821 13.6663 7.00016C13.6663 3.31826 10.6816 0.333496 6.99967 0.333496C3.31778 0.333496 0.333008 3.31826 0.333008 7.00016C0.333008 10.6821 3.31778 13.6668 6.99967 13.6668Z"
								fill="#7A8CA4"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M6.99967 5.54183C7.34485 5.54183 7.62467 5.82165 7.62467 6.16683V10.3335C7.62467 10.6787 7.34485 10.9585 6.99967 10.9585C6.6545 10.9585 6.37467 10.6787 6.37467 10.3335V6.16683C6.37467 5.82165 6.6545 5.54183 6.99967 5.54183Z"
								fill="#7A8CA4"
							/>
							<path
								d="M7.83301 3.66683C7.83301 4.12707 7.45991 4.50016 6.99967 4.50016C6.53944 4.50016 6.16634 4.12707 6.16634 3.66683C6.16634 3.20659 6.53944 2.8335 6.99967 2.8335C7.45991 2.8335 7.83301 3.20659 7.83301 3.66683Z"
								fill="#7A8CA4"
							/>
						</svg>
					</div>
				</div>

				<div className={styles.primary_content}>
					<div className={styles.name}>{shortname}</div>
					<div className={styles.speciality}>
						{specialities.map((spetiality) => spetiality.name).join(", ")}
					</div>
				</div>
			</div>

			{showFooter && (
				<div className={styles.footer}>
					{rating && (
						<div className={styles.rating}>
							<svg
								width="12"
								height="12"
								viewBox="0 0 12 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2.93108 11.3321C2.85496 11.3321 2.77933 11.3091 2.7152 11.2603C2.60212 11.1732 2.54558 11.027 2.56908 10.8834L3.12273 7.50082L0.77792 5.10817C0.677813 5.00559 0.641833 4.85172 0.684911 4.71324C0.728234 4.5722 0.843026 4.47218 0.981316 4.45167L4.22194 3.95672L5.67117 0.88192C5.7331 0.748567 5.86062 0.666504 6.00037 0.666504C6.14013 0.666504 6.26765 0.748567 6.32958 0.88192L7.7788 3.95672L11.0192 4.45167C11.1575 4.47218 11.2725 4.5722 11.3156 4.71324C11.3589 4.85172 11.3229 5.00559 11.2226 5.10817L8.87802 7.50082L9.43143 10.8834C9.45492 11.027 9.39838 11.1732 9.2853 11.2603C9.17222 11.345 9.02268 11.3578 8.89858 11.2886L6.00037 9.69345L3.10192 11.2886C3.04832 11.3193 2.98958 11.3321 2.93108 11.3321Z"
									fill="#F7236F"
								/>
							</svg>
							{rating}
						</div>
					)}
					{(experienceYearsAll || minPrice) && (
						<div
							className={classNames(styles.description, {
								[styles.desc_padding]: rating,
							})}
						>
							{!!experienceYearsAll && (
								<span>
									{translate("appointment.experience", true)}:{" "}
									{!!experienceYearsAll ? experienceYearsAll : "0"}{" "}
									{translate(yearsEnd(experienceYearsAll), true)}
								</span>
							)}

							{!!minPrice && (
								<span>
									{translate("appointment.minPrice", true)}:{" "}
									{(!!minPrice ? minPrice : "0").toLocaleString()}{" "}
									{translate("app.currencySign", true)}
								</span>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
}
