import styles from "./Modal.module.css";
import CloseModalButton from "../modal-items/close";
import Modal from "../modal-safari-wrapper/modal";
import classNames from "classnames";

export default function ModalInfo({
	iconType = "success",
	title,
	subtitle,
	subtitleStyles,
	text,
	textClass,
	children,
	afterOpenModal,
	modalStyle = {},
	isOpen,
	onRequestClose,
	bodyClass,
	overlayClassName,
	modalClassName = "",
}) {
	return (
		<Modal
			isOpen={isOpen}
			className={modalClassName}
			onAfterOpen={afterOpenModal}
			onRequestClose={onRequestClose}
			style={{
				content: {
					display: "flex",
					alignItems: "stretch",
				},
				...modalStyle,
			}}
			overlayClassName={overlayClassName}
		>
			<div className={classNames(styles.modal, bodyClass)}>
				<CloseModalButton closeModal={onRequestClose} />

				<div>{title && <div className={styles.title}>{title}</div>}</div>

				<div>
					{iconType === "failure" && (
						<div className={styles.svg}>
							<svg
								width="81"
								height="81"
								viewBox="0 0 81 81"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="40.5" cy="40.5" r="40" fill="#F7236F" />
								<path
									d="M40.5 17.5C38.2909 17.5 36.5 19.2909 36.5 21.5V44.5C36.5 46.7091 38.2909 48.5 40.5 48.5C42.7091 48.5 44.5 46.7091 44.5 44.5V21.5C44.5 19.2909 42.7091 17.5 40.5 17.5Z"
									fill="white"
								/>
								<path
									d="M40.5 63.5C43.2614 63.5 45.5 61.2614 45.5 58.5C45.5 55.7386 43.2614 53.5 40.5 53.5C37.7386 53.5 35.5 55.7386 35.5 58.5C35.5 61.2614 37.7386 63.5 40.5 63.5Z"
									fill="white"
								/>
							</svg>
						</div>
					)}
					{iconType === "error" && (
						<div className={styles.svg}>
							<svg
								width="81"
								height="80"
								viewBox="0 0 81 80"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="40.5" cy="40" r="40" fill="#F7236F" />
								<path
									d="M27.7098 22.8939C26.518 21.702 24.5857 21.702 23.3939 22.8939C22.202 24.0857 22.202 26.018 23.3939 27.2098L36.1841 40L23.3939 52.7902C22.202 53.982 22.202 55.9143 23.3939 57.1061C24.5857 58.298 26.518 58.298 27.7098 57.1061L40.5 44.3159L53.2902 57.1061C54.482 58.298 56.4143 58.298 57.6061 57.1061C58.798 55.9143 58.798 53.982 57.6061 52.7902L44.8159 40L57.6061 27.2098C58.798 26.018 58.798 24.0857 57.6061 22.8939C56.4143 21.702 54.482 21.702 53.2902 22.8939L40.5 35.6841L27.7098 22.8939Z"
									fill="white"
								/>
							</svg>
						</div>
					)}
					{iconType === "success" && (
						<div className={styles.svg}>
							<svg
								width="81"
								height="80"
								viewBox="0 0 81 80"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="40.5" cy="40" r="40" fill="#54A0FF" />
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M63.4309 25.2779C64.9343 26.8965 64.8408 29.4275 63.2222 30.9309L37.3822 54.9309C35.8372 56.3658 33.4436 56.3551 31.9116 54.9062L19.7516 43.4062C18.1465 41.8883 18.0759 39.3566 19.5938 37.7516C21.1117 36.1465 23.6434 36.0759 25.2485 37.5938L34.6847 46.5179L57.7779 25.0692C59.3965 23.5658 61.9275 23.6592 63.4309 25.2779Z"
									fill="white"
								/>
							</svg>
						</div>
					)}
					<div
						className={classNames("h3", styles.title2)}
						style={subtitleStyles}
					>
						{subtitle}
					</div>
					<div className={`${textClass} text`}>{text}</div>
				</div>
				{children}
			</div>
		</Modal>
	);
}
