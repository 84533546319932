import { useDispatch, useSelector } from "react-redux";
import {
	selectAccessToken,
	selectIsGuestMode,
	setResetPasswordInfo,
} from "../../services/login.slice";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "../magic-link/Magiclink.module.css";
import Loader from "../../../../components/loader";
import { useEffect } from "react";
import { setExaminationInfo } from "../../../appointment/services/appointment.slice";

/**
 * Если пользователь перешел по ссылке из письма, восстановления пароля, то необзодимо сохранить данные для востоановления пароля
 * и перенаправить на страницу лоигна (или на главную, если пользователь уже авторизован)
 * На странице логина отроектся маодльное окно для ввода нового пароля, если есть uid и token
 * */
export default function NewPassword() {
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isGuest = useSelector(selectIsGuestMode);
	const isAuth = !!useSelector(selectAccessToken);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (isAuth) {
			if (isGuest && params.uid && params.token) {
				const date = searchParams.get("date");
				const slotIdMis = Number(searchParams.get("slot_id"));
				const doctorId = Number(searchParams.get("doctor_id"));
				const serviceId = Number(searchParams.get("service_id"));
				const specialityId = Number(searchParams.get("speciality_id"));
				const additionalSlots =
					searchParams
						.get("additional_slots")
						?.split(",")
						?.map((sl) => ({ idMis: +sl })) || [];

				dispatch(
					setResetPasswordInfo({ uid: params.uid, token: params.token })
				);

				if (date || slotIdMis || doctorId || serviceId || specialityId) {
					dispatch(
						setExaminationInfo({
							magicLink: true,
							...(date && { lDate: date }),
							...(slotIdMis && { lSlotIdMis: slotIdMis }),
							...(doctorId && { lDoctorId: doctorId }),
							...(serviceId && { lServiceId: serviceId }),
							...(specialityId && { lSpecialityId: specialityId }),
							...(!!additionalSlots.length && {
								lAdditionalSlots: additionalSlots,
							}),
						})
					);
				}

				navigate("/login");
			} else {
				navigate("/events");
			}
		}
	}, [isAuth]);

	return (
		<div className={styles.loader_container}>
			<Loader />
		</div>
	);
}
