import { Navigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../../../components/loader";
import styles from "./Magiclink.module.css";
import { useAuthViaMagikLinkMutation } from "../../services/login.api";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAccessToken,
	selectIsGuestMode,
	setAuthInfo,
	setInvalidMagicLink,
	setLoginMethod,
} from "../../services/login.slice";
import { Metrics, loginGtmEvent } from "../../../../utils/metrics";
import {
	selectExaminationInfo,
	setExaminationInfo,
} from "../../../appointment/services/appointment.slice";
import { getDoctorSheduleByToken } from "../../../../utils";

export default function MagicLinkAuthorization() {
	const params = useParams();
	const [searchParams] = useSearchParams();
	const [authTrigger, { data, isLoading, error }] =
		useAuthViaMagikLinkMutation();
	const dispatch = useDispatch();
	const accessToken = useSelector(selectAccessToken);
	const guestMode = useSelector(selectIsGuestMode);
	const examinationInfo = useSelector(selectExaminationInfo);

	useEffect(() => {
		if (error) {
			dispatch(setInvalidMagicLink(true));
			Metrics.gtmEvent("auth_fail", { auth_way: "magiclink" });
		}
	}, [error]);

	useEffect(() => {
		if (!data && !isLoading && !error && accessToken && guestMode) {
			/**
			 * должен обязательно присутствовать accessToken в гостевом режиме
			 * */
			authTrigger({ uid: params.uid, token: params.token });

			const date = searchParams.get("date");
			const slotIdMis = Number(searchParams.get("slot_id"));
			const doctorId = Number(searchParams.get("doctor_id"));
			const serviceId = Number(searchParams.get("service_id"));
			const specialityId = Number(searchParams.get("speciality_id"));
			const additionalSlots =
				searchParams
					.get("additional_slots")
					?.split(",")
					?.map((sl) => ({ idMis: +sl })) || [];

			if (date || slotIdMis || doctorId || serviceId || specialityId)
				dispatch(
					setExaminationInfo({
						magicLink: true,
						...(date && { lDate: date }),
						...(slotIdMis && { lSlotIdMis: slotIdMis }),
						...(doctorId && { lDoctorId: doctorId }),
						...(serviceId && { lServiceId: serviceId }),
						...(specialityId && { lSpecialityId: specialityId }),
						...(!!additionalSlots.length && {
							lAdditionalSlots: additionalSlots,
						}),
					})
				);
		}
		if (data && data.data?.access) {
			//если успешная авторизация
			dispatch(
				setAuthInfo({
					accessToken: data.data.access,
					refreshToken: data.data.refresh,
					isGuest: false,
				})
			);

			if (examinationInfo?.magicLink) {
				getDoctorSheduleByToken(data.data.access, {
					dateFrom: examinationInfo.lDate,
					dateTo: examinationInfo.lDate,
					doctorId: examinationInfo.lDoctorId,
					specialityId: examinationInfo.lSpecialityId,
				})
					.then((data) => {
						const timeSlot = data.timeSlots.find(
							({ idMis }) => idMis === examinationInfo.lSlotIdMis
						);

						dispatch(
							setExaminationInfo({
								magicLink: true,
								acceptAdultsShowWarning: data.doctorInfo.doctor.acceptAdults,
								doctor: data.doctorInfo.doctor,
								date: `${timeSlot?.time}`,
								cabinet: timeSlot?.cabinet,
								slotIdMis: timeSlot?.idMis,
								specialityId: examinationInfo.lSpecialityId,
								receptionTypes: data.doctorInfo.speciality.appointmentFeature,
								additionalSlots: examinationInfo.lAdditionalSlots,
								serviceId: examinationInfo.lServiceId,
							})
						);
					})
					.catch((error) => {
						console.log(error.message);
					});
			}

			loginGtmEvent(data.data.access, "magiclink");
		}
	}, [data, accessToken, guestMode]);

	if (guestMode && accessToken) {
		// Если пользователь уже был авторизован
		// return <Navigate to="/"/>
	}

	if (!params.uid || !params.token) {
		return <Navigate to="/login" />;
	}

	if (error) {
		dispatch(setLoginMethod("byLogin"));

		return <Navigate to="/login" />;
	}

	if (isLoading || !data) {
		return (
			<div className={styles.loader_container}>
				<Loader />
			</div>
		);
	}

	if (data) {
		return (
			<div className={styles.loader_container}>
				<Loader />
			</div>
		);
	}
}
