import { createApi } from "@reduxjs/toolkit/query/react";
import baseConfig from "../../../app/config.api";

export const historyApi = createApi({
	reducerPath: "historyApi",
	baseQuery: baseConfig,
	endpoints: (builder) => ({
		getHistory: builder.query({
			query: (medCardNumber) => `/history/result/${medCardNumber}`,
			transformResponse: (response) => response.data.results,
		}),
		getSnapshots: builder.query({
			query: (medCardNumber) => `/history/xray/${medCardNumber}`,
			transformResponse: (response) => response.data.xrays,
		}),
		getAssignments: builder.query({
			query: (medCardNumber) => `/history/assignment/${medCardNumber}`,
			transformResponse: (response) => response.data.assignments,
		}),
		getSnapshot: builder.mutation({
			query: (idStudyMis) => ({
				method: "POST",
				body: {
					idStudyMis,
				},
				url: "/history/xray/view/",
			}),
		}),
	}),
});

export const {
	useGetHistoryQuery,
	useGetSnapshotsQuery,
	useGetAssignmentsQuery,
	useGetSnapshotMutation,
} = historyApi;
