import { useEffect } from "react";
import NotificationFull from "../../components/notification-full";
import styles from "./style.module.css";

export default function NotificationBlock({ notification, onRead, onDelete }) {
	useEffect(() => {
		if (!notification.isRead) onRead([notification.id]);
	}, [notification.id]);

	return (
		<div className={styles.notification_block}>
			{notification && (
				<NotificationFull
					title={notification.title}
					description={notification.description}
					date={notification.receiveDate}
					image={notification.image}
					infoUrl={notification.infoUrl}
					category={notification.category}
					onDelete={() => onDelete([notification.id])}
				/>
			)}
		</div>
	);
}
