import { useState } from "react";
import { useTranslate } from "../../../../../../localization/translate";
import Button from "../../../../../../components/button/button";
import QrModal from "./index";

export default function QrModalWrapper({ uid = null, options = {} }) {
	const translate = useTranslate();
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);

	return (
		<>
			<Button light onClick={handleOpen}>
				{translate("profile.qr.view")}
			</Button>

			{isOpen && (
				<QrModal
					isOpen={isOpen}
					onClose={handleClose}
					uid={uid}
					options={options}
				/>
			)}
		</>
	);
}
