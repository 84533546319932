import styles from "./doctors-appointment.module.css";
import Search from "../search";
import React, {
	useEffect,
	useMemo,
	useRef,
	useState,
	useCallback,
} from "react";
import Button from "../../../../components/button/button";
import arrow from "../../../../icons/arrow_blue_24x24.svg";
import { useGetSpecialityQuery } from "../../services/appointment.api";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import FrequentQuery from "../search/frequent-queries";
import { scrollToPosition, isAndroidApp, isIOSApp } from "../../../../utils";
import { Metrics } from "../../../../utils/metrics";

const isCyrillic = (char) => /[а-яА-ЯЁё]/.test(char);

export default function SpecialityList({ isPublic = false, isWidget = false }) {
	const { data } = useGetSpecialityQuery();
	const [specialties, setSpecialties] = useState({});
	const location = useLocation();

	const searchRef = useRef(null);
	const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);

	const observerHandler = useCallback(
		([entrie]) => setShowScrollTopBtn(!entrie.isIntersecting),
		[setShowScrollTopBtn]
	);

	const observer = useMemo(
		() =>
			new IntersectionObserver(observerHandler, {
				rootMargin: "20px",
				threshold: 0,
			}),
		[observerHandler]
	);

	const getSortedKeys = (specialties) => {
		const keys = Object.keys(specialties ?? {}) || [];
		const other = specialties["#"] ? ["#"] : [];
		const cyrillic = keys.filter((key) => isCyrillic(key) && key !== "#");
		const latinic = keys.filter((key) => !isCyrillic(key) && key !== "#");
		return [...other, ...cyrillic.sort(), ...latinic.sort()];
	};

	const handleAlphabetClick = (key) => {
		setClass(classNames(styles.secondary_list, styles.hidden));
		setkey(key);
	};

	const [secondListClass, setClass] = useState(styles.secondary_list);
	const [key, setkey] = useState(null);

	useEffect(() => {
		if (secondListClass.includes(styles.hidden) && key) {
			document.getElementById(key).scrollIntoView({
				behavior: "smooth",
				block: "start",
				inline: "center",
			});
			setkey(null);
		}
	}, [secondListClass, key]);

	useEffect(() => {
		if (data) {
			const list = {};

			data.forEach((speciality) => {
				const key = speciality.name[0].toUpperCase();

				if (Number.isInteger(Number(key)) || speciality.name.length === 1) {
					if (list["#"]) {
						list["#"].push(speciality);
					} else {
						list["#"] = [speciality];
					}
				} else if (list[key]) {
					list[key].push(speciality);
				} else {
					list[key] = [speciality];
				}
			});

			setSpecialties(list);
		}
	}, [data]);

	useEffect(() => {
		observer.observe(searchRef.current);
		return () => observer.disconnect();
	}, []);

	const handleScrollTop = () => scrollToPosition(0, "smooth");

	const sortedKeys = useMemo(() => getSortedKeys(specialties), [specialties]);

	return (
		<div className={styles.doctors_appointment}>
			<div ref={searchRef}>
				<Search
					className={styles.search}
					isPublic={isPublic}
					isWidget={isWidget}
				/>
			</div>
			<FrequentQuery
				isPublic={isPublic}
				isWidget={isWidget}
				className={secondListClass}
			/>
			{sortedKeys?.length > 0 && (
				<div className={styles.primary_list}>
					<div className={styles.alphabet}>
						{sortedKeys.map((char) => (
							<div
								onClick={() => handleAlphabetClick(`char-${char}`)}
								className={styles.char}
								key={`char-${char}`}
							>
								{char.toUpperCase()}
							</div>
						))}
					</div>
					<div className={styles.list}>
						{sortedKeys.map((char) => (
							<div key={`char-${char}`} id={`char-${char}`}>
								<div className={styles.section_title}>{char}</div>
								{specialties[char] &&
									specialties[char].map((row) => (
										<NavLink
											className={styles.row}
											key={`row-${char}-${row.id}`}
											to={`/${
												isPublic || isWidget ? "schedule" : "appointment"
											}/speciality/${row.id}${location.search}`}
										>
											{row.name}
										</NavLink>
									))}
							</div>
						))}
					</div>
				</div>
			)}

			{showScrollTopBtn && !isWidget && (
				<Button
					className={classNames(styles.top_btn, {
						[styles.mobile]: isAndroidApp || isIOSApp,
					})}
					small
					light
					onClick={handleScrollTop}
				>
					<img src={arrow} />
				</Button>
			)}
		</div>
	);
}
