import classNames from "classnames";
import moment from "moment";
import { useTranslate } from "../../../../../../localization/translate";
import {
	convertDate,
	moscowTimeZone,
} from "../../../../../../utils/dates/timezone-converter";
import styles from "./style.module.css";

export default function RequestItem({
	date,
	number,
	summary,
	topic,
	isInWork,
	isClosed,
	count,
}) {
	const translate = useTranslate();

	return (
		<div className={styles.request}>
			<div className={styles.header}>
				<div className={styles.date}>
					{moment(date).format("DD MMMM YYYY, HH:mm")}
					{moscowTimeZone() ? "" : translate("app.mskTime")}
				</div>

				<div className={styles.number}>{number}</div>

				{count > 0 && <div className={styles.unreaded_marker} />}
			</div>

			<div className={styles.content}>
				<div className={styles.left}>
					<div className={styles.description}>{summary}</div>
					{topic && (
						<div className={styles.theme}>
							{translate("profile.support.requestCard.theme")}&nbsp;{topic}
						</div>
					)}
				</div>

				<div className={styles.right}>
					<button
						className={classNames(styles.status_btn, {
							[styles.open]: !isClosed,
						})}
					>
						{isClosed
							? translate("profile.support.requestCard.close")
							: (
								isInWork ? translate("profile.support.requestCard.isInWork")
								: translate("profile.support.requestCard.open")
							)
						}
					</button>
				</div>
			</div>
		</div>
	);
}
