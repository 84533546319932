import css from "./error.module.css"
import errorPicture from "../../icons/error-picture.svg"
import { useTranslate } from "../../localization/translate";
import Button from "../button/button";

export default function Error({ status, message }) {
	const t = useTranslate()

	return (
		<div className={css.wrapper}>
			<div className={css.container}>
				<img src={errorPicture} alt="" className={css.picture} />
				<h1>{t("app.SomethingWentWrong")}</h1>
				<div className={css.text}>{t("app.SomethingWentWrongText")}</div>
			</div>
			<Button
				onClick={() => window.location.reload()}
				className={css.button}
				small={true}
			>
				{t("app.SomethingWentWrongButton")}
			</Button>
		</div>
	);
}
