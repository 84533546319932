import classNames from "classnames";
import { useTranslate } from "../../../../localization/translate";
import { useGetDiscountDataQuery } from "../../services/profile.api";
import { useRouterDynamicSegment } from "./hooks/useRouterDynamicSegment";
import InfoBannerWrapper from "./components/InfoBlockWrapper";
import styles from "./number-coupon.module.css";
import { useEffect } from "react";
import { Metrics } from "../../../../utils/metrics";

const NumberCoupon = () => {
	const translate = useTranslate();
	const id = useRouterDynamicSegment(0);

	const { data: discounts, isLoading, error } = useGetDiscountDataQuery(null);

	useEffect(() => {
		Metrics.gtmEvent("view_discount_item", {
			discount_type: "number-coupon",
		});
	}, []);

	return (
		<div className={styles.number_coupon}>
			<div className={classNames("h3", styles.title)}>
				{translate("profile.discounts.numberCoupon.title")}
			</div>

			<div className={styles.content_block}>
				<InfoBannerWrapper
					title={translate("profile.discounts.numberCoupon.whatIt")}
					list={translate("profile.discounts.numberCoupon.infoList")}
					learMoreLink="https://www.medicina.ru/patsientam/diskontnye-programmy/"
				/>
			</div>
		</div>
	);
};

export default NumberCoupon;
