import { createApi } from "@reduxjs/toolkit/query/react";
import baseConfig from "../../../app/config.api";

export const receivedApi = createApi({
	reducerPath: "receivedApi",
	baseQuery: baseConfig,
	tagTypes: [],
	endpoints: (builder) => ({
		getReceivedBill: builder.query({
			query: (id) => `/invoice/${id}/receive`,
			transformResponse: (response) => response.data,
		}),
		createReceivedBill: builder.mutation({
			query: (id) => ({
				method: "GET",
				url: `/invoice/${id}/receive`,
			}),
			transformResponse: (response) => response.data,
		}),
	}),
});

export const { useGetReceivedBillQuery, useCreateReceivedBillMutation } =
	receivedApi;
