import styles from "./Magiclink.module.css";
import Modal from "../../../../components/modal-safari-wrapper/modal";
import Button from "../../../../components/button/button";
import { useTranslate } from "../../../../localization/translate";
import { useEffect, useState } from "react";
import CloseModalButton from "../../../../components/modal-items/close";
import Input from "../../../../components/input";
import { useDispatch, useSelector } from "react-redux";
import {
	resetLogin,
	selectLogin,
	setRequestLinkIsOpen,
	selectRequestLinkIsOpen,
} from "../../services/login.slice";
import { magicLinkValidation } from "../../services/utils";
import { useRequestMagicLinkMutation } from "../../services/login.api";
import {
	getErrorMessageFromError,
	getFromClarificationByTarget,
} from "../../../../app/error-handler.api";
import ModalInfo from "../../../../components/custom-modal/info";
import classNames from "classnames";
import MedCardForm from "../med-card-form";
import { useMedCardConfirmMagiclinkMutation } from "../../services/login.api";
import CreateIssue from "../../../profile/modules/support/components/CreateIssue";
import { Metrics } from "../../../../utils/metrics";
import { selectExaminationInfo } from "../../../appointment/services/appointment.slice";

const modalStyle = {
	content: {
		padding: "0",
		display: "flex",
		alignItems: "center",
	},
	overlay: {
		display: "flex",
		alignItems: "center",
		zIndex: 180,
	},
};
const successModalStyle = {
	content: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "100px 72px 96px",
	},
	overlay: {
		display: "flex",
		alignItems: "center",
		zIndex: 190,
	},
};

export default function RequestMagicLink({ isWidget }) {
	const [requestLinkTrigger, { data, error: requestError, isLoading }] =
		useRequestMagicLinkMutation();
	const translate = useTranslate();
	const dispatch = useDispatch();
	const isOpen = useSelector(selectRequestLinkIsOpen);
	const closeModal = () => dispatch(setRequestLinkIsOpen(false));
	const reduxLogin = useSelector(selectLogin);
	const [login, setLogin] = useState(reduxLogin);
	const [error, setError] = useState(null);

	const [verifyMedCard, setVerifyMedCard] = useState(false);
	const [success, setSuccess] = useState(false);

	const [isOpenCreateIssue, setIsOpenCreateIssue] = useState(false);

	const examinationInfo = useSelector(selectExaminationInfo);

	const isDisabled = () => {
		if (login.length === 0) return true;
		if (isLoading) return true;
		if (error) return true;

		return false;
	};

	const handleSendMagicLink = (email) => {
		requestLinkTrigger({
			login: email,
			...(examinationInfo?.date && {
				date: examinationInfo.date.split(" ")[0],
			}),
			...(examinationInfo?.doctor?.id && {
				doctorId: examinationInfo.doctor.id,
			}),
			...(examinationInfo?.slotIdMis && {
				slotId: examinationInfo.slotIdMis,
			}),
			...(examinationInfo?.serviceId && {
				serviceId: examinationInfo.serviceId,
			}),
			...(examinationInfo?.specialityId && {
				specialityId: examinationInfo.specialityId,
			}),
			...(examinationInfo?.additionalSlots?.length && {
				additionalSlots: examinationInfo.additionalSlots.map((s) => s.idMis),
			}),
		});
		Metrics.gtmEvent("auth_magiclink");
	};

	useEffect(() => {
		return () => dispatch(resetLogin());
	}, []);

	useEffect(() => {
		const err = getErrorMessageFromError({
			error: requestError,
			fromClarifications: true,
		});

		if (err) {
			setError(getFromClarificationByTarget(err, "login")?.message);
		}
	}, [requestError]);

	useEffect(() => {
		if (data && data.data?.s) {
			setVerifyMedCard(true);
		} else if (data) {
			setSuccess(true);
		}
	}, [data]);

	const openModal = () => {
		setLogin(reduxLogin);
		dispatch(setRequestLinkIsOpen(true));
	};

	const makeValidation = (val) => {
		if (val.length > 0) {
			const validation = magicLinkValidation(val);
			if (!validation.valid) {
				const errors = validation.messages.map((msg) => translate(msg, true));
				setError(errors.join("; "));
			}
		}
	};

	useEffect(() => {
		if (isOpen) {
			setError(null);
			makeValidation(login);
		}
	}, [isOpen]);

	const handleSuccess = () => {
		setSuccess(true);
		setVerifyMedCard(false);
	};

	if (verifyMedCard) {
		return (
			<MedCardForm
				type="magiclink"
				isOpen={verifyMedCard}
				fetchHook={useMedCardConfirmMagiclinkMutation}
				hash={data.data.s}
				onSuccess={handleSuccess}
				onClose={() => setVerifyMedCard(false)}
				isWidget={isWidget}
			/>
		);
	}

	if (data && success) {
		return (
			<>
				<Button light onClick={openModal}>
					{translate("login.loginWithoutPassword", true)}
				</Button>
				<ModalInfo
					isOpen={isOpen}
					onRequestClose={closeModal}
					subtitle={
						<>{translate("login.magicLink.requestLink.success")} e&#8209;mail</>
					}
					// text={data.data.emailMasked} MED-1545
					modalStyle={{
						...successModalStyle,
						overlay: {
							...successModalStyle.overlay,
							alignItems: isWidget ? "flex-start" : "center",
						},
					}}
					textClass={styles.margin_top}
					iconType="success"
					bodyClass={styles.modal}
					modalClassName={classNames(styles.modal, styles.success)}
				>
					<div className={`${styles.margin_top} text`}>
						{translate("login.magicLink.requestLink.followInstruction", true)}
					</div>
					<div className={styles.doNotReceiveEmail}>
						{translate("login.magicLink.requestLink.doNotReceiveEmail")}
					</div>
					<div
						onClick={() => setIsOpenCreateIssue(true)}
						className={styles.contactSupport}
					>
						{translate("login.sms.contactSupport")}
					</div>
					<Button light className={styles.margin_top} onClick={closeModal}>
						{translate("login.magicLink.requestLink.backToLogin", true)}
					</Button>
					<CreateIssue
						isOpen={isOpenCreateIssue}
						handleClose={() => setIsOpenCreateIssue(false)}
						initEmail={login.includes("@") ? login : ""}
						skipFetchProfile
					/>
				</ModalInfo>
			</>
		);
	}

	return (
		<>
			<Button light onClick={openModal}>
				{translate("login.loginWithoutPassword", true)}
			</Button>
			<ModalContainer
				closeModal={closeModal}
				isOpen={isOpen}
				style={{
					overlay: {
						alignItems: isWidget ? "flex-start" : "center",
						zIndex: 190
					},
				}}
			>
				<div className={styles.body}>
					<div className={`${styles.title} h3`}>
						{translate("login.magicLink.requestLink.title", true)}
					</div>
					<div className={styles.frame}>
						<div className={`${styles.description} text`}>
							{translate("login.magicLink.requestLink.description")}
						</div>
						<Input
							type="text"
							value={login}
							onBlur={(val) => makeValidation(val)}
							onChange={(val) => {
								setError(null);
								setLogin(val);
							}}
							onKeyUp={(e) =>
								e.key === "Enter" && !isDisabled() && handleSendMagicLink(login)
							}
							errorMsg={error}
							placeholder={translate(
								"login.magicLink.requestLink.placeholder",
								true
							)}
						/>
						<Button
							disabled={isDisabled()}
							onClick={() => handleSendMagicLink(login)}
							className={styles.btn}
						>
							{translate("login.magicLink.requestLink.btn", true)}
						</Button>
					</div>
				</div>
			</ModalContainer>
		</>
	);
}

const ModalContainer = ({ children, closeModal, isOpen, style }) => {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			style={{ ...modalStyle, ...style }}
		>
			<div className={styles.modal}>
				<CloseModalButton closeModal={closeModal} />
				{children}
			</div>
		</Modal>
	);
};
