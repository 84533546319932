import styles from "./Login.module.css";
import Button from "../../../components/button/button";
import Input from "../../../components/input";
import { useTranslate } from "../../../localization/translate";
import { useEffect, useState } from "react";
import { loginValidation } from "../services/utils";
import { useLoginMutation } from "../services/login.api";
import {
	getErrorMessageFromError,
	getFromClarificationByTarget,
} from "../../../app/error-handler.api";
import Loader from "../../../components/loader";
import {
	resetLogin,
	setLogin,
	selectLogin,
	selectRequestLinkIsOpen,
	setAuthInfo,
} from "../services/login.slice";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import LoginSelector from "./login-selector";
import CreateIssue from "../../profile/modules/support/components/CreateIssue";
import ModalInfo from "../../../components/custom-modal/info";
import { Metrics, loginGtmEvent } from "../../../utils/metrics";

const modalStyle = {
	content: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	overlay: {
		display: "flex",
		alignItems: "center",
	},
};

export default function ByLogin({ onHide }) {
	const [loginTrigger, { data, error, isLoading }] = useLoginMutation();
	const login = useSelector(selectLogin);
	const isDisabled = () => {
		if (login.length === 0 || password.length === 0) {
			return true;
		}

		if (loginError) {
			return true;
		}

		if (isLoading) {
			return true;
		}

		return false;
	};

	const translate = useTranslate();
	const [password, setPassword] = useState("");
	const [loginError, setLoginError] = useState(null);
	const dispatch = useDispatch();
	const magicLinkIsOpen = useSelector(selectRequestLinkIsOpen);
	const [serverError, setServerError] = useState();
	const passwordType = "password";
	const [isOpen, setIsOpen] = useState();
	const [isOpenCreateIssue, setIsOpenCreateIssue] = useState(false);

	useEffect(() => {
		if (data) {
			//если успешная авторизация
			dispatch(
				setAuthInfo({
					accessToken: data.data.access,
					refreshToken: data.data.refresh,
					isGuest: false,
				})
			);
			loginGtmEvent(data.data.access, "login_password");
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			const clarifications = getErrorMessageFromError({
				error,
				fromClarifications: true,
			});
			if (clarifications) {
				setServerError(
					getFromClarificationByTarget(clarifications, "credentials")
				);
			}
			const error2008 = getErrorMessageFromError({
				error,
				code: 2008,
				status: 400,
			});
			if (error2008) {
				setIsOpen(true);
			}

			Metrics.gtmEvent("auth_fail", { auth_way: "login_password" });
		}
	}, [error]);

	useEffect(() => {
		return () => dispatch(resetLogin());
	}, []);

	useEffect(() => onHide?.(isOpen), [isOpen]);

	return (
		<>
			{serverError && (
				<div className={styles.server_error}>{serverError.message}</div>
			)}
			<Input
				onChange={(val) => {
					dispatch(setLogin(val));
					setLoginError(null);
				}}
				type="text"
				autocapitalize="off"
				onBlur={(val) => {
					if (val.length > 0) {
						const validation = loginValidation(val);
						if (!validation.valid) {
							const errors = validation.messages.map((msg) =>
								translate(msg, true)
							);
							setLoginError(errors.join("; "));
						}
					}
				}}
				errorMsg={magicLinkIsOpen ? null : loginError}
				placeholder={translate("login.loginCouple.loginField", true)}
				value={login}
			/>
			<Input
				value={password}
				onChange={setPassword}
				containerClass={styles.password}
				type={passwordType}
				isPassword
				placeholder={translate("login.loginCouple.password", true)}
			/>
			<Button
				onClick={() => {
					loginTrigger({ login, password });
					Metrics.gtmEvent("auth_password", { auth_way: "login_password" });
				}}
				disabled={isDisabled()}
				className={styles.btn}
			>
				{isLoading ? (
					<Loader
						containerStyle={{ margin: "-5px 0 -10px" }}
						width={30}
						height={30}
					/>
				) : (
					translate("login.loginBtn", true)
				)}
			</Button>
			<ModalInfo
				isOpen={isOpen}
				modalStyle={modalStyle}
				onRequestClose={() => setIsOpen(false)}
				subtitle={translate("login.limitIsOver")}
				text={translate("login.limitIsOverHelper", true)}
				textClass={styles.margin_top}
				iconType="error"
				modalClassName={classNames(styles.react_modal, styles.fail)}
			>
				<div
					onClick={() => setIsOpenCreateIssue(true)}
					className={styles.contactSupport}
				>
					{translate("login.sms.contactSupport")}
				</div>
				<CreateIssue
					isOpen={isOpenCreateIssue}
					handleClose={() => setIsOpenCreateIssue(false)}
					skipFetchProfile
				/>
				<LoginSelector
					onSelect={() => setIsOpen(false)}
					advancedFunctional={false}
				/>
			</ModalInfo>
		</>
	);
}
