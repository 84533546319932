import { useEffect, useState } from "react";
import { useGetSupportIssueByIdQuery } from "../../../services/profile.api";
import { useDispatch } from "react-redux";
import { InitIssue } from "../contexts/issue-context";

export default function useIssueContext(id) {
	const dispatch = useDispatch();
	const [issue, setIssue] = useState(InitIssue);

	const { data, isLoading, refetch } = useGetSupportIssueByIdQuery(id);

	useEffect(() => {
		setIssue(data ?? InitIssue);

		if (data) {
			dispatch({
				type: "profileApi/invalidateTags",
				payload: ["SupportCounters"],
			});
		}
	}, [data]);
	
	useEffect(() => refetch(), []);

	const addComment = (commnet) =>
		setIssue((prev) => ({ ...prev, history: [...prev.history, commnet] }));

	const deleteComment = (commnet) =>
		setIssue((prev) => ({
			...prev,
			history: prev.history.filter(
				(item) => item.creationDate !== commnet.creationDate
			),
		}));

	return {
		issue,
		setIssue,
		isLoading,
		addComment,
		deleteComment,
	};
}
