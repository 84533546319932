import SearchInput from "../../../../components/search-input/search-input";
import { useEffect, useRef, useState } from "react";
import styles from "./search.module.css";
import classNames from "classnames";
import { useOnClickOutside } from "../../../../utils";
import { useTranslate } from "../../../../localization/translate";
import FrequentQuery from "./frequent-queries";
import { useGetHintsMutation } from "../../services/appointment.api";
import { useLocalStorage } from "beautiful-react-hooks";
import { useDispatch } from "react-redux";
import { setHistorySearch } from "../../services/appointment.slice";
import Loader from "../../../../components/loader";
import SearchItem from "./search-results";
import { Metrics } from "../../../../utils/metrics";

const REQUEST_DELAY_MS = 500;

export default function Search({
	className,
	isPublic = false,
	isWidget = false,
}) {
	const focusHandler = (e) => {
		setOpened(true);
	};
	const input = useRef();
	const [isOpened, setOpened] = useState(false);
	const [value, setValue] = useState("");
	const dropdown = useRef();
	useOnClickOutside(dropdown, () => setOpened(false), false);
	const translate = useTranslate();
	const [delay, setDelay] = useState(null);
	const [history, setHistory] = useLocalStorage("history-search", []);
	const [search, { data: searchResults, isLoading, error }] =
		useGetHintsMutation();
	const dispatch = useDispatch();
	const [response, setResponse] = useState(null);

	useEffect(() => {
		if (!isLoading) {
			setResponse(null);
		}
	}, [isLoading]);

	useEffect(() => {
		if (input.current) {
			input.current.addEventListener("focus", focusHandler);
		}

		return () =>
			input.current && input.current.removeEventListener("focus", focusHandler);
	});

	useEffect(() => {
		/*
		 * Эффект отвечает за задержку между остановкой ввода символов и отрпавкой запроса
		 * */
		if (value.length > 1 && !delay) {
			setDelay(
				setTimeout(() => {
					response?.abort();
					setResponse(search(value));
					if (value?.length > 1) {
						Metrics.gtmEvent("search", { query: value });
						Metrics.gtmEvent("search_appointment", { query: value });
					}
				}, REQUEST_DELAY_MS)
			);
		}

		if (delay) {
			clearTimeout(delay);

			if (value.length > 1) {
				setDelay(
					setTimeout(() => {
						response?.abort();
						setResponse(search(value));
						if (value?.length > 1) {
							Metrics.gtmEvent("search", { query: value });
							Metrics.gtmEvent("search_appointment", { query: value });
						}
					}, REQUEST_DELAY_MS)
				);
			}
		}

		return () => {
			if (delay) {
				clearTimeout(delay);
			}
		};
	}, [value]);

	useEffect(() => {
		/*
		 * Эффект отвечает за сохранение истории поиска
		 * */
		if (searchResults) {
			const { doctors = [], services = [], specialities = [] } = searchResults;
			const res = [];
			history.concat(doctors, services, specialities).forEach((item) => {
				if (item.shortname) {
					if (
						res.find(
							(doctor) =>
								doctor.shortname === item.shortname && doctor.id === item.id
						)
					) {
						return null;
					}
				}
				if (item.name) {
					if (
						res.find(
							(speciality) =>
								speciality.name === item.name && speciality.id === item.id
						)
					) {
						return null;
					}
				}
				if (item.service) {
					if (
						res.find(
							(service) =>
								service.service && service.service.code === item.service.code
						)
					) {
						return null;
					}
				}

				res.push(item);
			});

			setHistory(res.slice(-10));
			dispatch(setHistorySearch(res.slice(-10)));
		}
	}, [searchResults]);

	return (
		<div
			className={classNames(styles.container, { [styles.expanded]: isOpened })}
		>
			<div
				className={classNames(styles.dropdown, { [styles.expanded]: isOpened })}
				ref={dropdown}
			>
				<div className={styles.header}>
					<SearchInput
						className={classNames(className, styles.input_container)}
						ref={input}
						value={value}
						onChange={setValue}
					/>
					{isOpened && (
						<div
							className={styles.button}
							onClick={() => {
								setOpened(false);
								setValue("");
							}}
						>
							{translate("app.cancel", true)}
						</div>
					)}
				</div>
				{isOpened && value.length === 0 && (
					<FrequentQuery
						isPublic={isPublic}
						isWidget={isWidget}
						onSelectItem={() => {
							setOpened(false);
							setValue("");
						}}
					/>
				)}
				{isOpened && !isLoading && searchResults && value.length > 0 && (
					<Results
						isPublic={isPublic}
						isWidget={isWidget}
						searchResults={searchResults}
						setOpened={() => {
							setOpened(false);
							setValue("");
						}}
					/>
				)}
				{isOpened && isLoading && (
					<div className={styles.loader}>
						<Loader />
					</div>
				)}
				{isOpened && value.length > 0 && (
					<HasNoMatches
						searchResults={searchResults}
						search={value}
						error={error}
					/>
				)}
			</div>
		</div>
	);
}

const Results = ({
	searchResults,
	setOpened,
	isPublic = false,
	isWidget = false,
}) => {
	const result = [];
	const translate = useTranslate();
	const pushItems = (list = []) =>
		list.forEach((item, i) =>
			result.push(
				<SearchItem
					isPublic={isPublic}
					isWidget={isWidget}
					item={item}
					isLast={i + 1 === list.length}
					onSelectItem={setOpened}
				/>
			)
		);

	if (searchResults.specialities && searchResults.specialities.length) {
		result.push(
			<div className={styles.search_cat_title}>
				{translate("appointment.searchFiled.specialities")}
			</div>
		);
		pushItems(searchResults.specialities);
	}

	if (searchResults.doctors && searchResults.doctors.length) {
		result.push(
			<div className={styles.search_cat_title}>
				{translate("appointment.searchFiled.doctors")}
			</div>
		);
		pushItems(searchResults.doctors);
	}

	if (searchResults.services && searchResults.services.length) {
		result.push(
			<div className={styles.search_cat_title}>
				{translate("appointment.searchFiled.services")}
			</div>
		);
		pushItems(searchResults.services);
	}

	return result;
};

const HasNoMatches = ({ searchResults, search, error }) => {
	const translate = useTranslate();
	const notFound = (
		<div className={classNames("text", styles.not_found)}>
			{translate("appointment.searchFiled.notFound1")}
			{search}
			{translate("appointment.searchFiled.notFound2")}
		</div>
	);

	if (!searchResults && !error) {
		return null;
	} else if (!searchResults && error) {
		return notFound;
	} else {
		if (
			!searchResults.services ||
			!searchResults.specialities ||
			!searchResults.doctors ||
			(searchResults.services.length === 0 &&
				searchResults.specialities.length === 0 &&
				searchResults.doctors.length === 0)
		) {
			return notFound;
		}
	}

	return null;
};
