import css from "./selectors.module.css";
import arrow from "../../../../icons/arrow_down_24x24.svg";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { setSubjectOpinion } from "../../services/appointment.slice";

export default function SpecialitySelector({
	userSelection,
	setUserSelection,
	data,
}) {
	const dispatch = useDispatch();
	const onClick = (id) => {
		setUserSelection({
			...userSelection,
			subjectId: id,
		});
		dispatch(setSubjectOpinion("ServiceSelector"));
	};

	return (
		<div className={css.list}>
			{data.map((subject) => (
				<div
					key={`subject ${subject.id}`}
					className={css.item}
					onClick={() => onClick(subject.id)}
				>
					<span className={classNames("text", css.speciality_name)}>
						{subject.speciality.name}
					</span>
					<span className={css.arrow}>
						<img src={arrow} alt="" />
					</span>
				</div>
			))}
		</div>
	);
}
