import classNames from "classnames";
import search from "./search-input.module.css";
import { useTranslate } from "../../localization/translate";
import React, { useEffect, useState } from "react";

const SearchInput = React.forwardRef((props, ref) => {
	const translate = useTranslate();
	const [value, setValue] = useState(props.value);
	useEffect(() => {
		setValue(value);
	}, [props.value]);

	return (
		<div className={classNames(search.container, props.className)}>
			<div className={search.icon}>
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M6.28648 0C9.7584 0 12.573 2.81368 12.573 6.28454C12.573 7.71093 12.0976 9.02633 11.2966 10.0811L15.7493 14.5375C16.0838 14.8723 16.0835 15.4149 15.7486 15.7494C15.4136 16.0838 14.8708 16.0835 14.5363 15.7487L10.0842 11.2932C9.0291 12.0939 7.7133 12.5691 6.28648 12.5691C2.81455 12.5691 0 9.7554 0 6.28454C0 2.81368 2.81455 0 6.28648 0ZM6.28648 1.71387C3.76145 1.71387 1.71448 3.76022 1.71448 6.28454C1.71448 8.80887 3.76145 10.8552 6.28648 10.8552C8.8115 10.8552 10.8585 8.80887 10.8585 6.28454C10.8585 3.76022 8.8115 1.71387 6.28648 1.71387Z"
						fill="#7A8CA4"
					/>
				</svg>
			</div>
			<input
				type="text"
				value={props.onChange ? props.value : value}
				placeholder={
					props.placeholder
						? props.placeholder
						: translate("appointment.searchPlaceholder", true)
				}
				onChange={(e) => {
					if (props.onChange) {
						props.onChange(e.target.value);
					}
					setValue(e.target.value);
				}}
				ref={ref}
				onKeyUp={() => {
					if (props.onKeyUp) {
						props.onKeyUp(value);
					}
				}}
				onFocus={() => {
					if (props.onFocus) {
						props.onFocus(value);
					}
				}}
			/>
		</div>
	);
});

export default SearchInput;
