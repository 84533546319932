import SelectorModal from "../../../../components/item-selector/modal-selector";
import React, { useEffect, useState } from "react";
import style from "./card-info.module.css";
import { useTranslate } from "../../../../localization/translate";
import Button from "../../../../components/button/button";
import { useDeleteCardMutation } from "../../services/profile.api";
import { useDispatch, useSelector } from "react-redux";
import { selectedCard, setAccountData } from "../../services/profile.slice";
import Loader from '../../../../components/loader';

export default function CardInfo() {
	const dispatch = useDispatch();

	const onRequestClose = () =>
		dispatch(
			setAccountData({
				key: "cardInfo",
				value: null,
			})
		);

	const card = useSelector(selectedCard);
	const [deleteCard, { data, isLoading }] = useDeleteCardMutation();
	const [markAsDeleted, setMarkAsDeleted] = useState(false);
	const translate = useTranslate();
	const isOpened = !!card;

	const handleDeleteCard = async () => {
		if (!isLoading)  {
			await deleteCard(card.id);
			onRequestClose();
		} 
	};

	useEffect(() => {
		if (data) {
			setMarkAsDeleted(false);
			onRequestClose();
		}
	}, [data]);

	return (
		<SelectorModal
			isOpen={isOpened}
			applyBtn={{
				text: markAsDeleted ? translate("app.cancel", true) : null,
				onClick: () => {
					if (markAsDeleted) {
						//Если пользователь нажал кнопку "отмена", то удаление не производиться
						setMarkAsDeleted(false);
						onRequestClose();
					}
				},
				disabled: false,
			}}
			title={translate("cards.title")}
			onRequestClose={() => {
				if (markAsDeleted) {
					deleteCard(card.id);
				} else {
					onRequestClose();
				}
			}}
			advancedButtons={
				<>
					{!markAsDeleted && (
						<Button
							onClick={() => setMarkAsDeleted(true)}
							light
							variant={"danger"}
						>
							{translate("cards.deleteCard")}
						</Button>
					)}
					{markAsDeleted && !isLoading && (
						<Button onClick={handleDeleteCard} light>
							{translate("profile.bills.backToAccounts")}
						</Button>
					)}
					{markAsDeleted && isLoading && <Loader width={20} height={20} className={style.loader} />}
				</>
			}
		>
			{markAsDeleted ? (
				<div>
					<div className={style.icon}>
						<svg
							width="81"
							height="80"
							viewBox="0 0 81 80"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle cx="40.5" cy="40" r="40" fill="#54A0FF" />
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M63.4309 25.2779C64.9343 26.8965 64.8408 29.4275 63.2222 30.9309L37.3822 54.9309C35.8372 56.3658 33.4436 56.3551 31.9116 54.9062L19.7516 43.4062C18.1465 41.8883 18.0759 39.3566 19.5938 37.7516C21.1117 36.1465 23.6434 36.0759 25.2485 37.5938L34.6847 46.5179L57.7779 25.0692C59.3965 23.5658 61.9275 23.6592 63.4309 25.2779Z"
								fill="white"
							/>
						</svg>
					</div>
					<div className={style.success_title}>
						{translate("cards.successRemove")}
					</div>
					<div className={style.success_text}>
						{translate("cards.successRemoveText")}
					</div>
				</div>
			) : (
				<div className={style.info}>
					<div className={style.row}>
						<div>{card && card.name}</div>
						<div></div>
					</div>
				</div>
			)}
		</SelectorModal>
	);
}
