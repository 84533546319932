import { useContext, useState } from "react";
import { useTranslate } from "../../../../localization/translate";
import ListItemRow from "../../../../components/list-item-row";
import Button from "../../../../components/button/button";
import Loader from "../../../../components/loader";
import Results from "../../components/Results";
import {
	useCreateInvoiceMutation,
	usePaymentByCardMutation,
	usePaymentByAccountMutation,
	usePaymentByCertificateMutation,
} from "../../services/payments.api";
import NavigationContext from "../../contexts/navigation-context";
import usePaymentContext from "../../hooks/usePaymentContext";
import { PaymentMethods, UrlPayment, Discounts, ResultWrapper } from "../index";
import { PAYMENT_METHODS, PAYMENT_TYPES } from "../../helpers/constants";
import { copyText } from "../../../../utils";
import styles from "./style.module.css";
import { Metrics } from "../../../../utils/metrics";
import Checkbox from "../../../../components/input/checkbox/Checkbox";
import { CODES_ENUM } from "../../../agreements/constants";
import Agreements from "../../../agreements/agreements";

export default function ServicePaymnet({
	type,
	purposeId,
	reserveId,
	doctorId,
	scheduleIdMis = null,
	medCardNumber = null,
}) {
	const translate = useTranslate();
	const { push } = useContext(NavigationContext);

	const [docType, setDocType] = useState(null);
	const [pdaAcception, setPdaAcception] = useState(true);
	const [offerAcception, setOfferAcception] = useState(true);
	const [consentAcception, setConsentAcception] = useState(true);

	const [createInvoice, { isLoading: isCreatingInvoice, error }] =
		useCreateInvoiceMutation();
	const [paymentByCard, { isLoading: isPayingByCard }] =
		usePaymentByCardMutation();
	const [paymentByAccount, { isLoading: isPayingByAccount }] =
		usePaymentByAccountMutation();
	const [paymnetByCertificate, { isLoading: isPayingByCertificate }] =
		usePaymentByCertificateMutation();

	const togglePdaAcception = () => {
		setPdaAcception((pre) => !pre);
	};

	const toggleOfferAcception = () => {
		setOfferAcception((pre) => !pre);
	};

	const toggleConsentAcception = () => {
		setConsentAcception((pre) => !pre);
	};

	const handleAgreement = () => {
		setPdaAcception(true);
		setDocType(null);
	};

	const {
		payment: {
			checkout,
			discount,
			date,
			medCardNumber: medCardNumberFromBills,
		},
		isLoading,
		isSuccess,
		setPayment,
		error: checkoutError,
	} = usePaymentContext({
		type,
		purposeId,
		medCardNumber,
	});

	const [errors, setErrors] = useState(null);

	const handleSelectPaymentMethod = () => push(PaymentMethods);

	const handleSelectDiscountMethod = () => push(Discounts);

	const checkErrors = () => {
		let _errors = {
			...errors,
			...(!checkout?.paymentMethod && {
				method: translate("app.forms.required", true),
			}),
		};

		setErrors(_errors);
		return _errors;
	};

	const handlePaymentErros = (data, error) => {
		if (!error) {
			setPayment((prev) => ({
				...prev,
				results: {
					...prev.results,
					type: "success",
					orderNumber: data.invoiceNumber,
					...(!!reserveId && { reserveId }),
				},
			}));

			Metrics.gtmEvent("complete_order_payment", {
				orderId: purposeId,
				total_sum: checkout.amount,
				payment_sum: checkout.discounted ?? checkout.amount,
				discount: checkout.discounted
					? checkout.amount - checkout.discounted
					: 0,
				payment_method: checkout.paymentMethod?.name,
			});
		} else {
			setPayment((prev) => ({
				...prev,
				results: { ...prev.results, type: "fail" },
			}));
		}
	};

	const afterCreateInvoice = async (invoiceNumber) => {
		if (checkout.paymentMethod.type === PAYMENT_METHODS.url) {
			push(UrlPayment);
		} else if (checkout.paymentMethod.type === PAYMENT_METHODS.card) {
			const { data, error } = await paymentByCard({
				invoiceNumber,
				bankCardId: checkout.paymentMethod.operationId,
			});

			if (data) {
				handlePaymentErros(data, error);
				push(ResultWrapper);
			}
		} else if (checkout.paymentMethod.type === PAYMENT_METHODS.account) {
			const { data, error } = await paymentByAccount({
				invoiceNumber,
				accountId: checkout.paymentMethod.operationId,
			});

			if (data) {
				handlePaymentErros(data, error);
				push(ResultWrapper);
			}
		} else if (checkout.paymentMethod.type === PAYMENT_METHODS.certificate) {
			const { data, error } = await paymnetByCertificate({
				invoiceNumber,
				certificateNumber: checkout.paymentMethod.operationId,
			});

			if (data) {
				handlePaymentErros(data, error);
				push(ResultWrapper);
			}
		} else {
			console.error(checkout, invoiceNumber, "Unknow PaymentMethod");
		}
	};

	const hanldePayment = async () => {
		if (!Object.keys(checkErrors()).length) {
			const { data } = await createInvoice({
				purposeId,
				reserveId,
				doctorId,
				medCardNumber,
				paymentPurpose: type,
				paymentMethod: checkout.paymentMethod.type,
				amount: type === PAYMENT_TYPES.other ? checkout.amount : null,
				createDate: type === PAYMENT_TYPES.other ? date || null : null,
				medCardNumber:
					type === PAYMENT_TYPES.other ? medCardNumberFromBills || null : null,
				...(scheduleIdMis && { scheduleIdMis }),
				...(discount?.type && { discountType: discount?.type }),
				...(discount?.id && { discountId: discount?.id }),
			});

			if (data && data.invoiceId) {
				setPayment((prev) => ({ ...prev, invoice: data.invoiceId }));
				afterCreateInvoice(data.invoiceId);
			}
		}
	};

	const handleShareUrl = async () => {
		const {
			data: { invoiceId, sharingUrl },
		} = await createInvoice({
			purposeId,
			reserveId,
			doctorId,
			medCardNumber,
			paymentPurpose: type,
			paymentMethod: PAYMENT_METHODS.url,
			amount: null,
			...(scheduleIdMis && { scheduleIdMis }),
			...(discount?.type && { discountType: discount?.type }),
			...(discount?.id && { discountId: discount?.id }),
		});
		setPayment((prev) => ({ ...prev, invoice: invoiceId }));
		await copyText(sharingUrl);
		setPayment((prev) => ({
			...prev,
			results: {
				...prev.results,
				type: "success",
				orderNumber: invoiceId,
				isLink: true,
			},
		}));
		push(ResultWrapper);
	};

	return (
		<>
			{isLoading && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}
			{isSuccess && !error && !checkoutError && (
				<div className={styles.service}>
					<div className={styles.content}>
						{checkout.isPaymentAvailable && (
							<div className={styles.content_block}>
								<div className={styles.title}>
									{translate(`payments.${type}.paymentMethod`)}
								</div>

								<ListItemRow
									title={
										checkout.paymentMethod?.name ||
										translate(`payments.${type}.selectMethod`)
									}
									onClick={handleSelectPaymentMethod}
									error={errors?.method}
								/>
							</div>
						)}

						{checkout.isDiscountAvailable && (
							<div className={styles.content_block}>
								<div className={styles.title}>
									{translate(`payments.${type}.discountMethod`)}
								</div>

								<ListItemRow
									title={
										discount?.name ||
										translate(`payments.${type}.selectDiscount`)
									}
									description={discount?.description}
									onClick={handleSelectDiscountMethod}
								/>
							</div>
						)}

						<div className={styles.content_block}>
							<div className={styles.title}>
								{translate(`payments.${type}.summ.title`)}
							</div>

							<ListItemRow
								nonInteract
								showDivider={false}
								title={translate(
									`payments.${type}.summ.${
										checkout.isDiscountAvailable && discount?.id
											? "withDiscount"
											: "summ"
									}`
								)}
								renderArrow={() => (
									<div>
										{(checkout.isDiscountAvailable
											? checkout.discounted
											: checkout.amount
										)?.toLocaleString()}
										&nbsp;
										{translate("app.currencySign")}
									</div>
								)}
							/>

							{checkout.isDiscountAvailable && discount?.id && (
								<ListItemRow
									nonInteract
									size="small"
									theme="grey"
									showDivider={false}
									title={translate(`payments.${type}.summ.withoutDiscount`)}
									renderArrow={() => (
										<div>
											{checkout.amount?.toLocaleString()}&nbsp;
											{translate("app.currencySign")}
										</div>
									)}
								/>
							)}
						</div>
					</div>

					<div className={styles.confirm}>
						<Checkbox
							label={
								<>
									{translate("appointment.confirm.confirmPda")}&nbsp;
									<span
										style={{ cursor: "pointer" }}
										onClick={() => setDocType(CODES_ENUM.PD_CONDITIONS)}
										className={styles.agreement}
									>
										{translate("appointment.confirm.confirmPda2")}
									</span>
									&nbsp;
									{translate("appointment.confirm.confirmPda3")}
									&nbsp;
									<span
										style={{ cursor: "pointer" }}
										onClick={() => setDocType(CODES_ENUM.PD_POLICY)}
										className={styles.agreement}
									>
										{translate("appointment.subjectOpinion.privacyPolicy")}
									</span>
								</>
							}
							onChange={togglePdaAcception}
							checked={pdaAcception}
						/>
					</div>

					<div className={styles.confirm}>
						<Checkbox
							label={
								<>
									{translate("appointment.confirm.confirmOffer1")}&nbsp;
									<a
										style={{ cursor: "pointer" }}
										href="https://www.medicina.ru/local/files/dogovor_oferta.pdf"
										target="_blank"
										rel="noreferrer"
										className={styles.agreement}
									>
										{translate("appointment.confirm.confirmOffer2")}
									</a>
									&nbsp;
									{translate("appointment.confirm.confirmOffer3")}
								</>
							}
							onChange={toggleOfferAcception}
							checked={offerAcception}
						/>
					</div>

					<div className={styles.confirm}>
						<Checkbox
							label={<>{translate("appointment.confirm.confirmConsent")}</>}
							onChange={toggleConsentAcception}
							checked={consentAcception}
						/>
					</div>

					{isCreatingInvoice ||
					isPayingByCard ||
					isPayingByAccount ||
					isPayingByCertificate ? (
						<Loader
							width={18}
							height={18}
							containerStyle={{
								display: "flex",
								justifyContent: "center",
							}}
						/>
					) : (
						<div className={styles.actions}>
							<Button
								disabled={!pdaAcception || !offerAcception || !consentAcception}
								onClick={hanldePayment}
							>
								{translate(`payments.${type}.paymentBtn`)}&nbsp;
								{(checkout?.discounted || checkout?.amount)?.toLocaleString()}
								&nbsp;
								{translate("app.currencySign")}
							</Button>

							{[
								PAYMENT_TYPES.service,
								PAYMENT_TYPES.opinion,
								PAYMENT_TYPES.program,
							].includes(type) && (
								<Button light onClick={handleShareUrl}>
									{translate(`payments.${type}.sharePaymentLink`)}
								</Button>
							)}
						</div>
					)}

					<Agreements
						isOpen={!!docType}
						type={docType}
						onClose={() => setDocType(null)}
						onAgree={handleAgreement}
					/>
				</div>
			)}
			{(checkoutError || error) && <Results type="fail" />}
		</>
	);
}
