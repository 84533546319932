import { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "beautiful-react-hooks";
import moment from "moment";
import { useTranslate } from "../../../../localization/translate";
import EventsListLayout from "../events-list-layout/events-list-layout";
import FilterContainer from "../filter-container/filter-container";
import { useGetPlanQuery, useGetPollIdQuery } from "../../services/events.api";
import EventListItem from "../event-list-item/event-item";
import Empty from "../empty-events";
import Button from "../../../../components/button/button";
import grid from "../../modules/history/grid.module.css";
import styles from "../../events.module.css";
import css from "./polls.module.css";
import avatar from "./../../../../icons/doctor-avatar.svg";
import { MedicationCard } from "./MedicationCard";
import { MedicationContainer } from "./MedicationContainer";

export default function Polls() {
	const translate = useTranslate();
	const navigate = useNavigate();
	const location = useLocation();

	const [planAppointments, setPlanAppointments] = useState();

	const isBigDesktop = useMediaQuery("(min-width: 1440px)");

	const hasItems = useMemo(
		() => planAppointments && planAppointments.length > 0,
		[planAppointments]
	);

	const filterCallback = (list) =>
		setPlanAppointments(prepareAppointments(list));

	const selected = false;

	const prepareAppointments = (list = []) => {
		const withDates = [];
		list.forEach((item) => {
			const matches = withDates.find(
				(sub) => sub.date === moment(item.date).format("YYYY-MM-DD")
			);
			if (matches) {
				matches.items.push(item);
			} else {
				withDates.push({
					date: moment(item.date).format("YYYY-MM-DD"),
					items: [item],
				});
			}
		});

		return withDates;
	};

	const { data } = useGetPlanQuery();
	const { data: pollId, error, loading: pollLoading } = useGetPollIdQuery();

	const pollErrorCode = useMemo(
		() => error?.data?.messages?.[0]?.internalMessageCode,
		[error]
	);

	useEffect(() => {
		if (data) {
			setPlanAppointments(prepareAppointments(data.data.appointments));
		}
	}, [data]);

	useEffect(() => {
		if (error && error?.data?.messages?.[0]?.internalMessageCode !== 2201)
			navigate("/events");
	}, [error]);

	return (
		<div className={styles.events}>
			<div className={styles.title}>{translate("events.title", true)}</div>

			<EventsListLayout
				medication={
					isBigDesktop &&
					pollErrorCode !== 2203 &&
					!pollLoading && (
						<>
							<div className={css.title}>
								<h3>{translate("polls.yourMedication")}</h3>
							</div>

							<MedicationCard
								title={translate("polls.proactiveSurveillance.title")}
								description={translate(
									"polls.proactiveSurveillance.description"
								)}
								additionalTitle={
									pollId?.id && !error
										? translate("polls.proactiveSurveillance.additionalTitle")
										: null
								}
								additionalDescription={
									pollId?.id && !error
										? translate(
												"polls.proactiveSurveillance.additionalDescription"
										  )
										: null
								}
							/>
						</>
					)
				}
				navigate={
					isBigDesktop && (
						<>
							<div className={css.title}>
								<h3>{translate("polls.doctorsAppointments")}</h3>
							</div>

							<div className={css.navigation}>
								<NavLink to="/events" end className={styles.link}>
									{({ isActive }) => (
										<Button
											className={styles.eventButton}
											secondary={
												!isActive &&
												location.pathname !== "/" &&
												!location.pathname.includes("/events/")
											}
										>
											{translate("events.plan.planTitle", true)}
										</Button>
									)}
								</NavLink>

								<NavLink to="/events/history">
									{({ isActive }) => (
										<Button
											className={styles.eventButton}
											secondary={!isActive}
										>
											{translate("events.history.historyTitle", true)}
										</Button>
									)}
								</NavLink>

								{hasItems ? (
									<div className={styles.filterButton}>
										<FilterContainer
											applyCallback={filterCallback}
											list={data.data.appointments}
											showDates
											defaultDates={
												hasItems && planAppointments.map((item) => item.date)
											}
										/>
									</div>
								) : null}
							</div>
						</>
					)
				}
				selected={
					<>
						<MedicationContainer pollId={error ? null : pollId?.id} />
					</>
				}
			>
				{!hasItems && (
					<Empty
						noMargin
						title={translate("history.list.hasNoItems", true)}
						text={translate("events.plan.empty", true)}
						link={
							<NavLink to="/appointment" className={styles.link}>
								<Button className={styles.empty_button}>
									{translate(`appointment.doctorModal.makeAppointment`, true)}
								</Button>
							</NavLink>
						}
					/>
				)}

				{hasItems &&
					(isBigDesktop || (!isBigDesktop && !selected)) &&
					planAppointments.map((items) => (
						<div key={"date_visit" + items.date} className={grid.list__section}>
							{moment().format("YYYY-MM-DD") === items.date ? (
								<>
									<div className={grid.list__sectionDate}>
										{translate("events.today")}
									</div>
								</>
							) : (
								<div className={grid.list__sectionDate}>
									{moment(items.date).format("D MMMM YYYY, dddd")}
								</div>
							)}

							<div className={grid.list__sectionItems}>
								{items.items.map((item) => (
									<div
										onClick={() => navigate(`/events/${item.scheduleIdMis}`)}
										key={"plan_visit" + item.scheduleIdMis}
										className={grid.list__sectionItem}
									>
										<EventListItem
											key={item.scheduleIdMis}
											receptionType={item.type}
											confirmCallback={() => navigate(`/events`)}
											type={"plan"}
											slotIdMis={item.scheduleIdMis}
											service={item.service}
											isPaid={item.isPaid}
											shortname={item.doctor ? item.doctor.shortname : "_"}
											specialities={
												item.speciality ? item.speciality.name : "_"
											}
											cabinet={item.cabinet}
											patient={item.patient}
											sourceDate={item.date}
											photoUrl={item.doctor ? item.doctor.photoUrl : avatar}
											isSelected={item.scheduleIdMis === selected}
											scheduleIdMis={item.scheduleIdMis}
											doctor={item.doctor ? item.doctor : null}
											isOnline={item.isOnline}
											speciality={item.speciality}
										/>
									</div>
								))}
							</div>
						</div>
					))}
			</EventsListLayout>
		</div>
	);
}
