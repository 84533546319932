import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../../localization/translate";
import {
	NavLink,
	Route,
	Routes,
	useLocation,
	useSearchParams,
} from "react-router-dom";
import classNames from "classnames";
import ServiceCard from "../../components/service-card";
import SpecialityList from "../appointment/modules/speciality-list";
import SpecialitySchedule from "../appointment/modules/speciality-schedule/speciality-schedule";
import Research from "../appointment/modules/research/research";
import ResearchSchedule from "../appointment/modules/research-schedule/research-schedule";
import styles from "../appointment/appointment.module.css";
import new_styles from "./guest-appointment.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
	selectExaminationInfo,
	selectReceptionTypeIsOpen,
	selectedDoctor,
	setSelectedDoctor,
} from "../appointment/services/appointment.slice";
import DoctorModal from "../appointment/modules/doctor-modal/doctor-modal";
import ExaminationModal from "../appointment/modules/examination-modal/examination-modal";
import ReceptionTypeModal from "../appointment/modules/reception-type-modal/reception-type-modal";
import SubscribeModal from "../appointment/modules/subscribe-modal/subscribe-modal";
import PatientSelectorContainer from "../appointment/modules/patientSelector/container";
import { selectPatientSelectorState } from "../profile/services/profile.slice";
import { selectIsGuestMode } from "../login/services/login.slice";
import NewPasswordModal from "../login/modules/reset-password/new-password.modal";
import AttachCard from "../profile/modules/share-access/attach-card";
import { isSafari } from "../../utils";

export default function GuestAppointment() {
	const isGuest = useSelector(selectIsGuestMode);
	const translate = useTranslate();
	const location = useLocation();
	const [params] = useSearchParams();
	const [isWidget, setIsWidget] = useState(false);
	const wrapperRef = useRef(null);

	const dispatch = useDispatch();
	const doctorInfo = useSelector(selectedDoctor);
	const showPatientSelector = useSelector(selectPatientSelectorState);
	const examinationInfo = useSelector(selectExaminationInfo);
	const receptionTypeModal = useSelector(selectReceptionTypeIsOpen);

	const navigationClassNames = classNames(styles.navigation, {
		[styles.hidden]: location.pathname !== "/schedule",
		[styles.wide]: location.pathname === "/schedule",
	});

	useEffect(() => {
		const isWidget = params.get("widget") === "1";
		setIsWidget(isWidget);
	}, []);

	const scrollToTopOfWidget = () => {
		if (!!wrapperRef && !!wrapperRef.current) {
			if (isSafari) {
				wrapperRef.current.scrollIntoView(true);
			} else {
				wrapperRef.current.scrollIntoView({
					block: "start",
					behavior: "smooth",
				});
			}
		}
	};

	useEffect(() => {
		scrollToTopOfWidget();
	}, [
		location,
		doctorInfo,
		showPatientSelector,
		examinationInfo,
		receptionTypeModal,
	]);

	return (
		<>
			<div
				ref={wrapperRef}
				className={cx(styles.appointment, new_styles.wrapper, {
					[new_styles.non_widget]: !isWidget,
				})}
			>
				<div className={classNames(styles.header, new_styles.header)}>
					{location.pathname !== "/schedule" && (
						<NavLink
							className={() => classNames(new_styles.btn_link, new_styles.back)}
							to={"/schedule" + location.search}
						>
							<svg
								width="10"
								height="18"
								viewBox="0 0 10 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M9.74156 0.255101C10.0861 0.595235 10.0861 1.1467 9.74156 1.48683L2.13019 9L9.74156 16.5132C10.0861 16.8533 10.0861 17.4048 9.74156 17.7449C9.39698 18.085 8.83831 18.085 8.49373 17.7449L0.258435 9.61587C-0.0861451 9.27573 -0.0861451 8.72427 0.258435 8.38413L8.49373 0.255101C8.83831 -0.0850335 9.39698 -0.0850335 9.74156 0.255101Z"
									fill="#7A8CA4"
								/>
							</svg>
						</NavLink>
					)}
					<div className={styles.title}>
						{translate("appointment.pageTitle")}
					</div>
					<div />
				</div>

				<div className={navigationClassNames}>
					<div className={classNames(styles.primary, new_styles.primary)}>
						{!isWidget && (
							<NavLink className={() => new_styles.btn_link} to="/login">
								<button className={new_styles.back_btn}>
									<svg
										className={new_styles.arrow}
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M15.7674 4.22676C16.0775 4.5291 16.0775 5.01929 15.7674 5.32163L8.91717 12L15.7674 18.6784C16.0775 18.9807 16.0775 19.4709 15.7674 19.7732C15.4573 20.0756 14.9545 20.0756 14.6444 19.7732L7.23259 12.5474C6.92247 12.2451 6.92247 11.7549 7.23259 11.4526L14.6444 4.22676C14.9545 3.92441 15.4573 3.92441 15.7674 4.22676Z"
											fill="#7A8CA4"
										/>
									</svg>

									{translate("appointment.navigation.backToLogin")}
								</button>
							</NavLink>
						)}

						<NavLink
							className={({ isActive }) =>
								classNames(styles.nav_item, {
									[styles.active]: isActive,
								})
							}
							to={"/schedule/speciality" + location.search}
						>
							<ServiceCard
								icon="doctor"
								isActive={location.pathname.includes("/schedule/speciality")}
								title={translate("appointment.navigation.toDoctorTitle", true)}
								text={translate("appointment.navigation.toDoctorText", true)}
							/>
						</NavLink>
						<NavLink
							className={({ isActive }) =>
								classNames(styles.nav_item, {
									[styles.active]: isActive,
								})
							}
							to={"/schedule/services" + location.search}
						>
							<ServiceCard
								icon="services"
								isActive={location.pathname.includes("/schedule/services")}
								title={translate("appointment.navigation.servicesTitle", true)}
								text={translate("appointment.navigation.servicesText", true)}
							/>
						</NavLink>
					</div>
				</div>

				<div
					className={cx(styles.content, { [new_styles.scrollable]: isWidget })}
				>
					<Routes>
						<Route path="/">
							<Route
								path="/speciality"
								element={
									<SpecialityList isPublic={isGuest} isWidget={isWidget} />
								}
							/>
							<Route
								path="/speciality/:specialityId"
								element={
									<SpecialitySchedule isPublic={isGuest} isWidget={isWidget} />
								}
							/>

							<Route
								path="/services"
								element={<Research isPublic={isGuest} isWidget={isWidget} />}
							/>
							<Route
								path="/services/:research_id/:service_id"
								element={
									<ResearchSchedule isPublic={isGuest} isWidget={isWidget} />
								}
							/>
						</Route>
					</Routes>
				</div>

				<DoctorModal
					doctorInfo={doctorInfo}
					setSelectedDoctor={(data) => dispatch(setSelectedDoctor(data))}
					isWidget={isWidget}
				/>
				<SubscribeModal isPublic={isGuest} isWidget={isWidget} />
				<ExaminationModal
					isPublic={isGuest}
					isWidget={isWidget}
					scrollToTopOfWidget={scrollToTopOfWidget}
				/>
				<ReceptionTypeModal isPublic={isGuest} isWidget={isWidget} />

				<AttachCard isWidget={isWidget} />
				{showPatientSelector && (
					<PatientSelectorContainer isWidget={isWidget} />
				)}
			</div>
			<NewPasswordModal />
		</>
	);
}
