import { useDispatch, useSelector } from "react-redux";
import {
	selectEditModalOpened,
	setEditModalState,
} from "../../services/profile.slice";
import Modal from "../../../../components/modal-safari-wrapper/modal";
import CloseModalButton from "../../../../components/modal-items/close";
import Button from "../../../../components/button/button";
import { useTranslate } from "../../../../localization/translate";
import Input from "../../../../components/input";
import { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./personal-data.create.module.css";
import ProfileNotice from "./notice";
import { useUpdateProfileMutation } from "../../services/profile.api";
import Loader from "../../../../components/loader";
import { getErrorMessageFromError } from "../../../../app/error-handler.api";

const modalStyle = {
	content: {
		width: "407px",
		padding: "30px 32px 16px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	overlay: {
		display: "flex",
		alignItems: "center",
	},
};

export default function PersonalDataEdit({ savedEmail }) {
	const isOpened = useSelector(selectEditModalOpened);
	const dispatch = useDispatch();
	const closeModal = () => dispatch(setEditModalState(false));
	const translate = useTranslate();
	const [email, setEmail] = useState(savedEmail ? savedEmail : "");
	const [error, setError] = useState(null);
	const [update, { data, isLoading, error: queryError }] =
		useUpdateProfileMutation();
	const validate = () => {
		const regExp = new RegExp(/^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/i);
		if (!regExp.test(email)) {
			setError(translate("profile.personalData.invalidEmail", true));

			return false;
		}

		return true;
	};
	const submit = () => {
		if (validate()) {
			if (email === savedEmail) {
				dispatch(setEditModalState(false));
			} else {
				update({ email });
			}
		}
	};

	useEffect(() => {
		if (data) {
			dispatch(setEditModalState(false));
		}
	}, [data]);

	useEffect(() => {
		if (queryError) {
			const clarifications = getErrorMessageFromError({
				fromClarifications: true,
				error: queryError,
			});
			if (clarifications) {
				const emailError = clarifications.find((msg) => msg.target === "email");
				if (emailError) {
					setError(emailError.message);
				}
			}
		}
	}, [queryError]);

	return (
		<Modal isOpen={isOpened} onRequestClose={closeModal} style={modalStyle}>
			<CloseModalButton closeModal={closeModal} />
			<div className={styles.create_pd}>
				<div className={classNames(styles.title, styles.input_row)}>Email</div>
				<div className={classNames(styles.input_row, styles.standart)}>
					<Input
						type="text"
						placeholder={translate(`profile.personalData.email`, true)}
						value={email}
						errorMsg={error}
						onChange={(val) => {
							setError(null);
							setEmail(val);
						}}
						onKeyUp={(e) => {
							if (e.key === "Enter") {
								submit();
							}
						}}
					/>
				</div>
				<div className={styles.notice}>
					<ProfileNotice
						isGrey
						isSmall
						title={translate("app.attention", true)}
						message={translate(
							"profile.personalData.attentionForEmailEdit",
							true
						)}
					/>
				</div>
				<Button onClick={submit}>
					{isLoading ? (
						<Loader
							containerStyle={{ margin: "-8px 0" }}
							width={30}
							height={30}
						/>
					) : (
						translate("app.forms.changeBtn", true)
					)}
				</Button>
			</div>
		</Modal>
	);
}
