import { useState, useEffect } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { useTranslate } from "../../../../localization/translate";
import Loader from "../../../../components/loader";
import ReviewClosedRequest from "./components/ReviewClosedRequest";
import IssueForm from "./components/IssueForm";
import SupportInfoBanner from "./components/SupportInfoBanner";
import TopicSelect from "./components/TopicSelect";
import IssueChat from "./components/IssueChat";
import { IssueProvider } from "./contexts/issue-context";
import useIssueContext from "./hooks/use-issue-context";
import styles from "./support-detail.module.css";

export default function SupportDetail() {
	const translate = useTranslate();
	const [isOpenRate, setIsOpenRate] = useState(false);

	const { id } = useParams();
	const { issue, isLoading, setIssue, addComment, deleteComment } =
		useIssueContext(id);

	useEffect(() => {
		setIsOpenRate(issue?.isClosed && !issue?.isRated);
	}, [issue]);

	return (
		<IssueProvider
			value={{
				issue,
				setIssue,
				addComment,
				deleteComment,
			}}
		>
			{issue && (
				<div className={styles.support_detail}>
					<div className={classNames("h3", styles.title)}>
						{translate("profile.support.detail.title")}&nbsp;{id}
					</div>

					<div className={styles.content}>
						{(issue.isClosed || issue.topic) && (
							<div className={styles.chat}>
								<IssueChat issue={issue} />
							</div>
						)}

						{!issue.isClosed && !issue.topic && (
							<>
								<div className={styles.info_banner}>
									<SupportInfoBanner />
								</div>

								<div className={styles.topic_select}>
									<TopicSelect issueId={issue.id} />
								</div>
							</>
						)}
					</div>

					{!issue.isClosed && issue.topic && (
						<div className={styles.issue_form}>
							<IssueForm
								issueId={issue.id}
								isClosureRequested={issue.isClosureRequested}
							/>
						</div>
					)}

					<ReviewClosedRequest
						issueId={issue.id}
						isOpen={isOpenRate}
						handleClose={() => setIsOpenRate(false)}
					/>
				</div>
			)}

			{isLoading && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}
		</IssueProvider>
	);
}
