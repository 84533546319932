import {Route, Routes} from "react-router-dom";
import React, {useEffect, useState} from "react";
import GetCoffee from "./scanner/coffee";
import CoffeeRequest from "./request/coffee-request";
import {iOS, isAndroidApp, isIOSApp} from "../../../../utils";
import {Metrics} from "../../../../utils/metrics";
import {useGetProfileQuery} from "../../services/profile.api";
import { useSearchParams } from "react-router-dom";
import stores from "../../../../appstores.json";
import Result from "./result/result";
import CoffeeStatus from "./status/status";
import CoffeeOverlay from "./components/overlay/overlay";

export default function GetCoffeePage() {
  //пользователь должен пройти все следующие роуты по порядку. В каждом из них редирект на следующий

  return <Routes>
    <Route index={true} element={<RouteControllerCoffee/>} />{/*сканирование qr кода*/}
    <Route path="/:id" element={<RouteControllerCoffeeRequest/>} />{/*Запрос кредита и получение id транзакции*/}
    <Route path="/:transaction/status" element={<CoffeeOverlay><CoffeeStatus/></CoffeeOverlay>} />{/* запрос статуса транзакции*/}
    <Route path="/:status/result" element={<CoffeeOverlay><Result/></CoffeeOverlay>} />{/* результат транзакции*/}
  </Routes>
}

function RouteControllerCoffeeRequest() {
  const [ready, setReady] = useState(false);
  let [searchParams] = useSearchParams();

  useEffect(() => {
    if (!ready) {

      if (isAndroidApp || isIOSApp) {
        //отсканировано в приложении, можно дальше выполнять логику
        setReady(true);
      } else {

        if (searchParams.get("web") === "1") {
          //отсканировано внутри сайта
          setReady(true);
        } else {
          //отсканировано камерой и открыт браузер - редиректим в стор
          if (iOS()) {
            window.location.href = stores.apple_store;
          } else {
            window.location.href = stores.play_google;
          }
        }
      }
    }
  }, [ready]);

  if (ready) {
    return <CoffeeRequest/>;
  } else {
    //ждем чтобы понять вебвью или сайт
    return <></>;
  }
}

function RouteControllerCoffee () {
  const { data: profile } = useGetProfileQuery();
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (profile) {
      Metrics.gtmEvent("vending_view", {
        card: profile.medCardNumber
      });
    }
  }, [profile])

  useEffect(() => {
    if (!rendered) {
      if (isAndroidApp) {
        window.MedicinaAndroidWebView?.startScanCoffee?.();

        return <></>;
      } else if (isIOSApp) {
        window.startScanCoffee?.();

      }
      setRendered(true);
    }
  }, [rendered]);

  if (rendered) {
    if (isAndroidApp || isIOSApp) {
      return <></>;
    } else {
      return <GetCoffee/>;
    }
  } else {
    return <></>;
  }
}