import { useTranslate } from "../../../../localization/translate";
import React, { useEffect, useState } from "react";
import Button from "../../../../components/button/button";
import header from "./header.module.css";
import moment from "moment";
import styles from "./receptions.module.css";
import SearchInput from "../../../../components/search-input/search-input";
import Dropdown from "../../../../components/dropdown/dropdown";
import DatePicker from "../../../../components/datepicker/datepicker";
import { useMediaQuery } from "beautiful-react-hooks";
import Modal from "../../../../components/modal-safari-wrapper/modal";
import CloseModalButton from "../../../../components/modal-items/close";
import classNames from "classnames";

export default function Header({
	dateRange,
	setDateRange,
	search,
	setSearch,
	defaultDates,
}) {
	const [startDate, endDate] = dateRange;
	const translate = useTranslate();
	const [opened, setOpened] = useState(false);
	const searchRef = React.createRef();
	const onKeyUp = (val) => {
		setSearch(val);
	};
	const isBigDesktop = useMediaQuery("(min-width: 1440px)");
	const isMobile = useMediaQuery("(max-width: 620px)");

	useEffect(() => {
		return () => setOpened(false);
	}, []);

	return (
		<div className={styles.header}>
			<div className={styles.search}>
				<SearchInput
					className={styles.search_field}
					ref={searchRef}
					value={search}
					onKeyUp={onKeyUp}
					placeholder={translate("app.search", true)}
				/>
				{!isBigDesktop && (
					<div
						onClick={(e) => {
							setOpened(!opened);
						}}
					>
						<div
							className={classNames(header.mobile_filter, {
								[header.active]: startDate && endDate,
							})}
						>
							<svg
								width="44"
								height="44"
								viewBox="0 0 44 44"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M29.7843 26.4442L29.8406 26.6454L32.2564 26.6452C32.6424 26.6452 33 26.9857 33 27.4617C33 27.9376 32.6423 28.2782 32.2563 28.2782L29.8404 28.2792L29.784 28.4802C29.3705 29.9537 28.0949 31 26.6154 31C25.1359 31 23.8603 29.9537 23.4468 28.4802L23.3904 28.2792L11.7436 28.2782C11.3576 28.2782 11 27.9376 11 27.4617C11 26.9857 11.3576 26.6452 11.7436 26.6452L23.3901 26.6453L23.4465 26.4442C23.8597 24.9702 25.1355 23.9234 26.6154 23.9234C28.0952 23.9234 29.371 24.9702 29.7843 26.4442ZM21.5791 15.5208L21.6355 15.722L32.2564 15.7218C32.6424 15.7218 33 16.0624 33 16.5383C33 17.0143 32.6424 17.3548 32.2564 17.3548L21.6353 17.3558L21.5788 17.5568C21.1653 19.0303 19.8898 20.0766 18.4103 20.0766C16.9307 20.0766 15.6552 19.0303 15.2417 17.5568L15.1853 17.3558L11.7436 17.3548C11.3576 17.3548 11 17.0143 11 16.5383C11 16.0624 11.3576 15.7218 11.7436 15.7218L15.185 15.722L15.2414 15.5208C15.6546 14.0468 16.9304 13 18.4103 13C19.8901 13 21.1659 14.0468 21.5791 15.5208ZM26.6154 25.2843C25.4436 25.2843 24.5385 26.2841 24.5385 27.4617C24.5385 28.6393 25.4436 29.6391 26.6154 29.6391C27.7871 29.6391 28.6923 28.6393 28.6923 27.4617C28.6923 26.2841 27.7871 25.2843 26.6154 25.2843ZM18.4103 14.3609C17.2385 14.3609 16.3333 15.3607 16.3333 16.5383C16.3333 17.7159 17.2385 18.7157 18.4103 18.7157C19.582 18.7157 20.4872 17.7159 20.4872 16.5383C20.4872 15.3607 19.582 14.3609 18.4103 14.3609Z"
									fill="#7A8CA4"
								/>
							</svg>
						</div>
						{!isMobile && (
							<Dropdown
								expanded={opened}
								closeBtn
								title={translate("history.list.period", true)}
								className={header.calendar_container}
								setExpanded={setOpened}
							>
								<DatePicker
									maxDate={moment()}
									markedDates={defaultDates}
									setDateRange={(arg) => {
										setDateRange(arg);
										setOpened(false);
									}}
									dateRange={dateRange}
									openState={opened}
								/>
							</Dropdown>
						)}
					</div>
				)}
				{isMobile && (
					<Modal
						isOpen={opened}
						// className={modalClassName}
						onRequestClose={() => setOpened(false)}
						style={{
							content: {
								overflowX: "hidden",
								minHeight: "92vh",
								maxHeight: "92vh",
							},
						}}
					>
						<CloseModalButton closeModal={() => setOpened(false)} />
						<div className={header.date_picker_title}>
							{translate("history.list.period", true)}
						</div>
						<DatePicker
							maxDate={moment()}
							markedDates={defaultDates}
							setDateRange={(arg) => {
								setDateRange(arg);
								setOpened(false);
							}}
							oneMonth
							calendarClassName={styles.date_picker}
							dateRange={dateRange}
							openState={opened}
						/>
					</Modal>
				)}
			</div>
			{isBigDesktop && (
				<div className={header.filter_container}>
					<Button
						secondary={startDate && endDate}
						className={header.button}
						small
						onClick={() => setDateRange([null, null])}
					>
						{translate("history.list.forAllTime")}
					</Button>
					<Button
						small
						className={header.button_daterange}
						onClick={() => setOpened(!opened)}
						secondary={!startDate || !endDate}
						nonHover
					>
						{startDate && endDate ? (
							<>
								{moment(startDate).format("D MMMM YYYY")}
								&nbsp;-&nbsp;
								{moment(endDate).format("D MMMM YYYY")}
							</>
						) : (
							translate("history.list.changeDateRange", true)
						)}
						<span className={header.icon}>
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.10571 6.44383C3.35766 6.18539 3.76616 6.18539 4.01811 6.44383L9.58341 12.1524L15.1487 6.44383C15.4007 6.18539 15.8092 6.18539 16.0611 6.44383C16.3131 6.70226 16.3131 7.12127 16.0611 7.3797L10.0396 13.5562C9.78766 13.8146 9.37917 13.8146 9.12722 13.5562L3.10571 7.3797C2.85376 7.12127 2.85376 6.70226 3.10571 6.44383Z"
									fill="#7A8CA4"
								/>
							</svg>
						</span>
					</Button>
					<Dropdown
						expanded={opened}
						closeBtn
						title={translate("history.list.period", true)}
						className={header.calendar_container}
						setExpanded={setOpened}
					>
						<DatePicker
							maxDate={moment()}
							markedDates={defaultDates}
							setDateRange={(arg) => {
								setDateRange(arg);
								setOpened(false);
							}}
							dateRange={dateRange}
							openState={opened}
						/>
					</Dropdown>
				</div>
			)}
		</div>
	);
}
