import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCatalogProgramsQuery } from "../../services/profile.api";
import { useTranslate } from "../../../../localization/translate";
import Loader from "../../../../components/loader";
import InfoBanner from "../../../../components/info-banner";
import ProgramsList from "./components/programs-list";
import styles from "./group-detail.module.css";

const GroupDetail = ({}) => {
	const navigate = useNavigate();
	const translate = useTranslate();

	const { id } = useParams();
	const { data: groups, isLoading, error } = useGetCatalogProgramsQuery();

	const group = useMemo(
		() =>
			groups &&
			groups.programGroups &&
			groups.programGroups.find((group) => group.id === Number(id)),
		[id, groups]
	);

	return (
		<div className={styles.group_detail}>
			{isLoading && (
				<div className={styles.loader}>
					<Loader />
				</div>
			)}

			{error && (
				<InfoBanner
					title={translate("app.failedToFetch", true)}
					theme="plain"
				/>
			)}

			{group && group.programs.length > 0 && (
				<ProgramsList
					title={group.name}
					collection={group.programs}
					handkeClickItem={(program) =>
						navigate(`/profile/programs/catalog/${program.id}`)
					}
				/>
			)}
		</div>
	);
};

export default GroupDetail;
