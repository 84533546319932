import texts from "./ru.json";

/**
 * Хук для локализцаии
 * Принимает первым аргументом строку, составленную из ключей объектов и их детей, неограниченной вложенности
 * Второй аргумент  - флаг, показывающий в каком формате вернуть перевод:
 * 1. onlyText === false -  в виде <span> - в этом случае все переносы в тексте будут сохранены из возвращены с тегом <br>
 * 2. onlyText === true - в простой строки
 * */
export function useTranslate() {
	// const {lang} = useSelector(state => state.localization);

	return (key, onlyText = false) => {
		if (!key) {
			return "";
		}
		if (texts) {
			const chain = key.split(".");
			let result = texts;
			chain.forEach((enlace) => {
				if (result[enlace]) {
					result = result[enlace];
				} else {
					result = key;
				}
			});
			if (typeof result === "string") {
				if (onlyText) {
					return result;
				} else {
					return breakLines(result);
				}
			} else if (Array.isArray(result)) {
				if (onlyText) {
					return result;
				} else {
					return result.map((item) => breakLines(item));
				}
			} else {
				if (onlyText) {
					return JSON.stringify(result);
				} else {
					return breakLines(JSON.stringify(result));
				}
			}
		} else {
			return key;
		}
	};
}

const breakLines = (str) => {
	const arr = str.replace(/\u2028/g, "\n").split("\n");

	return arr.map(function (item, idx) {
		return (
			<span key={idx}>
				{item}
				{arr.length === idx + 1 ? null : <br />}
			</span>
		);
	});
};

export const langsMap = [
	{
		code: "ru",
		name: "Русский",
	},
	{
		code: "en",
		name: "English",
	},
	{
		code: "es",
		name: "Español",
	},
];

export const getLangObj = (code) => {
	if (!code) {
		return langsMap[0];
	}

	const obj = langsMap.find((lang) => lang.code === code);

	if (obj) {
		return obj;
	} else {
		return langsMap[0];
	}
};
