import styles from "./Login.module.css";
import { useTranslate } from "../../../localization/translate";
import LoginByPhone from "./phone-login/phone-login";
import Promo from "./promo";
import ByLogin from "./username-login";
import ByEmail from "./email-login";
import LoginSelector from "./login-selector";
import { selectLoginMethod } from "../services/login.slice";
import { useSelector } from "react-redux";
import InvalidMagicLink from "./magic-link/invalid-link";
import NewPasswordModal from "./reset-password/new-password.modal";

export const forms = {
	byPhone: <LoginByPhone />,
	byLogin: <ByLogin />,
	byEmail: <ByEmail />,
};

export default function AuthPage() {
	const translate = useTranslate();
	const form = useSelector(selectLoginMethod);

	return (
		<div className={styles.container}>
			<Promo />

			<div className={styles.rightpad}>
				<div className={styles.form}>
					<img className={styles.mobile_logo} src="/images/logo.svg" alt="" />
					<h1 className={styles.welcome}>{translate("login.welcome")}</h1>
					<div className={styles.description}>
						{translate("login.whyDoNeedLogin")}
					</div>
					<div className={styles.input_group}>
						{forms[form]}
						<LoginSelector />
					</div>
				</div>
			</div>
			<InvalidMagicLink />
			<NewPasswordModal />
		</div>
	);
}
