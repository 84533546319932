import {
	Link,
	Navigate,
	NavLink,
	useNavigate,
	useParams,
} from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./history.module.css";
import Button from "../../../../components/button/button";
import { setPatient } from "../../../profile/services/profile.slice";
import { useGetProfileQuery } from "../../../profile/services/profile.api";
import Empty from "./../../modules/empty-events";
import EventModal from "../event-list-item/event-item";
import moment from "moment";
import avatar from "./../../../../icons/doctor-avatar.svg";
import { useTranslate } from "../../../../localization/translate";
import {
	useGetHistoryQuery,
	useGetPollIdQuery,
	usePrepareRecordsMutation,
} from "../../services/events.api";
import HistoryCard from "./history-card";
import Dropdown from "../../../../components/dropdown/dropdown";
import DatePicker from "../../../../components/datepicker/datepicker";
import Loader from "../../../../components/loader";
import SearchInput from "../../../../components/search-input/search-input";
import FilterContainer from "../filter-container/filter-container";
import { useMediaQuery } from "beautiful-react-hooks";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import Records from "./records";
import {
	convertDate,
	moscowTimeZone,
} from "../../../../utils/dates/timezone-converter";
import SelectServices from "../../../appointment/modules/select-services";
import { setPaymentData } from "../../../payments/services/payments.slice";
import { PAYMENT_TYPES } from "../../../payments/helpers/constants";
import Modal from "../../../../components/modal-safari-wrapper/modal";
import CloseModalButton from "../../../../components/modal-items/close";
import EventsListLayout from "../events-list-layout/events-list-layout";
import grid from "./grid.module.css";
import css from "../polls/polls.module.css";
import { MedicationCard } from "../polls/MedicationCard";

export default function History() {
	const translate = useTranslate();
	const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
	const { data, isLoading } = useGetHistoryQuery(selectedYear);
	const isBigDesktop = useMediaQuery("(min-width: 1440px)");
	const isSmallView = useMediaQuery("(max-width: 1029px)");
	const isMobile = useMediaQuery("(max-width: 709px)");
	const isSmallMobile = useMediaQuery("(max-width: 400px)");
	const [isOpenServicesModal, setIsOpenServicesModal] = useState(false);
	const { data: profile } = useGetProfileQuery();
	const {
		data: pollId,
		error: pollError,
		loading: pollLoading,
	} = useGetPollIdQuery();

	const pollErrorCode = useMemo(
		() => pollError?.data?.messages?.[0]?.internalMessageCode,
		[pollError]
	);

	const [opened, setOpened] = useState(false);
	const [defaultDates, setDates] = useState();
	const [dateRange, setDateRange] = useState([null, null]);
	const [search, setSearch] = useState("");
	const [startDate, endDate] = dateRange;
	const momentMaxDate = moment()
		? moment.isMoment(moment())
			? moment()
			: moment(moment())
		: null;
	const [yearsExpanded, setExpanded] = useState(false);
	const [videoReceivingStatus, setVideoReceivingStatus] = useState(0);
	const [prepareRecords] = usePrepareRecordsMutation();

	const searchRef = React.createRef();
	const onKeyUp = (val) => {
		setSearch(val);
	};

	const years = useMemo(() => {
		const currentYear = moment().format("YYYY");
		let firstYear = currentYear;
		if (currentYear > selectedYear) {
			const diff = currentYear - selectedYear;
			if (diff > 4) {
				firstYear = selectedYear + 4;
			} else {
				firstYear = currentYear;
			}
		}

		const res = [firstYear];
		for (let i = 0; i < 6; i++) {
			res.push(res[res.length - 1] - 1);
		}
		return res;
	}, [selectedYear]);
	// const calculateMonth = () => {
	//     if (momentMaxDate) {
	//         const diff = momentMaxDate.diff(moment(),'months');
	//         return moment().add(diff > 2 ? 1 : diff,"month");
	//     }
	//     return moment().add(1,"month");
	// };
	// const [rightMonth, setRightMonth] = useState(calculateMonth());
	// const [leftMonth, setLeftMonth] = useState(calculateMonth());
	// const selectedYear = function (){
	//     const rightYear = rightMonth.format("YYYY");
	//     const leftYear = leftMonth.format("YYYY");
	//     if (rightYear === leftYear) {
	//         return leftYear;
	//     } else {
	//         return rightYear;
	//     }
	// }();
	const filterCallback = (list) => {
		setList(list);
	};

	const navigate = useNavigate();
	let { selected } = useParams();
	const [list, setList] = useState([]);
	selected = selected && parseInt(selected);

	const tempFilter = (item) => {
		if (startDate && endDate) {
			const itemDate = moment(item.date).hours(0).minutes(0).seconds(0);

			if (
				itemDate.isSame(startDate) ||
				itemDate.isSame(endDate) ||
				(itemDate.isBefore(endDate) && itemDate.isAfter(startDate))
			) {
				// console.log("mathes")
			} else {
				return false;
			}
		}
		return (
			/*moment(item.date).format("YYYY") === selectedYear && */ item.doctor &&
			searchFunc(
				item.speciality.name +
					item.doctor.shortname +
					item.patient.firstName +
					item.patient.lastName
			)
		);
	};
	const searchFunc = (str) =>
		str
			.toLowerCase()
			.replace(/\s/g, "")
			.includes(search.toLowerCase().replace(/\s/g, ""));
	const hasItems = useMemo(() => data && data.data.length > 0, [data]);
	const selectedItem = data
		? list.filter(tempFilter).find((item) => item.scheduleIdMis === selected)
		: null;
	const dispatch = useDispatch();

	const medCardNumber =
		profile?.medCardNumber !== selectedItem?.patient?.medCardNumber
			? selectedItem?.patient?.medCardNumber
			: null;

	const handleStartPreparing = async () => {
		const { error } = await prepareRecords(selectedItem.telemedicine.id);

		if (!error) {
			setVideoReceivingStatus(1);
			setList(
				list.map((item) => ({
					...item,
					telemedicine: {
						...item.telemedicine,
						videoReceivingStatus:
							item.telemedicine?.id === selectedItem.telemedicine.id
								? 1
								: item.telemedicine?.videoReceivingStatus || 0,
					},
				}))
			);
		}
	};

	const groupByDate = (list = []) => {
		const result = [];

		list.forEach((sourceItem) => {
			const sourceDate = sourceItem.date.split("T")[0];
			const itemByDate = result.find(
				(item) => item.date.split("T")[0] === sourceDate
			);

			if (itemByDate) {
				itemByDate.children.push(sourceItem);
			} else {
				result.push({
					date: sourceDate,
					children: [sourceItem],
				});
			}
		});

		return result;
	};
	const filtredItems = useMemo(
		() => groupByDate(list.filter(tempFilter)),
		[list, dateRange, search]
	);

	const handlePayOnline = () => {
		const isExamination = selectedItem?.type === "examination";
		if (isExamination && !selectedItem?.service?.id) {
			setIsOpenServicesModal(true);
		} else {
			dispatch(
				setPaymentData({
					PaymentModal: {
						type: PAYMENT_TYPES.service,
						purposeId: selectedItem.service.id,
						scheduleIdMis: selectedItem.scheduleIdMis,
						medCardNumber,
					},
					PaymentTarget: selectedItem.doctor,
				})
			);
		}
	};

	useEffect(() => {
		if (data?.data) {
			setList(data.data);
			const temp = [];

			data.data.forEach((item) => {
				temp.push(moment(item.date).format("YYYY-MM-DD"));
			});
			setDates(temp);
		}
	}, [data]);

	useEffect(() => {
		setVideoReceivingStatus(
			selectedItem?.telemedicine?.videoReceivingStatus || 0
		);
	}, [selectedItem]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, [selectedYear]);

	useEffect(() => {
		const isAppointmentOpened = !!window.location.pathname.split("/")[2];

		if (
			isBigDesktop &&
			filtredItems?.[0]?.children?.[0]?.scheduleIdMis &&
			!isAppointmentOpened
		) {
			navigate(
				`/events/history/${filtredItems?.[0]?.children?.[0]?.scheduleIdMis}`
			);
		}
	}, [isBigDesktop, filtredItems]);

	if (isLoading) return <Loader />;

	return (
		<div>
			<EventsListLayout
				medication={
					pollErrorCode !== 2203 &&
					!pollLoading && (
						<>
							<div className={css.title}>
								<h3>{translate("polls.yourMedication")}</h3>
							</div>

							<MedicationCard
								title={translate("polls.proactiveSurveillance.title")}
								description={translate(
									"polls.proactiveSurveillance.description"
								)}
								additionalTitle={
									pollId?.id
										? translate("polls.proactiveSurveillance.additionalTitle")
										: null
								}
								additionalDescription={
									pollId?.id
										? translate(
												"polls.proactiveSurveillance.additionalDescription"
										  )
										: null
								}
								onClick={() => navigate("/")}
							/>
						</>
					)
				}
				filters={
					<>
						<div className={styles.filters}>
							<div className={styles.years_dropdown_container}>
								<Button
									className={styles.eventButton}
									onClick={() => setExpanded(!yearsExpanded)}
									defaultFontSize={false}
								>
									{selectedYear} {translate("app.year", true)}
									<div className={styles.arrow}>
										<svg
											width="15"
											height="8"
											viewBox="0 0 15 8"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M1.10547 0.443827C1.35742 0.185392 1.76591 0.185392 2.01786 0.443827L7.58317 6.15236L13.1485 0.443827C13.4004 0.185392 13.8089 0.185392 14.0609 0.443827C14.3128 0.702262 14.3128 1.12127 14.0609 1.3797L8.03937 7.55617C7.78742 7.81461 7.37892 7.81461 7.12697 7.55617L1.10547 1.3797C0.853516 1.12127 0.853516 0.702262 1.10547 0.443827Z"
												fill="white"
											/>
										</svg>
									</div>
								</Button>

								<Dropdown
									expanded={yearsExpanded}
									setExpanded={setExpanded}
									className={styles.years_dropdown}
									closeBtn
									title={`${translate("app.year", true)}`}
								>
									{years.map((year) => (
										<div
											className={styles.item}
											key={`'year-${year}`}
											onClick={() => {
												// const newDate = moment(rightMonth).set("year", year);
												// setRightMonth(newDate);
												setSelectedYear(year);
												setExpanded(false);
											}}
										>
											<span>{year}</span>
											{selectedYear === year && (
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M19.8173 6.19433C20.059 6.45547 20.0611 6.88121 19.8222 7.14525L9.2727 18.8004C9.15711 18.9281 8.99955 19 8.83517 19C8.67078 19 8.51322 18.9281 8.39762 18.8004L4.17784 14.1384C3.93885 13.8743 3.94101 13.4486 4.18265 13.1874C4.4243 12.9263 4.81393 12.9286 5.05292 13.1927L8.83517 17.3714L18.9471 6.19958C19.1861 5.93554 19.5757 5.93319 19.8173 6.19433Z"
														fill="#7A8CA4"
													/>
												</svg>
											)}
										</div>
									))}
								</Dropdown>
							</div>

							<Button
								className={styles.dateRange}
								secondary={!startDate}
								onClick={() => {
									setOpened(!opened);
								}}
								defaultFontSize={false}
							>
								{startDate && endDate ? (
									<>
										{moment(startDate).format("DD.MM")}
										&nbsp;-&nbsp;
										{moment(endDate).format("DD.MM.YYYY")}
										&nbsp;
									</>
								) : (
									translate("history.list.changeDateRange", true)
								)}
								<div className={styles.arrow}>
									{startDate ? (
										<svg
											width="15"
											height="8"
											viewBox="0 0 15 8"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M1.10547 0.443827C1.35742 0.185392 1.76591 0.185392 2.01786 0.443827L7.58317 6.15236L13.1485 0.443827C13.4004 0.185392 13.8089 0.185392 14.0609 0.443827C14.3128 0.702262 14.3128 1.12127 14.0609 1.3797L8.03937 7.55617C7.78742 7.81461 7.37892 7.81461 7.12697 7.55617L1.10547 1.3797C0.853516 1.12127 0.853516 0.702262 1.10547 0.443827Z"
												fill="white"
											/>
										</svg>
									) : (
										<svg
											width="15"
											height="8"
											viewBox="0 0 15 8"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M1.10547 0.443827C1.35742 0.185392 1.76591 0.185392 2.01786 0.443827L7.58317 6.15236L13.1485 0.443827C13.4004 0.185392 13.8089 0.185392 14.0609 0.443827C14.3128 0.702262 14.3128 1.12127 14.0609 1.3797L8.03937 7.55617C7.78742 7.81461 7.37892 7.81461 7.12697 7.55617L1.10547 1.3797C0.853516 1.12127 0.853516 0.702262 1.10547 0.443827Z"
												fill="#7A8CA4"
											/>
										</svg>
									)}
								</div>
							</Button>
							{isSmallView ? (
								<Modal isOpen={opened} onRequestClose={setOpened}>
									<CloseModalButton closeModal={() => setOpened(false)} />
									<DatePicker
										maxDate={moment()}
										markedDates={defaultDates}
										setDateRange={(arg) => {
											setDateRange(arg);
											setOpened(false);
										}}
										oneMonth={isMobile}
										dateRange={dateRange}
										externalDateRange={dateRange}
										calendarClassName={classNames(
											styles.modal__datepicker,
											styles.mobile
										)}
										footerClassName={classNames(
											styles.modal__datepickerFooter,
											styles.mobile
										)}
									/>
								</Modal>
							) : (
								<Dropdown
									expanded={opened}
									closeBtn
									title={translate("history.list.period", true)}
									className={styles.calendar}
									setExpanded={setOpened}
								>
									<DatePicker
										// maxDate={moment()}
										maxDate={moment("2020-11-31")}
										markedDates={defaultDates}
										setDateRange={(arg) => {
											setDateRange(arg);
											setOpened(false);
										}}
										dateRange={dateRange}
										openState={setOpened}
										footerClassName={styles.modal__datepickerFooter}
									/>
								</Dropdown>
							)}
						</div>
						<div className={styles.filters__search}>
							<SearchInput
								className={styles.filters__searchInput}
								ref={searchRef}
								onChange={onKeyUp}
								value={search}
							/>
							{search && (
								<div
									className={styles.filters__searchCancel}
									onClick={() => setSearch("")}
								>
									{translate("events.history.cancel", true)}
								</div>
							)}
						</div>
					</>
				}
				navigate={
					<>
						<div className={css.title}>
							<h3>{translate("polls.doctorsAppointments")}</h3>
							{isSmallMobile && hasItems && (!selectedItem || isBigDesktop) && (
								<div className={styles.filterButton}>
									<FilterContainer
										applyCallback={filterCallback}
										list={data.data}
										externalDateRange={dateRange}
									/>
								</div>
							)}
						</div>

						<div className={css.navigation}>
							<NavLink to="/events" end className={styles.link}>
								{({ isActive }) => (
									<Button
										className={styles.eventButton}
										secondary={!isActive}
										defaultFontSize={false}
									>
										{translate("events.plan.planTitle", true)}
									</Button>
								)}
							</NavLink>
							<NavLink to="/events/history" className={styles.historyLink}>
								{({ isActive }) => (
									<Button
										className={styles.eventButton}
										secondary={!isActive}
										defaultFontSize={false}
									>
										{translate("events.history.historyTitle", true)}
									</Button>
								)}
							</NavLink>
							{!isSmallMobile &&
								hasItems &&
								(!selectedItem || isBigDesktop) && (
									<div className={styles.filterButton}>
										<FilterContainer
											applyCallback={filterCallback}
											list={data.data}
											externalDateRange={dateRange}
										/>
									</div>
								)}
						</div>
					</>
				}
				selected={
					selectedItem && (
						<>
							<HistoryCard
								type={"history"}
								status={selectedItem.status}
								receptionType={selectedItem.type}
								shortname={
									selectedItem.doctor ? selectedItem.doctor.shortname : "_"
								}
								specialities={
									selectedItem.speciality ? selectedItem.speciality.name : "_"
								}
								rating={selectedItem.doctor ? selectedItem.doctor.rating : 0}
								minPrice={
									selectedItem.doctor ? selectedItem.doctor.minPrice : 0
								}
								experienceYearsAll={
									selectedItem.doctor
										? selectedItem.doctor.experienceYearsAll
										: 0
								}
								photoUrl={
									selectedItem.doctor ? selectedItem.doctor.photoUrl : avatar
								}
								date={
									<>
										{moment(selectedItem.date).format("D MMMM в H:mm")}
										{moscowTimeZone() ? "" : translate("app.byMskTime")}
									</>
								}
								sourceDate={selectedItem.date}
								service={selectedItem.service}
								patient={selectedItem.patient}
								feature={selectedItem.feature}
								cabinet={selectedItem.cabinet}
								doctor={selectedItem.doctor}
								speciality={selectedItem.speciality}
								slotIdMis={selectedItem.scheduleIdMis}
								telemedicine={selectedItem.telemedicine}
								hasReview={selectedItem.hasReview}
								isOnline={selectedItem.isOnline}
								additionalSlots={selectedItem.additionalSlots}
							/>

							{selectedItem.telemedicine && selectedItem.telemedicine.id && (
								<div className={styles.telemed}>
									<Records
										records={selectedItem.telemedicine.records}
										status={videoReceivingStatus}
										handleStartPreparing={handleStartPreparing}
									/>
								</div>
							)}

							{!(selectedItem.telemedicine && selectedItem.telemedicine.id) &&
								selectedItem.status !== "cancelled" &&
								!selectedItem.isPaid && (
									<Button
										className={styles.pay}
										onClick={handlePayOnline}
										defaultFontSize={false}
									>
										{translate("appointment.confirm.payOnline", true)}
									</Button>
								)}

							{selectedItem.status !== "cancelled" && (
								<NavLink
									to={"/history/receptions"}
									style={{
										marginTop: !(
											!(
												selectedItem.telemedicine &&
												selectedItem.telemedicine.id
											) && !selectedItem.isPaid
										)
											? 10
											: 0,
									}}
									onClick={() => dispatch(setPatient(selectedItem.patient))}
									className={styles.event_save}
								>
									{translate("events.history.protocols", true)}
								</NavLink>
							)}

							{selectedItem.status !== "cancelled" && (
								<NavLink to={"/profile/orders"} className={styles.event_save}>
									{translate("events.history.invoicing", true)}
								</NavLink>
							)}

							<SelectServices
								isOpen={isOpenServicesModal}
								doctorId={selectedItem?.doctor?.id}
								slotIdMis={selectedItem?.scheduleIdMis}
								cancelExamination={() => setIsOpenServicesModal(false)}
								onCloseSelf={() => setIsOpenServicesModal(false)}
								medCardNumber={medCardNumber}
							/>
						</>
					)
				}
			>
				{hasItems && (
					<>
						{filtredItems.map((item) => (
							<div key={item.date} className={grid.list__section}>
								<div className={grid.list__sectionDate}>
									{moment().format("D MMMM YYYY") ===
									moment(item.date).format("D MMMM YYYY") ? (
										<>{translate("events.today", true)}</>
									) : (
										<>{moment(item.date).format("D MMMM YYYY, dddd")}</>
									)}
								</div>
								<div className={grid.list__sectionItems}>
									{item.children.map((item) => (
										<div
											key={item.scheduleIdMis}
											onClick={() =>
												navigate(`/events/history/${item.scheduleIdMis}`)
											}
											className={grid.list__sectionItem}
										>
											<EventModal
												hasReview={item.hasReview}
												type={"history"}
												doctor={item.doctor}
												speciality={item.speciality}
												shortname={item.doctor ? item.doctor.shortname : "_"}
												specialities={
													item.speciality ? item.speciality.name : "_"
												}
												cabinet={
													item.status === "cancelled"
														? translate("events.history.visitNotPlace", true)
														: item.cabinet
												}
												sourceDate={item.date}
												patient={item.patient}
												photoUrl={item.doctor ? item.doctor.photoUrl : avatar}
												isOnline={item.isOnline}
												isSelected={selected === item.scheduleIdMis}
												scheduleIdMis={item.scheduleIdMis}
											/>
										</div>
									))}
								</div>
							</div>
						))}
						{filtredItems.length > 0 && isBigDesktop && (
							<div
								className={styles.empty_link}
								onClick={() => setSelectedYear(selectedYear - 1)}
							>
								{`${translate("events.history.showEvents", true)} ${
									selectedYear - 1
								}  ${translate("app.year", true)}`}
							</div>
						)}
					</>
				)}
			</EventsListLayout>

			{filtredItems.length > 0 && !isBigDesktop && !selectedItem && (
				<div
					className={styles.empty_link}
					onClick={() => setSelectedYear(selectedYear - 1)}
					style={{ margin: "40px auto 20px" }}
				>
					{`${translate("events.history.showEvents", true)} ${
						selectedYear - 1
					}  ${translate("app.year", true)}`}
				</div>
			)}

			{list.filter(tempFilter).length === 0 && (
				<>
					<Empty
						title={translate("history.list.hasNoItems", true)}
						text={`${translate(
							"events.history.in",
							true
						)} ${selectedYear} ${translate(
							"events.history.yearHasEmpty",
							true
						)}`}
						link={
							<div
								className={styles.empty_link}
								onClick={() => {
									// const newDate = moment(rightMonth).set("year", selectedYear - 1);
									// setRightMonth(newDate);
									setSelectedYear(selectedYear - 1);
								}}
							>
								{`${translate("events.history.showEvents", true)} ${
									selectedYear - 1
								}  ${translate("app.year", true)}`}
							</div>
						}
					/>
				</>
			)}
		</div>
	);
}
