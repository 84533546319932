import style from "./notice.module.css";
import classNames from "classnames";

export default function ProfileNotice({
	title,
	message,
	btn,
	important = null,
	isGrey = false,
	isSmall = false,
	className: externalClass,
	messageClassName,
	titleClassName,
}) {
	const className = classNames(
		style.notice,
		{
			[style.grey]: isGrey,
			[style.small]: isSmall,
			[style.important]: important,
		},
		externalClass
	);

	if (isSmall) {
		return (
			<div className={className}>
				<div className={style.title_container}>
					<div className={style.icon}>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M8 6.25C8.41421 6.25 8.75 6.58579 8.75 7V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7C7.25 6.58579 7.58579 6.25 8 6.25Z"
							/>
							<path d="M9 4C9 4.55228 8.55229 5 8 5C7.44772 5 7 4.55228 7 4C7 3.44772 7.44772 3 8 3C8.55229 3 9 3.44772 9 4Z" />
						</svg>
					</div>
					{title && (
						<div className={classNames(style.title, titleClassName)}>
							{title}
						</div>
					)}
				</div>
				<div className={style.body}>
					{message && (
						<div className={classNames(style.text, messageClassName)}>
							{message}
						</div>
					)}
					{btn && (
						<div className={style.btn} onClick={btn.onClick}>
							{btn.text}
						</div>
					)}
				</div>
			</div>
		);
	}

	return (
		<div className={className}>
			<div className={style.icon}>
				<svg
					width="28"
					height="28"
					viewBox="0 0 28 28"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M14.0001 24.8334C19.9832 24.8334 24.8334 19.9832 24.8334 14.0001C24.8334 8.017 19.9832 3.16675 14.0001 3.16675C8.017 3.16675 3.16675 8.017 3.16675 14.0001C3.16675 19.9832 8.017 24.8334 14.0001 24.8334ZM14.0001 27.3334C21.3639 27.3334 27.3334 21.3639 27.3334 14.0001C27.3334 6.63628 21.3639 0.666748 14.0001 0.666748C6.63628 0.666748 0.666748 6.63628 0.666748 14.0001C0.666748 21.3639 6.63628 27.3334 14.0001 27.3334Z"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M14.0001 11.0834C14.6904 11.0834 15.2501 11.6431 15.2501 12.3334V20.6667C15.2501 21.3571 14.6904 21.9167 14.0001 21.9167C13.3097 21.9167 12.7501 21.3571 12.7501 20.6667V12.3334C12.7501 11.6431 13.3097 11.0834 14.0001 11.0834Z"
					/>
					<path d="M15.6667 7.33341C15.6667 8.25389 14.9206 9.00008 14.0001 9.00008C13.0796 9.00008 12.3334 8.25389 12.3334 7.33341C12.3334 6.41294 13.0796 5.66675 14.0001 5.66675C14.9206 5.66675 15.6667 6.41294 15.6667 7.33341Z" />
				</svg>
			</div>
			<div className={style.body}>
				{important && (
					<div className={style.important}>
						<span>{important}</span>
					</div>
				)}
				{title && (
					<div className={classNames(style.title, titleClassName)}>{title}</div>
				)}
				{message && (
					<div className={classNames(style.text, messageClassName)}>
						{message}
					</div>
				)}
				{btn && (
					<div className={style.btn} onClick={btn.onClick}>
						{btn.text}
					</div>
				)}
			</div>
		</div>
	);
}
