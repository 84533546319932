import {
	useGetNotificationsQuery,
	useGetProfileQuery,
	useSaveNotificationsMutation,
} from "../../services/profile.api";
import { useDispatch } from "react-redux";
import { setEditModalState } from "../../services/profile.slice";
import PersonalDataEdit from "../personala-data/personal-data.edit.";
import ProfileNotice from "../personala-data/notice";
import { useTranslate } from "../../../../localization/translate";
import styles from "./profile-notifications.module.css";
import classNames from "classnames";
import Loader from "../../../../components/loader";
import ReactSwitch from "react-switch";
import { useEffect, useState } from "react";
import { setupNotifications } from "../../../../utils/firebase/init";
import { useSendNotificationTokenMutation } from "../../../login/services/login.api";

export default function ProfileNotifications() {
	const { data, isLoading } = useGetNotificationsQuery();
	const [save, { data: savedData, error: savingError }] =
		useSaveNotificationsMutation();
	const { data: profile, error } = useGetProfileQuery();
	const dispatch = useDispatch();
	const translate = useTranslate();
	const [localData, setLocalData] = useState(data);
	const [whichSaving, setWhichSaving] = useState(null);
	const [sendNotificationToken] = useSendNotificationTokenMutation();

	const onChange = (value) => {
		if (value.sendingType === "PUSH") {
			save(value);
			setWhichSaving(value.id);

			if (value.isEnabled)
				setupNotifications()
					.then((token) => token && sendNotificationToken(token))
					.catch(console.warn);
		} else {
			save(value);
			setWhichSaving(value.id);
		}
	};

	const showItems = (sendingType) => {
		if (sendingType === "EMAIL" && !error) {
			return !(profile && !profile.email);
		} else {
			return true;
		}
	};

	useEffect(() => {
		if (savingError) {
			setWhichSaving(null);
		}
	}, [savingError]);

	useEffect(() => {
		if (data) {
			setLocalData(data);
		}
	}, [data]);

	useEffect(() => {
		if (savedData) {
			setLocalData(savedData);
			setWhichSaving(null);
		}
	}, [savedData]);

	return (
		<div className={styles.notifications}>
			<div className={classNames("h3", styles.title, "profile_content_title")}>
				{translate("profile.notification.title")}
			</div>
			{isLoading && (
				<div className={styles.loader}>
					<Loader />
				</div>
			)}
			<div>
				{localData &&
					localData.map((list) => (
						<div className={styles.list} key={list.sendingType}>
							<div className={styles.title}>
								<div className={styles.category_name}>
									{list.name}{" "}
									{profile?.email &&
										list.sendingType === "EMAIL" &&
										`(${profile.email})`}
								</div>
								{!error &&
									profile &&
									!profile.email &&
									list.sendingType === "EMAIL" && (
										<div className={styles.profile_notice}>
											<ProfileNotice
												isGrey
												title={translate("app.attention", true)}
												message={translate(
													"profile.notification.attentionIfHaveNoEmail"
												)}
												btn={{
													onClick: () => dispatch(setEditModalState(true)),
													text: translate("profile.notification.specify", true),
												}}
											/>
										</div>
									)}
								{showItems(list.sendingType) &&
									list.notifications.map((notification) => (
										<div
											className={styles.item}
											key={`notification-${notification.id}`}
										>
											<div className={styles.item_name}>
												{notification.name}
											</div>
											<ReactSwitch
												onChange={(value) =>
													onChange({
														id: notification.id,
														sendingType: list.sendingType,
														isEnabled: value,
													})
												}
												handleDiameter={18}
												offColor="#D4DDE7"
												onColor="#54A0FF"
												disabled={whichSaving === notification.id}
												height={22}
												width={36}
												uncheckedIcon={false}
												checkedIcon={false}
												checked={notification.isEnabled}
											/>
										</div>
									))}
							</div>
						</div>
					))}
			</div>
			<PersonalDataEdit />
		</div>
	);
}
