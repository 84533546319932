import css from "./form.module.css";
import moment from "moment";
import trash from "../../../../icons/trash_blue_18x18.svg";
import cancelCross from "../../../../icons/cancel_cross_blue_18x18.svg";
import md5 from "md5";
import Textarea from "../../../../components/input/Textarea/textarea";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslate } from "../../../../localization/translate";
import classNames from "classnames";
import Button from "../../../../components/button/button";
import Checkbox from "../../../../components/input/checkbox/Checkbox";
import { setSubjectOpinion } from "../../services/appointment.slice";
import { useDispatch, useSelector } from "react-redux";
import { useSubmitOpinionMutation } from "../../services/appointment.api";
import { useGetProfileQuery } from "../../../profile/services/profile.api";
import { selectPatient } from "../../../profile/services/profile.slice";
import { pushSystemNotification } from "../../../../app/app.slice";
import { getErrorMessageFromError } from "../../../../app/error-handler.api";
import Loader from "../../../../components/loader";
import { useUploadMutation } from "../../../../app/applcation.api";
import { setPaymentData } from "../../../payments/services/payments.slice";
import { PAYMENT_TYPES } from "../../../payments/helpers/constants";
import Agreements from "../../../agreements/agreements";
import { CODES_ENUM } from "../../../agreements/constants";
import { isSafari } from "../../../../utils";
import { Metrics } from "../../../../utils/metrics";

export default function SubjectOpinionForm({
	userSelection,
	data: opinionSubject,
}) {
	const patient = useSelector(selectPatient);
	const { data: profile } = useGetProfileQuery();
	const currentPatient = patient || profile;
	const medCardNumber =
		currentPatient?.medCardNumber !== profile?.medCardNumber
			? currentPatient?.medCardNumber
			: null;

	const [complaints, setComplaints] = useState("");
	const [attachLink, setAttachLink] = useState("");
	const [type, setType] = useState(null);
	const [errors, setErrors] = useState({});
	const [documentsListAcception, setDocumentsListAcception] = useState(false);
	const [privacyPolicyAcception, setPrivacyPolicyAcception] = useState(false);
	const [files, setFiles] = useState();
	const translate = useTranslate();
	const subject = opinionSubject.find(
		(item) => item.id === userSelection.subjectId
	);
	const dispatch = useDispatch();
	const [submit, { data, error, isLoading }] = useSubmitOpinionMutation();
	const isDisabled =
		isLoading || (files && files.find((file) => file.status === "pending"));

	const onSubmit = () => {
		setErrors({});
		const newErrors = {};

		if (!documentsListAcception) {
			newErrors.documentsListAcception = translate(
				"profile.personalData.checkboxError",
				true
			);
		}
		if (!privacyPolicyAcception) {
			newErrors.privacyPolicyAcception = translate(
				"profile.personalData.checkboxError",
				true
			);
		}
		if (complaints.length === 0) {
			newErrors.complaints = translate("app.forms.required", true);
		}
		if (!files && attachLink.length === 0) {
			dispatch(
				pushSystemNotification({
					type: "error",
					clientMessage: translate(
						"appointment.subjectOpinion.fileIsEmptyError",
						true
					),
				})
			);
		}
		const expression =
			/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
		const regex = new RegExp(expression);
		if (!!attachLink && !attachLink.match(regex)) {
			newErrors.attachFiles = translate(
				"appointment.subjectOpinion.wrongAttachUrl",
				true
			);
		}

		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);

			return null;
		}

		submit({
			medCardNumber: currentPatient.medCardNumber,
			opinionSubjectId: userSelection.subjectId,
			...userSelection,
			files:
				files && files.length > 0
					? files
							.filter((file) => file.status === "success")
							.map((file) => ({
								file: file.serverName,
								name: file.originalFile.name,
							}))
					: null,
			attachFiles: attachLink.length === 0 ? null : attachLink,
			complaints,
			documentsListAcception,
			privacyPolicyAcception,
		});
	};

	const handleAgreement = (type) => {
		if ([CODES_ENUM.PD_CONDITIONS, CODES_ENUM.PD_POLICY].includes(type)) {
			setPrivacyPolicyAcception(true);
		}
		setType(null);
	};

	useEffect(() => {
		if (!subject) {
			dispatch(setSubjectOpinion(null));
		}
	}, [subject]);

	useEffect(() => {
		if (data) {
			const {
				data: { id },
			} = data;
			Metrics.gtmEvent("second_opinion_reserve", {
				doctorId: userSelection.doctorId,
			});
			dispatch(setSubjectOpinion(null));
			dispatch(
				setPaymentData({
					PaymentModal: {
						type: PAYMENT_TYPES.opinion,
						purposeId: id,
						medCardNumber,
					},
					PaymentTarget: data.data,
				})
			);
		}
		if (error) {
			const clarifications = getErrorMessageFromError({
				fromClarifications: true,
				error,
			});
			const newError = {};

			if (!clarifications || !Array.isArray(clarifications)) {
				return null;
			}
			clarifications.forEach((err) => (newError[err.target] = err.message));
			setErrors(newError);
		}
	}, [data, error]);

	if (!subject) {
		dispatch(setSubjectOpinion(null));

		return null;
	}

	return (
		<div className={css.form}>
			<div className={classNames("text", css.pretitle)}>
				{translate("appointment.subjectOpinion.tellAboutTheProblem")}
			</div>
			<Textarea
				value={complaints}
				error={errors.complaints}
				onChange={setComplaints}
				placeholder={translate("appointment.subjectOpinion.complaints", true)}
				className={css.textarea}
				specialLabelClassName={css.label}
			/>
			<div className={css.attach_files}>
				<div className={classNames("text", css.subtitle)}>
					{translate("appointment.subjectOpinion.attachFiles")}
				</div>
				<div className={classNames("text", css.description)}>
					{translate("appointment.subjectOpinion.attachFilesDescription")}
				</div>
				<Files files={files} setFiles={setFiles} />
			</div>
			<div className={css.cloud_link}>
				<div className={classNames("text", css.description)}>
					{translate("appointment.subjectOpinion.docCloudLink")}
				</div>
				<Textarea
					value={attachLink}
					error={errors.attachFiles}
					onChange={setAttachLink}
					placeholder={translate(
						"appointment.subjectOpinion.docCloudLinkPlaceholder",
						true
					)}
					className={css.textarea}
					specialLabelClassName={css.label}
				/>
			</div>

			<div className={css.checkboxes}>
				<div className={css.checkbox}>
					<Checkbox
						label={
							<>
								{translate("appointment.subjectOpinion.iAmAgree")}
								<a rel="noopener" target="_blank" href={subject.documentsList}>
									{translate("appointment.subjectOpinion.necessaryDocsList")}
								</a>
								{translate("appointment.subjectOpinion.docConditionCancel")}
							</>
						}
						onChange={setDocumentsListAcception}
						checked={documentsListAcception}
						error={errors.documentsListAcception}
					/>
				</div>
				<div className={css.checkbox}>
					<Checkbox
						label={
							<>
								{translate("appointment.subjectOpinion.ppaAgree")}
								<a
									style={{ cursor: "pointer" }}
									onClick={() => setType(CODES_ENUM.PD_CONDITIONS)}
								>
									{translate("appointment.subjectOpinion.ppaLink")}
								</a>
								{translate("appointment.subjectOpinion.ppaAccording")}
								<a
									style={{ cursor: "pointer" }}
									onClick={() => setType(CODES_ENUM.PD_POLICY)}
								>
									{translate("appointment.subjectOpinion.privacyPolicy")}
								</a>
								{translate("appointment.subjectOpinion.ppaFor")}
							</>
						}
						onChange={setPrivacyPolicyAcception}
						checked={privacyPolicyAcception}
						error={errors.privacyPolicyAcception}
					/>
				</div>
			</div>
			<Button disabled={isDisabled} onClick={onSubmit} className={css.payBtn}>
				{translate("app.goToPayment")}
			</Button>

			<Agreements
				isOpen={!!type}
				type={type}
				onClose={() => setType(null)}
				onAgree={handleAgreement}
			/>
		</div>
	);
}

const Files = ({ setFiles, files = [] }) => {
	const translate = useTranslate();
	const ref = useRef();
	const ALLOWED_FILE_TYPES = [".jpg", ".jpeg", ".pdf", ".zip", ".rar"];
	const onChange = (e) => {
		const newFiles = Array.from(e.target.files).map((file) => {
			let wrongFormat = true;
			let toBig = false;

			ALLOWED_FILE_TYPES.forEach((format) => {
				if (file.name.endsWith(format)) {
					wrongFormat = false;
				}
			});

			if (
				(file.name.endsWith(".zip") || file.name.endsWith(".rar")) &&
				file.size > 30 * 1024 * 1024
			) {
				toBig = true;
			}

			if (
				["image/jpeg", "application/pdf"].includes(file.type) &&
				file.size > 3 * 1024 * 1024
			) {
				toBig = true;
			}

			return {
				id: md5(file.name + file.size + moment().unix()),
				originalFile: file,
				status: wrongFormat || toBig ? "failure" : "pending",
				wrongFormat,
				toBig,
			};
		});
		ref.current.value = null;
		setFiles([].concat(files ? files : [], newFiles));
	};
	const onUpload = (file) =>
		setFiles(
			[].concat(files.map((_file) => (_file.id === file.id ? file : _file)))
		);
	const removeFile = (id) =>
		setFiles([].concat(files.filter((file) => file.id !== id)));

	return (
		<div>
			{files &&
				files.length > 0 &&
				files.map((file) => (
					<File
						file={file}
						key={`file-${file.id}`}
						removeFile={removeFile}
						onUpload={onUpload}
					/>
				))}
			<Button light onClick={() => ref.current.click()}>
				{translate("appointment.subjectOpinion.attachFilesButton")}
			</Button>
			<input
				hidden
				type="file"
				ref={ref}
				accept={ALLOWED_FILE_TYPES.join(",")}
				multiple
				onChange={onChange}
			/>
		</div>
	);
};

const File = ({ file, removeFile, onUpload }) => {
	const [submitFile, { data, error }] = useUploadMutation();
	const translate = useTranslate();
	const fileError = file.wrongFormat || file.toBig;

	useEffect(() => {
		if (file && file.status === "pending") {
			submitFile(file.originalFile);
		}
	}, [file]);

	useEffect(() => {
		if (data) {
			onUpload({
				...file,
				serverName: data.data.fileName,
				status: "success",
			});
		}

		if (error) {
			onUpload({
				...file,
				status: "failure",
			});
		}
	}, [data, error]);

	const fileName = useMemo(() => {
		const chunks = file.originalFile.name.split(".");
		const name = chunks.slice(0, -1).join(".");
		const ext = chunks[chunks.length - 1];
		const formattedName =
			name.length >= 20 ? `${name.slice(0, 10)}...${name.slice(-5)}` : name;
		return `${formattedName}.${ext}`;
	}, [file.originalFile.name]);

	return (
		<div className={classNames(css.file, { [css.error]: fileError })}>
			<div className={css.container}>
				{file.status === "pending" && (
					<div className={css.file_loader}>
						<Loader height={28} width={28} />
					</div>
				)}
				<div className={css.file_name}>{fileName}</div>
				<div className={css.file_icon} onClick={() => removeFile(file.id)}>
					{file.status === "pending" ? (
						<img src={cancelCross} alt="" />
					) : (
						<img src={trash} alt="" />
					)}
				</div>
			</div>
			{file.wrongFormat && (
				<div className={css.error_message}>
					{translate("appointment.subjectOpinion.wrongFileType")}
				</div>
			)}
			{file.toBig && (
				<div className={css.error_message}>
					{translate("appointment.subjectOpinion.toBig")}
				</div>
			)}
		</div>
	);
};
