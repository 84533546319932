import { useMemo, useState } from "react";
import { useTranslate } from "../../../../localization/translate";
import Loader from "../../../../components/loader";
import AvatarModal from "./avatar-modal";
import styles from "./personal-data.module.css";

export const AvatarItem = ({ photo, isLoading, initials }) => (
	<>
		{isLoading && <Loader />}
		{!isLoading && photo && <img src={photo} alt="" />}
		{!isLoading &&
			!photo &&
			(initials ? (
				<span className={styles.initials}>{initials}</span>
			) : (
				<svg
					width="26"
					height="30"
					viewBox="0 0 26 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M17.509 8.20317C17.509 5.43587 15.3363 3.19504 12.6532 3.19504C9.97002 3.19504 7.79732 5.43587 7.79732 8.20317C7.79732 10.9705 9.97002 13.2113 12.6532 13.2113C15.3363 13.2113 17.509 10.9705 17.509 8.20317ZM20.2838 8.20317C20.2838 12.551 16.8688 16.0731 12.6532 16.0731C8.43755 16.0731 5.02254 12.551 5.02254 8.20317C5.02254 3.85535 8.43755 0.333252 12.6532 0.333252C16.8688 0.333252 20.2838 3.85535 20.2838 8.20317ZM2.94146 25.5783C2.94146 26.9282 2.83029 26.8048 3.57395 26.8048H22.4261C23.1697 26.8048 23.0586 26.9282 23.0586 25.5783C23.0586 22.3042 18.4746 20.3658 13 20.3658C7.52542 20.3658 2.94146 22.3042 2.94146 25.5783ZM0.166687 25.5783C0.166687 20.0261 6.13095 17.504 13 17.504C19.8691 17.504 25.8334 20.0261 25.8334 25.5783C25.8334 28.4636 24.7497 29.6666 22.4261 29.6666H3.57395C1.25039 29.6666 0.166687 28.4636 0.166687 25.5783Z"
						fill="#54A0FF"
					/>
				</svg>
			))}
	</>
);

export default function Avatar({
	photo,
	firstName,
	lastName,
	editable = true,
}) {
	const translate = useTranslate();
	const [isOpenModal, setIsOpenModal] = useState(false);

	const handleOpenModal = () => setIsOpenModal(true);
	const handleCloseModal = () => setIsOpenModal(false);

	const initials = useMemo(
		() =>
			firstName && lastName
				? `${firstName[0].toUpperCase()}.${lastName[0].toUpperCase()}.`
				: null,
		[firstName, lastName]
	);

	return (
		<div className={styles.avatar}>
			<div className={styles.preview}>
				<AvatarItem photo={photo} initials={initials} />
			</div>

			{editable && (
				<div className={styles.change} onClick={handleOpenModal}>
					{translate(`app.forms.${photo ? "changeBtn" : "addBtn"}`)}
				</div>
			)}

			<AvatarModal
				photo={photo}
				isOpen={isOpenModal}
				onClose={handleCloseModal}
				renderAvatar={(props) => <AvatarItem initials={initials} {...props} />}
			/>
		</div>
	);
}
