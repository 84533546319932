import { useEffect, useRef, useMemo } from "react";
import QRCode from "qrcode";
import QrDownload from "../qr-download";
import QrModalWrapper from "../qr-modal/qr-modal.wrapper";
import { defaultOptions } from "../../constants";
import styles from "./style.module.css";

export default function QrCode({
	uid = null,
	description,
	downloadable = false,
	viewable = false,
	options = {},
}) {
	const ref = useRef(null);

	const extraOptions = useMemo(
		() => ({ ...defaultOptions, ...options }),
		[options]
	);

	useEffect(() => {
		QRCode.toCanvas(uid, extraOptions)
			.then((canvas) => ref.current.prepend(canvas))
			.catch(console.warn);
	}, []);

	return (
		<div ref={ref} className={styles.qr}>
			{<div className={styles.description}>{description}</div>}

			{(downloadable || viewable) && (
				<div className={styles.actions}>
					{downloadable && <QrDownload uid={uid} />}
					{viewable && <QrModalWrapper uid={uid} />}
				</div>
			)}
		</div>
	);
}
