import React, { useEffect, useMemo, useState } from "react";
import Patient from "./patient-dropdown";
import styles from "./history-card.module.css";
import DoctorInfoCard from "../../doctor-info-card";
import Button from "../../../../../components/button/button";
import { useTranslate } from "../../../../../localization/translate";
import {
	selectWillBeCanceled,
	setAppointmentToCancel,
	setRescheduleDoctor,
} from "../../../services/events.slice";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "beautiful-react-hooks";
import ModalInfo from "../../../../../components/custom-modal/info";
import CloseModalButton from "../../../../../components/modal-items/close";
import classNames from "classnames";
import { useExportCalendarEventMutation } from "../../../../appointment/services/appointment.api";
import { useGetTelemedLinkMutation } from "../../../services/events.api";
import {
	setRateInfo,
	setSelectedDoctor,
} from "../../../../appointment/services/appointment.slice";
import Loader from "../../../../../components/loader";
import { downloader } from "../../../../../utils";
import { getErrorMessageFromError } from "../../../../../app/error-handler.api";
import { Metrics } from "../../../../../utils/metrics";
import moment from "moment";
import cn from "classnames";
import { ReactComponent as StarFilledIcon } from "../../../../../icons/star-filled.svg";

export default function HistoryCard(item) {
	const {
		type,
		speciality,
		slotIdMis,
		sourceDate,
		doctor,
		shortname,
		specialities,
		rating,
		minPrice,
		experienceYearsAll,
		photoUrl,
		date,
		service,
		patient,
		feature,
		cabinet,
		receptionType,
		isPaid,
		status,
		telemedicine,
		hasReview,
		handlePayOnline,
		className = "",
		isOnline,
		additionalSlotsData,
	} = item;
	const translate = useTranslate();
	const { firstName, lastName } = patient;
	const dispatch = useDispatch();
	const [asked, setAsked] = useState(false);
	const eventInfo = useSelector(selectWillBeCanceled);
	const isMobile = useMediaQuery("(max-width: 709px)");
	const [patientIsOpen, patientSetIsOpen] = useState();
	const [getICV, { data: icv, isLoading }] = useExportCalendarEventMutation();
	const [getLink] = useGetTelemedLinkMutation();
	const [link, setLink] = useState(null);
	const [noteForPatient, setNoteForPatient] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		if (icv) {
			downloader(icv, "event.ics");
		}
	}, [icv]);

	const isCancelled = useMemo(() => status === "cancelled", [status]);

	const fetchLink = async () => {
		const { data, error } = await getLink(telemedicine.id);

		if (data) {
			setLink(data.joinUrl);
			setErrorMessage(data?.achtung);
		}

		if (error) {
			const clarifications = getErrorMessageFromError({
				fromClarifications: true,
				error,
			});
			setNoteForPatient(clarifications?.[0]?.message);
			setErrorMessage(error?.data?.data?.achtung);
			setLink(null);
		}
	};

	useEffect(() => {
		setNoteForPatient(null);
		setErrorMessage(null);
	}, [slotIdMis]);

	useEffect(() => {
		if (isOnline && telemedicine?.id && type !== "history") {
			fetchLink();
		} else {
			setLink(null);
		}
	}, [isOnline, telemedicine?.id, type, isPaid]);

	const onConfirmDelete = () => {
		if (!eventInfo) {
			dispatch(
				setAppointmentToCancel({
					slotIdMis:
						additionalSlotsData?.parentSlot?.scheduleIdMis || slotIdMis,
					medCardNumber: patient.medCardNumber,
				})
			);
			// setAsked(false);
			Metrics.gtmEvent("cancel_reserve", {
				doctorId: doctor.id,
				date: sourceDate,
				type: isOnline ? "online" : "offline",
			});
		}
	};

	const onCancelDelete = () => {
		if (!eventInfo) {
			dispatch(setAppointmentToCancel(null));
			setAsked(false);
		}
	};

	return (
		<div className={classNames(styles.card, className)}>
			<DoctorInfoCard
				doctor={doctor}
				speciality={speciality}
				shortname={shortname}
				specialities={specialities}
				rating={rating}
				minPrice={minPrice}
				experienceYearsAll={experienceYearsAll}
				photoUrl={photoUrl}
			/>
			<div
				className={styles.event_date}
				style={{ marginBottom: isOnline && type !== "history" ? 0 : 22 }}
			>
				{date}
			</div>

			{isOnline && type !== "history" && (
				<div className={styles.telemed}>
					{link ? (
						<a href={link} target="_blank" className={styles.link}>
							<Button className={styles.telemed_btn}>
								{translate("appointment.confirm.goToConsultation")}
							</Button>
						</a>
					) : (
						<Button className={styles.telemed_btn} disabled={!link}>
							{translate("appointment.confirm.goToConsultation")}
						</Button>
					)}

					{noteForPatient && (
						<div
							className={classNames(
								styles.telemed_description,
								styles.info_text
							)}
							dangerouslySetInnerHTML={{ __html: noteForPatient }}
						/>
					)}

					{errorMessage && (
						<div
							className={classNames(
								styles.telemed_description,
								styles.warn_text
							)}
							dangerouslySetInnerHTML={{ __html: errorMessage }}
						/>
					)}

					<div className={styles.telemed_description}>
						{translate("appointment.confirm.helpText")}
						&nbsp;
						<a
							className={styles.download_link}
							href="https://trueconf.ru/downloads/windows.html"
							target="_blank"
						>
							Windows
						</a>
						,&nbsp;
						<a
							className={styles.download_link}
							href="https://trueconf.ru/downloads/mac.html"
							target="_blank"
						>
							macOS
						</a>
						,&nbsp;
						<a
							className={styles.download_link}
							href="https://apps.apple.com/ru/app/trueconf/id536475636"
							target="_blank"
						>
							IOS
						</a>
						,&nbsp;
						<a
							className={styles.download_link}
							href="https://play.google.com/store/apps/details?id=com.trueconf.videochat"
							target="_blank"
						>
							Android
						</a>
					</div>
				</div>
			)}

			{type === "history" && isOnline && (
				<div className={styles.telemed}>
					<div className={styles.telemed_description}>
						{translate("appointment.confirm.recordsAvailableText")}
					</div>
				</div>
			)}

			{type !== "history" && !!additionalSlotsData && (
				<div className={cn(styles.event_params, styles.additionalSlots)}>
					<p className={styles.helper}>
						{translate("appointment.slots.info1")}
						{additionalSlotsData.additionalSlots.length + 1}
						{translate("appointment.slots.info2")}
						{[
							additionalSlotsData.parentSlot?.date || sourceDate || date,
							...additionalSlotsData.additionalSlots.map((sl) => sl.date),
						]
							.sort((a, b) => a.localeCompare(b))
							.map((s) => moment(s).format("HH:mm"))
							.join(", ")}
					</p>
					{/* <p className={styles.about}>{translate("appointment.slots.about")}</p> */}
				</div>
			)}

			<div className={styles.flex_container}>
				<div className={styles.event_info}>
					{service && (
						<div className={styles.event_params}>
							<div className={styles.params_title}>
								{translate("appointment.confirm.service", true)}
							</div>
							<div className={classNames(styles.params_value, styles.link)}>
								{service.name}
							</div>
						</div>
					)}
					<div className={styles.event_params}>
						<div className={styles.params_title}>
							{translate("appointment.confirm.patient", true)}
						</div>
						<div
							className={classNames(styles.params_value, styles.patient)}
							onClick={() => patientSetIsOpen(true)}
						>
							{firstName} {lastName}
							{!isMobile && (
								<Patient
									isOpen={patientIsOpen}
									setIsOpen={patientSetIsOpen}
									patient={patient}
								/>
							)}
						</div>
					</div>
					{patient && !!patient.medCardNumber && (
						<div className={styles.event_params}>
							<div className={styles.params_title}>
								{translate("profile.personalData.medCardNumber", true)}
							</div>
							<div className={styles.params_value}>{patient.medCardNumber}</div>
						</div>
					)}
					<div className={styles.event_params}>
						<div className={styles.params_title}>
							{translate("appointment.confirm.visit", true)}
						</div>
						<div className={styles.params_value}>
							{feature && receptionType === "examination" && (
								<>
									{translate("appointment.confirm.appointmentExamination")}{" "}
									{feature?.name?.toLowerCase()}
								</>
							)}
							{!feature && receptionType === "examination" && (
								<>
									{translate("appointment.confirm.appointmentExamination")}{" "}
									{translate("appointment.confirm.offline")}
								</>
							)}
							{receptionType === "diagnostic" && (
								<>{translate("appointment.confirm.diagnostic")}</>
							)}
						</div>
					</div>
					{cabinet && !isOnline && (
						<div className={styles.event_params}>
							<div className={styles.params_title}>
								{translate("appointment.confirm.cabinet", true)}
							</div>
							<div className={styles.params_value}>{cabinet}</div>
						</div>
					)}
					{!!minPrice && (
						<div className={styles.event_params}>
							<div className={styles.params_title}>
								{translate("appointment.minPrice", true)}
							</div>
							<div className={styles.params_value}>
								{minPrice.toLocaleString()}{" "}
								{translate("app.currencySign", true)}
							</div>
						</div>
					)}
				</div>
				<div className={styles.card_buttons}>
					{type === "history" ? (
						<>
							<div
								className={classNames(styles.event_action, styles.accent)}
								onClick={() => {
									if (doctor && speciality) {
										dispatch(
											setSelectedDoctor({
												id: doctor.id,
												specialityId: specialities[0].id,
											})
										);
									}
								}}
							>
								<svg
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M3.96515 1.09159C5.49447 0.198665 7.27686 -0.161683 9.03298 0.0670116C10.7891 0.295706 12.4197 1.10052 13.6693 2.35535C14.9189 3.61017 15.7168 5.24409 15.9379 7.00104C15.9913 7.42527 15.6907 7.81246 15.2665 7.86586C14.8423 7.91926 14.4551 7.61865 14.4017 7.19442C14.2233 5.77756 13.5799 4.45989 12.5721 3.44792C11.5644 2.43595 10.2493 1.78687 8.83303 1.60243C7.41672 1.41799 5.97924 1.70861 4.74587 2.42874C3.86017 2.94588 3.11567 3.66352 2.56807 4.5173H5.29032C5.7179 4.5173 6.06452 4.86392 6.06452 5.29149C6.06452 5.71907 5.7179 6.06568 5.29032 6.06568H0.774194C0.346618 6.06568 0 5.71907 0 5.29149V0.775893C0 0.348318 0.346618 0.0016997 0.774194 0.0016997C1.20177 0.0016997 1.54839 0.348318 1.54839 0.775893V3.26818C2.19236 2.39042 3.01295 1.64756 3.96515 1.09159ZM0.73295 8.13591C1.15714 8.0822 1.54455 8.38254 1.59826 8.80673C1.77758 10.2231 2.42155 11.54 3.42938 12.5513C4.43722 13.5627 5.75201 14.2113 7.16793 14.3957C8.58385 14.58 10.021 14.2898 11.2542 13.5703C12.1395 13.0538 12.8838 12.3371 13.4317 11.4844H10.7097C10.2821 11.4844 9.93548 11.1378 9.93548 10.7102C9.93548 10.2826 10.2821 9.93602 10.7097 9.93602H15.2258C15.6534 9.93602 16 10.2826 16 10.7102V15.2258C16 15.6534 15.6534 16 15.2258 16C14.7982 16 14.4516 15.6534 14.4516 15.2258V12.7327C13.8073 13.6099 12.9866 14.3522 12.0345 14.9077C10.5054 15.7998 8.72354 16.1597 6.96799 15.9311C5.21244 15.7025 3.58224 14.8983 2.33261 13.6443C1.08299 12.3903 0.284483 10.7574 0.0621313 9.00121C0.00842531 8.57702 0.308761 8.18961 0.73295 8.13591Z"
									/>
								</svg>
								<div className={styles.action_pass}>Перезаписаться</div>
							</div>
							{!hasReview && (
								<div
									className={classNames(styles.event_action, styles.accent)}
									onClick={() =>
										doctor &&
										dispatch(
											setRateInfo({
												slotIdMis,
												doctorId: doctor.id,
												date: sourceDate,
											})
										)
									}
								>
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="currentColor"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M8.00006 1.93563L9.88505 5.93493L14.1873 6.59205L11.0715 9.77167L11.7977 14.2106L8.00008 12.1205L4.20209 14.2106L4.92865 9.77167L1.81258 6.59205L6.11507 5.93493L8.00006 1.93563ZM11.8846 14.7413C11.8846 14.7414 11.8846 14.7412 11.8846 14.7413V14.7413ZM6.85302 0.737477C7.0595 0.295283 7.49905 0 8.00006 0C8.50108 0 8.94063 0.295283 9.1471 0.737478C9.14749 0.73831 9.14788 0.739142 9.14827 0.739974L10.9341 4.52882L14.9134 5.13663C14.9141 5.13673 14.9147 5.13683 14.9154 5.13693C15.4087 5.2109 15.7986 5.56517 15.9411 6.02827C16.0819 6.48176 15.9674 6.98715 15.6288 7.33328L12.7262 10.2954L13.4127 14.4913C13.4892 14.9592 13.3079 15.4436 12.9234 15.7401L12.9146 15.7469C12.5269 16.0371 11.9966 16.0896 11.5518 15.8426C11.551 15.8422 11.5502 15.8417 11.5495 15.8413L8.00005 13.8879L4.45099 15.841C4.24587 15.9568 4.03209 15.9985 3.84164 15.9985C3.58061 15.9985 3.31106 15.9184 3.08077 15.7435L3.0764 15.7401C2.69184 15.4436 2.51058 14.9592 2.58714 14.4913L3.27393 10.2954L0.371493 7.33377L0.370359 7.33261C0.0323907 6.9863 -0.0816273 6.48117 0.0587548 6.02819C0.201127 5.56641 0.589824 5.21112 1.08436 5.13694C1.08503 5.13683 1.08571 5.13673 1.08639 5.13663L5.06605 4.52882L6.85302 0.737477Z"
										/>
									</svg>
									<div className={styles.action_pass}>Оценить</div>
								</div>
							)}
							{hasReview && (
								<div
									className={classNames(
										styles.event_action,
										styles.accent,
										styles.hasReview
									)}
								>
									<StarFilledIcon className={styles.icon} />
									<div className={styles.action_pass}>Приём оценен</div>
								</div>
							)}
						</>
					) : (
						<>
							{!isMobile && !isPaid && (
								<Button
									className={styles.pay}
									onClick={() => handlePayOnline(item)}
								>
									{translate("appointment.confirm.payOnline", true)}
								</Button>
							)}
							<div
								className={classNames(styles.event_action, styles.accent)}
								onClick={() => {
									if (doctor) {
										const o = {
											id: doctor.id,
											rescheduleInfo: {
												oldDate:
													additionalSlotsData?.parentSlot?.date || sourceDate,
												oldCabinet: cabinet,
												oldSlotIdMis:
													additionalSlotsData?.parentSlot?.scheduleIdMis ||
													slotIdMis,
												oldIsPaid: isPaid,
											},
										};

										if (speciality) {
											o.specialityId = speciality.id;
										}

										if (service) {
											o.serviceId = service.id;
											o.serviceName = service.name;
										}

										if (patient) {
											o.patient = patient;
										}

										if (service) {
											o.service = service;
										}

										dispatch(setRescheduleDoctor(o));
									}
								}}
							>
								<svg
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M7.85714 4C8.33053 4 8.71429 4.36162 8.71429 4.80769V9.38462H10.1429C10.6162 9.38462 11 9.74623 11 10.1923C11 10.6384 10.6162 11 10.1429 11H8.14286C7.51167 11 7 10.5178 7 9.92308V4.80769C7 4.36162 7.38376 4 7.85714 4Z"
									/>
								</svg>
								<div className={styles.action_pass}>
									{translate("events.plan.passVisit", true)}
								</div>
							</div>
							<div
								className={classNames(styles.event_action, styles.error)}
								onClick={() => setAsked(true)}
							>
								<svg
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L10.5303 9.46967C10.8232 9.76256 10.8232 10.2374 10.5303 10.5303C10.2374 10.8232 9.76256 10.8232 9.46967 10.5303L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M10.5303 5.46967C10.8232 5.76256 10.8232 6.23744 10.5303 6.53033L6.53033 10.5303C6.23744 10.8232 5.76256 10.8232 5.46967 10.5303C5.17678 10.2374 5.17678 9.76256 5.46967 9.46967L9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967Z"
									/>
								</svg>
								<div className={styles.action_pass}>
									{translate("events.plan.cancelVisit", true)}
								</div>
							</div>
							{isPaid && (
								<div
									className={classNames(styles.event_action, styles.no_pointer)}
								>
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="currentColor"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M7.49992 5.54183C7.8451 5.54183 8.12492 5.82165 8.12492 6.16683V10.3335C8.12492 10.6787 7.8451 10.9585 7.49992 10.9585C7.15474 10.9585 6.87492 10.6787 6.87492 10.3335V6.16683C6.87492 5.82165 7.15474 5.54183 7.49992 5.54183Z"
										/>
										<path d="M8.33325 3.66683C8.33325 4.12707 7.96016 4.50016 7.49992 4.50016C7.03968 4.50016 6.66658 4.12707 6.66658 3.66683C6.66658 3.20659 7.03968 2.8335 7.49992 2.8335C7.96016 2.8335 8.33325 3.20659 8.33325 3.66683Z" />
									</svg>

									<div className={styles.action_pass}>
										{translate("events.plan.paid", true)}
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</div>
			<div
				className={classNames(styles.footer_buttons, {
					[styles.plan]: type === "history",
				})}
			>
				{isCancelled ? (
					<div className={styles.cloosed_text}>
						{translate("events.history.visitNotPlace")}
					</div>
				) : (
					<>
						{type === "plan" && isMobile && !isPaid && (
							<Button
								className={classNames(styles.pay, styles.mobile)}
								onClick={() => handlePayOnline(item)}
							>
								{translate("appointment.confirm.payOnline", true)}
							</Button>
						)}
						{type === "plan" && (
							<div
								className={classNames(styles.btn, {
									[styles.disable]: isLoading,
								})}
								onClick={() => {
									if (isLoading) {
										return;
									}
									let body = {
										slot: sourceDate,
									};
									if (speciality) {
										body.specialityId = speciality.id;
									}
									if (service) {
										body.serviceId = service.id;
									}
									getICV(body);
								}}
							>
								<span>
									{translate("app.addToCalendar")}
									{isLoading && (
										<div className={styles.btn_loader}>
											<Loader height={18} />
										</div>
									)}
								</span>
							</div>
						)}

						<div className={styles.btn} hidden>
							{translate("events.plan.savePass", true)}
						</div>
					</>
				)}
			</div>
			<ConfirmAction
				speciality={speciality && speciality.name}
				shortname={shortname}
				date={date}
				asked={asked}
				onConfirm={onConfirmDelete}
				onCancel={onCancelDelete}
				eventInfo={eventInfo}
			/>
			{isMobile && (
				<Patient
					isOpen={patientIsOpen}
					setIsOpen={patientSetIsOpen}
					patient={patient}
				/>
			)}
		</div>
	);
}

export const ConfirmAction = ({
	asked,
	speciality,
	onConfirm,
	onCancel,
	shortname,
	date,
}) => {
	const translate = useTranslate();
	const [isLoading, setLoading] = useState(false);
	const modalStyle = {
		content: {
			textAlign: "center",
			maxHeight: "550px",
		},
		overlay: {
			display: "flex",
			alignItems: "center",
		},
	};

	const handleConfirm = () => {
		if (!isLoading) {
			setLoading(true);
			onConfirm();
		}
	};

	return (
		<div onClick={(e) => e.stopPropagation()}>
			<ModalInfo
				isOpen={asked}
				onRequestClose={onCancel}
				modalClassName={styles.confirm_modal}
				iconType={"failure"}
				subtitle={"Вы уверены что хотите отменить визит?"}
				text={
					<>
						{shortname}
						<br />
						{speciality}
						<br />
						{date}
					</>
				}
				modalStyle={modalStyle}
				textClass={styles.text_class}
			>
				<CloseModalButton closeModal={onCancel} />
				<Button onClick={handleConfirm} className={styles.confirmButton}>
					{!!isLoading ? (
						<Loader height={18} white />
					) : (
						translate("app.confirm")
					)}
				</Button>
				<Button light onClick={onCancel}>
					{translate("app.cancel")}
				</Button>
			</ModalInfo>
		</div>
	);
};
