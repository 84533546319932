import styles from "./short_code.module.css";
import { useTranslate } from "../../../../localization/translate";
import Ricib from "../../../../components/ricib/ricib.v.0.1";
import { useEffect, useState, useRef } from "react";
import md5 from "md5";
import Button from "../../../../components/button/button";
import { useDispatch } from "react-redux";
import {
	setShortCode,
	setExpired,
	updateLastActivity,
	setAuthInfoToLS,
} from "../../services/login.slice";
import classNames from "classnames";
import { Metrics } from "../../../../utils/metrics";

const CODE_LENGTH = 4;
const CLEANUP_TIMEOUT = 2000;

export default function SetupSC({ wrap = true, onComplete = () => {} }) {
	const translate = useTranslate();
	const [code, setCode] = useState(null);
	const [error, setError] = useState(null);
	const [repeatCode, setRepeatCode] = useState(null);
	const dispatch = useDispatch();
	const timerRef = useRef(null);
	const [ricibKey, setRicibKey] = useState(null);

	// useEffect(() => {
	//     if (accessToken && !isGuest) {
	//         console.log('asd');
	//         refetch();
	//     }
	// }, [accessToken]);

	useEffect(() => {
		if (code && repeatCode) {
			if (repeatCode === code) {
				dispatch(updateLastActivity());
				dispatch(setExpired(false));
				dispatch(setShortCode(md5(code)));
				dispatch(setAuthInfoToLS());
				onComplete();
				Metrics.gtmEvent("set_code");
			} else {
				setError(translate("login.shortCodeLogin.invalid", true));
			}
		}
	}, [repeatCode]);

	useEffect(() => {
		if (!!error) {
			timerRef.current = setTimeout(() => {
				setError(null);
				setRicibKey(Math.random());
			}, CLEANUP_TIMEOUT);
		}
	}, [error]);

	const content = (
		<>
			<div className={styles.errorMsg}>{error}</div>
			{!code ? (
				<>
					<div className={`${styles.h3} h3`}>
						{translate("login.shortCodeLogin.create")}
					</div>
					<div className={`${styles.text} text`}>
						{translate("login.shortCodeLogin.whyFor")}
					</div>
					<Ricib
						onComplete={(code) => setCode(code)}
						length={CODE_LENGTH}
						advInputClass={styles.input}
						type="password"
					/>
				</>
			) : (
				<>
					<div className={`${styles.h3} h3`}>
						{translate("login.shortCodeLogin.repeat")}
					</div>

					<Ricib
						key={ricibKey}
						onComplete={(repeatedCode) => {
							setRepeatCode(repeatedCode);
						}}
						onChange={(repeatedCode) => {
							if (repeatedCode.length !== code.length) {
								setError(null);
								setRepeatCode(null);
							}
							clearTimeout(timerRef.current);
						}}
						length={CODE_LENGTH}
						type="password"
						advInputClass={styles.repeatInput}
						errorMsg={error ? " " : null}
					/>

					<Button
						onClick={() => {
							setCode(null);
							setError(null);
						}}
						className={styles.changeCode}
						light
					>
						{translate("login.shortCodeLogin.change")}
					</Button>
				</>
			)}
		</>
	);

	if (wrap) {
		return (
			<div className={classNames(styles.wrapper, styles.short_code)}>
				<div className={styles.form} style={code ? { marginTop: "54px" } : {}}>
					{content}
				</div>
			</div>
		);
	} else {
		return content;
	}
}
