import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import styles from "./plan.module.css";
import Button from "../../../../components/button/button";
import Empty from "./../../modules/empty-events";
import EventListItem from "../event-list-item/event-item";
import moment from "moment";
import avatar from "./../../../../icons/doctor-avatar.svg";
import { useTranslate } from "../../../../localization/translate";
import { useGetPlanQuery, useGetPollIdQuery } from "../../services/events.api";
import Loader from "../../../../components/loader";
import FilterContainer from "../filter-container/filter-container";
import { useMediaQuery } from "beautiful-react-hooks";
import HistoryCard from "../history/history-card";
import { moscowTimeZone } from "../../../../utils/dates/timezone-converter";
import EventsListLayout from "../events-list-layout/events-list-layout";
import grid from "../history/grid.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setCounters } from "../../../profile/services/profile.slice";
import { useGetProfileQuery } from "../../../profile/services/profile.api";
import { useGetQrQuery } from "../../../profile/services/profile.api";
import { setPaymentData } from "../../../payments/services/payments.slice";
import { PAYMENT_TYPES } from "../../../payments/helpers/constants";
import SelectServices from "../../../appointment/modules/select-services";
import QrDownload from "../../../profile/modules/QrCode/components/qr-download";
import QrCode from "../../../profile/modules/QrCode/components/qr-code";
import { MedicationCard } from "../polls/MedicationCard";
import css from "../polls/polls.module.css";
import { Stars } from "../../../appointment/modules/rate-visit/rate-visit";
import CloseModalButton from "../../../../components/modal-items/close";
import {
	selectNoRatingAppointmentVisible,
	setNoRatingAppointmentVisible,
} from "../../../login/services/login.slice";
import {
	selectRateInfo,
	setRateInfo,
} from "../../../appointment/services/appointment.slice";

export default function Plan({ isPaymentPage }) {
	const dispatch = useDispatch();
	const rateInfo = useSelector(selectRateInfo);
	// const paymentData = useSelector(select)
	const isNoRatingAppointmentVisible = useSelector(
		selectNoRatingAppointmentVisible
	);
	const isBigDesktop = useMediaQuery("(min-width: 1440px)");
	const isSmallMobile = useMediaQuery("(max-width: 400px)");

	let { selected: selectedId } = useParams();
	const selected = parseInt(selectedId);
	const prepareAppointments = (list) => {
		const withDates = [];

		// hide linked
		const linked = {};
		list.forEach(({ scheduleIdMis, additionalSlots }) => {
			const parentIdMis = list.find(
				(s) => !!s.additionalSlots?.includes(scheduleIdMis.toString())
			)?.scheduleIdMis;

			// if item has additional slots
			if (!!additionalSlots?.length) {
				linked[scheduleIdMis] = additionalSlots;
				return;
			}

			// if item is additional slot and has parent
			else if (!!parentIdMis && !!linked[parentIdMis]) {
				return;
			}

			// if single slot
			else if (!parentIdMis && !additionalSlots?.length) {
				linked[scheduleIdMis] = [];
				return;
			}
		});

		const toWorkWith = Object.entries(linked)
			.map((group) =>
				group
					.flat()
					.map((idMis) =>
						list.find((s) => s.scheduleIdMis.toString() === idMis)
					)
					.sort((a, b) => a.date.localeCompare(b.date))
					.slice(0, 1)
			)
			.flat()
			.filter(
				(obj, index, self) =>
					index === self.findIndex((t) => t.scheduleIdMis === obj.scheduleIdMis)
			);

		toWorkWith.forEach((item) => {
			const matches = withDates.find(
				(sub) => sub.date === moment(item.date).format("YYYY-MM-DD")
			);
			if (matches) {
				matches.items.push(item);
			} else {
				withDates.push({
					date: moment(item.date).format("YYYY-MM-DD"),
					items: [item],
				});
			}
		});

		return withDates;
	};

	const { data: profile } = useGetProfileQuery();

	const translate = useTranslate();
	const { data, isLoading } = useGetPlanQuery();
	const { data: qr } = useGetQrQuery();
	const { data: pollId, error, loading: pollLoading } = useGetPollIdQuery();

	const pollErrorCode = useMemo(
		() => error?.data?.messages?.[0]?.internalMessageCode,
		[error]
	);

	const selectedItem = data
		? data.data.appointments.find((item) => item.scheduleIdMis === selected)
		: null;
	const [planAppointments, setPlanAppointments] = useState();
	const filterCallback = (list) => {
		setPlanAppointments(prepareAppointments(list));
		navigate("/events");
	};
	const hasItems = useMemo(
		() => planAppointments && planAppointments.length > 0,
		[planAppointments]
	);
	const location = useLocation();
	const [servicesModalEvent, setServicesModalEvent] = useState(false);
	const medCardNumber =
		profile?.medCardNumber !== selectedItem?.patient?.medCardNumber
			? selectedItem?.patient?.medCardNumber
			: null;

	const handlePayOnline = async (item) => {
		if (!item) {
			return null;
		}
		let reserveId;

		const isExamination = item.receptionType === "examination";

		if (isExamination && (!item.service || !item.service.id)) {
			setServicesModalEvent(item);
		} else {
			dispatch(
				setPaymentData({
					PaymentModal: {
						type: PAYMENT_TYPES.service,
						purposeId: item.service?.id,
						scheduleIdMis: item.slotIdMis || selected,
						medCardNumber,
						doctorId: item.doctor.id,
						reserveId,
					},
					PaymentTarget: item.doctor,
				})
			);
		}
	};
	const navigate = useNavigate();

	useEffect(() => {
		if (data) {
			setPlanAppointments(prepareAppointments(data.data.appointments));
			// dispatch(setList(data.data.appointments));
			dispatch(
				setCounters({ appointmentsCount: data.data.appointments.length })
			);
		}
	}, [data]);

	useEffect(() => {
		const isAppointmentOpened = !!window.location.pathname.split("/")[2];

		if (!selected && isBigDesktop && hasItems && !isAppointmentOpened) {
			navigate(`/events/${planAppointments[0].items[0].scheduleIdMis}`);
			// setSelected(planAppointments[0].items[0].scheduleIdMis);
		}
	}, [planAppointments, selected, isBigDesktop]);

	const getAdditionalSlotsData = (item) => {
		const list = data.data.appointments;
		if (!item) return null;
		if (!list?.length) return null;

		const parentSlot = list.find((p) =>
			p?.additionalSlots?.includes(item.scheduleIdMis.toString())
		);

		if (!!parentSlot) {
			return {
				parentSlot,
				additionalSlots: list.filter((s) =>
					parentSlot.additionalSlots.includes(s.scheduleIdMis.toString())
				),
			};
		} else if (!parentSlot && !item.additionalSlots?.length) {
			return null;
		} else {
			return {
				additionalSlots: list.filter((s) =>
					item.additionalSlots.includes(s.scheduleIdMis.toString())
				),
			};
		}
	};

	const closeNoRatingAppointment = () => {
		dispatch(setNoRatingAppointmentVisible(false));
	};

	const onRateLastVisit = (stars) => {
		dispatch(
			setRateInfo({
				slotIdMis: data.data.noRatingAppointment.scheduleIdMis,
				doctorId: data.data.noRatingAppointment.doctor.id,
				date: data.data.noRatingAppointment.date,
				rating: stars,
			})
		);
	};

	const onNoRatingAppointmentClick = () => {
		navigate(`/events/history/${data.data.noRatingAppointment.scheduleIdMis}`);
	};

	useEffect(() => {
		if (
			!!location.search &&
			location.search.includes("shortCode") &&
			location.search.includes("accessToken") &&
			location.search.includes("refreshToken")
		) {
			navigate(`/events/${selectedId}${isPaymentPage ? "/payment" : ""}`);
		}
	}, [location, isPaymentPage]);

	useEffect(() => {
		if (isPaymentPage && selectedItem) {
			handlePayOnline({
				feature: selectedItem.feature,
				doctor: selectedItem.doctor,
				speciality: selectedItem.speciality,
				receptionType: selectedItem.type,
				service: selectedItem.service,
				slotIdMis: selectedItem.slotIdMis,
			});
		}
	}, [selectedItem]);

	const closeSelectServices = () => {
		setServicesModalEvent(null);

		if (isPaymentPage) {
			navigate(`/events/${selectedId}`);
		}
	};

	if (isLoading) return <Loader />;

	return (
		<div>
			<EventsListLayout
				medication={
					pollErrorCode !== 2203 &&
					!pollLoading && (
						<>
							<div className={css.title}>
								<h3>{translate("polls.yourMedication")}</h3>
							</div>

							<MedicationCard
								title={translate("polls.proactiveSurveillance.title")}
								description={translate(
									"polls.proactiveSurveillance.description"
								)}
								additionalTitle={
									pollId?.id
										? translate("polls.proactiveSurveillance.additionalTitle")
										: null
								}
								additionalDescription={
									pollId?.id
										? translate(
												"polls.proactiveSurveillance.additionalDescription"
										  )
										: null
								}
								onClick={() => navigate("/")}
							/>
						</>
					)
				}
				navigate={
					<>
						<div className={css.title}>
							<h3>{translate("polls.doctorsAppointments")}</h3>
							{isSmallMobile && hasItems ? (
								<div className={styles.filterButton}>
									<FilterContainer
										applyCallback={filterCallback}
										list={data.data.appointments}
										showDates
										defaultDates={
											hasItems && planAppointments.map((item) => item.date)
										}
									/>
								</div>
							) : null}
						</div>

						<div className={css.navigation}>
							<NavLink to="/events" end className={styles.link}>
								{({ isActive }) => (
									<Button
										defaultFontSize={false}
										className={styles.eventButton}
										secondary={
											!isActive &&
											location.pathname !== "/" &&
											!location.pathname.includes("/events/")
										}
									>
										{translate("events.plan.planTitle", true)}
									</Button>
								)}
							</NavLink>
							<NavLink to="/events/history">
								{({ isActive }) => (
									<Button
										className={styles.eventButton}
										secondary={!isActive}
										defaultFontSize={false}
									>
										{translate("events.history.historyTitle", true)}
									</Button>
								)}
							</NavLink>
							{!isSmallMobile && hasItems ? (
								<div className={styles.filterButton}>
									<FilterContainer
										applyCallback={filterCallback}
										list={data.data.appointments}
										showDates
										defaultDates={
											hasItems && planAppointments.map((item) => item.date)
										}
									/>
								</div>
							) : null}
						</div>
					</>
				}
				selected={
					selectedItem && (
						<HistoryCard
							type={"plan"}
							receptionType={selectedItem.type}
							handlePayOnline={handlePayOnline}
							isPaid={selectedItem.isPaid}
							status={selectedItem.status}
							shortname={
								selectedItem.doctor ? selectedItem.doctor.shortname : "_"
							}
							specialities={
								selectedItem.speciality ? selectedItem.speciality.name : "_"
							}
							rating={selectedItem.doctor ? selectedItem.doctor.rating : 0}
							minPrice={selectedItem.doctor ? selectedItem.doctor.minPrice : 0}
							experienceYearsAll={
								selectedItem.doctor ? selectedItem.doctor.experienceYearsAll : 0
							}
							photoUrl={
								selectedItem.doctor ? selectedItem.doctor.photoUrl : avatar
							}
							date={
								<>
									{moment(selectedItem.date).format("D MMMM в H:mm")}
									{moscowTimeZone() ? "" : translate("app.byMskTime", true)}
								</>
							}
							confirmCallback={() => {
								navigate(`/events`);
							}}
							sourceDate={selectedItem.date}
							service={selectedItem.service}
							patient={selectedItem.patient}
							feature={selectedItem.feature}
							cabinet={selectedItem.cabinet}
							doctor={selectedItem.doctor}
							speciality={selectedItem.speciality}
							slotIdMis={selectedItem.scheduleIdMis || selectedItem.slotIdMis}
							telemedicine={selectedItem.telemedicine}
							isOnline={selectedItem.isOnline}
							additionalSlotsData={getAdditionalSlotsData(selectedItem)}
						/>
					)
				}
				qr={
					<>
						{qr?.data?.qrCode &&
							data?.data?.appointments?.length > 0 &&
							planAppointments?.length > 0 && (
								<div className={styles.download_qr}>
									{!isBigDesktop ? (
										<QrCode
											uid={qr.data.qrCode}
											description={translate("profile.qr.description")}
											downloadable
											viewable
											options={{ width: 140 }}
										/>
									) : (
										<QrDownload uid={qr.data.qrCode} />
									)}
								</div>
							)}
					</>
				}
				noRatingAppointment={
					data?.data.noRatingAppointment &&
					isNoRatingAppointmentVisible && (
						<div className={styles.noRatingAppointment}>
							<p className={styles.intro}>
								{translate("events.noRatingAppointment.title")}
							</p>
							<p className={styles.main} onClick={onNoRatingAppointmentClick}>
								{data.data.noRatingAppointment.doctor.shortname}{" "}
								{moment(data.data.noRatingAppointment.date).format("DD MMMM")}
								{", "}
								{!!data.data.noRatingAppointment.service
									? data.data.noRatingAppointment.service.name
									: data.data.noRatingAppointment.speciality.name}
							</p>
							<Stars
								containerClass={styles.stars}
								starClass={styles.star}
								setCount={onRateLastVisit}
								count={rateInfo?.rating || 0}
							/>
							<CloseModalButton
								className={styles.close}
								closeModal={closeNoRatingAppointment}
							/>
						</div>
					)
				}
			>
				{hasItems &&
					(isBigDesktop || (!isBigDesktop && !selected)) &&
					planAppointments.map((items) => (
						<div key={"date_visit" + items.date} className={grid.list__section}>
							{moment().format("YYYY-MM-DD") === items.date ? (
								<>
									<div className={grid.list__sectionDate}>
										{translate("events.today")}
									</div>
									{/* <div className={styles.pass}>{translate("events.plan.savePass", true)}</div> */}
								</>
							) : (
								<div className={grid.list__sectionDate}>
									{moment(items.date).format("D MMMM YYYY, dddd")}
								</div>
							)}

							<div className={grid.list__sectionItems}>
								{items.items.map((item) => (
									<div
										onClick={(e) => {
											navigate(`/events/${item.scheduleIdMis}`);
											// setSelected(item.scheduleIdMis);
										}}
										key={"plan_visit" + item.scheduleIdMis}
										className={grid.list__sectionItem}
									>
										<EventListItem
											key={item.scheduleIdMis}
											receptionType={item.type}
											handlePayOnline={handlePayOnline}
											confirmCallback={() => {
												navigate(`/events`);
												// setSelected(null)
											}}
											type={"plan"}
											slotIdMis={item.scheduleIdMis}
											service={item.service}
											isPaid={item.isPaid}
											shortname={item.doctor ? item.doctor.shortname : "_"}
											specialities={
												item.speciality ? item.speciality.name : "_"
											}
											cabinet={item.cabinet}
											patient={item.patient}
											sourceDate={item.date}
											photoUrl={item.doctor ? item.doctor.photoUrl : avatar}
											isSelected={item.scheduleIdMis === selected}
											scheduleIdMis={item.scheduleIdMis}
											doctor={item.doctor ? item.doctor : null}
											isOnline={item.isOnline}
											speciality={item.speciality}
											additionalSlotsData={getAdditionalSlotsData(item)}
											rating={
												(rateInfo?.slotIdMis === item.scheduleIdMis &&
													rateInfo?.rating) ||
												0
											}
											feature={item.feature}
										/>
									</div>
								))}
							</div>
						</div>
					))}
			</EventsListLayout>

			<SelectServices
				isOpen={!!servicesModalEvent}
				doctorId={servicesModalEvent?.doctor?.id}
				slotIdMis={servicesModalEvent?.slotIdMis || selected}
				cancelExamination={closeSelectServices}
				onCloseSelf={closeSelectServices}
				medCardNumber={medCardNumber}
				specialityId={servicesModalEvent?.speciality?.id}
			/>
			{!hasItems && (
				<Empty
					title={translate("history.list.hasNoItems", true)}
					text={translate("events.plan.empty", true)}
					link={
						<NavLink to="/appointment" className={styles.link}>
							<Button className={styles.empty_button} defaultFontSize={false}>
								{translate(`appointment.doctorModal.makeAppointment`, true)}
							</Button>
						</NavLink>
					}
				/>
			)}
		</div>
	);
}
