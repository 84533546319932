import {useNavigate, useParams} from "react-router-dom";
import WaitingCoffee from "../components/credit-waiting/credit-waiting";
import {useCoffeeCreditMutation} from "../../../services/profile.api";
import {useEffect} from "react";
import CoffeeOverlay from "../components/overlay/overlay";

export default function CoffeeRequest() {
  const {id} = useParams();
  const [requestCreditCoffee, {data, error, isUninitialized}] = useCoffeeCreditMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isUninitialized) {//Запросим кредит и получим id транзакзакции
      requestCreditCoffee({sn: id});
    }
  }, [isUninitialized]);

  useEffect(() => {
    if (error) {
      navigate("/coffee/failure/result");
    }
  }, [error]);

  useEffect(() => {
    if (data?.transactionId) { // ЕСли получен id транзакзакции
      navigate(`/coffee/${data.transactionId}/status`);
    }
  }, [data])

  useEffect(() => {
    document.body.freeze();

    return () => document.body.unfreeze();
  }, []);

  return <CoffeeOverlay><WaitingCoffee/></CoffeeOverlay>
}