import { createContext } from "react";

export const InitIssue = null;

const IssueContext = createContext({
	issue: InitIssue,
	setIssue: () => {},
	addComment: () => {},
});

export const IssueProvider = IssueContext.Provider;

export default IssueContext;
