import s from "./index.module.css";
import { ReactComponent as Illustration } from "../../icons/slow-internet.svg";
import Button from "../../components/button/button";
import { useDispatch } from "react-redux";
import { setShowSlowInternetScreen } from "../../app/app.slice";

const SlowInternetScreen = () => {
	const dispatch = useDispatch();

	const reload = () => {
		dispatch(setShowSlowInternetScreen(false));
		window.location.reload();
	};

	return (
		<div className={s.wrapper}>
			<Illustration className={s.illustration} />
			<h1>Кажется, что-то пошло не так...</h1>
			<p>И мы уже это исправляем!</p>
			<p>Попробуйте обновить страницу чуть позже</p>
			<Button className={s.button} onClick={reload}>
				Обновить страницу
			</Button>
		</div>
	);
};

export default SlowInternetScreen;
