export const INPUT_TYPE = {
	CLOSE: "CLOSE",
	OPEN: "OPEN",
};

export const isValidOpenType = (value) => value?.trim()?.length > 0;

export const isOpen = ({ type }) => type === INPUT_TYPE.OPEN;

export const isClose = ({ type }) => type === INPUT_TYPE.CLOSE;

export const isAdditional = ({ isAdditionalQuestion, additionalAnswers }) =>
	isAdditionalQuestion || additionalAnswers?.length > 0;

export const isAdditionalQuestion = ({
	isAdditionalQuestion,
	additionalAnswers,
}) => isAdditionalQuestion && additionalAnswers?.length > 0;

export const isNextAvailable = ({ answer, additional, question }) =>
	answer &&
	(answer.isAdditionalQuestion ||
		!answer.additionalAnswers?.length ||
		additional) &&
	(!question?.isAdditional || additional) &&
	(isClose(answer) || isValidOpenType(answer.value)) &&
	(!additional || isClose(additional) || isValidOpenType(additional?.value));

export const isChildAnswer = (parentAnswer, additionalAnswer) =>
	!parentAnswer.isAdditional &&
	parentAnswer?.additionalAnswers?.length > 0 &&
	parentAnswer?.additionalAnswers?.find(({ id }) => additionalAnswer.id === id);
