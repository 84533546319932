import { useDispatch } from "react-redux";
import { setSubjectOpinion } from "../../services/appointment.slice";
import css from "./selectors.module.css";
import classNames from "classnames";
import arrow from "../../../../icons/arrow_down_24x24.svg";
import { useTranslate } from "../../../../localization/translate";

export default function ServiceSelector({
	userSelection,
	setUserSelection,
	data,
}) {
	const dispatch = useDispatch();
	const onClick = (id) => {
		setUserSelection({
			...userSelection,
			serviceId: id,
		});
		dispatch(setSubjectOpinion("DoctorSelector"));
	};
	const subject = data.find((item) => item.id === userSelection.subjectId);
	const translate = useTranslate();

	if (!subject) {
		dispatch(setSubjectOpinion(null));

		return null;
	}

	return (
		<div className={css.list}>
			{subject.services.map((service) => (
				<div
					key={`service-${subject.id}-${service.id}`}
					className={css.item}
					onClick={() => onClick(service.id)}
				>
					<span className={classNames("text", css.speciality_name)}>
						{service.name}
					</span>
					<span className={css.price}>
						{service.price.toLocaleString()}{" "}
						{translate("app.currencySign", true)}
					</span>
					<span className={css.arrow}>
						<img src={arrow} alt="" />
					</span>
				</div>
			))}
		</div>
	);
}
