import styles from "./Modal.module.css";
import CloseModalButton from "../../../../components/modal-items/close";
import { useTranslate } from "../../../../localization/translate";
import Loader from "../../../../components/loader";
import LoginSelector from "../login-selector";
import Ricib from "../../../../components/ricib/ricib.v.0.1";
import Timer from "../../../../components/timer/timer";
import { useEffect, useState } from "react";
import md5 from "md5";
import { useDispatch, useSelector } from "react-redux";
import {
	resetTimerByID,
	selectTimer,
} from "../../../../components/timer/timerSlice";
import {
	getErrorMessageFromError,
	getFromClarificationByTarget,
} from "../../../../app/error-handler.api";
import ModalInfo from "../../../../components/custom-modal/info";
import { setAuthInfo } from "../../services/login.slice";
import classNames from "classnames";
import CreateIssue from "../../../profile/modules/support/components/CreateIssue";
import { CustomModal } from "../../../../components/custom-modal";
import MedCardForm from "../med-card-form";
import { useMedCardConfirmMutation, useRecoveryCardMutation } from "../../services/login.api";
import { Metrics, loginGtmEvent } from "../../../../utils/metrics";
import { ReactComponent as CallIcon } from "../../../../icons/call.svg";
import {
	pushSystemNotification,
	resetAllNotifications,
} from "../../../../app/app.slice";
import MedCardRecoveryForm from "../med-card-recovery-form";
import Button from "../../../../components/button/button";
import { tr } from "date-fns/locale";

const CODE_LENGTH = 5;
const CALL_CODE_LENGTH = 4;
const START_TIMER = 120;

const modalStyle = {
	content: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	overlay: {
		display: "flex",
		alignItems: "center",
	},
};
const successModalStyle = {
	content: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "100px 72px 96px",
	},
	overlay: {
		display: "flex",
		alignItems: "center",
		zIndex: 190,
	},
};

export default function VerifyCodeEmail({
																		 limitIsOver,
																		 modalIsOpen,
																		 closeModal,
																		 num,
																		 repeatRequest,
																		 phone,
																		 onVerifyMedCard,
																		 type,
																		 onTypeChange,
																		 waitTimer,

																		 sendCode,
																		 smartCaptchaToken,
																		 data,
																		 error,
																		 isLoading,
																		 reset,
																		 isWidget,
																		 isNumberSuccess
																	 }) {
	const [ricibErrMsg, setRicibErrMsg] = useState(null);
	const [resetRicib, setResetRicib] = useState(false);
	const [isOpenCreateIssue, setIsOpenCreateIssue] = useState(false);
	const dispatch = useDispatch();
	const [uid, setUID] = useState(md5(Math.random()));
	const [verifyMedCardIsOpen, setVerifyMedCardIsOpen] = useState(false);
	const [cardRecovery, setCardRecovery] = useState(false);
	const [cardRecoverySuc, setCardRecoverySuc] = useState(false);
	const translate = useTranslate();
	const timers = useSelector(selectTimer);

	const closeModalCardNum = () => {
		setVerifyMedCardIsOpen(false);
		reset();
	};
	const closeModalCardRec = () => {
		setCardRecovery(false);
		reset();
		window.location.reload();
	};
	const closeModalCardRecSuc = () => {
		setCardRecoverySuc(false);
		// window.location.reload();
		isNumberSuccess();
	};
	const sucModalCardRec = () => {
		setVerifyMedCardIsOpen(false);
		setCardRecoverySuc(true);
	}
	const openIssueCardRecovery = () => {
		setVerifyMedCardIsOpen(false);
		setCardRecovery(true);
	};

	const goBack = () => {
		setVerifyMedCardIsOpen(false);
		setVerifyMedCardIsOpen(true);
	}

	useEffect(() => {
		return () => {
			dispatch(resetTimerByID(uid));
		};
	});

	useEffect(() => {
		const errorMsg = getErrorMessageFromError({
			error,
			fromClarifications: true,
		});
		if (errorMsg) {
			setRicibErrMsg(getFromClarificationByTarget(errorMsg, "code")?.message);
		}

		if (error) Metrics.gtmEvent("auth_fail", { auth_way: "phone" });
	}, [error]);

	useEffect(() => {
		/*
		 * Этот механизм необходим для того чтобы полностью "обнулить" поле <Ricib/>
		 * Компонент просто перерендироться полностью с нуля
		 * */
		if (resetRicib) {
			setRicibErrMsg(null);
			repeatRequest();
			setResetRicib(false);
		} else {
			dispatch(resetTimerByID(uid));
			setUID(md5(Math.random()));
		}
	}, [resetRicib, waitTimer]);

	useEffect(() => {
		if (data) {
			if (data.access) {
				//если успешная авторизация
				dispatch(
					setAuthInfo({
						accessToken: data.access,
						refreshToken: data.refresh,
						isGuest: false,
					})
				);
				loginGtmEvent(data.access, "phone");
			} else if (data.s) {
				//если токена нет и надо ввести номер карты
				closeModal();
				setVerifyMedCardIsOpen(true);
			}
		}
	}, [data]);

	useEffect(() => onVerifyMedCard(verifyMedCardIsOpen), [verifyMedCardIsOpen]);

	const changeTypeHandler = () => {
		if (timers[timers.length - 1]?.timer === 0) {
			dispatch(resetTimerByID(uid));
			setUID(md5(Math.random()));
			setRicibErrMsg(null);

			if (type === "sms") onTypeChange("call");
			if (type === "call") onTypeChange("sms");
		} else {
			dispatch(resetAllNotifications());

			dispatch(
				pushSystemNotification({
					type: "error",
					clientMessage: `Функция станет доступна через ${new Date(
						timers[timers.length - 1]?.timer * 1000
					)
						.toISOString()
						.slice(14, 19)}, подождите и попробуйте снова`,
				})
			);
		}
	};

	useEffect(() => {
		if (modalIsOpen) {
			setRicibErrMsg(null);
		}
	}, [modalIsOpen]);

	if (limitIsOver) {
		return (
			<ModalInfo
				isOpen={modalIsOpen}
				modalStyle={modalStyle}
				onRequestClose={closeModal}
				subtitle={translate(`login.${type}.limitIsOver`)}
				text={translate(`login.${type}.helper`, true)}
				textClass={styles.text_class}
				iconType="error"
				modalClassName={classNames(styles.react_modal, styles.fail)}
			>
				<LoginSelector onSelect={closeModal} />
				<ContactSupport
					style={{ marginTop: "32px" }}
					onClick={() => setIsOpenCreateIssue(true)}
				/>
				<CreateIssue
					isOpen={isOpenCreateIssue}
					handleClose={() => setIsOpenCreateIssue(false)}
					initPhone={phone}
				/>
			</ModalInfo>
		);
	}

	if (verifyMedCardIsOpen) {
		return (
			<MedCardRecoveryForm
				type="email"
				isOpen={verifyMedCardIsOpen}
				fetchHook={useRecoveryCardMutation}
				hash={data && data.s}
				onClose={closeModalCardRec}
				onSuccess={sucModalCardRec}
				goBack={goBack}
				isWidget={isWidget}
			/>
		);
	}

	// if (cardRecovery) {
	// 	return (
	// 		<MedCardRecoveryForm
	// 			type="phone"
	// 			isOpen={cardRecovery}
	// 			fetchHook={useRecoveryCardMutation}
	// 			hash={data && data.s}
	// 			onClose={closeModalCardRec}
	// 			onSuccess={sucModalCardRec}
	// 			goBack={goBack}
	// 			isWidget={isWidget}
	// 		/>
	// 	);
	// }

	if(cardRecoverySuc) {
		return (
			<ModalInfo
				isOpen={cardRecoverySuc}
				subtitle={translate("profile.support.recovery.successTitlePhone")}
				modalStyle={{
					...successModalStyle,
					overlay: {
						...successModalStyle.overlay,
						alignItems: isWidget ? "flex-start" : "center",
					},
				}}
				onRequestClose={closeModalCardRecSuc}
				textClass={styles.margin_top}
				iconType="success"
				bodyClass={styles.modal}
			>
				<div className={`${styles.margin_top} text`}>+7{phone[1]}•••••••{phone[9]}{phone[10]}</div>
				<div className={`${styles.margin_top} text`}>
					{translate("profile.support.recovery.successTextPhone")}
				</div>
				<div
					className={styles.timeout_modal_support}
					onClick={closeModalCardRecSuc}
				>
					{translate("login.sms.backToLogin", true)}
				</div>
			</ModalInfo>
		)
	}

	return (
		<CustomModal
			isOpen={modalIsOpen}
			onClose={closeModal}
			className={styles.rmodal}
			overlayClassName={"overlay-scrollbar-empty"}
			style={{
				overlay: {
					zIndex: 180,
					display: "flex",
					alignItems: isWidget ? "flex-start" : "center",
				},
			}}
		>
			<div className={styles.modal}>
				<CloseModalButton closeModal={closeModal} />
				<div className={`${styles.title} h3`}>
					{translate(`login.${type}.modalTitle`)}
				</div>
				{type === "sms" && (
					<>
						<div className={styles.whereToSend}>
							{translate("login.sms.whereToSend")}
						</div>
						<div className={styles.phoneNumber}>{num}</div>
						{!resetRicib && (
							<Ricib
								onChange={(code) => {
									if (code.length < CODE_LENGTH) {
										setRicibErrMsg(null);
									}
								}}
								length={CODE_LENGTH}
								errorMsg={ricibErrMsg}
								onComplete={(code) => {
									setRicibErrMsg(null);
									sendCode({
										phone: num,
										code,
										wayToGet: type,
										smartCaptchaToken,
									});
									Metrics.gtmEvent("send_auth_code");
								}}
								advInputClass={styles.input}
								errorClass={styles.error_class}
							/>
						)}
					</>
				)}
				{type === "call" && (
					<div className={styles.verifyCall}>
						<CallIcon className={styles.icon} />
						<span className={styles.phone}>
							{translate("login.call.phonePreview")}
						</span>
						{!resetRicib && (
							<Ricib
								onChange={(code) => {
									if (code.length < CODE_LENGTH) {
										setRicibErrMsg(null);
									}
								}}
								length={CALL_CODE_LENGTH}
								errorMsg={ricibErrMsg}
								onComplete={(code) => {
									setRicibErrMsg(null);
									sendCode({
										phone: num,
										code,
										wayToGet: type,
										smartCaptchaToken,
									});
									Metrics.gtmEvent("send_auth_code");
								}}
								advInputClass={styles.input}
								errorClass={styles.error_class}
							/>
						)}
					</div>
				)}
				{isLoading || data ? (
					<div className={styles.loader}>
						<Loader />
					</div>
				) : (
					<>
						<div className={styles.canRepeatAfter}>
							{modalIsOpen && (
								<Timer
									uid={uid}
									start_timer={waitTimer || START_TIMER}
									timeIsOverString={
										<span
											onClick={() => setResetRicib(true)}
											className={styles.timer}
										>
											{translate(`login.${type}.repeatRequest`, true)}
										</span>
									}
									timerClass={styles.timer}
									text={translate(`login.${type}.canRepeatAfter`, true)}
								/>
							)}
						</div>
						<DoNotReceiveCode type={type} />
						<AnotherWay type={type} onClick={changeTypeHandler} />
						<ContactSupport onClick={() => setIsOpenCreateIssue(true)} />
					</>
				)}
				<CreateIssue
					isOpen={isOpenCreateIssue}
					handleClose={() => setIsOpenCreateIssue(false)}
				/>
			</div>
		</CustomModal>
	);
}

const DoNotReceiveCode = ({ type }) => {
	const translate = useTranslate();

	return (
		<div className={styles.doNotReceiveCode}>
			{translate(`login.${type}.doNotReceiveCode`)}
		</div>
	);
};

const AnotherWay = ({ type, onClick }) => {
	const translate = useTranslate();

	return (
		<button className={styles.contactSupport} onClick={onClick}>
			{translate(`login.${type}.anotherWay`)}
		</button>
	);
};

const ContactSupport = ({ style = {}, onClick }) => {
	const translate = useTranslate();

	return (
		<button className={styles.contactSupport} style={style} onClick={onClick}>
			{translate("login.sms.contactSupport")}
		</button>
	);
};