import style from "./textarea.module.css";
import classNames from "classnames";
import { useState, useMemo } from "react";

export default function Textarea({
	value = "",
	placeholder,
	onChange,
	error,
	errorIsAbsolute = true,
	onFocus,
	onBlur,
	className,
	onKeyUp = () => {},
	specialLabelClassName = "",
}) {
	const [isTyping, setIsTyping] = useState(false);

	const isActive = useMemo(
		() => value.length > 0 || isTyping,
		[isTyping, value]
	);

	return (
		<div
			className={classNames(
				style.container,
				{ [style.errorContainer]: error },
				specialLabelClassName
			)}
		>
			{isActive && (
				<div className={classNames("special-label", style.special_label)}>
					{isActive ? placeholder : ""}
				</div>
			)}
			<textarea
				onFocus={(e) => {
					setIsTyping(true);
					onFocus && onFocus(e.target.value);
				}}
				onBlur={(e) => {
					setIsTyping(false);
					onBlur && onBlur(e.target.value);
				}}
				placeholder={!isTyping && placeholder ? placeholder : ""}
				className={classNames(
					style.textarea,
					{ [style.not_empty]: isActive },
					className
				)}
				onKeyUp={onKeyUp}
				onChange={(e) => onChange(e.target.value)}
				value={value}
			/>
			{error && (
				<div
					className={classNames(style.error, {
						[style.is_absolute]: errorIsAbsolute,
					})}
				>
					{error}
				</div>
			)}
		</div>
	);
}
