import { useDispatch, useSelector } from "react-redux";
import styles from "./actions.module.css";
import {
	selectWillBeCanceled,
	setAppointmentToCancel,
} from "../../services/events.slice";
import { useAppointmentCancelMutation } from "../../services/events.api";
import { useEffect, useState } from "react";
import ModalInfo from "../../../../components/custom-modal/info";
import { useTranslate } from "../../../../localization/translate";
import {
	getErrorMessageFromError,
	getFromClarificationByTarget,
} from "../../../../app/error-handler.api";
import Button from "../../../../components/button/button";
import { useNavigate } from "react-router-dom";

const modalStyle = {
	overlay: {
		display: "flex",
		alignItems: "center",
	},
};

export default function AppointmentCancel() {
	const eventInfo = useSelector(selectWillBeCanceled);
	const [cancelAppointment, { data: isOk, error, isLoading }] =
		useAppointmentCancelMutation();
	const [errorOpened, setErrorOpened] = useState(false);
	const dispatch = useDispatch();
	const translate = useTranslate();
	const navigate = useNavigate();

	const handleCancelAppointment = async () => {
		await cancelAppointment({
			slotIdMis: eventInfo.slotIdMis,
			medCardNumber: eventInfo.medCardNumber,
		});

		navigate("/events");
	};

	useEffect(() => {
		if (!!eventInfo && !isLoading) {
			handleCancelAppointment();
		}
	}, [eventInfo]);

	useEffect(() => {
		if (isOk) {
			dispatch(setAppointmentToCancel(null));
		}
		if (error) {
			const err = getErrorMessageFromError({
				error: error,
				fromClarifications: true,
			});
			if (err && err.length > 0) {
				const slotIdMisError = getFromClarificationByTarget(err, "slotIdMis");

				slotIdMisError && setErrorOpened(slotIdMisError.message);
			} else {
				setErrorOpened(translate("app.somethingWhenWrong"));
			}
			dispatch(setAppointmentToCancel(null));
		}
	}, [error, isOk]);

	if (errorOpened) {
		return (
			<ModalInfo
				iconType="failure"
				isOpen={errorOpened}
				onRequestClose={() => setErrorOpened(false)}
				subtitle={errorOpened}
				text={translate("app.tryLater", true)}
				modalStyle={modalStyle}
				textClass={styles.cancel_text}
			>
				<Button
					onClick={() => setErrorOpened(false)}
					className={styles.cancel_button}
				>
					{translate("app.well", true)}
				</Button>
			</ModalInfo>
		);
	}

	return null;
}
