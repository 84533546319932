import { useNavigate } from "react-router-dom";
import { useTranslate } from "../../../../../../localization/translate";
import ListItemRow from "../../../../../../components/list-item-row";
import styles from "./style.module.css";

const OthersDiscounts = ({ showDiscountCards, showGiftCertificates }) => {
	const nabigate = useNavigate();
	const translate = useTranslate();

	return (
		<>
			<div className={styles.title}>
				{translate("profile.discounts.othersDiscounts")}
			</div>

			<div className={styles.discounts_list}>
				{showDiscountCards && (
					<ListItemRow
						title={translate("profile.discounts.discountCard.title")}
						onClick={() => nabigate("/profile/discounts/discount-card")}
					/>
				)}
				{showGiftCertificates && (
					<ListItemRow
						title={translate("profile.discounts.giftCertificate.title")}
						onClick={() => nabigate("/profile/discounts/gift-certificate")}
					/>
				)}
			</div>
		</>
	);
};

export default OthersDiscounts;
