import { useMemo } from "react";
import moment from "moment";
import classNames from "classnames";
import Checkbox from "../../../../components/input/checkbox/Checkbox";
import {
	convertDate,
	moscowTimeZone,
} from "../../../../utils/dates/timezone-converter";
import styles from "./style.module.css";
import { useTranslate } from "../../../../localization/translate";

export default function NotificationCard({
	title,
	category,
	date,
	isRead = false,
	image = null,
	checked = false,
	selected = false,
	onCheck,
	onSelect,
}) {
	const isService = useMemo(() => category === "Сервисные", [category]);
	const translate = useTranslate();

	return (
		<div
			className={classNames(styles.card, { [styles.selected]: selected })}
			onClick={onSelect}
		>
			{!isService && (
				<div className={styles.checkbox}>
					<Checkbox checked={checked} onChange={onCheck} />
				</div>
			)}

			<div className={styles.content}>
				<div className={styles.header}>
					<div className={styles.header_start}>
						{!isRead && <div className={styles.readed} />}

						<div className={styles.date}>
							{moment(date).format("DD MMMM YYYY, HH:mm")}
							{moscowTimeZone() ? "" : translate("app.byMskTime")}
						</div>
					</div>

					<div className={styles.category}>{category}</div>
				</div>

				<div className={styles.title}>{title}</div>

				{image && <img alt={title} src={image} className={styles.image} />}
			</div>
		</div>
	);
}
