import {Route, NavLink, Routes, useLocation, useNavigate} from "react-router-dom";
import stylesProfile from "./profile.module.css";
import styles from "./profile.menu.module.css";
import { useTranslate } from "../../localization/translate";
import profileMenu from "./profile-menu.json";
import classNames from "classnames";
import * as Pages from "./modules";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setAuthInfo,
	clearLastActivity,
	selectBiometryAllowed,
	setBiometryAllowed,
} from "../login/services/login.slice";
import { useLogoutMutation } from "../login/services/login.api";
import { setShortCodeEditOpened } from "./services/profile.slice";
import ChangeShortCode from "./modules/chabge-short-code/change-short-code";
import AttachCard from "./modules/share-access/attach-card";
import Detalization from "./modules/payment-accounts/account-detalization";
import PersonalDataCreate from "./modules/personala-data/personal-data.create";
import {useGetProfileQuery, useGetSupportCountersQuery} from "./services/profile.api";
import Badge from "../../components/badge/badge";
import { Metrics } from "../../utils/metrics";
import { ReactComponent as FaceIDIcon } from "../../icons/biometry-face.svg";
import { ReactComponent as TouchIDIcon } from "../../icons/biometry-fingerprint.svg";
import { ReactComponent as BiometryIcon } from "../../icons/biometry.svg";
import { isAndroidApp, isBiometryAvailable, isIOSApp } from "../../utils";
import ReactSwitch from "react-switch";
import Footer from "../../components/footer";
import {useMediaQuery} from "beautiful-react-hooks";

export default function Profile() {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const [logout, { data }] = useLogoutMutation();

	const isMobileApp = isAndroidApp || isIOSApp;
	const isMobile = useMediaQuery("(max-width: 768px)");
	const [biometryType, setBiometryType] = useState();
	const isBiometryAllowed = useSelector(selectBiometryAllowed);
	const { data: profile} = useGetProfileQuery();
	const navigate = useNavigate();

	const menuSelector = (action) => {
		switch (action) {
			case "logout":
				logout();
				dispatch(setAuthInfo(null));
				Metrics.gtmEvent("logout");
				break;
			case "change-shortcode":
				dispatch(setShortCodeEditOpened(true));
				break;
			case "biometry":
				if (isBiometryAllowed) {
					dispatch(setBiometryAllowed(false));
					localStorage.setItem("isBiometryAllowed", false);
				} else {
					dispatch(setBiometryAllowed(true));
					localStorage.setItem("isBiometryAllowed", true);
				}
				break;
			case "coffee-machine":
				Metrics.gtmEvent("vending_view", {
					card: profile?.medCardNumber
				});
				if (isAndroidApp) {
					window.MedicinaAndroidWebView?.startScanCoffee?.();
				} else if (isIOSApp) {
					window.startScanCoffee?.();
				} else {
					navigate("/coffee")
				}
				break;
			default:
				return;
		}
	};

	const { data: supportCounters } = useGetSupportCountersQuery();

	const location = useLocation();
	let routes = [];
	for (let key in profileMenu) {
		routes = routes.concat(profileMenu[key].items);
	}
	const menuClassName = classNames(styles.menu, {
		[stylesProfile.hidden]: location.pathname !== "/profile",
	});
	const contentClassName = classNames(stylesProfile.content, {
		[stylesProfile.hidden]: location.pathname === "/profile",
	});

	useEffect(() => {
		//do logout after success logout/ query
		if (data) {
			dispatch(clearLastActivity());
			dispatch(setAuthInfo(null));
			/*
			 * TODO: remove this cruch
			 * */
			setTimeout(() => window.location.reload(), 500);
		}
	}, [data]);

	window.checkBiometryStatusCallBack = (type) => {
		setBiometryType(type);
	};

	useEffect(() => {
		if (isAndroidApp) {
			window.MedicinaAndroidWebView?.checkBiometryStatus?.();
		}

		if (isIOSApp) {
			window.checkBiometryStatus?.();
		}
	}, [isAndroidApp, isIOSApp]);

	return (
		<div className={stylesProfile.profile}>
			<div className={stylesProfile.header}>
				<div className={stylesProfile.title}>
					{translate("profile.pageTitle")}
				</div>
			</div>
			<div className={menuClassName}>
				{profileMenu.map((category) => (
					<div className={styles.category} key={`category-${category.id}`}>
						{category.name && (
							<div className={styles.name}>
								{translate(`profile.menu.categories.${category.name}`, true)}
							</div>
						)}
						{category.items
							.filter((item) => !item.onlyRoute)
							.filter((item) => !!item.url || !!item.onClickAction)
							.filter((item) => !item.onlyMobile || item.onlyMobile && (isMobileApp || isMobile))
							.map((item) => {
								if (item.onClickAction) {
									if (
										item.id === "biometry" &&
										(!isMobileApp ||
											!isBiometryAvailable ||
											!biometryType ||
											biometryType === "none")
									) {
										return null;
									}

									return (
										<span
											className={styles.item}
											onClick={() => menuSelector(item.id)}
											key={`nav-link-${item.id}`}
										>
											<MenuItem
												alias={item.id}
												title={item.id === "biometry" && biometryType}
											/>
										</span>
									);
								} else {
									return (
										<NavLink
											className={({ isActive }) =>
												classNames(styles.item, {
													[styles.active]: isActive,
												})
											}
											to={"/profile" + item.url}
											key={`nav-link-${item.id}`}
										>
											<MenuItem
												alias={item.id}
												counter={
													item.id === "support"
														? supportCounters?.repliesCount
														: item.id === "chats"
														? null // here counter
														: null
												}
											/>
										</NavLink>
									);
								}
							})}
					</div>
				))}
			</div>
			<div className={contentClassName}>
				<Routes>
					<Route path="/payment-accounts/:id" element={<Detalization />} />
					{routes
						.filter((route) =>
							route.component
							&& (!route.onlyMobile || route.onlyMobile && (isMobileApp || isMobile))
							&& (!route.medCardNumRequired || route.medCardNumRequired && profile?.medCardNumber)
						)
						.map((route) => {
							const Element = Pages[route.component];
							const props = {
								key: `route-${route.id}`,
								element: Pages[route.component] ? <Element /> : <div />,
							};

							if (route.isIndex) {
								props.index = true;
							} else {
								props.path = route.route_path;
							}

							if (route.id === 'coffee-machine') {
								return null
							}

							return <Route {...props} />;
						})}
				</Routes>
			</div>
			<Footer isInApp className={stylesProfile.footer} />
			<ChangeShortCode />
			<AttachCard />
			<PersonalDataCreate />
		</div>
	);
}

const COUNTERS_MAX_VIEW = 9;

const MenuItem = ({ alias, counter, title }) => {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const isBiometryAllowed = useSelector(selectBiometryAllowed);

	const hasCounters = useMemo(
		() => Number.isInteger(counter) && counter > 0,
		[counter]
	);

	const formatterCounter = useMemo(
		() =>
			counter >= COUNTERS_MAX_VIEW ? `${COUNTERS_MAX_VIEW}+` : `${counter}`,
		[counter]
	);

	const toggleBiometryAllowed = () => {
		if (isBiometryAllowed) {
			dispatch(setBiometryAllowed(false));
			localStorage.setItem("isBiometryAllowed", false);
		} else {
			dispatch(setBiometryAllowed(true));
			localStorage.setItem("isBiometryAllowed", true);
		}
	};

	return (
		<>
			<div className={styles.icon}>
				{alias === "biometry" ? (
					<>
						{title === "touchId" && <TouchIDIcon />}
						{title === "faceId" && <FaceIDIcon />}
						{title === "biometry" && <BiometryIcon />}
					</>
				) : (
					<img src={`/images/profile-menu/${alias}.svg`} />
				)}
			</div>

			<div className={styles.text}>
				{translate(`profile.menu.${title || alias}`, true)}
				{alias === "biometry" && (
					<ReactSwitch
						checked={isBiometryAllowed}
						onChange={toggleBiometryAllowed}
						uncheckedIcon={false}
						checkedIcon={false}
						height={22}
						width={36}
						offColor="#D4DDE7"
						onColor="#54A0FF"
					/>
				)}
			</div>

			{hasCounters && (
				<Badge count={formatterCounter} advClass={styles.badge} />
			)}
		</>
	);
};
