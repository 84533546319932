import { useContext, useMemo, useState } from "react";
import { useTranslate } from "../../../../localization/translate";
import ListItemRow from "../../../../components/list-item-row";
import Button from "../../../../components/button/button";
import AmountInput from "../../components/AmountInput";
import Loader from "../../../../components/loader";
import {
	useCreateInvoiceMutation,
	usePaymentByCardMutation,
} from "../../services/payments.api";
import Results from "../../components/Results";
import NavigationContext from "../../contexts/navigation-context";
import usePaymentContext from "../../hooks/usePaymentContext";
import { PaymentMethods, UrlPayment, ResultWrapper } from "../index";
import { PAYMENT_METHODS } from "../../helpers/constants";
import styles from "./style.module.css";

export default function DepositPaymnet({
	type,
	subtype,
	purposeId,
	scheduleIdMis = null,
}) {
	const translate = useTranslate();
	const { push } = useContext(NavigationContext);

	const [createInvoice, { isLoading: isCreatingInvoice, error }] =
		useCreateInvoiceMutation();
	const [paymentByCard, { isLoading: isPayingByCard }] =
		usePaymentByCardMutation();

	const {
		payment: { checkout, discount },
		isLoading,
		isSuccess,
		setPayment,
		error: checkoutError,
	} = usePaymentContext({
		type,
		purposeId,
	});

	const [errors, setErrors] = useState(null);

	const isRefill = useMemo(() => subtype === "refill", [subtype]);

	const handleSelectPaymentMethod = () => push(PaymentMethods);

	const handleChangeAmount = (amount) => {
		setPayment((prev) => ({ ...prev, checkout: { ...prev.checkout, amount } }));
		setErrors((prev) => {
			if (!prev) return null;
			else {
				const { amount, ...any } = prev;
				return { ...any };
			}
		});
	};

	const checkErrors = () => {
		let _errors = { ...errors };

		if (isRefill) {
			_errors = {
				...(!checkout?.amount && {
					amount: translate("app.forms.required", true),
				}),
				...(!checkout?.paymentMethod && {
					method: translate("app.forms.required", true),
				}),
			};
		}

		setErrors(_errors);
		return _errors;
	};

	const afterCreateInvoice = async (invoiceNumber) => {
		if (checkout.paymentMethod.type === PAYMENT_METHODS.url) {
			push(UrlPayment);
		} else if (checkout.paymentMethod.type === PAYMENT_METHODS.card) {
			const { data, error } = await paymentByCard({
				invoiceNumber,
				bankCardId: checkout.paymentMethod.operationId,
			});

			if (!error) {
				setPayment((prev) => ({
					...prev,
					results: {
						...prev.results,
						type: "success",
						orderNumber: data.invoiceNumber,
					},
				}));
				push(ResultWrapper);
			}
		}
	};

	const handlePayment = async () => {
		if (!Object.keys(checkErrors()).length) {
			const {
				data,
			} = await createInvoice({
				purposeId,
				paymentPurpose: type,
				paymentMethod: checkout.paymentMethod.type,
				amount: checkout.amount,
				...(scheduleIdMis && { scheduleIdMis }),
				...(discount?.type && { discountType: discount?.type }),
				...(discount?.id && { discountId: discount?.id }),
			});

			if (data && data.invoiceId) {
				setPayment((prev) => ({ ...prev, invoice: data.invoiceId }));
				afterCreateInvoice(data.invoiceId);
			}
		}
	};

	return (
		<>
			{isLoading && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}
			{isSuccess && !error && !checkoutError && checkout.isPaymentAvailable && (
				<div className={styles.deposit}>
					<div className={styles.content}>
						{isRefill ? (
							<div className={styles.content_block}>
								<div className={styles.title}>
									{translate(`payments.${type}.refill.paymentMethod`)}
								</div>

								<ListItemRow
									title={
										checkout.paymentMethod?.name ||
										translate(`payments.${type}.refill.selectMethod`)
									}
									onClick={handleSelectPaymentMethod}
									error={errors?.method}
								/>
							</div>
						) : (
							<>
								<div className={styles.content_block}>
									<div className={styles.title}>
										{translate(`payments.${type}.transfer.writeOffAccount`)}
									</div>

									<ListItemRow
										title="Лицевой счет •• 6789"
										description="Баланс: 1 098 657.88 ₽"
									/>
								</div>

								<div className={styles.content_block}>
									<div className={styles.title}>
										{translate(`payments.${type}.transfer.enrollmentAccount`)}
									</div>

									<ListItemRow
										title="Авансовый счет"
										description="Остаток: 17 459.45 ₽"
									/>
								</div>
							</>
						)}

						<div className={styles.content_block}>
							<div className={styles.title}>
								{translate(`payments.${type}.${subtype}.summ`)}
							</div>

							<AmountInput
								amount={checkout?.amount || ""}
								onChangeAmount={handleChangeAmount}
								amountError={errors?.amount}
							/>
						</div>
					</div>

					<div className={styles.actions}>
						<Button onClick={handlePayment}>
							{isCreatingInvoice || isPayingByCard ? (
								<Loader
									width={18}
									height={18}
									white
									containerStyle={{ display: "flex", justifyContent: "center" }}
								/>
							) : (
								<>{translate(`payments.${type}.${subtype}.paymentBtn`)}</>
							)}
						</Button>
					</div>
				</div>
			)}
			{(checkoutError || error) && <Results type="fail" />}
		</>
	);
}
