import { createApi } from "@reduxjs/toolkit/query/react";
import baseCongif from "../../../app/config.api";

export const notificationsApi = createApi({
	reducerPath: "notificationsApi",
	baseQuery: baseCongif,
	tagTypes: ["Notifications"],
	endpoints: (builder) => ({
		getNotifications: builder.query({
			query: (params) => ({
				url: "/notifications",
				params,
			}),
			transformResponse: (response) => response.data,
			providesTags: ["Notifications"],
		}),
		getNotificationById: builder.query({
			query: (id) => `/notifications/${id}`,
			transformResponse: (response) => response.data,
		}),
		readNotifications: builder.mutation({
			query: (ids) => ({
				method: "POST",
				body: { ids },
				url: "/notifications/read",
			}),
			transformResponse: (response) => response.data,
		}),
		readNotificationsInvalidate: builder.mutation({
			query: (ids) => ({
				method: "POST",
				body: { ids },
				url: "/notifications/read",
			}),
			transformResponse: (response) => response.data,
			invalidatesTags: ["Notifications"],
		}),
		deleteNotifications: builder.mutation({
			query: (ids) => ({
				method: "DELETE",
				body: { ids },
				url: "/notifications/delete",
			}),
			transformResponse: (response) => response.data,
			invalidatesTags: ["Notifications"],
		}),
	}),
});

export const {
	useGetNotificationsQuery,
	useGetNotificationByIdQuery,
	useDeleteNotificationsMutation,
	useReadNotificationsMutation,
	useReadNotificationsInvalidateMutation,
} = notificationsApi;
