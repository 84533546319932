import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../../../localization/translate";
import { getErrorMessageFromError } from "../../../../app/error-handler.api";
import { setAuthInfo } from "../../services/login.slice";
import { Metrics, loginGtmEvent } from "../../../../utils/metrics";
import { selectExaminationInfo } from "../../../appointment/services/appointment.slice";
import MedCardRecoveryFormView from "./mad-card-form.view";
import { da } from "date-fns/locale";
import moment from "moment";
import styles from "../med-card-form/styles.module.css";
import ModalInfo from "../../../../components/custom-modal/info";
import CloseModalButton from "../../../../components/modal-items/close";
import Input from "../../../../components/input";
import Loader from "../../../../components/loader";
import Button from "../../../../components/button/button";
import { CustomModal } from "../../../../components/custom-modal";

const modalStyle = {
	content: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		minHeight: "388px",
	},
	overlay: {
		display: "flex",
		alignItems: "center",
	},
};

const HelperBlock = ({ translate }) => (
	<div className={styles.whereToSend}>
		{translate("profile.support.recovery.errorText")}
		&nbsp;
		<a
			href={`tel:${translate("login.sms.supportContact", true)}`}
			className={styles.link}
		>
			{translate("login.sms.supportContact", true)}
		</a>
	</div>
);

// const getErrorMessage = (error) => {
// 	const confirmExpired = getErrorMessageFromError({
// 		error,
// 		code: 2045,
// 		fromClarifications: true,
// 	});
//
// 	const wrongSecret = getErrorMessageFromError({ error, code: 2044 });
//
// 	const incorrectNumber = getErrorMessageFromError({
// 		error,
// 		code: 2046,
// 		fromClarifications: true,
// 	});
//
// 	const notFound = getErrorMessageFromError({
// 		error,
// 		code: 2053,
// 		fromClarifications: true,
// 	});
//
// 	const incorrectCredentials = getErrorMessageFromError({
// 		error,
// 		code: 2011,
// 		fromClarifications: true,
// 	});
//
// 	const incorrectNumberForThisEmail = getErrorMessageFromError({
// 		error,
// 		code: 2164,
// 		fromClarifications: true,
// 	});
//
// 	return {
// 		confirmExpired,
// 		wrongSecret,
// 		incorrectCredentials,
// 		incorrectNumber: incorrectNumber || notFound || incorrectNumberForThisEmail,
// 	};
// };
//
// export const ErrorTypes = {
// 	EXPIRED: "EXPIRED",
// 	INCORRECT_CREDS: "INCORRECT_CREDS",
// };

export default function MedCardRecoveryForm({
																							type,
																							isOpen,
																							onClose,
																							onRecCard,
																							goBack,
																							onSuccess = () => ({}),
																							hash,
																							fetchHook,
																							isWidget,
																						}) {
	const translate = useTranslate();
	const dispatch = useDispatch();

	const [expired, setExpired] = useState(false);
	const [confirmError, setConfirmError] = useState(null);

	const examinationInfo = useSelector(selectExaminationInfo);
	const [recoveryCard, { data, isLoading, error, reset }] = fetchHook();

	useEffect(() => {
		if (data && data.access) {
			dispatch(
				setAuthInfo({
					accessToken: data.access,
					refreshToken: data.refresh,
					isGuest: false,
				})
			);

			// loginGtmEvent(data.access, type);

			handleCloseModal();
			reset();
		}
		if (data && !error) onSuccess();
	}, [data]);

	useEffect(() => {
		if (error) {
			// 	const {
			// 		confirmExpired,
			// 		wrongSecret,
			// 		incorrectNumber,
			// 		incorrectCredentials,
			// 	} = getErrorMessage(error);
			//
			// 	if (incorrectNumber)
			// 		setConfirmError(translate("login.sms.incorrectMedCardNumber", true));
			//
			// 	if (confirmExpired) setExpired(true);
			//
			// 	if (wrongSecret) handleCloseModal();
			//
			// 	if (incorrectCredentials)
			// 		handleCloseModal({
			// 			type: ErrorTypes.INCORRECT_CREDS,
			// 			...incorrectCredentials[0],
			// 		});
			//
			// 	Metrics.gtmEvent("auth_fail", { auth_way: type });
		}
	}, [error]);

	const handleConfirm = (name, secName, dateOfBirth) =>
		recoveryCard({
			firstName: name,
			lastName: secName,
			dateOfBirth: moment(dateOfBirth, "DD.MM.YYYY").format("YYYY-MM-DD"),
			s: hash
			// ...(examinationInfo?.date && {
			// 	date: examinationInfo.date.split(" ")[0],
			// }),
			// ...(examinationInfo?.doctor?.id && {
			// 	doctorId: examinationInfo.doctor.id,
			// }),
			// ...(examinationInfo?.slotIdMis && {
			// 	slotId: examinationInfo.slotIdMis,
			// }),
			// ...(examinationInfo?.serviceId && {
			// 	serviceId: examinationInfo.serviceId,
			// }),
			// ...(examinationInfo?.specialityId && {
			// 	specialityId: examinationInfo.specialityId,
			// }),
			// ...(examinationInfo?.additionalSlots?.length && {
			// 	additionalSlots: examinationInfo.additionalSlots.map((s) => s.idMis),
		});

	const handleCloseModal = (error) => {
		// const _expired = expired;
		// setConfirmError(null);
		// setExpired(false);
		// if (_expired) {
		// 	onClose({ type: ErrorTypes.EXPIRED });
		// 	return;
		// }
		onClose(error);
	};

	return (
		<>
			{error ?
				<>
					{/*<ModalInfo*/}
					{/*	iconType={"error"}*/}
					{/*	isOpen={isOpen}*/}
					{/*	modalStyle={{*/}
					{/*		...modalStyle,*/}
					{/*		overlay: {*/}
					{/*			...modalStyle.overlay,*/}
					{/*			alignItems: isWidget ? "flex-start" : "center",*/}
					{/*		},*/}
					{/*	}}*/}
					{/*	modalClassName={styles.timeout_modal}*/}
					{/*	subtitle={translate("profile.support.recovery.errorTitle")}*/}
					{/*	bodyClass={styles.timeout_modal_body}*/}
					{/*	textClass={styles.timeout_modal_text}*/}
					{/*	onRequestClose={handleCloseModal}*/}
					{/*	text={translate("login.sms.timeoutText")}*/}
					{/*>*/}
					{/*	<div*/}
					{/*		className={styles.timeout_modal_support}*/}
					{/*		onClick={handleCloseModal}*/}
					{/*	>*/}
					{/*		{translate("login.sms.backToLogin", true)}*/}
					{/*	</div>*/}
					{/*</ModalInfo>*/}
					<CustomModal
						isOpen={isOpen}
						onClose={onClose}
						className={styles.rmodal}
						style={{
							overlay: {
								zIndex: 180,
								display: "flex",
								alignItems: isWidget ? "flex-start" : "center",
							},
						}}
					>
						<div className={styles.modal}>
							<CloseModalButton closeModal={onClose} />
							<div className={styles.exclamError}></div>
							<div className={`${styles.title} h3`}>
								{translate("profile.support.recovery.errorTitle")}
							</div>

							<HelperBlock translate={translate} />

							<div
								className={styles.timeout_modal_support}
								onClick={handleCloseModal}
							>
								{translate("login.sms.backToLogin", true)}
							</div>
						</div>
					</CustomModal>
				</>
				:
				<MedCardRecoveryFormView
					isOpen={isOpen}
					isLoading={isLoading}
					onClose={handleCloseModal}
					onRecCard={onRecCard}
					goBack={goBack}
					confirmAction={handleConfirm}
					error={confirmError}
					isWidget={isWidget}
					type={type}
				/>
			}
		</>
	);
}
