import { useState, useEffect, useMemo, Children } from "react";
import classNames from "classnames";

import Actions from "./components/actions";
import Indicators from "./components/indicators";
import useExtraItems from "./hooks/useExtraItems";
import useSwipes from "./hooks/useSwipes";
import styles from "./style.module.css";

const Slider = ({
	children,
	show = 1,
	infiniteLoop = false,
	withIndicator = false,
}) => {
	const length = useMemo(() => Children.count(children), [children]);
	const showActions = useMemo(() => length > show, [length, show]);

	const { extraNextItems, extraPreviousItems } = useExtraItems(
		children,
		length,
		show
	);

	const isRepeating = useMemo(
		() => infiniteLoop && length > show,
		[length, infiniteLoop, show]
	);

	const [currentIndex, setCurrentIndex] = useState(isRepeating ? show : 0);
	const [isTransitionEnabled, setTransitionEnabled] = useState(true);
	const [animationInProgress, setAnimationInProgress] = useState(false);

	const contentStyles = useMemo(
		() => ({
			transform: `translateX(-${currentIndex * (100 / show)}%)`,
			transition: !isTransitionEnabled ? "none" : undefined,
		}),
		[isTransitionEnabled, currentIndex, show]
	);

	const isCanPrev = useMemo(
		() => isRepeating || currentIndex > 0,
		[currentIndex, isRepeating]
	);

	const isCanNext = useMemo(
		() => isRepeating || currentIndex < length - show,
		[currentIndex, length, isRepeating, show]
	);

	useEffect(() => {
		if (isRepeating) {
			if (currentIndex === show || currentIndex === length) {
				setTransitionEnabled(true);
			}
		}
	}, [currentIndex, isRepeating, show, length]);

	useEffect(() => {
		setCurrentIndex(isRepeating ? show : 0);
	}, [isRepeating, length, show]);

	const next = () => {
		if (!animationInProgress && isCanNext) {
			setCurrentIndex((prev) => prev + 1);
			setAnimationInProgress(true);
		}
	};

	const prev = () => {
		if (!animationInProgress && isCanPrev) {
			setCurrentIndex((prev) => prev - 1);
			setAnimationInProgress(true);
		}
	};

	const handlers = useSwipes(5, {
		left: prev,
		right: next,
	});

	const handleTransitionEnd = () => {
		if (isRepeating) {
			if (currentIndex === 0) {
				setTransitionEnabled(false);
				setCurrentIndex(length);
			} else if (currentIndex === length + show) {
				setTransitionEnabled(false);
				setCurrentIndex(show);
			}
		}
		setAnimationInProgress(false);
	};

	return (
		<div className={styles.slider}>
			<div className={styles.wrapper}>
				<div {...handlers} className={styles.content_wrapper}>
					<div
						className={classNames(styles.content, styles[`show-${show}`])}
						style={contentStyles}
						onTransitionEnd={handleTransitionEnd}
					>
						{isRepeating && extraPreviousItems}

						{children}

						{isRepeating && extraNextItems}
					</div>
				</div>

				{showActions && (
					<div className={styles.actions_wrapper}>
						<Actions
							disabled={{
								left: !isCanPrev,
								right: !isCanNext,
							}}
							onClick={{
								left: prev,
								right: next,
							}}
						/>
					</div>
				)}
			</div>

			{showActions && withIndicator && (
				<Indicators
					currentIndex={currentIndex}
					isRepeating={isRepeating}
					length={length}
					show={show}
				/>
			)}
		</div>
	);
};

export default Slider;
