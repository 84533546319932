import { Fragment } from "react";
import { useTranslate } from "../../../../localization/translate";
import ListItemRow from "../../../../components/list-item-row";
import styles from "./style.module.css";

export default function MethodsSelector({
	subtitle,
	blockTitle,
	collection,
	empty,
	onClickMethod,
}) {
	const translate = useTranslate();

	return (
		<div className={styles.methods_selector}>
			{subtitle && <div className={styles.subtitle}>{subtitle}</div>}

			{collection?.length > 0 && (
				<div className={styles.list}>
					<div className={styles.block_title}>{blockTitle}</div>

					{collection.map((item) => (
						<Fragment key={item.id}>
							<ListItemRow
								title={item.name}
								description={item.description}
								disabled={item.disabled}
								showDivider={!item.disabled}
								onClick={() => onClickMethod(item)}
							/>
							{item.disabled && item.disabledText && (
								<div className={styles.disabled_text}>{item.disabledText}</div>
							)}
						</Fragment>
					))}
				</div>
			)}

			{collection.length <= 0 && (
				<div className={styles.empty}>
					{empty ?? translate("payments.DiscountMethods.emptyDiscounts")}
				</div>
			)}
		</div>
	);
}
