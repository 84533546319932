import { useState } from "react";

// TODO: enhance and moved to global hooks for project
export default function useSwipes(delta = 10, handlers) {
	const [touchPosition, setTouchPosition] = useState(null);

	const onTouchStart = (e) => {
		const touchDown = e.touches[0].clientX;
		setTouchPosition(touchDown);
	};

	const onTouchMove = (e) => {
		const touchDown = touchPosition;

		if (touchDown === null) {
			return;
		}

		const currentTouch = e.touches[0].clientX;

		const diff = touchDown - currentTouch;

		if (diff > delta) {
			handlers?.right?.();
		}

		if (diff < -delta) {
			handlers?.left?.();
		}

		setTouchPosition(null);
	};

	return { onTouchStart, onTouchMove };
}
