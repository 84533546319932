import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { config, vapidKey } from "./configs";
import { setupPermission } from "./permissions";
import { setNotificationToken, isTokenSentedToServer } from "./token-manager";

const setupApp = (config) =>
	new Promise((resolve, reject) => {
		try {
			const app = initializeApp(config);
			const messaging = getMessaging(app);
			resolve({ app, messaging });
		} catch (error) {
			reject(error);
		}
	});

export const setupNotifications = () =>
	setupApp(config).then(({ messaging }) => {
		return setupPermission()
			.then(() => getToken(messaging, { vapidKey }))
			.then((token) => {
				if (isTokenSentedToServer(token)) {
					return null;
				} else {
					setNotificationToken(token);
					return token;
				}
			})
			.catch((error) => {
				setNotificationToken(null);
				console.warn("An error occurred while retrieving token.", error);
			});
	});
