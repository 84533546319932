import { useState, useEffect } from "react";
import startsWith from "lodash.startswith";
import { useTranslate } from "../../../../../../localization/translate";
import { CustomModal } from "../../../../../../components/custom-modal";
import Loader from "../../../../../../components/loader";
import Button from "../../../../../../components/button/button";
import Input from "../../../../../../components/input";
import Textarea from "../../../../../../components/input/Textarea/textarea";
import { emailValidation } from "../../../../../login/services/utils";
import {
	useGetProfileQuery,
	useCreateIssueMutation,
} from "../../../../services/profile.api";
import InfoBanner from "../../../../../../components/info-banner";
import Select from "../../../../../../components/select";
import styles from "./style.module.css";
import { Metrics } from "../../../../../../utils/metrics";

const ALLOWED_COUNTRIES = ["ru"];

export default function CreateIssue({
	isOpen,
	handleClose,
	initPhone,
	initEmail,
	skipFetchProfile,
}) {
	const translate = useTranslate();

	const ISSUES_TOPICS = [
		{
			value: "appointment",
			label: translate("profile.support.topicSelector.topics.appointment"),
		},
		{
			value: "registration",
			label: translate("profile.support.topicSelector.topics.registration"),
		},
		{
			value: "authorisation",
			label: translate("profile.support.topicSelector.topics.authorisation"),
		},
		{
			value: "protocol",
			label: translate("profile.support.topicSelector.topics.protocol"),
		},
		{
			value: "other",
			label: translate("profile.support.topicSelector.topics.other"),
		},
	];

	const { data: profile } = useGetProfileQuery(null, {
		skip: initPhone || skipFetchProfile,
	});

	const [createNewIssue, { data, isLoading }] = useCreateIssueMutation();

	useEffect(() => {
		setSecondname(profile?.lastName || "");
		setFirstname(profile?.firstName || "");
		setEmail(profile?.email || initEmail || "");
		setPhone(profile?.phone || initPhone || "");

		if (profile?.email || initEmail) setIsValidEmail(true);
	}, [profile, isOpen]);

	const [secondname, setSecondname] = useState("");
	const [firstname, setFirstname] = useState("");
	const [problem, setProblem] = useState("");
	const [email, setEmail] = useState("");
	const [topic, setTopic] = useState(null);

	const [phone, setPhone] = useState("");
	const [isValid, setIsValid] = useState(true);
	const [isTyping, setIsTyping] = useState(false);
	const [isValidEmail, setIsValidEmail] = useState(false);

	const [errors, setErrors] = useState({});
	const [isSuccess, setIsSuccess] = useState(false);

	const handleChangePhone = (phone, country) => {
		deleteError("phone");

		if (phone.length > 2) {
			setIsValid(
				startsWith(phone, country.dialCode) ||
					startsWith(country.dialCode, phone)
			);
			setIsTyping(
				startsWith(phone, country.dialCode) ||
					startsWith(country.dialCode, phone)
			);
		} else {
			setIsValid(true);
			setIsTyping(true);
		}

		setPhone(phone);
	};

	const checkErrors = () => {
		const generalError = translate("app.forms.required", true);
		const _errors = {};

		if (!secondname) {
			_errors.secondname = generalError;
		}

		if (!firstname) {
			_errors.firstname = generalError;
		}

		if (!problem) {
			_errors.problem = generalError;
		}

		if (!email) {
			_errors.email = generalError;
		}

		if (!phone) {
			_errors.phone = generalError;
		}

		if (!topic) {
			_errors.topic = generalError;
		}

		setErrors((prev) => ({ ...prev, ..._errors }));
		return Object.values(_errors).length <= 0 && isValid && isValidEmail;
	};

	const deleteError = (key) => {
		const _errors = errors;
		delete _errors[key];
		setErrors(_errors);
	};

	const submit = async () => {
		if (checkErrors()) {
			const { data } = await createNewIssue({
				name: firstname,
				surname: secondname,
				description: problem,
				topic: topic.value,
				email,
				phone,
			});

			if (data) {
				setIsSuccess(true);
				Metrics.gtmEvent("add_support_message");
			}
		}
	};

	const handleCloseModal = () => {
		if (isSuccess) {
			setIsSuccess(false);
			setErrors(false);
			setIsTyping(false);
			setIsValid(true);
			setPhone("");
			setEmail("");
			setProblem("");
			setFirstname("");
			setSecondname("");
			setTopic(null);
		}

		handleClose();
	};

	return (
		<CustomModal
			overlayClassName={"overlay-scrollbar-empty"}
			isOpen={isOpen}
			onClose={handleCloseModal}
			title={translate("profile.support.create.title")}
			style={{ overlay: { zIndex: 300 } }}
		>
			{!isSuccess && (
				<div className={styles.create_issue}>
					<div className={styles.header}>
						<div className={styles.subtitle}>
							{translate("profile.support.create.subtitle")}
						</div>

						<a
							href={`tel:${translate(
								"profile.support.create.supportTel",
								true
							)}`}
							className={styles.support_tel}
						>
							{translate("profile.support.create.supportTel")}
						</a>
					</div>

					<div className={styles.content}>
						<div className={styles.field_container}>
							<Input
								type="text"
								value={secondname}
								onChange={(val) => {
									setSecondname(val);
									deleteError("secondname");
								}}
								placeholder={translate(
									"profile.support.create.secondname",
									true
								)}
								errorMsg={errors.secondname}
							/>
						</div>

						<div className={styles.field_container}>
							<Input
								type="text"
								value={firstname}
								onChange={(val) => {
									setFirstname(val);
									deleteError("firstname");
								}}
								placeholder={translate(
									"profile.support.create.firstname",
									true
								)}
								errorMsg={errors.firstname}
							/>
						</div>

						<div className={styles.field_container} style={{ marginBottom: 0 }}>
							<Select
								value={topic}
								onChange={(value) => {
									deleteError("topic");
									setTopic(value);
								}}
								options={ISSUES_TOPICS}
								emptyLabel={translate("profile.support.create.emptyTopic")}
								error={errors.topic}
							/>
						</div>

						<div className={styles.field_container} style={{ marginBottom: 0 }}>
							<Textarea
								value={problem}
								onChange={(val) => {
									setProblem(val);
									deleteError("problem");
								}}
								placeholder={translate(
									"profile.support.create.describeProblem",
									true
								)}
								error={errors.problem}
								specialLabelClassName={styles.textarea}
							/>
						</div>

						<div className={styles.contact_methods}>
							<div className={styles.subtitle} style={{ marginBottom: 8 }}>
								{translate("profile.support.create.contactMethods")}
							</div>

							<div className={styles.field_container}>
								<Input
									disabled={!!profile}
									value={email}
									onChange={(val) => {
										setEmail(val);
										deleteError("email");
										setIsValidEmail(true);
									}}
									type="text"
									onBlur={(val) => {
										if (val.length > 0) {
											const validation = emailValidation(val);
											if (!validation.valid) {
												const errors = validation.messages.map((msg) =>
													translate(msg, true)
												);

												setIsValidEmail(false);
												setErrors((prev) => ({
													...prev,
													email: errors.join("; "),
												}));
											}
										}
									}}
									placeholder={translate("profile.support.create.email", true)}
									errorMsg={errors.email}
								/>
							</div>

							<div className={styles.field_container}>
								<Input
									disabled={!!profile}
									type="phone-mask"
									value={phone}
									onlyCountries={ALLOWED_COUNTRIES}
									onChange={handleChangePhone}
									onBlur={(e, country) => {
										if (
											country &&
											country.format &&
											country.format.length > e.target.value.length &&
											phone.length !== 0
										) {
											setIsValid(false);
										}
										setIsTyping(false);
									}}
									onFocus={() => {
										if (isValid) {
											setIsTyping(true);
										}
									}}
									placeholder={translate("profile.support.create.tel", true)}
									specialLabel={translate("profile.support.create.tel", true)}
									errorMessage={translate("login.wrongNumber", true)}
									isValid={isValid}
									isTyping={isTyping}
								/>

								{errors.phone && isValid && (
									<span className={styles.error}>{errors.phone}</span>
								)}
							</div>
						</div>

						{Object.values(errors).length > 0 && (
							<div className={styles.general_error}>
								{translate("profile.support.create.generalError", true)}
							</div>
						)}
					</div>

					<div className={styles.actions}>
						<Button onClick={submit}>
							{isLoading ? (
								<Loader
									width={18}
									height={18}
									white
									containerStyle={{ display: "flex", justifyContent: "center" }}
								/>
							) : (
								translate("profile.support.create.send")
							)}
						</Button>
					</div>
				</div>
			)}

			{isSuccess && (
				<div className={styles.success}>
					<InfoBanner
						title={
							<>
								{translate("profile.support.create.success")}
								<br />
								{data?.id}&nbsp;
								{translate("profile.support.create.successSended")}
							</>
						}
						theme="plain"
						layout="vertical"
						renderIcon={() => (
							<svg
								width="81"
								height="81"
								viewBox="0 0 81 81"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="40.5" cy="40.5" r="40" fill="#54A0FF" />
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M63.4309 25.7779C64.9343 27.3965 64.8408 29.9275 63.2222 31.4309L37.3822 55.4309C35.8372 56.8658 33.4436 56.8551 31.9116 55.4062L19.7516 43.9062C18.1465 42.3883 18.0759 39.8566 19.5938 38.2516C21.1117 36.6465 23.6434 36.5759 25.2485 38.0938L34.6847 47.0179L57.7779 25.5692C59.3965 24.0658 61.9275 24.1592 63.4309 25.7779Z"
									fill="white"
								/>
							</svg>
						)}
					>
						<div className={styles.success_description}>
							{translate("profile.support.create.successDescription")}
						</div>
					</InfoBanner>
				</div>
			)}
		</CustomModal>
	);
}
