import HasNoHistory from "../../../../components/has-no-items/has-no-items";
import { useTranslate } from "../../../../localization/translate";
import {
	useGetSnapshotMutation,
	useGetSnapshotsQuery,
} from "../../services/history.api";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { prepareList } from "../../utils";
import styles from "./snapshots.module.css";
import Header from "../receptions-history/header";
import Loader from "../../../../components/loader";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectPatient } from "../../../profile/services/profile.slice";
import { useGetProfileQuery } from "../../../profile/services/profile.api";
import HasNoItems from "../../../../components/has-no-items/has-no-items";
import { Metrics } from "../../../../utils/metrics";

export default function Snapshots() {
	const selectedPatient = useSelector(selectPatient);
	const { data: profile } = useGetProfileQuery();
	const currentPatientCard = selectedPatient
		? selectedPatient.medCardNumber
		: profile
		? profile.medCardNumber
		: null;
	const { data, isLoading, error } = useGetSnapshotsQuery(currentPatientCard, {
		skip: !currentPatientCard,
	});
	const [dateRange, setDateRange] = useState([null, null]);
	const [defaultDates, setDates] = useState();
	const [search, setSearch] = useState("");
	const translate = useTranslate();
	moment().locale("ru");
	const [list, setList] = useState(
		prepareList({ list: data, dateRange, search })
	);

	useEffect(() => {
		setList(prepareList({ list: data, dateRange, search }));
	}, [data, dateRange, search]);

	useEffect(() => {
		if (data && !defaultDates && Array.isArray(data)) {
			const temp = [];

			data.forEach((item) => {
				const value = moment(item.date).format("YYYY-MM-DD");
				if (!temp.includes(value)) {
					temp.push(value);
				}
			});
			setDates(temp);
		}
	}, [data]);

	useEffect(() => {
		if (data) Metrics.gtmEvent("view_history_snapshots");
	}, [data]);

	const headerProps = {
		dateRange,
		setDateRange,
		defaultDates,
		list,
		search,
		setSearch,
	};

	if ((Object.keys(list).length === 0 && !isLoading) || (error && !isLoading)) {
		error && console.error(error);

		return (
			<div className={styles.snapshots}>
				{(dateRange[0] || dateRange[1] || search.length > 0) && (
					<Header {...headerProps} />
				)}
				<HasNoItems title={translate("history.list.hasNoSnapshots", true)} />
			</div>
		);
	}

	if (isLoading) {
		return (
			<div className={styles.loader}>
				<Loader />
			</div>
		);
	}

	return (
		<div className={styles.snapshots}>
			<Header {...headerProps} />
			{Object.keys(list).map((date) => (
				<div className={styles.block} key={`visit-${date}`}>
					<div className={styles.title}>
						{moment(date).format("D MMMM YYYY, dddd")}
					</div>
					{list[date].map((item) => (
						<Item item={item} key={`snapshot-${date}-${item.idStudyMis}`} />
					))}
				</div>
			))}
		</div>
	);
}

const Item = ({ item, date }) => {
	const [
		getSnapshot,
		{ data: snapshot, isLoading: snapshotIsLoading, error: errorSnapshot },
	] = useGetSnapshotMutation();
	const translate = useTranslate();

	// useEffect(() => {
	// 	if (snapshot) {
	// 		if (isSafari) {
	// 			let a = document.createElement("a");
	// 			document.body.appendChild(a);
	// 			a.style.display = "none";
	// 			a.href = snapshot.data.viewUrl;
	// 			a.id = "temp";
	// 			a.target = "_blank";
	// 			a.click();
	// 			// console.log(a, snapshot.data.viewUrl);
	// 			document.body.removeChild(a);
	// 		} else {
	// 			window.open(snapshot.data.viewUrl);
	// 		}
	// 	}
	// 	if (errorSnapshot) {
	// 		console.log(snapshot, errorSnapshot);
	// 	}
	// }, [snapshot, errorSnapshot]);

	useEffect(() => {
		getSnapshot(item.idStudyMis);
	}, [item]);

	return (
		<div className={classNames(styles.note, "text")}>
			<span>{item.name}</span>
			{/*
                Если снимок не загружается в данный момент и не сафари
            */}
			{/* {!!snapshot && !snapshotIsLoading && !isSafari && (
				<span
					onClick={() => getSnapshot(item.idStudyMis)}
					className={styles.show_icon}
				>
					<svg
						width="20"
						height="19"
						viewBox="0 0 20 19"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.9796 1.01548C11.9796 0.546872 12.3595 0.166992 12.8281 0.166992H18.4847C18.9533 0.166992 19.3332 0.546872 19.3332 1.01548V6.67204C19.3332 7.14065 18.9533 7.52053 18.4847 7.52053C18.0161 7.52053 17.6362 7.14065 17.6362 6.67204V3.0639L8.71428 11.9858C8.38293 12.3172 7.8457 12.3172 7.51435 11.9858C7.18299 11.6545 7.18299 11.1172 7.51435 10.7859L16.4363 1.86396H12.8281C12.3595 1.86396 11.9796 1.48408 11.9796 1.01548ZM3.40051 4.69224C2.82777 4.69224 2.36347 5.15654 2.36347 5.72928V16.0997C2.36347 16.6724 2.82777 17.1367 3.40051 17.1367H13.7709C14.3436 17.1367 14.8079 16.6724 14.8079 16.0997V10.4431C14.8079 9.97448 15.1878 9.5946 15.6564 9.5946C16.125 9.5946 16.5049 9.97448 16.5049 10.4431V16.0997C16.5049 17.6096 15.2808 18.8337 13.7709 18.8337H3.40051C1.89056 18.8337 0.666504 17.6096 0.666504 16.0997V5.72928C0.666504 4.21933 1.89056 2.99527 3.40051 2.99527H9.05707C9.52568 2.99527 9.90556 3.37515 9.90556 3.84376C9.90556 4.31236 9.52568 4.69224 9.05707 4.69224H3.40051Z"
							fill="#54A0FF"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M12.8281 0.732649C12.6719 0.732649 12.5453 0.859275 12.5453 1.01548C12.5453 1.17168 12.6719 1.29831 12.8281 1.29831H16.4363C16.665 1.29831 16.8713 1.43612 16.9589 1.64749C17.0464 1.85887 16.998 2.10216 16.8362 2.26394L7.91432 11.1859C7.80387 11.2963 7.80387 11.4754 7.91432 11.5858C8.02478 11.6963 8.20385 11.6963 8.3143 11.5858L17.2362 2.66392C17.398 2.50214 17.6413 2.45375 17.8527 2.5413C18.064 2.62885 18.2019 2.83511 18.2019 3.0639V6.67204C18.2019 6.82824 18.3285 6.95487 18.4847 6.95487C18.6409 6.95487 18.7675 6.82824 18.7675 6.67204V1.01548C18.7675 0.859275 18.6409 0.732649 18.4847 0.732649H12.8281ZM3.40051 3.56093C2.20296 3.56093 1.23216 4.53173 1.23216 5.72928V16.0997C1.23216 17.2972 2.20296 18.268 3.40051 18.268H13.7709C14.9684 18.268 15.9392 17.2972 15.9392 16.0997V10.4431C15.9392 10.2869 15.8126 10.1603 15.6564 10.1603C15.5002 10.1603 15.3736 10.2869 15.3736 10.4431V16.0997C15.3736 16.9848 14.656 17.7023 13.7709 17.7023H3.40051C2.51537 17.7023 1.79782 16.9848 1.79782 16.0997V5.72928C1.79782 4.84414 2.51537 4.12659 3.40051 4.12659H9.05707C9.21328 4.12659 9.3399 3.99996 9.3399 3.84376C9.3399 3.68756 9.21328 3.56093 9.05707 3.56093H3.40051Z"
							fill="#54A0FF"
						/>
					</svg>
				</span>
			)} */}
			{/*
                Если снимок не получен и это сафари
            */}
			{/* {((!snapshot && !snapshotIsLoading) || errorSnapshot) && isSafari && (
				<span
					onClick={() => getSnapshot(item.idStudyMis)}
					className={styles.get_link}
				>
					{translate("history.navigation.getTheLink", true)}
				</span>
			)} */}
			{/*
            Если снимок получен и это сафари, то показать ссылку
            */}
			{snapshot && !errorSnapshot && (
				<a href={snapshot.data.viewUrl} target={"_blank"}>
					<svg
						width="20"
						height="19"
						viewBox="0 0 20 19"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.9796 1.01548C11.9796 0.546872 12.3595 0.166992 12.8281 0.166992H18.4847C18.9533 0.166992 19.3332 0.546872 19.3332 1.01548V6.67204C19.3332 7.14065 18.9533 7.52053 18.4847 7.52053C18.0161 7.52053 17.6362 7.14065 17.6362 6.67204V3.0639L8.71428 11.9858C8.38293 12.3172 7.8457 12.3172 7.51435 11.9858C7.18299 11.6545 7.18299 11.1172 7.51435 10.7859L16.4363 1.86396H12.8281C12.3595 1.86396 11.9796 1.48408 11.9796 1.01548ZM3.40051 4.69224C2.82777 4.69224 2.36347 5.15654 2.36347 5.72928V16.0997C2.36347 16.6724 2.82777 17.1367 3.40051 17.1367H13.7709C14.3436 17.1367 14.8079 16.6724 14.8079 16.0997V10.4431C14.8079 9.97448 15.1878 9.5946 15.6564 9.5946C16.125 9.5946 16.5049 9.97448 16.5049 10.4431V16.0997C16.5049 17.6096 15.2808 18.8337 13.7709 18.8337H3.40051C1.89056 18.8337 0.666504 17.6096 0.666504 16.0997V5.72928C0.666504 4.21933 1.89056 2.99527 3.40051 2.99527H9.05707C9.52568 2.99527 9.90556 3.37515 9.90556 3.84376C9.90556 4.31236 9.52568 4.69224 9.05707 4.69224H3.40051Z"
							fill="#54A0FF"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M12.8281 0.732649C12.6719 0.732649 12.5453 0.859275 12.5453 1.01548C12.5453 1.17168 12.6719 1.29831 12.8281 1.29831H16.4363C16.665 1.29831 16.8713 1.43612 16.9589 1.64749C17.0464 1.85887 16.998 2.10216 16.8362 2.26394L7.91432 11.1859C7.80387 11.2963 7.80387 11.4754 7.91432 11.5858C8.02478 11.6963 8.20385 11.6963 8.3143 11.5858L17.2362 2.66392C17.398 2.50214 17.6413 2.45375 17.8527 2.5413C18.064 2.62885 18.2019 2.83511 18.2019 3.0639V6.67204C18.2019 6.82824 18.3285 6.95487 18.4847 6.95487C18.6409 6.95487 18.7675 6.82824 18.7675 6.67204V1.01548C18.7675 0.859275 18.6409 0.732649 18.4847 0.732649H12.8281ZM3.40051 3.56093C2.20296 3.56093 1.23216 4.53173 1.23216 5.72928V16.0997C1.23216 17.2972 2.20296 18.268 3.40051 18.268H13.7709C14.9684 18.268 15.9392 17.2972 15.9392 16.0997V10.4431C15.9392 10.2869 15.8126 10.1603 15.6564 10.1603C15.5002 10.1603 15.3736 10.2869 15.3736 10.4431V16.0997C15.3736 16.9848 14.656 17.7023 13.7709 17.7023H3.40051C2.51537 17.7023 1.79782 16.9848 1.79782 16.0997V5.72928C1.79782 4.84414 2.51537 4.12659 3.40051 4.12659H9.05707C9.21328 4.12659 9.3399 3.99996 9.3399 3.84376C9.3399 3.68756 9.21328 3.56093 9.05707 3.56093H3.40051Z"
							fill="#54A0FF"
						/>
					</svg>
				</a>
			)}
			{(!snapshot || snapshotIsLoading) && (
				<span>
					<Loader
						height={25}
						width={25}
						containerStyle={{ marginTop: "-2px" }}
					/>
				</span>
			)}
		</div>
	);
};
