import moment from "moment";
import s from "./calendar.module.css";
import cn from "classnames";
import { forwardRef } from "react";

const getDayCssClass = (day, activeDate, timeSlots = []) => {
	const todaySlots = timeSlots.filter((ts) =>
		moment(day).isSame(moment(ts.time), "d")
	);
	const hasSlots = todaySlots.some((ts) => ts.isAvailable);
	const isToday = moment(day).isSame(moment(), "d");
	const isActive = moment(day).isSame(activeDate, "d");
	const isDisabled =
		(moment(day).isBefore(moment(), "d") && !isToday) ||
		moment(day).isAfter(moment().add(60, "days"));

	return cn(s.day, {
		[s.today]: isToday,
		[s.active]: isActive,
		[s.innactive_all]: isDisabled,
		[s.hasSlots]: hasSlots,
		[s.hasNoSlots]: !hasSlots,
	});
};

const TimeLine = forwardRef(
	({ weeks = [], activeDate, timeSlots, onDayClick, onScroll }, ref) => {
		return (
			<div ref={ref} className={s.timeline} onScroll={onScroll}>
				{weeks.map((week) => (
					<div key={week} className={s.week}>
						{week.map((day) => {
							const isToday = moment(day).isSame(moment(), "d");
							const isDisabled =
								(moment(day).isBefore(moment(), "d") && !isToday) ||
								moment(day).isAfter(moment().add(60, "days"));
							const className = getDayCssClass(day, activeDate, timeSlots);

							return (
								<div
									className={className}
									key={day}
									onClick={(e) => !isDisabled && onDayClick(day)}
								>
									<span>{moment(day).format("ddd")}</span>
									<span>{moment(day).format("D")}</span>
								</div>
							);
						})}
					</div>
				))}
			</div>
		);
	}
);

export default TimeLine;
