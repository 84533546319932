import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseConfig from "../../../app/config.api";

export const agreementsApi = createApi({
	reducerPath: "agreementsApi",
	baseQuery: baseConfig,
	tagTypes: ["Agreements"],
	endpoints: (builder) => ({
		getAgreements: builder.query({
			query: (code) => `/info_page/agreement?code=${code}`,
			providesTags: ["Agreements"],
			transformResponse: (response) => response.data,
		}),
	}),
});

export const { useGetAgreementsQuery } = agreementsApi;
