import { useEffect } from "react";
import { useCallback, useMemo, useRef } from "react";
import Loader from "../loader";

export default function ScrollTrigger({
	showLoader = false,
	hasMore,
	children,
	onScrolledBottom = () => {},
}) {
	const last = useRef(null);

	const handleTrigger = useCallback(
		([entrie]) => entrie.isIntersecting && onScrolledBottom(),
		[onScrolledBottom]
	);

	const observer = useMemo(
		() =>
			new IntersectionObserver(handleTrigger, {
				rootMargin: "20px",
				threshold: 0,
			}),
		[handleTrigger, onScrolledBottom]
	);

	useEffect(() => {
		observer.observe(last.current);
		return () => observer.disconnect();
	}, [hasMore]);

	return (
		<>
			{children}

			<div ref={last} style={{ width: 0, height: 0 }} />

			{showLoader && hasMore && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}
		</>
	);
}
