import classNames from "classnames";
import { useTranslate } from "../../../../localization/translate";
import { useGetDiscountDataQuery } from "../../services/profile.api";
import { useRouterDynamicSegment } from "./hooks/useRouterDynamicSegment";
import Button from "../../../../components/button/button";
import InfoBannerWrapper from "./components/InfoBlockWrapper";
import styles from "./discount-pass.module.css";
import { useEffect } from "react";
import { Metrics } from "../../../../utils/metrics";

const DiscountPass = () => {
	const translate = useTranslate();
	const id = useRouterDynamicSegment(0);

	const { data: discounts, isLoading, error } = useGetDiscountDataQuery(null);

	useEffect(() => {
		Metrics.gtmEvent("view_discount_item", {
			discount_type: "discount-pass",
		});
	}, []);

	return (
		<div className={styles.discount_pass}>
			<div className={classNames("h3", styles.title)}>
				{translate("profile.discounts.discountPass.title")}
			</div>

			<div className={styles.content}>
				<InfoBannerWrapper
					title={translate("profile.discounts.discountPass.whatIt")}
					list={translate("profile.discounts.discountPass.infoList")}
					learMoreLink="https://www.medicina.ru/patsientam/diskontnye-programmy/"
				/>
			</div>

			<Button className={styles.buy_pass_btn}>
				{translate("profile.discounts.discountPass.buyPass")}
			</Button>
		</div>
	);
};

export default DiscountPass;
