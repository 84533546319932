import { useMemo } from "react";

export const declension = (number, titles) => {
	const cases = [2, 0, 1, 1, 1, 2];
	const absoluteValueOfNumber = Math.abs(number);

	return titles[
		absoluteValueOfNumber % 100 > 4 && absoluteValueOfNumber % 100 < 20
			? 2
			: cases[absoluteValueOfNumber % 10 < 5 ? absoluteValueOfNumber % 10 : 5]
	];
};

export const useDeclension = (number, titles) =>
	useMemo(() => declension(number, titles), [number, titles]);
