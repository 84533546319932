import PhoneInput from "react-phone-input-2";
import styles from "./PhoneInput.module.css";
import classNames from "classnames";
import startsWith from "lodash.startswith";

export default function (props) {
	const {
		placeholder,
		country = "ru",
		value,
		disabled,
		onChange,
		onKeyDown = () => {},
		onKeyUp = () => {},
		onBlur = () => {},
		onFocus = () => {},
		containerClass,
		errorMessage = null,
		isValid,
		specialLabel = "",
		onlyCountries = null,
		isTyping = false,
	} = props;

	const containerClassnames = classNames(styles.container, containerClass, {
		"invalid-value": !isValid && value.length > 0,
		"typing-value": isTyping,
	});

	const advProps = {};
	if (onlyCountries && Array.isArray(onlyCountries)) {
		advProps.onlyCountries = onlyCountries;
	}
	const inputClass = classNames(styles.input, {
		[styles.initial]: value.length === 0 && !isTyping,
	});

	return (
		<>
			<PhoneInput
				isValid={(inputNumber, country, countries) => {
					return countries.some((country) => {
						return (
							startsWith(inputNumber, country.dialCode) ||
							startsWith(country.dialCode, inputNumber)
						);
					});
				}}
				placeholder={placeholder}
				country={country}
				value={value}
				specialLabel={value.length !== 0 || isTyping ? specialLabel : ""}
				onBlur={(e, country) => {
					onBlur(e, country);
				}}
				onFocus={(e) => {
					onFocus(e);
				}}
				countryCodeEditable={false}
				onKeyDown={onKeyDown}
				onKeyUp={onKeyUp}
				containerClass={containerClassnames}
				buttonClass={styles.button}
				disabled={disabled}
				prefix={"+"}
				onChange={(phone, country, e) => {
					onChange(phone, country, e);
				}}
				inputClass={inputClass}
				{...advProps}
			/>
			{errorMessage && !isValid && (
				<div className={styles.errorMessage}>{errorMessage}</div>
			)}
		</>
	);
}
