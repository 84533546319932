import Input from "../../../../components/input";
import { useTranslate } from "../../../../localization/translate";

export default function AmountInput({ amount, onChangeAmount, amountError }) {
	const translate = useTranslate();

	const handleChange = (val) => {
		if (isNaN(val)) {
			if (val.length > 0) {
				onChangeAmount(parseInt(val.replace(/\s/g, "")));
			} else {
				onChangeAmount("");
			}
		} else {
			onChangeAmount(parseInt(val));
		}
	};

	return (
		<Input
			type={"text"}
			value={isNaN(amount) ? "" : amount.toLocaleString()}
			onChange={handleChange}
			containerClass={amountError && "invalid-value"}
			errorMsg={amountError}
			placeholder={translate("app.currencySign", true)}
			placeholderWhenFocused={" "}
		/>
	);
}
