import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	invoice: null,
};

export const receivedSlice = createSlice({
	name: "received",
	initialState,
	reducers: {
		setInvoice: (state, action) => {
			state.invoice = action.payload.invoice;
		},
		resetInvoice: (state) => {
			state.invoice = null;
		},
	},
});

export const { setInvoice, resetInvoice } = receivedSlice.actions;

export const getInvoice = (state) => state.received.invoice;
