import { useEffect } from "react";
import { useLocalStorage } from "beautiful-react-hooks";

export const useSearchHistory = (key, searchResults) => {
	const [history, setHistory] = useLocalStorage(key, []);

	useEffect(() => {
		if (searchResults) {
			const { programs = [], services = [] } = searchResults;
			const res = [];

			history.concat(programs, services).forEach((item) => {
				if (
					res.find(
						(entity) => entity.id === item.id || entity.name === item.name
					)
				) {
					return null;
				}

				res.push(item);
			});

			setHistory(res.slice(-10));
		}
	}, [searchResults]);

	return [history, setHistory];
};
