import { useContext, useMemo } from "react";
import { useTranslate } from "../../../../localization/translate";
import Loader from "../../../../components/loader";
import MethodsSelector from "../../components/MethodsSelector";
import Results from "../../components/Results";
import PaymentContext from "../../contexts/payment-context";
import NavigationContext from "../../contexts/navigation-context";
import { PaymentAccounts } from "../index";
import { useGetPaymentDataQuery } from "../../services/payments.api";
import { PaymentMethodsFactory } from "../../helpers/utils";

const TypesWithAmount = [];
const METHODS = [];

import("../../helpers/constants").then(({ PAYMENT_TYPES, PAYMENT_METHODS }) => {
	TypesWithAmount.push(
		PAYMENT_TYPES.opinion,
		PAYMENT_TYPES.program,
		PAYMENT_TYPES.service,
		PAYMENT_TYPES.invoice
	);
	METHODS.push(...Object.values(PAYMENT_METHODS));
});

export default function PaymentMethods({
	type,
	purposeId,
	medCardNumber = null,
}) {
	const translate = useTranslate();
	const {
		payment: { checkout },
		setPayment,
	} = useContext(PaymentContext);
	const { push, erase } = useContext(NavigationContext);

	const { data, isLoading, error } = useGetPaymentDataQuery({
		paymentPurpose: type,
		medCardNumber,
		purposeId,
	});

	const mapPaymentMethods = useMemo(
		() => PaymentMethodsFactory(translate),
		[translate]
	);

	const methods = useMemo(() => mapPaymentMethods(data), [data]);

	const showSubtitle = useMemo(() => TypesWithAmount.includes(type), [type]);

	const handleSelectMethod = ({ type, id, name }) => {
		if (METHODS.includes(type)) {
			setPayment((prev) => ({
				...prev,
				checkout: {
					...prev.checkout,
					paymentMethod: {
						type,
						name,
						operationId: Number.isInteger(id) ? id : null,
					},
				},
			}));

			erase();
		} else {
			push(PaymentAccounts);
		}
	};

	return (
		<>
			{isLoading && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}

			{data && !error && (
				<MethodsSelector
					subtitle={
						showSubtitle && (
							<>
								{translate(`payments.PaymentMethods.${type}.subtitle`, true)}
								&nbsp;
								{checkout.amount?.toLocaleString()}&nbsp;
								{translate("app.currencySign", true)}
							</>
						)
					}
					blockTitle={translate("payments.PaymentMethods.availableMethods")}
					collection={methods}
					onClickMethod={handleSelectMethod}
				/>
			)}

			{error && <Results type="fail" />}
		</>
	);
}
