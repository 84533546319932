import style from "./purchased-card.module.css";
import arrow from "../../../../icons/arrow_down_24x24.svg";
import React from "react";
import { useTranslate } from "../../../../localization/translate";
import { useNavigate } from "react-router-dom";

function servicesSyntax(number, titles) {
	const cases = [2, 0, 1, 1, 1, 2];

	return titles[
		number % 100 > 4 && number % 100 < 20
			? 2
			: cases[number % 10 < 5 ? number % 10 : 5]
	];
}

export default function PurchasedProgram({
	name,
	availableServices,
	balance,
	idMis: id,
}) {
	const translate = useTranslate();
	const navigate = useNavigate();

	return (
		<div
			className={style.card}
			onClick={() => navigate(`/profile/programs/${id}`)}
		>
			<div>
				<div className={style.name}>{name}</div>
				<div className={style.sub}>
					{!!balance &&
						`${translate(
							"profile.programs.balance",
							true
						)} ${balance.toLocaleString()} ${translate(
							"app.currencySign",
							true
						)}`}
					{!!balance && availableServices.length > 0 && ", "}
					{availableServices.length > 0 && (
						<>
							{`${translate("profile.programs.available", true)} ${
								availableServices.length
							} `}
							{servicesSyntax(
								availableServices.length,
								JSON.parse(translate("profile.programs.services", true))
							)}
						</>
					)}
				</div>
			</div>
			{/*<div className={style.arrow}>*/}
			{/*    <img src={arrow}/>*/}
			{/*</div>*/}
		</div>
	);
}
