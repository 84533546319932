import moment from "moment";
import style from "./subscribe-modal.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
	setSubscribeModal,
	selectedSubscribeModal,
	selectedDoctor,
	setSelectedDoctor,
} from "../../services/appointment.slice";
import ModalInfo from "../../../../components/custom-modal/info";
import { useTranslate } from "../../../../localization/translate";
import Button from "../../../../components/button/button";
import Loader from "../../../../components/loader";
import { useSubscribeMutation } from "../../services/appointment.api";
import { useNavigate, useParams } from "react-router-dom";
import {
	getErrorMessageFromError,
	getFromClarificationByTarget,
} from "../../../../app/error-handler.api";
import { useGetNotificationsQuery } from "../../../profile/services/profile.api";
import { useEffect, useMemo, useState } from "react";
import { moscowTimeZone } from "../../../../utils/dates/timezone-converter";
import { Metrics } from "../../../../utils/metrics";
import { AuthModal } from "../examination-modal/auth-modal";
import classNames from "classnames";

const modalStyle = {
	overlay: {
		display: "flex",
		alignItems: "center",
		zIndex: 150,
	},
	content: {
		zIndex: 150,
	},
};

export default function SubscribeModal({ isPublic = false, isWidget = false }) {
	const timeSlot = useSelector(selectedSubscribeModal);
	const { data, isLoading } = useGetNotificationsQuery(null, {
		skip: isPublic,
	});

	const subIsActive = useMemo(() => {
		if (!data) return false;

		const activeSubs = data.map(
			({ notifications }) =>
				notifications.find((item) => item.name === "Подписка на занятые слоты")
					?.isEnabled || false
		);

		return activeSubs.some((item) => item);
	}, [data]);

	if (!timeSlot) {
		return null;
	}

	if (isLoading) {
		return (
			<ModalInfo
				iconType={null}
				isOpen
				modalStyle={{
					...modalStyle,
					overlay: {
						...modalStyle.overlay,
						alignItems: isWidget ? "flex-start" : "center",
					},
				}}
			>
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			</ModalInfo>
		);
	}

	return (
		<ModalBody
			subIsActive={subIsActive}
			isPublic={isPublic}
			isWidget={isWidget}
		/>
	);
}

const ModalBody = ({
	subIsActive = false,
	isPublic = false,
	isWidget = false,
}) => {
	moment().locale("ru");
	const dispatch = useDispatch();
	const params = useParams();
	const timeSlot = useSelector(selectedSubscribeModal);
	const doctorInfo = useSelector(selectedDoctor);
	const translate = useTranslate();

	const navigate = useNavigate();

	const [subscribe, { data, isLoading, error }] = useSubscribeMutation();
	const momentTimeslot = moment(timeSlot.time, "YYYY-MM-DD HH:mm");
	const speciality =
		timeSlot.specialityId ||
		params.specialityId ||
		(doctorInfo && doctorInfo.specialityId);
	const doctorId = doctorInfo?.id;
	const subscribeProps = {
		datetimeSlot: momentTimeslot.format(`YYYY-MM-DDTHH:mm:ss`),
	};

	const [isAuthOpen, setIsAuthOpen] = useState(false);

	if (speciality) {
		subscribeProps.speciality = speciality;
	}
	if (timeSlot.idMis) {
		subscribeProps.idMis = timeSlot.idMis;
	}
	if (doctorId) {
		subscribeProps.doctor = doctorId;
	}

	const messages = getErrorMessageFromError({
		fromClarifications: true,
		error,
		status: 400,
	});
	const alreadySubscribed = getFromClarificationByTarget(messages, "slot");

	const onRequestClose = () => {
		if (isPublic) {
			if (speciality && !isWidget) {
				navigate(`/appointment/speciality/${speciality}`);
			}
		}

		dispatch(setSubscribeModal(null));
	};

	const makeSubscribtion = () => {
		subscribe(subscribeProps).then(() => {
			Metrics.gtmEvent("add_to_wishlist");
			Metrics.gtmEvent("subscribe_slot", {
				doctorId,
				date: timeSlot.date,
			});
		});
	};

	const onClick = () => {
		if (isPublic) setIsAuthOpen(true);
		else !isLoading && makeSubscribtion();
	};

	const goToSettings = () => {
		onRequestClose();

		if (isWidget) {
			window.open("/profile/notifications", "_blank");
		} else {
			navigate("/profile/notifications");
		}
	};

	if (messages && !alreadySubscribed) {
		return (
			<ModalInfo
				iconType={"error"}
				isOpen={!!timeSlot}
				onRequestClose={onRequestClose}
				modalStyle={{
					...modalStyle,
					overlay: {
						...modalStyle.overlay,
						alignItems: isWidget ? "flex-start" : "center",
					},
				}}
				subtitle={
					<>
						{translate("appointment.subscribeTimeslot.subscribe")}
						&nbsp;
						{translate("app.at")}
						&nbsp;
						{moment(momentTimeslot).format("DD MMMM  HH:mm")}
						{moscowTimeZone() ? "" : translate("app.byMskTime")}
					</>
				}
				subtitleStyles={{ marginTop: 0 }}
				text={translate("app.somethingWhenWrong")}
				textClass={style.text}
				modalClassName={style.modal}
				bodyClass={style.body}
			>
				<Button className={style.button} onClick={onRequestClose}>
					{translate("appointment.subscribeTimeslot.backToSchedule", true)}
				</Button>
			</ModalInfo>
		);
	}

	if (alreadySubscribed) {
		return (
			<ModalInfo
				iconType={null}
				isOpen={!!timeSlot}
				onRequestClose={onRequestClose}
				modalStyle={modalStyle}
				subtitle={
					<>
						{moment(momentTimeslot).format("DD MMMM  HH:mm")}{" "}
						{moscowTimeZone() ? "" : translate("app.byMskTime")}
					</>
				}
				subtitleStyles={{ marginTop: 0 }}
				text={
					<>
						<span>{alreadySubscribed.message}</span>
						{translate("appointment.subscribeTimeslot.alreadySubscribed")}
					</>
				}
				textClass={style.text}
				modalClassName={style.modal}
				bodyClass={style.body}
			>
				<Button className={style.button} onClick={onRequestClose}>
					{translate("app.well", true)}
				</Button>
			</ModalInfo>
		);
	}

	if (data && !messages) {
		return (
			<ModalInfo
				iconType={"success"}
				isOpen={!!timeSlot}
				onRequestClose={onRequestClose}
				modalStyle={modalStyle}
				subtitle={
					<>
						{translate("appointment.subscribeTimeslot.successfullSubcscribe")}
						{moment(momentTimeslot).format("DD MMMM  HH:mm")}
						{moscowTimeZone() ? "" : translate("app.byMskTime")}
						{/*convertDate()*/}
					</>
				}
				text={translate(
					"appointment.subscribeTimeslot.successfullSubcscribeText"
				)}
				textClass={style.text}
				modalClassName={style.modal}
				bodyClass={style.body}
			>
				<Button className={style.button} onClick={onRequestClose}>
					{translate("appointment.subscribeTimeslot.backToSchedule", true)}
				</Button>
			</ModalInfo>
		);
	}

	if (isAuthOpen) {
		if (!isWidget) dispatch(setSelectedDoctor(null));

		return (
			<AuthModal
				isOpen
				onClose={() => {
					setIsAuthOpen(false);
					onRequestClose();
				}}
				onSuccessAuth={makeSubscribtion}
			/>
		);
	}

	if (!subIsActive && !isPublic) {
		return (
			<ModalInfo
				iconType={null}
				isOpen
				onRequestClose={onRequestClose}
				modalStyle={modalStyle}
				subtitle={
					<>
						{translate("appointment.subscribeTimeslot.subscribe")}
						&nbsp;
						{translate("app.at")}
						&nbsp;
						{moment(
							timeSlot.date + " " + timeSlot.time,
							"YYYY-MM-DD HH:mm"
						).format("DD MMMM  HH:mm")}
					</>
				}
				subtitleStyles={{ marginTop: 0 }}
				text={translate("appointment.subscribeTimeslot.notificationDisabled")}
				textClass={style.text}
				modalClassName={style.modal}
				bodyClass={style.body}
			>
				<Button className={style.button} onClick={goToSettings}>
					{translate("appointment.subscribeTimeslot.settings")}
				</Button>
			</ModalInfo>
		);
	}

	return (
		<ModalInfo
			iconType={null}
			isOpen={!!timeSlot}
			onRequestClose={onRequestClose}
			modalStyle={{
				...modalStyle,
				overlay: {
					...modalStyle.overlay,
					alignItems: isWidget ? "flex-start" : "center",
				},
			}}
			subtitle={
				<>
					{moment(momentTimeslot).format("DD MMMM  HH:mm")}
					{moscowTimeZone() ? "" : translate("app.byMskTime", true)}
				</>
			}
			subtitleStyles={{ marginTop: 0 }}
			text={translate("appointment.subscribeTimeslot.confirmText")}
			textClass={style.text}
			modalClassName={classNames(style.modal, { [style.widget]: isWidget })}
			bodyClass={classNames(style.body, { [style.widget]: isWidget })}
		>
			<Button
				className={classNames(style.button, { [style.widget]: isWidget })}
				onClick={onClick}
			>
				{translate("appointment.subscribeTimeslot.subscribeThisSlot", true)}
			</Button>
		</ModalInfo>
	);
};
