import { useState } from "react";
import { CustomModal, ContentModal } from "../../../components/custom-modal";
import SelectorModal from "../../../components/item-selector/modal-selector";
import Button from "../../../components/button/button";
import styles from "./style.module.css";

const rowStyles = {
	width: "100%",
	marginBottom: 20,
	display: "flex",
	justifyContent: "space-between",
};

const SmallContent = () => (
	<div
		style={{
			height: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
		}}
	>
		Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam modi
		laudantium neque corporis distinctio? Quibusdam, error ipsa. Aperiam
		molestiae ipsum, amet quis, distinctio, velit delectus esse consectetur
		voluptatum dolorum nihil itaque voluptatem officia! Omnis, accusamus? Error
		eveniet omnis voluptas iure accusamus. Doloremque vel sit laboriosam error
		<Button>Submit</Button>
	</div>
);

const LargeContent = () => (
	<>
		<div style={{ marginBottom: 20 }}>
			Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit
			minus natus aut accusamus porro provident veniam nostrum tempora
			architecto ipsa, ullam vero doloribus molestiae voluptates similique ea
			magnam veritatis et! Repudiandae ex cum reiciendis dolorum ut,
			reprehenderit dolores dolore sapiente!
		</div>

		<div style={{ marginBottom: 20 }}>
			Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit
			minus natus aut accusamus porro provident veniam nostrum tempora
			architecto ipsa, ullam vero doloribus molestiae voluptates similique ea
			magnam veritatis et! Repudiandae ex cum reiciendis dolorum ut,
			reprehenderit dolores dolore sapiente!
		</div>

		<div style={{ marginBottom: 20 }}>
			Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit
			minus natus aut accusamus porro provident veniam nostrum tempora
			architecto ipsa, ullam vero doloribus molestiae voluptates similique ea
			magnam veritatis et! Repudiandae ex cum reiciendis dolorum ut,
			reprehenderit dolores dolore sapiente!
		</div>

		<div style={{ marginBottom: 30 }}>
			Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit
			minus natus aut accusamus porro provident veniam nostrum tempora
			architecto ipsa, ullam vero doloribus molestiae voluptates similique ea
			magnam veritatis et! Repudiandae ex cum reiciendis dolorum ut,
			reprehenderit dolores dolore sapiente!
		</div>

		<Button>Submit</Button>
	</>
);

export default () => {
	const [cm1, setCm1] = useState(false);
	const [cm2, setCm2] = useState(false);
	const [m1, setM1] = useState(false);
	const [m2, setM2] = useState(false);
	const [s1, setS1] = useState(false);
	const [s2, setS2] = useState(false);

	return (
		<>
			<div style={rowStyles}>
				<Button className={styles.btn} onClick={() => setCm1(true)}>
					CustomModal.static
				</Button>
				<Button className={styles.btn} onClick={() => setCm2(true)}>
					CustomModal.scroll
				</Button>
			</div>

			<div style={rowStyles}>
				<Button className={styles.btn} onClick={() => setM1(true)}>
					ContentModal.static
				</Button>
				<Button className={styles.btn} onClick={() => setM2(true)}>
					ContentModal.scroll
				</Button>
			</div>

			<div style={rowStyles}>
				<Button className={styles.btn} onClick={() => setS1(true)}>
					SelectorModal.static
				</Button>
				<Button className={styles.btn} onClick={() => setS2(true)}>
					SelectorModal.scroll
				</Button>
			</div>

			<CustomModal isOpen={cm1} onClose={() => setCm1(false)}>
				<SmallContent />
			</CustomModal>

			<CustomModal isOpen={cm2} onClose={() => setCm2(false)}>
				<LargeContent />
			</CustomModal>

			<ContentModal
				isOpen={m1}
				closeModal={() => setM1(false)}
				styles={{
					content: {
						display: "flex",
						alignItems: "stretch",
						justifyContent: "center",
					},
				}}
			>
				<div style={{ maxWidth: 600, paddingTop: 40 }}>
					<SmallContent />
				</div>
			</ContentModal>

			<ContentModal
				isOpen={m2}
				closeModal={() => setM2(false)}
				styles={{
					content: {
						display: "flex",
						alignItems: "stretch",
						justifyContent: "center",
					},
				}}
			>
				<div style={{ maxWidth: 600, paddingTop: 40 }}>
					<LargeContent />
				</div>
			</ContentModal>

			<SelectorModal
				isOpen={s1}
				onRequestClose={() => setS1(false)}
				applyBtn={{
					text: "test",
				}}
				advancedButtons={<Button light>test</Button>}
			>
				<SmallContent />
			</SelectorModal>

			<SelectorModal
				isOpen={s2}
				onRequestClose={() => setS2(false)}
				applyBtn={{
					text: "test",
				}}
				advancedButtons={<Button light>test</Button>}
			>
				<LargeContent />
			</SelectorModal>
		</>
	);
};
