import { useState, useContext, useMemo } from "react";
import { useTranslate } from "../../../../../../localization/translate";
import Input from "../../../../../../components/input";
import Loader from "../../../../../../components/loader";
import Button from "../../../../../../components/button/button";
import {
	useAddCommentMutation,
	useMarkIssueClosedMutation,
	useAddIssueAttachmentMutation,
} from "../../../../services/profile.api";
import IssueContext from "../../contexts/issue-context";
import styles from "./style.module.css";

const ALLOWED_FILE_TYPES = [".jpg", ".jpeg", ".png"];

const SendIcon = () => (
	<svg
		width="28"
		height="29"
		viewBox="0 0 28 29"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.0003 6.76406C11.9493 6.76406 9.98231 7.57882 8.53203 9.0291C7.08175 10.4794 6.26699 12.4464 6.26699 14.4974C6.26699 16.5484 7.08175 18.5154 8.53203 19.9657C9.98231 21.416 11.9493 22.2307 14.0003 22.2307C15.0159 22.2307 16.0215 22.0307 16.9597 21.6421C17.898 21.2534 18.7505 20.6838 19.4686 19.9657C20.1867 19.2476 20.7564 18.3951 21.145 17.4568C21.5336 16.5186 21.7337 15.5129 21.7337 14.4974C21.7337 13.4818 21.5336 12.4762 21.145 11.538C20.7564 10.5997 20.1867 9.74721 19.4686 9.0291C18.7505 8.311 17.898 7.74136 16.9597 7.35273C16.0215 6.96409 15.0159 6.76406 14.0003 6.76406ZM7.40066 7.89773C9.151 6.14739 11.525 5.16406 14.0003 5.16406C15.226 5.16406 16.4397 5.40548 17.572 5.87452C18.7044 6.34356 19.7333 7.03105 20.6 7.89773C21.4667 8.76441 22.1542 9.79331 22.6232 10.9257C23.0922 12.0581 23.3337 13.2717 23.3337 14.4974C23.3337 15.7231 23.0922 16.9367 22.6232 18.0691C22.1542 19.2015 21.4667 20.2304 20.6 21.0971C19.7333 21.9637 18.7044 22.6512 17.572 23.1203C16.4397 23.5893 15.226 23.8307 14.0003 23.8307C11.525 23.8307 9.151 22.8474 7.40066 21.0971C5.65032 19.3467 4.66699 16.9727 4.66699 14.4974C4.66699 12.022 5.65032 9.64807 7.40066 7.89773ZM13.2003 12.6362L11.7216 14.1149C11.4091 14.4274 10.9026 14.4274 10.5902 14.1149C10.2778 13.8025 10.2778 13.296 10.5902 12.9836L13.4346 10.1391C13.7471 9.8267 14.2536 9.8267 14.566 10.1391L17.4105 12.9836C17.7229 13.296 17.7229 13.8025 17.4105 14.1149C17.098 14.4274 16.5915 14.4274 16.2791 14.1149L14.8003 12.6362V18.29C14.8003 18.7318 14.4422 19.09 14.0003 19.09C13.5585 19.09 13.2003 18.7318 13.2003 18.29V12.6362Z"
			fill="white"
		/>
	</svg>
);

const FileIcon = () => (
	<svg
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.7163 4.82943C12.8167 3.75637 14.3942 3.2906 15.9202 3.58821C17.7626 3.94755 19.2028 5.35196 19.5713 7.14866C19.8765 8.63671 19.3989 10.1751 18.2985 11.2481L12.6196 16.7859C11.9868 17.403 11.0795 17.6709 10.2019 17.4997C9.14233 17.2931 8.31407 16.4854 8.10215 15.4521C7.92663 14.5963 8.20132 13.7116 8.83416 13.0945L14.6114 7.4607C14.8844 7.19444 15.3271 7.19444 15.6002 7.4607C15.8732 7.72696 15.8732 8.15866 15.6002 8.42492L9.82294 14.0587C9.5207 14.3534 9.38951 14.7759 9.47334 15.1847C9.57455 15.6782 9.97012 16.0639 10.4762 16.1626C10.8953 16.2443 11.3286 16.1164 11.6309 15.8217L17.3097 10.2839C18.0795 9.53322 18.4136 8.45706 18.2001 7.41608C17.9423 6.15919 16.9348 5.17672 15.6459 4.92535C14.5784 4.71715 13.4749 5.04298 12.7051 5.79365L6.93125 11.4241C5.78927 12.5377 5.2385 14.1031 5.43881 15.6658C5.73578 17.9825 7.60705 19.8073 9.98277 20.0969C11.5853 20.2923 13.1906 19.7552 14.3325 18.6415L18.8064 14.2788C19.0795 14.0125 19.5222 14.0125 19.7952 14.2788C20.0683 14.545 20.0683 14.9767 19.7952 15.243L15.3213 19.6058C13.8742 21.0169 11.84 21.6975 9.80932 21.45C6.79883 21.083 4.42758 18.7707 4.05127 15.835C3.79743 13.8547 4.49536 11.871 5.94247 10.4599L11.7163 4.82943Z"
			fill="#7A8CA4"
		/>
	</svg>
);

const FileInput = ({ onSelectFile }) => {
	const handleChange = (event) => onSelectFile(event.target.files[0]);

	return (
		<div className={styles.file_upload}>
			<input
				type="file"
				id="file_input"
				hidden
				accept={ALLOWED_FILE_TYPES.join(",")}
				onChange={handleChange}
			/>

			<label htmlFor="file_input">
				<FileIcon />
			</label>
		</div>
	);
};

export default function IssueForm({ issueId, isClosureRequested }) {
	const translate = useTranslate();
	const { addComment } = useContext(IssueContext);

	const [message, setMessage] = useState("");
	const [file, setFile] = useState(null);

	const [submitCommnet] = useAddCommentMutation();
	const [markClosed, { isLoading }] = useMarkIssueClosedMutation();
	const [submitFile] = useAddIssueAttachmentMutation();

	const isFilled = useMemo(() => message.length > 0, [message]);

	const handleSubmit = async () => {
		const { data } = await submitCommnet({
			issueId,
			comment: message,
		});

		if (data) {
			setMessage("");
			addComment(data);
		}

		if (file) {
			uploadFile(file);
			setFile(null);
		}
	};

	const handleClose = () => markClosed({ issueId });

	const uploadFile = async (file) => {
		if (!validateFile(file)) {
			return;
		}

		const { data } = await submitFile({ issueId, file });

		if (data) {
			addComment(data);
		}

		if (!data) {
			addComment({
				error: translate("profile.support.detail.fileUploadError", true),
				author: "user",
				comment: null,
				attachments: {
					name: file.name,
					url: URL.createObjectURL(file),
				},
				creationDate: Date.now(),
			});
		}
	};

	const validateFile = (_file) => {
		if (_file.size > 50_000_000) {
			addComment({
				error: translate("profile.support.detail.fileUploadError", true),
				author: "user",
				comment: null,
				attachments: {
					name: _file.name,
					url: URL.createObjectURL(_file),
				},
				creationDate: Date.now(),
			});

			return false;
		}

		return true;
	};

	const handleUploadFile = (_file) => {
		setFile(null);

		if (!message) {
			uploadFile(_file);
		} else {
			setFile(_file);
		}
	};

	const handleKeyUp = (e) => {
		if (e.key === "Enter" && message.length > 0) {
			handleSubmit();
		}
	};

	return (
		<div className={styles.form}>
			{!isClosureRequested && (
				<Button
					light
					small
					disabled={isLoading}
					className={styles.close_issue_btn}
					onClick={handleClose}
				>
					{isLoading ? (
						<Loader
							width={20}
							height={20}
							containerStyle={{ display: "flex", justifyContent: "center" }}
						/>
					) : (
						translate("profile.support.detail.closeIssue")
					)}
				</Button>
			)}

			<div className={styles.wrapper}>
				<div className={styles.input_container}>
					<Input
						type="text"
						value={message}
						onChange={setMessage}
						placeholder={translate(
							"profile.support.detail.inputPalceholder",
							true
						)}
						onKeyUp={handleKeyUp}
						className={styles.input}
					/>

					<div className={styles.file_input}>
						<FileInput onSelectFile={handleUploadFile} />
					</div>
				</div>

				{isFilled && (
					<Button small className={styles.send_btn} onClick={handleSubmit}>
						<SendIcon />
					</Button>
				)}
			</div>
		</div>
	);
}
