import moment from "moment";

export const prepareList = ({ search, list = [], dateRange = [], type }) => {
	const [startDate, endDate] = dateRange;
	if (!list || list.length === 0 || !Array.isArray(list)) {
		return {};
	}

	const result = {};

	list
		.filter((item) => {
			let res = true;

			if (type && item.type !== type) {
				res = false;
			}

			if (startDate && endDate) {
				// если выбран диапазон дат

				if (
					(moment(item.date).isSame(startDate) ||
						moment(item.date).isAfter(startDate)) &&
					(moment(item.date).isSame(endDate) ||
						moment(item.date).isBefore(endDate))
				) {
					res = true;
				} else {
					res = false;
				}
			}

			if (search.length > 0 && res) {
				res = item.name.toLowerCase().includes(search.toLowerCase());
			}

			return res;
		})
		.forEach((item) => {
			if (result[item.date]) {
				result[item.date].push(item);
			} else {
				result[item.date] = [item];
			}
		});

	return result;
};
