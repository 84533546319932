import { useEffect } from "react";
import classNames from "classnames";
import { useTranslate } from "../../../../localization/translate";
import Loader from "../../../../components/loader";
import SupportInfoBanner from "./components/SupportInfoBanner";
import Requests from "./components/Requests";
import { useGetSupportIssuesQuery } from "../../services/profile.api";
import styles from "./support.module.css";

export default function Support() {
	const translate = useTranslate();
	const { data, isLoading, refetch } = useGetSupportIssuesQuery();

	useEffect(() => refetch(), []);

	return (
		<>
			{data && (
				<div className={styles.support}>
					<div className={classNames("h3", styles.title)}>
						{translate("profile.support.title")}
					</div>

					<div className={styles.content_block}>
						<SupportInfoBanner canCreate />
					</div>

					<div className={styles.content_block}>
						<Requests collection={data.issues} />
					</div>
				</div>
			)}

			{isLoading && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}
		</>
	);
}
