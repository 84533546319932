import styles from "./assignment.module.css";
import Loader from "../../../../components/loader";
import React, { useEffect, useState } from "react";
import { useGetAssignmentsQuery } from "../../services/history.api";
import SearchInput from "../../../../components/search-input/search-input";
import { useTranslate } from "../../../../localization/translate";
import ProfileNotice from "../../../profile/modules/personala-data/notice";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { useMediaQuery } from "beautiful-react-hooks";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectPatient } from "../../../profile/services/profile.slice";
import { useGetProfileQuery } from "../../../profile/services/profile.api";
import HasNoItems from "../../../../components/has-no-items/has-no-items";
import { Metrics } from "../../../../utils/metrics";

export default function Assignment() {
	const onKeyUp = (val) => {
		setSearch(val);
	};
	moment().locale("ru");
	const isMobile = useMediaQuery("(max-width: 620px)");
	const [noticeShown, setShown] = useState(true);
	const [search, setSearch] = useState("");
	const selectedPatient = useSelector(selectPatient);
	const { data: profile } = useGetProfileQuery();
	const currentPatientCard = selectedPatient
		? selectedPatient.medCardNumber
		: profile
		? profile.medCardNumber
		: null;
	const { data, isLoading, error } = useGetAssignmentsQuery(
		currentPatientCard,
		{ skip: !currentPatientCard }
	);
	const [list, setList] = useState([]);
	const translate = useTranslate();
	const checkMatch = (str) => {
		if (search.length > 0) {
			return str.toLowerCase().trim().includes(search.toLowerCase().trim());
		}

		return true;
	};

	useEffect(() => {
		if (data) {
			setList(data);
		}
	}, [data, search]);

	useEffect(() => {
		if (data) Metrics.gtmEvent("view_history_assignment");
	}, [data]);

	if (isLoading && !data) {
		return (
			<div className={styles.loader}>
				<Loader />
			</div>
		);
	}

	if (list.length === 0) {
		return (
			<div className={styles.assignment}>
				{search.length > 0 && <SearchInput value={search} onKeyUp={onKeyUp} />}
				<HasNoItems title={translate("history.list.hasNoAssignments", true)} />
			</div>
		);
	}

	return (
		<div className={styles.assignment}>
			<SearchInput value={search} onKeyUp={onKeyUp} className={styles.search} />
			{noticeShown && (
				<ProfileNotice
					isGrey
					title={translate("app.attention", true)}
					message={translate("history.assignment.lifeTimeNotice")}
					btn={{
						onClick: () => setShown(false),
						text: translate("appointment.subscribeTimeslot.ok", true),
					}}
					className={styles.notice}
					isSmall={isMobile}
				/>
			)}
			<ProfileNotice
				isGrey
				title={<AppointmentButton />}
				isSmall={isMobile}
				message={
					<>
						{translate("history.assignment.callForAppointment")}
						{isMobile && <br />}
						<a
							href={`tel:${translate("history.assignment.phoneNumber")}`}
							className={styles.link}
						>
							{translate("history.assignment.phoneNumber")}
						</a>
						{!isMobile &&
							translate("history.assignment.callForAppointmentContinue", true)}
					</>
				}
			/>
			{list
				.filter(
					(item) =>
						checkMatch(item.name) ||
						(item.speciality && checkMatch(item.speciality.name)) ||
						checkMatch(
							item.services
								.map((service) => service.code + service.name)
								.join("")
						)
				)
				.map((item) => {
					const validTillMS = moment.duration(
						moment(item.validTill).diff(moment()),
						"milliseconds"
					);

					return (
						<div
							className={classNames(styles.item, {
								[styles.with_speciality]: item.speciality,
							})}
							key={`assignment-${item.id}`}
						>
							<div>
								<span className={classNames("text", styles.name)}>
									{item.name}
								</span>
								{item.speciality && (
									<AppointmentButton
										link={`/appointment/speciality/${item.speciality.id}`}
									/>
								)}
							</div>
							<div
								className={classNames(styles.till_date, {
									[styles.less_than]: validTillMS.asDays() < 5,
								})}
							>
								{translate("history.assignment.tillDate")}
								{validTillMS.humanize()}
								&nbsp;
								{moment(item.validTill).format("DD.MM.YYYY")}
							</div>
							{item.type === "TEST" && (
								<div className={classNames(styles.hint)}>
									{translate("history.assignment.hint", true)}
								</div>
							)}
							{item.services.length > 0 && (
								<div className={styles.services}>
									{item.services.map((service) => (
										<div key={`assignment-${item.id}-${service.code}`}>
											- {service.name} {service.code}
										</div>
									))}
								</div>
							)}
						</div>
					);
				})}
		</div>
	);
}

const AppointmentButton = ({ link, className }) => {
	const css = classNames(styles.appointment_button, className);
	const translate = useTranslate();

	if (link) {
		return (
			<NavLink to={link} className={css}>
				{translate("history.assignment.appointmentButton", true)}
			</NavLink>
		);
	}

	return (
		<span className={css}>
			{translate("history.assignment.appointmentButton", true)}
		</span>
	);
};
