import React from "react";
import styles from "./styles.module.css";

export const MedicationCard = ({
	title,
	description,
	additionalTitle,
	additionalDescription,
	onClick = () => ({}),
}) => {
	return (
		<div className={styles.card} onClick={onClick}>
			<div className={styles.info_icon}>
				<svg
					width="45"
					height="44"
					viewBox="0 0 45 44"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="23.5" cy="13.5" r="1.5" fill="#7A8CA4" />
					<rect x="17" y="26" width="7" height="2" rx="1" fill="#7A8CA4" />
					<rect x="17" y="30" width="12" height="2" rx="1" fill="#7A8CA4" />
					<rect x="17" y="34" width="12" height="2" rx="1" fill="#7A8CA4" />
					<rect
						x="11"
						y="8"
						width="24"
						height="33"
						rx="5"
						stroke="#7A8CA4"
						strokeWidth="2"
					/>
				</svg>
			</div>

			<div className={styles.content}>
				<div className={styles.title_wrp}>
					<span className={styles.title}>{title}</span>
				</div>

				<span className={styles.description}>{description}</span>

				{(additionalTitle || additionalDescription) && (
					<div className={styles.additional}>
						{additionalTitle && (
							<div className={styles.chip}>
								<span>{additionalTitle}</span>
							</div>
						)}

						{additionalDescription && (
							<span className={styles.additional_text}>
								{additionalDescription}
							</span>
						)}
					</div>
				)}
			</div>

			<div className={styles.arrow_icon}>
				<svg
					width="24"
					height="25"
					viewBox="0 0 24 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M7.23259 4.72676C6.92247 5.0291 6.92247 5.51929 7.23259 5.82163L14.0828 12.5L7.23259 19.1784C6.92247 19.4807 6.92247 19.9709 7.23259 20.2732C7.54271 20.5756 8.04552 20.5756 8.35564 20.2732L15.7674 13.0474C16.0775 12.7451 16.0775 12.2549 15.7674 11.9526L8.35564 4.72676C8.04552 4.42441 7.54271 4.42441 7.23259 4.72676Z"
						fill="#7A8CA4"
					/>
				</svg>
			</div>
		</div>
	);
};
