import { useContext, useEffect } from "react";
import { useGetCheckoutQuery } from "../services/payments.api";
import PaymentContext from "../contexts/payment-context";

export default function usePaymentContext({
	type,
	purposeId,
	medCardNumber = null,
}) {
	const { payment, setPayment } = useContext(PaymentContext);

	const { data, isLoading, isSuccess, error } = useGetCheckoutQuery({
		purposeId,
		medCardNumber,
		paymentPurpose: type,
		...(payment.discount?.type && { discountType: payment.discount?.type }),
		...(payment.discount?.id && { discountId: payment.discount?.id }),
	});

	useEffect(
		() =>
			setPayment((prev) => ({
				...prev,
				checkout: {
					amount: payment.checkout.amount || data?.amount,
					discounted: data?.amountDiscounted || null,
					paymentMethod:
						payment.checkout.paymentMethod || data?.defaultPaymentMethod,
					isDiscountAvailable: data?.isDiscountAvailable || false,
					isPaymentAvailable: data?.isPaymentAvailable || false,
				},
			})),
		[data]
	);

	return {
		error,
		payment,
		isLoading,
		isSuccess,
		setPayment,
	};
}
