import { useMemo } from "react";
import Modal from "../../modal-safari-wrapper/modal";
import classNames from "classnames";
import Button from "../../button/button";
import CloseModalButton from "../../modal-items/close";
import arrow from "../../../icons/arrow_down_24x24.svg";
import styles from "./style.module.css";

const modalStyles = {
	content: {
		display: "flex",
	},
};

export default ({
	isOpen,
	title = "",
	showCloseBtn = true,
	showBackwardBtn = false,
	renderBackwardBtn,
	overlayClassName,
	onBackwardClick,
	onClose,
	onOpen,
	style,
	className,
	children,
}) => {
	const showHeader = useMemo(
		() => title || showBackwardBtn || showCloseBtn,
		[title, showBackwardBtn, showCloseBtn]
	);

	const mappedStyles = useMemo(
		() => ({
			content: { ...modalStyles.content, ...style?.content },
			overlay: { ...modalStyles.overlay, ...style?.overlay },
		}),
		[style]
	);

	return (
		<Modal
			overlayClassName={overlayClassName}
			isOpen={isOpen}
			onRequestClose={onClose}
			onAfterOpen={onOpen}
			style={mappedStyles}
			className={classNames(styles.modal, className)}
		>
			{showHeader && (
				<div className={styles.header}>
					<div className={styles.start}>
						{showBackwardBtn &&
							(renderBackwardBtn ? (
								renderBackwardBtn()
							) : (
								<Button
									small
									light
									className={styles.backward_btn}
									onClick={onBackwardClick}
								>
									<img alt="arrow" src={arrow} />
								</Button>
							))}
					</div>

					<div className={styles.center}>
						{title && <div className={styles.title}>{title}</div>}
					</div>

					<div className={styles.end}>
						{showCloseBtn && (
							<CloseModalButton
								className={styles.close_btn}
								closeModal={onClose}
							/>
						)}
					</div>
				</div>
			)}

			<div
				className={classNames(styles.content, {
					[styles.with_header]: showHeader,
				})}
			>
				{children}
			</div>
		</Modal>
	);
};
