import { PAYMENT_METHODS, DISCOUNT_METHODS } from "./constants";

export const PaymentMethodsFactory = (translate) => (data) => [
	...(data?.bankCards?.map(({ id, name }) => ({
		id,
		name,
		type: PAYMENT_METHODS.card,
	})) || []),
	...(data?.giftCertificates?.map(({ name, number: id }) => ({
		id,
		name,
		type: PAYMENT_METHODS.certificate,
	})) || []),
	...(data?.isPaymentByUrlAvailable
		? [
				{
					id: "otherCard",
					name: translate("payments.PaymentMethods.otherCard", true),
					type: PAYMENT_METHODS.url,
				},
		  ]
		: []),
	...(data?.accounts.length
		? [
				{
					id: "anyAccount",
					name: translate("payments.PaymentMethods.anyAccount.title", true),
					description: translate(
						"payments.PaymentMethods.anyAccount.description",
						true
					),
					type: "anyAccount",
				},
		  ]
		: []),
];

export const DiscountMethodsFactory = (translate) => (data) => [
	...(data?.discountCards?.map((item) => ({
		id: item.number,
		name: item.name,
		description: `-${item.discountValue}%`,
		type: DISCOUNT_METHODS.discountCard,
	})) || []),
	...(data?.coupons?.map((item) => ({
		id: item.id,
		name: item.name,
		description: `-${item.discountValue}%`,
		type: DISCOUNT_METHODS.coupon,
	})) || []),
];

export const AccountMethodsFactory = (translate) => (accounts) =>
	accounts.map(({ account, isAvailable }) => ({
		...account,
		disabled: false, // isAvailable,
		disabledText: translate("payments.PaymentAccounts.disabled", true),
		description: `${translate(
			`payments.PaymentAccounts.${account.balance ? "balance" : "owner"}`,
			true
		)} ${account.balance || account.owner} ${
			account.balance ? translate("app.currencySign", true) : ""
		}`,
		type: PAYMENT_METHODS.account,
	}));
