export const CODES_ENUM = {
	USER_AGREEMENT: "USER_AGREEMENT",
	PD_POLICY: "PD_POLICY",
	PD_CONDITIONS: "PD_CONDITIONS",
};

export const CONDITIONS_CALLBACK_URIs = {
	[CODES_ENUM.USER_AGREEMENT]: "https://my.medicina.ru/user-agreement",
	[CODES_ENUM.PD_CONDITIONS]:
		"https://www.medicina.ru/usloviya-obrabotki-personalnykh-dannykh/vtoroe-mnenie.php",
	[CODES_ENUM.PD_POLICY]:
		"https://www.medicina.ru/politika-obrabotki-personalnykh-dannykh-v-oao-meditsina/",
};
