import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.jsx";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorkerRegistration";
import { isSafari, isIOSApp, isAndroidApp } from "./utils";
import PullToRefresh from "pulltorefreshjs";


window.isDevMode = process.env.NODE_ENV == "development" ||
	window.location.host.includes("backend-dev-app2.medicina.ru")

if ( window.isDevMode ) {
	console.log(store, "isSafari", isSafari);
}

document.ontouchmove = function (event) {
	event.preventDefault();
};

if (isAndroidApp || isIOSApp) {
	PullToRefresh.init({
		mainElement: "body",
		instructionsReleaseToRefresh: " ",
		instructionsRefreshing: " ",
		iconRefreshing: " ",
		iconArrow: " ",
		instructionsPullToRefresh: " ",
		shouldPullToRefresh() {
			console.log({
				shouldPullToRefresh:
					!document.body?.className?.includes("ReactModal__Body--open") &&
					!window.scrollY,
			});

			return (
				!document.body?.className?.includes("ReactModal__Body--open") &&
				!window.scrollY
			);
		},
		onRefresh() {
			console.log({
				isIOSApp,
				isAndroidApp,
				isModalOpened: !!document.body?.className?.includes(
					"ReactModal__Body--open"
				),
				refresh:
					(isIOSApp || isAndroidApp) &&
					!document.body?.className?.includes("ReactModal__Body--open"),
			});
			if (
				(isIOSApp || isAndroidApp) &&
				!document.body?.className?.includes("ReactModal__Body--open")
			) {
				window.location.reload();
			}
		},
	});
}

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
