import style from "./feedback.module.css";
import React, { useEffect } from "react";
import moment from "moment";
import Masonry from "react-masonry-css";
import empty from "../../../../icons/empty-avatar.svg";
import { useMediaQuery } from "beautiful-react-hooks";
import { Stars } from "../rate-visit/rate-visit";
import { useState } from "react";
import classNames from "classnames";
import { useTranslate } from "../../../../localization/translate";
import { useGetDoctorReviewsQuery } from "../../services/appointment.api";
import Button from "../../../../components/button/button";
import Loader from "../../../../components/loader";

export default function Feedbacks({ id, ...props }) {
	const isMobile = useMediaQuery("(max-width: 900px)");
	const [reviews, setReviews] = useState([]);
	const [page, setPage] = useState(1);

	const { data, isFetching } = useGetDoctorReviewsQuery({
		id,
		page,
	});

	const showMore = () => {
		setPage((curr) => curr + 1);
	};

	useEffect(() => {
		if (data?.reviews?.length) {
			setReviews((curr) =>
				[...curr, ...data.reviews].filter((obj, pos, arr) => {
					return arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos;
				})
			);
		}
	}, [data]);

	useEffect(() => {
		return () => {
			setPage(1);
			setReviews([]);
		};
	}, []);

	return (
		<div className={style.container}>
			<Masonry
				breakpointCols={isMobile ? 1 : 2}
				className={style.masonry_grid}
				columnClassName={style.masonry_grid_column}
			>
				{reviews.map((review) => {
					const date = moment(review.date);
					const format =
						moment().year() === date.year() ? "DD.MM" : "DD.MM.YYYY";

					return (
						<div
							className={style.review}
							key={`review-${review.id}-${review.date}`}
						>
							<div className={style.header}>
								<img
									src={review.author.photo ? review.author.photo : empty}
									className={style.avatar}
									alt=""
								/>
								<div className={style.extra_info}>
									<div className={style.name}>{review.author.initials}</div>
									<Stars
										count={review.rate}
										containerClass={style.stars}
										starClass={style.star_class}
									/>
								</div>
								<div className={style.date}>{date.format(format)}</div>
							</div>
							<TextContainer text={review.comment} />
						</div>
					);
				})}
			</Masonry>
			{isFetching ? (
				<Loader className={style.loader} />
			) : (
				(data?.reviewsCount || 0) > reviews.length && (
					<Button onClick={showMore} className={style.button} light>
						Показать ещё отзывы
					</Button>
				)
			)}
		</div>
	);
}

const TextContainer = ({ text }) => {
	const [expanded, setExpanded] = useState(false);
	const [showExpander, setShow] = useState(false);
	const ref = React.useRef();
	const translate = useTranslate();

	useEffect(() => {
		if (ref.current && ref.current.scrollHeight > ref.current.offsetHeight) {
			setShow(true);
		}
	}, [ref]);

	return (
		<>
			<div
				ref={ref}
				className={classNames(style.comment, { [style.expanded]: expanded })}
			>
				{text}
			</div>
			{showExpander && (
				<span
					onClick={() => setExpanded(!expanded)}
					className={style.read_more}
				>
					{translate(expanded ? "app.hide" : "app.readMore", true)}
				</span>
			)}
		</>
	);
};
