import AES from "crypto-js/aes";
import enc from "crypto-js/enc-utf8";

export default class Crypto {
	static encrypt(text) {
		const passphrase = "530588f933";
		return AES.encrypt(text, passphrase).toString();
	}

	static decrypt(ciphertext) {
		const passphrase = "530588f933";
		return enc.stringify(AES.decrypt(ciphertext, passphrase));
	}
}
