import React, { useEffect } from "react";
import {
	Navigate,
	Outlet,
	useLocation,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAccessToken,
	selectExpired,
	selectIsGuestMode,
	setAuthInfo,
} from "./services/login.slice";
import {
	selectExaminationInfo,
	selectedSubscribeModal,
	setExaminationInfo,
} from "../appointment/services/appointment.slice";
import { useGetDoctorScheduleMutation } from "../appointment/services/appointment.api";

export default function RedirectControll() {
	const isGuest = useSelector(selectIsGuestMode);
	const expired = useSelector(selectExpired);
	const location = useLocation();
	const [params] = useSearchParams();
	const navigate = useNavigate();
	const isAuth = !!useSelector(selectAccessToken);
	const examinationInfo = useSelector(selectExaminationInfo);
	const timeSlot = useSelector(selectedSubscribeModal);
	const isWidget = window.location.href.includes("widget");
	const dispatch = useDispatch();
	const [getSchedule] = useGetDoctorScheduleMutation();

	const handleGetExaminationInfo = async () => {
		try {
			const date = params.get("date");
			const slotIdMis = Number(params.get("slot_id"));
			const doctorId = Number(params.get("doctor_id"));
			const serviceId = Number(params.get("service_id"));
			const specialityId = Number(params.get("speciality_id"));
			const additionalSlots =
				params
					.get("additional_slots")
					?.split(",")
					?.map((sl) => ({ idMis: +sl })) || [];

			const { data } = await getSchedule({
				dateFrom: date,
				dateTo: date,
				doctorId,
				specialityId,
			});

			const timeSlot = data.timeSlots.find(({ idMis }) => idMis === slotIdMis);

			dispatch(
				setExaminationInfo({
					magicLink: true,
					acceptAdultsShowWarning: data.doctorInfo.doctor.acceptAdults,
					doctor: data.doctorInfo.doctor,
					date: `${timeSlot?.time}`,
					cabinet: timeSlot?.cabinet,
					slotIdMis: timeSlot?.idMis,
					specialityId,
					receptionTypes: data.doctorInfo.speciality.appointmentFeature,
					additionalSlots,
					serviceId,
				})
			);
		} catch (error) {
			console.log(error.message);
		}
	};

	useEffect(() => {
		const isMagicLink = location.pathname.includes("magic_link");
		const isResetPassword = location.pathname.includes("reset_password");

		if (isMagicLink && isAuth && !isGuest) {
			handleGetExaminationInfo();
		}

		if (isAuth && !isGuest && isResetPassword) {
			dispatch(setAuthInfo());
			navigate(location.pathname + location.search);
		}
	}, [isAuth, isGuest]);

	if (location.pathname === "/refresh-session") {
		if (!expired) {
			return <Navigate to={"/login"} replace />;
		}
	} else if (
		isAuth &&
		!isGuest &&
		!examinationInfo?.isPublic &&
		!timeSlot &&
		!isWidget
	) {
		//Если пользователь авторизован
		return <Navigate to={"/events"} replace />;
	}

	return <Outlet />;
}
