import classNames from "classnames";
import styles from "./item-selector.module.css";

export default function ItemSelector({
	children,
	text,
	className,
	activeElementText,
	activeElementAction,
	noChoice = false,
}) {
	return (
		<div className={classNames(styles.item_selector, className)}>
			<span className={styles.name}>{text}</span>
			{!noChoice && (
				<span
					onClick={(e) => {
						activeElementAction && activeElementAction();
					}}
					className={styles.trigger_link}
				>
					{activeElementText}
				</span>
			)}
			{children}
		</div>
	);
}
