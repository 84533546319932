import React, { useEffect, useCallback, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import "moment/locale/ru";
import "swiper/css";
import AuthenticationProvider from "./features/login/authenticationProvider";
import {
	BrowserRouter as Router,
	Outlet,
	Route,
	Routes,
	useSearchParams,
	useLocation,
} from "react-router-dom";
import {
	selectApplicationError,
	selectShowSlowInternetScreen,
} from "./app/app.slice";
import { useSelector } from "react-redux";
import Error from "./components/errors/error";
import routes from "./app/sitemap.json";
import * as Pages from "./features";
import {
	selectLastUrl,
	setAuthInfo,
	setExpired,
	setShortCode,
} from "./features/login/services/login.slice";
import AuthRequired from "./features/login/auth-required";
import LoginPage from "./features/login/modules/login-page";
import MagicLinkAuthorization from "./features/login/modules/magic-link/magic-link-authorization";
import ShortCode from "./features/login/modules/short-code/short-code";
import RedirectControll from "./features/login/redirect-control";
import SystemNotifications from "./components/system-notification/system-notification";
import NewPassword from "./features/login/modules/reset-password/new-password";
import PaymentRedirect from "./features/payment-redirect/payment-redirect";
import { setInvoice } from "./features/received-bills/services/received-bills.slice";
import { Metrics } from "./utils/metrics";
import { scrollToPosition } from "./utils";
import ErrorBoundary from "./components/error-catch";
import { usePushReceivedMessage } from "./utils/firebase/use-push-received.message";
import Components from "./others/ComponentsPage";
import Polls from "./features/events/modules/polls/polls";
import GuestAppointment from "./features/guest-appointment/guest-appointment";
import AuthPage from "./features/login/modules/auth-page";
import DoctorAppointmentWidget from "./widgets/doctor-appointment";
import SlowInternetScreen from "./features/slow-internet-screen";
import SlowInternetConnectionBannerWrapper from "./features/slow-internet-banner";
import {GetCoffeePage} from "./features/profile/modules";

function App() {
	const appError = useSelector(selectApplicationError);
	const lastURL = useSelector(selectLastUrl);
	const isWidget = window.location.href.includes("widget");
	const showSlowInternetScreen = useSelector(selectShowSlowInternetScreen);

	if (showSlowInternetScreen) {
		return <SlowInternetScreen />;
	}

	if (appError) {
		return <Error status={appError.status} message={appError.error} />;
	}

	return (
		<SlowInternetConnectionBannerWrapper>
			<AuthenticationProvider>
				<ErrorBoundary>
					<Router>
						<Routes>
							<Route path="/" element={<Root isWidget={isWidget} />}>
								<Route path="/">
									<Route
										path="/payment-result"
										index
										element={<PaymentRedirect />}
									/>
								</Route>
								<Route path="/" element={<RedirectControll />}>
									<Route path="/login" index element={<LoginPage />} />
									<Route path="/auth" element={<AuthPage />} />
									<Route
										path="/auth/magic_link/:uid/:token"
										element={<MagicLinkAuthorization />}
									/>
									<Route path="/refresh-session" element={<ShortCode />} />
									<Route
										path="/reset_password/:uid/:token"
										element={<NewPassword />}
									/>
									<Route path="/schedule/*" element={<GuestAppointment />} />
								</Route>
								<Route path="/" element={<AuthRequired />}>
									<Route index element={<Polls />} />
									<Route path={"/coffee/*"} element={<GetCoffeePage />} />


									{routes.map((route) => {
										const Element = Pages[route.component];

										const props = {
											key: `route-${route.id}`,
											element: <Element />,
										};

										if (route.isIndex) {
											props.index = true;
										} else {
											props.path = route.route_path;
										}

										return <Route {...props} />;
									})}

									<Route path="/components/*" element={<Components />} />
								</Route>
								{!lastURL && !isWidget && (
									<Route
										path="*"
										element={<Error status={404} message={"not found"} />}
									/>
								)}
							</Route>
							<Route path="/widget">
								<Route
									path="doctor-appointment/:doctorId"
									element={<DoctorAppointmentWidget />}
								/>
							</Route>
						</Routes>
					</Router>
					<SystemNotifications />
				</ErrorBoundary>
			</AuthenticationProvider>
		</SlowInternetConnectionBannerWrapper>
	);
}

export default App;

const Root = ({ isWidget }) => {
	const location = useLocation();
	const [params] = useSearchParams();
	const dispatch = useDispatch();
	const [search] = useSearchParams();
	const invoice = search.get("invoice");

	const onMessage = useCallback(
		() =>
			dispatch({
				type: "profileApi/invalidateTags",
				payload: ["SupportCounters", "SupportIssues", "SupportIssue"],
			}),
		[]
	);

	usePushReceivedMessage(onMessage);

	if (invoice) {
		dispatch(setInvoice({ invoice }));
	}

	useEffect(() => {
		Metrics.hit();
		Metrics.gtmPageView({ url: location.pathname });

		const accessToken = params.get("accessToken");
		const refreshToken = params.get("refreshToken");
		const shortCode = params.get("shortCode");

		if (!!accessToken && !!refreshToken && !!shortCode && !isWidget) {
			localStorage.setItem("accessToken", accessToken);
			localStorage.setItem("refreshToken", refreshToken);
			localStorage.setItem("shortCode", shortCode);
			localStorage.setItem("isGuest", false);
			localStorage.setItem("expired", false);

			dispatch(setShortCode(shortCode));
			dispatch(setExpired(false));
			dispatch(setAuthInfo({ accessToken, refreshToken, isGuest: false }));
			window.location.reload();
		}
	}, [location, params]);

	useLayoutEffect(() => {
		window.onload = function () {
			setTimeout(function () {
				scrollToPosition(0);
			}, 0);
		};

		setTimeout(function () {
			scrollToPosition(0);
		}, 0);
	}, [location, params]);

	return (
		<>
			<Outlet />
		</>
	);
};
