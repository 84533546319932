import styles from "./filter.module.css";
import Button from "../../../../components/button/button";
import { useTranslate } from "../../../../localization/translate";
import FilterFooter from "../../../../components/filter/filter.footer";
import { useState, useEffect } from "react";
import ReactSwitch from "react-switch";

export default function FilterBody({
	filters,
	setFilters,
	onApplyClick,
	found,
	entries,
	filerResults,
	setExpanded,
	isDisabled,
	onChange,
}) {
	const translate = useTranslate();
	const [innerFilters, setInnerFilters] = useState(filters);

	useEffect(() => {
		setInnerFilters(filters);
	}, [filters]);

	useEffect(() => {
		onChange(innerFilters);
	}, [innerFilters]);

	return (
		<>
			<div className={styles.gender}>
				<div className={styles.title}>
					{translate("appointment.filters.doctorsGender")}
				</div>
				<div className={styles.items}>
					{innerFilters.gender.list.map((item) => (
						<Button
							onClick={(e) => {
								setInnerFilters({
									...innerFilters,
									gender: {
										...innerFilters.gender,
										value: item,
									},
								});
							}}
							key={`gender-${item}`}
							secondary={item !== innerFilters.gender.value}
						>
							{translate(
								`appointment.filters.${item ? item.toLowerCase() : "all"}`
							)}
						</Button>
					))}
				</div>
			</div>
			<div className={styles.experience_rating}>
				<div className={styles.title}>
					{translate("appointment.filters.experienceAndRating")}
				</div>
				<div className={styles.str}>
					<div className={styles.text}>
						<div>{translate("appointment.filters.withExperience")}</div>
						<div>{translate("appointment.filters.overTenYears")}</div>
					</div>
					<div>
						<ReactSwitch
							onChange={(value) => {
								setInnerFilters({
									...innerFilters,
									greatExperience: value,
								});
							}}
							handleDiameter={18}
							offColor="#D4DDE7"
							onColor="#54A0FF"
							height={22}
							width={36}
							uncheckedIcon={false}
							checkedIcon={false}
							checked={innerFilters.greatExperience}
						/>
					</div>
				</div>
				<div className={styles.str}>
					<div className={styles.text}>
						<div>{translate("appointment.filters.withRating")}</div>
						<div>{translate("appointment.filters.overFourYears")}</div>
					</div>
					<div>
						<ReactSwitch
							onChange={(value) => {
								setInnerFilters({
									...innerFilters,
									greatRating: value,
								});
							}}
							handleDiameter={18}
							offColor="#D4DDE7"
							onColor="#54A0FF"
							height={22}
							width={36}
							uncheckedIcon={false}
							checkedIcon={false}
							checked={innerFilters.greatRating}
						/>
					</div>
				</div>
			</div>
			<FilterFooter
				onApplyClick={() => {
					setFilters(innerFilters);
					onApplyClick();
				}}
				isEmptyDisabled
				isDisabled={isDisabled}
				filerResults={filerResults}
				found={found}
				entries={entries}
				setExpanded={setExpanded}
			/>
		</>
	);
}
