import styles from "./history.module.css";
import PatientSelector from "../appointment/modules/patientSelector";
import React from "react";
import { useTranslate } from "../../localization/translate";
import { NavLink, Routes, Route, useLocation } from "react-router-dom";
import classNames from "classnames";
import ServiceCard from "../../components/service-card";
import ReceptionsHistory from "./modules/receptions-history/receptions-history";
import { useMediaQuery } from "beautiful-react-hooks";
import Button from "../../components/button/button";
import Snapshots from "./modules/snapshots/snapshots";
import Assignment from "./modules/assignment/assignment";
import AttachCard from "../profile/modules/share-access/attach-card";
import Footer from "../../components/footer";

export default function History() {
	const translate = useTranslate();
	const location = useLocation();
	const isBigDesktop = useMediaQuery("(min-width: 1440px)");

	return (
		<div className={styles.history}>
			<div className={styles.header}>
				<div className={styles.title}>{translate("history.title")}</div>
				<PatientSelector className={styles.patient_selector} />
				{!isBigDesktop && (
					<div className={styles.mobile_navigation}>
						<NavLink to="/history/receptions">
							<Button
								small
								secondary={location.pathname !== "/history/receptions"}
							>
								{translate("history.navigation.receptions", true)}
							</Button>
						</NavLink>
						<NavLink to="/history/assignment" className={styles.courses}>
							<Button
								small
								secondary={location.pathname !== "/history/assignment"}
							>
								{translate("history.navigation.courses", true)}
							</Button>
						</NavLink>
						<NavLink to="/history/analyzes">
							<Button
								small
								secondary={location.pathname !== "/history/analyzes"}
							>
								{translate("history.navigation.analyzes", true)}
							</Button>
						</NavLink>
						<NavLink to="/history/snapshots">
							<Button
								small
								secondary={location.pathname !== "/history/snapshots"}
							>
								{translate("history.navigation.snapshots", true)}
							</Button>
						</NavLink>
					</div>
				)}
			</div>
			<div className={styles.wrapper}>
				{isBigDesktop && (
					<div className={styles.navigation}>
						<div className={styles.primary}>
							<NavLink
								className={({ isActive }) =>
									classNames(styles.nav_item, {
										[styles.active]: isActive,
									})
								}
								to="/history/receptions"
							>
								<ServiceCard
									icon={"receptions"}
									isActive={location.pathname === "/history/receptions"}
									title={translate("history.navigation.receptions", true)}
									text={translate(
										"history.navigation.receptionsDescriptions",
										true
									)}
									isSmall
								/>
							</NavLink>
							<NavLink
								to="/history/analyzes"
								className={({ isActive }) =>
									classNames(styles.nav_item, {
										[styles.active]: isActive,
									})
								}
							>
								<ServiceCard
									icon={"analyzes"}
									isActive={location.pathname === "/history/analyzes"}
									title={translate("history.navigation.analyzes", true)}
									text={translate(
										"history.navigation.analyzesDescription",
										true
									)}
									isSmall
								/>
							</NavLink>
							<NavLink
								className={({ isActive }) =>
									classNames(styles.nav_item, {
										[styles.active]: isActive,
									})
								}
								to="/history/snapshots"
							>
								<ServiceCard
									icon={"snapshots"}
									isActive={location.pathname === "/history/snapshots"}
									title={translate("history.navigation.snapshots", true)}
									text={translate(
										"history.navigation.snapshotsDescription",
										true
									)}
									isSmall
								/>
							</NavLink>
							<NavLink
								className={({ isActive }) =>
									classNames(styles.nav_item, {
										[styles.active]: isActive,
									})
								}
								to="/history/assignment"
							>
								<ServiceCard
									isLast
									isSmall
									icon={"courses"}
									isActive={location.pathname === "/history/assignment"}
									title={translate("history.navigation.courses", true)}
									text={translate(
										"history.navigation.coursesDescription",
										true
									)}
								/>
							</NavLink>
						</div>
					</div>
				)}
				<div className={styles.content}>
					<Routes>
						<Route
							path="/receptions"
							element={<ReceptionsHistory type={"protocol"} />}
						/>
						<Route
							path="/analyzes"
							element={<ReceptionsHistory type={"test"} />}
						/>
						<Route path="/snapshots" element={<Snapshots />} />
						<Route path="/assignment" element={<Assignment />} />
					</Routes>
				</div>
			</div>
			<Footer isInApp />
			<AttachCard />
		</div>
	);
}
