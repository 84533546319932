import styles from "./filter.module.css";
import Button from "../button/button";
import classNames from "classnames";
import { useTranslate } from "../../localization/translate";
import { useDeclension } from "../../utils/declension";

export default function FilterFooter({
	onApplyClick,
	filerResults,
	setExpanded,
	found,
	entries,
	isDisabled = false,
	isEmptyDisabled,
}) {
	const translate = useTranslate();
	const resultsCount = useDeclension(filerResults, entries);
	const foundCount = useDeclension(filerResults, found);

	return (
		<div className={styles.footer}>
			<Button
				disabled={isDisabled || (isEmptyDisabled ? false : filerResults === 0)}
				onClick={() => {
					onApplyClick && onApplyClick();
					setExpanded(false);
				}}
			>
				{translate("app.apply")}
			</Button>
			<div
				className={classNames(styles.results, {
					[styles.no_results]: filerResults === 0,
				})}
			>
				{filerResults === 0
					? translate("appointment.filters.noMatch")
					: `${foundCount} ${filerResults} ${resultsCount}`}
			</div>
		</div>
	);
}
