import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslate } from "../../../../localization/translate";
import Loader from "../../../../components/loader";
import NotificationBlock from "../notification-block";
import {
	useDeleteNotificationsMutation,
	useGetNotificationByIdQuery,
	useReadNotificationsInvalidateMutation,
} from "../../services/notifications.api";
import styles from "./style.module.css";

export default function NotificationDetails() {
	const translate = useTranslate();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();

	const { data, isLoading } = useGetNotificationByIdQuery(id);
	const [read] = useReadNotificationsInvalidateMutation();
	const [del] = useDeleteNotificationsMutation();

	const handleRead = async (ids) => {
		const { data } = await read(ids);

		if (data) {
			dispatch({
				type: "profileApi/invalidateTags",
				payload: ["Profile"],
			});
		}
	};

	const handleDelete = async (ids) => {
		const { data } = await del(ids);

		if (data) {
			navigate("/notifications");

			dispatch({
				type: "profileApi/invalidateTags",
				payload: ["Profile"],
			});
		}
	};

	return (
		<div className={styles.details}>
			<div className={styles.title}>{translate("notifications.title")}</div>

			{data && (
				<NotificationBlock
					notification={data}
					onRead={handleRead}
					onDelete={handleDelete}
				/>
			)}

			{isLoading && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}
		</div>
	);
}
