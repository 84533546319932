import styles from "./layout.module.css";
import AppMenu from "../../features/app-menu/app-menu";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import {
	selectResetPassword,
	selectExpired,
	setResetPasswordInfo,
} from "../../features/login/services/login.slice";
import Banners from "../../features/banners/banners";
import ReceivedBills from "../../features/received-bills/received-bills";
import { useDispatch, useSelector } from "react-redux";
import backIcon from "../../icons/back-icon.svg";
import forwardIcon from "../../icons/forward-icon.svg";
import { isIOSApp, isAndroidApp } from "../../utils";
import classNames from "classnames";

export default function Layout() {
	const passwordResetInfo = useSelector(selectResetPassword);
	const expired = useSelector(selectExpired);
	const dispatch = useDispatch();

	useEffect(() => {
		if (passwordResetInfo) {
			//Если пользователь авторизован, а в redux store осталась информация для восставновления пароля
			// ее необходимо удалить, чтобы при логауте не было повторно показано окно востановления пароля
			dispatch(setResetPasswordInfo(null));
		}
	}, [passwordResetInfo]);

	return (
		<div
			className={classNames(styles.layout, {
				[styles.mobile_application]: isIOSApp || isAndroidApp,
			})}
		>
			<div className={styles.menu}>
				<AppMenu />
				{(isIOSApp || isAndroidApp) && (
					<div className={styles.mobile_navigation}>
						<img src={backIcon} alt="" onClick={() => window.history.back()} />
						<img
							src={forwardIcon}
							alt=""
							onClick={() => window.history.forward()}
						/>
					</div>
				)}
			</div>
			<div className={styles.wrapper}>
				<div className={styles.container}>
					<div className={styles.banners}>
						<Banners />
					</div>

					<Outlet />

					{!expired && <ReceivedBills />}
				</div>
			</div>
		</div>
	);
}
