import { useTranslate } from "../../../../../../../../localization/translate";
import ListItemRow from "../../../../../../../../components/list-item-row";
import styles from "./style.module.css";

const SearchResults = ({ results, onClickItem }) => {
	const translate = useTranslate();

	return (
		<div className={styles.results}>
			{results?.programs?.length > 0 && (
				<div className={styles.serach_result}>
					<div className={styles.title}>
						{translate("profile.programs.programsLabel")}
					</div>

					{results.programs.map((item) => (
						<ListItemRow
							key={item.id}
							title={item.name}
							showArrow={false}
							onClick={() => onClickItem(item)}
						/>
					))}
				</div>
			)}

			{results?.services?.length > 0 && (
				<div className={styles.serach_result}>
					<div className={styles.title}>
						{translate("profile.programs.servicesLabel")}
					</div>

					{results.services.map((item) => (
						<ListItemRow
							key={`${item.name}-${item.program.id}`}
							title={item.name}
							showArrow={false}
							onClick={() => onClickItem(item)}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default SearchResults;
