export const config = {
	apiKey: "AIzaSyB9KyMTL5zf2dJOl_hOqo-_v1DbBIWpx6E",
	authDomain: "new2022-b88b5.firebaseapp.com",
	projectId: "new2022-b88b5",
	storageBucket: "new2022-b88b5.appspot.com",
	messagingSenderId: "618667261275",
	appId: "1:618667261275:web:f28fbcbbf1783fe54f2e7e",
	measurementId: "G-62ECVT6VK2",
};

export const vapidKey =
	"BEg_Tyru-cmxd4p6k7aJISPhVtYbGsXNslqa77ARIKp9xq12yFbXJioa4YKGUbrPpjTO6KKXmxeLl9yVHpGz5mw";

export const FOREGROUND_MSG_SW_PATH = "foreground-msg-sw.js";
