import { useState, useEffect, useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import { loadBaseUrl } from "../../../../app/config.api";
import Loader from "../../../../components/loader";
import Results from "../Results";
import {
	useGetPaymentWidgetMutation,
	useInvoiceResultMutation,
} from "../../services/payments.api";
import { TAGS_TO_INVALIDATE } from "../../helpers/constants";
import styles from "./style.module.css";
import PaymentContext from "../../contexts/payment-context";
import { Metrics } from "../../../../utils/metrics";

const resultUrls = {
	successUrl: `${loadBaseUrl()}/payment-result`,
	failUrl: `${loadBaseUrl()}/payment-result`,
};

export default function PaymentWidget({ purposeId, invoiceNumber }) {
	const ref = useRef();
	const dispatch = useDispatch();
	const [paymentWidgetUrl, setPaymentWidgetUrl] = useState(null);

	const {
		payment: { checkout },
	} = useContext(PaymentContext);

	const [getPaymentWidget, { isLoading: isLoadingWidget, error }] =
		useGetPaymentWidgetMutation();
	const [sendInvoiceResult] = useInvoiceResultMutation();

	const prepare = async () => {
		const { data: paymentUrl } = await getPaymentWidget({
			invoiceNumber,
			...resultUrls,
		});

		setPaymentWidgetUrl(paymentUrl);
	};

	useEffect(() => {
		prepare();

		return () =>
			sendInvoiceResult({
				invoiceNumber,
				result: "unknown",
			}).then(() => {
				TAGS_TO_INVALIDATE.forEach((item) => dispatch(item));
				Metrics.gtmEvent("complete_order_payment", {
					orderId: purposeId,
					total_sum: checkout.amount,
					payment_sum: checkout.discounted ?? checkout.amount,
					discount: checkout.discounted
						? checkout.amount - checkout.discounted
						: 0,
					payment_method: checkout.paymentMethod?.name,
				});
			});
	}, []);

	return (
		<>
			{isLoadingWidget && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}

			{paymentWidgetUrl && (
				<div ref={ref} className={styles.url}>
					<iframe
						src={paymentWidgetUrl}
						frameBorder="0"
						className={styles.iframe}
						height={ref.current?.getBoundingClientRect().height}
					/>
				</div>
			)}

			{error && <Results type="fail" />}
		</>
	);
}
