import { isAdditional, isAdditionalQuestion } from "./answers";

export const buildAdditionalQuestion = (question, answer) => {
	const { id: questionId, description } = question;
	const { titleAdditional, additionalAnswers } = answer;

	return {
		id: questionId,
		description,
		title: titleAdditional,
		answers: additionalAnswers,
		isAdditional: true,
	};
};

export const getQuestionIndex = (questions, question) =>
	questions.findIndex(({ id }) => question.id === id);

export const getUserAnswer = ({ answers }, answersUser) =>
	answers?.find(({ id }) => answersUser?.find(({ answer }) => answer === id));

export const getAdditionalAnswer = (answer, answersUser) =>
	answer?.additionalAnswers?.find(({ id }) =>
		answersUser?.find(({ answer: answerId }) => answerId === id)
	);

export const getParentQuestion = (questions, question) =>
	question.isAdditional ? questions.find(({ id }) => question.id === id) : null;

export const getCurrectQuestionWithAnswer = (questions, answersUser) => {
	for (const question of questions) {
		const userAnswer = getUserAnswer(question, answersUser);
		const additionalAnswer = getAdditionalAnswer(userAnswer, answersUser);

		if (!userAnswer || (isAdditional(userAnswer) && !additionalAnswer))
			return { question, answer: userAnswer };
	}

	return { question: null, anser: null };
};

export const associateQuestions = (questions, answersUser) => {
	const qsts = [];

	for (const question of questions) {
		const userAnswer = getUserAnswer(question, answersUser);
		const additionalAnswer = getAdditionalAnswer(userAnswer, answersUser);

		const value =
			answersUser.find(({ answer }) => answer === userAnswer?.id)?.value ??
			null;

		const additionalValue =
			answersUser.find(({ answer }) => answer === additionalAnswer?.id)
				?.value ?? null;

		const isAdditionalQst = userAnswer
			? isAdditionalQuestion(userAnswer)
			: false;

		qsts.push({
			...question,
			answer: { ...userAnswer, value },
			additional:
				userAnswer && !isAdditionalQst
					? { ...additionalAnswer, value: additionalValue }
					: null,
		});

		if (isAdditionalQst)
			qsts.push({
				...buildAdditionalQuestion(question, userAnswer),
				answer: { ...additionalAnswer, value: additionalValue },
				additional: null,
			});
	}

	return qsts;
};
