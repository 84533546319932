import { createSlice } from "@reduxjs/toolkit";
import Crypto from "../../../utils/crypto";
import jwt_decode from "jwt-decode";

const initialState = {
	anonLoginAttempts: 0,
	status: "idle",
	needRefresh: false,
	loginMethod: "byPhone",
	// loginMethod: "byPhone",
	lastActivity: localStorage.getItem("lastActivity") || null,
	// expiredActiveTime false если пользователь не был активен долгое время
	expiredActiveTime: localStorage.getItem("expired") === "true",
	// login - Сохраняется тут для того, чтобы набраный логин можно было подставить в <RequestMagicLink/>
	login: "",
	//requestLinkIsOpen - Необходимо для того, чтобы скрывать ошибку валидации на форме с логином/email,
	// когда открыто окно <RequestMagicLink/>
	requestLinkIsOpen: false,
	invalidMagicLink: false,
	accessToken: localStorage.getItem("accessToken"),
	refreshToken: localStorage.getItem("refreshToken"),
	isGuest: localStorage.getItem("isGuest") === "true",
	lastUrl: localStorage.getItem("lastURL"),
	shortCode: localStorage.getItem("shortCode"),
	resetPassword: null,

	isBiometryAllowed: localStorage.getItem("isBiometryAllowed") === "true",
	isBiometryAsked: localStorage.getItem("isBiometryAsked") === "true",

	isNoRatingAppointmentVisible: !!localStorage.getItem(
		"isNoRatingAppointmentVisible"
	)
		? localStorage.getItem("isNoRatingAppointmentVisible") === "true"
		: true,
};

export const loginSlice = createSlice({
	name: "authentication",
	initialState,
	reducers: {
		setBiometryAllowed: (state, { payload }) => {
			state.isBiometryAllowed = !!payload;
			localStorage.setItem("isBiometryAllowed", payload.toString());
		},

		setBiometryAsked: (state, { payload }) => {
			state.isBiometryAsked = !!payload;
			localStorage.setItem("isBiometryAsked", payload.toString());
		},

		setNoRatingAppointmentVisible: (state, { payload }) => {
			state.isNoRatingAppointmentVisible = !!payload;
			localStorage.setItem("isNoRatingAppointmentVisible", payload.toString());
		},

		setAnonLoginAttempts: (state) => {
			state.anonLoginAttempts += 1;
		},
		setNeedRefresh: (state, action) => {
			state.needRefresh = action.payload;
		},
		setLoginMethod: (state, action) => {
			state.loginMethod = action.payload;
		},
		updateLastActivity: (state) => {
			const timeNow = new Date().getTime();
			const ciphertext = Crypto.encrypt(`${timeNow}`);
			state.lastActivity = ciphertext;
			localStorage.setItem("lastActivity", ciphertext);
		},
		clearLastActivity: (state) => {
			state.lastActivity = null;
			localStorage.removeItem("lastActivity");
		},
		setExpired: (state, action) => {
			console.log("set expired", action.payload)
			state.expiredActiveTime = action.payload;
			localStorage.setItem("expired", action.payload);
		},
		setLastUrl: (state, action) => {
			state.lastUrl = action.payload;

			if (action.payload) {
				localStorage.setItem("lastURL", action.payload);
			} else {
				localStorage.removeItem("lastURL");
			}
		},
		setLogin: (state, action) => {
			state.login = action.payload;
		},
		setRequestLinkIsOpen: (state, action) => {
			state.requestLinkIsOpen = action.payload;
		},
		resetLogin: (state) => {
			state.login = initialState.login;
		},
		setInvalidMagicLink: (state, action) => {
			state.invalidMagicLink = action.payload;
		},
		setAuthInfo: (state, action) => {
			/*
			 * Устанавливается access (& refresh) token при успешной авторзиции
			 * если action.payload == false - значит это операция logout
			 * */
			if (action.payload) {
				const { accessToken, refreshToken, isGuest } = action.payload;

				if (accessToken) {
					state.accessToken = accessToken;
					state.isGuest = !!jwt_decode(accessToken)?.guest;
				}
				if (refreshToken) {
					state.refreshToken = refreshToken;
					// localStorage.setItem("refreshToken", refreshToken);
				}
				if (typeof isGuest !== "undefined") {
					state.isGuest = isGuest;
					// localStorage.setItem("isGuest", isGuest);
				}
			} else {
				localStorage.removeItem("accessToken");
				localStorage.removeItem("refreshToken");
				localStorage.removeItem("shortCode");
				localStorage.removeItem("isGuest");
				localStorage.removeItem("isBiometryAllowed");
				localStorage.removeItem("isBiometryAsked");
				localStorage.removeItem("isNoRatingAppointmentVisible");
				state.accessToken = null;
				state.refreshToken = null;
				state.shortCode = null;
				state.isGuest = false;
				state.isBiometryAllowed = false;
				state.isBiometryAsked = false;
			}
		},
		setAuthInfoToLS: (state) => {
			if (state.accessToken) {
				localStorage.setItem("accessToken", state.accessToken);
			} else {
				localStorage.removeItem("accessToken");
			}

			if (state.refreshToken) {
				localStorage.setItem("refreshToken", state.refreshToken);
			} else {
				localStorage.removeItem("refreshToken");
			}

			if (state.isGuest === true || state.isGuest === false) {
				localStorage.setItem("isGuest", state.isGuest);
			} else {
				localStorage.removeItem("isGuest");
			}
		},
		setShortCode: (state, action) => {
			if (action.payload) {
				localStorage.setItem("shortCode", action.payload);
			} else {
				localStorage.removeItem("shortCode");
			}
			state.shortCode = action.payload;
			window.dispatchEvent(new Event("storage"));
		},
		setGuestMode: (state, action) => {
			state.isGuest = action.payload;
		},
		setResetPasswordInfo: (state, action) => {
			state.resetPassword = action.payload;
		},
	},
});

export const {
	setExpired,
	setAuthInfo,
	setAuthInfoToLS,
	updateLastActivity,
	setLoginMethod,
	setAnonLoginAttempts,
	setNeedRefresh,
	setLogin,
	setInvalidMagicLink,
	resetLogin,
	setRequestLinkIsOpen,
	setLastUrl,
	clearLastActivity,
	setShortCode,
	setGuestMode,
	setResetPasswordInfo,
	setBiometryAllowed,
	setBiometryAsked,
	setNoRatingAppointmentVisible,
} = loginSlice.actions;

export const selectBiometryAllowed = (state) =>
	state.authentication.isBiometryAllowed;
export const selectBiometryAsked = (state) =>
	state.authentication.isBiometryAsked;

export const selectNoRatingAppointmentVisible = (state) =>
	state.authentication.isNoRatingAppointmentVisible;

export const selectAnonLoginAttempts = (state) =>
	state.authentication.anonLoginAttempts;
export const selectNeedRefresh = (state) => state.authentication.needRefresh;
export const selectLoginMethod = (state) => state.authentication.loginMethod;
export const selectLastActivity = (state) =>
	state.authentication.lastActivity
		? Number(Crypto.decrypt(state.authentication.lastActivity))
		: null;
export const selectExpired = (state) => state.authentication.expiredActiveTime;
export const selectLogin = (state) => state.authentication.login;
export const selectRequestLinkIsOpen = (state) =>
	state.authentication.requestLinkIsOpen;
export const selectInvalidMagicLinkFlag = (state) =>
	state.authentication.invalidMagicLink;
export const selectLastUrl = (state) => state.authentication.lastUrl;
export const selectRefreshToken = (state) => state.authentication.refreshToken;
export const selectAccessToken = (state) => state.authentication.accessToken;
export const selectShortCode = (state) => state.authentication.shortCode;
export const selectIsGuestMode = (state) => state.authentication.isGuest;
export const selectResetPassword = (state) =>
	state.authentication.resetPassword;

export default loginSlice.reducer;
