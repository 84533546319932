import React, { useMemo } from "react";
import Button from "../../../../../../components/button/button";
import { useTranslate } from "../../../../../../localization/translate";
import { RadioButton } from "../Inputs";
import styles from "./styles.module.css";

export const QuestionResult = ({
	question,
	answer,
	additional,
	onUpdate = () => {},
}) => {
	const translate = useTranslate();

	const label = useMemo(
		() =>
			`${answer?.title} ${answer?.value ? `(${answer.value})` : ""} ${
				additional?.title ? `(${additional.title})` : ""
			} ${additional?.value ? `(${additional.value})` : ""}`,
		[answer, additional]
	);

	return (
		<div className={styles.question_result}>
			<div className={styles.title}>
				<span>{question}</span>
			</div>

			<div className={styles.answer}>
				<RadioButton value={answer?.id || ""} label={label} isActive />

				<Button small light className={styles.updateAnswer} onClick={onUpdate}>
					{translate("polls.results.updateAnswer")}
				</Button>
			</div>
		</div>
	);
};
