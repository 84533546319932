import { useState } from "react";
import Input from "../../../components/input";

const wrapper = (children) => <div style={{ margin: 20 }}>{children}</div>;

export default () => {
	const [checkbox, setCheck] = useState(true);

	return (
		<>
			{wrapper(
				<Input
					type="checkbox"
					checked={checkbox}
					onChange={(val) => setCheck(val)}
				/>
			)}
			{wrapper(<Input type="checkbox" />)}
		</>
	);
};
