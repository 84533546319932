import styles from "./datepicker.module.css";
import { useMediaQuery } from "beautiful-react-hooks";
import classNames from "classnames";
import moment from "moment";
import Button from "../button/button";
import { useTranslate } from "../../localization/translate";
import Dropdown from "../dropdown/dropdown";
import { useEffect, useState } from "react";

moment().locale("ru");
const DROPDOWN_YEARS_LENGTH = 3;

export default function DatePicker({
	openState,
	calendarClassName,
	maxDate,
	footerClassName = "",
	markedDates, // Массив дней, которые надо пометить синей точкой. Передавать  в формате "YYYY-MM-DD"
	isHolidayActive = false,
	setDateRange: externalSetDateRange,
	dateRange: externalDateRange = [null, null],
	oneMonth = false,
}) {
	const current = moment();
	const isMobile = useMediaQuery("(max-width: 620px)");
	const momentMaxDate = maxDate
		? moment.isMoment(maxDate)
			? maxDate
			: moment(maxDate)
		: null;
	const translate = useTranslate();
	const [yearsExpanded, setExpanded] = useState(false);
	const calculateMonth = () => {
		let month;
		if (momentMaxDate) {
			const diff = momentMaxDate.diff(moment(), "months");
			month = moment().add(diff > 2 ? 1 : diff, "month");
		} else {
			month = moment().add(1, "month");
		}

		return oneMonth ? month.add(-1, "month") : month;
	};
	const [rightMonth, setRightMonth] = useState(calculateMonth());
	const [leftMonth, setLeftMonth] = useState();
	const [dateRange, setDateRange] = useState(externalDateRange);
	const [rangeStart, rangeEnd] = dateRange;

	useEffect(() => {
		setDateRange(externalDateRange);
		if (rightMonth && externalDateRange[1]) {
			const newDate = moment(rightMonth).set(
				"month",
				externalDateRange[1].month()
			);
			newDate.set("year", externalDateRange[1].year());
			setRightMonth(newDate);
		}
	}, [externalDateRange]);

	useEffect(() => {
		if (!openState) {
			// setDateRange([null, null]);
		} else {
			if (rightMonth && externalDateRange[1]) {
				const newDate = moment(rightMonth).set(
					"month",
					externalDateRange[1].month()
				);
				newDate.set("year", externalDateRange[1].year());
				setRightMonth(newDate);
			}
			setDateRange(externalDateRange);
		}
	}, [openState]);

	useEffect(() => {
		const calculated = calculateMonth();
		if (calculated.format("YYYY-MM-DD") !== rightMonth.format("YYYY-MM-DD")) {
			setRightMonth(calculated);
		}
	}, [maxDate]);

	useEffect(() => {
		const newLeftMonth = moment(rightMonth).add(-1, "month");
		if (
			!leftMonth ||
			leftMonth.format("YYYY-MM-DD") !== newLeftMonth.format("YYYY-MM-DD")
		) {
			setLeftMonth(newLeftMonth);
		}
	}, [rightMonth]);

	if (!leftMonth) {
		return null;
	}

	const years = (function () {
		const res = [moment().format("YYYY")];
		for (let i = 0; i < DROPDOWN_YEARS_LENGTH; i++) {
			res.push(res[res.length - 1] - 1);
		}

		return res;
	})();
	const selectedYear = (function () {
		const rightYear = rightMonth.format("YYYY");
		const leftYear = leftMonth.format("YYYY");
		if (rightYear === leftYear) {
			return rightYear;
		} else {
			return leftYear + " - " + rightYear;
		}
	})();
	const onArrowControlClick = (direction) => {
		if (direction > 0 && momentMaxDate) {
			const temp = moment(rightMonth);

			if (temp.isBefore(momentMaxDate)) {
				temp.add(1, "month").date(1);
			}
		}

		setRightMonth(moment(rightMonth).add(direction, "months"));
	};
	const onDayClick = (day) => {
		if ((!rangeStart && !rangeEnd) || (rangeEnd && rangeStart)) {
			setDateRange([day, null]);
		}
		if (rangeStart && !rangeEnd) {
			if (day.isSame(rangeStart)) {
				setDateRange([null, null]);
			}
			if (day.isBefore(rangeStart)) {
				setDateRange([day, rangeStart]);
			}
			if (day.isAfter(rangeStart)) {
				setDateRange([rangeStart, day]);
			}
		}
	};
	const onApplyClick = () => externalSetDateRange(dateRange);
	const onResetClick = () => {
		externalSetDateRange([null, null]);
		setDateRange([null, null]);
	};
	const daysProps = {
		today: current,
		onDayClick,
		momentMaxDate,
		markedDates,
		dateRange,
	};

	const canReset = (function () {
		if (
			externalDateRange[0] &&
			externalDateRange[1] &&
			rangeStart &&
			rangeEnd
		) {
			if (
				externalDateRange[0].format("YYYY-MM-DD") ===
					rangeStart.format("YYYY-MM-DD") &&
				externalDateRange[1].format("YYYY-MM-DD") ===
					rangeEnd.format("YYYY-MM-DD")
			) {
				return true;
			}
		}

		return false;
	})();

	return (
		<div className={classNames(styles.calendar, calendarClassName)}>
			<div className={styles.header}>
				<div
					className={styles.control_arrow}
					onClick={() => onArrowControlClick(-1)}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M15.7674 4.22676C16.0775 4.5291 16.0775 5.01929 15.7674 5.32163L8.91717 12L15.7674 18.6784C16.0775 18.9807 16.0775 19.4709 15.7674 19.7732C15.4573 20.0756 14.9545 20.0756 14.6444 19.7732L7.23259 12.5474C6.92247 12.2451 6.92247 11.7549 7.23259 11.4526L14.6444 4.22676C14.9545 3.92441 15.4573 3.92441 15.7674 4.22676Z"
							fill="#7A8CA4"
						/>
					</svg>
				</div>
				<div className={styles.control_selector}>
					<div className={styles.monthes}>
						{leftMonth.format("MMMM")} - {rightMonth.format("MMMM")}
					</div>
					<div className={styles.years}>
						{isMobile ? (
							<span
								onClick={() => setExpanded(!yearsExpanded)}
								className={styles.mobile_year}
							>
								<span>{selectedYear}</span>
								&nbsp;
								{translate("app.year")}
							</span>
						) : (
							<Button small onClick={() => setExpanded(!yearsExpanded)}>
								<span>{selectedYear}</span>
								&nbsp;
								{translate("app.year")}
								<svg
									width="21"
									height="20"
									viewBox="0 0 21 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M3.60596 6.44383C3.85791 6.18539 4.2664 6.18539 4.51835 6.44383L10.0837 12.1524L15.649 6.44383C15.9009 6.18539 16.3094 6.18539 16.5614 6.44383C16.8133 6.70226 16.8133 7.12127 16.5614 7.3797L10.5399 13.5562C10.2879 13.8146 9.87941 13.8146 9.62746 13.5562L3.60596 7.3797C3.354 7.12127 3.354 6.70226 3.60596 6.44383Z"
										fill="white"
									/>
								</svg>
							</Button>
						)}
						<Dropdown
							expanded={yearsExpanded}
							setExpanded={setExpanded}
							className={styles.years_dropdown}
							closeBtn
							title={translate("app.year", true)}
						>
							{years.map((year) => (
								<div
									className={styles.item}
									key={`'year-${year}`}
									onClick={() => {
										const newDate = moment(rightMonth).set("year", year);
										setRightMonth(newDate);
									}}
								>
									<span>{year}</span>
									{rightMonth.year() === parseInt(year) && (
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M19.8173 6.19433C20.059 6.45547 20.0611 6.88121 19.8222 7.14525L9.2727 18.8004C9.15711 18.9281 8.99955 19 8.83517 19C8.67078 19 8.51322 18.9281 8.39762 18.8004L4.17784 14.1384C3.93885 13.8743 3.94101 13.4486 4.18265 13.1874C4.4243 12.9263 4.81393 12.9286 5.05292 13.1927L8.83517 17.3714L18.9471 6.19958C19.1861 5.93554 19.5757 5.93319 19.8173 6.19433Z"
												fill="#7A8CA4"
											/>
										</svg>
									)}
								</div>
							))}
						</Dropdown>
					</div>
				</div>
				<div
					className={styles.control_arrow}
					onClick={() => onArrowControlClick(1)}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M7.23259 4.22676C6.92247 4.5291 6.92247 5.01929 7.23259 5.32163L14.0828 12L7.23259 18.6784C6.92247 18.9807 6.92247 19.4709 7.23259 19.7732C7.54271 20.0756 8.04552 20.0756 8.35564 19.7732L15.7674 12.5474C16.0775 12.2451 16.0775 11.7549 15.7674 11.4526L8.35564 4.22676C8.04552 3.92441 7.54271 3.92441 7.23259 4.22676Z"
							fill="#7A8CA4"
						/>
					</svg>
				</div>
			</div>
			<div className={styles.body}>
				{!oneMonth && (
					<div className={styles.month}>
						<div className={styles.month_wrap}>
							<div className={styles.title}>{leftMonth.format("MMMM")}</div>
							<DaysWeek isHolidayActive={isHolidayActive} />
							<Days
								{...daysProps}
								month={leftMonth}
								isHolidayActive={isHolidayActive}
								position={"left"}
							/>
						</div>
					</div>
				)}
				<div className={styles.month}>
					<div className={styles.month_wrap}>
						<div className={styles.title}>{rightMonth.format("MMMM")}</div>
						<DaysWeek isHolidayActive={isHolidayActive} />
						<Days
							{...daysProps}
							month={rightMonth}
							isHolidayActive={isHolidayActive}
							position={"right"}
						/>
					</div>
				</div>
			</div>
			<div className={classNames(styles.footer, footerClassName)}>
				<Button
					onClick={canReset ? onResetClick : onApplyClick}
					disabled={!rangeEnd || !rangeStart}
					className={styles.button}
				>
					{canReset ? (
						translate("app.clear")
					) : (
						<>
							{translate("app.select")}
							{rangeEnd &&
								rangeStart &&
								(isMobile ? (
									<span>
										: {rangeStart.format("DD.MM")} - {rangeEnd.format("DD.MM")}
									</span>
								) : (
									<span>
										: {rangeStart.format("DD MMMM")} -{" "}
										{rangeEnd.format("DD MMMM")}
									</span>
								))}
						</>
					)}
				</Button>
			</div>
		</div>
	);
}

const Days = ({
	dateRange,
	month,
	position,
	today,
	momentMaxDate,
	markedDates,
	isHolidayActive = false,
	onDayClick,
}) => {
	const days = [];
	const [start, end] = dateRange;
	const length = month.daysInMonth();
	const currentYear = month.year();
	const currentMonth = month.month() + 1;

	for (let i = 0; i < length; i++) {
		days.push(moment(`${currentYear}-${currentMonth}-${i + 1}`, "YYYY-MM-DD"));
	}

	let diff;
	let tail;
	const startOfWeek = moment(
		`${currentYear}-${currentMonth}-01`,
		"YYYY-MM-DD"
	).day();
	const endOfWeek = moment(
		`${currentYear}-${currentMonth}-${month.daysInMonth()}`,
		"YYYY-MM-DD"
	).day();
	diff = (startOfWeek === 0 ? 7 : startOfWeek) - 1;
	if (position === "right") {
		tail = endOfWeek === 7 ? 0 : 7 - endOfWeek;
	}

	if (diff > 0) {
		for (let i = 0; i < diff; i++) {
			const date = moment(days[0]);
			days.unshift(date.add(-1, "days"));
		}
	}

	if (tail > 0) {
		for (let i = 0; i < tail; i++) {
			const date = moment(days[days.length - 1]);
			days.push(date.add(1, "days"));
		}
	}

	return (
		<div className={styles.days}>
			{days.map((day) => {
				const isToday = day.format("YYYY-MM-DD") === today.format("YYYY-MM-DD");
				const isDayOff = isHolidayActive
					? false
					: day.day() === 6 || day.day() === 0;
				const inactive =
					day.month() !== currentMonth - 1 ||
					(momentMaxDate && day.isAfter(momentMaxDate));
				const selected = day.isSame(start) || day.isSame(end);
				const inRange = start && end && day.isAfter(start) && day.isBefore(end);

				return (
					<div
						className={classNames(styles.day, {
							[styles.today]: isToday,
							[styles.day_off]: isDayOff,
							[styles.inactive]: inactive,
							[styles.marked]:
								markedDates && markedDates.includes(day.format("YYYY-MM-DD")),
							[styles.selected]: selected,
							[styles.in_range]: inRange,
						})}
						key={Math.random()}
					>
						<span onClick={() => !isDayOff && !inactive && onDayClick(day)}>
							{day.format("D")}
						</span>
					</div>
				);
			})}
		</div>
	);
};

const DaysWeek = ({ isHolidayActive = false }) => {
	const translate = useTranslate();

	return (
		<div className={styles.week_days}>
			{translate("app.daysArray", true).map((item, i) => (
				<div
					className={classNames(styles.day_week, {
						[styles.day_off]: isHolidayActive ? false : i > 4,
					})}
					key={item}
				>
					<span>{item}</span>
				</div>
			))}
		</div>
	);
};
