import { useMemo, useState } from "react";
import { useTranslate } from "../../../../../../../../localization/translate";
import Button from "../../../../../../../../components/button/button";
import ListItemRow from "../../../../../../../../components/list-item-row";
import styles from "./style.module.css";

const SearchHistory = ({ history, onClickItem, onClear }) => {
	const translate = useTranslate();
	const [historyExpanded, setHistoryExpanded] = useState(false);

	const historyList = useMemo(
		() => history.slice(0, historyExpanded ? history.length : 4),
		[history, historyExpanded]
	);

	const historyTooLong = useMemo(() => history.length > 4, [history]);

	const togleHistoryExpanded = () => setHistoryExpanded((prev) => !prev);

	return (
		<div className={styles.history}>
			<div className={styles.header}>
				<div className={styles.title}>
					{translate("profile.programs.searchHistory")}
				</div>

				<Button small light className={styles.clear_btn} onClick={onClear}>
					{translate("app.clear")}
				</Button>
			</div>

			<div className={styles.history_list}>
				{historyList.map((item) => (
					<ListItemRow
						key={`${item.id}-${item.name}`}
						title={item.name}
						showArrow={false}
						onClick={() => onClickItem(item)}
					/>
				))}
			</div>

			{historyTooLong && (
				<Button
					small
					light
					className={styles.show_more_btn}
					onClick={togleHistoryExpanded}
				>
					{historyExpanded
						? translate("app.showLess", true)
						: translate("app.showMore", true)}
				</Button>
			)}
		</div>
	);
};

export default SearchHistory;
