import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getInvoice } from "./services/received-bills.slice";
import { resetInvoice } from "./services/received-bills.slice";
import BillModal from "./modules/BillModal";

export default function ReceivedBills() {
	const dispatch = useDispatch();
	const [search, setSearch] = useSearchParams();
	const invoice = useSelector(getInvoice);

	const handleClose = () => {
		dispatch(resetInvoice());

		if (search.has("invoice")) {
			search.delete("invoice");
			setSearch(search);
		}
	};

	if (!invoice) return null;

	return <BillModal invoice={invoice} onClose={handleClose} />;
}
