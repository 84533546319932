import { useEffect, useMemo, useState } from "react";
import { StartScreens } from "../helpers/constants";
import { EmptyScreen } from "../modules";

const getStartScreen = (type) =>
	Reflect.has(StartScreens, type) ? StartScreens[type] : EmptyScreen;

export default function useNavigationStack(type) {
	const [stack, setStack] = useState(() => [getStartScreen(type)]);

	useEffect(() => {
		setStack([getStartScreen(type)]);
	}, [type]);

	const push = (node) => setStack((stack) => [...stack, node]);

	const pop = () => setStack((stack) => stack.slice(0, -1));

	const erase = () => setStack((stack) => stack.slice(0, 1));

	const length = useMemo(() => stack.length, [stack]);

	const Component = useMemo(() => stack[length - 1], [stack]);

	return {
		Component,
		length,
		push,
		pop,
		erase,
	};
}
