import { useEffect, useState, useRef, useMemo } from "react";
import classNames from "classnames";
import styles from "./style.module.css";
import Button from "../button/button";
import { useTranslate } from "../../localization/translate";

export default function Collapser({
	initHeight = 0,
	softPruning = false,
	children,
}) {
	const translate = useTranslate();
	const contentRef = useRef(null);
	const [isOpen, toggleOpen] = useState(false);
	const [contentHeight, setContentHeight] = useState(initHeight);

	const isSimple = useMemo(
		() => initHeight > contentHeight,
		[initHeight, contentHeight]
	);

	const wrapperStyles = useMemo(
		() => ({
			height: `${
				isOpen ? contentHeight : isSimple ? contentHeight : initHeight
			}px`,
		}),
		[isOpen, contentHeight, initHeight]
	);

	const wrapperClasses = useMemo(
		() => classNames(styles.wrapper, { [styles.soft_pruning]: softPruning }),
		[softPruning]
	);

	useEffect(() => {
		updateHeight();
	}, [children]);

	const updateHeight = () => setContentHeight(contentRef.current.scrollHeight);

	return (
		<div className={styles.collapser}>
			<div className={wrapperClasses} style={wrapperStyles}>
				{softPruning && !isOpen && <div className={styles.soft} />}

				<div ref={contentRef} className={styles.content}>
					{children}
				</div>
			</div>

			{!isSimple && (
				<Button
					small
					light
					className={styles.action}
					onClick={() => toggleOpen(!isOpen)}
				>
					{isOpen ? translate("app.showLess") : translate("app.showMore")}
				</Button>
			)}
		</div>
	);
}
