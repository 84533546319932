import list from "./accounts.module.css";
import { useTranslate } from "../../../../localization/translate";
import checkIcon from "../../../../icons/check_icon_blue_18x15.svg";
import SelectorModal from "../../../../components/item-selector/modal-selector";
import { useEffect, useState } from "react";
import css from "./accounts.module.css";
import arrow from "../../../../icons/arrow_down_24x24.svg";
import { useSetDefaultMethodMutation } from "../../services/profile.api";
import { useDispatch } from "react-redux";
import { pushSystemNotification } from "../../../../app/app.slice";

export default function DefaultPaymentMethodSelector({
	isOpen,
	setIsOpen,
	accounts,
	setAccounts,
}) {
	const translate = useTranslate();
	const onRequestClose = () => {
		setIsOpen(false);
	};
	const [setDefault, { data, isLoading, error }] =
		useSetDefaultMethodMutation();
	const onApply = () => {
		const parts = selected.split("^");
		const body = {
			paymentType: parts[2],
			operationId: parts[0],
		};

		setDefault(body);
	};
	const [selected, setSelected] = useState();
	const dispatch = useDispatch();

	useEffect(() => {
		if (error) {
			dispatch(
				pushSystemNotification({
					type: "error",
					clientMessage: (
						<span>
							{translate("app.somethingWhenWrong")}
							<br />
							{translate("app.tryLater")}
						</span>
					),
				})
			);
		}
	}, [error]);

	useEffect(() => {
		if (data) {
			setIsOpen(false);
			setAccounts(data);
		}
	}, [data]);

	useEffect(() => {
		if (accounts && accounts.defaultPaymentMethod) {
			setSelected(
				accounts.defaultPaymentMethod.operationId +
					"^" +
					accounts.defaultPaymentMethod.name +
					"^" +
					accounts.defaultPaymentMethod.type.toLowerCase()
			);
		}
	}, [accounts]);

	return (
		<SelectorModal
			applyBtn={{
				text: translate("app.forms.saveChanges", true),
				onClick: onApply,
				disabled: isLoading,
			}}
			subtitle={translate("profile.bills.chooseNewDefaultMethod")}
			title={translate("profile.bills.defaultMethod")}
			onRequestClose={onRequestClose}
			isOpen={isOpen}
		>
			<div className={list.category}>
				{accounts &&
					accounts.bankCard.map((item) => (
						<div
							className={list.row}
							onClick={() =>
								setSelected(item.id + "^" + item.name + "^" + "card")
							}
							key={`bank-selector-card-${item.name}`}
						>
							<div className={list.info}>
								<div className={list.account}>{item.name}</div>
							</div>
							{selected === item.id + "^" + item.name + "^" + "card" && (
								<div className={list.check}>
									<img src={checkIcon} />
								</div>
							)}
						</div>
					))}
			</div>
			{!!accounts.accounts.filter((account) => !account.isFamily).length && (
				<div className={list.category}>
					<div className={list.category_title}>
						{translate("profile.bills.personalAccounts")}
					</div>
					{accounts.accounts
						.filter((account) => !account.isFamily)
						.map((account) => (
							<div
								className={css.row}
								onClick={() =>
									setSelected(account.id + "^" + account.name + "^" + "account")
								}
								key={`personal-account-selector-${account.id}`}
							>
								<div className={css.info}>
									<div className={css.account}>{account.name}</div>
									<div className={css.account_info}>
										{translate("profile.bills.balance", true)}: &nbsp;
										{parseFloat(account.balance).toLocaleString(undefined, {
											minimumFractionDigits: 2,
										})}
										&nbsp;
										{translate("app.currencySign", true)}
									</div>
								</div>
								{selected ===
									account.id + "^" + account.name + "^" + "account" && (
									<div className={list.check}>
										<img src={checkIcon} />
									</div>
								)}
							</div>
						))}
				</div>
			)}
			{!!accounts.accounts.filter((account) => account.isFamily).length && (
				<div className={list.category}>
					<div className={list.category_title}>
						{translate("profile.bills.sharedAccounts")}
					</div>

					{accounts.accounts
						.filter((account) => account.isFamily)
						.map((account) => (
							<div
								className={css.row}
								onClick={() =>
									setSelected(account.id + "^" + account.name + "^" + "account")
								}
								key={`shared-account-selector-${account.id}`}
							>
								<div className={css.info}>
									<div className={css.account}>{account.name}</div>
									<div className={css.account_info}>
										{translate("profile.bills.balance", true)}: &nbsp;
										{parseFloat(account.balance).toLocaleString(undefined, {
											minimumFractionDigits: 2,
										})}
										&nbsp;
										{translate("app.currencySign", true)}
									</div>
								</div>
								{selected ===
									account.id + "^" + account.name + "^" + "account" && (
									<div className={list.check}>
										<img src={checkIcon} />
									</div>
								)}
							</div>
						))}
				</div>
			)}
		</SelectorModal>
	);
}
