import Button from "../../../button/button";
import styles from "./style.module.css";

import left from "../../../../icons/slide-left.svg";
import right from "../../../../icons/slide-right.svg";

export default ({ disabled, onClick }) => (
	<>
		<Button
			disabled={disabled?.left}
			onClick={onClick?.left}
			className={styles.action}
		>
			<img src={left} />
		</Button>

		<Button
			disabled={disabled?.right}
			onClick={onClick?.right}
			className={styles.action}
		>
			<img src={right} />
		</Button>
	</>
);
