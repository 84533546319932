import { useEffect } from "react";
import { Collapser } from "../../../../../components/collapser/controlled-collapser";
import { Metrics } from "../../../../../utils/metrics";
import styles from "./styles.module.css";

export const GeneralInfo = ({ info }) => {
	useEffect(() => {
		if (info?.length) Metrics.gtmEvent("view_poll_info");
	}, [info]);

	return (
		<div>
			{info?.map(({ id, title, text }) => (
				<Collapser
					key={id}
					title={<span className={styles.title}>{title}</span>}
				>
					<span
						className={styles.text}
						dangerouslySetInnerHTML={{ __html: text }}
					/>
				</Collapser>
			))}
		</div>
	);
};
