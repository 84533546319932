import Button from "../../../components/button/button";

const wrapper = (children) => <div style={{ margin: 20 }}>{children}</div>;

export default () => (
	<>
		{wrapper(<Button light children="Button" />)}
		{wrapper(<Button children="Button" />)}
		{wrapper(<Button children="Button" disabled />)}
	</>
);
