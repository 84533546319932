import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useDebouncedCallback from "beautiful-react-hooks/useDebouncedCallback";
import ScrollTrigger from "../../../../components/scroll-trigger";
import Search from "../search";
import NotificationCard from "../../components/notification-card";
import CheckedActions from "../../components/checked-actions";
import styles from "./style.module.css";

const DEBOUNCE_MS = 500;

export default function NotificationsList({
	collection,
	isTablet,
	hasMore,
	onRead,
	onDelete,
	onSearchNotification,
	selectedNotification,
	onSelectNotification,
	onScrolledEnd,
}) {
	const navigate = useNavigate();

	const [searchValue, setSearchValue] = useState("");
	const [checkedNotifications, setCheckedNotifications] = useState([]);

	const handleCheckNotification = (id) =>
		setCheckedNotifications((prev) =>
			prev.includes(id) ? prev.filter((idx) => idx !== id) : [...prev, id]
		);

	const handleSelectNotification = (notification) => {
		if (isTablet) navigate(`/notifications/${notification.id}`);
		else onSelectNotification(notification);
	};

	const handleDelete = () => {
		onDelete(checkedNotifications);
		setCheckedNotifications([]);
	};

	const handleRead = () => {
		onRead(checkedNotifications);
		setCheckedNotifications([]);
	};

	const debouncedSetSearchValue = useDebouncedCallback(
		onSearchNotification,
		[],
		DEBOUNCE_MS
	);

	useEffect(() => {
		debouncedSetSearchValue(searchValue);
	}, [searchValue]);

	return (
		<div className={styles.notifications_list}>
			<div className={styles.search}>
				<Search value={searchValue} setValue={setSearchValue} />
			</div>

			{checkedNotifications.length > 0 && (
				<div className={styles.checked_actions}>
					<CheckedActions
						count={checkedNotifications.length}
						onRead={handleRead}
						onDelete={handleDelete}
					/>
				</div>
			)}

			<div className={styles.collection}>
				<ScrollTrigger
					showLoader
					hasMore={hasMore}
					onScrolledBottom={onScrolledEnd}
				>
					{collection.map((item) => (
						<NotificationCard
							key={item.id}
							title={item.title}
							category={item.category}
							date={item.receiveDate}
							isRead={item.isRead}
							image={item.image}
							selected={item.id === selectedNotification?.id}
							checked={checkedNotifications.includes(item.id)}
							onCheck={() => handleCheckNotification(item.id)}
							onSelect={() => handleSelectNotification(item)}
						/>
					))}
				</ScrollTrigger>
			</div>
		</div>
	);
}
