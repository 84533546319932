import { useState, useRef } from "react";
import classNames from "classnames";
import { useOnClickOutside } from "../../utils";
import left from "../../icons/slide-left.svg";
import styles from "./style.module.css";

export default function Select({
	value,
	onChange,
	options,
	emptyLabel = "",
	error,
}) {
	const select = useRef(null);
	const [isOptionsOpen, setIsOptionsOpen] = useState(false);

	const toggleOptions = () => {
		setIsOptionsOpen(!isOptionsOpen);
	};

	const setSelectedThenCloseDropdown = (option) => {
		onChange(option);
		setIsOptionsOpen(false);
	};

	const handleClose = () => setIsOptionsOpen(false);

	useOnClickOutside(select, handleClose);

	return (
		<div ref={select} className={styles.select}>
			<button
				type="button"
				aria-haspopup="listbox"
				aria-expanded={isOptionsOpen}
				className={classNames(styles.toggle_btn, {
					[styles.active]: isOptionsOpen,
				})}
				onClick={toggleOptions}
			>
				{value?.label || emptyLabel}

				<img src={left} />
			</button>

			<ul
				className={classNames(styles.options, {
					[styles.active]: isOptionsOpen,
				})}
				role="listbox"
				aria-activedescendant={value?.label}
				tabIndex={-1}
			>
				{options.map((option, index) => (
					<li
						key={option.value}
						id={option.value}
						role="option"
						aria-selected={option.value == value?.value}
						tabIndex={0}
						onClick={() => setSelectedThenCloseDropdown(option)}
						className={styles.option_item}
					>
						{option.label}
					</li>
				))}
			</ul>

			{error && <div className={styles.error}>{error}</div>}
		</div>
	);
}
