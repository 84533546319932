import css from "./share-access.module.css";
import Input from "../../../../components/input";
import Button from "../../../../components/button/button";
import { useTranslate } from "../../../../localization/translate";
import ProfileNotice from "../personala-data/notice";

export default function LinkCard({
	newCardNum,
	setNewCardNum,
	isLoading,
	request,
	cardFieldError,
	noticeText,
}) {
	const translate = useTranslate();

	return (
		<div className={css.modal_body}>
			<div className={css.modal_wrapper}>
				<div className={css.modal_title}>
					{translate("profile.shareAccess.addCard", true)}
				</div>
				<div className={css.modal_description}>
					{translate("profile.shareAccess.addCardDescription")}
				</div>
				<Input
					type="number"
					min="0"
					inputmode="numeric"
					pattern="[0-9]*"
					// isValid={!!cardFieldError}
					value={newCardNum}
					errorMsg={cardFieldError}
					onChange={(val) => {
						setNewCardNum(val.replace(/\D/g, ""));
					}}
					onKeyUp={(e) => {
						const val = e.target.value.replace(/\D/g, "");
						if (e.key === "Enter" && val.length > 0) {
							request(e.target.value.replace(/\D/g, ""));
						}
					}}
					containerClass={css.modal_input}
					placeholder={translate("profile.personalData.medCardNumber", true)}
				/>
				{noticeText && (
					<ProfileNotice
						isGrey
						className={css.modal_notice}
						title={translate("app.information", true)}
						message={noticeText}
					/>
				)}
			</div>
			<Button
				onClick={() => request(newCardNum)}
				disabled={newCardNum.length === 0 || isLoading}
			>
				{translate("app.forms.addBtn")}
			</Button>
		</div>
	);
}
