import { useDispatch, useSelector } from "react-redux";
import CloseModalButton from "../../../../components/modal-items/close";
import Modal from "../../../../components/modal-safari-wrapper/modal";
import { useTranslate } from "../../../../localization/translate";
import styles from "./auth-modal.module.css";
import {
	selectAccessToken,
	selectLoginMethod,
	selectRefreshToken,
	setAuthInfoToLS,
} from "../../../login/services/login.slice";
import LoginSelector from "../../../login/modules/login-selector";
import LoginByPhone from "../../../login/modules/phone-login/phone-login";
import ByLogin from "../../../login/modules/username-login";
import ByEmail from "../../../login/modules/email-login";
import { useEffect, useState } from "react";
import { getProfileByToken, tokenPayload } from "../../../../utils";
import SetupSC from "../../../login/modules/short-code/setup";
import { PersonalDataCreateModal } from "../../../profile/modules/personala-data/personal-data.create";
import Loader from "../../../../components/loader";

const modalStyle = {
	overlay: {
		display: "flex",
		zIndex: 150,
	},
	content: {},
};

export const AuthModal = ({
	isOpen,
	onClose,
	onSuccessAuth,
	isWidget = false,
}) => {
	const t = useTranslate();
	const form = useSelector(selectLoginMethod);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isShowShortCode, setIsShowShortCode] = useState(false);
	const token = useSelector(selectAccessToken);
	const refresh = useSelector(selectRefreshToken);
	const [profile, setProfile] = useState(null);
	const [isCreateProfile, setIsCreateProfile] = useState(false);

	const [isHided, setIsHided] = useState(false);
	const dispatch = useDispatch();

	useEffect(async () => {
		if (!!token) {
			const { guest } = tokenPayload(token);

			if (!guest) {
				setIsSuccess(true);
				dispatch(
					setAuthInfoToLS({
						accessToken: token,
						isGuest: false,
						refreshToken: refresh,
					})
				);
				const profile = await getProfileByToken(token);

				if (profile.medCardNumber) {
					setProfile(profile);
				}

				setIsShowShortCode(true);
			}
		}
	}, [token]);

	const onComplete = () => {
		if (profile) onSuccessAuth(profile.medCardNumber);
		else setIsCreateProfile(true);
	};

	if (isCreateProfile) {
		return (
			<PersonalDataCreateModal
				isOpen
				isPublic
				onCreateProfile={(data) => {
					onSuccessAuth(data.medCardNumber);
					setIsCreateProfile(false);
				}}
				onClose={() => {
					setIsCreateProfile(false);
					setIsShowShortCode(false);
					onClose?.();
				}}
			/>
		);
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			style={{
				...modalStyle,
				overlay: {
					...modalStyle.overlay,
					alignItems: isWidget ? "flex-start" : "center",
				},
			}}
		>
			<CloseModalButton closeModal={onClose} />

			{!isSuccess && (
				<div
					className={styles.content}
					style={{ display: isHided ? "none" : "block" }}
				>
					<h3 className={styles.title}>
						{t(`appointment.auth.title.${form}`)}
					</h3>

					<div className={styles.wrapper}>
						{form === "byPhone" && (
							<LoginByPhone onHide={setIsHided} isWidget={isWidget} />
						)}
						{form === "byLogin" && (
							<ByLogin onHide={setIsHided} isWidget={isWidget} />
						)}
						{form === "byEmail" && (
							<ByEmail onHide={setIsHided} isWidget={isWidget} />
						)}
						<LoginSelector isWidget={isWidget} />
					</div>
				</div>
			)}

			{isSuccess && isShowShortCode && (
				<div className={styles.content}>
					<SetupSC wrap={false} onComplete={onComplete} />
				</div>
			)}

			{isSuccess && !isShowShortCode && (
				<div className={styles.loader}>
					<Loader />
				</div>
			)}
		</Modal>
	);
};
