import React from "react";
import styles from "./styles.module.css";

export const RadioButton = ({
	id = Math.random().toString(16),
	name,
	value,
	label,
	isActive = false,
	onChange,
}) => {
	const onOptionChange = (event) => onChange?.(event.target.value);

	return (
		<div className={styles.radio}>
			<input
				type="radio"
				id={id}
				name={name}
				value={value}
				checked={isActive}
				onChange={onOptionChange}
			/>

			<div
				className={styles.checkbox}
				onClick={() => onChange?.(value.toString())}
			/>

			<label htmlFor={id}>{label}</label>
		</div>
	);
};
