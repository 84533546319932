import React, {useEffect, useState} from "react";
import css from "./coffee.module.css";
import {useTranslate} from "../../../../../localization/translate";
import HowToUse from "../components/scanner/how-to-use";
import { QrReader } from 'react-qr-reader';
import {useNavigate} from "react-router-dom";
import InvalidCode from "../components/scanner/invalid-code";
import {iOS} from "../../../../../utils";
import {Metrics} from "../../../../../utils/metrics";
import {useGetProfileQuery} from "../../../services/profile.api";

const validateScanData = data => {
  const splitter = window.location.href + "/";
  if (data.startsWith(splitter)) {
    const splitted = data.split(splitter);

    if (splitted?.[1]) {
      return !Number.isNaN(Number(splitted[1]))

    } else return false
  } else return false
};

export default function GetCoffee() {
  const beenHereBefore = localStorage.getItem("vendingsUsage");
  const [howToUseOpened, setHowToUseOpened] = useState(beenHereBefore !== "1");
  const t = useTranslate();
  const [error, setError] = useState();
  const [invalidCode, setInvalidCode] = useState();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [ready, setReady] = useState(false);
  const {data: profile} = useGetProfileQuery();

  useEffect(() => {
    document.body.freeze();

    return () => document.body.unfreeze();
  }, []);

  useEffect(() => {
    if (!beenHereBefore) {
      localStorage.setItem("vendingsUsage", 1);
    }
  }, [beenHereBefore]);

  useEffect(() => {
    if (navigator.mediaDevices) {
      const video = document.getElementById(css.video);
      if (video) {
        video.playsInline = true;
        console.log(video)
      }
      navigator.mediaDevices
        .getUserMedia({video: true})
        .then(stream => {
          if (window.isDevMode) {
            console.log("STREAM SUCCESS", stream)
          }
          setReady(true);
          setError(null);
          setTimeout(() => {
            const video = document.getElementById(css.video);

            if (video && !iOS()) {
              video.playsInline = true;
            }
          }, 1000);
        })
        .catch((err) => {
          if (iOS()) {
            if (window.isDevMode) {
              console.log("IOS CAMERA NOT ALLOWED", err)
            }
            setError(t("profile.coffee.cameraNotAllowed", true))
          }
        })
    }
  }, [navigator.mediaDevices]);

  useEffect(() => {
    console.log(data , invalidCode , howToUseOpened)
    if (data) {
      if (!invalidCode && !howToUseOpened) {
        if (validateScanData(data)) {
          const urlParts = data.split("/");
          const vending_id = urlParts[urlParts.length - 1];
          if (window.isDevMode) {
            console.log(
              "scan_vending_qr", {
                card: profile?.medCardNumber,
                vending_id
              })
          }
          Metrics.gtmEvent("scan_vending_qr", {
            card: profile?.medCardNumber,
            vending_id
          });
          const pathname = data.replace(window.location.href + "/", "");
          navigate(pathname + "?web=1");
        } else {
          setData(null);
          setInvalidCode(t("profile.coffee.invalidCode", true));
        }
      } else {
        setData(null);
      }
    }
  }, [data]);

  useEffect(() => {
    if (howToUseOpened && invalidCode) {
      setInvalidCode(null);
    }
  }, [howToUseOpened])

  return <div className={css.scanner}>
    <div className={css.header}>
      <div className={css.backward} onClick={() => navigate("/profile")}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M10.4547 2.50502C10.8321 2.10175 10.8321 1.44791 10.4547 1.04464C10.0774 0.64137 9.46558 0.64137 9.08824 1.04464L1.45777 9.19936C1.40989 9.24156 1.36588 9.2886 1.32639 9.33977L0.708008 10.0006L1.39125 10.7308L9.08824 18.9566C9.46558 19.3599 10.0774 19.3599 10.4547 18.9566C10.8321 18.5534 10.8321 17.8995 10.4547 17.4963L4.40289 11.0286L18.3256 11.0261C18.8592 11.026 19.2918 10.5636 19.2917 9.99332C19.2916 9.42301 18.8589 8.96076 18.3253 8.96085L4.41161 8.96333L10.4547 2.50502Z" fill="white"/>
        </svg>
      </div>
      <div className={css.instruction} onClick={() => setHowToUseOpened(true)}>
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M9.50033 17.5846C13.6885 17.5846 17.0837 14.1895 17.0837 10.0013C17.0837 5.81314 13.6885 2.41797 9.50033 2.41797C5.31217 2.41797 1.91699 5.81314 1.91699 10.0013C1.91699 14.1895 5.31217 17.5846 9.50033 17.5846ZM9.50033 19.3346C14.655 19.3346 18.8337 15.156 18.8337 10.0013C18.8337 4.84664 14.655 0.667969 9.50033 0.667969C4.34567 0.667969 0.166992 4.84664 0.166992 10.0013C0.166992 15.156 4.34567 19.3346 9.50033 19.3346Z" fill="white"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M9.50033 7.95964C9.98358 7.95964 10.3753 8.35139 10.3753 8.83464V14.668C10.3753 15.1512 9.98358 15.543 9.50033 15.543C9.01708 15.543 8.62533 15.1512 8.62533 14.668V8.83464C8.62533 8.35139 9.01708 7.95964 9.50033 7.95964Z" fill="white"/>
          <path d="M10.667 5.33464C10.667 5.97897 10.1447 6.5013 9.50033 6.5013C8.85599 6.5013 8.33366 5.97897 8.33366 5.33464C8.33366 4.6903 8.85599 4.16797 9.50033 4.16797C10.1447 4.16797 10.667 4.6903 10.667 5.33464Z" fill="white"/>
        </svg>
        {t("profile.coffee.howToUse?")}
      </div>
    </div>
    {
      (ready || !iOS()) && <QrReader
        onResult={(result, error) => {
          if (!!result) {
            setError(null);
            setData(result?.text);
          }

          if (!!error) {
            if (!iOS() && error.toString().includes("denied")) {
              if (window.isDevMode) {
                console.log("ANDROID CAMERA NOT ALLOWED", error);
              }
              setError(t("profile.coffee.cameraNotAllowed", true))
            }
            if (error.toString().includes("denied")) {
              setError(t("profile.coffee.cameraNotAllowed", true))
            } else {
              // setError(error.toString())
            }
          }
        }}
        videoContainerStyle={{
          height: "100%",
          paddingTop: 0
        }}
        ViewFinder={() => {
          return <div className={css.finder}>
            <div className={css.hore}/>
            <svg style={{position: "absolute"}} width="276" height="276" viewBox="0 0 276 276" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M30 270C16.7452 270 6 259.255 6 246V186C2.68629 186 0 188.686 0 192V246C0 262.569 13.4314 276 30 276H84C87.3137 276 90 273.314 90 270H30ZM186 270C186 273.314 188.686 276 192 276H246C262.569 276 276 262.569 276 246V192C276 188.686 273.314 186 270 186V246C270 259.255 259.255 270 246 270H186ZM270 90C273.314 90 276 87.3137 276 84V30C276 13.4315 262.569 0 246 0H192C188.686 0 186 2.68629 186 6H246C259.255 6 270 16.7452 270 30V90ZM6 90V30C6 16.7452 16.7452 6 30 6H90C90 2.68629 87.3137 0 84 0H30C13.4315 0 0 13.4314 0 30V84C0 87.3137 2.68629 90 6 90Z" fill="#54A0FF"/>
            </svg>


          </div>
        }}
        constraints={{
          facingMode: "environment"
        }}
        videoId={css.video}
        className={css.container}
        playsInline={true}
      />
    }
    <div className={css.footer}>
      {
        error ?? t("profile.coffee.useCamera")
      }
    </div>
    <HowToUse setHowToUseOpened={setHowToUseOpened} opened={howToUseOpened} />
    <InvalidCode invalidCode={invalidCode} setInvalidCode={setInvalidCode}/>
  </div>
}