import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isOpen: false,
	type: null,
};

export const agreementsSlice = createSlice({
	name: "agreements",
	initialState,
	reducers: {
		openAgreements: (state, action) => {
			state.isOpen = true;
			state.type = action.payload;
		},
		closeAgreements: (state) => {
			state.isOpen = false;
			state.type = null;
		},
	},
});

export const { openAgreements, closeAgreements } = agreementsSlice.actions;

export const isOpen = (state) => state.agreements.isOpen;
export const type = (state) => state.agreements.type;
