import { useTranslate } from "../../../../../../localization/translate";
import Dropdown from "../../../../../../components/dropdown/dropdown";
import Button from "../../../../../../components/button/button";
import styles from "./style.module.css";

export const Icon = () => (
	<svg
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.0003 4.66406C14.4422 4.66406 14.8003 5.02223 14.8003 5.46406V16.3327L17.7013 13.4317C18.0137 13.1193 18.5203 13.1193 18.8327 13.4317C19.1451 13.7441 19.1451 14.2507 18.8327 14.5631L14.566 18.8297C14.2536 19.1422 13.7471 19.1422 13.4346 18.8297L9.16797 14.5631C8.85555 14.2507 8.85555 13.7441 9.16797 13.4317C9.48039 13.1193 9.98693 13.1193 10.2993 13.4317L13.2003 16.3327V5.46406C13.2003 5.02223 13.5585 4.66406 14.0003 4.66406ZM5.46699 17.4641C5.90882 17.4641 6.26699 17.8222 6.26699 18.2641V19.3307C6.26699 19.9672 6.51985 20.5777 6.96994 21.0278C7.42002 21.4779 8.03047 21.7307 8.66699 21.7307H19.3337C19.9702 21.7307 20.5806 21.4779 21.0307 21.0278C21.4808 20.5777 21.7337 19.9673 21.7337 19.3307V18.2641C21.7337 17.8222 22.0918 17.4641 22.5337 17.4641C22.9755 17.4641 23.3337 17.8222 23.3337 18.2641V19.3307C23.3337 20.3916 22.9122 21.409 22.1621 22.1592C21.4119 22.9093 20.3945 23.3307 19.3337 23.3307H8.66699C7.60613 23.3307 6.58871 22.9093 5.83857 22.1592C5.08842 21.409 4.66699 20.3916 4.66699 19.3307V18.2641C4.66699 17.8222 5.02516 17.4641 5.46699 17.4641Z"
			fill="#54A0FF"
		/>
	</svg>
);

export default function FilesDropdown({
	files,
	expanded,
	setExpanded,
	onDownload,
}) {
	const translate = useTranslate();

	return (
		<Dropdown
			title={translate("profile.personalData.contracts.filesDropdown")}
			expanded={expanded}
			setExpanded={setExpanded}
			className={styles.dropdown}
		>
			{files.map((item) => (
				<div key={item.url} className={styles.doc_row}>
					<div className={styles.title}>{item.name}</div>

					<Button
						className={styles.download_btn}
						onClick={() => onDownload(item)}
					>
						<Icon />
					</Button>
				</div>
			))}
		</Dropdown>
	);
}
