import styles from "./filter.module.css";
import Dropdown from "../dropdown/dropdown";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslate } from "../../localization/translate";
import CloseModalButton from "../modal-items/close";
import Button from "../button/button";

export default function Filter({
	className,
	dropdownPosition = "right",
	children,
	filerResults,
	onApplyClick,
	found,
	clearAction,
	entries,
	expandedDefault,
	filterIsApply,
	setDefaultExpanded,
}) {
	const [expanded, setExpanded] = useState(expandedDefault);
	const translate = useTranslate();

	useEffect(() => {
		setExpanded(expandedDefault);
	}, [expandedDefault]);

	useEffect(() => {
		setDefaultExpanded(expanded);
	}, [expanded]);

	return (
		<div className={classNames(styles.filter, className)}>
			<div
				className={classNames(styles.toggler, {
					[styles.applied]: filterIsApply,
				})}
				onClick={() => setExpanded(!expanded)}
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M19.0766 15.9504L19.1279 16.1292L21.324 16.129C21.6749 16.129 22 16.4318 22 16.8548C22 17.2779 21.6748 17.5806 21.3239 17.5806L19.1276 17.5815L19.0763 17.7602C18.7004 19.0699 17.5408 20 16.1958 20C14.8508 20 13.6912 19.0699 13.3153 17.7602L13.264 17.5815L2.67599 17.5806C2.3251 17.5806 2 17.2779 2 16.8548C2 16.4318 2.3251 16.129 2.67599 16.129L13.2638 16.1292L13.315 15.9504C13.6907 14.6402 14.8505 13.7097 16.1958 13.7097C17.5411 13.7097 18.701 14.6402 19.0766 15.9504ZM11.6174 6.24075L11.6686 6.41952L21.324 6.41935C21.6749 6.41935 22 6.72209 22 7.14516C22 7.56823 21.6749 7.87097 21.324 7.87097L11.6684 7.8718L11.6171 8.05048C11.2412 9.36027 10.0816 10.2903 8.7366 10.2903C7.39159 10.2903 6.23199 9.36027 5.85606 8.05048L5.80479 7.87183L2.67599 7.87097C2.3251 7.87097 2 7.56823 2 7.14516C2 6.7221 2.32511 6.41936 2.67599 6.41935L5.80455 6.41952L5.8558 6.24075C6.23145 4.93048 7.39128 4 8.7366 4C10.0819 4 11.2417 4.93048 11.6174 6.24075ZM16.1958 14.9194C15.1306 14.9194 14.3077 15.8081 14.3077 16.8548C14.3077 17.9016 15.1306 18.7903 16.1958 18.7903C17.261 18.7903 18.0839 17.9016 18.0839 16.8548C18.0839 15.8081 17.261 14.9194 16.1958 14.9194ZM8.7366 5.20968C7.67137 5.20968 6.84849 6.09844 6.84849 7.14516C6.84849 8.19188 7.67137 9.08065 8.7366 9.08065C9.80182 9.08065 10.6247 8.19188 10.6247 7.14516C10.6247 6.09844 9.80182 5.20968 8.7366 5.20968Z"
						fill="#7A8CA4"
					/>
				</svg>
			</div>
			<Dropdown
				expanded={expanded}
				setExpanded={setExpanded}
				className={classNames(styles.dropdown, styles[dropdownPosition])}
			>
				<CloseModalButton closeModal={() => setExpanded(false)} />
				<div className={styles.header}>
					<div
						onClick={clearAction}
						className={classNames(styles.clear, {
							[styles.invisible]: !clearAction || !filterIsApply,
						})}
					>
						{translate("app.clear")}
					</div>
					<div className={styles.title}>{translate("app.filters")}</div>
				</div>
				<div className={styles.items}>{children}</div>
			</Dropdown>
		</div>
	);
}
