import SelectorModal from "../../../../components/item-selector/modal-selector";
import React, { useEffect, useState } from "react";
import { useTranslate } from "../../../../localization/translate";
import { useDispatch } from "react-redux";
import { setAccountData } from "../../services/profile.slice";
import style from "./account-top-up.module.css";
import Input from "../../../../components/input";
import {
	useDepositByCardMutation,
	useGetPaymentMethodsQuery,
} from "../../services/profile.api";
import arrow from "../../../../icons/arrow_down_24x24.svg";
import classNames from "classnames";
import checkIcon from "../../../../icons/check_icon_blue_18x15.svg";

export default function AccountTopUp({ account, setBalance = () => {} }) {
	const [amountError, setAmountError] = useState();
	const [deposit, { data: depositResult, isLoading }] =
		useDepositByCardMutation();
	const onApply = () => {
		if (depositResult) {
			dispatch(
				setAccountData({
					key: "accountReplenish",
					value: null,
				})
			);
		} else if (cardSelectorOpened) {
			setCardSelectorOpened(false);
			setPaymentMethod(selectedCard);
		} else {
			setAmountError(null);

			if (!amount || amount.length === 0) {
				setAmountError(translate("app.forms.required", true));
			} else {
				const id = paymentMethod?.id || paymentMethod?.operationId;
				deposit({
					bankCardId: id,
					accountId: account.id,
					amount,
				});
			}
		}
	};
	const dispatch = useDispatch();
	const onRequestClose = () => {
		if (cardSelectorOpened) {
			setSelectedCard(paymentMethod);
			setCardSelectorOpened(false);
		} else {
			dispatch(
				setAccountData({
					key: "accountReplenish",
					value: null,
				})
			);
		}
	};
	const isOpened = !!account;
	const translate = useTranslate();
	const [amount, setAmount] = useState("");
	const { data } = useGetPaymentMethodsQuery();
	const [paymentMethod, setPaymentMethod] = useState(
		data ? data.defaultPaymentMethod : null
	);
	const [cardSelectorOpened, setCardSelectorOpened] = useState(false);
	const [selectedCard, setSelectedCard] = useState(
		data ? data.defaultPaymentMethod : null
	);

	useEffect(() => {
		if (depositResult) {
			setBalance(parseFloat(account.balance) + amount);
			setAmountError(null);
			setAmount("");
		}
	}, [depositResult]);

	useEffect(() => {
		if (data) {
			let defaultMethod = null;
			if (data.defaultPaymentMethod?.type === "CARD") {
				defaultMethod = data.defaultPaymentMethod;
			} else if (data.bankCard.length) {
				defaultMethod = data.bankCard[0];
			}

			setPaymentMethod(defaultMethod);
			setSelectedCard(defaultMethod);
		}
	}, [data]);

	return (
		<SelectorModal
			isOpen={isOpened}
			applyBtn={{
				text: depositResult
					? translate("profile.bills.backToAccounts", true)
					: translate("profile.bills.replenishBalance", true),
				onClick: onApply,
				disabled: isLoading,
				backwardIcon: cardSelectorOpened,
			}}
			title={
				cardSelectorOpened
					? translate("profile.bills.selectPaymentMethod")
					: translate("profile.bills.replenishAccount")
			}
			onRequestClose={onRequestClose}
			wrapperClassName={style.modal_wrapper}
		>
			{depositResult && (
				<>
					<div className={style.success}>
						<div className={style.svg}>
							<svg
								width="81"
								height="80"
								viewBox="0 0 81 80"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="40.5" cy="40" r="40" fill="#54A0FF" />
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M63.4309 25.2779C64.9343 26.8965 64.8408 29.4275 63.2222 30.9309L37.3822 54.9309C35.8372 56.3658 33.4436 56.3551 31.9116 54.9062L19.7516 43.4062C18.1465 41.8883 18.0759 39.3566 19.5938 37.7516C21.1117 36.1465 23.6434 36.0759 25.2485 37.5938L34.6847 46.5179L57.7779 25.0692C59.3965 23.5658 61.9275 23.6592 63.4309 25.2779Z"
									fill="white"
								/>
							</svg>
						</div>
						<div className={`h3 ${style.title}`}>
							{translate("profile.bills.depositSuccessTitle")}{" "}
							{depositResult.data?.invoiceId}
						</div>
						<div className={classNames(`text`, style.text)}>
							{translate("profile.bills.depositSuccessText")}
						</div>
					</div>
				</>
			)}
			{!depositResult && !cardSelectorOpened && (
				<div className={style.block}>
					<div className={style.subtitle}>
						{translate("profile.bills.paymentMethod")}
					</div>
					<div
						className={style.str}
						onClick={() => setCardSelectorOpened(true)}
					>
						<div className={style.row}>
							<div className={style.name}>
								{paymentMethod
									? paymentMethod.name
									: translate("profile.bills.selectPaymentMethod", true)}
							</div>
							<div className={style.sub}></div>
						</div>
						<div className={style.arrow}>
							<img src={arrow} />
						</div>
					</div>
					<div className={style.subtitle}>
						{translate("profile.bills.amountReplenish")}
					</div>
					<Input
						type={"text"}
						value={isNaN(amount) ? "" : amount.toLocaleString()}
						onChange={(val) => {
							/*
							 * В state->amount  храниться число или пустая  строка.
							 * Инпуту в качестве отображаемого значения подается число разделеное пробелами - amount.toLocaleString()
							 * Когда инпут возвращает на onChange строку с пробелами, пробелы вырезаются и строка превращается в число
							 *  и записывается в amount
							 * */
							if (isNaN(val)) {
								if (val.length > 0) {
									setAmount(parseInt(val.replace(/\s/g, "")));
								} else {
									setAmount("");
								}
							} else {
								setAmount(parseInt(val));
							}
							setAmountError(null);
						}}
						containerClass={amountError && "invalid-value"}
						errorMsg={amountError}
						placeholder={translate("app.currencySign", true)}
						placeholderWhenFocused={" "}
					/>
				</div>
			)}
			{!depositResult && cardSelectorOpened && (
				<div className={classNames(style.block, style.select_card)}>
					<div className={style.subtitle}>
						{translate("profile.bills.allowedMethods")}
					</div>
					{data &&
						data.bankCard.map((card) => (
							<div
								className={style.str}
								onClick={() => setSelectedCard(card)}
								key={`card-${card.id || card.operationId}`}
							>
								<div className={style.row}>
									<div className={style.name}>{card.name}</div>
									<div className={style.sub}></div>
								</div>
								<div>
									{(card.id || card.operationId) ===
										(selectedCard.operationId || selectedCard.id) && (
										<img src={checkIcon} />
									)}
								</div>
							</div>
						))}
				</div>
			)}
		</SelectorModal>
	);
}
