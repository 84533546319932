import Loader from "../loader";
import styles from "./button.module.css";
import classNames from "classnames";

export default function Button({
	small = false,
	children,
	disabled = false,
	onClick = () => {},
	light = false,
	className,
	nonHover = false,
	secondary = false,
	variant = "primary",
	defaultFontSize = true,
	isLoading = false,
}) {
	const btnClass = classNames(
		styles[variant],
		{
			[styles.default]: !light,
			[styles.light]: light,
			[styles.small]: small,
			[styles.secondary]: secondary,
			[styles.non_hover]: nonHover,
			[styles.defaultFont]: defaultFontSize,
			[styles.loading]: isLoading,
		},
		styles.button,
		className
	);

	const _children = light ? <span>{children}</span> : children;

	return (
		<button
			onClick={() => {
				if (!disabled) {
					onClick();
				}
			}}
			disabled={disabled}
			className={btnClass}
		>
			{_children}
			<Loader className={styles.loader} white={!light} />
		</button>
	);
}
