import { useTranslate } from "../../../../localization/translate";
import { useDispatch, useSelector } from "react-redux";
import {
	allowAddPatient,
	selectPatient,
	setPatientSelectorState,
} from "../../../profile/services/profile.slice";
import { useGetProfileQuery } from "../../../profile/services/profile.api";
import PatientSelectorContainer from "./container";
import ItemSelector from "../../../../components/item-selector/item-selector";

export default function PatientSelector({ className }) {
	const translate = useTranslate();
	const selectedPatient = useSelector(selectPatient);
	const { data: profile } = useGetProfileQuery();
	const patient = selectedPatient ? selectedPatient : profile;
	const dispatch = useDispatch();

	if (!patient || !profile) {
		return null;
	}

	return (
		<ItemSelector
			activeElementAction={() => {
				dispatch(allowAddPatient());
				dispatch(setPatientSelectorState(true));
			}}
			text={translate("appointment.selectPatient")}
			className={className}
			activeElementText={
				patient.lastName +
				(patient.firstName
					? ` ${patient.firstName && patient.firstName[0]}.`
					: "")
			}
		>
			<PatientSelectorContainer />
		</ItemSelector>
	);
}
