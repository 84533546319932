import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslate } from "../../../../../localization/translate";
import Button from "../../../../../components/button/button";
import { useGetGeneralInfoQuery } from "../../../services/events.api";
import { MedicationCard } from "../MedicationCard";
import { Poll } from "../Poll";
import { GeneralInfo } from "../GeneralInfo";
import { Recommendations } from "../Recommendations";
import { PollCompleted } from "../Poll/PollCompleted";
import styles from "./styles.module.css";

export const Tabs = {
	POLL: "poll",
	GENERAL_INFO: "general_info",
	RECOMMENDATIONS: "recommendations",
};

export const MedicationContainer = ({ pollId }) => {
	const translate = useTranslate();
	const [activeTab, setActiveTab] = useState(null);
	const [searchParams, setSearchParams] = useSearchParams();

	const { data: generalInfo } = useGetGeneralInfoQuery();

	useEffect(
		() =>
			setActiveTab(
				searchParams.get("result")
					? null
					: pollId
					? Tabs.POLL
					: Tabs.GENERAL_INFO
			),
		[pollId]
	);

	const handleChangeTab = (tab) => {
		if (searchParams.get("result")) {
			searchParams.delete("result");
			setSearchParams(searchParams);
		}

		setActiveTab(tab);
	};

	if (!pollId) {
		return null;
	}

	return (
		<div className={styles.container}>
			<div className={styles.info_icon}>
				<svg
					width="44"
					height="60"
					viewBox="0 0 44 60"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="22.8461" cy="11" r="2.53846" fill="#7A8CA4" />
					<rect
						x="11.8462"
						y="32.1538"
						width="11.8462"
						height="3.38462"
						rx="1.69231"
						fill="#7A8CA4"
					/>
					<rect
						x="11.8462"
						y="38.9231"
						width="20.3077"
						height="3.38462"
						rx="1.69231"
						fill="#7A8CA4"
					/>
					<rect
						x="11.8462"
						y="45.6923"
						width="20.3077"
						height="3.38462"
						rx="1.69231"
						fill="#7A8CA4"
					/>
					<rect
						x="1.69231"
						y="1.69231"
						width="40.6154"
						height="55.8462"
						rx="8.46154"
						stroke="#7A8CA4"
						strokeWidth="3.38462"
					/>
				</svg>
			</div>

			<div className={styles.content}>
				<div className={styles.medication_card}>
					<MedicationCard
						title={translate("polls.selected.title")}
						description={translate("polls.selected.description")}
					/>
				</div>

				<div className={styles.title}>
					<h2>{translate("polls.selected.title")}</h2>
				</div>

				<div className={styles.description}>
					<span>{translate("polls.selected.description")}</span>
				</div>

				<div className={styles.tabs_btns}>
					{pollId && (
						<Button
							className={styles.tab_btn}
							secondary={activeTab !== Tabs.POLL}
							onClick={() => handleChangeTab(Tabs.POLL)}
						>
							{translate("polls.selected.startPollTabBtn")}
						</Button>
					)}

					{generalInfo?.treatmentInfo?.length > 0 && (
						<Button
							className={styles.tab_btn}
							secondary={activeTab !== Tabs.GENERAL_INFO}
							onClick={() => handleChangeTab(Tabs.GENERAL_INFO)}
						>
							{translate("polls.selected.generalInfoTabBtn")}
						</Button>
					)}

					{generalInfo?.recommendations?.length > 0 && (
						<Button
							className={styles.tab_btn}
							secondary={activeTab !== Tabs.RECOMMENDATIONS}
							onClick={() => handleChangeTab(Tabs.RECOMMENDATIONS)}
						>
							{translate("polls.selected.recommendationsTabBtn")}
						</Button>
					)}
				</div>

				<div className={styles.main_content}>
					{activeTab === Tabs.POLL && pollId && !searchParams.get("result") && (
						<Poll pollId={pollId} />
					)}

					{activeTab === Tabs.GENERAL_INFO &&
						!searchParams.get("result") &&
						generalInfo?.treatmentInfo?.length > 0 && (
							<GeneralInfo info={generalInfo.treatmentInfo} />
						)}

					{activeTab === Tabs.RECOMMENDATIONS &&
						!searchParams.get("result") &&
						generalInfo?.recommendations?.length > 0 && (
							<Recommendations recommendations={generalInfo.recommendations} />
						)}

					{searchParams.get("result") && (
						<PollCompleted pollId={searchParams.get("result")} />
					)}
				</div>
			</div>
		</div>
	);
};
