import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
	planAppointments: [],
	willBeCanceled: null,
	rescheduleDoctor: null,
	rateInfo: null,
};

export const eventsSlice = createSlice({
	name: "visitEvents",
	initialState,
	reducers: {
		setPlanAppointments: (state, action) => {
			state.planAppointments = action.payload;
		},
		setHistoryAppointments: (state, action) => {
			state.historyAppointments = action.payload;
		},
		setAppointmentToCancel: (state, action) => {
			state.willBeCanceled = action.payload;
		},
		setList: (state, action) => {
			state.planAppointments = action.payload;
		},
		setRescheduleDoctor: (state, action) => {
			state.rescheduleDoctor = action.payload;
		},
		setRateInfo: (state, action) => {
			state.rateInfo = action.payload;
		},
	},
});

export const {
	setPlanAppointments,
	setList,
	setRateInfo,
	setHistoryAppointments,
	setRescheduleDoctor,
	setAppointmentToCancel,
} = eventsSlice.actions;

export const selectRateInfo = (state) => state.visitEvents.rateInfo;
export const selectPlanAppointments = (state) => {
	const withDates = [];
	state.visitEvents.planAppointments.forEach((item) => {
		const matches = withDates.find(
			(sub) => sub.date === moment(item.date).unix()
		);
		if (matches) {
			matches.items.push(item);
		} else {
			withDates.push({
				date: moment(item.date).unix(),
				items: [item],
			});
		}
	});

	return withDates;
};
export const selectWillBeCanceled = (state) => state.visitEvents.willBeCanceled;
export const selectedRescheduleDoctor = (state) =>
	state.visitEvents.rescheduleDoctor;

export default eventsSlice;
