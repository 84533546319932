import { useMemo } from "react";
import moment from "moment";
import { useTranslate } from "../../../../localization/translate";
import Button from "../../../../components/button/button";
import {
	convertDate,
	moscowTimeZone,
} from "../../../../utils/dates/timezone-converter";
import styles from "./style.module.css";

const DeleteIcon = () => (
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.69231 6.23077C9.69231 5.84842 9.38235 5.53846 9 5.53846C8.61765 5.53846 8.30769 5.84842 8.30769 6.23077V14.5385C8.30769 14.9208 8.61765 15.2308 9 15.2308C9.38235 15.2308 9.69231 14.9208 9.69231 14.5385V6.23077Z"
			fill="#7A8CA4"
		/>
		<path
			d="M11.8076 5.53953C12.1894 5.56074 12.4817 5.88741 12.4605 6.26917L11.9989 14.5769C11.9777 14.9586 11.6511 15.2509 11.2693 15.2297C10.8875 15.2085 10.5952 14.8818 10.6165 14.5001L11.078 6.19237C11.0992 5.8106 11.4259 5.51832 11.8076 5.53953Z"
			fill="#7A8CA4"
		/>
		<path
			d="M6.92201 6.19237C6.9008 5.8106 6.57413 5.51832 6.19237 5.53953C5.8106 5.56074 5.51832 5.88741 5.53953 6.26917L6.00107 14.5769C6.02227 14.9586 6.34895 15.2509 6.73071 15.2297C7.11247 15.2085 7.40476 14.8818 7.38355 14.5001L6.92201 6.19237Z"
			fill="#7A8CA4"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.9577 1.76731C5.77712 2.12848 5.67565 2.48361 5.61801 2.76923H2.54985C2.54286 2.76912 2.53584 2.76912 2.52881 2.76923H0.692308C0.309957 2.76923 0 3.07919 0 3.46154C0 3.84389 0.309957 4.15385 0.692308 4.15385H1.93233L3.48476 15.797C3.65291 17.0582 4.72867 18 6.00095 18H11.999C13.2713 18 14.3471 17.0582 14.5152 15.797L16.0677 4.15385H17.3077C17.69 4.15385 18 3.84389 18 3.46154C18 3.07919 17.69 2.76923 17.3077 2.76923H15.4712C15.4642 2.76912 15.4571 2.76912 15.4501 2.76923H12.382C12.3244 2.48361 12.2229 2.12848 12.0423 1.76731C11.8282 1.33903 11.4961 0.889689 10.985 0.548965C10.4696 0.205352 9.81345 0 9 0C8.18655 0 7.53044 0.205352 7.01502 0.548965C6.50393 0.889689 6.17185 1.33903 5.9577 1.76731ZM10.9555 2.76923C10.9174 2.64485 10.868 2.51472 10.8039 2.38653C10.6718 2.12251 10.4847 1.87954 10.2169 1.70103C9.95351 1.52542 9.57116 1.38462 9 1.38462C8.42884 1.38462 8.04649 1.52542 7.78306 1.70103C7.5153 1.87954 7.32815 2.12251 7.19614 2.38653C7.13205 2.51472 7.08256 2.64485 7.04447 2.76923H10.9555ZM14.6708 4.15385L13.1428 15.614C13.0663 16.1873 12.5774 16.6154 11.999 16.6154H6.00095C5.42264 16.6154 4.93366 16.1873 4.85723 15.614L3.3292 4.15385H14.6708Z"
			fill="#7A8CA4"
		/>
	</svg>
);

export default function NotificationFull({
	title,
	date,
	description,
	image,
	infoUrl,
	category,
	onDelete,
}) {
	const translate = useTranslate();
	const isService = useMemo(() => category === "Сервисные", [category]);

	return (
		<div className={styles.full}>
			<div className={styles.header}>
				<div className={styles.title}>{title}</div>

				{!isService && (
					<Button small light className={styles.delete_btn} onClick={onDelete}>
						<DeleteIcon />
					</Button>
				)}
			</div>

			<div className={styles.date}>
				{moment(date).format("DD MMMM YYYY, HH:mm")}
				{moscowTimeZone() ? "" : translate("app.byMskTime")}
			</div>

			{image && <img alt={title} src={image} className={styles.image} />}

			<div
				className={styles.description}
				dangerouslySetInnerHTML={{ __html: description }}
			/>

			{infoUrl && (
				<a href={infoUrl} target="_blank" className={styles.learn_more}>
					{translate("notifications.learnMore")}
				</a>
			)}
		</div>
	);
}
