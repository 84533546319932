export const PERMISSIONS_ENUM = {
	denied: "denied",
	granted: "granted",
	default: "default",
};

export const notificationSupported = () => "Notification" in window;

export const getPermission = () => Notification.permission;

export const permissionIsGranted = (permission) =>
	permission === PERMISSIONS_ENUM.granted;

export const requestPermission = () => Notification.requestPermission();

export const setupPermission = () =>
	new Promise((resolve, reject) => {
		if (!notificationSupported()) {
			return reject();
		}
		if (permissionIsGranted(getPermission())) {
			return resolve();
		} else {
			return requestPermission()
				.then((permission) =>
					permissionIsGranted(permission) ? resolve() : reject()
				)
				.catch(() => reject());
		}
	});
