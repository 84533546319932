import Modal from "../../../../components/modal-safari-wrapper/modal";
import CloseModalButton from "../../../../components/modal-items/close";
import { useMediaQuery } from "beautiful-react-hooks";

export default function LinkModal({
	isOpen,
	onRequestClose,
	children,
	isWidget,
}) {
	const isMobile = useMediaQuery("(max-width: 620px)");
	const modalStyleMobile = {
		content: {
			display: "flex",
			justifyContent: "center",
			padding: "0",
			alignItems: "stretch",
		},
		overlay: {
			display: "flex",
			alignItems: isWidget ? "flex-start" : "center",
			zIndex: 150,
		},
	};
	const modalStyle = Object.assign({}, modalStyleMobile);
	modalStyle.content = {
		...modalStyleMobile.content,
		width: isMobile ? "455px" : "483px",
		maxWidth: isMobile ? 455 : 483,
		minWidth: isMobile ? 455 : 483,
	};
	modalStyle.overlay = {
		...modalStyleMobile.overlay,
	};

	return (
		<Modal
			isOpen={isOpen}
			style={isMobile ? modalStyleMobile : modalStyle}
			onRequestClose={onRequestClose}
		>
			<CloseModalButton closeModal={onRequestClose} />
			{children}
		</Modal>
	);
}
