import s from "./index.module.css";
import Button from "../../../../components/button/button";
import Loader from "../../../../components/loader";
import { useDispatch } from "react-redux";
import {
	setBiometryAllowed,
	setBiometryAsked,
} from "../../services/login.slice";
import { ReactComponent as FaceIDIcon } from "../../../../icons/biometry-face.svg";
import { ReactComponent as TouchIDIcon } from "../../../../icons/biometry-fingerprint.svg";
import { ReactComponent as BiometryIcon } from "../../../../icons/biometry.svg";
import { ReactComponent as CrossIcon } from "../../../../icons/cross.svg";
import { useTranslate } from "../../../../localization/translate";
import { useEffect } from "react";
import { useState } from "react";
import { isAndroidApp, isIOSApp } from "../../../../utils";

const BiometryAccess = () => {
	const t = useTranslate();
	const dispatch = useDispatch();
	const [type, setType] = useState();
	const [attempts, setAttempts] = useState(3);

	const handleClose = () => {
		dispatch(setBiometryAsked(true));
	};

	window.requestBiometryCallBack = (result) => {
		if (result === "success") {
			dispatch(setBiometryAllowed(true));
			dispatch(setBiometryAsked(true));
		}

		if (result === "failure") {
			if (!!attempts) {
				setAttempts((curr) => curr - 1);
			} else {
				dispatch(setBiometryAllowed(false));
				handleClose();
			}
		}
	};

	window.checkBiometryStatusCallBack = (type) => {
		if (type === "none") {
			dispatch(setBiometryAllowed(false));
			dispatch(setBiometryAsked(true));
		} else setType(type);
	};

	const handleGetAccess = async () => {
		try {
			if (isAndroidApp) {
				await window.MedicinaAndroidWebView?.requestBiometry?.();
			}

			if (isIOSApp) {
				await window.requestBiometry?.();
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (isAndroidApp) {
			window.MedicinaAndroidWebView?.checkBiometryStatus?.();
		}

		if (isIOSApp) {
			window.checkBiometryStatus?.();
		}
	}, []);

	if (!type) {
		return <Loader />;
	}

	return (
		<div className={s.container}>
			<button className={s.close} onClick={handleClose}>
				<CrossIcon className={s.icon} />
			</button>
			{type === "touchId" && <TouchIDIcon className={s.icon} />}
			{type === "faceId" && <FaceIDIcon className={s.icon} />}
			{type === "biometry" && <BiometryIcon className={s.icon} />}
			<p className={s.title}>
				{t("biometry.titleStart")} {type === "faceId" && "Face ID"}
				{type === "touchId" && "Touch ID"}
				{type === "biometry" && t("biometry.biometryType")}
			</p>
			<p className={s.text}>{t("biometry.subTitle")}</p>
			<Button onClick={handleGetAccess}>{t("biometry.allow")}</Button>
			<Button secondary className={s.button} onClick={handleClose}>
				{t("biometry.later")}
			</Button>
		</div>
	);
};

export default BiometryAccess;
