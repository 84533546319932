import classNames from "classnames";
import styles from "./dropdown.module.css";
import { useOnClickOutside } from "../../utils";
import React, { useRef } from "react";
import CloseModalButton from "../modal-items/close";

export default function Dropdown({
	children,
	className,
	expanded,
	setExpanded = () => {},
	closeBtn,
	title,
	listenOutSideClick = true,
}) {
	const ref = useRef();
	useOnClickOutside(ref, () => listenOutSideClick && setExpanded(false));

	return (
		<div
			className={classNames(
				styles.months_list,
				{ [styles.hidden]: !expanded },
				className
			)}
			onClick={(e) => e.stopPropagation()}
			ref={ref}
		>
			{closeBtn && <CloseModalButton closeModal={() => setExpanded(false)} />}
			{title && <div className={styles.title}>{title}</div>}
			{children}
		</div>
	);
}
