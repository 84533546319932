import styles from "./programs.module.css";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import ProfileNotice from "../personala-data/notice";
import { useTranslate } from "../../../../localization/translate";
import {
	useGetPurchasedProgramQuery,
	useGetCatalogProgramsQuery,
} from "../../services/profile.api";
import Loader from "../../../../components/loader";
import PurchasedProgram from "./purchased-card";
import Masonry from "react-masonry-css";
import React from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import HasNoItems from "../../../../components/has-no-items/has-no-items";
import ListItemRow from "../../../../components/list-item-row";
import ProgramsList from "./components/programs-list";
import Search from "./components/search";

export default function Programs() {
	const translate = useTranslate();
	const navigate = useNavigate();
	const { data: purchased, isLoading, error } = useGetPurchasedProgramQuery();
	const {
		data: catalog,
		isLoading: isLoadingCatalog,
		error: catalogError,
	} = useGetCatalogProgramsQuery();
	const isMobile = useMediaQuery("(max-width: 900px)");

	return (
		<div>
			<div className={classNames("h3", styles.title, "profile_content_title")}>
				{translate("profile.menu.programs")}
			</div>
			{error ||
				(catalogError && (
					<ProfileNotice title={translate("app.failedToFetch", true)} />
				))}
			<div className={styles.category}>
				<div className={styles.category_title}>
					{translate("profile.programs.myPrograms")}
				</div>
				{isLoading && (
					<div className={styles.loader}>
						<Loader />
					</div>
				)}
				{purchased && (
					<Masonry
						breakpointCols={isMobile ? 1 : 2}
						className={styles.masonry_grid}
						columnClassName={styles.masonry_grid_column}
					>
						{purchased.map((program) => (
							<PurchasedProgram
								{...program}
								key={`purchased-program-${program.idMis}`}
							/>
						))}
					</Masonry>
				)}
				{purchased && purchased.length === 0 && (
					<HasNoItems
						className={styles.has_no_items}
						text={translate("profile.programs.youHaveNoPurchasedPrograms")}
						positionY={"top"}
					/>
				)}
			</div>
			<div className={styles.category}>
				{isLoadingCatalog && (
					<div className={styles.loader}>
						<Loader />
					</div>
				)}

				{catalog && catalog.programGroups.length > 0 && (
					<ProgramsList
						title={translate("profile.programs.programCatalog")}
						collection={catalog.programGroups}
						renderItem={(props) => (
							<ListItemRow
								key={props.id}
								title={props.name}
								showArrow={props.programs && props.programs.length > 0}
								onClick={() =>
									props.programs &&
									props.programs.length &&
									navigate(`/profile/programs/groups/${props.id}`)
								}
							/>
						)}
						renderSubheader={() => <Search />}
					/>
				)}
			</div>
		</div>
	);
}
