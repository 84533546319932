import { useTranslate } from "../../localization/translate";
import styles from "./payment-redirect.module.css";

export default function PaymentRedirect() {
	const translate = useTranslate();

	return (
		<div className={styles.payment_redirect}>
			<div className={styles.text}>
				{translate("payments.Redirect.closeModalForContinue")}
			</div>
		</div>
	);
}
