import css from "./invalid-code.module.css";
import classNames from "classnames";
import Close from "./close";
import React from "react";
import {useTranslate} from "../../../../../../localization/translate";
import Button from "../../../../../../components/button/button";

export default function InvalidCode({invalidCode, setInvalidCode}) {
  const onRequestClose = () => setInvalidCode(false);
  const t = useTranslate();

    return <div className={classNames(css.modal, {[css.opened]: invalidCode})}>
      <Close onClose={onRequestClose}/>
      <div className={css.container}>
        <div className={css.title}>
          {t("profile.coffee.invalidCodeTitle")}
        </div>
        <div className={css.graphics}>
          <img src="/images/coffee/invalid-code.svg" alt=""/>
        </div>
        <div className={css.text}>
          {invalidCode}
        </div>
        <Button className={css.button} onClick={onRequestClose}>{t("profile.coffee.tryAgain")}</Button>
      </div>
    </div>
}