import { useMemo, useContext } from "react";
import { useTranslate } from "../../../../localization/translate";
import { useGetPaymentDataQuery } from "../../services/payments.api";
import PaymentContext from "../../contexts/payment-context";
import NavigationContext from "../../contexts/navigation-context";
import MethodsSelector from "../../components/MethodsSelector";
import { AccountMethodsFactory } from "../../helpers/utils";

export default function PaymentAccounts({
	type,
	purposeId,
	medCardNumber = null,
}) {
	const translate = useTranslate();
	const {
		payment: { checkout },
		setPayment,
	} = useContext(PaymentContext);
	const { erase } = useContext(NavigationContext);

	const { data } = useGetPaymentDataQuery({
		paymentPurpose: type,
		medCardNumber,
		purposeId,
	});

	const mapAccountMethods = useMemo(
		() => AccountMethodsFactory(translate),
		[translate]
	);

	const accounts = useMemo(
		() => mapAccountMethods(data?.accounts || []),
		[data]
	);

	const handleSelectAccount = ({ type, name, id }) => {
		setPayment((prev) => ({
			...prev,
			checkout: {
				...prev.checkout,
				paymentMethod: {
					type,
					name,
					operationId: id,
				},
			},
		}));

		erase();
	};

	return (
		<MethodsSelector
			subtitle={
				<>
					{translate(`payments.PaymentAccounts.${type}.subtitle`, true)}
					&nbsp;
					{checkout.amount?.toLocaleString()}&nbsp;
					{translate("app.currencySign", true)}
				</>
			}
			blockTitle={translate("payments.PaymentAccounts.selectAccount")}
			collection={accounts}
			onClickMethod={handleSelectAccount}
		/>
	);
}
