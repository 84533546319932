import styles from "./notify.module.css";
import classNames from "classnames";

export default function Notify({
	onClick = () => {},
	isActive = false,
	date,
	text,
	type,
	isNew = false,
}) {
	const classes = classNames(styles.notify, {
		[styles.active]: isActive,
	});

	return (
		<div className={classes} onClick={onClick}>
			<div className={styles.heading}>
				{isNew && <div className={styles.marker} />}
				<span className={styles.date}>{date}</span>
				<span className={styles.type}>{type}</span>
			</div>
			<div className={styles.text}>{text}</div>
		</div>
	);
}
