import { useEffect, useMemo } from "react";
import classNames from "classnames";
import { useTranslate } from "../../../../localization/translate";
import { useGetDiscountDataQuery } from "../../services/profile.api";
import { useRouterDynamicSegment } from "./hooks/useRouterDynamicSegment";
import InfoBannerWrapper from "./components/InfoBlockWrapper";
import styles from "./coupon-book.module.css";
import ListItemRow from "../../../../components/list-item-row";
import { Metrics } from "../../../../utils/metrics";

const CouponBook = () => {
	const translate = useTranslate();
	const id = useRouterDynamicSegment(0);

	const hasId = useMemo(() => !!Number(id), [id]);

	const { data: discounts, isLoading } = useGetDiscountDataQuery(null, {
		skip: !hasId,
	});

	const couponBook = useMemo(
		() =>
			hasId &&
			discounts?.couponBooks?.find(({ number }) => number === Number(id)),
		[discounts, hasId, id]
	);

	useEffect(() => {
		if (couponBook || !hasId)
			Metrics.gtmEvent("view_discount_item", {
				discount_type: "coupon-book",
			});
	}, [couponBook, hasId]);

	return (
		<div className={styles.coupon_book}>
			<div className={classNames("h3", styles.title)}>
				{translate("profile.discounts.couponBook.title")}
			</div>

			{couponBook?.coupons.length > 0 && (
				<div className={styles.content_block}>
					<div className={styles.content_title}>
						{translate("profile.discounts.couponBook.available")}
					</div>

					<div className={styles.coupon_list}>
						{couponBook?.coupons.map(({ service }) => (
							<ListItemRow
								key={service.id}
								title={service?.name}
								showArrow={false}
							/>
						))}
					</div>
				</div>
			)}

			<div className={styles.content_block}>
				<InfoBannerWrapper
					title={translate("profile.discounts.couponBook.whatIt")}
					list={translate("profile.discounts.couponBook.infoList")}
					learMoreLink="https://www.medicina.ru/patsientam/diskontnye-programmy/"
				/>
			</div>
		</div>
	);
};

export default CouponBook;
