import { useEffect } from "react";
import db from "mime-db";
import { loadBaseUrl } from "../app/config.api";
import moment from "moment";
import { isSameDay, lightFormat } from "date-fns";

export const VERSION = [
	!!process.env.REACT_APP_BUILD_DATE &&
		moment(process.env.REACT_APP_BUILD_DATE).format("YYYY.M.D"),
	process.env.REACT_APP_COMMIT_HASH,
]
	.filter((s) => !!s)
	.join(".");

export function useOnClickOutside(ref, handler, allowTouch = true) {
	useEffect(() => {
		const listener = (event) => {
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}
			handler(event);
		};
		document.addEventListener("mousedown", listener);
		allowTouch && document.addEventListener("touchstart", listener);

		return () => {
			document.removeEventListener("mousedown", listener);
			allowTouch && document.removeEventListener("touchstart", listener);
		};
	}, [ref, handler]);
}

export function downloader(source, name) {
	const file = new Blob([source], {
		type: "plain/text",
	});
	const link = document.createElement("a");
	link.href = URL.createObjectURL(file);
	link.download = name;
	link.click();
}

export function downloadByBlob(blob, name) {
	const link = document.createElement("a");
	link.href = URL.createObjectURL(blob);
	link.download = name;
	link.click();
}

export function downloadByDataURL(url, name) {
	const link = document.createElement("a");
	link.href = url;
	link.download = name;
	link.click();
}

export function downloadFileByUrl(url, options = {}) {
	let headers = new Headers();
	const token = localStorage.getItem("accessToken");

	if (token) {
		headers.append("X-Access-Token", `Bearer ${token}`);
	}

	const opts = {
		method: "GET",
		headers,
		mode: "cors",
		cache: "default",
		...options,
	};

	return fetch(url, opts);
}

export function fileViewer(
	url,
	success = () => {},
	failure = () => {},
	fileName = "medicina",
	fileExtension
) {
	if (url && url.length > 0) {
		let headers = new Headers();
		const token = localStorage.getItem("accessToken");

		if (token) {
			headers.append("X-Access-Token", `Bearer ${token}`);
		}
		const options = {
			method: "GET",
			headers,
			mode: "cors",
			cache: "default",
		};

		fetch(url, options)
			.then((res) => {
				if (res.status !== 200) {
					throw res.json();
				}
				return res.blob();
			})
			.then((blob) => {
				let fileUrl = window.URL.createObjectURL(blob);

				if (isSafari) {
					let extensions = db[blob.type];
					const extension = fileExtension
						? fileExtension
						: extensions?.extensions?.length
						? extensions.extensions[0]
						: null;
					let a = document.createElement("a");
					document.body.appendChild(a);
					a.style.display = "none";
					a.href = fileUrl;
					a.target = "_blank";
					a.download = `${fileName}${extension ? `.${extension}` : ""}`;
					a.click();
					document.body.removeChild(a);
				} else {
					window.open(fileUrl);
				}

				success();
			})
			.catch((e) => {
				console.error(e);
				e.then((e) => {
					failure(e);
				});
			});
	} else {
		failure("URL not specified!");
	}
}

export const scrollToPosition = (top = 0, behavior = "auto") => {
	try {
		window.scroll({
			top,
			left: 0,
			behavior,
		});
	} catch (_) {
		window.scrollTo(0, top);
	}
};

export const oldWayToCopy = (text) => {
	const temp = document.createElement("input");
	const root = document.getElementById("root");
	root.appendChild(temp);
	temp.value = text;
	temp.select();
	document.execCommand("copy");
	root.removeChild(temp);
};

export const copyText = async (text) => {
	if (isAndroidApp) {
		try {
			await window.MedicinaAndroidWebView.copyToClipboard(text);
		} catch (err) {
			console.error("window.MedicinaAndroidWebView is undefined", err);
		}
	} else {
		if (isSupportCopy) navigator.clipboard.writeText(text);
		else oldWayToCopy(text);
	}
};

export const isSupportCopy =
	!!navigator.clipboard && !!navigator.clipboard.writeText;

// AOMedicinaWebApp/1.0 iOS/14.5
export const isSafari =
	navigator.userAgent.includes("AOMedicinaWebApp") ||
	(navigator.userAgent.includes("Safari") &&
		!navigator.userAgent.includes("Chrome"));

export const isIOSApp =
	navigator.userAgent.includes("AOMedicinaWebApp") &&
	([
		"iPad Simulator",
		"iPhone Simulator",
		"iPod Simulator",
		"iPad",
		"iPhone",
		"iPod",
	].includes(navigator.platform) ||
		(navigator.userAgent.includes("Mac") && "ontouchend" in document));

export const isAndroidApp =
	navigator.userAgent.includes("AOMedicinaWebApp") &&
	!!/(android)/i.test(navigator.userAgent);

export const openUrlInNewTab = (url) => {
	if (isSafari) {
		let a = document.createElement("a");
		document.body.appendChild(a);
		a.style.display = "none";
		a.href = url;
		a.id = "temp";
		a.target = "_blank";
		a.click();
		document.body.removeChild(a);
	} else {
		window.open(url);
	}
};

export function iOS() {
	return (
		[
			"iPad Simulator",
			"iPhone Simulator",
			"iPod Simulator",
			"iPad",
			"iPhone",
			"iPod",
		].includes(navigator.platform) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes("Mac") && "ontouchend" in document)
	);
}

export const checkIOSVersionBrowser = () => {
	const agent = window.navigator.userAgent;
	const start = agent.indexOf("OS ");

	if (
		(agent.indexOf("iPhone") > -1 || agent.indexOf("iPad") > -1) &&
		start > -1
	)
		return window.Number(agent.substr(start + 3, 3).replace("_", "."));

	return 0;
};

export const checkIOSVersionApp = () => {
	const agent = window.navigator.userAgent;
	const start = agent.indexOf("iOS/");

	if (isIOSApp && start > -1) return window.Number(agent.substr(start + 4, 3));

	return 0;
};

export const getPlatform = () => {
	if (isAndroidApp) return "android";
	else if (isIOSApp) return "ios";
	else return "web";
};

export const checkIOSVersion = () =>
	isIOSApp ? checkIOSVersionApp() : checkIOSVersionBrowser();

export const tokenPayload = (token) => {
	try {
		const payload = token.split(".")[1];
		return JSON.parse(atob(payload));
	} catch (_) {
		return null;
	}
};

export const getProfileByToken = async (token) => {
	const headers = new Headers();

	if (token) headers.append("X-Access-Token", `Bearer ${token}`);

	const options = {
		method: "GET",
		headers,
	};

	const res = await fetch(`${loadBaseUrl()}/api/profile`, options);
	const data = await res.json();

	if (res.ok) {
		return Promise.resolve(data.data);
	}

	return Promise.reject(null);
};

export const getDoctorSheduleByToken = async (token, body) => {
	const headers = new Headers();

	headers.append("Content-Type", "application/json");
	if (token) headers.append("X-Access-Token", `Bearer ${token}`);

	const options = {
		method: "POST",
		headers,
		body: JSON.stringify(body),
	};

	const res = await fetch(`${loadBaseUrl()}/api/schedule/doctor`, options);
	const data = await res.json();

	if (res.ok) {
		return Promise.resolve(data.data);
	}

	return Promise.reject(data);
};

export const isBiometryAvailable =
	!!window.MedicinaAndroidWebView?.checkBiometryStatus ||
	!!window.checkBiometryStatus;

export const isInIframe = () => {
	try {
		return window.self !== window.top;
	} catch (error) {
		return false;
	}
};

export function getSiteSourceName () {
	const {ancestorOrigins} = window.location;

	if (ancestorOrigins?.length) {
		return ancestorOrigins[0]
	}

	return window.location.hostname
}


document.body.freeze = () => {
	document.documentElement.style.height = "100%";
	document.documentElement.style.overflow = "hidden";
	document.body.style.height = "100%";
	document.body.style.overflow = "hidden";
}

document.body.unfreeze = () => {
	document.documentElement.style.height = null;
	document.documentElement.style.overflow =  null;
	document.body.style.height =  null;
	document.body.style.overflow =  null;
}