import styles from "./ricib.module.css";
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { iOS, checkIOSVersion } from "../../utils";

export const SingleOtpField = ({ type = "text", length, onComplete }) => {
	const [code, setCode] = useState("");

	const isPass = useMemo(() => type === "password", [type]);
	const attributes = {};

	if (!iOS()) {
		attributes.pattern = "[0–9]*";
	}

	const handleChange = (event) => {
		let value = event.target.value.replace(/[^0-9]/g, "");
		setCode(value);
	};

	useEffect(() => {
		if (code.length === length) {
			onComplete(code);
		}
	}, [code]);

	return (
		<input
			value={code}
			autoFocus
			type="text"
			inputMode="numeric"
			maxLength={length}
			autoComplete={iOS() ? "one-time-code" : "off"}
			className={classNames(styles.input, styles.single, {
				[styles.pass]: isPass,
			})}
			onChange={handleChange}
			{...attributes}
		/>
	);
};

export function OtpField({ type = "text", length, onComplete = () => {} }) {
	const [code, setCode] = useState(new Array(length).fill(""));

	const insertCode = (text, index) => {
		const resultValue = [...code];
		text.split("").forEach((item, i) => {
			resultValue.splice(index + i, 0, item);
		});

		if (resultValue.length >= length) {
			setCode(resultValue.slice(0, length));
		} else {
			const diff = length - resultValue.length;
			setCode(resultValue.concat(new Array(diff).fill("")));
		}
	};

	const isPass = useMemo(() => type === "password", [type]);

	useEffect(() => {
		const index =
			code.join("").length < length ? code.join("").length : length - 1;
		const obj = document.getElementsByClassName(`target-${index}`);
		if (obj && obj.length > 0) {
			obj[0].focus();
		}
		if (code.join("").length === length) {
			onComplete(code.join(""));
		}
	}, [code]);

	return (
		<>
			{code.map((char, i) => {
				const autoFocus = i === code.join("").length;
				const disabled = i > code.join("").length;
				const attributes = {
					"data-index": i,
				};

				if (!iOS()) {
					attributes.maxLength = 1;
					attributes.pattern = "[0–9]*";
				}

				return (
					<input
						type="text"
						autoFocus={autoFocus}
						className={classNames(styles.input, `target-${i}`, {
							[styles.disabled]: disabled,
							[styles.pass]: isPass,
						})}
						onChange={(e) => {
							let value = e.target.value.replace(/[^0-9]/g, "");
							if (value !== char && value.length > 0 && !disabled) {
								if (value.length === 1) {
									const newValue = code.map((val, idx) =>
										i === idx ? value : val
									);
									setCode(newValue);
								} else {
									insertCode(value, i);
								}
							}
						}}
						onKeyDown={(e) => {
							if (e.key === "Backspace") {
								e.preventDefault();
								const position = e.target.value.length > 0 ? i : i - 1;

								const newValue = code
									.map((val, idx) => (position === idx ? "" : val))
									.join("")
									.split("");
								const diff = length - newValue.length;
								for (let z = 0; z < diff; z++) {
									newValue.push("");
								}

								setCode(newValue);

								return false;
							}
						}}
						onFocus={(e) => disabled && e.target.blur()} //Чтобы скрыть виртуальную клавиуатру на телефоне
						onPaste={(event) => {
							const text = event.clipboardData
								.getData("text/plain")
								.replace(/[^0-9]/g, "");
							insertCode(text, i);
						}}
						value={char}
						{...attributes}
						key={`input-${i}-${char}`}
						// disabled={disabled}
						autoComplete={iOS() ? "one-time-code" : "off"}
						inputMode="numeric"
					/>
				);
			})}
		</>
	);
}

export default ({
	type = "text",
	length,
	errorMsg = null,
	onComplete = () => {},
	advInputClass = "",
	errorClass = "",
}) => {
	const [error, setError] = useState(errorMsg);
	const inputClassName = classNames(
		{ [styles.invalidInput]: error },
		styles.ricib,
		advInputClass
	);

	useEffect(() => {
		setError(errorMsg);
	}, [errorMsg]);

	const fieldProps = {
		type,
		length,
		onComplete,
	};

	return (
		<div className={inputClassName}>
			<OtpField {...fieldProps} />

			{error && (
				<div className={classNames(styles.errorMsg, errorClass)}>{error}</div>
			)}
		</div>
	);
};
