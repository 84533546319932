import styles from "./app-menu.module.css";
import { NavLink } from "react-router-dom";
import routes from "../../app/sitemap.json";
import { useTranslate } from "../../localization/translate";
import classNames from "classnames";
import {
	useGetProfileQuery,
	useGetCountersQuery,
	useGetSupportCountersQuery,
} from "../profile/services/profile.api";
import { selectCounters, setCounters } from "../profile/services/profile.slice";
import Badge from "../../components/badge/badge";
import { useMediaQuery } from "beautiful-react-hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function AppMenu() {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const counters = useSelector(selectCounters);

	const { data: profile, isLoading, error } = useGetProfileQuery();
	const { data: fetchedCounters } = useGetCountersQuery();
	const { data: supportCounters } = useGetSupportCountersQuery();

	const hasFIO =
		profile &&
		profile.firstName &&
		profile.firstName.length > 0 &&
		profile.lastName &&
		profile.lastName.length > 0;
	const hasUnreadNotifications = profile?.hasUnreadNotifications;

	const isDesktop = useMediaQuery("(min-width: 1030px)");
	const isWide = useMediaQuery("(min-width: 1440px)");

	useEffect(() => {
		if (fetchedCounters) {
			dispatch(setCounters(fetchedCounters));
		}
	}, [fetchedCounters]);

	return (
		<div className={styles.menu}>
			{!error && !isLoading && hasFIO && (
				<NavLink
					to={isWide ? "/profile/personal-info" : "/profile"}
					className={({ isActive }) =>
						classNames(styles.user, {
							[styles.item]: profile && !profile.photo,
							[styles.user_info]: !isDesktop || (hasFIO && profile.photo),
							[styles.active]: isActive,
						})
					}
				>
					<div className={styles.avatar_container}>
						{supportCounters?.repliesCount > 0 && (
							<Badge
								count={supportCounters?.repliesCount}
								advClass={styles.badge_counter}
							/>
						)}

						{profile.photo ? (
							<img src={profile.photo} className={styles.avatar} />
						) : (
							<div className={styles.icon_wrapper}>
								<img
									src={`/images/app-menu/profile.svg`}
									className={classNames(styles.icon, styles.default)}
								/>
								<img
									src={`/images/app-menu/profile-active.svg`}
									className={classNames(styles.icon, styles.active)}
								/>
							</div>
						)}
						<div className={styles.name}>
							<span>{profile.firstName}</span>
							<span> {profile.lastName[0]}.</span>
						</div>
					</div>
					{translate(`app.menu.profile`)}
				</NavLink>
			)}
			{routes.map((route) => {
				return (
					<NavLink
						to={
							route.id === "profile"
								? isWide
									? "/profile/personal-info"
									: "/profile"
								: route.url
						}
						key={`nav-link-${route.id}`}
						className={({ isActive }) =>
							classNames(styles.item, {
								[styles.active]: isActive,
								[styles.desktop_hidden]:
									(profile && hasFIO && route.id === "profile") || !profile,
							})
						}
					>
						<div className={styles.icon_wrapper}>
							{route.id === "notifications" && hasUnreadNotifications && (
								<div className={styles.unreaded_marker} />
							)}

							{route.id === "events" && counters?.appointmentsCount > 0 && (
								<Badge
									count={counters?.appointmentsCount}
									advClass={styles.badge_counter}
								/>
							)}

							{route.id === "profile" && supportCounters?.repliesCount > 0 && (
								<Badge
									count={supportCounters?.repliesCount}
									advClass={styles.badge_counter}
								/>
							)}

							{route.id === "profile" && profile?.photo && isDesktop ? (
								<img src={profile.photo} className={styles.avatar} />
							) : (
								<>
									<img
										src={`/images/app-menu/${route.id}.svg`}
										className={classNames(styles.icon, styles.default)}
									/>
									<img
										src={`/images/app-menu/${route.id}-active.svg`}
										className={classNames(styles.icon, styles.active)}
									/>
								</>
							)}
						</div>
						{translate(`${route.title_path}.${route.id}`)}
					</NavLink>
				);
			})}
		</div>
	);
}
