import { useDispatch, useSelector } from "react-redux";
import {
	selectExpired,
	selectLastActivity,
	setExpired,
	setLastUrl,
	updateLastActivity,
	selectShortCode, selectIsGuestMode,
} from "./services/login.slice";
import { resetPaymentData } from "../payments/services/payments.slice";
import React, { useEffect } from "react";
import ShortCode from "./modules/short-code/short-code";
import {useSearchParams} from "react-router-dom";

/**
 * Даный провайдер обеспечивает функционал разлогинивания в случае отсутсвия активности пользователя в течении указанного времени
 *
 *  @param {object} children - Содержимое
 *  @param {number || string} time - Время отсуствия активности в милисекундах
 *
 */

export default function ActivityTimerProvider({
	children,
	time = null,
	isWidget = false,
}) {
	const shortCode = useSelector(selectShortCode);
	const [params] = useSearchParams();
	const isGuest = useSelector(selectIsGuestMode);

	if (isGuest) {
		return children;
	}

	if (!shortCode && !params.get("shortCode") && !isWidget) {
		return <ShortCode />;
	}

	if (!time) {
		return children;
	}

	return <Observer time={time}>{children}</Observer>;
}

/**
 * Функция отслеживает любую активность пользователя и продлевает время последней активности
 * Если разница между текущим временем и временнем последней активности будет больше чем time,
 * то будет предложено ввести код или заново залогиниться
 *
 * */
function Observer({ children, time }) {
	const lastActivity = useSelector(selectLastActivity);
	const dispatch = useDispatch();

	const handler = (e) => {
		const currentTime = new Date().getTime();
		const diff = currentTime - (lastActivity ? lastActivity : currentTime);

		if (diff < time) {
			dispatch(updateLastActivity());
			if (expired) {
				dispatch(setExpired(false));
			}
		} else {
			dispatch(resetPaymentData());
			dispatch(setExpired(true));
			dispatch(setLastUrl( window.location.pathname));
			window.removeEventListener(e, handler);
		}
	};

	const activityEvents =
		"touchstart touchend click contextmenu scroll mousemove touchmove keydown";
	const expired = useSelector(selectExpired);

	useEffect(() => {
		const currentTime = new Date().getTime();
		const diff = currentTime - (lastActivity ? lastActivity : currentTime);

		if (diff > time) {
			dispatch(resetPaymentData());
			dispatch(setExpired(true));
		}
	}, []);

	useEffect(() => {
		if (!expired) {
			activityEvents.split(" ").forEach(function (e) {
				window.addEventListener(e, handler, false);
			});
		}

		return () =>
			activityEvents.split(" ").forEach(function (e) {
				window.removeEventListener(e, handler);
			});
	});

	return children;
}
