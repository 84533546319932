import css from "./selectors.module.css";
import {
	setDoctorModalScheduleState,
	setSubjectOpinion,
} from "../../services/appointment.slice";
import DoctorCard from "../../../../components/doctor-card/doctor-card";
import { useDispatch } from "react-redux";

export default function DoctorSelector({
	userSelection,
	setUserSelection,
	data,
}) {
	const dispatch = useDispatch();
	const subject = data.find((item) => item.id === userSelection.subjectId);
	const onClick = (id) => {
		setUserSelection({
			...userSelection,
			doctorId: id,
		});
		dispatch(setSubjectOpinion("Confirm"));
	};

	if (!subject) {
		dispatch(setSubjectOpinion(null));

		return null;
	}

	return (
		<div className={css.list}>
			{subject.doctors.map((doctor) => (
				<DoctorCard
					{...doctor}
					key={`doc-${doctor.id}`}
					className={css.doctor}
					showFooter={false}
					cardClick={() => onClick(doctor.id)}
					advCallback={() => dispatch(setDoctorModalScheduleState(true))}
				/>
			))}
		</div>
	);
}
