import { useMemo } from "react";
import styles from "./service-card.module.css";
import cn from "classnames";
import services from "../../icons/services.svg";
import services_active from "../../icons/services_active.svg";
import second_opinion from "../../icons/second-opinion.svg";
import second_opinion_active from "../../icons/second-opinion_active.svg";
import doctor from "../../icons/doctor.svg";
import doctor_active from "../../icons/doctor_active.svg";
import treatment from "../../icons/treatment.svg";
import receptions_active from "../../icons/receptions_active.svg";
import receptions from "../../icons/receptions.svg";
import snapshots from "../../icons/snapshots.svg";
import snapshots_active from "../../icons/snapshots_active.svg";
import analyzes_active from "../../icons/analyzes_active.svg";
import analyzes from "../../icons/analyzes.svg";
import courses_active from "../../icons/courses_active.svg";
import courses from "../../icons/courses.svg";
import login from "../../icons/login.svg";

import classNames from "classnames";

const icons = {
	services,
	services_active,
	doctor,
	doctor_active,
	treatment,
	second_opinion,
	second_opinion_active,
	receptions_active,
	receptions,
	snapshots,
	analyzes_active,
	analyzes,
	snapshots_active,
	courses_active,
	courses,
	login,
};

export default function ServiceCard({
	isActive,
	icon,
	text,
	title,
	isLast = false,
	isSmall = false,
	className,
	onClick,
}) {
	const activeIcon = useMemo(() => `${icon}_active`, [icon]);
	const hasActiveIcon = useMemo(() => activeIcon in icons, [activeIcon]);
	const rIcon = useMemo(
		() => (isActive && hasActiveIcon ? icons[activeIcon] : icons[icon]),
		[icon, isActive, hasActiveIcon, activeIcon]
	);

	return (
		<div
			className={classNames(styles.service_card, className, {
				[styles.last_child]: isLast,
				[styles.active]: isActive,
				[styles.small]: isSmall,
			})}
			onClick={onClick}
		>
			<div className={cn(styles.icon, { [styles.empty]: !icon })}>
				<img src={rIcon} />
			</div>
			<div className={styles.content}>
				<div className={classNames("h3", styles.title)}>{title}</div>
				<div className={styles.text}>{text}</div>
			</div>
		</div>
	);
}
