import s from "./services.module.css";
import { ReactComponent as ChevronRightIcon } from "../../../../icons/chevron-right-wide.svg";

const DoctorModalServices = ({ services = [], onClick }) => {
	return (
		<div className={s.container}>
			{services.map((service) => (
				<div
					key={service.id}
					className={s.row}
					onClick={() => onClick(service)}
				>
					<p className={s.name}>{service.name}</p>
					<span className={s.price}>{service.price.toLocaleString()} ₽</span>
					<ChevronRightIcon className={s.icon} />
				</div>
			))}
		</div>
	);
};

export default DoctorModalServices;
