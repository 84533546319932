import { useDispatch, useSelector } from "react-redux";
import {
	selectExaminationInfo,
	selectReceptionType,
	selectReceptionTypeIsOpen,
	setExaminationInfo,
	setReceptionType,
	setReceptionTypeState,
	setSelectedDoctor,
} from "../../services/appointment.slice";
import { useGetProfileQuery } from "../../../profile/services/profile.api";
import { useEffect, useLayoutEffect, useState } from "react";
import { setCreateModalState } from "../../../profile/services/profile.slice";
import OnlineExamination from "./online-examination";
import AdultWarning from "./adults-warning";
import { AuthModal } from "./auth-modal";
import { selectShortCode } from "../../../login/services/login.slice";

export default function ExaminationModal({
	isPublic = false,
	isWidget = false,
	scrollToTopOfWidget,
}) {
	const examinationInfo = useSelector(selectExaminationInfo);
	const { data: profile } = useGetProfileQuery(null, { skip: isPublic });
	const receptionType = useSelector(selectReceptionType);
	const dispatch = useDispatch();
	const isOpenReceptionType = useSelector(selectReceptionTypeIsOpen);
	const isShortCode =
		!!useSelector(selectShortCode) || !!localStorage.getItem("shortCode");

	const cancelExamination = () => {
		dispatch(setExaminationInfo(null));
		dispatch(setReceptionType(null));
		dispatch(setReceptionTypeState(false));
	};

	const [isShowAuth, setIsShowAuth] = useState(false);

	useEffect(() => {
		if (!isOpenReceptionType && !receptionType) {
			//Если закрыто окно выбора типа приема и тип приема не установлен, то отменяем запись
			if (!examinationInfo?.magicLink) cancelExamination();
		}
	}, [isOpenReceptionType]);

	useEffect(() => {
		if (examinationInfo && (profile || isPublic)) {
			if (examinationInfo && !examinationInfo.isPublic && isPublic) {
				if (!isWidget) dispatch(setSelectedDoctor(null));
				dispatch(setExaminationInfo({ ...examinationInfo, isPublic: true }));
				setIsShowAuth(true);
			}

			if (
				examinationInfo.serviceId &&
				!profile?.medCardNumber &&
				examinationInfo.acceptAdultsShowWarning &&
				!isPublic
			) {
				//диагностический прием - есть serviceId, но нет мед карты
				dispatch(setCreateModalState(true));
			}

			if (
				// !examinationInfo.serviceId &&
				!receptionType &&
				examinationInfo.acceptAdultsShowWarning &&
				!isPublic &&
				isShortCode
			) {
				//Если запись на прием ко врачу специалисту, но не выбран тип приема
				dispatch(setReceptionTypeState(true));
			}

			if (
				!examinationInfo.serviceId &&
				receptionType &&
				!profile?.medCardNumber &&
				!isPublic
			) {
				//Если запись на прием ко врачу специалисту
				//И указан тип приема receptionType, но нет мед карты
				dispatch(setCreateModalState(true));
			}
		}
	}, [examinationInfo, profile, receptionType, isPublic, isShortCode]);

	useEffect(() => {
		setTimeout(() => scrollToTopOfWidget?.(), 100);
	}, [isShowAuth]);

	if (!examinationInfo) {
		return null;
	}

	if (!examinationInfo.acceptAdultsShowWarning) {
		return (
			<AdultWarning
				ignoreCallback={() =>
					dispatch(
						setExaminationInfo({
							...examinationInfo,
							acceptAdultsShowWarning: true,
						})
					)
				}
			/>
		);
	}

	if (isShowAuth) {
		return (
			<AuthModal
				isOpen
				isWidget={isWidget}
				onClose={() => setIsShowAuth(false)}
				onSuccessAuth={() => {
					setIsShowAuth(false);
					dispatch(setReceptionTypeState(true));
				}}
			/>
		);
	}

	if (!examinationInfo.serviceId && !receptionType) {
		return null;
	}

	if ((profile?.medCardNumber || isPublic) && receptionType) {
		return (
			<OnlineExamination
				isPublic={isPublic}
				isWidget={isWidget}
				cancelExamination={cancelExamination}
				scrollToTopOfWidget={scrollToTopOfWidget}
			/>
		);
	}

	return null;
}
