import { useEffect, useMemo, useState } from "react";
import Button from "../../../../../../components/button/button";
import Loader from "../../../../../../components/loader";
import { ContentModal } from "../../../../../../components/custom-modal/content.modal";
import { useTranslate } from "../../../../../../localization/translate";
import checkedIcon from "../../../../../../icons/check_icon_blue_18x15.svg";
import { useCalculateApplication } from "../../hooks/useCalculateApplication";
import styles from "./style.module.css";

const getValue = (value, toLocaleString) => {
	if (!value) return " - ";
	if (toLocaleString) return ` ${value.toLocaleString()} `;
	return ` ${value} `;
};

const RadioButton = ({ label, value, checked, onClick }) => (
	<div className={styles.radio} onClick={() => onClick(value)}>
		<div className={styles.text}>{label}</div>
		{checked && <img alt="checked" src={checkedIcon} />}
	</div>
);

const modalStyles = {
	content: {
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
		justifyContent: "space-between",
	},
};

const ProgramApplication = ({
	isOpen,
	program,
	isCreating,
	errors,
	onCreateApplication,
	closeModal,
}) => {
	const translate = useTranslate();
	const { basePrice, ageRatios, distanceRatios, yearRatios } = program;

	const params = useMemo(
		() => ({
			...(ageRatios?.length && { ageRatios }),
			...(distanceRatios?.length && { distanceRatios }),
			...(yearRatios?.length && { yearRatios }),
		}),
		[ageRatios, distanceRatios, yearRatios]
	);

	const [selectedParams, setSelectedParams] = useState(null);
	const { amount, isCalculating } = useCalculateApplication(
		basePrice,
		selectedParams
	);

	useEffect(() => {
		setSelectedParams(
			Object.entries(params).reduce(
				(acc, [key, value]) => ({ [key]: value[0], ...acc }),
				{}
			)
		);
	}, [params]);

	const handleCreateApplication = () =>
		(!isCreating || !isCalculating) &&
		onCreateApplication({ amount, selectedParams });

	return (
		<ContentModal
			isOpen={isOpen}
			closeModal={closeModal}
			className={styles.application}
			styles={modalStyles}
		>
			<>
				<div className={styles.content}>
					<div className={styles.title}>
						{translate("profile.programs.selectParams")}
					</div>

					{selectedParams &&
						Object.entries(params).map(([key, params]) => (
							<div className={styles.content_block} key={key}>
								<div className={styles.content_title}>
									{translate(`profile.programs.params.${key}`)}
								</div>

								{params.map((item) => (
									<RadioButton
										key={item.id}
										label={item.name}
										value={item}
										checked={selectedParams[key].id === item.id}
										onClick={(value) =>
											setSelectedParams((prev) => ({
												...prev,
												[key]: value,
											}))
										}
									/>
								))}
							</div>
						))}

					{errors?.length > 0 && (
						<div className={styles.errors}>
							{errors.map((item, key) => (
								<span key={key} className={styles.error_item}>
									{item.message}
								</span>
							))}
						</div>
					)}
				</div>

				<div className={styles.actions}>
					<Button
						className={styles.payment_btn}
						onClick={handleCreateApplication}
					>
						{isCreating || isCalculating ? (
							<Loader
								width={30}
								height={30}
								white
								containerStyle={{ display: "flex", justifyContent: "center" }}
							/>
						) : (
							<>
								{translate("profile.programs.payment", true)}:
								{getValue(amount, true)}
								{translate("app.currencySign", true)}
							</>
						)}
					</Button>
				</div>
			</>
		</ContentModal>
	);
};

export default ProgramApplication;
