export const TOKEN_LS_KEY = "notification_token";

export const getNotificationToken = () =>
	window.localStorage.getItem(TOKEN_LS_KEY);

export const setNotificationToken = (token) =>
	window.localStorage.setItem(TOKEN_LS_KEY, token || "");

export const isTokenSentedToServer = (token) =>
	getNotificationToken() === token;
