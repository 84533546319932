import { useEffect } from "react";

export const usePushReceivedMessage = (onMessage) => {
	useEffect(() => {
		const serviceWorkerIsAvailable = "serviceWorker" in navigator;

		if (serviceWorkerIsAvailable)
			navigator.serviceWorker.onmessage = ({ data }) => {
				if (data.messageType === "push-received") onMessage(data);
			};

		return () =>
			serviceWorkerIsAvailable && (navigator.serviceWorker.onmessage = null);
	}, [onMessage]);
};
