import { createApi } from "@reduxjs/toolkit/query/react";
import baseConfig from "../../../app/config.api";

export const profileApi = createApi({
	reducerPath: "profileApi",
	baseQuery: baseConfig,
	tagTypes: [
		"Profile",
		"ProfileNotifications",
		"Orders",
		"PaymentMethods",
		"CatalogPrograms",
		"CatalogProgramsHints",
		"Discounts",
		"BonusAccount",
		"SupportIssues",
		"SupportIssue",
		"Counters",
		"QR",
		"SupportCounters",
	],
	endpoints: (builder) => ({
		getProfile: builder.query({
			query: () => "/profile",
			providesTags: ["Profile"],
			transformResponse: (response) => {
				const possibleNullValue = ["firstName", "lastName", "middleName"];
				for (let key in response.data) {
					if (possibleNullValue.includes(key) && !response.data[key]) {
						response.data[key] = "";
					}
				}

				return response.data;
			},
		}),
		createMedCard: builder.mutation({
			query: (form) => ({
				url: "/profile/create_med_card",
				method: "POST",
				body: form,
			}),
			invalidatesTags: ["Profile", "QR"],
		}),
		updateProfile: builder.mutation({
			query: (body) => ({
				method: "PUT",
				url: "/profile/",
				body,
			}),
			invalidatesTags: ["Profile"],
		}),
		getNotifications: builder.query({
			query: () => "/profile/notifications/",
			transformResponse: (response) => response.data,
			providesTags: ["ProfileNotifications"],
		}),
		saveNotifications: builder.mutation({
			query: (body) => ({
				url: "/profile/notifications/",
				method: "PUT",
				body,
			}),
			invalidatesTags: ["ProfileNotifications"], // TODO: mutate store by entyty id
			transformResponse: (response) => response.data,
		}),
		linkCard: builder.mutation({
			query: (medCardNumber) => ({
				url: "/profile/link/sms/",
				body: { medCardNumber },
				method: "POST",
			}),
			transformResponse: (response) => response.data,
		}),
		confirmLinkCard: builder.mutation({
			query: ({ code, s }) => ({
				url: "/profile/link/sms/confirm/",
				body: {
					code,
					s,
				},
				method: "POST",
			}),
			invalidatesTags: ["Profile"],
		}),
		delinkMe: builder.mutation({
			query: ({ medCardNumber, me = null }) => ({
				url: `/profile/delink${me ? "/me" : ""}`,
				method: "POST",
				body: { medCardNumber },
			}),
			invalidatesTags: ["Profile"],
		}),
		getPaymentMethods: builder.query({
			query: () => "/payment/data",
			transformResponse: (response) => response.data,
			providesTags: ["PaymentMethods"],
		}),
		getAccountDetalization: builder.query({
			query: (accountId) => `/account/${accountId}/detalization`,
			transformResponse: (response) => response.data,
		}),
		setDefaultMethod: builder.mutation({
			query: (body) => ({
				url: "/payment/default/",
				method: "PUT",
				body,
			}),
			transformResponse: (response) => response.data,
			invalidatesTags: ["PaymentMethods"],
		}),
		getOrders: builder.query({
			query: ({ medCardNumber }) => `/bills/${medCardNumber}/`,
			transformResponse: (response) => response.data,
			providesTags: ["Orders"],
		}),
		hideBill: builder.mutation({
			query: (billId) => ({
				method: "POST",
				body: { billId },
				url: "/bills/hide/",
			}),
			invalidatesTags: (successResponse, response) => {
				if (successResponse) {
					return ["Orders"];
				} else {
					return false;
				}
			},
		}),
		deleteReceivedBill: builder.mutation({
			query: (id) => ({
				method: "DELETE",
				url: `/receive_delete_invoice/${id}`,
			}),
			transformResponse: (response) => response.data,
			invalidatesTags: (successResponse, response) => {
				if (successResponse) {
					return ["Orders"];
				} else {
					return false;
				}
			},
		}),
		addCard: builder.mutation({
			query: (body) => ({
				method: "POST",
				body,
				url: "/bank_card/",
			}),
			invalidatesTags: ["PaymentMethods"],
		}),
		deleteCard: builder.mutation({
			query: (bank_card_id) => ({
				method: "DELETE",
				url: `/bank_card/${bank_card_id}/`,
			}),
			invalidatesTags: ["PaymentMethods"],
		}),
		depositByCard: builder.mutation({
			query: (body) => ({
				method: "POST",
				body,
				url: "/payment/deposit/by_card/",
			}),
		}),
		getPurchasedProgram: builder.query({
			query: () => "/program/purchased",
			transformResponse: (response) => response.data.purchasedPrograms,
		}),
		getCatalogPrograms: builder.query({
			query: () => "/program/catalog",
			providesTags: ["CatalogPrograms"],
			transformResponse: (response) => response.data,
		}),
		getCatalogProgramById: builder.query({
			query: (id) => `/program/catalog/${id}`,
			providesTags: ["CatalogPrograms"],
			transformResponse: (response) => response.data,
		}),
		getHints: builder.mutation({
			query: (string) => `/program/catalog/search/hints?q=${string}`,
			providesTags: ["CatalogProgramsHints"],
			transformResponse: (response) => response.data,
		}),
		getDiscountData: builder.query({
			query: () => "/discount/data",
			providesTags: ["Discounts"],
			transformResponse: (response) => response.data,
		}),
		getBonusAccountById: builder.query({
			query: (id) => `/bonus_account/${id}/detalization`,
			providesTags: ["BonusAccount"],
			transformResponse: (response) => response.data,
		}),
		programApplication: builder.mutation({
			query: (body) => ({
				method: "POST",
				body,
				url: "/program/catalog/application/",
			}),
			transformResponse: (response) => response.data,
		}),
		getReferrals: builder.query({
			query: () => "/profile/referrals",
			transformResponse: (response) => response.data,
		}),
		getReferralUrl: builder.query({
			query: () => "/profile/referral_url",
			transformResponse: (response) => response.data,
		}),
		getSupportIssues: builder.query({
			query: () => `/issues/`,
			providesTags: ["SupportIssues"],
			transformResponse: (response) => response.data,
		}),
		serviceReview: builder.mutation({
			query: (body) => ({
				method: "POST",
				url: "/service/review/",
				body,
			}),
			transformResponse: (response) => response.data,
		}),
		getSupportIssueById: builder.query({
			query: (id) => `/issues/${id}`,
			providesTags: ["SupportIssue"],
			transformResponse: (response) => response.data,
		}),
		supportRate: builder.mutation({
			query: (body) => ({
				method: "POST",
				url: "/support/rate/",
				body,
			}),
			invalidatesTags: ["SupportIssues", "SupportIssue"],
			transformResponse: (response) => response.data,
		}),
		addComment: builder.mutation({
			query: (body) => ({
				method: "POST",
				url: "/issues/comment/",
				body,
			}),
			invalidatesTags: ["SupportIssue"],
			transformResponse: (response) => response.data,
		}),
		createIssueBlank: builder.mutation({
			query: () => ({
				method: "GET",
				url: "/issues/blank/",
			}),
			invalidatesTags: ["SupportIssues"],
			transformResponse: (response) => response.data,
		}),
		setIssueTopic: builder.mutation({
			query: (body) => ({
				method: "POST",
				url: "/issues/topic/",
				body,
			}),
			invalidatesTags: ["SupportIssues", "SupportIssue"],
			transformResponse: (response) => response.data,
		}),
		markIssueClosed: builder.mutation({
			query: (body) => ({
				method: "POST",
				url: "/issues/mark_closed/",
				body,
			}),
			invalidatesTags: ["SupportIssues", "SupportIssue"],
			transformResponse: (response) => response.data,
		}),
		addIssueAttachment: builder.mutation({
			query: ({ issueId, file }) => {
				const form = new FormData();
				form.append("issue_id", issueId);
				form.append("file", file);

				return {
					method: "POST",
					url: "/issues/attachment/",
					body: form,
				};
			},
			invalidatesTags: ["SupportIssue"],
			transformResponse: (response) => response.data,
		}),
		createIssue: builder.mutation({
			query: (body) => ({
				method: "POST",
				url: "/issues/new/",
				body,
			}),
			invalidatesTags: ["SupportIssues"],
			transformResponse: (response) => response.data,
		}),
		reopenIssue: builder.mutation({
			query: (body) => ({
				method: "POST",
				url: "/issues/reopen/",
				body,
			}),
			invalidatesTags: ["SupportIssues", "SupportIssue"],
			transformResponse: (response) => response.data,
		}),
		coffeeCredit: builder.mutation({
			query: (body) => ({
				method: "POST",
				url: "/coffee/credit",
				body,
			}),
			transformResponse: (response) => response.data,
		}),
		coffeeStatus: builder.mutation({
			query: (body) => ({
				method: "POST",
				url: "/coffee/status",
				body,
			}),
			transformResponse: (response) => response.data,
		}),
		getAboutPage: builder.query({
			query: (id) => `/info_page/about`,
			transformResponse: (response) => response.data,
		}),
		getContracts: builder.query({
			query: () => "/profile/contracts",
			transformResponse: (response) => response.data,
		}),
		getCounters: builder.query({
			query: () => "/profile/counters",
			providesTags: ["Counters"],
			transformResponse: (response) => response.data,
		}),
		getQr: builder.query({
			query: () => "/profile/pass",
			providesTags: ["QR"],
		}),
		getSupportCounters: builder.query({
			query: () => "/support/counters",
			providesTags: ["SupportCounters"],
			transformResponse: (response) => response.data,
		}),
	}),
});

export const {
	useDeleteReceivedBillMutation,
	useGetReferralUrlQuery,
	useGetReferralsQuery,
	useProgramApplicationMutation,
	useGetBonusAccountByIdQuery,
	useGetDiscountDataQuery,
	useGetHintsMutation,
	useGetCatalogProgramByIdQuery,
	useGetCatalogProgramsQuery,
	useGetPurchasedProgramQuery,
	useDepositByCardMutation,
	useDeleteCardMutation,
	useAddCardMutation,
	useHideBillMutation,
	useGetOrdersQuery,
	useSetDefaultMethodMutation,
	useGetAccountDetalizationQuery,
	useGetPaymentMethodsQuery,
	useDelinkMeMutation,
	useConfirmLinkCardMutation,
	useLinkCardMutation,
	useGetProfileQuery,
	useCreateMedCardMutation,
	useUpdateProfileMutation,
	useGetNotificationsQuery,
	useSaveNotificationsMutation,
	useGetSupportIssuesQuery,
	useServiceReviewMutation,
	useGetSupportIssueByIdQuery,
	useSupportRateMutation,
	useAddCommentMutation,
	useCreateIssueBlankMutation,
	useSetIssueTopicMutation,
	useMarkIssueClosedMutation,
	useAddIssueAttachmentMutation,
	useCreateIssueMutation,
	useReopenIssueMutation,
	useGetAboutPageQuery,
	useGetContractsQuery,
	useGetCountersQuery,
	useLazyGetCountersQuery,
	useGetQrQuery,
	useGetSupportCountersQuery,
	useCoffeeCreditMutation,
	useCoffeeStatusMutation
} = profileApi;
