import Modal from "react-modal";

export const MODAL_OPEN_HTML_CLASS = "ReactModal__Html--open";
export default function ModalWrapper(props) {
	const style = {
		...props.style,
		touchAction: "none",
		overlay: {
			touchAction: "none",
			...props.style?.overlay,
		},
		content: {
			top: 0,
			bottom: 0,
			...props.style?.content,
		},
	};

	return (
		<Modal {...props} style={style} htmlOpenClassName={MODAL_OPEN_HTML_CLASS} preventScroll>
			{props.children}
		</Modal>
	);
}
