import { useMemo } from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import Slider from "../../../../components/slider";
import SliderItem from "../slider-item";
import { Metrics } from "../../../../utils/metrics";
import styles from "./style.module.css";

export default function SliderWrapper({ collecition }) {
	const isTablet = useMediaQuery("(max-width: 1240px)");
	const isMobile = useMediaQuery("(max-width: 560px)");

	const showSlides = useMemo(
		() => (isTablet ? (isMobile ? 1 : 2) : 3),
		[isMobile, isTablet]
	);

	return (
		<div className={styles.wrapper}>
			<Slider show={showSlides} infiniteLoop withIndicator>
				{collecition.map((item) => (
					<SliderItem
						key={item.id}
						title={item.title}
						image={item.image}
						link={item.link}
						onClick={() =>
							Metrics.gtmEvent("select_promotion", { ItemId: item.id })
						}
					/>
				))}
			</Slider>
		</div>
	);
}
