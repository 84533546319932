import Loader from "../../components/loader";
import { useGetBannersQuery } from "./services/banners.api";
import SliderWrapper from "./modules/slider-wrapper";
import {useMediaQuery} from "beautiful-react-hooks";

export default function Banners() {
	const { data, isLoading } = useGetBannersQuery();
	const isMobile = useMediaQuery("(max-width: 620px)");

	return (
		<>
			{isLoading && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}

			{data?.banners?.length > 0 && (
				<SliderWrapper
					collecition={[
						...data.banners.filter(banner => isMobile && banner.visibleOnMobile || !isMobile && banner.visibleOnDesktop),
					]}
				/>
			)}
		</>
	);
}
