import styles from "./system-notification.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
	resetNotification,
	selectSystemNotifications,
} from "../../app/app.slice";
import CloseModalButton from "../modal-items/close";
import { useEffect, useState } from "react";
import classNames from "classnames";

const DISPLAY_TIME = 3000;
const INTERVAL = 200;

export default function SystemNotifications() {
	const systemNotifications = useSelector(selectSystemNotifications);
	const dispatch = useDispatch();
	const [hide, setHidden] = useState(false);
	const close = () => {
		setHidden(true);
		setTimeout(() => {
			dispatch(resetNotification(systemNotifications[0].id));
			setHidden(false);
		}, INTERVAL);
	};

	useEffect(() => {
		if (systemNotifications && systemNotifications.length > 0) {
			const timeout = setTimeout(() => {
				close();
			}, DISPLAY_TIME);

			return () => clearTimeout(timeout);
		}
	}, [systemNotifications]);

	if (!systemNotifications || systemNotifications.length === 0) {
		return null;
	}

	return (
		<div
			className={classNames(
				styles.notify,
				{ [styles.hidden]: hide },
				styles[systemNotifications[0].type]
			)}
		>
			<div className={styles.icon} />
			{systemNotifications[0].title && (
				<div className={styles.title}>title</div>
			)}
			<div className={classNames(styles.description, "text")}>
				{systemNotifications[0].clientMessage}
			</div>
			{systemNotifications[0].btn && (
				<div className={classNames("text", styles.btn)}>button</div>
			)}
			<CloseModalButton className={styles.closeBtn} closeModal={close} />
		</div>
	);
}
