import ActivityTimerProvider from "./activity-timer-provider";
import React, { useEffect, useMemo } from "react";
import {
	Outlet,
	useNavigate,
	useLocation,
	useSearchParams,
	useMatch, Navigate
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAccessToken,
	selectBiometryAllowed,
	selectBiometryAsked,
	selectExpired,
	selectIsGuestMode,
	selectLastUrl,
	selectShortCode,
	setLastUrl,
} from "./services/login.slice";
import Layout from "../../components/layout/layout";
import ShortCode from "./modules/short-code/short-code";
import PaymentModal from "../payments/payment-modal";
import {
	isOpenPaymentModal,
	paymentData,
	initInvoice,
	paymentTarget,
	resetPaymentData,
	initAmount,
	initDiscount,
	initDate,
	initMedCardNumber,
} from "../payments/services/payments.slice";
import { setupNotifications } from "../../utils/firebase/init";
import { useSendNotificationTokenMutation } from "./services/login.api";
import { useGetProfileQuery } from "../profile/services/profile.api";
import { Metrics } from "../../utils/metrics";
import BiometryAccess from "./modules/biometry/access";
import { isAndroidApp, isBiometryAvailable, isIOSApp } from "../../utils";

export default function Middleware () {
	const queryParams = useSearchParams()
	const urlMatch = useMatch("/coffee/:id");

	if (urlMatch && !isAndroidApp && !isIOSApp && queryParams[0].get("web") !== "1") {
		 return <Outlet/>
	}

	return <AuthRequiredChecker/>
}

function AuthRequiredChecker() {
	const isGuest = useSelector(selectIsGuestMode);
	const lastURL = useSelector(selectLastUrl);
	const expired = useSelector(selectExpired);
	const isAuth = !!useSelector(selectAccessToken);
	const navigate = useNavigate();
	const location = useLocation();
	const [params] = useSearchParams();
	const shortCode = useSelector(selectShortCode);
	const dispatch = useDispatch();
	const isOpen = useSelector(isOpenPaymentModal);
	const paymentConfig = useSelector(paymentData);
	const targetConfig = useSelector(paymentTarget);
	const invoice = useSelector(initInvoice);
	const amount = useSelector(initAmount);
	const discount = useSelector(initDiscount);
	const date = useSelector(initDate);
	const medCardNumber = useSelector(initMedCardNumber);
	const [sendNotificationToken] = useSendNotificationTokenMutation();

	const isMobileApp = isAndroidApp || isIOSApp;
	const isBiometryAllowed = useSelector(selectBiometryAllowed);
	const isBiometryAsked = useSelector(selectBiometryAsked);

	const { data: profile } = useGetProfileQuery(null, {
		skip: !shortCode,
	});

	const isAuthLink = useMemo(() => {
		const accessToken = params.get("accessToken");
		const refreshToken = params.get("refreshToken");
		const shortCode = params.get("shortCode");
		return !!accessToken && !!refreshToken && !!shortCode;
	}, [params]);

	useEffect(() => {

		if ((isGuest || !isAuth) && !isAuthLink) {
			if (location.pathname !== "/login") {
				dispatch(setLastUrl(location.pathname + location.search));
			}
			navigate("/login");
		}
	}, [isGuest, isAuth]);

	useEffect(() => {
		if (lastURL && !expired) {
			navigate(lastURL);
			dispatch(setLastUrl(null));
		}
	}, [lastURL, !expired]);

	useEffect(() => {
		if (
			expired &&
			!location.pathname.includes("widget") &&
			!params.get("widget")
		) {
			dispatch(setLastUrl(location.pathname + location.search));
			navigate("/refresh-session", { state: { from: location.pathname } });
		}
	}, [expired]);

	useEffect(() => {
		if (isAuth && !isGuest && shortCode) {
			setupNotifications()
				.then((token) => token && sendNotificationToken(token))
				.catch(console.warn);
		}
	}, [shortCode]);

	useEffect(() => {
		if (profile) {
			const { dateOfBirth, sex } = profile;
			Metrics.config = { dateOfBirth, sex };
		}
	}, [profile]);

	const handleClosePaymentModal = () => dispatch(resetPaymentData());

	if ((isGuest || !isAuth) && !isAuthLink) {
		return <Navigate to={"/login"} replace={true}/>
	}

	if (!shortCode && !isGuest && isAuth && !isAuthLink) {
		// если польователь авторизовался, но шорт код не установлен, то ноадо показать компонент установки этого кода
		return <ShortCode />;
	}

	if (
		!!shortCode &&
		isMobileApp &&
		isBiometryAvailable &&
		!isBiometryAsked &&
		!isBiometryAllowed
	) {
		return <BiometryAccess />;
	}

	return (
		<ActivityTimerProvider time={5 * 60 * 1000}>
			<Layout>
				<Outlet />
			</Layout>

			<PaymentModal
				isOpen={isOpen}
				onClose={handleClosePaymentModal}
				{...paymentConfig}
				target={targetConfig}
				initInvoice={invoice}
				initAmount={amount}
				initDiscount={discount}
				initDate={date}
				initMedCardNumber={medCardNumber}
			/>
		</ActivityTimerProvider>
	);
}
