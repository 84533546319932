import { DepositPayment, ServicePayment, UrlPayment } from "../modules";

export const PAYMENT_TYPES = {
	service: "SERVICE",
	deposit: "DEPOSIT",
	test: "TEST",
	program: "PROGRAM",
	opinion: "OPINION",
	invoice: "INVOICE",
	other: "OTHER",
};

export const PAYMENT_METHODS = {
	card: "CARD",
	url: "URL",
	account: "ACCOUNT",
	certificate: "CERTIFICATE",
};

export const DEPOSIT_TYPES = {
	refill: "refill",
	transfer: "transfer",
};

export const DISCOUNT_METHODS = {
	discountCard: "DISCOUNT_CARD",
	coupon: "COUPON",
};

/*
  define relations between PAYMENT_TYPES and modules
*/
export const StartScreens = {
	OTHER: ServicePayment,
	PROGRAM: ServicePayment,
	SERVICE: ServicePayment,
	OPINION: ServicePayment,
	INVOICE: ServicePayment,
	DEPOSIT: DepositPayment,
	TEST: UrlPayment,
};

export const TAGS_TO_INVALIDATE = [
	{
		type: "profileApi/invalidateTags",
		payload: ["PaymentMethods"],
	},
	{
		type: "eventsApi/invalidateTags",
		payload: ["Planned"],
	},
	{
		type: "eventsApi/invalidateTags",
		payload: ["History"],
	},
];
