import { Routes, Route } from "react-router-dom";
import React from "react";
import styles from "./events.module.css";
import {
	selectedRescheduleDoctor,
	setRescheduleDoctor,
} from "./services/events.slice";
import { useTranslate } from "../../localization/translate";
import Plan from "./modules/plan/plan";
import History from "./modules/history/history";
import AppointmentCancel from "./modules/appointment-actions/cancel";
import ExaminationModal from "../appointment/modules/examination-modal/examination-modal";
import ReceptionTypeModal from "../appointment/modules/reception-type-modal/reception-type-modal";
import DoctorModal from "../appointment/modules/doctor-modal/doctor-modal";
import { useDispatch, useSelector } from "react-redux";
import RateVisit from "../appointment/modules/rate-visit/rate-visit";
import PatientSelectorContainer from "../appointment/modules/patientSelector/container";
import {
	selectedDoctor,
	setSelectedDoctor,
	selectRateInfo,
	setRateInfo,
} from "../appointment/services/appointment.slice";
import SubscribeModal from "../appointment/modules/subscribe-modal/subscribe-modal";
import AttachCard from "../profile/modules/share-access/attach-card";
import { useEffect } from "react";
import { Metrics } from "../../utils/metrics";
import Footer from "../../components/footer";

export default function Events() {
	const rescheduleDoctor = useSelector(selectedRescheduleDoctor);
	const dispatch = useDispatch();
	const translate = useTranslate();
	const rateInfo = useSelector(selectRateInfo);
	const doctorInfo = useSelector(selectedDoctor);

	useEffect(() => {
		Metrics.gtmEvent("view_events");
	}, []);

	return (
		<div className={styles.events}>
			<div className={styles.title}>{translate("events.title", true)}</div>

			<Routes>
				<Route path={"/"} element={<Plan />} />
				<Route path={"/:selected"} element={<Plan />} />
				<Route path={"/:selected/payment"} element={<Plan isPaymentPage />} />
				<Route path={"/history"} element={<History />} />
				<Route path={"/history/:selected"} element={<History />} />
			</Routes>

			<Footer isInApp />

			<AppointmentCancel />
			<RateVisit
				rateInfo={rateInfo}
				clearRateInfo={() => dispatch(setRateInfo(null))}
			/>
			<DoctorModal
				doctorInfo={rescheduleDoctor}
				setSelectedDoctor={(arg) => dispatch(setRescheduleDoctor(arg))}
			/>
			<ExaminationModal />
			<ReceptionTypeModal />
			<PatientSelectorContainer />
			<SubscribeModal />
			<DoctorModal
				doctorInfo={doctorInfo}
				setSelectedDoctor={(data) => dispatch(setSelectedDoctor(data))}
			/>
			<AttachCard />
		</div>
	);
}
