import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { loginApi } from "../features/login/services/login.api";
import appSlice from "./app.slice";
import loginReducer from "../features/login/services/login.slice";
import { rtkQueryErrorHandler } from "./error-handler.api";
import timerSlice from "../components/timer/timerSlice";
import { profileApi } from "../features/profile/services/profile.api";
import profileSlice from "../features/profile/services/profile.slice";
import appointmentSlice from "../features/appointment/services/appointment.slice";
import { applicationApi } from "./applcation.api";
import { appointmentApi } from "../features/appointment/services/appointment.api";
import { historyApi } from "../features/history/services/history.api";
import eventsSlice from "../features/events/services/events.slice";
import { eventsApi } from "../features/events/services/events.api";
import { paymentsApi } from "../features/payments/services/payments.api";
import { paymentSlice } from "../features/payments/services/payments.slice";
import { bannersApi } from "../features/banners/services/banners.api";
import { receivedApi } from "../features/received-bills/services/received-bills.api";
import { receivedSlice } from "../features/received-bills/services/received-bills.slice";
import { notificationsApi } from "../features/notifications/services/notifications.api";
import { agreementsSlice } from "../features/agreements/services/agreements.slice";
import { agreementsApi } from "../features/agreements/services/agreements.api";

export const store = configureStore({
	reducer: {
		[receivedApi.reducerPath]: receivedApi.reducer,
		[bannersApi.reducerPath]: bannersApi.reducer,
		[paymentsApi.reducerPath]: paymentsApi.reducer,
		[notificationsApi.reducerPath]: notificationsApi.reducer,
		[loginApi.reducerPath]: loginApi.reducer,
		[profileApi.reducerPath]: profileApi.reducer,
		[applicationApi.reducerPath]: applicationApi.reducer,
		[appointmentApi.reducerPath]: appointmentApi.reducer,
		[historyApi.reducerPath]: historyApi.reducer,
		[eventsApi.reducerPath]: eventsApi.reducer,
		[agreementsApi.reducerPath]: agreementsApi.reducer,
		authentication: loginReducer,
		timers: timerSlice,
		application: appSlice,
		profile: profileSlice.reducer,
		appointment: appointmentSlice.reducer,
		visitEvents: eventsSlice.reducer,
		payments: paymentSlice.reducer,
		received: receivedSlice.reducer,
		agreements: agreementsSlice.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat(
			rtkQueryErrorHandler,
			loginApi.middleware,
			profileApi.middleware,
			paymentsApi.middleware,
			notificationsApi.middleware,
			bannersApi.middleware,
			historyApi.middleware,
			receivedApi.middleware,
			appointmentApi.middleware,
			eventsApi.middleware,
			agreementsApi.middleware,
			applicationApi.middleware
		),
	devTools: process.env.NODE_ENV !== "production",
});

setupListeners(store.dispatch);
