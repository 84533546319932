import { useMemo, useRef, useEffect, useContext } from "react";
import classNames from "classnames";
import moment from "moment";
import Loader from "../../../../../../components/loader";
import { useTranslate } from "../../../../../../localization/translate";
import { mapIssueHistory } from "../../utils/map-issue-history";
import { scrollToBottom } from "../../utils/scroll-bottom";
import { fileViewer } from "../../../../../../utils";
import { useReopenIssueMutation } from "../../../../services/profile.api";
import IssueContext from "../../contexts/issue-context";
import styles from "./style.module.css";
import { convertDate } from "../../../../../../utils/dates/timezone-converter";

const FileIcon = () => (
	<svg
		width="12"
		height="15"
		viewBox="0 0 12 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.78724 1.74707C6.61252 0.94228 7.79566 0.59295 8.94013 0.816159C10.322 1.08566 11.4021 2.13897 11.6785 3.48649C11.9074 4.60253 11.5491 5.75629 10.7239 6.56108L6.46472 10.7144C5.99009 11.1773 5.30966 11.3782 4.65146 11.2498C3.85675 11.0948 3.23555 10.489 3.07661 9.71407C2.94497 9.07222 3.15099 8.40868 3.62562 7.94584L7.95855 3.72053C8.16333 3.52083 8.49535 3.52083 8.70014 3.72053C8.90492 3.92022 8.90492 4.244 8.70014 4.44369L4.3672 8.66901C4.14052 8.89006 4.04213 9.20696 4.105 9.5135C4.18091 9.88362 4.47759 10.1729 4.85714 10.247C5.17149 10.3083 5.49646 10.2123 5.72314 9.99126L9.98227 5.83791C10.5596 5.27492 10.8102 4.4678 10.6501 3.68706C10.4567 2.74439 9.70113 2.00754 8.73445 1.81901C7.93383 1.66286 7.10616 1.90724 6.52882 2.47024L2.19844 6.69306C1.34195 7.52828 0.928872 8.70232 1.07911 9.87437C1.30183 11.6119 2.70529 12.9805 4.48708 13.1977C5.68898 13.3442 6.89292 12.9414 7.74941 12.1062L11.1048 8.83408C11.3096 8.63439 11.6416 8.63439 11.8464 8.83408C12.0512 9.03378 12.0512 9.35755 11.8464 9.55725L8.491 12.8293C7.40566 13.8877 5.88003 14.3982 4.35699 14.2125C2.09913 13.9373 0.320683 12.203 0.0384492 10.0012C-0.151931 8.51601 0.371522 7.02827 1.45686 5.96989L5.78724 1.74707Z"
			fill="#7A8CA4"
		/>
	</svg>
);

export default function IssueChat({ issue }) {
	const translate = useTranslate();
	const ref = useRef();

	const { deleteComment } = useContext(IssueContext);
	const [reopenIssue, { isLoading }] = useReopenIssueMutation();

	const history = useMemo(
		() => mapIssueHistory(issue.history),
		[issue.history]
	);

	useEffect(() => scrollToBottom(ref.current), [issue]);

	const handleReopen = () => reopenIssue({ issueId: issue.id });

	const handleOpenFile = (attachment) => fileViewer(attachment.url);

	const handleDeleteMessage = (item) => deleteComment(item);

	return (
		<div ref={ref} className={styles.chat}>
			<div className={classNames(styles.message_row, styles.self)}>
				<div
					className={classNames(styles.message, styles.self, styles.default)}
				>
					{issue.description}
				</div>
			</div>

			{issue.topic && (
				<div className={classNames(styles.message_row, styles.self)}>
					<div
						className={classNames(styles.message, styles.self, styles.default)}
					>
						{issue.topic}
					</div>
				</div>
			)}

			{Object.entries(history).map(([date, messages]) => (
				<div key={date} className={styles.date_item}>
					<div className={styles.date}>{date}</div>

					{messages.map((item) => (
						<div
							key={item.creationDate}
							className={classNames(styles.message_row, {
								[styles.self]: item.author === "user",
								[styles.error]: item.error,
							})}
						>
							<div className={styles.message_wrap}>
								<div
									className={classNames(styles.message, {
										[styles.self]: item.author === "user",
									})}
								>
									{item.comment && (
										<span className={styles.message_text}>{item.comment}</span>
									)}

									{item.attachments && (
										<div
											className={styles.attachment}
											onClick={() => handleOpenFile(item.attachments)}
										>
											<div className={styles.file}>
												<FileIcon />

												<span className={styles.file_name}>
													{item.attachments.name}
												</span>
											</div>
										</div>
									)}

									<span className={styles.message_time}>
										{moment(convertDate(item.creationDate)).format("HH:mm")}
									</span>
								</div>

								{item.error && (
									<button
										className={styles.close_btn}
										onClick={() => handleDeleteMessage(item)}
									>
										<svg
											width="16"
											height="17"
											viewBox="0 0 16 17"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M7.99935 12.8307C10.3926 12.8307 12.3327 10.8906 12.3327 8.4974C12.3327 6.10416 10.3926 4.16406 7.99935 4.16406C5.60611 4.16406 3.66602 6.10416 3.66602 8.4974C3.66602 10.8906 5.60611 12.8307 7.99935 12.8307ZM7.99935 13.8307C10.9449 13.8307 13.3327 11.4429 13.3327 8.4974C13.3327 5.55188 10.9449 3.16406 7.99935 3.16406C5.05383 3.16406 2.66602 5.55188 2.66602 8.4974C2.66602 11.4429 5.05383 13.8307 7.99935 13.8307Z"
												fill="#F7236F"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M6.31246 6.81051C6.50772 6.61525 6.82431 6.61525 7.01957 6.81051L9.68624 9.47718C9.8815 9.67244 9.8815 9.98902 9.68624 10.1843C9.49097 10.3795 9.17439 10.3795 8.97913 10.1843L6.31246 7.51762C6.1172 7.32235 6.1172 7.00577 6.31246 6.81051Z"
												fill="#F7236F"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M9.68624 6.81051C9.8815 7.00577 9.8815 7.32235 9.68624 7.51762L7.01957 10.1843C6.82431 10.3795 6.50772 10.3795 6.31246 10.1843C6.1172 9.98902 6.1172 9.67244 6.31246 9.47718L8.97913 6.81051C9.17439 6.61525 9.49097 6.61525 9.68624 6.81051Z"
												fill="#F7236F"
											/>
										</svg>
									</button>
								)}
							</div>

							{item.error && (
								<div className={styles.error_text}>{item.error}</div>
							)}
						</div>
					))}
				</div>
			))}

			{issue.isClosed && (
				<div className={styles.extra_messages}>
					<button
						className={styles.reopen_btn}
						disabled={isLoading}
						onClick={handleReopen}
					>
						{isLoading ? (
							<Loader
								width={20}
								height={20}
								containerStyle={{ display: "flex", justifyContent: "center" }}
							/>
						) : (
							translate("profile.support.detail.reopen")
						)}
					</button>
				</div>
			)}
		</div>
	);
}
