import ListItemRow from "../../../../../../components/list-item-row";
import styles from "./style.module.css";

const ProgramsList = ({
	title,
	collection,
	renderItem,
	renderSubheader,
	handkeClickItem = () => ({}),
}) => {
	return (
		<div className={styles.programs_list}>
			{title && <div className={styles.header}>{title}</div>}

			{renderSubheader && (
				<div className={styles.subheader}>{renderSubheader()}</div>
			)}

			<div className={styles.list}>
				{collection.map((entity) =>
					renderItem ? (
						renderItem(entity)
					) : (
						<ListItemRow
							key={entity.id}
							title={entity.name}
							onClick={() => handkeClickItem(entity)}
						/>
					)
				)}
			</div>
		</div>
	);
};

export default ProgramsList;
