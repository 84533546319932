import { createContext } from "react";

export const InitialPayment = {
	invoice: null,
	date: null,
	medCardNumber: null,
	discount: {
		id: null,
		name: null,
		description: null,
		type: null,
	},
	checkout: {
		amount: null,
		discounted: null,
		paymentMethod: null,
		isDiscountAvailable: false,
		isPaymentAvailable: false,
	},
	results: {
		type: null,
		orderNumber: null,
		isLink: false,
	},
};

const PaymentContext = createContext({
	payment: InitialPayment,
	setPayment: () => {},
});

export const PaymentProvider = PaymentContext.Provider;

export default PaymentContext;
