import React, { useState } from "react";
import styles from "./event-item.module.css";
import cl from "classnames";
import { useTranslate } from "../../../../localization/translate";
import { useDispatch, useSelector } from "react-redux";
import {
	selectWillBeCanceled,
	setAppointmentToCancel,
	setRescheduleDoctor,
} from "../../services/events.slice";
import { ConfirmAction } from "../history/history-card";
import moment from "moment";
import { useGetProfileQuery } from "../../../profile/services/profile.api";
import {
	setRateInfo,
	setSelectedDoctor,
} from "../../../appointment/services/appointment.slice";
import classNames from "classnames";
import { moscowTimeZone } from "../../../../utils/dates/timezone-converter";
import { useMediaQuery } from "beautiful-react-hooks";
import { Stars } from "../../../appointment/modules/rate-visit/rate-visit";
import { ReactComponent as RedoIcon } from "../../../../icons/redo.svg";

export const NoAvatarIcon = () => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.2796 11.0569C19.2796 9.04434 17.6995 7.41464 15.7481 7.41464C13.7967 7.41464 12.2165 9.04434 12.2165 11.0569C12.2165 13.0695 13.7967 14.6992 15.7481 14.6992C17.6995 14.6992 19.2796 13.0695 19.2796 11.0569ZM21.2976 11.0569C21.2976 14.219 18.814 16.7805 15.7481 16.7805C12.6822 16.7805 10.1985 14.219 10.1985 11.0569C10.1985 7.89487 12.6822 5.33334 15.7481 5.33334C18.814 5.33334 21.2976 7.89487 21.2976 11.0569ZM8.68501 23.6934C8.68501 24.6751 8.60416 24.5854 9.145 24.5854H22.8557C23.3965 24.5854 23.3156 24.6751 23.3156 23.6934C23.3156 21.3122 19.9819 19.9024 16.0003 19.9024C12.0188 19.9024 8.68501 21.3122 8.68501 23.6934ZM6.66699 23.6934C6.66699 19.6554 11.0046 17.8211 16.0003 17.8211C20.996 17.8211 25.3337 19.6554 25.3337 23.6934C25.3337 25.7918 24.5455 26.6667 22.8557 26.6667H9.145C7.45514 26.6667 6.66699 25.7918 6.66699 23.6934Z"
			fill="#54A0FF"
		/>
	</svg>
);

export default function EventListItem(item) {
	const {
		confirmCallback,
		service,
		slotIdMis,
		doctor,
		isPaid,
		type,
		hasReview,
		sourceDate,
		speciality,
		photoUrl,
		shortname,
		specialities = [],
		cabinet,
		patient,
		isSelected,
		scheduleIdMis,
		handlePayOnline,
		isOnline,
		additionalSlotsData,
		rating,
	} = item;
	const translate = useTranslate();
	const dispatch = useDispatch();
	const [asked, setAsked] = useState(false);
	const date = moment(sourceDate).format("H:mm");
	const eventInfo = useSelector(selectWillBeCanceled);
	const { data: profile } = useGetProfileQuery();
	const patientFirstname =
		profile &&
		(profile.medCardNumber === patient.medCardNumber ? (
			<>{translate("events.you", true)}</>
		) : (
			patient.firstName
		));
	const patientLastname =
		profile &&
		(profile.medCardNumber === patient.medCardNumber ? null : patient.lastName);
	const isMobile = useMediaQuery("(max-width: 426px)");

	const onRatingChange = (rating) => {
		if (doctor) {
			dispatch(
				setRateInfo({
					slotIdMis: scheduleIdMis,
					doctorId: doctor.id,
					date: sourceDate,
					rating,
				})
			);
		}
	};

	const onCancelDelete = () => {
		if (!eventInfo) {
			dispatch(setAppointmentToCancel(null));
			setAsked(false);
		}
	};

	return (
		<div
			className={cl(styles.card, {
				[styles.selected_card]: isSelected,
			})}
		>
			<div className={styles.event_info}>
				<div className={styles.cabinet_info}>{`${date}${
					moscowTimeZone() ? "" : translate("app.byMskTime", true)
				}, ${isMobile ? "\r\n" : ""} ${
					isOnline
						? translate("appointment.confirm.isOnline", true)
						: `${translate("appointment.confirm.cabinet", true)} ${cabinet}`
				}`}</div>
				<div className={styles.event_creator}>
					{patientFirstname} {patientLastname}
				</div>
			</div>
			<div className={styles.doctor}>
				<div className={styles.doctor_photo}>
					{photoUrl ? (
						<img src={photoUrl} className={styles.avatar} />
					) : (
						<div className={styles.no_avatar}>
							<NoAvatarIcon />
						</div>
					)}

					<div
						className={styles.info}
						onClick={(e) => {
							e.stopPropagation();
							if (doctor) {
								const props = { id: doctor.id };
								if (speciality) {
									props.specialityId = speciality.id;
								}
								dispatch(setSelectedDoctor(props));
							}
						}}
					>
						<svg
							width="14"
							height="14"
							viewBox="0 0 14 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M6.99967 12.4168C9.99122 12.4168 12.4163 9.9917 12.4163 7.00016C12.4163 4.00862 9.99122 1.5835 6.99967 1.5835C4.00813 1.5835 1.58301 4.00862 1.58301 7.00016C1.58301 9.9917 4.00813 12.4168 6.99967 12.4168ZM6.99967 13.6668C10.6816 13.6668 13.6663 10.6821 13.6663 7.00016C13.6663 3.31826 10.6816 0.333496 6.99967 0.333496C3.31778 0.333496 0.333008 3.31826 0.333008 7.00016C0.333008 10.6821 3.31778 13.6668 6.99967 13.6668Z"
								fill="#7A8CA4"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M6.99967 5.54183C7.34485 5.54183 7.62467 5.82165 7.62467 6.16683V10.3335C7.62467 10.6787 7.34485 10.9585 6.99967 10.9585C6.6545 10.9585 6.37467 10.6787 6.37467 10.3335V6.16683C6.37467 5.82165 6.6545 5.54183 6.99967 5.54183Z"
								fill="#7A8CA4"
							/>
							<path
								d="M7.83301 3.66683C7.83301 4.12707 7.45991 4.50016 6.99967 4.50016C6.53944 4.50016 6.16634 4.12707 6.16634 3.66683C6.16634 3.20659 6.53944 2.8335 6.99967 2.8335C7.45991 2.8335 7.83301 3.20659 7.83301 3.66683Z"
								fill="#7A8CA4"
							/>
						</svg>
					</div>
				</div>
				<div className={styles.doctor_info}>
					<div className={styles.doctor_name}>
						{shortname || doctor?.name || "_"}
					</div>
					<div className={styles.doctor_profession}>{specialities}</div>
				</div>
			</div>
			<div
				className={classNames(styles.modal_buttons, {
					[styles.history]: type === "history",
				})}
			>
				{type === "plan" ? (
					<>
						<div className={styles.event_change}>
							{!isPaid && (
								<>
									<div
										className={styles.change_button}
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											handlePayOnline(item);
										}}
									>
										<svg
											width="17"
											height="15"
											viewBox="0 0 17 15"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M0.5 1.81034C0.5 0.810519 1.30878 0 2.30645 0H14.6935C15.6912 0 16.5 0.810519 16.5 1.81034V6.98276C16.5 7.41126 16.1534 7.75862 15.7258 7.75862C15.2982 7.75862 14.9516 7.41126 14.9516 6.98276V4.65517H2.04839V10.0862C2.04839 10.229 2.16393 10.3448 2.30645 10.3448H13.8567L12.0816 8.56586C11.7793 8.26287 11.7793 7.77162 12.0816 7.46862C12.3839 7.16563 12.8741 7.16563 13.1765 7.46862L16.0908 10.3892C16.124 10.4225 16.1545 10.4575 16.1822 10.4939C16.3749 10.6351 16.5 10.8632 16.5 11.1207C16.5 11.3781 16.3749 11.6063 16.1822 11.7474C16.1545 11.7839 16.124 11.8189 16.0908 11.8522L13.1765 14.7728C12.8741 15.0757 12.3839 15.0757 12.0816 14.7728C11.7793 14.4698 11.7793 13.9785 12.0816 13.6755L13.8567 11.8966H2.30645C1.30878 11.8966 0.5 11.086 0.5 10.0862V1.81034ZM14.9516 1.81034V3.10345H2.04839V1.81034C2.04839 1.66751 2.16393 1.55172 2.30645 1.55172H14.6935C14.8361 1.55172 14.9516 1.66751 14.9516 1.81034Z"
												fill="#54A0FF"
											/>
										</svg>
										<div className={styles.button_text}>
											{translate("app.pay", true)}
										</div>
									</div>
								</>
							)}
							<div
								className={styles.change_button}
								onClick={(e) => {
									e.stopPropagation();

									if (doctor) {
										const o = {
											id: doctor.id,
											rescheduleInfo: {
												oldDate:
													additionalSlotsData?.parentSlot?.date || sourceDate,
												oldCabinet: cabinet,
												oldSlotIdMis:
													additionalSlotsData?.parentSlot?.scheduleIdMis ||
													slotIdMis,
												oldIsPaid: isPaid,
											},
										};
										if (speciality) {
											o.specialityId = speciality.id;
										}

										if (patient) {
											o.patient = patient;
										}

										if (service) {
											o.service = service;
										}

										dispatch(setRescheduleDoctor(o));
									}
								}}
							>
								<svg
									width="17"
									height="16"
									viewBox="0 0 17 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M8.5 14.5C12.0899 14.5 15 11.5899 15 8C15 4.41015 12.0899 1.5 8.5 1.5C4.91015 1.5 2 4.41015 2 8C2 11.5899 4.91015 14.5 8.5 14.5ZM8.5 16C12.9183 16 16.5 12.4183 16.5 8C16.5 3.58172 12.9183 0 8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 12.4183 4.08172 16 8.5 16Z"
										fill="#54A0FF"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M8.35714 4C8.83053 4 9.21429 4.36162 9.21429 4.80769V9.38462H10.6429C11.1162 9.38462 11.5 9.74623 11.5 10.1923C11.5 10.6384 11.1162 11 10.6429 11H8.64286C8.01167 11 7.5 10.5178 7.5 9.92308V4.80769C7.5 4.36162 7.88376 4 8.35714 4Z"
										fill="#54A0FF"
									/>
								</svg>
								<div className={styles.button_text}>
									{translate("appointment.moveAppointment")}
								</div>
							</div>
							<div
								className={styles.change_button}
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									setAsked(true);
								}}
							>
								<svg
									width="17"
									height="16"
									viewBox="0 0 17 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M8.5 14.5C12.0899 14.5 15 11.5899 15 8C15 4.41015 12.0899 1.5 8.5 1.5C4.91015 1.5 2 4.41015 2 8C2 11.5899 4.91015 14.5 8.5 14.5ZM8.5 16C12.9183 16 16.5 12.4183 16.5 8C16.5 3.58172 12.9183 0 8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 12.4183 4.08172 16 8.5 16Z"
										fill="#54A0FF"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M5.96967 5.46967C6.26256 5.17678 6.73744 5.17678 7.03033 5.46967L11.0303 9.46967C11.3232 9.76256 11.3232 10.2374 11.0303 10.5303C10.7374 10.8232 10.2626 10.8232 9.96967 10.5303L5.96967 6.53033C5.67678 6.23744 5.67678 5.76256 5.96967 5.46967Z"
										fill="#54A0FF"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M11.0303 5.46967C11.3232 5.76256 11.3232 6.23744 11.0303 6.53033L7.03033 10.5303C6.73744 10.8232 6.26256 10.8232 5.96967 10.5303C5.67678 10.2374 5.67678 9.76256 5.96967 9.46967L9.96967 5.46967C10.2626 5.17678 10.7374 5.17678 11.0303 5.46967Z"
										fill="#54A0FF"
									/>
								</svg>
								<div className={styles.button_text}>
									{translate("events.plan.cancelVisit", true)}
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div className={styles.event_change}>
							{hasReview ? (
								// <div className={styles.rateVisit}>
								// 	<span>{translate("appointment.rate.hasReview")}</span>
								// </div>
								<></>
							) : (
								<div className={styles.rateVisit}>
									<span>{translate("appointment.rate.rateDoctor")}</span>
									<Stars
										containerClass={styles.stars}
										starClass={styles.star}
										count={rating}
										setCount={onRatingChange}
									/>
								</div>
							)}
							<div
								className={styles.change_button}
								onClick={(e) => {
									e.stopPropagation();

									if (doctor) {
										dispatch(
											setSelectedDoctor({
												id: doctor.id,
												specialityId: specialities[0].id,
											})
										);
									}
								}}
							>
								<RedoIcon className={styles.icon} />
								<div className={styles.button_text}>
									{translate("appointment.reschedule")}
								</div>
							</div>
						</div>
					</>
				)}
			</div>
			<ConfirmAction
				speciality={speciality && speciality.name}
				asked={asked}
				onConfirm={() =>
					dispatch(
						setAppointmentToCancel({
							slotIdMis:
								additionalSlotsData?.parentSlot?.scheduleIdMis || scheduleIdMis,
							medCardNumber: patient.medCardNumber,
						})
					)
				}
				onCancel={onCancelDelete}
				shortname={shortname}
				date={
					<>
						{moment(sourceDate).format("D MMMM в H:mm")}
						{moscowTimeZone() ? "" : translate("app.byMskTime", true)}
					</>
				}
				setAsked={setAsked}
				confirmCallback={confirmCallback}
				doctor={doctor}
			/>
		</div>
	);
}
