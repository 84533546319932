const MS_PER_MIN = 60000;
const MINS_PER_HOUR = 60;
const MSK_UTC_MINS_OFFSET = 180;

/*
  It is supposed that all dates on the server have Moscow time (+03:00 from UTC)
*/

export const getUserTimezoneOffset = (date) =>
	date.getTimezoneOffset() + MSK_UTC_MINS_OFFSET;

export const minutesToMilliseconds = (mins) => mins * MS_PER_MIN;

export const getHoursAndMinutesFromOffset = (mins) => [
	parseInt(mins / MINS_PER_HOUR),
	mins % MINS_PER_HOUR,
];

export const createTimeString = (hours, mins) =>
	`${String(hours).length > 1 ? "" : "0"}${hours}:${
		String(mins).length > 1 ? "" : "0"
	}${mins}`;

export const convertDate = (rawDate) => {
	const dateStr =
		typeof rawDate === "object"
			? rawDate
			: rawDate.replace(/-/g, "/").replace("T", " ");
	const timestamp = Date.parse(rawDate);
	const date = new Date(isNaN(timestamp) ? dateStr : timestamp);

	return new Date(
		date.getTime() - minutesToMilliseconds(getUserTimezoneOffset(date))
	);
};

export const convertString = (string, separator = ":") => {
	return string;

	const [hours, mins] = string.split(separator).map(Number);

	const [offsetH, offsetM] = getHoursAndMinutesFromOffset(
		getUserTimezoneOffset(new Date())
	);

	return createTimeString((hours - offsetH) % 24, (mins - offsetM) % 60);
};

export const moscowTimeZone = () => new Date().getTimezoneOffset() === -180;
