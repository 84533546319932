import styles from "./search-results.module.css";
import classNames from "classnames";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
	setResearchId,
	setSelectedDoctor,
} from "../../services/appointment.slice";
import { Metrics } from "../../../../utils/metrics";
import {getSiteSourceName} from "../../../../utils";

export default function SearchItem({
	onSelectItem,
	item,
	isLast,
	isPublic = false,
	isWidget = false,
}) {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	let content;
	let onClick;

	if (item.service) {
		onClick = () => {
			onSelectItem && onSelectItem();
			navigate(
				`/${isPublic || isWidget ? "schedule" : "appointment"}/services/${
					item.researchId
				}/${item.service.id}${location.search}`
			);
			Metrics.gtmEvent("select_content", {
				type: "service",
				ItemId: item.service.id,
				researchId: item.researchId,
			});
		};
		content = (
			<>
				<span>{item.service.name}</span>
				<br />
				<span className={styles.subcription}>{item.researchName}</span>
			</>
		);
	}
	if (item.name) {
		onClick = () => {
			if (item.examination !== null) {
				onSelectItem && onSelectItem();
				navigate(
					`/${isPublic || isWidget ? "schedule" : "appointment"}/services${
						location.search
					}`
				);
				dispatch(setResearchId(item.examination));
			} else {
				onSelectItem && onSelectItem();
				navigate(
					`/${isPublic || isWidget ? "schedule" : "appointment"}/speciality/${
						item.id
					}${location.search}`
				);
			}
			Metrics.gtmEvent("select_content", {
				type: "speciality",
				ItemId: item.id,
			});
		};
		content = item.name;
	}
	if (item.shortname) {
		onClick = () => {
			let doctorInfo = {
				id: item.id
			}
			if (item.specialities?.length) {
				doctorInfo = {
					...doctorInfo,
					specialityId: item.specialities[0]?.id
				}
			}
			dispatch(setSelectedDoctor(doctorInfo));
			onSelectItem && onSelectItem();

			Metrics.gtmEvent("select_item", {
				item_list_id: "search",
				item_list_name: "Поиск/История поиска",
				items: [
					{
						item_id: item.id,
						item_name: item.shortname
					}
				],
				site_source_domain: getSiteSourceName(),
				site_source_path: location.pathname
			});


			// Metrics.gtmEvent("select_content", { type: "doctor", ItemId: item.id });
		};
		

		const initials = item.shortname.split(" ");
		content = (
			<div className={styles.doctor}>
				{item.photoUrl ? (
					<img src={item.photoUrl} alt="" className={styles.avatar} />
				) : (
					<div className={styles.no_avatar}>
						{initials && initials[0] && initials[0][0].toUpperCase()}.
						{initials && initials[1] && initials[1][0].toUpperCase()}.
					</div>
				)}
				<div>
					<div>{item.shortname}</div>
					<div className={styles.specialities}>
						{item.specialities &&
							item.specialities.map((item) => item.name).join(", ")}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div
			onClick={onClick}
			className={classNames(styles.row, { [styles.last]: isLast })}
		>
			{content}
		</div>
	);
}
