import { Route, Routes } from "react-router-dom";
import Notifications from "./modules/notifications";
import NotificationDetails from "./modules/notification-details";
import Footer from "../../components/footer";

export default function NotificationsGeneral() {
	return (
		<>
			<Routes>
				<Route path="/" index element={<Notifications />} />
				<Route path="/:id" element={<NotificationDetails />} />
			</Routes>
			<Footer isInApp />
		</>
	);
}
