import { createApi } from "@reduxjs/toolkit/query/react";
import baseConfig from "../../../app/config.api";

export const paymentsApi = createApi({
	reducerPath: "paymentsApi",
	baseQuery: baseConfig,
	tagTypes: [],
	endpoints: (builder) => ({
		getCheckout: builder.query({
			query: (body) => ({
				method: "POST",
				body,
				url: "/checkout",
			}),
			transformResponse: (response) => response.data,
		}),
		getPaymentData: builder.query({
			query: (body) => ({
				method: "POST",
				body,
				url: "/checkout/payment_data",
			}),
			transformResponse: (response) => response.data,
		}),
		getDiscountData: builder.query({
			query: (body) => ({
				method: "POST",
				body,
				url: "/checkout/discount_data",
			}),
			transformResponse: (response) => response.data,
		}),
		createInvoice: builder.mutation({
			query: (body) => ({
				method: "POST",
				body,
				url: "/payment/invoice/",
			}),
			transformResponse: (response) => response.data,
		}),
		getPaymentWidget: builder.mutation({
			query: (body) => ({
				method: "POST",
				body,
				url: "/payment/invoice/by_url",
			}),
			transformResponse: (response) => response.data.paymentUrl,
		}),
		invoiceResult: builder.mutation({
			query: (body) => ({
				method: "POST",
				body,
				url: "/payment/invoice/result",
			}),
			transformResponse: (response) => response.data,
		}),
		paymentByCard: builder.mutation({
			query: (body) => ({
				method: "POST",
				body,
				url: "/payment/invoice/by_card",
			}),
			transformResponse: (response) => response.data,
		}),
		paymentByAccount: builder.mutation({
			query: (body) => ({
				method: "POST",
				body,
				url: "/payment/invoice/by_account",
			}),
			transformResponse: (response) => response.data,
		}),
		paymentByCertificate: builder.mutation({
			query: (body) => ({
				method: "POST",
				body,
				url: "/payment/invoice/by_certificate",
			}),
			transformResponse: (response) => response.data,
		}),
	}),
});

export const {
	useGetCheckoutQuery,
	useGetPaymentDataQuery,
	useGetDiscountDataQuery,
	useCreateInvoiceMutation,
	useGetPaymentWidgetMutation,
	useInvoiceResultMutation,
	usePaymentByCardMutation,
	usePaymentByAccountMutation,
	usePaymentByCertificateMutation,
} = paymentsApi;
