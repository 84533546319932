import React from "react";
import css from "./how-to-use.module.css";
import classNames from "classnames";
import {useTranslate} from "../../../../../../localization/translate";
import Button from "../../../../../../components/button/button";
import Close from "./close";


export default function HowToUse({setHowToUseOpened, opened}) {
	const t = useTranslate();
	const onClose = () => setHowToUseOpened(false);

	return <div className={classNames(css.modal, { [css.opened]: opened })}>
		<Close onClose={onClose}/>
		<div className={css.container}>
			<div className={css.title}>
				{t("profile.coffee.howToUse?")}
			</div>
			<div className={css.graphics}>
				<img src="/images/coffee/how-to-use.svg" alt=""/>
			</div>
			<ol className={css.list}>
				{t("profile.coffee.howToUseSteps", true).map(li => <li key={li}>{li}</li>)}
			</ol>
			<Button className={css.button} onClick={onClose}>{t("app.ok")}</Button>
		</div>
	</div>
}