import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Question } from "./Question";
import { useGetPollQuery } from "../../../services/events.api";
import {
	buildAdditionalQuestion,
	isAdditional,
	isAdditionalQuestion,
	getUserAnswer,
	getCurrectQuestionWithAnswer,
	getQuestionIndex,
	getParentQuestion,
} from "./utils";
import { PollResults } from "./PollResults/PollResults";
import { Metrics } from "../../../../../utils/metrics";

export const Poll = ({ pollId }) => {
	const [question, setQuestion] = useState(null);
	const [activeAnswer, setActiveAnswer] = useState(null);
	const [_, setSearchParams] = useSearchParams();

	const { data: poll, isFetching } = useGetPollQuery(pollId);

	const pollLength = useMemo(
		() => ({
			current:
				question && poll
					? question.isAdditional
						? getQuestionIndex(
								poll.questions,
								getParentQuestion(poll.questions, question)
						  ) + 1
						: getQuestionIndex(poll.questions, question) + 1
					: 0,
			length: poll?.questions?.length,
		}),
		[poll, question]
	);

	const backBtnConfig = useMemo(
		() => ({
			show:
				poll &&
				question &&
				(question?.isAdditional ||
					getQuestionIndex(poll?.questions, question) >= 1),
			onClick: () => getPreviosQuestion(poll, question),
		}),
		[question, poll]
	);

	const getPreviosQuestion = (poll, question) => {
		if (question.isAdditional) {
			const parentQst = getParentQuestion(poll.questions, question);
			setQuestion(parentQst);
		} else {
			const index = getQuestionIndex(poll.questions, question);

			if (index >= 1) {
				const prevQst = poll?.questions?.[index - 1];
				const prevAnswer = getUserAnswer(prevQst, poll.answersUser);

				setQuestion(prevQst);
				setActiveAnswer(prevAnswer);
			}
		}
	};

	const init = ({ questions, answersUser }) => {
		const { question, answer } = getCurrectQuestionWithAnswer(
			questions,
			answersUser
		);

		if (answer && isAdditional(answer)) {
			setQuestion(
				isAdditionalQuestion(answer)
					? buildAdditionalQuestion(question, answer)
					: question
			);

			setActiveAnswer(answer);
		} else {
			setQuestion(question);
		}
	};

	const handleShowResults = () => setSearchParams({ result: pollId });

	useEffect(() => poll && init(poll), [poll, isFetching]);

	useEffect(
		() => pollId && Metrics.gtmEvent("begin_poll", { pollId }),
		[pollId]
	);

	return (
		<div>
			{poll && question && (
				<Question
					pollId={pollId}
					counts={pollLength}
					activeAnswer={activeAnswer}
					question={question}
					setQuestion={setQuestion}
					backBtnConfig={backBtnConfig}
					isLoadingPoll={isFetching}
				/>
			)}

			{poll && !question && !poll.isCompleted && (
				<PollResults
					poll={poll}
					pollId={pollId}
					setQuestion={setQuestion}
					setActiveAnswer={setActiveAnswer}
					onSaveResults={handleShowResults}
				/>
			)}
		</div>
	);
};
