import Input from "../../../../components/input";
import Loader from "../../../../components/loader";
import {
	useSendNumberMutation,
	useVerifySmsCodeMutation,
} from "../../services/login.api";
import { useEffect, useState } from "react";
import { useTranslate } from "../../../../localization/translate";
import styles from "../Login.module.css";
import Modal from "react-modal";
import VerifyCode from "./verify-code";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/button/button";
import { getErrorMessageFromError } from "../../../../app/error-handler.api";
import startsWith from "lodash.startswith";
import { Metrics } from "../../../../utils/metrics";
import { InvisibleSmartCaptcha } from "@yandex/smart-captcha";

const ALLOWED_COUNTRIES = ["ru"];
Modal.setAppElement("#modal");

export default function LoginByPhone({ onHide, isWidget }) {
	const translate = useTranslate();
	const [sendNumber, { data, isLoading, error }] = useSendNumberMutation();
	const [
		sendCode,
		{
			data: confirmData,
			error: confirmError,
			isLoading: isConfirmLoading,
			reset: confirmReset,
		},
	] = useVerifySmsCodeMutation();

	const [modalIsOpen, setIsOpen] = useState(false);
	const [num, setNum] = useState("");
	const [errMsg, setErrMsg] = useState(translate("login.wrongNumber", true));
	const [isValid, setIsValid] = useState(true);
	const [isTyping, setIsTyping] = useState(false);
	const [limitIsOver, setLimitIsOver] = useState(false);
	const [redirect, setRedirect] = useState(null);
	const navigate = useNavigate();
	const [rawValue, setRawValue] = useState(num);
	const [fullLength, setLength] = useState(3);
	const [type, setType] = useState("sms");

	const [waitTimer, setWaitTimer] = useState(null);

	const [isVerifyingMedCard, setIsVerifyingMedCard] = useState(false);

	// yandex captcha
	const [isCaptchaShow, setCaptchaShow] = useState(false);
	const [smartCaptchaToken, setSmartCaptchaToken] = useState(null);

	const showCaptcha = () => {
		if (!smartCaptchaToken) {
			setCaptchaShow(true);
		} else {
			onCaptchaSuccess(smartCaptchaToken);
		}
	};

	const hideCaptcha = () => setCaptchaShow(false);

	const onCaptchaSuccess = (token = null) => {
		if (!!token) {
			hideCaptcha();
			setSmartCaptchaToken(token);
			sendNumber({ phone: num, wayToGet: type, smartCaptchaToken: token });
			Metrics.gtmEvent("auth_phone");
		}
	};

	const onCaptchaTokenExpired = () => {
		setSmartCaptchaToken(null);
	};

	// yandex captcha end

	useEffect(() => {
		if (redirect) {
			return navigate(redirect);
		}
	}, [redirect]);

	const isDisabled = () => {
		if (modalIsOpen) {
			return true;
		}

		if (!isValid) {
			return true;
		}

		if (rawValue.length < fullLength) {
			return true;
		}

		if (isLoading) {
			return true;
		}

		// if (isInvalidValue) {

		// if (num.length !== clearLogin(rawValue).length) {
		//     return true;
		// }

		return false;
	};

	const closeModal = () => {
		setIsOpen(false);
		setLimitIsOver(false);
		setWaitTimer(null);
	};

	useEffect(() => {
		if (data) {
			setIsOpen(true);
		}
	}, [data]);

	useEffect(
		() => onHide?.(modalIsOpen || isVerifyingMedCard),
		[modalIsOpen, isVerifyingMedCard]
	);

	useEffect(() => {
		const error2008 = getErrorMessageFromError({
			error,
			code: 2008,
			status: 400,
		});
		if (error2008) {
			setErrMsg(error2008.msg);
			setLimitIsOver(true);
			setIsOpen(true);
		}
		const error2007 = getErrorMessageFromError({
			error,
			code: 2007,
			status: 400,
		});

		if (error2007) {
			const seconds = (error2007.msg?.match(/\b(\d{2}:\d{2})\b/) ?? [])[1]
				?.split(":")
				.reduce((acc, val, index) => acc + val * Math.pow(60, 1 - index), 0);
			setWaitTimer(parseInt(seconds));
			setIsOpen(true);
		}

		if (error) Metrics.gtmEvent("auth_fail", { auth_way: "phone" });
	}, [error]);

	useEffect(() => {
		const error2007 = getErrorMessageFromError({
			error: confirmError,
			code: 2007,
			status: 400,
		});

		if (error2007) {
			const seconds = (error2007.msg.match(/\b(\d{2}:\d{2})\b/) ?? [])[1]
				?.split(":")
				.reduce((acc, val, index) => acc + val * Math.pow(60, 1 - index), 0);
			setWaitTimer(parseInt(seconds));
			setIsOpen(true);
		}

		if (confirmError) Metrics.gtmEvent("auth_fail", { auth_way: "phone" });
	}, [confirmError]);

	useEffect(() => {
		if (confirmData === null && !confirmError) {
			setLimitIsOver(true);
		}
	}, [confirmData, confirmError]);

	const onTypeChange = (type) => {
		sendNumber({ phone: num, wayToGet: type, smartCaptchaToken });
		setType(type);
	};

	return (
		<>
			<Input
				onBlur={(e, country) => {
					if (
						country &&
						country.format &&
						country.format.length > e.target.value.length &&
						num.length !== 0
					) {
						setIsValid(false);
					}
					setIsTyping(false);
				}}
				onFocus={() => {
					if (isValid) {
						setIsTyping(true);
					}
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter" && !isDisabled()) {
						showCaptcha();
					}
				}}
				onlyCountries={ALLOWED_COUNTRIES}
				specialLabel={translate("login.phoneNumber")}
				containerClass={styles.box}
				placeholder={translate("login.phoneNumber", true)}
				type="phone-mask"
				value={num}
				isValid={isValid}
				onChange={(phone, country, e) => {
					if (country && country.format) {
						setLength(country.format.length);
					}
					if (phone.length > 2) {
						setIsValid(
							startsWith(phone, country.dialCode) ||
								startsWith(country.dialCode, phone)
						);
						setIsTyping(
							startsWith(phone, country.dialCode) ||
								startsWith(country.dialCode, phone)
						);
					} else {
						setIsValid(true);
						setIsTyping(true);
					}
					setNum(phone);
					setRawValue(e.target.value);
				}}
				isTyping={isTyping}
				disabled={isLoading}
				errorMessage={errMsg}
			/>
			<Button
				onClick={showCaptcha}
				disabled={isDisabled()}
				className={styles.btn}
			>
				{modalIsOpen ? (
					<Loader
						containerStyle={{ margin: "-8px 0" }}
						width={30}
						height={30}
					/>
				) : (
					translate("login.loginBtn", true)
				)}
			</Button>
			{/* {isCaptchaShow && (
				<Captcha
					onClose={hideCaptcha}
					onSuccess={onCaptchaSuccess}
					onExpired={onCaptchaTokenExpired}
				/>
			)} */}
			<InvisibleSmartCaptcha
				sitekey={process.env.REACT_APP_YANDEX_CAPTCHA_API_KEY}
				visible={isCaptchaShow}
				onSuccess={onCaptchaSuccess}
				onExpired={onCaptchaTokenExpired}
				hideShield
			/>
			<VerifyCode
				onVerifyMedCard={setIsVerifyingMedCard}
				closeModal={closeModal}
				modalIsOpen={modalIsOpen}
				num={rawValue}
				phone={num}
				repeatRequest={() => {
					setWaitTimer(null);
					sendNumber({ phone: num, wayToGet: type, smartCaptchaToken });
					Metrics.gtmEvent("auth_phone");
				}}
				limitIsOver={limitIsOver}
				redirect={(url) => setRedirect(url)}
				errMsg={errMsg}
				type={type}
				onTypeChange={onTypeChange}
				waitTimer={waitTimer}
				sendCode={sendCode}
				smartCaptchaToken={smartCaptchaToken}
				data={confirmData}
				error={confirmError}
				isLoading={isConfirmLoading}
				reset={confirmReset}
				isWidget={isWidget}
			/>
		</>
	);
}
