import ModalInfo from "../../../../components/custom-modal/info";
import confirm from "./confirm-modal.module.css";
import classNames from "classnames";
import Button from "../../../../components/button/button";
import React, { useState } from "react";
import { useTranslate } from "../../../../localization/translate";
import { useDispatch } from "react-redux";
import { setExaminationInfo } from "../../services/appointment.slice";

export default function AdultWarning({ ignoreCallback }) {
	const translate = useTranslate();
	const modalStyle = {
		overlay: {
			zIndex: 140,
		},
		content: {
			height: 600,
		},
	};
	const [isOpen, setOpen] = useState(true);
	const dispatch = useDispatch();

	return (
		<ModalInfo
			iconType={"failure"}
			isOpen={isOpen}
			modalStyle={modalStyle}
			modalClassName={confirm.modal}
			subtitle={translate("app.attention", true) + "!"}
			bodyClass={classNames(confirm.content, confirm.failure)}
			onRequestClose={() => {
				setOpen(false);
				dispatch(setExaminationInfo(null));
			}}
			textClass={confirm.text}
			text={translate("appointment.subscribeTimeslot.adultWarningText")}
		>
			<div className={confirm.make_appointment}>
				<Button
					onClick={() => {
						setOpen(false);
						dispatch(setExaminationInfo(null));
					}}
				>
					{translate("appointment.subscribeTimeslot.backToSchedule", true)}
				</Button>
				<Button
					className={confirm.long_btn}
					light
					onClick={() => {
						ignoreCallback();
						setOpen(false);
					}}
				>
					{translate("appointment.subscribeTimeslot.forceContinue", true)}
				</Button>
			</div>
		</ModalInfo>
	);
}
