import { useEffect } from "react";
import { Collapser } from "../../../../../components/collapser/controlled-collapser";
import styles from "./styles.module.css";
import { Metrics } from "../../../../../utils/metrics";

export const Recommendations = ({ isCompleted = false, recommendations }) => {
	useEffect(() => {
		if (isCompleted && recommendations?.length) {
			Metrics.gtmEvent("view_recommendations");
		}
	}, [isCompleted, recommendations]);

	return (
		<div>
			{recommendations?.map(({ id, title, text }) => (
				<Collapser
					key={id}
					title={<span className={styles.title}>{title}</span>}
				>
					<span
						className={styles.text}
						dangerouslySetInnerHTML={{ __html: text }}
					/>
				</Collapser>
			))}
		</div>
	);
};
