import styles from "./schedule.module.css";
import Search from "../search";
import Filter from "../../../../components/filter/filter";
import FilterBody from "./filter-body";
import Calendar from "../../../../components/calendar/calendar";
import classNames from "classnames";
import moment from "moment";
import DoctorCard from "../../../../components/doctor-card/doctor-card";
import { useState, useEffect } from "react";
import { useTranslate } from "../../../../localization/translate";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
	setExaminationInfo,
	setReceptionTypeState,
} from "../../services/appointment.slice";
import { useGetSpecialityQuery } from "../../services/appointment.api";
import { moscowTimeZone } from "../../../../utils/dates/timezone-converter";
import ProfileNotice from "../../../profile/modules/personala-data/notice";
import ChooseDoctorModal from "../choose-doctor";
import { isSameDay } from "date-fns";
import { Metrics } from "../../../../utils/metrics";
import {getSiteSourceName} from "../../../../utils";

export default function Schedule({
	isPublic = false,
	breadcrumbs,
	setDate,
	activeDate,
	data,
	isLoading,
	serviceName,
	servicePrice,
}) {
	const initFilters = {
		gender: {
			value: null,
			list: [null, "FEMALE", "MALE"],
		},
		greatExperience: false,
		greatRating: false,
		alreadyVisit: false,
	};
	const [filters, setFilters] = useState(initFilters);
	const [isApplyDisabled, setIsApplyDisabled] = useState(false);
	const [resultsCount, setResultsCount] = useState(0);
	const [filterIsApply, setApplyFilter] = useState(false);
	const translate = useTranslate();
	const [expanded, setExpanded] = useState(false);
	const [selectedTimeslot, setSelectedTimeslot] = useState(null);
	const params = useParams();
	const dispatch = useDispatch();
	const { data: specialities } = useGetSpecialityQuery();
	const speciality =
		params.specialityId &&
		specialities &&
		specialities.find((speciality) => speciality.id == params.specialityId);

	const sourceList = (filters) => {
		const sourceArray = data ? data.allDoctors || [] : [];

		return sourceArray.filter((doctor) => {
			let match = true;
			if (filters.gender.value) {
				match =
					filters.gender.value.toLowerCase() === doctor.sex?.toLowerCase();
			}
			if (match && filters.greatExperience) {
				match =
					doctor.experienceYearsAll && parseInt(doctor.experienceYearsAll) > 10;
			}
			if (match && filters.greatRating) {
				match = doctor.rating && parseFloat(doctor.rating) > 4;
			}

			return match;
		});
	};

	const props = {
		timeSlots: data && data.timeSlots,
		className: styles.calendar,
		isLoading,
		activeDate,
		setDate,
		onTimeslotSelect: setSelectedTimeslot,
		showSubscribeNotice: true,
		timeSlot: selectedTimeslot,
	};

	if (data && data.speciality) {
		props.speciality = data.speciality;
	}

	const doctorsAtTime = selectedTimeslot
		? selectedTimeslot.slotDoctors.map((slot) => slot.doctorId)
		: [];
	const allDoctors = sourceList(filters);

	const forTodayDoctorIds = [
		...new Set(
			(data?.timeSlots || [])
				.filter((ts) => isSameDay(new Date(activeDate), new Date(ts.time)))
				.map((ts) => ts.doctors)
				.flat()
		),
	];

	const forTodayDoctors = sourceList(filters).filter((doctor) =>
		forTodayDoctorIds.includes(doctor.id)
	);

	const clearFilters = () => {
		setFilters(initFilters);
		setApplyFilter(false);
	};

	const handleDisableFilter = (innerFilters) => {
		setResultsCount(sourceList(innerFilters).length);
		setIsApplyDisabled(sourceList(innerFilters).length === 0);
	};

	const onDoctorClick = (doctor, additionalSlots) => {
		const selectedDoctor = selectedTimeslot.slotDoctors.find(
			(doc) => doc.doctorId === doctor.id
		);

		Metrics.gtmEvent("select_item", {
			item_list_id: "doctors_research_time",
			item_list_name: "Список врачей в исследовании на выбранное время",
			researchId: params.research_id,
			items: [
				{
					item_id: doctor.id,
					item_name: doctor.shortname,
					price: servicePrice,
					quantity: 1,
					item_list_id: "doctors_research_time",
					item_list_name: "Список врачей в исследовании на выбранное время"
				}
			],
			site_source_domain: getSiteSourceName(),
			site_source_path: window.location.pathname
		})

		Metrics.gtmEvent("begin_checkout", {
			researchId: params.research_id,
			items: [
				{
					item_id: doctor.id,
					item_name: doctor.shortname,
					price: servicePrice,
					quantity: 1,
					item_list_id: "doctors_research_time",
					item_list_name: "Список врачей в исследовании на выбранное время"
				}
			],
			site_source_domain: getSiteSourceName(),
			site_source_path: window.location.pathname
		})

		const obj = {
			date: `${moment(activeDate).format("yyyy-MM-DD")} ${moment(
				selectedTimeslot.time
			).format("HH:mm")}:00`,
			doctor: doctor,
			slotIdMis: selectedDoctor.idMis,
			cabinet: selectedDoctor.cabinet,
			acceptAdultsShowWarning: doctor.acceptAdults,
			additionalSlots,
			specialityId: selectedDoctor.specialityId,
		};
		if (params.service_id) {
			obj.serviceId = parseInt(params.service_id);
			if (serviceName) {
				obj.serviceName = serviceName;
			}
			if (servicePrice) {
				obj.price = servicePrice;
			}
		} else {
			obj.featureIdMis = null;
		}
		if (params.specialityId || data?.speciality.id) {
			obj.specialityId = parseInt(params.specialityId) || data.speciality.id;
			obj.receptionTypes = (speciality || data.speciality).appointmentFeature;
		}

		if (!isPublic) {
			dispatch(setReceptionTypeState(true));
		}

		dispatch(setExaminationInfo(obj));
		setSelectedTimeslot(null);
	};

	const availableSlots = (data?.timeSlots || [])
		.filter((sl) => sl.isAvailable)
		.map((slot) => slot.slotDoctors.map((sl) => ({ time: slot.time, ...sl })))
		.flat();


	
	useEffect(() => {

		if(!!forTodayDoctors.length && !selectedTimeslot) {
			Metrics.gtmEvent("view_item_list", {
				item_list_id: "doctors_research_today",
				item_list_name: "Список врачей в исследовании на сегодня",
				researchId: params.research_id,
				items: forTodayDoctors.map((doctor) => {
					return {
						item_id: doctor.id,
						item_name: doctor.shortname,
						price: servicePrice,
						quantity: 1,
						item_list_id: "doctors_research_today",
						item_list_name: "Список врачей в исследовании на сегодня"
					}
				}),
				site_source_domain: getSiteSourceName(),
				site_source_path: window.location.pathname
			});
		}
	
	}, [forTodayDoctors]);

	useEffect(() => {
	
	
		if(!!allDoctors.length && !selectedTimeslot) {
			Metrics.gtmEvent("view_item_list", {
				item_list_id: "doctors_research_all",
				item_list_name: "Список врачей в исследовании",
				researchId: params.research_id,
				items: allDoctors.map((doctor) => {
					return {
						item_id: doctor.id,
						item_name: doctor.shortname,
						price: servicePrice,
						quantity: 1,
						item_list_id: "doctors_research_all",
						item_list_name: "Список врачей в исследовании"
					}
				}),
				site_source_domain: getSiteSourceName(),
				site_source_path: window.location.pathname
			});
		}
	}, [allDoctors]);


	useEffect(() => {

		if(!!doctorsAtTime.length && selectedTimeslot) {
			Metrics.gtmEvent("view_item_list", {
				item_list_id: "doctors_research_time",
				item_list_name: "Список врачей в исследовании на выбранное время",
				researchId: params.research_id,
				items: forTodayDoctors
				.filter((doctor) => doctorsAtTime.includes(doctor.id))
				.map((doctor) => {
					return {
						item_id: doctor.id,
						item_name: doctor.shortname,
						price: servicePrice,
						quantity: 1,
						item_list_id: "doctors_research_time",
						item_list_name: "Список врачей в исследовании на выбранное время"
					}


				}),
				site_source_domain: getSiteSourceName(),
				site_source_path: window.location.pathname
			});
		}
	}, [selectedTimeslot]);

	return (
		<div className={styles.schedule}>
			<div className={styles.header}>
				<Search className={styles.search} isPublic={isPublic} />
				{data && (
					<Filter
						clearAction={clearFilters}
						className={styles.filter}
						dropdownPosition={"left"}
						filterIsApply={filterIsApply}
						expandedDefault={expanded}
						setDefaultExpanded={setExpanded}
					>
						<FilterBody
							onApplyClick={() => setApplyFilter(true)}
							filters={filters}
							setExpanded={setExpanded}
							setFilters={setFilters}
							filerResults={resultsCount}
							onChange={handleDisableFilter}
							isDisabled={isApplyDisabled}
							found={translate("appointment.filters.found", true)}
							entries={translate("appointment.filters.doctors", true)}
						/>
					</Filter>
				)}
			</div>
			{breadcrumbs}
			{data?.noticesS?.map(({ id, description }) => (
				<div key={id} style={{ paddingTop: 8 }}>
					<ProfileNotice
						isGrey
						important={translate(
							"history.assignment.endoscopyNotice.important",
							true
						)}
						title={<div dangerouslySetInnerHTML={{ __html: description }} />}
						titleClassName={styles.notice_message_text}
					/>
				</div>
			))}

			<Calendar
				showAppointmentContact
				isPublic={isPublic}
				serviceId={parseInt(params.service_id)}
				{...props}
			/>

			{data && (
				<>
					{!selectedTimeslot && forTodayDoctors.length > 0 && (
						<div className={styles.doctors}>
							<div className={classNames(styles.title, "h3")}>
								{translate("appointment.specialities.availableDoctorsAt", true)}
								{moment().format("YYYY-MM-DD") ===
								moment(activeDate).format("YYYY-MM-DD")
									? translate("appointment.specialities.today", true)
									: moment(activeDate).format("D MMMM")}
								{/*convertDate()*/}
							</div>

							{data?.noticesD?.map(({ id, description }) => (
								<div key={id} style={{ paddingTop: 8, width: "100%" }}>
									<ProfileNotice
										isGrey
										important={translate(
											"history.assignment.endoscopyNotice.important",
											true
										)}
										title={
											<div dangerouslySetInnerHTML={{ __html: description }} />
										}
										titleClassName={styles.notice_message_text}
									/>
								</div>
							))}

							{forTodayDoctors.map((doctor) => (
								<DoctorCard
									{...doctor}
									key={`today-${doctor.id}`}
									className={styles.doctor}
									speciality={params.specialityId || data.speciality.id}
								/>
							))}
						</div>
					)}
					{!selectedTimeslot && allDoctors.length > 0 && (
						<div className={styles.doctors}>
							<div className={classNames(styles.title, "h3")}>
								{translate("appointment.specialities.allDoctors")}
							</div>
							{allDoctors.map((doctor) => (
								<DoctorCard
									key={`all-${doctor.id}`}
									{...doctor}
									speciality={params.specialityId || data.speciality.id}
									className={styles.doctor}
								/>
							))}
						</div>
					)}
					{selectedTimeslot && (
						<div className={styles.doctors}>
							<div className={classNames(styles.title, "h3")}>
								{translate("appointment.specialities.availableDoctorsAt")}
								{moment().format("YYYY-MM-DD") !==
									moment(activeDate).format("YYYY-MM-DD") &&
									moment(activeDate).format("D MMMM")}
								&nbsp;
								{moment(selectedTimeslot.time).format("HH:mm")}{" "}
								{moscowTimeZone() ? "" : translate("app.byMskTime", true)}
							</div>
							{sourceList(filters)
								.filter((doctor) => doctorsAtTime.includes(doctor.id))
								.map((doctor) => (
									<DoctorCard
										key={`on-time-${doctor.id}`}
										{...doctor}
										className={styles.doctor}
										cardClick={() => onDoctorClick(doctor)}
									/>
								))}
						</div>
					)}
				</>
			)}

			{!!selectedTimeslot?.time && (
				<ChooseDoctorModal
					timeSlot={selectedTimeslot}
					onClose={() => setSelectedTimeslot(null)}
					service={serviceName}
					date={activeDate}
					allSlots={availableSlots}
					doctors={sourceList(filters).filter((doctor) =>
						doctorsAtTime.includes(doctor.id)
					)}
					onDoctorClick={onDoctorClick}
				/>
			)}
		</div>
	);
}
