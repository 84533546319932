import InfoBanner from "../../../../../../components/info-banner";
import { useTranslate } from "../../../../../../localization/translate";
import styles from "./style.module.css";

const InfoBannerWrapper = ({
	title,
	list,
	children,
	learMoreLink,
	onClick,
}) => {
	const translate = useTranslate();

	return (
		<InfoBanner
			title={title}
			renderActions={
				learMoreLink
					? () => (
							<a
								href={learMoreLink}
								target="_blank"
								className={styles.learn_more_btn}
								onClick={onClick}
							>
								{translate("profile.discounts.learnMore")}
							</a>
					  )
					: null
			}
		>
			{children && <div className={styles.content}>{children}</div>}

			{list && (
				<ul className={styles.list}>
					{list.map((item, key) => (
						<li key={key}>{item}</li>
					))}
				</ul>
			)}
		</InfoBanner>
	);
};

export default InfoBannerWrapper;
