import Dropdown from "../../../../../components/dropdown/dropdown";
import styles from "./patient-dropdown.module.css";
import CloseModalButton from "../../../../../components/modal-items/close";
import React from "react";
import { useTranslate } from "../../../../../localization/translate";
import Button from "../../../../../components/button/button";
import { useGetProfileQuery } from "../../../../profile/services/profile.api";
import { useNavigate } from "react-router-dom";
import { setPatient } from "../../../../profile/services/profile.slice";
import { useDispatch } from "react-redux";
import classNames from "classnames";

export default function Patient({
	isOpen,
	setIsOpen,
	patient,
	className,
	showBtns = true,
}) {
	const translate = useTranslate();
	const { medCardNumber, photo, firstName, lastName } = patient;
	const { data: profile } = useGetProfileQuery();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	if (!profile || !patient) {
		return null;
	}

	return (
		<Dropdown
			expanded={isOpen}
			setExpanded={() => setIsOpen(false)}
			className={classNames(styles.patient_dropdown, className)}
		>
			<CloseModalButton
				closeModal={() => {
					setIsOpen(false);
				}}
			/>
			<div className={styles.patient_title}>
				{translate("appointment.confirm.patient")}
			</div>
			<div className={styles.row}>
				{photo ? (
					<img src={photo} className={styles.avatar} />
				) : (
					<div className={styles.no_avatar}>
						{firstName && firstName[0].toUpperCase()}.{" "}
						{lastName && lastName[0].toUpperCase()}.
					</div>
				)}
				<div className={styles.info}>
					<div className={styles.fio}>
						{firstName} {lastName}
					</div>
					<div className={styles.card_number}>
						{translate("profile.personalData.medCardNumber")}: &nbsp;
						{medCardNumber}
					</div>
				</div>
			</div>
			{showBtns && (
				<div className={styles.buttons}>
					<Button
						onClick={() =>
							navigate(
								profile.medCardNumber == medCardNumber
									? "/profile/personal-info"
									: `/profile/sharing/${medCardNumber}`
							)
						}
					>
						{translate("events.goToProfile", true)}
					</Button>
					<Button
						onClick={() => {
							if (profile.medCardNumber != medCardNumber) {
								dispatch(
									setPatient(
										profile.attachedCards.find(
											(card) => card.medCardNumber == medCardNumber
										)
									)
								);
							}
							navigate("/history/receptions");
						}}
						light
					>
						{translate("events.goToHistory", true)}
					</Button>
				</div>
			)}
		</Dropdown>
	);
}
