import { useMemo } from "react";
import QRCode from "qrcode";
import { useTranslate } from "../../../../../../localization/translate";
import Button from "../../../../../../components/button/button";
import { defaultOptions } from "../../constants";
import { downloadByDataURL } from "../../../../../../utils";
import { Metrics } from "../../../../../../utils/metrics";

const FILE_EXT = "jpeg";
const FILE_TYPE = `image/${FILE_EXT}`;
const FILE_NAME = `qr.${FILE_EXT}`;

export default function QrDownload({ uid = null, options = {} }) {
	const translate = useTranslate();

	const extraOptions = useMemo(
		() => ({ ...defaultOptions, ...options, type: FILE_TYPE }),
		[options]
	);

	const handleDownload = () =>
		QRCode.toDataURL(uid, extraOptions)
			.then((url) => {
				Metrics.gtmEvent("download_qr");
				return downloadByDataURL(url, FILE_NAME);
			})
			.catch(console.warn);

	return (
		<Button light onClick={handleDownload}>
			{translate("profile.qr.download")}
		</Button>
	);
}
