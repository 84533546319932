import filter from "../../../../icons/filter.svg";
import arrow from "../../../../icons/arrow_down_24x24.svg";
import React, { useEffect, useState, useMemo } from "react";
import classNames from "classnames";
import Button from "../../../../components/button/button";
import Dropdown from "../../../../components/dropdown/dropdown";
import CloseModalButton from "../../../../components/modal-items/close";
import styles from "./filter.module.css";
import { useTranslate } from "../../../../localization/translate";
import Checkbox from "../../../../components/input/checkbox/Checkbox";
import { useGetProfileQuery } from "../../../profile/services/profile.api";
import header from "../../../history/modules/receptions-history/header.module.css";
import moment from "moment";
import DatePicker from "../../../../components/datepicker/datepicker";
import { useMediaQuery } from "beautiful-react-hooks";
import { NoAvatarIcon } from "../event-list-item/event-item";

export default function FilterContainer({
	list = [],
	showDates,
	applyCallback,
	defaultDates,
	externalDateRange = [null, null],
}) {
	const [dateRange, setDateRange] = useState(externalDateRange);
	const [startDate, endDate] = dateRange;
	const [opened, setOpened] = useState();
	const [applied, setApplied] = useState();
	const translate = useTranslate();
	const [expandedList, setExpandedList] = useState(null);
	const source = {
		doctors: [],
		services: [],
		patients: [],
	};

	const [filters, setFilters] = useState(JSON.parse(JSON.stringify(source)));

	const filteredList = useMemo(
		() =>
			list.filter((item) => {
				if (filters.doctors.length) {
					const match =
						item.doctor && filters.doctors.find((id) => id === item.doctor.id);
					if (!match) {
						return false;
					}
				}
				if (startDate && endDate) {
					const itemDate = item.date.split("T")[0];

					return (
						(startDate.isSame(itemDate) || startDate.isBefore(itemDate)) &&
						(endDate.isSame(itemDate) || endDate.isAfter(itemDate))
					);
				} else if (externalDateRange) {
					const [startDate, endDate] = externalDateRange;
					if (startDate && endDate) {
						return (
							(startDate.isSame(item.date) || startDate.isBefore(item.date)) &&
							(endDate.isSame(item.date) || endDate.isAfter(item.date))
						);
					}
				}
				if (filters.patients.length) {
					const match = filters.patients.find(
						(medCardNumber) => medCardNumber === item.patient.medCardNumber
					);
					if (!match) {
						return false;
					}
				}

				return true;
			}),
		[list, dateRange, externalDateRange, filters]
	);

	const filerResults = filteredList.length;

	const Component =
		expandedList &&
		items[
			expandedList[0].toUpperCase() +
				expandedList.slice((expandedList.length - 1) * -1)
		];

	const founded = function (values) {
		//выбриает слово с нжуным окночанием в завсимости от числа
		const cases = [2, 0, 1, 1, 1, 2];
		const count =
			filerResults % 100 > 4 && filerResults % 100 < 20
				? 2
				: cases[filerResults % 10 < 5 ? filerResults % 10 : 5];

		return translate(`${values[count]}`, true);
	};
	const { data: profile } = useGetProfileQuery();

	list.forEach((item) => {
		if (item.service) {
			source.services.push(item.service);
		}

		if (item.patient) {
			const match = source.patients.find(
				(patient) => patient.medCardNumber === item.patient.medCardNumber
			);
			if (!match) {
				source.patients.push(item.patient);
			}
		}

		if (item.doctor) {
			const match = source.doctors.find(
				(doctor) => doctor.id === item.doctor.id
			);
			if (!match) {
				source.doctors.push(item.doctor);
			}
		}
	});

	const firstSelected = (field) => {
		if (field === "patients") {
			if (profile && profile.medCardNumber && filters[field].length > 0) {
				const match = filters[field][0] === profile.medCardNumber;

				if (match) {
					return translate("events.you", true);
				} else {
					const patient = source[field].find(
						(patient) => patient.medCardNumber === filters[field][0]
					);

					if (patient) return patient.firstName + " " + patient.lastName;
				}
			}
		}

		if (field === "doctors") {
			if (filters[field].length > 0) {
				const doctor = source[field].find(
					(doctor) => doctor.id === filters[field][0]
				);

				if (doctor) return doctor.shortname;
			}
		}

		return "";
	};

	const isMobile = useMediaQuery("(max-width: 709px)");

	useEffect(() => {
		if (
			!opened &&
			filters.patients.length === 0 &&
			filters.doctors.length === 0 &&
			filters.services.length === 0 &&
			(!startDate || !endDate)
		) {
			setApplied(false);
		}
	}, [opened]);

	useEffect(() => {
		if (!opened) {
			setExpandedList(null);
		}

		const scroller = document.querySelector("html");
		if (isMobile) {
			if (opened) {
				scroller.style.overflowY = "hidden";
			} else {
				scroller.style.overflowY = "auto";
			}
		} else {
			scroller.setAttribute("style", "");
		}
	}, [opened]);

	return (
		<div className={styles.filter}>
			<div
				className={classNames(styles.toggler, { [styles.applied]: applied })}
				onClick={() => setOpened(!opened)}
			>
				<img src={filter} alt="" />
			</div>

			<Dropdown
				expanded={opened}
				setExpanded={setOpened}
				listenOutSideClick={!isMobile}
				className={classNames(styles.dropdown, styles.left, {
					[styles.hidden]: !opened,
				})}
			>
				<div className={styles.filter_name_expanded}>
					{expandedList && (
						<div
							className={styles.backward}
							onClick={() => setExpandedList(null)}
						>
							<img src={arrow} alt="" />
						</div>
					)}
					{applied && !expandedList && (
						<div
							className={styles.clear_filter}
							onClick={() => {
								setFilters({
									doctors: [],
									services: [],
									patients: [],
								});
								setDateRange([null, null]);
								applyCallback(list);
							}}
						>
							{translate("app.clear")}
						</div>
					)}
					<div className={styles.title}>
						{expandedList
							? translate(`events.filter.${expandedList}`)
							: translate("app.filters")}
					</div>
				</div>
				<CloseModalButton closeModal={() => setOpened(false)} />

				{!expandedList && (
					<div className={styles.items}>
						{showDates && (
							<div className={styles.row}>
								<div className={styles.subtitle}>
									{translate("events.filter.dates", true)}
								</div>
								{
									<div
										className={classNames(
											header.filter_container,
											styles.filters
										)}
									>
										<Button
											className={classNames(header.button, styles.dates_btn)}
											small
											onClick={() => setDateRange([null, null])}
										>
											{translate("history.list.forAllTime")}
										</Button>
										<Button
											small
											className={classNames(
												header.button_daterange,
												styles.dates_btn
											)}
											onClick={() => setExpandedList("dates")}
											secondary
											nonHover
										>
											{startDate && endDate ? (
												<>
													{moment(startDate).format("D MMMM YYYY")}
													&nbsp;-&nbsp;
													{moment(endDate).format("D MMMM YYYY")}
												</>
											) : (
												translate("history.list.changeDateRange", true)
											)}
											<span className={header.icon}>
												<svg
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M3.10571 6.44383C3.35766 6.18539 3.76616 6.18539 4.01811 6.44383L9.58341 12.1524L15.1487 6.44383C15.4007 6.18539 15.8092 6.18539 16.0611 6.44383C16.3131 6.70226 16.3131 7.12127 16.0611 7.3797L10.0396 13.5562C9.78766 13.8146 9.37917 13.8146 9.12722 13.5562L3.10571 7.3797C2.85376 7.12127 2.85376 6.70226 3.10571 6.44383Z"
														fill="#7A8CA4"
													/>
												</svg>
											</span>
										</Button>
									</div>
								}
							</div>
						)}

						{Object.keys(source).map((filterGroup) => (
							<div className={styles.row} key={`filterGroup -${filterGroup}`}>
								<div className={styles.subtitle}>
									{translate(`events.filter.${filterGroup}`, true)}
								</div>
								<div
									className={classNames(
										styles.select_container,
										styles.selected,
										{
											[styles.disabled]: source[filterGroup].length === 0,
										}
									)}
									onClick={(e) =>
										source[filterGroup].length > 0 &&
										setExpandedList(filterGroup)
									}
								>
									<div className={classNames("text", styles.filter_name)}>
										{filters[filterGroup].length === 0
											? translate(
													`events.filter.all${filterGroup[0].toUpperCase()}${filterGroup.slice(
														(filterGroup.length - 1) * -1
													)}`,
													true
											  )
											: firstSelected(filterGroup)}
										{filters[filterGroup].length > 1 && (
											<>
												{translate(`events.filter.andMore`)}{" "}
												{filters[filterGroup].length - 1}
											</>
										)}
									</div>
									<div className={styles.arrow}>
										<img src={arrow} alt="" />
									</div>
								</div>
							</div>
						))}
					</div>
				)}

				{expandedList &&
					(expandedList === "dates" ? (
						<div className={styles.items}>
							<div className={styles.expanded_items}>
								<DatePicker
									maxDate={moment().add(1, "years").format("YYYY-MM-DD")}
									markedDates={defaultDates}
									setDateRange={(arg) => {
										setDateRange(arg);
										setExpandedList(null);
									}}
									oneMonth={isMobile}
									calendarClassName={isMobile && styles.expanded__calendar}
									dateRange={dateRange}
									openState
									isHolidayActive
									footerClassName={isMobile && styles.expanded__calendarFooter}
								/>
							</div>
						</div>
					) : (
						<div className={classNames(styles.items, styles.scrollable_items)}>
							<div className={styles.expanded_items}>
								<div className={styles.subtitle}>
									{translate(`events.filter.${expandedList}`, true)}
								</div>
								{source[expandedList].map((item) => (
									<Component
										{...item}
										key={`list-${expandedList}${
											item?.id || item?.medCardNumber
										}`}
										setFilters={setFilters}
										filters={filters}
									/>
								))}
							</div>
						</div>
					))}

				{expandedList ? (
					expandedList === "dates" ? null : (
						<Button onClick={() => setExpandedList(null)}>
							{translate("app.select")}
						</Button>
					)
				) : (
					<Button
						onClick={() => {
							applyCallback(filteredList);
							setOpened(false);
							setApplied(true);
						}}
					>
						{translate("app.apply")}
					</Button>
				)}
				<div
					className={classNames(styles.results, {
						[styles.no_results]: filerResults === 0,
					})}
				>
					{filerResults === 0
						? translate("appointment.filters.noMatch")
						: `${founded(
								JSON.parse(translate("appointment.filters.found2", true))
						  )} ${filerResults} ${founded(
								JSON.parse(translate("events.filter.eventsNumerals", true))
						  )}`}
				</div>
			</Dropdown>
		</div>
	);
}

const items = {
	Doctors: ({ photoUrl, filters, setFilters, shortname, specialities, id }) => {
		const checked = filters.doctors.find((doctor) => doctor === id);

		return (
			<div className={styles.filter_group_item}>
				{photoUrl && (
					<img src={photoUrl} alt="" className={styles.doctor_avatar} />
				)}
				{!photoUrl && (
					<div className={styles.no_avatar}>{<NoAvatarIcon />}</div>
				)}
				<div className={classNames(styles.info, styles.row)}>
					<div className={styles.doctor_name}>
						<div className="text">{shortname}</div>
						<div>{specialities.map((item) => item.name).join(", ")}</div>
					</div>
					<Checkbox
						checked={!!checked}
						onChange={() => {
							let doctors;
							if (checked) {
								doctors = filters.doctors.filter((patient) => patient !== id);
							} else {
								doctors = [...filters.doctors, id];
							}

							setFilters({
								...filters,
								doctors,
							});
						}}
					/>
				</div>
			</div>
		);
	},
	Services: ({ setFilters }) => <div />,
	Patients: ({
		photoUrl,
		filters,
		setFilters,
		firstName,
		lastName,
		medCardNumber,
	}) => {
		const translate = useTranslate();
		const checked = filters.patients.find(
			(patient) => patient === medCardNumber
		);

		return (
			<div className={styles.filter_group_item}>
				{photoUrl ? (
					<img src={photoUrl} alt="" className={styles.patient_avatar} />
				) : (
					<div className={styles.empty_avatar}>
						{firstName[0]}. {lastName[0]}.
					</div>
				)}
				<div className={classNames(styles.info, styles.row)}>
					<div className={styles.doctor_name}>
						<div className="text">
							{firstName} {lastName}
						</div>
						<div>
							{translate(`events.filter.carNumber`, true)} {medCardNumber}
						</div>
					</div>
					<Checkbox
						checked={!!checked}
						onChange={() => {
							let patients;
							if (checked) {
								patients = (filters.patients || []).filter(
									(patient) => patient !== medCardNumber
								);
							} else {
								patients = [...filters.patients, medCardNumber];
							}

							setFilters({
								...filters,
								patients,
							});
						}}
					/>
				</div>
			</div>
		);
	},
};
