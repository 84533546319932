import { Route, Routes, NavLink } from "react-router-dom";
import Pages from "./subpages";
import routes from "./route.map.json";

export default () => {
	return (
		<div>
			<div style={{ marginBottom: 20, display: "flex", flexWrap: "wrap" }}>
				{routes.routes.map((route, key) => (
					<NavLink
						key={key}
						to={`/components${route.path}`}
						style={{ padding: "0px 20px", textDecoration: "none" }}
					>
						{route.name}
					</NavLink>
				))}
			</div>

			<Routes>
				{routes.routes.map((route, key) => {
					const Element = Pages[route.component];
					const props = { element: <Element /> };

					if (route.index) {
						props.index = route.index;
					} else {
						props.path = route.path;
					}

					return <Route key={key} {...props} />;
				})}
			</Routes>
		</div>
	);
};
