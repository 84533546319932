import { useContext, useEffect } from "react";
import { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import Button from "../../../../components/button/button";
import { useTranslate } from "../../../../localization/translate";
import CreateIssue from "../../../profile/modules/support/components/CreateIssue";
import { TAGS_TO_INVALIDATE } from "../../helpers/constants";
import styles from "./style.module.css";
import PaymentContext from "../../contexts/payment-context";
import { useNavigate } from "react-router-dom";

const SuccessIcon = () => (
	<svg
		width="81"
		height="81"
		viewBox="0 0 81 81"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="40.5" cy="40.5" r="40" fill="#54A0FF" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M63.4309 25.7779C64.9343 27.3965 64.8408 29.9275 63.2222 31.4309L37.3822 55.4309C35.8372 56.8658 33.4436 56.8551 31.9116 55.4062L19.7516 43.9062C18.1465 42.3883 18.0759 39.8566 19.5938 38.2516C21.1117 36.6465 23.6434 36.5759 25.2485 38.0938L34.6847 47.0179L57.7779 25.5692C59.3965 24.0658 61.9275 24.1592 63.4309 25.7779Z"
			fill="white"
		/>
	</svg>
);

const FailIcon = () => (
	<svg
		width="81"
		height="81"
		viewBox="0 0 81 81"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="40.5" cy="40.5" r="40" fill="#F7236F" />
		<path
			d="M40.5 17.5C38.2909 17.5 36.5 19.2909 36.5 21.5V44.5C36.5 46.7091 38.2909 48.5 40.5 48.5C42.7091 48.5 44.5 46.7091 44.5 44.5V21.5C44.5 19.2909 42.7091 17.5 40.5 17.5Z"
			fill="white"
		/>
		<path
			d="M40.5 63.5C43.2614 63.5 45.5 61.2614 45.5 58.5C45.5 55.7386 43.2614 53.5 40.5 53.5C37.7386 53.5 35.5 55.7386 35.5 58.5C35.5 61.2614 37.7386 63.5 40.5 63.5Z"
			fill="white"
		/>
	</svg>
);

export default function Results({
	type,
	title = null,
	orderNumber,
	paymentType,
	reserveId,
	isLink,
	noDescription = false,
	onClick,
}) {
	const { handleCloseModal } = useContext(PaymentContext);

	const translate = useTranslate();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isOpenCreateIssue, setIsOpenCreateIssue] = useState();

	const isFail = useMemo(() => type === "fail", [type]);
	const isSuccess = useMemo(() => type === "success", [type]);

	useEffect(() => {
		if (isSuccess) {
			TAGS_TO_INVALIDATE.forEach((item) => dispatch(item));
		}
	}, [isSuccess]);

	const actionLocalizationKey = useMemo(
		() => (isFail ? `${type}` : `${type}.${paymentType}`),
		[isFail, isSuccess]
	);

	return (
		<div className={styles.results}>
			<div className={styles.content}>
				{isSuccess && <SuccessIcon />}
				{isFail && <FailIcon />}

				<div className={styles.title}>
					{title ? (
						title
					) : (
						<>
							{isSuccess
								? isLink
									? translate(`payments.Results.${type}.${paymentType}.link`)
									: translate(`payments.Results.${type}.${paymentType}.title`)
								: translate(`payments.Results.${type}.title`)}
							{isSuccess && orderNumber && <>&nbsp;{orderNumber}</>}
						</>
					)}
				</div>

				{!noDescription && (
					<div className={styles.description}>
						{isLink
							? translate(`payments.Results.${type}.linkCopied`)
							: translate(`payments.Results.${type}.description`)}
					</div>
				)}

				{isFail && (
					<Button
						light
						small
						className={styles.support_btn}
						onClick={() => setIsOpenCreateIssue(true)}
					>
						{translate("payments.Results.fail.support_btn")}
					</Button>
				)}
			</div>

			{(!!onClick || !!handleCloseModal) && !reserveId && (
				<div className={styles.actions}>
					<Button
						className={styles.action_btn}
						onClick={onClick || handleCloseModal}
					>
						{translate(`payments.Results.${actionLocalizationKey}.action_btn`)}
					</Button>
				</div>
			)}

			<CreateIssue
				isOpen={isOpenCreateIssue}
				handleClose={() => setIsOpenCreateIssue(false)}
			/>
		</div>
	);
}
