import CloseModalButton from "../modal-items/close";
import css from "./modal-selector.module.css";
import arrow from "../../icons/arrow_down_24x24.svg";
import Button from "../button/button";
import Modal from "../modal-safari-wrapper/modal";
import { useMediaQuery } from "beautiful-react-hooks";
import classNames from "classnames";

export default function SelectorModal({
	children,
	onRequestClose,
	isOpen,
	applyBtn = {
		onClick: () => {},
		text: "",
		backwardIcon: false,
		disabled: false,
	},
	title,
	subtitle,
	advancedButtons,
	wrapperClassName,
}) {
	const isMobile = useMediaQuery("(max-width: 620px)");
	const modalStyleMobile = {
		content: {
			display: "flex",
			justifyContent: "center",
			padding: "0",
			alignItems: "stretch",
		},
		overlay: {
			zIndex: 145,
			display: "flex",
			alignItems: "center",
		},
	};
	const modalStyle = Object.assign({}, modalStyleMobile);
	modalStyle.content = {
		...modalStyleMobile.content,
		width: "455px",
		maxWidth: 455,
	};
	modalStyle.overlay = {
		...modalStyleMobile.overlay,
	};

	return (
		<Modal
			isOpen={isOpen}
			style={isMobile ? modalStyleMobile : modalStyle}
			onRequestClose={onRequestClose}
		>
			<CloseModalButton closeModal={onRequestClose} />
			<div className={css.modal_body}>
				<div className={css.modal_title}>
					{applyBtn.backwardIcon && (
						<div onClick={applyBtn.onClick} className={css.back}>
							<img src={arrow} />
						</div>
					)}
					{title}
				</div>
				{subtitle && <div className={css.modal_subtitle}>{subtitle}</div>}
				<div className={classNames(css.modal_wrapper, wrapperClassName)}>
					{children}
				</div>
				{!applyBtn.backwardIcon && (
					<div className={css.apply_button}>
						{!!applyBtn.text && (
							<Button onClick={applyBtn.onClick} disabled={applyBtn.disabled}>
								{applyBtn.text}
							</Button>
						)}
						{advancedButtons}
					</div>
				)}
			</div>
		</Modal>
	);
}
