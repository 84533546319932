import { useState } from "react";
import { useTranslate } from "../../../../../../localization/translate";
import InfoBanner from "../../../../../../components/info-banner";
import Button from "../../../../../../components/button/button";
import ReviewModal from "../ReviewModal";
import CreateIssue from "../CreateIssue";
import styles from "./style.module.css";

export default function SupportInfoBanner({ canCreate = false }) {
	const translate = useTranslate();
	const [isOpenReview, setIsOpenReview] = useState(false);
	const [isOpenCreate, setIsOpenCreate] = useState(false);
	const list = translate("profile.support.info.list");

	return (
		<>
			<InfoBanner title={translate("profile.support.info.title")}>
				<ul className={styles.list}>
					<li>{list[0]}</li>
					<li>
						{list[1]}
						&nbsp;
						<button
							className={styles.link}
							onClick={() => setIsOpenReview(true)}
						>
							{list[2]}
						</button>
					</li>
					<li>
						{list[3]}
						<br />
						<a
							href={`tel:${translate("profile.support.info.supportTel", true)}`}
							className={styles.link}
						>
							{translate("profile.support.info.supportTel")}
						</a>
						&nbsp;
						{list[4]}
						&nbsp;
						<button
							className={styles.link}
							onClick={() => setIsOpenCreate(true)}
						>
							{translate("profile.support.info.createIssue")}
						</button>
					</li>
				</ul>
			</InfoBanner>

			{canCreate && (
				<Button
					className={styles.create_btn}
					onClick={() => setIsOpenCreate(true)}
				>
					{translate("profile.support.info.createIssue2")}
				</Button>
			)}

			<ReviewModal
				isOpen={isOpenReview}
				handleClose={() => setIsOpenReview(false)}
			/>

			<CreateIssue
				isOpen={isOpenCreate}
				handleClose={() => setIsOpenCreate(false)}
			/>
		</>
	);
}
