import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "../../../../localization/translate";
import { CustomModal } from "../../../../components/custom-modal";
import Loader from "../../../../components/loader";
import ListItemRow from "../../../../components/list-item-row";
import Results from "../../../payments/components/Results";
import { useGetDoctorByIdQuery } from "../../services/appointment.api";
import { setPaymentData } from "../../../payments/services/payments.slice";
import { PAYMENT_TYPES } from "../../../payments/helpers/constants";
import styles from "./style.module.css";

export default function SelectServices({
	isOpen,
	doctorId,
	slotIdMis,
	onCloseSelf,
	cancelExamination,
	medCardNumber = null,
	specialityId,
}) {
	const translate = useTranslate();
	const dispatch = useDispatch();

	const { data, isLoading, error } = useGetDoctorByIdQuery(
		{ doctorId },
		{ skip: !isOpen }
	);

	const services = useMemo(() => data?.services || [], [data]);

	useEffect(() => () => cancelExamination(), []);

	const handleSelectServices = async (id) => {
		cancelExamination();

		dispatch(
			setPaymentData({
				PaymentModal: {
					type: PAYMENT_TYPES.service,
					purposeId: id,
					scheduleIdMis: slotIdMis,
					medCardNumber,
					doctorId,
				},
				PaymentTarget: data,
			})
		);
	};

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onCloseSelf}
			title={translate("appointment.confirm.selectService")}
		>
			<div className={styles.content}>
				{isLoading && (
					<Loader
						containerStyle={{ display: "flex", justifyContent: "center" }}
					/>
				)}

				{data && services.length > 0 && (
					<div className={styles.services}>
						{services.map((service) => (
							<ListItemRow
								key={service.id}
								title={service.name}
								renderArrow={() => (
									<div className={styles.service_price}>
										{service.price.toLocaleString()}&nbsp;
										{translate("app.currencySign")}
									</div>
								)}
								onClick={() => handleSelectServices(service.id)}
							/>
						))}
					</div>
				)}

				{(error || !services.length) && !isLoading && (
					<Results
						type="fail"
						noDescription
						onClick={cancelExamination}
						title={translate("appointment.confirm.selectServiceError")}
					/>
				)}
			</div>
		</CustomModal>
	);
}
