import css from "./credit-waiting.module.css";
import Lottie from 'react-lottie';
import * as animationData from './credit-waiting-anim.json'
import {useTranslate} from "../../../../../../localization/translate";

export default function CreditWaiting() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const t = useTranslate();

  return <div className={css.container}>
    <Lottie options={defaultOptions}
            height={"auto"}
            width={"100%"}
            isStopped={false}
            isPaused={false}
    />
    <div className={css.text}>{t("profile.coffee.inProgress")}</div>
  </div>
}