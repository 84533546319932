import classNames from "classnames";
import { useTranslate } from "../../localization/translate";
import styles from "./style.module.css";
import { isIOSApp, isAndroidApp, VERSION } from "../../utils";
import { lightFormat } from "date-fns";

export const ContentBlock = ({
	title,
	url,
	children,
	noMargin = false,
	className = "",
}) => (
	<div
		className={classNames(styles.content_block, className, {
			[styles.no_margin]: noMargin,
		})}
	>
		{url && title && (
			<a
				className={classNames(styles.title, styles.link)}
				target="_blank"
				rel="noreferrer"
				href={url}
			>
				{title}
			</a>
		)}
		{!url && title && <div className={styles.title}>{title}</div>}

		{children && (
			<div style={{ marginTop: title ? 16 : 0 }}>
				<div className={styles.text}>{children}</div>
			</div>
		)}
	</div>
);

export default function Footer({ isAuth = false, isInApp = false, className }) {
	const translate = useTranslate();

	return (
		<footer
			className={classNames(
				styles.footer,
				{
					[styles.is_auth]: isAuth,
					[styles.mobile_application]: isAndroidApp || isIOSApp,
					[styles.app]: isInApp,
				},
				className
			)}
		>
			<div className={styles.content}>
				{!isAuth && (
					<div className={styles.promo}>
						<div className={styles.promo_title}>
							{translate("footer.promoTitle")}
						</div>

						<div className={styles.promo_desc}>
							{translate("footer.promoDesc")}
						</div>

						{
							!isIOSApp
							&& !isAndroidApp
							&& <div className={styles.stores}>
								<a
									href="https://www.apple.com/ru/app-store/"
									target="_blank"
									rel="noreferrer"
								>
									<img src="/images/app-store.png" alt="" />
								</a>
								<a
									href="https://play.google.com/store?hl=ru&gl=US"
									target="_blank"
									rel="noreferrer"
								>
									<img src="/images/google-play.png" alt="" />
								</a>
							</div>
						}
					</div>
				)}

				<div className={styles.leftpad}>
					<ContentBlock
						noMargin
						title={translate("footer.contractOffer")}
						url="https://www.medicina.ru/local/files/dogovor_oferta.pdf"
					/>

					<ContentBlock
						noMargin
						title={translate("footer.moneyBack")}
						url="https://www.medicina.ru/pravila-vozvrata-sredstv/"
					/>

					<ContentBlock
						noMargin
						title="Условия обработки данных сервисом Yandex Captcha"
						url="https://yandex.ru/legal/smartcaptcha_notice/"
					/>

					<ContentBlock
						noMargin
						title={translate("footer.privacy")}
						url="https://www.medicina.ru/politika-obrabotki-personalnykh-dannykh-v-oao-meditsina/"
					>
						{translate("footer.privacyDesc1")}
						&nbsp;
						<a
							className={styles.inline_link}
							href="https://www.medicina.ru/personal-data-patients/"
							target="_blank"
							rel="noreferrer"
						>
							{translate("footer.privacyDesc2")}
						</a>
						&nbsp;
						{translate("footer.privacyDesc3")}
						&nbsp;
						<a
							className={styles.inline_link}
							href="https://www.medicina.ru/personal-data-employees/"
							target="_blank"
							rel="noreferrer"
						>
							{translate("footer.privacyDesc4")}
						</a>
						&nbsp;
						{translate("footer.privacyDesc5")}
					</ContentBlock>

					<ContentBlock noMargin className={styles.pm_block}>
						<div className={styles.payment_methods}>
							<img
								alt="payment_methods"
								src="/images/footer/pm_row_first.png"
								className={styles.pm_row}
							/>

							<img
								alt="payment_methods"
								src="/images/footer/pm_row_third.png"
								className={styles.pm_row}
							/>
						</div>
					</ContentBlock>

					<ContentBlock className={styles.pm_info}>
						{translate("footer.paymnetMethodsFirst")}
					</ContentBlock>

					<ContentBlock>
						{translate("footer.paymentMethodsSecure")}
						&nbsp;
						<a
							className={styles.inline_link}
							href="https://www.assist.ru"
							target="_blank"
							rel="noreferrer"
						>
							{translate("footer.paymentMethodsSecure2")}
						</a>
						&nbsp;
						{translate("footer.paymentMethodsSecure3")}
					</ContentBlock>

					<ContentBlock>
						©{lightFormat(new Date(), "yyyy")}
						{translate("footer.license1")}
						,<br />
						<a
							className={styles.inline_link}
							target="_blank"
							rel="noreferrer"
							href="https://www.medicina.ru/o-klinike/nagrady-sertifikaty-litsenzii-svidetelstva/#licenses-12"
						>
							{translate("footer.license2")}
						</a>
						,&nbsp;
						<a
							className={styles.inline_link}
							target="_blank"
							rel="noreferrer"
							href="https://www.medicina.ru/yuridicheskaya-informatsiya/"
						>
							{translate("footer.license3")}
						</a>
					</ContentBlock>

					{!!VERSION && (
						<ContentBlock className={styles.version}>
							<span>Версия{(isAndroidApp || isIOSApp) && " приложения"}: </span>
							<span>{VERSION}</span>
						</ContentBlock>
					)}
				</div>

				{!isAuth && (
					<div className={styles.rightpad}>
						<ContentBlock noMargin className={styles.pm_block}>
							<div className={styles.payment_methods}>
								<img
									alt="payment_methods"
									src="/images/footer/pm_row_first.png"
									className={styles.pm_row}
								/>

								<img
									alt="payment_methods"
									src="/images/footer/pm_row_third.png"
									className={styles.pm_row}
								/>
							</div>
						</ContentBlock>

						<ContentBlock className={styles.pm_info}>
							{translate("footer.paymnetMethodsFirst")}
						</ContentBlock>
					</div>
				)}
			</div>
		</footer>
	);
}
