import { useGetProfileQuery } from "../../services/profile.api";
import {
	setCreateModalState,
	setEditModalState,
} from "../../services/profile.slice";
import { useTranslate } from "../../../../localization/translate";
import styles from "./personal-data.module.css";
import "../../profile.css";
import ProfileNotice from "./notice";
import classNames from "classnames";
import NumberFormat from "react-number-format";
import PersonalDataCreate from "./personal-data.create";
import { useDispatch } from "react-redux";
import Avatar from "./avatar";
import PersonalDataEdit from "./personal-data.edit.";
import ProfileCard from "./profile-card";
import Contracts from "./contracts";

export default function PersonalData() {
	const { data, isLoading, error } = useGetProfileQuery();
	const translate = useTranslate();
	const dispatch = useDispatch();

	if (!data || error) {
		return <Wrapper error={error} />;
	}

	const { email, invoicePayment } = data;

	return (
		<Wrapper error={error}>
			<ProfileCard
				{...data}
				isLoading={isLoading}
				editEmail={() => dispatch(setEditModalState(true))}
			>
				{invoicePayment && (
					<ProfileNotice
						title={translate("app.attention", true)}
						message={translate("profile.personalData.hasUnpaidBills", true)}
						btn={{
							onClick: () => console.log("check"),
							text: translate("profile.personalData.goToBilling", true),
						}}
					/>
				)}

				<Contracts />
			</ProfileCard>

			<PersonalDataEdit savedEmail={email} />
		</Wrapper>
	);
}

const Wrapper = ({ children, error }) => {
	const translate = useTranslate();

	return (
		<div className={styles.pd}>
			<div className={classNames("h3", styles.title, "profile_content_title")}>
				{translate("profile.personalData.title")}
			</div>
			{error && <ProfileNotice title={translate("app.failedToFetch", true)} />}
			{children}
		</div>
	);
};
