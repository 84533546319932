import { useMemo, Children } from "react";

export default function useExtraItems(children, length, show) {
	const extraPreviousItems = useMemo(() => {
		let output = [];

		for (let index = 0; index < show; index++) {
			output.push(Children.toArray(children)[length - 1 - index]);
		}

		output.reverse();
		return output;
	}, [children, show, length]);

	const extraNextItems = useMemo(() => {
		let output = [];

		for (let index = 0; index < show; index++) {
			output.push(Children.toArray(children)[index]);
		}

		return output;
	}, [children, show]);

	return {
		extraNextItems,
		extraPreviousItems,
	};
}
