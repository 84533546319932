import { useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import styles from "./style.module.css";

export const ControlledCollapser = ({
	isOpen = false,
	initHeight = 0,
	children,
}) => {
	const contentRef = useRef(null);
	const [contentHeight, setContentHeight] = useState(initHeight);

	const wrapperStyles = useMemo(
		() => ({ height: isOpen ? contentHeight : initHeight }),
		[isOpen, initHeight, contentHeight]
	);

	const updateHeight = () =>
		setContentHeight(contentRef?.current?.scrollHeight);

	useEffect(updateHeight, [children]);
	useEffect(() => {
		if (isOpen) setTimeout(updateHeight, 450);
	}, [isOpen]);

	return (
		<div className={styles.collapser}>
			<div className={styles.wrapper} style={wrapperStyles}>
				<div ref={contentRef} className={styles.content}>
					{children}
				</div>
			</div>
		</div>
	);
};

const ArrowIcon = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.10571 13.5562C3.35766 13.8146 3.76616 13.8146 4.01811 13.5562L9.58341 7.84764L15.1487 13.5562C15.4007 13.8146 15.8092 13.8146 16.0611 13.5562C16.3131 13.2977 16.3131 12.8787 16.0611 12.6203L10.0396 6.44383C9.78766 6.18539 9.37917 6.18539 9.12722 6.44383L3.10571 12.6203C2.85376 12.8787 2.85376 13.2977 3.10571 13.5562Z"
			fill="#7A8CA4"
		/>
	</svg>
);

export const Collapser = ({ initHeight = 0, children, title, arrow }) => {
	const [isOpen, setIsOpen] = useState(false);

	const iconClassNames = useMemo(
		() => cx(styles.arrow, { [styles.down]: isOpen }),
		[isOpen]
	);

	return (
		<div className={styles.row}>
			<div className={styles.title} onClick={() => setIsOpen(!isOpen)}>
				<span>{title}</span>
				<div className={iconClassNames}>{arrow ?? <ArrowIcon />}</div>
			</div>

			<ControlledCollapser isOpen={isOpen} initHeight={initHeight}>
				{children}
			</ControlledCollapser>
		</div>
	);
};
