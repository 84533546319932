import { createApi } from "@reduxjs/toolkit/query/react";
import baseConfig from "../../../app/config.api";

export const eventsApi = createApi({
	reducerPath: "eventsApi",
	baseQuery: baseConfig,
	tagTypes: ["Planned", "Polls", "PollGeneralInfo", "PollResults", "PollId"],
	endpoints: (builder) => ({
		getPlan: builder.query({
			query: () => "/appointment/plan/",
			providesTags: ["Planned"],
		}),
		getHistory: builder.query({
			query: (year) =>
				year ? `/appointment/history/?year=${year}` : "/appointment/history/",
			providesTags: ["History"],
		}),
		appointmentCancel: builder.mutation({
			query: ({ slotIdMis, medCardNumber }) => ({
				url: "appointment/cancel",
				method: "POST",
				body: {
					slotIdMis,
					medCardNumber,
				},
			}),
			invalidatesTags: (result, error, arg) => {
				return ["Planned"];
			},
		}),
		examinationReschedule: builder.mutation({
			query: (body) => ({
				url: "/appointment/examination/reschedule/",
				method: "POST",
				body,
			}),
			invalidatesTags: ["Planned"],
		}),
		diagnosticReschedule: builder.mutation({
			query: (body) => ({
				url: "/appointment/diagnostic/reschedule/",
				method: "POST",
				body,
			}),
			invalidatesTags: ["Planned"],
		}),
		getTelemedLink: builder.mutation({
			query: (id) => ({
				url: `/telemedicine/${id}/join_url`,
				method: "GET",
			}),
			transformResponse: (response) => response.data,
		}),
		prepareRecords: builder.mutation({
			query: (id) => ({
				url: `/telemedicine/${id}/records/`,
				method: "POST",
			}),
			transformResponse: (response) => response.data,
		}),
		verifyUrl: builder.mutation({
			query: (url) => ({
				url: "/verify_url/",
				method: "POST",
				body: { url },
			}),
			transformResponse: (response) => response.data,
		}),
		getPoll: builder.query({
			query: (id) => `/poll/${id}`,
			providesTags: ["Polls"],
			transformResponse: (response) => response.data,
		}),
		pollAnswer: builder.mutation({
			query: ({ pollId, answerId, questionId, value }) => ({
				url: `/poll/${pollId}/answer`,
				method: "POST",
				body: { answerId, questionId, ...(value?.length && { value }) },
			}),
			invalidatesTags: (_, __, { silent }) => (!silent ? ["Polls"] : []),
		}),
		getGeneralInfo: builder.query({
			query: () => "/general_info/",
			providesTags: ["PollGeneralInfo"],
			transformResponse: (response) => response.data,
		}),
		getPollResults: builder.query({
			query: (id) => `/poll/${id}/result/`,
			providesTags: ["PollResults"],
			transformResponse: (response) => response.data,
		}),
		getPollId: builder.query({
			query: () => "/poll/",
			providesTags: ["PollId"],
			transformResponse: (response) => response.data,
		}),
	}),
});

export const {
	useGetPlanQuery,
	useGetHistoryQuery,
	useAppointmentCancelMutation,
	useExaminationRescheduleMutation,
	useDiagnosticRescheduleMutation,
	useGetTelemedLinkMutation,
	usePrepareRecordsMutation,
	useVerifyUrlMutation,
	useGetPollQuery,
	usePollAnswerMutation,
	useGetGeneralInfoQuery,
	useGetPollResultsQuery,
	useGetPollIdQuery,
} = eventsApi;
