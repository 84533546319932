import { useMemo } from "react";
import styles from "./style.module.css";

export default ({ currentIndex, isRepeating, length, show }) => {
	const localShow = useMemo(
		() => (isRepeating ? show : 0),
		[isRepeating, show]
	);

	const calculatedActiveIndex = useMemo(
		() =>
			currentIndex - localShow < 0
				? length + (currentIndex - localShow)
				: currentIndex - localShow,
		[currentIndex, length, localShow]
	);

	return (
		<div className={styles.dots}>
			{Array.from(new Array(length)).map((item, index) => (
				<div
					key={index}
					data-index={index}
					className={
						styles[index === calculatedActiveIndex ? "dots_active" : "dots_far"]
					}
				></div>
			))}
		</div>
	);
};
