import { useDispatch, useSelector } from "react-redux";
import {
	selectResetPassword,
	setAuthInfo,
	setAuthInfoToLS,
	setResetPasswordInfo,
} from "../../services/login.slice";
import { useEffect, useState } from "react";
import styles from "./rp.module.css";
import Input from "../../../../components/input";
import Button from "../../../../components/button/button";
import { ModalContainer } from "./request-reset-password";
import { useTranslate } from "../../../../localization/translate";
import { magicLinkValidation } from "../../services/utils";
import { useResetPasswordConfirmMutation } from "../../services/login.api";
import {
	getErrorMessageFromError,
	getFromClarificationByTarget,
} from "../../../../app/error-handler.api";
import classNames from "classnames";
import { Metrics, loginGtmEvent } from "../../../../utils/metrics";
import {
	selectExaminationInfo,
	setExaminationInfo,
} from "../../../appointment/services/appointment.slice";
import { getDoctorSheduleByToken } from "../../../../utils";
import { pushSystemNotification } from "../../../../app/app.slice";

const modalStyle = {
	content: {
		width: "455px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "0",
	},
	overlay: {
		display: "flex",
		alignItems: "center",
		zIndex: 200,
	},
};

export default function NewPasswordModal() {
	const passwordResetInfo = useSelector(selectResetPassword);
	const [modalIsOpen, setIsOpen] = useState(true);
	const examinationInfo = useSelector(selectExaminationInfo);

	const closeModal = () => {
		setIsOpen(false);
	};

	const makeValidation = (val) => {
		if (val.length > 0) {
			const validation = magicLinkValidation(val);
			if (!validation.valid) {
				const errors = validation.messages.map((msg) => translate(msg, true));
				setError(errors.join("; "));
			}
		}
	};

	const [error, setError] = useState(null);
	const isDisabled = () => {
		if (password.length === 0) return true;
		// if (isLoading) return true;
		if (error) return true;

		return false;
	};
	const [password, setPassword] = useState("");
	const [trigger, { data, error: queryError }] =
		useResetPasswordConfirmMutation();

	const sendPassword = () => {
		trigger({
			uid: passwordResetInfo.uid,
			token: passwordResetInfo.token,
			newPassword: password,
		});
	};
	const dispatch = useDispatch();
	const translate = useTranslate();

	useEffect(() => {
		if (data && data.data?.access) {
			//TODO: обсудить с беком почему приходяит не валидный токен (возможно токен является гостевым)
			dispatch(
				setAuthInfo({
					accessToken: data.data.access,
					refreshToken: data.data.refresh,
					isGuest: false,
				})
			);

			if (examinationInfo?.magicLink) {
				getDoctorSheduleByToken(data.data.access, {
					dateFrom: examinationInfo.lDate,
					dateTo: examinationInfo.lDate,
					doctorId: examinationInfo.lDoctorId,
					specialityId: examinationInfo.lSpecialityId,
				})
					.then((data) => {
						const timeSlot = data.timeSlots.find(
							({ idMis }) => idMis === examinationInfo.lSlotIdMis
						);

						dispatch(
							setExaminationInfo({
								magicLink: true,
								acceptAdultsShowWarning: data.doctorInfo.doctor.acceptAdults,
								doctor: data.doctorInfo.doctor,
								date: `${timeSlot.time}`,
								cabinet: timeSlot.cabinet,
								slotIdMis: timeSlot.idMis,
								specialityId: examinationInfo.lSpecialityId,
								receptionTypes: data.doctorInfo.speciality.appointmentFeature,
								additionalSlots: examinationInfo.lAdditionalSlots,
								serviceId: examinationInfo.lServiceId,
							})
						);

						dispatch(setResetPasswordInfo(null));
					})
					.catch((err) => {
						dispatch(
							pushSystemNotification({
								type: "error",
								clientMessage: err?.data?.messages[0]?.clientMessage,
							})
						);
					});

				loginGtmEvent(data.data.access, "new_password");
				dispatch(setAuthInfoToLS());
			}
		}
	}, [data]);

	useEffect(() => {
		if (queryError) {
			const clarifications = getErrorMessageFromError({
				fromClarifications: true,
				error: queryError,
			});

			if (clarifications && clarifications.length > 0) {
				const error = getFromClarificationByTarget(
					clarifications,
					"newPassword"
				);
				error && setError(error.message);
			} else {
				dispatch(
					pushSystemNotification({
						type: "error",
						clientMessage: queryError.data?.messages[0].clientMessage,
					})
				);
			}

			Metrics.gtmEvent("auth_fail", { auth_way: "new_password" });
		}
	}, [queryError]);

	if (passwordResetInfo) {
		return (
			<ModalContainer
				closeModal={() => setIsOpen(false)}
				isOpen={modalIsOpen}
				style={modalStyle}
			>
				<div className={styles.body}>
					<div className={`${styles.title} h3`}>
						{translate("login.resetPassword.set.title", true)}
					</div>
					<div className={styles.frame}>
						<div className={`${styles.description} text`}>
							{translate("login.resetPassword.set.description")}
						</div>
						<Input
							type="text"
							value={password}
							onBlur={(val) => makeValidation(val)}
							onChange={(val) => {
								setError(null);
								setPassword(val);
							}}
							containerClass={styles.input}
							onKeyUp={(e) =>
								e.key === "Enter" && !isDisabled() && sendPassword()
							}
							errorMsg={error}
							placeholder={translate(
								"login.resetPassword.set.placeholder",
								true
							)}
						/>
						<div
							className={classNames(styles.subdescription, {
								[styles.with_error]: !!error,
							})}
						>
							{translate("login.resetPassword.set.passwordCharsMustBe")}
							<br />
							<br />
							<ul>
								{JSON.parse(
									translate("login.resetPassword.set.terms", true)
								).map((li) => (
									<li key={li}>{li}</li>
								))}
							</ul>
						</div>
						<Button
							disabled={isDisabled()}
							onClick={() => sendPassword()}
							className={styles.btn}
						>
							{translate("app.forms.save", true)}
						</Button>
					</div>
				</div>
			</ModalContainer>
		);
	} else {
		return null;
	}
}
