import { useState } from "react";
import styles from "./date-mask-input.module.css";
import classNames from "classnames";
import InputMask from "react-input-mask";

export default function DateMaskInput({ props }) {
	const {
		placeholder = "",
		mask = "99.99.9999",
		maskPlaceholder = "дд.мм.гггг",
		containerClass,
		errorMsg,
		onChange = () => {},
		value = "",
		onKeyUp,
		className,
	} = props;

	const [isTyping, setIsTyping] = useState(false);
	const containerClassnames = classNames(styles.container, containerClass, {
		"invalid-value": errorMsg && value.length > 0,
	});
	const inputClassNames = classNames(
		"form-control",
		styles.text_input,
		className
	);

	return (
		<div className={containerClassnames}>
			{(value.length !== 0 || isTyping) && (
				<div className="special-label">{placeholder}</div>
			)}

			<InputMask
				mask={mask}
				value={value}
				onChange={(e) => onChange(e.target.value)}
				placeholder={placeholder}
				maskPlaceholder={maskPlaceholder}
				onFocus={() => setIsTyping(true)}
				onBlur={() => setIsTyping(false)}
				onKeyUp={onKeyUp}
				className={inputClassNames}
			/>

			{errorMsg && <div className={styles.error_message}>{errorMsg}</div>}
		</div>
	);
}
