import { createApi } from "@reduxjs/toolkit/query/react";
import baseConfig from "./config.api";

export const applicationApi = createApi({
	reducerPath: "applicationApi",
	baseQuery: baseConfig,
	endpoints: (builder) => ({
		upload: builder.mutation({
			query: (file) => {
				const form = new FormData();
				form.append("file", file);

				return {
					url: "/upload",
					method: "POST",
					// headers: {
					//     'Content-type': 'multipart/form-data',
					// },
					body: form,
				};
			},
		}),
	}),
});

export const { useUploadMutation } = applicationApi;
