import { useEffect, useMemo } from "react";
import classNames from "classnames";
import moment from "moment";
import Loader from "../../../../components/loader";
import ListItemRow from "../../../../components/list-item-row";
import { useTranslate } from "../../../../localization/translate";
import { useGetDiscountDataQuery } from "../../services/profile.api";
import { useRouterDynamicSegment } from "./hooks/useRouterDynamicSegment";
import InfoBannerWrapper from "./components/InfoBlockWrapper";
import styles from "./discount-card.module.css";
import { Metrics } from "../../../../utils/metrics";

const DiscountCard = () => {
	const translate = useTranslate();
	const id = useRouterDynamicSegment(0);

	const hasId = useMemo(() => !!Number(id), [id]);

	const { data: discounts, isLoading } = useGetDiscountDataQuery(null, {
		skip: !hasId,
	});

	const discountCard = useMemo(
		() =>
			hasId &&
			discounts?.discountCards?.find(({ number }) => number === Number(id)),
		[discounts, hasId, id]
	);

	useEffect(() => {
		if (discountCard || !hasId)
			Metrics.gtmEvent("view_discount_item", {
				discount_type: "discount-card",
			});
	}, [discountCard, hasId]);

	return (
		<div className={styles.discount_card}>
			<div className={classNames("h3", styles.title)}>
				{translate("profile.discounts.discountCard.title")}
			</div>

			{hasId && (
				<div className={styles.content_block}>
					{isLoading && (
						<Loader
							containerStyle={{ display: "flex", justifyContent: "center" }}
						></Loader>
					)}

					{discountCard && (
						<div className={styles.inner}>
							<div className={styles.discount}>
								{translate("profile.discounts.discountCard.sale")}{" "}
								{discountCard.totalDiscountValue}%
							</div>

							<ListItemRow
								title={translate("profile.discounts.discountCard.cardNumber")}
								renderArrow={() => (
									<span className={styles.row_details}>
										{discountCard.name}
									</span>
								)}
							/>
							<ListItemRow
								title={translate("profile.discounts.discountCard.dateUntil")}
								renderArrow={() => (
									<span className={styles.row_details}>
										{moment(discountCard.validTill).format("DD MMMM YYYY")}
									</span>
								)}
							/>

							{discountCard.services?.length > 0 && (
								<div className={styles.services}>
									<div className={styles.services_title}>
										{translate(
											"profile.discounts.discountCard.servicesDiscounts"
										)}
									</div>

									{discountCard.services.map((item) => (
										<ListItemRow
											key={item.service.id}
											title={item.service.name}
											description={`-${item.serviceDiscountValue}%`}
											showArrow={false}
										/>
									))}
								</div>
							)}
						</div>
					)}
				</div>
			)}

			<div className={styles.content_block}>
				<InfoBannerWrapper
					title={translate("profile.discounts.discountCard.whatIt")}
					list={translate("profile.discounts.discountCard.infoList")}
					learMoreLink="https://www.medicina.ru/patsientam/diskontnye-programmy/"
				>
					{translate("profile.discounts.discountCard.description")}
				</InfoBannerWrapper>
			</div>
		</div>
	);
};

export default DiscountCard;
