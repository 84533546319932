import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseConfig from "../../../app/config.api";

export const bannersApi = createApi({
	reducerPath: "bannersApi",
	baseQuery: baseConfig,
	tagTypes: ["Banners"],
	endpoints: (builder) => ({
		getBanners: builder.query({
			query: () => "/banner",
			providesTags: ["Banners"],
			transformResponse: (response) => response.data,
		}),
	}),
});

export const { useGetBannersQuery } = bannersApi;
