import styles from "./LoginSelector.module.css";
import { forms } from "../login-page";
import { useTranslate } from "../../../../localization/translate";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginMethod, setLoginMethod } from "../../services/login.slice";
import RequestMagicLink from "../magic-link/request-link";
import RequestResetPassword from "../reset-password/request-reset-password";

export default function LoginSelector({
	onSelect = () => {},
	advancedFunctional = true,
	isWidget = false,
}) {
	const formWithPasswordButtons = ["byLogin", "byEmail"]; //для каких типов логина есть кнопки "reset pass" & "login without pass"
	const translate = useTranslate();
	const dispatch = useDispatch();
	const form = useSelector(selectLoginMethod);

	return (
		<>
			{advancedFunctional && formWithPasswordButtons.includes(form) && (
				<div className={styles.passwordButtons}>
					<RequestMagicLink isWidget={isWidget} />
					<RequestResetPassword isWidget={isWidget} />
				</div>
			)}
			<div className={`${styles.otherWays} text`}>
				<div>{translate("login.otherWays")}</div>
				{Object.keys(forms)
					.filter((key) => key !== form)
					.map((key) => (
						<div
							key={key}
							className={styles.link}
							onClick={() => {
								dispatch(setLoginMethod(key));
								onSelect(key);
							}}
						>
							{translate(`login.${key}`, true)}
						</div>
					))}
			</div>
		</>
	);
}
