import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const useRouterDynamicSegment = (byPosition) => {
	const dynamicSegment = useParams()["*"];

	return useMemo(
		() =>
			dynamicSegment.split("/").filter((segment) => !!segment)[byPosition] ??
			null,
		[dynamicSegment]
	);
};
