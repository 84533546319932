import Modal from "../modal-safari-wrapper/modal";
import CloseModalButton from "../modal-items/close";
const modalStyle = {
	content: {
		padding: "30px 56px 40px !important",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		inset: 0,
	},
	overlay: {
		display: "flex",
		alignItems: "center",
	},
};

export function ContentModal({
	closeModal,
	isOpen,
	children,
	className,
	styles,
}) {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			style={{ ...modalStyle, ...styles }}
			className={className}
		>
			<CloseModalButton closeModal={closeModal} />
			{children}
		</Modal>
	);
}
