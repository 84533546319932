import { useTranslate } from "../../../../../../localization/translate";
import { useSetIssueTopicMutation } from "../../../../services/profile.api";
import styles from "./style.module.css";

export default function TopicSelect({ issueId }) {
	const translate = useTranslate();
	const [setTopic] = useSetIssueTopicMutation();

	const ISSUES_TOPICS = {
		appointment: translate("profile.support.topicSelector.topics.appointment"),
		registration: translate(
			"profile.support.topicSelector.topics.registration"
		),
		authorisation: translate(
			"profile.support.topicSelector.topics.authorisation"
		),
		protocol: translate("profile.support.topicSelector.topics.protocol"),
		other: translate("profile.support.topicSelector.topics.other"),
	};

	const handleSelectTopic = (topic) => setTopic({ issueId, topic });

	return (
		<div className={styles.topics_list}>
			<div className={styles.title}>
				{translate("profile.support.topicSelector.title")}
			</div>

			<div className={styles.topics}>
				{Object.entries(ISSUES_TOPICS).map(([key, value]) => (
					<button
						key={key}
						className={styles.topic}
						onClick={() => handleSelectTopic(key)}
					>
						{value}
					</button>
				))}
			</div>
		</div>
	);
}
