import { useMemo } from "react";
import { useTranslate } from "../../../../localization/translate";
import SearchInput from "../../../../components/search-input/search-input";
import Button from "../../../../components/button/button";
import styles from "./style.module.css";

export default function Search({ value, setValue }) {
	const translate = useTranslate();

	const isFilled = useMemo(() => value.length > 0, [value]);

	const handleReset = () => setValue("");

	return (
		<div className={styles.search}>
			<SearchInput
				placeholder={translate("notifications.search.placeholder", true)}
				value={value}
				onChange={setValue}
				className={styles.input}
			/>
			{isFilled && (
				<Button small light className={styles.reset_btn} onClick={handleReset}>
					{translate("app.cancel")}
				</Button>
			)}
		</div>
	);
}
