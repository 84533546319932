import React, { useEffect, useMemo } from "react";
import DoctorModalBody from "../../features/appointment/modules/doctor-modal/doctor-modal-body";
import { useParams } from "react-router-dom";
import { useGetDoctorByIdQuery } from "../../features/appointment/services/appointment.api";
import s from "./index.module.css";
import SubscribeModal from "../../features/appointment/modules/subscribe-modal/subscribe-modal";
import ReceptionTypeModal from "../../features/appointment/modules/reception-type-modal/reception-type-modal";
import ExaminationModal from "../../features/appointment/modules/examination-modal/examination-modal";
import PaymentModal from "../../features/payments/payment-modal";
import { useDispatch, useSelector } from "react-redux";
import {
	initAmount,
	initDate,
	initDiscount,
	initInvoice,
	initMedCardNumber,
	isOpenPaymentModal,
	paymentData,
	paymentTarget,
	resetPaymentData,
} from "../../features/payments/services/payments.slice";
import {
	selectReceptionTypeIsOpen,
	selectedDoctor,
	setSelectedDoctor,
} from "../../features/appointment/services/appointment.slice";
import {getSiteSourceName, tokenPayload} from "../../utils";
import {
	selectAccessToken,
	selectExpired,
} from "../../features/login/services/login.slice";
import ActivityTimerProvider from "../../features/login/activity-timer-provider";
import ShortCodeLogin from "../../features/login/modules/login";

function setCookie(name, value, options = {}) {

	options = {
		path: '/',
		// при необходимости добавьте другие значения по умолчанию
		...options
	};

	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString();
	}

	let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

	for (let optionKey in options) {
		updatedCookie += "; " + optionKey;
		let optionValue = options[optionKey];
		if (optionValue !== true) {
			updatedCookie += "=" + optionValue;
		}
	}

	document.cookie = updatedCookie;
}


export default function DoctorAppointmentWidget() {
	const dispatch = useDispatch();
	const { doctorId } = useParams();
	const { data: doctor } = useGetDoctorByIdQuery(
		{ doctorId, isMis: true },
		{ skip: !doctorId }
	);
	const doctorInfo = useSelector(selectedDoctor);
	const isOpen = useSelector(isOpenPaymentModal);
	const paymentConfig = useSelector(paymentData);
	const targetConfig = useSelector(paymentTarget);
	const invoice = useSelector(initInvoice);
	const amount = useSelector(initAmount);
	const discount = useSelector(initDiscount);
	const date = useSelector(initDate);
	const medCardNumber = useSelector(initMedCardNumber);
	const token = useSelector(selectAccessToken);
	const expired = useSelector(selectExpired);
	const isReceptionOpen = useSelector(selectReceptionTypeIsOpen);

	const isAuth = useMemo(() => {
		if (!!token) {
			const { guest } = tokenPayload(token);
			return !guest;
		} else return false;
	}, [token]);

	const handleClosePaymentModal = () => dispatch(resetPaymentData());

	useEffect(() => {
		if (localStorage.getItem("test")) {
			localStorage.removeItem("test", "123")
		} else {
			localStorage.setItem("test", "123")


		}


		setCookie('user4', 'Johnqwr', {secure: true, 'max-age': 3600, SameSite: "none"});
	}, []);

	useEffect(() => {
		if (doctor) {
			dispatch(setSelectedDoctor(doctor));
		}
	}, [doctor]);

	if (expired && isReceptionOpen) {
		return <ShortCodeLogin />;
	}

	return (
		<ActivityTimerProvider time={60 * 1000} isWidget>
			<div className={s.container}>
				<div style={{display: "none"}}>{getSiteSourceName()}</div>
				<DoctorModalBody
					doctorInfo={doctorInfo || doctor}
					setSelectedDoctor={(data) => dispatch(setSelectedDoctor(data))}
					isWidget
				/>
				<SubscribeModal isPublic={!isAuth} isWidget/>
				<ReceptionTypeModal isPublic={!isAuth} isWidget/>
				<ExaminationModal isPublic={!isAuth} isWidget/>
				<PaymentModal
					isOpen={isOpen}
					onClose={handleClosePaymentModal}
					{...paymentConfig}
					target={targetConfig}
					initInvoice={invoice}
					initAmount={amount}
					initDiscount={discount}
					initDate={date}
					initMedCardNumber={medCardNumber}
					isWidget
				/>
			</div>
		</ActivityTimerProvider>
	);
}
