import { useTranslate } from "../../../../localization/translate";
import { useDeclension } from "../../../../utils/declension";
import Button from "../../../../components/button/button";
import styles from "./style.module.css";

export default function CheckedActions({ count, onDelete, onRead }) {
	const translate = useTranslate();
	const notificationsCount = useDeclension(
		count,
		translate("notifications.notifications")
	);

	return (
		<div className={styles.checked_actions}>
			<div className={styles.actions}>
				<Button
					small
					nonHover
					secondary
					className={styles.mark_as_readed}
					onClick={onRead}
				>
					{translate("notifications.markAsReaded")}
				</Button>

				<Button small className={styles.delete} onClick={onDelete}>
					{translate("notifications.delete")}
				</Button>
			</div>

			<div className={styles.selected_count}>
				{translate("notifications.selected")}
				&nbsp;{count}&nbsp;
				{notificationsCount}
			</div>
		</div>
	);
}
