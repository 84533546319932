import { ContentModal } from "../../../../components/custom-modal/content.modal";
import style from "./change-short-code.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
	selectShortCodeEditOpened,
	setShortCodeEditOpened,
} from "../../services/profile.slice";
import SetupSC from "../../../login/modules/short-code/setup";
import { scrollToPosition } from "../../../../utils";
import { Metrics } from "../../../../utils/metrics";

export default function ChangeShortCode() {
	const isOpened = useSelector(selectShortCodeEditOpened);
	const dispatch = useDispatch();
	const closeModal = () => {
		setTimeout(() => scrollToPosition(0, "smooth"), 0);
		dispatch(setShortCodeEditOpened(false));
		Metrics.gtmEvent("change_code");
	};

	return (
		<ContentModal closeModal={closeModal} isOpen={isOpened}>
			<div className={style.csc}>
				<SetupSC wrap={false} onComplete={closeModal} />
			</div>
		</ContentModal>
	);
}
