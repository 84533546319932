import { useState, useMemo, useEffect } from "react";
import { useTranslate } from "../../localization/translate";
import { CustomModal } from "../../components/custom-modal";
import useNavigationStack from "./hooks/useNavigationStack";
import { NavigationProvider } from "./contexts/navigation-context";
import { PaymentProvider, InitialPayment } from "./contexts/payment-context";
import { ResultWrapper, UrlPayment } from "./modules";
import { Metrics } from "../../utils/metrics";
import { useLocation, useNavigate } from "react-router-dom";
import s from "./index.module.css";

export default function PaymentModal({
	isOpen,
	onClose,
	purposeId,
	reserveId,
	doctorId,
	type,
	subtype,
	scheduleIdMis,
	medCardNumber,
	initInvoice,
	initAmount,
	initDiscount,
	initDate,
	initMedCardNumber,
	target = null,
	isWidget = false,
}) {
	const translate = useTranslate();
	const location = useLocation();
	const navigate = useNavigate();
	const [payment, setPayment] = useState(InitialPayment);
	const { Component, length, push, pop, erase } = useNavigationStack(type);

	useEffect(() => {
		setPayment((prev) => ({
			...prev,
			invoice: initInvoice || null,
			date: initDate || null,
			medCardNumber: initMedCardNumber || null,
			checkout: {
				...payment.checkout,
				amount: initAmount,
				discounted: initDiscount,
			},
		}));
	}, [initInvoice, initAmount, initDiscount, initMedCardNumber, initDate]);

	useEffect(() => {
		const { checkout } = payment;

		if (isOpen && checkout?.paymentMethod)
			Metrics.gtmEvent("begin_order_payment", {
				orderId: purposeId,
				total_sum: checkout.amount,
				payment_sum: checkout.discounted ?? checkout.amount,
				discount: checkout.discounted
					? checkout.amount - checkout.discounted
					: 0,
				payment_method: checkout.paymentMethod?.name,
			});
	}, [isOpen, payment?.checkout?.paymentMethod?.name]);

	const isResultScreen = useMemo(
		() => Component === ResultWrapper,
		[Component]
	);

	const isUrlPayment = useMemo(() => Component === UrlPayment, [Component]);

	const handleCloseModal = () => {
		setPayment(InitialPayment);
		onClose();

		if (location.pathname.endsWith("/payment")) {
			navigate(location.pathname.replace("/payment", ""));
		}
	};

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={handleCloseModal}
			showBackwardBtn={length > 1 && !isResultScreen && !isUrlPayment}
			onBackwardClick={pop}
			title={translate(`payments.${type}.title`)}
			className={s.modal}
		>
			<NavigationProvider value={{ length, push, erase }}>
				<PaymentProvider value={{ payment, setPayment, handleCloseModal }}>
					{!!type && (
						<Component
							type={type}
							scheduleIdMis={scheduleIdMis}
							medCardNumber={medCardNumber}
							purposeId={purposeId}
							reserveId={reserveId}
							doctorId={doctorId}
							subtype={subtype}
							target={target}
							isWidget={isWidget}
						/>
					)}
				</PaymentProvider>
			</NavigationProvider>
		</CustomModal>
	);
}
