import { createSlice } from "@reduxjs/toolkit";
import md5 from "md5";

const initialState = {
	applicationError: null,
	showSlowInternetScreen: false,
	systemNotifications: [],
};

export const appSlice = createSlice({
	name: "application",
	initialState,
	reducers: {
		setApplicationError: (state, action) => {
			state.applicationError = action.payload;
		},
		pushSystemNotification: (state, { payload }) => {
			const notification = {
				...payload,
				id: md5(Math.random()),
			};
			state.systemNotifications =
				state.systemNotifications.concat(notification);
		},
		resetNotification: (state, { payload }) => {
			state.systemNotifications = state.systemNotifications.filter(
				(noti) => noti.id !== payload
			);
		},
		resetAllNotifications: (state) => {
			state.systemNotifications = [];
		},
		setShowSlowInternetScreen: (state, { payload }) => {
			state.showSlowInternetScreen = payload;
		},
	},
});

export const {
	setApplicationError,
	resetNotification,
	pushSystemNotification,
	resetAllNotifications,
	setShowSlowInternetScreen,
} = appSlice.actions;

export const selectApplicationError = (state) =>
	state.application.applicationError;
export const selectSystemNotifications = (state) =>
	state.application.systemNotifications;
export const selectShowSlowInternetScreen = (state) =>
	state.application.showSlowInternetScreen;

export default appSlice.reducer;
