import css from "./how-to-use.module.css";
import React from "react";

export default function Close({onClose}) {
  return <div className={css.close} onClick={onClose}>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#767680" fillOpacity="0.12"/>
      <path d="M9.92188 20.4531C9.53125 20.0625 9.54688 19.3984 9.91406 19.0312L13.5781 15.3672L9.91406 11.7109C9.54688 11.3438 9.53125 10.6797 9.92188 10.2891C10.3047 9.89844 10.9688 9.91406 11.3438 10.2812L15 13.9453L18.6562 10.2891C19.0391 9.91406 19.6875 9.90625 20.0781 10.2969C20.4688 10.6797 20.4609 11.3359 20.0859 11.7109L16.4297 15.3672L20.0859 19.0312C20.4609 19.4062 20.4688 20.0625 20.0781 20.4453C19.6875 20.8359 19.0391 20.8359 18.6562 20.4531L15 16.7969L11.3438 20.4609C10.9688 20.8281 10.3047 20.8438 9.92188 20.4531Z" fill="#3C3C43" fillOpacity="0.6"/>
    </svg>
  </div>
}