import classNames from "classnames";
import css from "./accounts.module.css";
import { useTranslate } from "../../../../localization/translate";
import ItemSelector from "../../../../components/item-selector/item-selector";
import arrow from "../../../../icons/arrow_down_24x24.svg";
import Button from "../../../../components/button/button";
import DefaultPaymentMethodSelector from "./default-payment-method-selector";
import React, { useEffect, useState } from "react";
import AccountInfo from "../account/account-info";
import {
	useGetPaymentMethodsQuery,
	useGetProfileQuery,
} from "../../services/profile.api";
import Loader from "../../../../components/loader";
import HasNoItems from "../../../../components/has-no-items/has-no-items";
import CardInfo from "../card-info/card-info";
import AccountTopUp from "../account/account-top-up";
import {
	accountToReplenish,
	setAccountData,
} from "../../services/profile.slice";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentData } from "../../../payments/services/payments.slice";
import { PAYMENT_TYPES } from "../../../payments/helpers/constants";

export default function PaymentAccounts() {
	const translate = useTranslate();
	const [defaultModal, setDefaultIsOpen] = useState(false);
	const { data: profile, error: profileError } = useGetProfileQuery();
	const { data, isLoading, error } = useGetPaymentMethodsQuery(null, {
		skip: !profile || (profile && !profile.medCardNumber),
	});
	const [accounts, setAccounts] = useState(null);
	const dispatch = useDispatch();
	const account = useSelector(accountToReplenish);

	useEffect(() => {
		if (data) {
			setAccounts(data);
		}
	}, [data]);

	const handleOpenPayments = () =>
		dispatch(
			setPaymentData({
				PaymentModal: {
					type: PAYMENT_TYPES.test,
					purposeId: null,
				},
				PaymentTarget: null,
			})
		);

	if ((isLoading || !profile) && !error && !profileError) {
		return (
			<div className={css.wrapper}>
				<div className={classNames("h3", css.h3)}>
					{translate("profile.menu.bills")}
				</div>
				<div className={css.loader}>
					<Loader />
				</div>
			</div>
		);
	}

	if (!profile.medCardNumber) {
		return (
			<div className={css.wrapper}>
				<div className={classNames("h3", css.h3)}>
					{translate("profile.menu.bills")}
				</div>
				<div className={css.loader}>
					<HasNoItems
						text={translate("profile.bills.hasNoAccounts")}
						positionY={"top"}
					/>
				</div>
			</div>
		);
	}

	if (error || profileError) {
		return (
			<div className={css.wrapper}>
				<div className={classNames("h3", css.h3)}>
					{translate("profile.menu.bills")}
				</div>
			</div>
		);
	}

	return (
		<div className={css.wrapper}>
			<div className={classNames("h3", css.h3)}>
				{translate("profile.menu.bills")}
			</div>

			{accounts && (
				<>
					{(accounts.bankCard.length !== 0 ||
						accounts.accounts.length !== 0) && (
						<ItemSelector
							activeElementAction={() => setDefaultIsOpen(true)}
							activeElementText={translate("app.forms.changeBtn", true)}
							text={
								<span>
									{translate("profile.bills.defaultPaymentMethod", true) +
										(accounts.defaultPaymentMethod ? ": " : "")}
								</span>
							}
							className={css.payment_method_selector}
							noChoice={!accounts.defaultPaymentMethod}
						>
							{accounts.defaultPaymentMethod && (
								<div className={css.default_method}>
									{accounts.defaultPaymentMethod.name}
								</div>
							)}
							{!accounts.defaultPaymentMethod && (
								<div className={css.no_default_method}>
									<HasNoItems
										text={translate("profile.bills.defaultNotSelected")}
										positionY="top"
									/>
								</div>
							)}
							{!accounts.defaultPaymentMethod && (
								<div
									className={classNames("text-center", css.no_default_method)}
								>
									<Button light onClick={() => setDefaultIsOpen(true)}>
										{translate("profile.bills.chooseDefaultMethod", true)}
									</Button>
								</div>
							)}
							<DefaultPaymentMethodSelector
								isOpen={defaultModal}
								setIsOpen={setDefaultIsOpen}
								accounts={accounts}
								setAccounts={setAccounts}
							/>
						</ItemSelector>
					)}
					{accounts.bankCard.length > 0 ? (
						<div className={css.category}>
							<div className={css.category_title}>
								{translate("profile.bills.allMethods")}
							</div>

							{accounts.bankCard.map((card) => (
								<div
									className={css.row}
									key={`bank-card-${card.name}`}
									onClick={() =>
										dispatch(
											setAccountData({
												key: "cardInfo",
												value: card,
											})
										)
									}
								>
									<div className={css.info}>
										<div className={css.account}>{card.name}</div>
									</div>
									<div className={css.arrow}>
										<img src={arrow} alt="" />
									</div>
								</div>
							))}
						</div>
					) : (
						<div className={css.no_payment_methods}>
							<HasNoItems
								positionY={"top"}
								text={translate("profile.bills.hasNoPaymentMethods")}
							/>
						</div>
					)}

					<div className="text-center">
						<Button light onClick={handleOpenPayments}>
							{translate("profile.bills.addCreditCard", true)}
						</Button>
					</div>

					{accounts.accounts.length > 0 ? (
						<>
							{accounts.accounts.filter((account) => !account.isFamily).length >
								0 && (
								<div className={css.category}>
									<div className={css.category_title}>
										{translate("profile.bills.personalAccounts")}
									</div>
									{accounts.accounts
										.filter((account) => !account.isFamily)
										.map((account) => (
											<div
												className={css.row}
												onClick={() =>
													dispatch(
														setAccountData({
															key: "accountInfo",
															value: account,
														})
													)
												}
												key={`personal-account-${account.id}`}
											>
												<div className={css.info}>
													<div className={css.account}>{account.name}</div>
													<div className={css.account_info}>
														{translate("profile.bills.balance", true)}: &nbsp;
														{parseFloat(account.balance).toLocaleString(
															undefined,
															{ minimumFractionDigits: 2 }
														)}
														&nbsp;
														{translate("app.currencySign", true)}
													</div>
												</div>
												<div className={css.arrow}>
													<img src={arrow} alt="" />
												</div>
											</div>
										))}
								</div>
							)}

							{accounts.accounts.filter((account) => account.isFamily).length >
								0 && (
								<div className={css.category}>
									<div className={css.category_title}>
										{translate("profile.bills.sharedAccounts")}
									</div>
									{accounts.accounts
										.filter((account) => account.isFamily)
										.map((account) => (
											<div
												className={css.row}
												onClick={() =>
													dispatch(
														setAccountData({
															key: "accountInfo",
															value: account,
														})
													)
												}
												key={`share-account-${account.id}`}
											>
												<div className={css.info}>
													<div className={css.account}>{account.name}</div>
													{account.balance && (
														<div className={css.account_info}>
															{translate("profile.bills.balance", true)}: &nbsp;
															{account.balance}
															&nbsp;
															{translate("app.currencySign", true)}
														</div>
													)}
													{account.owner && (
														<div className={css.account_info}>
															{translate("profile.bills.owner", true)}: &nbsp;
															{account.owner}
														</div>
													)}
												</div>
												<div className={css.arrow}>
													<img src={arrow} alt="" />
												</div>
											</div>
										))}
								</div>
							)}
						</>
					) : (
						<div className={css.no_payment_methods}>
							<HasNoItems
								positionY={"top"}
								text={translate("profile.bills.hasNoPaymentAccounts")}
							/>
						</div>
					)}
					<AccountInfo />
					{account && (
						<AccountTopUp
							account={account}
							setBalance={(balance) => {
								setAccounts({
									...accounts,
									accounts: accounts.accounts.map((item) => {
										if (item.id == account.id) {
											return {
												...item,
												balance,
											};
										}

										return item;
									}),
								});
							}}
						/>
					)}
					<CardInfo />
				</>
			)}
		</div>
	);
}
