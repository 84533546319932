import { useState, useEffect } from "react";
import { useTranslate } from "../../../../localization/translate";
import { CustomModal } from "../../../../components/custom-modal";
import CloseModalButton from "../../../../components/modal-items/close";
import Input from "../../../../components/input";
import Button from "../../../../components/button/button";
import Loader from "../../../../components/loader";
import styles from "./styles.module.css";
import { Metrics } from "../../../../utils/metrics";
import moment from "moment/moment";

const HelperBlock = ({ translate }) => (
	<div className={styles.whereToSend}>
		{translate("login.sms.enterPatData")}
	</div>
);

export default function MedCardRecoveryFormView({
																									isOpen,
																									onClose,
																									isLoading,
																									goBack,
																									confirmAction,
																									isWidget,
																									type
																								}) {
	const translate = useTranslate();
	const [cardNumber, setNum] = useState("");
	const [name, setName] = useState("");
	const [secName, setSecName] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [fieldError, setFieldError] = useState(null);

	const handleSubmit = () => {
		if(dateOfBirth.split('.')[0] > "31" ||
			dateOfBirth.split('.')[1] > "12" ||
			dateOfBirth.split('.')[2] > "2022" ||
			dateOfBirth.split('.')[2] < "1900" ||
			dateOfBirth.split('.')[2] === "гггг" ||
			dateOfBirth === '') {
			setFieldError(translate("profile.personalData.invalidDateOfBirth", true));
			setTimeout(function(){
				setFieldError(null);
			}, 2000);
		} else {
			confirmAction(name, secName, dateOfBirth);
			// Metrics.gtmEvent("send_med_card_number");
		}
	};

	const handleInputKeyDown = (event) => {
		if (event.key === "Enter") handleSubmit();
	};

	useEffect(() => {
		// if(dateOfBirth.split('.')[0] > "31" && dateOfBirth.split('.')[0] !== "dd") {
		// 	setFieldError(
		// 		translate("login.sms.incorrectCardNumTypeOnlyDigits", true)
		// 	);
		// } else {
		// 	setFieldError(null);
		// }
		// if (/[^+\d]/g.test(cardNumber)) {
		// 	setFieldError(
		// 		translate("login.sms.incorrectCardNumTypeOnlyDigits", true)
		// 	);
		// } else {
		// 	setFieldError(null);
		// }
		// console.log('dateOfBirth', dateOfBirth.split('.'));
	}, [name, secName, dateOfBirth]);

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			className={styles.rmodal}
			style={{
				overlay: {
					zIndex: 180,
					display: "flex",
					alignItems: isWidget ? "flex-start" : "center",
				},
			}}
		>
			<div className={styles.modal}>
				<CloseModalButton closeModal={onClose} />

				<div className={`${styles.title} h3`}>
					{translate("profile.support.recovery.title")}
				</div>

				<HelperBlock translate={translate} />

				<div className={styles.field_container}>
					<Input
						type="text"
						value={name}
						onChange={(val) => {
							setName(val);
						}}
						className={styles.tt_capitalize}
						placeholder={translate(
							"profile.support.create.firstname",
							true,
						)}
					/>
				</div>


				<div className={styles.field_container}>
					<Input
						type="text"
						value={secName}
						onChange={(val) => {
							setSecName(val);
						}}
						className={styles.tt_capitalize}
						placeholder={translate(
							"profile.support.create.secondname",
							true,
						)}
					/>
				</div>

				<div className={styles.field_container}>
					<Input
						type="date"
						value={dateOfBirth}
						errorMsg={fieldError}
						onChange={(val) => {
							setDateOfBirth(val);
							// 	deleteError("dateOfBirth");
						}}
						// onBlur={(val) => chekDoB(val)}
						placeholder={translate("profile.personalData.dateOfBirth", true)}
					/>
				</div>
				{isLoading ? (
					<div className={styles.loader}>
						<Loader />
					</div>
				) : (
					<Button
						disabled={name.length === 0 || secName.length === 0 || fieldError || dateOfBirth.includes('г') || dateOfBirth.length === 0}
						className={styles.enter_card_login}
						onClick={handleSubmit}
					>
						{translate("login.resetPassword.request.btn", true)}
					</Button>
				)}
				{type && type === "phone" && <div className={styles.text_back} onClick={goBack}>{translate("polls.back")}</div>}
			</div>
		</CustomModal>
	);
}
