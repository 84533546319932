import { useContext, useMemo } from "react";
import { useTranslate } from "../../../../localization/translate";
import Loader from "../../../../components/loader";
import MethodsSelector from "../../components/MethodsSelector";
import Results from "../../components/Results";
import PaymentContext from "../../contexts/payment-context";
import NavigationContext from "../../contexts/navigation-context";
import { useGetDiscountDataQuery } from "../../services/payments.api";
import { DiscountMethodsFactory } from "../../helpers/utils";

export default function Discounts({ type, purposeId, medCardNumber = null }) {
	const translate = useTranslate();
	const {
		payment: { checkout },
		setPayment,
	} = useContext(PaymentContext);
	const { erase } = useContext(NavigationContext);

	const { data, isLoading, error } = useGetDiscountDataQuery({
		paymentPurpose: type,
		medCardNumber,
		purposeId,
	});

	const mapDiscountMethods = useMemo(
		() => DiscountMethodsFactory(translate),
		[translate]
	);

	const discounts = useMemo(() => mapDiscountMethods(data), [data]);

	const handleSelectMethod = (item) => {
		setPayment((prev) => ({ ...prev, discount: item }));
		erase();
	};

	return (
		<>
			{isLoading && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}

			{data && !error && (
				<MethodsSelector
					subtitle={
						<>
							{translate(`payments.DiscountMethods.${type}.subtitle`, true)}
							&nbsp;
							{checkout.amount?.toLocaleString()}&nbsp;
							{translate("app.currencySign", true)}
						</>
					}
					blockTitle={translate("payments.DiscountMethods.availableMethods")}
					collection={discounts}
					onClickMethod={handleSelectMethod}
				/>
			)}

			{error && <Results type="fail" />}
		</>
	);
}
