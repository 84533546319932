import css from "./orders.module.css";
import classNames from "classnames";
import arrow from "../../../../icons/arrow_down_24x24.svg";
import HasNoItems from "../../../../components/has-no-items/has-no-items";
import { useTranslate } from "../../../../localization/translate";
import React, {
	useEffect,
	useState,
	useRef,
	useCallback,
	useMemo,
} from "react";
import PatientSelector from "../../../appointment/modules/patientSelector";
import { useMediaQuery } from "beautiful-react-hooks";
import Button from "../../../../components/button/button";
import moment from "moment";
import Dropdown from "../../../../components/dropdown/dropdown";
import DatePicker from "../../../../components/datepicker/datepicker";
import { useDispatch, useSelector } from "react-redux";
import { selectPatient } from "../../services/profile.slice";
import {
	useGetOrdersQuery,
	useGetProfileQuery,
	useHideBillMutation,
	useDeleteReceivedBillMutation,
} from "../../services/profile.api";
import Modal from "../../../../components/modal-safari-wrapper/modal";
import CloseModalButton from "../../../../components/modal-items/close";
import header from "../../../history/modules/receptions-history/header.module.css";
import Loader from "../../../../components/loader";
import SelectorModal from "../../../../components/item-selector/modal-selector";
import modal from "./invoice-info.module.css";
import { fileViewer } from "../../../../utils";
import { pushSystemNotification } from "../../../../app/app.slice";
import Patient from "../../../events/modules/history/history-card/patient-dropdown";
import { PAYMENT_TYPES } from "../../../payments/helpers/constants";
import { setPaymentData } from "../../../payments/services/payments.slice";
import { copyText } from "../../../../utils/index";
import { useCreateReceivedBillMutation } from "../../../received-bills/services/received-bills.api";
import Results from "../../../payments/components/Results";
import { moscowTimeZone } from "../../../../utils/dates/timezone-converter";
import { scrollToPosition, isAndroidApp, isIOSApp } from "../../../../utils";
import { Metrics } from "../../../../utils/metrics";

export default function Orders() {
	const timestampRef = useRef(Date.now())?.current;
	const [isPaid, setIsPaid] = useState(false);
	const translate = useTranslate();
	const [defaultDates, setMarkedDates] = useState();
	const patient = useSelector(selectPatient);
	const { data: profile } = useGetProfileQuery();
	const currentPatient = patient ? patient : profile;
	const { data, isFetching } = useGetOrdersQuery(
		{
			medCardNumber: currentPatient && currentPatient.medCardNumber,
			sessionId: timestampRef,
		},
		{ skip: !currentPatient || !profile.medCardNumber }
	);
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;
	const ordersByDate = {};

	const orders = data?.bills
		? data?.bills.filter((item) => {
				let res = true;

				if (startDate && endDate) {
					// если выбран диапазон дат

					res =
						(moment(item.date).isSame(startDate) ||
							moment(item.date).isAfter(startDate)) &&
						(moment(item.date).isSame(
							endDate.hours(23).minutes(59).seconds(59)
						) ||
							moment(item.date).isBefore(
								endDate.hours(23).minutes(59).seconds(59)
							));
				}

				if (isPaid !== item.isPaid) {
					res = false;
				}

				if (res && profile) {
					const date = moment(item.date).format("YYYY-MM-DD");

					if (ordersByDate[date]) {
						ordersByDate[date].push(item);
					} else {
						ordersByDate[date] = [item];
					}
				}

				return res;
		  })
		: [];
	const [selectedOrder, setSelectedOrder] = useState();
	const [pdfLoading, setPdfLoading] = useState(false);
	const dispatch = useDispatch();
	const [
		hideBill,
		{ data: hideBillResponse, isLoading: isHiding, error: hideError },
	] = useHideBillMutation();
	const [
		deleteReceivedBill,
		{
			data: deleteReceivedBillResponse,
			isLoading: isDeleting,
			error: deleteError,
		},
	] = useDeleteReceivedBillMutation();
	const isMobile = useMediaQuery("(max-width: 620px)");
	const [patientIsOpen, patientSetIsOpen] = useState();
	const [copied, setCopied] = useState(false);

	const [createReceivedBill] = useCreateReceivedBillMutation();
	const [reveiveError, setReceiveError] = useState(false);

	useEffect(() => {
		if (hideError) {
			dispatch(
				pushSystemNotification({
					type: "error",
					clientMessage:
						hideError.messages &&
						hideError.messages.length > 0 &&
						hideError.messages[0].clientMessage
							? hideError.messages[0].clientMessage
							: translate("app.failedToFetch", true),
				})
			);
		}
	}, [hideError]);

	useEffect(() => {
		if (deleteError) {
			dispatch(
				pushSystemNotification({
					type: "error",
					clientMessage:
						deleteError.messages &&
						deleteError.messages.length > 0 &&
						deleteError.messages[0].clientMessage
							? deleteError.messages[0].clientMessage
							: translate("app.failedToFetch", true),
				})
			);
		}
	}, [deleteError]);

	useEffect(() => {
		if (hideBillResponse || deleteReceivedBillResponse) {
			setSelectedOrder(null);
		}
	}, [hideBillResponse, deleteReceivedBillResponse]);

	useEffect(() => {
		if (data?.bills) {
			setMarkedDates(
				data?.bills
					.filter((item) => item.isPaid === isPaid)
					.map((item) => moment(item.date).format("YYYY-MM-DD"))
			);

			Metrics.gtmEvent("view_orders");
		}
	}, [data?.bills, isPaid]);

	const wrapperOptions = {
		defaultDates,
		orders,
		isPaid,
		setIsPaid,
		dateRange,
		setDateRange,
	};

	if (isFetching || !profile) {
		return (
			<Wrapper {...wrapperOptions}>
				<div className={css.loader}>
					<Loader />
				</div>
			</Wrapper>
		);
	}

	if (orders.length === 0) {
		return (
			<Wrapper {...wrapperOptions}>
				<div className={css.no_items}>
					<HasNoItems
						text={translate(
							`profile.orders.${isPaid ? "hasNoPaid" : "hasNoUnPaid"}`
						)}
						positionY="top"
					/>
				</div>
			</Wrapper>
		);
	}

	const handleCopyShareLink = (shareLink) =>
		copyText(shareLink)
			.then(() => setCopied(true))
			.catch(console.error);

	return (
		<Wrapper {...wrapperOptions}>
			<div className={css.category_list}>
				{Object.keys(ordersByDate).length > 0 && (
					<>
						<div className={css.category_title}>
							{translate("profile.orders.personalOrders")}
						</div>
						<List
							list={ordersByDate}
							keyName="profile"
							selectOrder={setSelectedOrder}
						/>
					</>
				)}
				{data?.receivedBills.length > 0 && (
					<>
						<div className={css.category_title} style={{ marginBottom: 28 }}>
							{translate("profile.orders.sentOrders")}
						</div>
						<ReceivedList
							list={data?.receivedBills}
							selectOrder={setSelectedOrder}
						/>
					</>
				)}
			</div>
			<SelectorModal
				onRequestClose={() => setSelectedOrder(null)}
				title={translate("profile.bills.invoice")}
				isOpen={!!selectedOrder}
				wrapperClassName={modal.wrapper_class_name}
				applyBtn={
					selectedOrder
						? {
								onClick: async () => {
									if (selectedOrder.isPaid) {
										setPdfLoading(true);
										fileViewer(
											selectedOrder.billUrl,
											() => setPdfLoading(false),
											(e) => {
												let message;
												if (typeof e === "string") {
													message = e;
												} else {
													message =
														e.messages &&
														e.messages.length > 0 &&
														e.messages[0].clientMessage
															? e.messages[0].clientMessage
															: translate("app.failedToFetch", true);
												}

												dispatch(
													pushSystemNotification({
														type: "error",
														clientMessage: message,
													})
												);
												setPdfLoading(false);
											}
										);
									} else {
										if (selectedOrder.id) {
											const isReceived =
												selectedOrder.payer.medCardNumber !==
												profile.medCardNumber;

											if (isReceived) {
												const { data, error } = await createReceivedBill(
													selectedOrder.invoiceNumber || selectedOrder.id
												);

												if (error) {
													setReceiveError(true);
													return;
												}

												dispatch(
													setPaymentData({
														PaymentModal: {
															type: PAYMENT_TYPES.test,
															purposeId: data.invoiceNumber,
														},
														PaymentTarget: selectedOrder,
														InitInvoice: data.invoiceNumber,
													})
												);
											} else {
												const type = selectedOrder.invoiceNumber
													? selectedOrder.date
														? PAYMENT_TYPES.invoice
														: PAYMENT_TYPES.test
													: PAYMENT_TYPES.other;

												const invoiceNumber = selectedOrder.invoiceNumber
													? !selectedOrder.date
														? selectedOrder.invoiceNumber
														: null
													: selectedOrder.id;

												dispatch(
													setPaymentData({
														PaymentModal: {
															type,
															purposeId:
																selectedOrder.invoiceNumber || selectedOrder.id,
														},
														PaymentTarget: selectedOrder,
														InitInvoice: invoiceNumber,
														InitAmount: selectedOrder.amountToPay,
														InitDiscount: selectedOrder.amountDiscounted,
														InitMedCardNumber: currentPatient.medCardNumber,
														InitDate: selectedOrder.date.split("T")[0],
													})
												);
											}
											setSelectedOrder(null);
										} else
											throw new Error(
												"Selected Order does not have InvoiceNumber"
											);
									}
								},
								text: translate(
									`app.${selectedOrder.isPaid ? "showPDF" : "pay"}`,
									true
								),
								disabled: pdfLoading || isHiding || isDeleting,
						  }
						: {}
				}
				advancedButtons={
					<>
						{selectedOrder && (selectedOrder.isPaid || !selectedOrder.date) ? (
							<Button
								light
								disabled={isHiding || isDeleting}
								onClick={() => {
									if (selectedOrder.id) {
										hideBill(selectedOrder.id);
									} else {
										deleteReceivedBill(selectedOrder.invoiceNumber);
									}
								}}
								variant={"error"}
								className={modal.adv_btn}
							>
								{translate("profile.bills.removeInvoice", true)}
							</Button>
						) : (
							<Button
								light
								disabled={pdfLoading}
								onClick={() => {
									setPdfLoading(true);
									fileViewer(
										selectedOrder.billUrl,
										() => setPdfLoading(false),
										(e) => {
											let message;
											if (typeof e === "string") {
												message = e;
											} else {
												message =
													e.messages &&
													e.messages.length > 0 &&
													e.messages[0].clientMessage
														? e.messages[0].clientMessage
														: translate("app.failedToFetch", true);
											}

											dispatch(
												pushSystemNotification({
													type: "error",
													clientMessage: message,
												})
											);
											setPdfLoading(false);
										}
									);
								}}
								className={modal.adv_btn}
							>
								{translate("app.showPDF", true)}
							</Button>
						)}
					</>
				}
			>
				{selectedOrder && (
					<div className={modal.body}>
						<div className={modal.amount}>
							{(
								selectedOrder.amountDiscounted || selectedOrder.amount
							)?.toLocaleString()}
							&nbsp;
							{translate("app.currencySign")}
						</div>
						<div className={modal.description}>
							{translate("profile.bills.invoiceFor")}
							{selectedOrder.id || selectedOrder.invoiceNumber}
						</div>
						<div className={modal.info}>
							{(!selectedOrder.date ||
								selectedOrder.payer.medCardNumber !==
									profile.medCardNumber) && (
								<div className={modal.item}>
									{translate("appointment.confirm.patient")}
									<div
										className={modal.link}
										onClick={() => patientSetIsOpen(true)}
									>
										{selectedOrder.payer.firstName}{" "}
										{selectedOrder.payer.lastName}
										{!isMobile && (
											<Patient
												isOpen={patientIsOpen}
												setIsOpen={patientSetIsOpen}
												patient={selectedOrder.payer}
												showBtns={!!selectedOrder?.id}
											/>
										)}
									</div>
								</div>
							)}
							{selectedOrder?.date && (
								<div className={modal.item}>
									{translate("app.date")}
									<span>
										{moment(selectedOrder.date).format("D MMMM YYYY")}
									</span>
								</div>
							)}
							{selectedOrder?.date && (
								<div className={modal.item}>
									{translate("app.time")}
									<span>
										{moment(selectedOrder.date).format("HH:mm")}
										{moscowTimeZone() ? "" : translate("app.mskTime")}
									</span>
								</div>
							)}
							{selectedOrder?.amountTotal !==
								selectedOrder?.amountDiscounted && (
								<div className={modal.item}>
									{translate("profile.bills.withoutDiscount")}
									<span>
										{selectedOrder.amountTotal?.toLocaleString()}{" "}
										{translate("app.currencySign")}
									</span>
								</div>
							)}
							{!selectedOrder.isPaid &&
								selectedOrder.date &&
								selectedOrder.payer.medCardNumber === profile.medCardNumber &&
								selectedOrder.invoiceNumber && (
									<div className={modal.item_row}>
										{translate("profile.bills.payLink")}
										<a
											href={selectedOrder.sharingUrl}
											target="_blank"
											rel="noopener"
										>
											{selectedOrder.sharingUrl}
										</a>
										<div
											className={modal.shareLink}
											onClick={() =>
												handleCopyShareLink(selectedOrder.sharingUrl)
											}
										>
											{translate("profile.bills.sharePaymentLink")}
										</div>
										{copied && (
											<div className={modal.linkCopied}>
												{translate("profile.bills.linkCopied")}
											</div>
										)}
									</div>
								)}
						</div>
					</div>
				)}
				{reveiveError && <Results type="fail" />}
			</SelectorModal>
			<div className="special">
				{selectedOrder && isMobile && (
					<Patient
						isOpen={patientIsOpen}
						setIsOpen={patientSetIsOpen}
						patient={selectedOrder.payer}
						className={modal.dropdown}
					/>
				)}
			</div>
		</Wrapper>
	);
}

const Wrapper = ({
	children,
	defaultDates,
	dateRange,
	setDateRange,
	isPaid,
	setIsPaid,
}) => {
	const translate = useTranslate();
	const [dateFilterOpened, setOpened] = useState(false);
	const [startDate, endDate] = dateRange;
	const isMobile = useMediaQuery("(max-width: 620px)");

	const headerRef = useRef(null);
	const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);

	const observerHandler = useCallback(
		([entrie]) => setShowScrollTopBtn(!entrie.isIntersecting),
		[setShowScrollTopBtn]
	);

	const observer = useMemo(
		() =>
			new IntersectionObserver(observerHandler, {
				rootMargin: "100px",
				threshold: 0,
			}),
		[observerHandler]
	);

	useEffect(() => {
		observer.observe(headerRef.current);
		return () => observer.disconnect();
	}, []);

	const handleScrollTop = () => scrollToPosition(0, "smooth");

	return (
		<div className={css.wrapper}>
			<div className={classNames("h3", css.h3)} ref={headerRef}>
				{translate("profile.menu.orders")}
			</div>
			<div className={css.category_title}>{translate("app.filtering")}</div>
			<div className={css.filter_container}>
				<Button
					className={css.filter_button}
					secondary={isPaid}
					small
					onClick={() => setIsPaid(false)}
				>
					{translate("profile.orders.unpaid")}
				</Button>
				<Button
					secondary={!isPaid}
					small
					onClick={() => setIsPaid(true)}
					className={css.filter_button}
				>
					{translate("profile.orders.paid")}
				</Button>
				<Button
					className={classNames(css.filter_button, css.filter_button__date)}
					small
					onClick={() => setDateRange([null, null])}
					secondary={startDate || endDate}
				>
					{translate("app.allDates")}
				</Button>
				<Button
					secondary={!startDate}
					small
					onClick={() => {
						setOpened(!dateFilterOpened);
					}}
					className={classNames(css.filter_button, css.filter_button__date)}
				>
					{startDate && endDate ? (
						<>
							{moment(startDate).format("DD.MM")}
							&nbsp;-&nbsp;
							{moment(endDate).format("DD.MM.YYYY")}
							&nbsp;
						</>
					) : (
						translate("history.list.changeDateRange", true)
					)}
					<div className={css.datepicker_expander}>
						{startDate ? (
							<svg
								width="15"
								height="8"
								viewBox="0 0 15 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M1.10547 0.443827C1.35742 0.185392 1.76591 0.185392 2.01786 0.443827L7.58317 6.15236L13.1485 0.443827C13.4004 0.185392 13.8089 0.185392 14.0609 0.443827C14.3128 0.702262 14.3128 1.12127 14.0609 1.3797L8.03937 7.55617C7.78742 7.81461 7.37892 7.81461 7.12697 7.55617L1.10547 1.3797C0.853516 1.12127 0.853516 0.702262 1.10547 0.443827Z"
									fill="white"
								/>
							</svg>
						) : (
							<svg
								width="15"
								height="8"
								viewBox="0 0 15 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M1.10547 0.443827C1.35742 0.185392 1.76591 0.185392 2.01786 0.443827L7.58317 6.15236L13.1485 0.443827C13.4004 0.185392 13.8089 0.185392 14.0609 0.443827C14.3128 0.702262 14.3128 1.12127 14.0609 1.3797L8.03937 7.55617C7.78742 7.81461 7.37892 7.81461 7.12697 7.55617L1.10547 1.3797C0.853516 1.12127 0.853516 0.702262 1.10547 0.443827Z"
									fill="#7A8CA4"
								/>
							</svg>
						)}
					</div>
				</Button>
				{!isMobile && (
					<Dropdown
						expanded={dateFilterOpened}
						closeBtn
						title={translate("history.list.period", true)}
						className={css.dropdown_datepicker}
						setExpanded={setOpened}
					>
						<DatePicker
							isHolidayActive
							maxDate={moment()}
							markedDates={defaultDates}
							setDateRange={(arg) => {
								setDateRange(arg);
								setOpened(false);
							}}
							dateRange={dateRange}
							openState={setOpened}
						/>
					</Dropdown>
				)}
			</div>

			<PatientSelector className={css.patient_selector} />

			{children}

			{isMobile && (
				<Modal
					isOpen={dateFilterOpened}
					// className={modalClassName}
					onRequestClose={() => setOpened(false)}
					// style={modalStyle}
				>
					<CloseModalButton closeModal={() => setOpened(false)} />
					<div className={header.date_picker_title}>
						{translate("history.list.period", true)}
					</div>
					<DatePicker
						isHolidayActive
						maxDate={moment()}
						markedDates={defaultDates}
						setDateRange={(arg) => {
							setDateRange(arg);
							setOpened(false);
						}}
						oneMonth
						dateRange={dateRange}
						openState={dateFilterOpened}
					/>
				</Modal>
			)}

			{showScrollTopBtn && (
				<Button
					className={classNames(css.top_btn, {
						[css.mobile]: isAndroidApp || isIOSApp,
					})}
					small
					light
					onClick={handleScrollTop}
				>
					<img src={arrow} />
				</Button>
			)}
		</div>
	);
};

const ReceivedList = ({ list, selectOrder }) => {
	const translate = useTranslate();

	return (
		<>
			{list.map((item) => (
				<div
					key={item.invoiceNumber}
					className={css.list_item}
					onClick={() => selectOrder(item)}
				>
					<span>
						№ {item.invoiceNumber}
						<span className={css.payer_by}>
							{translate("profile.orders.by")}&nbsp;{item.payer.firstName}&nbsp;
							{item.payer.lastName}
						</span>
					</span>
					<span className={css.amount}>
						<span>
							{item?.amount?.toLocaleString()}
							&nbsp;
							{translate("app.currencySign")}
						</span>
						<img src={arrow} alt="" />
					</span>
				</div>
			))}
		</>
	);
};

const List = ({ list, keyName, selectOrder }) => {
	const translate = useTranslate();

	return Object.keys(list).map((date) => (
		<div key={`dates-${keyName}-${date}`} className={css.category}>
			<div className={css.category_title}>
				{moment(date).format("D MMMM YYYY, dddd")}
			</div>
			<div className={css.list}>
				{list[date].map((invoice) => (
					<div
						key={`invoice-${invoice.id}`}
						className={css.list_item}
						onClick={() => selectOrder(invoice)}
					>
						<span>№ {invoice.id}</span>
						<span className={css.amount}>
							<span>
								{invoice?.amountDiscounted?.toLocaleString()}
								&nbsp;
								{translate("app.currencySign")}
							</span>
							<img src={arrow} alt="" />
						</span>
					</div>
				))}
			</div>
		</div>
	));
};
