import style from "./about.module.css";
import classNames from "classnames";
import { useTranslate } from "../../../../localization/translate";
import { useState } from "react";
import Collapser from "../../../../components/collapser";

export default function About({ description, services }) {
	const translate = useTranslate();

	return (
		<div className={style.about}>
			{description && description.length > 0 && (
				<div className={style.block}>
					<div className={classNames(style.title, "h3")}>
						{translate("appointment.doctorModal.merits")}
					</div>

					<Collapser initHeight={140} softPruning>
						<div
							className={classNames(style.text, "text")}
							dangerouslySetInnerHTML={{ __html: description }}
						/>
					</Collapser>
				</div>
			)}
			{services && services.length > 0 && (
				<div className={style.block}>
					<div className={classNames(style.title, "h3")}>
						{translate("appointment.doctorModal.services")}
					</div>
					{services.map((service) => (
						<div
							className={classNames(style.services, style.str)}
							key={"service-key-" + service.code}
						>
							<span>{service.name}</span>
							<div className={style.advanced}>
								<span>
									{service.price} {translate("app.currencySign")}
								</span>
								{/*<span>*/}
								{/*    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
								{/*        <path fillRule="evenodd" clipRule="evenodd" d="M7.23259 4.22676C6.92247 4.5291 6.92247 5.01929 7.23259 5.32163L14.0828 12L7.23259 18.6784C6.92247 18.9807 6.92247 19.4709 7.23259 19.7732C7.54271 20.0756 8.04552 20.0756 8.35564 19.7732L15.7674 12.5474C16.0775 12.2451 16.0775 11.7549 15.7674 11.4526L8.35564 4.22676C8.04552 3.92441 7.54271 3.92441 7.23259 4.22676Z" fill="#7A8CA4"/>*/}
								{/*    </svg>*/}
								{/*</span>*/}
							</div>
						</div>
					))}
				</div>
			)}
			<div className={style.block} hidden>
				<div className={classNames(style.title, "h3")}>
					{translate("appointment.doctorModal.myAppointments")}
				</div>
				<div className={style.my_appointments}>
					<div>
						<div className={classNames(style.name, "text")}>
							Вторичный прием
						</div>
						<div className={style.date}>28.02</div>
					</div>
					<div>
						<div className={classNames(style.name, "text")}>
							Первичный прием
						</div>
						<div className={style.date}>28.02</div>
					</div>
				</div>
			</div>
		</div>
	);
}
