import { useState } from "react";
import s from "./index.module.css";
import { ReactComponent as CrossIcon } from "../../icons/cross.svg";

const SlowInternetConnectionBannerWrapper = ({ children }) => {
	const [showBanner, setShowBanner] = useState(false);

	window.showSlowInternetBanner = (connection = "") => {
		if (
			connection.toUpperCase() === "3G" ||
			connection.toUpperCase() === "2G"
		) {
			setShowBanner(true);
		} else setShowBanner(false);
	};

	const close = () => setShowBanner(false);

	return (
		<>
			{children}
			{showBanner && (
				<div className={s.banner}>
					<button className={s.close} onClick={close}>
						<CrossIcon className={s.icon} />
					</button>
					<p className={s.title}>Медленный интернет</p>
					<p className={s.text}>
						На загрузку потребуется время. Проверьте подключение и попробуйте
						обновить страницу.
					</p>
				</div>
			)}
		</>
	);
};

export default SlowInternetConnectionBannerWrapper;
