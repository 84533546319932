import {
	useGetDoctorScheduleMutation,
	useGetResearchQuery,
	useGetResearchScheduleMutation,
} from "../../services/appointment.api";
import s from "./schedule.module.css";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import cn from "classnames";
import Calendar from "../../../../components/calendar/calendar";
import {
	selectAppointmentExaminationResult,
	selectDateRange,
	setAppointmentExaminationResult,
	setExaminationInfo,
} from "../../services/appointment.slice";
import { useDispatch, useSelector } from "react-redux";
import { Metrics } from "../../../../utils/metrics";
import { selectAccessToken } from "../../../login/services/login.slice";
import {getSiteSourceName, tokenPayload} from "../../../../utils";
import Button from "../../../../components/button/button";
import { useTranslate } from "../../../../localization/translate";
import { moscowTimeZone } from "../../../../utils/dates/timezone-converter";
import DoctorModalServices from "./services";
import ProfileNotice from "../../../profile/modules/personala-data/notice";

export default function DoctorSchedule(props) {
	const {
		doctorInfo,
		acceptAdults,
		patient,
		shortname,
		service,
		setService,
		speciality,
		activeDate,
		setActiveDate,
		isWidget,
	} = props;
	const t = useTranslate();
	const dispatch = useDispatch();
	const dateRange = useSelector(selectDateRange);
	const { data: research = [] } = useGetResearchQuery();

	const [
		getDoctorSchedule,
		{ isLoading: isDoctorScheduleLoading, error: doctorScheduleError },
	] = useGetDoctorScheduleMutation();

	const [
		getResearchSchedule,
		{ isLoading: isResearchScheduleLoading, error: researchScheduleError },
	] = useGetResearchScheduleMutation();

	const [schedule, setSchedule] = useState(null);
	const [timeSlot, setTimeSlot] = useState({});

	const token = useSelector(selectAccessToken);
	const appointmentExaminationResult = useSelector(
		selectAppointmentExaminationResult
	);

	const isAuth = useMemo(() => {
		if (!!token) {
			const { guest } = tokenPayload(token);
			return !guest;
		} else return false;
	}, [token]);

	const getSchedule = async () => {
		setSchedule(null);
		try {
			if (!!speciality.examination) {
				if (!!service) {
					const { data } = await getResearchSchedule({
						doctorId: doctorInfo.id,
						researchId: speciality.examination,
						serviceId: service.id,
						dateFrom: dateRange.from,
						dateTo: dateRange.to,
					});
					setSchedule(data);
				}
			} else {
				const { data } = await getDoctorSchedule({
					doctorId: doctorInfo.id,
					specialityId: doctorInfo.specialityId,
					dateFrom: dateRange.from,
					dateTo: dateRange.to,
				});
				setSchedule(data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (
			doctorInfo &&
			!(isDoctorScheduleLoading || isResearchScheduleLoading) &&
			!(doctorScheduleError || researchScheduleError) &&
			!!dateRange.from &&
			!!dateRange.to &&
			moment(dateRange.to).isAfter(dateRange.from, "d")
		) {
			getSchedule();
		}

		if (appointmentExaminationResult) {
			setTimeSlot({});
			dispatch(setAppointmentExaminationResult(null));
		}
	}, [doctorInfo, appointmentExaminationResult, service, dateRange]);

	const onClick = (selectedTimeslot) => {
		Metrics.gtmEvent("begin_checkout", {
			items: [
				{
					item_id: doctorInfo.id,
					item_name: shortname,
					price: props.minPrice,
					quantity: 1,
				},
			],
			site_source_domain: getSiteSourceName(),
			site_source_path: window.location.pathname
		});

		const obj = {
			date: `${moment(activeDate).format("yyyy-MM-DD")} ${moment(
				selectedTimeslot.time
			).format("HH:mm")}:00`,
			doctor: {
				id: props.id,
				specialities: props.specialities,
				shortname: props.shortname,
				minPrice: props.minPrice,
				photoUrl: props.photoUrl,
				surname: props.surname,
				name: props.name,
				middleName: props.middleName,
				rating: props.rating,
				experienceInYearsAll: props.experienceInYearsAll,
			},
			slotIdMis: !!service
				? selectedTimeslot.slotDoctors?.find((d) => d.doctorId === props.id)
						.idMis || selectedTimeslot.idMis
				: selectedTimeslot.idMis,
			cabinet: selectedTimeslot.cabinet,
			specialityId: doctorInfo.specialityId,
			featureIdMis: null,
			acceptAdultsShowWarning: acceptAdults,
			serviceId: service?.id,
			serviceName: service?.name,
		};

		const sourceTimeSlot =
			selectedTimeslot.slotDoctors?.find((d) => d.doctorId === props.id) ||
			selectedTimeslot;

		if (!!sourceTimeSlot.additionalSlots?.length) {
			const allSlots = (schedule?.timeSlots || [])
				.filter((sl) => sl.isAvailable)
				.map((slot) =>
					slot.slotDoctors.map((sl) => ({ time: slot.time, ...sl }))
				)
				.flat();

			obj.additionalSlots = allSlots.filter((s) =>
				sourceTimeSlot.additionalSlots.includes(s.idMis)
			);
		}

		if (speciality && speciality.appointmentFeature) {
			obj.receptionTypes = speciality.appointmentFeature;
		}
		if (doctorInfo.rescheduleInfo) {
			obj.rescheduleInfo = doctorInfo.rescheduleInfo;
		}
		if (doctorInfo.serviceId) {
			obj.serviceId = doctorInfo.serviceId;
		}
		if (doctorInfo.serviceName) {
			obj.serviceName = doctorInfo.serviceName;
		}
		if (patient) {
			obj.patient = patient;
		}

		dispatch(setExaminationInfo(obj));
	};

	const calendarProps = {
		speciality: props?.speciality,
		timeSlots: schedule?.timeSlots || [],
		className: s.calendar,
		onClick,
		activeDate,
		setDate: setActiveDate,

		timeSlot,
		setTimeSlot,
	};

	const backToServices = () => setService(null);

	const selectedTimeSlot =
		!!schedule?.timeSlots?.length &&
		schedule?.timeSlots.find((ts) => ts.time === timeSlot?.time);

	useEffect(() => {
		setTimeSlot({});
	}, [speciality, service]);

	const allSelectedSlots = selectedTimeSlot
		? !!selectedTimeSlot.slotDoctors
			? [
					selectedTimeSlot,
					...(schedule?.timeSlots || []).filter(
						(ts) =>
							!!selectedTimeSlot?.slotDoctors[0]?.additionalSlots.includes(
								ts.slotDoctors[0]?.idMis
							)
					),
			  ]
			: [selectedTimeSlot]
		: [];

	return (
		<div className={s.schedule}>
			<div className={s.title}>
				<h3>{t("appointment.doctorModal.makeAppointmentTitle")}</h3>
				<div className={s.specialities}>
					<span>{t("appointment.doctorModal.speciality")}</span>
					{props.specialities.map((spec) => (
						<Button
							key={spec.id}
							small
							className={cn(s.speciality, {
								[s.active]: spec.id === speciality?.id,
							})}
							onClick={() => props.updateSpeciality(spec)}
						>
							{spec.name}
						</Button>
					))}
				</div>
			</div>
			{!!speciality.examination ? (
				!!service ? (
					<>
						<div className={s.breadcrumbs}>
							<p className={s.back} onClick={backToServices}>
								Услуги
							</p>
							<span className={s.divider}>/</span>
							<p className={s.active}>{service.name}</p>
						</div>
						{schedule?.noticesS?.map(({ id, description }) => (
							<div key={id} className={s.noticeContainer}>
								<ProfileNotice
									isGrey
									important={t(
										"history.assignment.endoscopyNotice.important",
										true
									)}
									title={
										<div dangerouslySetInnerHTML={{ __html: description }} />
									}
									titleClassName={s.notice_message_text}
								/>
							</div>
						))}
						<Calendar
							{...calendarProps}
							doctorId={doctorInfo.id}
							serviceId={doctorInfo.serviceId}
							showSubscribeNotice={false}
							isPublic={!isAuth}
							showAppointmentContactDoctor
							isLoading={isResearchScheduleLoading}
						/>
					</>
				) : (
					<DoctorModalServices
						services={research
							.find((r) => r.id === speciality.examination)
							?.groups.map((g) => g.service)
							.flat()}
						onClick={(service) => setService(service)}
					/>
				)
			) : (
				<Calendar
					{...calendarProps}
					doctorId={doctorInfo.id}
					showSubscribeNotice={false}
					isPublic={!isAuth}
					showAppointmentContactDoctor
					isLoading={isDoctorScheduleLoading}
				/>
			)}

			<Button
				disabled={!selectedTimeSlot?.time}
				className={cn(s.make_appointment, { [s.widget]: isWidget })}
				onClick={() => onClick(timeSlot)}
			>
				{t(`appointment.doctorModal.makeAppointment`, true)}
				&nbsp;
				{selectedTimeSlot?.time && t("app.at")}
				{selectedTimeSlot?.time && <>&nbsp;</>}
				{!!selectedTimeSlot && !!selectedTimeSlot.time && (
					<>
						{moment(
							allSelectedSlots.sort((a, b) => a.time.localeCompare(b.time))[0]
								.time
						).format("HH:mm")}
						&nbsp;
						{moscowTimeZone() ? "" : t("app.mskTime")},&nbsp;
					</>
				)}
				{selectedTimeSlot?.time && moment(activeDate).format("D MMMM")}
			</Button>
		</div>
	);
}
