import { getPlatform, tokenPayload } from "..";
import { loadBaseUrl } from "../../app/config.api";

export const Sex = {
	0: "Male",
	1: "Female",
	2: "Other",
};

export class Metrics {
	static platform = getPlatform();
	static config = { sex: null, dateOfBirth: null };
	static appId = 90529422;

	static sendMetric(type, value) {
		if (Metrics.isReady) {
			window.ym(Metrics.appId, type, value);
		} else {
			// console.warn("some problems with load or init YM script!");
		}
	}

	static hit() {
		Metrics.sendMetric("hit", window.location.href);
	}

	static get isReady() {
		return window.ym && typeof window.ym === "function";
	}

	static gtmPageView(config) {
		window.dataLayer.push({
			event: "pageview",
			...(config &&
				Object.keys(config).length && {
					page: { ...config },
				}),
		});
	}

	static gtmEvent(name, props) {
		const { platform, config } = Metrics;
		const palyoad = tokenPayload(localStorage.getItem("accessToken"));
		const userId = !!palyoad?.guest ? null : palyoad?.user_id;
		if (!name) return;

		window.gtag("event", name, {
			platform,
			...(userId && { userId }),
			...(Sex[config.sex] && { gender: Sex[config.sex] }),
			...(config.dateOfBirth && { birthdate: config.dateOfBirth }),
			...(props && Object.keys(props).length && { ...props }),
		})
	}
}

export const loginGtmEvent = async (token, auth_way) => {
	const headers = new Headers();
	const payload = !!token && tokenPayload(token);

	if (token) headers.append("X-Access-Token", `Bearer ${token}`);

	const options = {
		method: "GET",
		headers,
	};

	const res = await fetch(`${loadBaseUrl()}/api/profile`, options);
	const data = await res.json();

	if (res.ok) {
		const { dateOfBirth, sex } = data.data;
		Metrics.config = { dateOfBirth, sex };

		Metrics.gtmEvent("login", { userId: payload?.user_id });
		Metrics.gtmEvent("auth", {
			userId: payload?.user_id,
			auth_way,
		});

		return;
	}

	Metrics.gtmEvent("login", { userId: payload?.user_id });
	Metrics.gtmEvent("auth", {
		userId: payload?.user_id,
		auth_way,
	});

	return;
};
