import ModalInfo from "../../../../components/custom-modal/info";
import css from "./share-access.module.css";
import classNames from "classnames";
import Button from "../../../../components/button/button";
import LinkModal from "./link-modal";
import LinkCard from "./link-card";
import Confirm from "./confirm";
import { useEffect, useState } from "react";
import { useTranslate } from "../../../../localization/translate";
import { getErrorMessageFromError } from "../../../../app/error-handler.api";
import {
	useConfirmLinkCardMutation,
	useLinkCardMutation,
} from "../../services/profile.api";
import { useMediaQuery } from "beautiful-react-hooks";
import { useDispatch, useSelector } from "react-redux";
import {
	attachCardIsOpen,
	linkButton,
	savedCard,
	selectPatientSelectorState,
	setAttachCardState,
} from "../../services/profile.slice";
import { Metrics } from "../../../../utils/metrics";

export default function AttachCard({ isWidget }) {
	const [alreadyAdded, setAlreadyAdded] = useState();
	const translate = useTranslate();
	const dispatch = useDispatch();
	const onRequestClose = () => {
		setConfirmErrMsg(false);
		setLimitIsOver(null);
		dispatch(setAttachCardState({ state: false }));
		resetLinkCard();
		resetConfirmedResult();
		setCode(null);
		setNewCardNum("");
		setAlreadyAdded(null);
		setNotFoundFieldError(null);
	};
	const [
		request,
		{
			data: requestLinkCard,
			isLoading: isRequesting,
			error: requestError,
			reset: resetLinkCard,
		},
	] = useLinkCardMutation();
	const [limitIsOver, setLimitIsOver] = useState(null);
	const [confirmErrMsg, setConfirmErrMsg] = useState(false);
	const isMobile = useMediaQuery("(max-width: 620px)");
	const [
		confirm,
		{
			data: confirmedData,
			isLoading: isConfirming,
			error: confirmApiError,
			reset: resetConfirmedResult,
		},
	] = useConfirmLinkCardMutation();
	const [code, setCode] = useState();
	const [notFoundFieldError, setNotFoundFieldError] = useState(null);
	const isOpen = useSelector(attachCardIsOpen);
	const savedToAttach = useSelector(savedCard);
	const linkButtonFlag = useSelector(linkButton);
	const hideConfirmSuccess =
		useSelector(selectPatientSelectorState) && linkButtonFlag;
	const [newCardNum, setNewCardNum] = useState(
		savedToAttach ? savedToAttach : ""
	);

	useEffect(() => {
		if (savedToAttach) {
			setNewCardNum(savedToAttach);
		}
	}, [savedToAttach]);

	useEffect(() => {
		if (hideConfirmSuccess && confirmedData && requestLinkCard) {
			onRequestClose();
		}
	}, [confirmedData]);

	useEffect(() => {
		if (confirmedData) {
			Metrics.gtmEvent("add_patient_card", { medCardNumber: newCardNum });
		}
	}, [confirmedData]);

	useEffect(() => {
		if (code && code.length === 5 && requestLinkCard && requestLinkCard.s) {
			confirm({ code, s: requestLinkCard.s });
		}
	}, [code]);

	useEffect(() => {
		if (confirmApiError) {
			const wrongCode = getErrorMessageFromError({
				error: confirmApiError,
				code: 2012,
				fromClarifications: true,
			});
			wrongCode && setConfirmErrMsg(wrongCode[0].message);
		}
	}, [confirmApiError]);

	useEffect(() => {
		if (requestError) {
			const notFound = getErrorMessageFromError({
				fromClarifications: true,
				error: requestError,
				code: 2021,
			});
			notFound &&
				notFound.length > 0 &&
				setNotFoundFieldError(notFound[0].message);

			const limitIsOver = getErrorMessageFromError({
				error: requestError,
				code: 2008,
			});
			limitIsOver && setLimitIsOver(true);

			const alreadyLinked = getErrorMessageFromError({
				code: 2037,
				fromClarifications: true,
				error: requestError,
			});
			alreadyLinked && setAlreadyAdded(alreadyLinked[0].message);
		}
	}, [requestError]);

	return (
		<>
			{alreadyAdded ? (
				<ModalInfo
					iconType={"failure"}
					isOpen={isOpen}
					modalStyle={{
						overlay: {
							display: "flex",
							alignItems: isWidget ? "flex-start" : "center",
							zIndex: 140,
						},
						content: isMobile
							? {}
							: {
									width: "455px !important",
									maxWidth: 455,
									minWidth: 455,
									minHeight: 600,
									borderRadius: 16,
							  },
					}}
					modalClassName={css.modal_error}
					subtitle={translate("app.attention", true) + "!"}
					bodyClass={classNames(css.content, css.failure)}
					onRequestClose={onRequestClose}
					textClass={css.modal_error_text}
					text={alreadyAdded}
				>
					<div className={css.modal_info_buttons}>
						<Button
							onClick={() => {
								setCode(null);
								resetLinkCard();
								setAlreadyAdded(null);
								setNewCardNum("");
								resetConfirmedResult();
							}}
						>
							{translate("profile.shareAccess.typeAnother")}
						</Button>
						<Button light onClick={onRequestClose}>
							{hideConfirmSuccess
								? translate("app.goBack", true)[0].toUpperCase() +
								  translate("app.goBack", true).slice(
										(translate("app.goBack", true).length - 1) * -1
								  )
								: translate("profile.shareAccess.backToProfile")}
						</Button>
					</div>
				</ModalInfo>
			) : limitIsOver ? (
				<ModalInfo
					iconType={"error"}
					isOpen={isOpen}
					modalStyle={{
						overlay: {
							display: "flex",
							alignItems: isWidget ? "flex-start" : "center",
							zIndex: 140,
						},
						content: isMobile
							? {}
							: {
									width: "455px",
									maxWidth: 455,
									minHeight: 600,
									borderRadius: 16,
							  },
					}}
					modalClassName={css.modal_error}
					subtitle={translate("profile.shareAccess.limitIsOverTitle")}
					bodyClass={classNames(css.content, css.failure)}
					onRequestClose={onRequestClose}
					textClass={css.modal_error_text}
					text={translate("profile.shareAccess.limitIsOverText")}
				/>
			) : (
				<LinkModal
					isOpen={confirmedData ? false : isOpen}
					onRequestClose={onRequestClose}
					isWidget={isWidget}
				>
					{!requestLinkCard && (
						<LinkCard
							newCardNum={newCardNum}
							setNewCardNum={(val) => {
								setNewCardNum(val);
								setNotFoundFieldError(null);
							}}
							isLoading={isRequesting}
							request={(data) => {
								setNotFoundFieldError(null);
								request(data);
							}}
							noticeText={
								notFoundFieldError
									? translate("profile.shareAccess.whyNotFound")
									: null
							}
							cardFieldError={notFoundFieldError}
						/>
					)}
					{requestLinkCard && !confirmedData && (
						<Confirm
							request={() => request(newCardNum)}
							setCode={setCode}
							code={code}
							medCardNumber={newCardNum}
							isConfirming={isConfirming}
							confirmErrMsg={confirmErrMsg}
							setConfirmErrMsg={setConfirmErrMsg}
						/>
					)}
				</LinkModal>
			)}
			{requestLinkCard && confirmedData && !hideConfirmSuccess && (
				<ModalInfo
					iconType={"success"}
					isOpen={isOpen}
					modalStyle={{
						overlay: {
							display: "flex",
							alignItems: isWidget ? "flex-start" : "center",
							zIndex: 140,
						},
						content: isMobile
							? {}
							: {
									width: "455px !important",
									maxWidth: 455,
									minWidth: 455,
									minHeight: 600,
									borderRadius: 16,
							  },
					}}
					modalClassName={css.modal_error}
					subtitle={translate("profile.shareAccess.successTitle")}
					bodyClass={classNames(css.content, css.failure)}
					onRequestClose={onRequestClose}
					textClass={css.modal_error_text}
					text={translate("profile.shareAccess.successText")}
				>
					<div className={css.modal_info_buttons}>
						<Button
							onClick={() => {
								setCode(null);
								resetLinkCard();
								setNewCardNum("");
								resetConfirmedResult();
							}}
						>
							{translate("profile.shareAccess.addAnother")}
						</Button>
						<Button light onClick={onRequestClose}>
							{translate("profile.shareAccess.backToProfile")}
						</Button>
					</div>
				</ModalInfo>
			)}
		</>
	);
}
