import { useEffect, useMemo, useState } from "react";
import { useGetNotificationsQuery } from "../services/notifications.api";

const PAGE_INIT = 1;
const ITEMS_PER_PAGE = 20;

export const matchItems = (prev, newItems) => [
	...prev,
	...newItems.filter((item) => !prev.find(({ id }) => id === item.id)),
];

export default function usePagination(query = "", per_page = ITEMS_PER_PAGE) {
	const [page, setPage] = useState(PAGE_INIT);
	const [collection, setCollection] = useState([]);

	const { data, isLoading, isFetching } = useGetNotificationsQuery({
		page,
		per_page,
		query,
	});

	const hasMore = useMemo(() => page < data?.pageCount, [data, page]);

	const isEmpty = useMemo(
		() => !query.length && !data?.notifications?.length,
		[data]
	);

	useEffect(() => {
		if (query.length > 0) setCollection(data ? data.notifications : []);
		else
			setCollection((prev) => matchItems(prev, data ? data.notifications : []));
	}, [data]);

	useEffect(() => {
		if (query.length > 0) setPage(PAGE_INIT);
	}, [query]);

	const handlePaginate = () => hasMore && setPage((prev) => prev + 1);

	const markReaded = (ids) =>
		setCollection(
			collection.map((item) => ({
				...item,
				isRead: ids.includes(item.id) || item.isRead,
			}))
		);

	const deleteNotifications = (ids) =>
		setCollection((prev) => prev.filter((item) => !ids.includes(item.id)));

	return {
		collection,
		hasMore,
		isEmpty,
		isLoading,
		isFetching,
		handlePaginate,
		markReaded,
		deleteNotifications,
	};
}
