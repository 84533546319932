import styles from "./personal-data.module.css";
import Avatar from "./avatar";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import { setCreateModalState } from "../../services/profile.slice";
import ProfileNotice from "./notice";
import { useTranslate } from "../../../../localization/translate";
import { useDispatch } from "react-redux";

export default function ProfileCard({
	firstName,
	lastName,
	phone,
	medCardNumber,
	photo,
	email,
	contractTerm,
	dateOfBirth,
	editEmail,
	isLoading,
	children,
	className,
	hideContractTerm,
}) {
	const getName = () => {
		if (
			!firstName ||
			!lastName ||
			(firstName.length === 0 && lastName.length === 0)
		) {
			return null;
		}

		return (
			<div className={classNames(styles.name, "h3")}>
				{firstName} {lastName}
			</div>
		);
	};
	const dispatch = useDispatch();
	const translate = useTranslate();
	const formatDate = (date) => (date ? date : "").split("-");

	return (
		<div className={classNames(styles.body, className)}>
			<Avatar
				photo={photo}
				profileIsLoading={isLoading}
				editable={!!editEmail}
				firstName={firstName}
				lastName={lastName}
			/>

			<div className={styles.info}>
				{getName()}
				<div className={styles.table}>
					{medCardNumber && medCardNumber > 0 && (
						<div className={styles.row}>
							<span className={styles.key}>
								{translate("profile.personalData.medCardNumber", true)}
							</span>
							<span className={styles.value}>{medCardNumber}</span>
						</div>
					)}
					{dateOfBirth && dateOfBirth.length !== 0 && (
						<div className={styles.row}>
							<span className={styles.key}>
								{translate("profile.personalData.dateOfBirth", true)}
							</span>
							<span className={styles.value}>
								{formatDate(dateOfBirth).reverse().join(".")}
							</span>
						</div>
					)}
					{phone && phone.length !== 0 && (
						<div className={styles.row}>
							<span className={styles.key}>
								{translate("profile.personalData.phone", true)}
							</span>
							<NumberFormat
								value={phone}
								displayType={"text"}
								renderText={(value, props) => (
									<span {...props} className={styles.value}>
										{value}
									</span>
								)}
								format="+# (###) ###-##-##"
							/>
						</div>
					)}
					{contractTerm && contractTerm.length !== 0 && !hideContractTerm && (
						<div className={styles.row}>
							<span className={styles.key}>
								{translate("profile.personalData.contractExpiration", true)}
							</span>
							<span className={styles.value}>
								{formatDate(contractTerm).reverse().join(".")}
							</span>
						</div>
					)}
					<div className={styles.row}>
						<span className={styles.key}>
							{translate("profile.personalData.email", true)}
						</span>
						<span className={classNames(styles.value, styles.email)}>
							<div>
								{email && email.length > 0
									? email
									: translate("profile.personalData.notSpecify", true)}
							</div>
							{editEmail && (
								<div className={styles.edit_btn} onClick={editEmail}>
									{translate(
										`app.forms.${email ? "changeBtn" : "addBtn"}`,
										true
									)}
								</div>
							)}
						</span>
					</div>
				</div>
				{(!medCardNumber || medCardNumber === 0) && (
					<ProfileNotice
						title={translate("profile.personalData.hasNoMedCard", true)}
						message={translate(
							"profile.personalData.hasNoMedCardDescription",
							true
						)}
						btn={{
							onClick: () => dispatch(setCreateModalState(true)),
							text: translate("profile.personalData.specify", true),
						}}
					/>
				)}
				{children}
			</div>
		</div>
	);
}
