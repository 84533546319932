import Modal from "../../../../components/modal-safari-wrapper/modal";
import CloseModalButton from "../../../../components/modal-items/close";
import { useEffect, useMemo } from "react";
import { useGetDoctorByIdQuery } from "../../services/appointment.api";
import { useMediaQuery } from "beautiful-react-hooks";
import { Metrics } from "../../../../utils/metrics";
import DoctorModalBody from "./doctor-modal-body";
import { useDispatch } from "react-redux";
import { setDate } from "../../services/appointment.slice";
import {getSiteSourceName} from "../../../../utils";

export const getValue = (value, localeString = false) => {
	if (value === null) {
		return " - ";
	}

	if (value === 0) {
		// return '0';
		return " - ";
	}

	if (value && localeString) {
		return value.toLocaleString();
	}

	return value;
};

export default function DoctorModal({
	doctorInfo,
	setSelectedDoctor,
	isWidget,
}) {
	const dispatch = useDispatch();
	const isDesktop = useMediaQuery("(min-width: 1030px)");
	const isEnoughHeight = useMediaQuery("(min-height: 800px)");
	const modalExtendedStyles = isEnoughHeight
		? { display: "flex", alignItems: "flex-start" }
		: {};

	const modalStyle = {
		overlay: {
			zIndex: 130,
			...modalExtendedStyles,
		},
		content: {
			display: "flex",
			maxWidth: 940,
		},
	};
	if (isDesktop) {
		modalStyle.content.minWidth = 940;
	}

	const fullDoctorInfo = useMemo(
		() => (doctorInfo ? { ...doctorInfo } : null),
		[doctorInfo]
	);

	const { data: doctor, error } = useGetDoctorByIdQuery(
		{ doctorId: fullDoctorInfo ? fullDoctorInfo.id : null },
		{ skip: !fullDoctorInfo }
	);

	const onRequestClose = () => {
		setSelectedDoctor(null);
		dispatch(setDate(null));
	};

	useEffect(() => {
		if (doctor) {
			Metrics.gtmEvent("view_item", {
				items: [
					{
						item_id: doctor.id,
						item_name: doctor.shortname,
						price: doctor.minPrice
					}

				],
				site_source_domain: getSiteSourceName(),
				site_source_path: window.location.pathname
			});
		}
	}, [doctor]);

	useEffect(() => {
		if (error) {
			onRequestClose();
		}
	}, [error]);

	if (!doctor || doctor?.id !== doctorInfo?.id) return null;

	return (
		<Modal
			isOpen={!!fullDoctorInfo}
			onRequestClose={onRequestClose}
			style={modalStyle}
		>
			<CloseModalButton closeModal={onRequestClose} />
			<DoctorModalBody
				doctorInfo={doctorInfo}
				setSelectedDoctor={setSelectedDoctor}
				isWidget={isWidget}
			/>
		</Modal>
	);
}
