import TextInput from "./text-input/TextInput";
import Checkbox from "./checkbox/Checkbox";
import InputWithMask from "./phonemask/InputWithMask";
import "./inputStyle.css";
import DateMaskInput from "./datemask/date-input";

export default function Input(props) {
	if (props.type === "phone-mask") {
		try {
			delete props.type;
		} catch (e) {}

		return <InputWithMask {...props} />;
	}
	if (props.type === "date") {
		try {
			delete props.type;
		} catch (e) {}
		return <DateMaskInput props={props} />;
	}

	if (["text", "password", "number"].includes(props.type) || props.isPassword) {
		try {
			delete props.type;
		} catch (e) {}

		const copy = Object.assign({}, props);

		if (props.isPassword) {
			copy.type = "password";
		}

		return <TextInput props={copy} />;
	}

	if (props.type === "checkbox") {
		try {
			delete props.type;
		} catch (e) {}

		return <Checkbox {...props} />;
	}

	return "";
}
