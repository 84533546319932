import { useDispatch, useSelector } from "react-redux";
import {
	selectInvalidMagicLinkFlag,
	setInvalidMagicLink,
	setRequestLinkIsOpen,
} from "../../services/login.slice";
import ModalInfo from "../../../../components/custom-modal/info";
import { useTranslate } from "../../../../localization/translate";
import Button from "../../../../components/button/button";
import { useEffect, useState } from "react";
import styles from "./Magiclink.module.css";

const modalStyle = {
	content: {
		width: "455px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "40px 56px 80px",
	},
	overlay: {
		display: "flex",
		alignItems: "center",
	},
};

export default function InvalidMagicLink() {
	const invalidMagicLinkFlag = useSelector(selectInvalidMagicLinkFlag);
	const translate = useTranslate();
	const dispatch = useDispatch();
	const closeModal = () => {
		dispatch(setInvalidMagicLink(false));
		setIsOpen(false);
	};
	const [isOpen, setIsOpen] = useState(true);

	useEffect(() => {
		return () => dispatch(setInvalidMagicLink(false));
	});

	if (invalidMagicLinkFlag) {
		return (
			<ModalInfo
				isOpen={isOpen}
				iconType={false}
				bodyClass={styles.modal}
				onRequestClose={closeModal}
				subtitle={translate("login.magicLink.invalid.title")}
				modalStyle={modalStyle}
				textClass={styles.margin_top}
				text={translate("login.magicLink.invalid.text")}
			>
				<Button
					light
					className={styles.margin_top}
					onClick={() => {
						closeModal();
						dispatch(setRequestLinkIsOpen(true));
					}}
				>
					{translate("login.magicLink.invalid.repeat")}
				</Button>
			</ModalInfo>
		);
	}

	return <></>;
}
