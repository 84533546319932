import Notify from "../../../components/notify/notify";

const wrapper = (children) => <div style={{ margin: 20 }}>{children}</div>;

export default () => (
	<>
		{wrapper(
			<Notify
				isNew
				date="20 марта, 14:16"
				type="События"
				text="Перед поездкой к врачу не забудьте оформить цифровой пропуск"
			/>
		)}
		{wrapper(
			<Notify
				isActive
				isNew
				date="20 марта, 14:16"
				type="События"
				text="Перед поездкой к врачу не забудьте оформить цифровой пропуск"
			/>
		)}
	</>
);
