import { useState } from "react";
import moment from "moment";
import { useTranslate } from "../../../../../../localization/translate";
import Button from "../../../../../../components/button/button";
import FilesDropdown from "../files-dropdown";
import { downloadFileByUrl, downloadByBlob } from "../../../../../../utils";
import styles from "./style.module.css";

export const Icon = () => (
	<svg
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 4.5C12.3787 4.5 12.6857 4.807 12.6857 5.18571V14.5017L15.1723 12.0151C15.4401 11.7473 15.8742 11.7473 16.142 12.0151C16.4098 12.2829 16.4098 12.7171 16.142 12.9849L12.4849 16.642C12.2171 16.9098 11.7829 16.9098 11.5151 16.642L7.85798 12.9849C7.5902 12.7171 7.5902 12.2829 7.85798 12.0151C8.12577 11.7473 8.55994 11.7473 8.82773 12.0151L11.3143 14.5017V5.18571C11.3143 4.807 11.6213 4.5 12 4.5ZM4.68571 15.4714C5.06442 15.4714 5.37143 15.7784 5.37143 16.1571V17.0714C5.37143 17.617 5.58816 18.1403 5.97395 18.526C6.35974 18.9118 6.88298 19.1286 7.42857 19.1286H16.5714C17.117 19.1286 17.6403 18.9118 18.026 18.526C18.4118 18.1403 18.6286 17.617 18.6286 17.0714V16.1571C18.6286 15.7784 18.9356 15.4714 19.3143 15.4714C19.693 15.4714 20 15.7784 20 16.1571V17.0714C20 17.9807 19.6388 18.8528 18.9958 19.4958C18.3528 20.1388 17.4807 20.5 16.5714 20.5H7.42857C6.51926 20.5 5.64719 20.1388 5.00421 19.4958C4.36122 18.8528 4 17.9807 4 17.0714V16.1571C4 15.7784 4.307 15.4714 4.68571 15.4714Z"
			fill="#7A8CA4"
		/>
	</svg>
);

export default function ContractItem({ title, dateTill, files }) {
	const [expanded, setExpanded] = useState(false);
	const translate = useTranslate();

	const handleClickDownloadBtn = () => {
		if (files.length > 1) {
			setExpanded(true);
		} else {
			handleDownloadFile(files[0]);
		}
	};

	const handleDownloadFile = (item) =>
		downloadFileByUrl(item.url)
			.then((res) => res.blob())
			.then((blob) => downloadByBlob(blob, item.name));

	return (
		<div className={styles.contract_item}>
			<div className={styles.content}>
				<div className={styles.title}>{title}</div>
				<div className={styles.description}>
					{translate("profile.personalData.contracts.dateTill")}
					&nbsp;
					{moment(dateTill).format("DD.MM.YYYY")}
				</div>
			</div>

			{files.length > 0 && (
				<div className={styles.action}>
					<Button
						className={styles.download_btn}
						onClick={handleClickDownloadBtn}
					>
						<Icon />
					</Button>
				</div>
			)}

			{files.length > 1 && (
				<FilesDropdown
					expanded={expanded}
					setExpanded={setExpanded}
					files={files}
					onDownload={handleDownloadFile}
				/>
			)}
		</div>
	);
}
