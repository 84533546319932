export { default as DepositPayment } from "./deposit-payment";
export { default as ServicePayment } from "./service-payment";
export { default as PaymentMethods } from "./payment-methods";
export { default as PaymentAccounts } from "./payment-accounts";
export { default as UrlPayment } from "./url-payment";
export { default as Discounts } from "./discounts";
export { default as ResultWrapper } from "./result-wrapper";

export const EmptyScreen = ({ type, purposeId }) => (
	<div>
		No screen with that name!
		<br />
		Props: {JSON.stringify({ type, purposeId })}
	</div>
);
