import css from "./subject-opinion.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
	selectSubjectOpinion,
	setSubjectOpinion,
} from "../../services/appointment.slice";
import Modal from "../../../../components/modal-safari-wrapper/modal";
import CloseModalButton from "../../../../components/modal-items/close";
import SpecialitySelector from "./speciality-selector";
import ServiceSelector from "./service-selector";
import DoctorSelector from "./doctor-selector";
import Confirm from "./confirm";
import SubjectOpinionForm from "./form";
import { useTranslate } from "../../../../localization/translate";
import { useGetOpinionSubjectQuery } from "../../services/appointment.api";
import Loader from "../../../../components/loader";
import { useEffect, useState } from "react";
import { useGetProfileQuery } from "../../../profile/services/profile.api";
import { pushSystemNotification } from "../../../../app/app.slice";

const modalStyle = {
	content: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	overlay: {
		alignItems: "center",
		padding: "50px 0px",
	},
};

export default function SubjectOpinion() {
	const initSelection = {
		subjectId: null,
		serviceId: null,
		doctorId: null,
	};
	const [userSelection, setUserSelection] = useState(initSelection);
	const subjectOpinion = useSelector(selectSubjectOpinion);
	const dispatch = useDispatch();
	const closeModal = () => {
		dispatch(setSubjectOpinion(null));
	};
	const translate = useTranslate();
	const { data: profile } = useGetProfileQuery();
	const { data, isLoading } = useGetOpinionSubjectQuery(null, {
		skip: !subjectOpinion || !profile,
	});

	useEffect(() => {
		if (subjectOpinion && profile && !profile.medCardNumber) {
			dispatch(
				pushSystemNotification({
					type: "error",
					clientMessage: translate(
						"appointment.subjectOpinion.needMedCard",
						true
					),
				})
			);
			dispatch(setSubjectOpinion(null));
		}
	}, [profile, subjectOpinion]);

	if (!subjectOpinion) {
		return null;
	}

	const Components = {
		SpecialitySelector,
		ServiceSelector,
		DoctorSelector,
		Confirm,
		SubjectOpinionForm,
	};
	const Component = Components[subjectOpinion];
	if (subjectOpinion === "SubjectOpinionForm") {
		modalStyle.content = {
			...modalStyle.content,
			marginTop: "auto"
		}
	} else {
		modalStyle.content = {
			...modalStyle.content,
			marginTop: "0"
		}
	}

	return (
		<Modal
			isOpen={!!subjectOpinion}
			onRequestClose={closeModal}
			style={{ ...modalStyle }}
			// className={className}
		>
			<CloseModalButton closeModal={closeModal} />
			<div className={css.body}>
				<div className={css.title}>
					{translate(
						`appointment.subjectOpinion.${subjectOpinion[0].toLowerCase()}${subjectOpinion.slice(
							(subjectOpinion.length - 1) * -1
						)}`
					)}
				</div>
				{isLoading && (
					<div className={css.loader}>
						<Loader />
					</div>
				)}
				{data && (
					<Component
						data={data}
						userSelection={userSelection}
						setUserSelection={setUserSelection}
					/>
				)}
			</div>
		</Modal>
	);
}
