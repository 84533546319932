import { useEffect, useMemo } from "react";
import classNames from "classnames";
import {
	useGetProfileQuery,
	useGetDiscountDataQuery,
} from "../../services/profile.api";
import { useTranslate } from "../../../../localization/translate";
import Loader from "../../../../components/loader";
import AvailableDiscounts from "./components/AvailableDiscounts";
import OthersDiscounts from "./components/OthersDiscounts";
import styles from "./discounts.module.css";
import { setEditModalState } from "../../services/profile.slice";
import ProfileNotice from "../personala-data/notice";
import { useNavigate } from "react-router-dom";
import { Metrics } from "../../../../utils/metrics";

const Discounts = () => {
	const translate = useTranslate();
	const { data: profile, isLoading } = useGetProfileQuery();

	const hasMedCard = useMemo(() => !!profile?.medCardNumber, [profile]);

	const { data: discounts, isLoading: isLoadingDiscounts } =
		useGetDiscountDataQuery(null, {
			skip: !hasMedCard,
		});

	const hasDiscounts = useMemo(
		() =>
			!!discounts &&
			Object.values(discounts).some((collection) => !!collection.length),
		[discounts]
	);

	useEffect(() => {
		if (discounts && hasDiscounts) {
			Metrics.gtmEvent("view_discounts_list");
		}
	}, [discounts, hasDiscounts]);

	const navigate = useNavigate();

	return (
		<div className={styles.discounts}>
			<div className={styles.content}>
				<div className={classNames("h3", styles.title)}>
					{translate("profile.discounts.title")}
				</div>

				{isLoading || isLoadingDiscounts ? (
					<Loader
						containerStyle={{ display: "flex", justifyContent: "center" }}
					/>
				) : (
					<>
						{hasMedCard && hasDiscounts && (
							<div className={styles.content_block}>
								<AvailableDiscounts discounts={discounts} />
							</div>
						)}

						{(discounts?.discountCards.length <= 0 ||
							discounts?.giftCertificates.length <= 0) && (
							<div className={styles.content_block}>
								<OthersDiscounts
									showDiscountCards={discounts.discountCards.length <= 0}
									showGiftCertificates={discounts.giftCertificates.length <= 0}
								/>
							</div>
						)}
					</>
				)}

				{!hasMedCard && (
					<ProfileNotice
						className={styles.content_block}
						isGrey
						title={translate("app.attention", true)}
						message={translate("app.getFullFunctionality")}
						btn={{
							onClick: () => navigate("/profile/personal-info"),
							text: translate("profile.personalData.specify", true),
						}}
					/>
				)}

				<div className={styles.content_block}>
					<a
						href="https://www.medicina.ru/patsientam/diskontnye-programmy/"
						target="_blank"
						className={styles.term_btn}
						onClick={() => Metrics.gtmEvent("view_discount_info")}
					>
						{translate("profile.discounts.termOfDiscountProgram")}
					</a>
				</div>
			</div>
		</div>
	);
};

export default Discounts;
