import { useEffect, useState } from "react";
import s from "./index.module.css";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as FaceIDIcon } from "../../../../icons/biometry-face.svg";
import { ReactComponent as TouchIDIcon } from "../../../../icons/biometry-fingerprint.svg";
import { ReactComponent as BiometryIcon } from "../../../../icons/biometry.svg";
import { ReactComponent as CrossIcon } from "../../../../icons/cross.svg";
import { useTranslate } from "../../../../localization/translate";
import RicibV01 from "../../../../components/ricib/ricib.v.0.1";
import {
	selectBiometryAllowed,
	selectShortCode,
	setAuthInfo,
	setExpired,
	setLastUrl,
	updateLastActivity,
} from "../../services/login.slice";
import md5 from "md5";
import { isAndroidApp, isIOSApp } from "../../../../utils";

const MAX_TRIES = 3;

const BiometryRecovery = () => {
	const t = useTranslate();
	const dispatch = useDispatch();
	const shortCode = useSelector(selectShortCode);
	const isBiometryAllowed = useSelector(selectBiometryAllowed);

	const [type, setType] = useState("none");
	const [attempts, setAttempts] = useState(MAX_TRIES);
	const [code, setCode] = useState("");
	const [error, setError] = useState(null);

	const handleClose = () => {
		dispatch(setAuthInfo(null));
		dispatch(setExpired(false));
		dispatch(setLastUrl(null));
		setTimeout(() => window.location.reload(), 500);
	};

	const handleBiometryRecovery = () => {
		if (isAndroidApp) {
			window.MedicinaAndroidWebView?.requestBiometry?.();
		}

		if (isIOSApp) {
			window.requestBiometry?.();
		}
	};

	window.requestBiometryCallBack = (result) => {
		if (result === "success") {
			setCode(shortCode);
			setError(null);
		}

		if (result === "failure") {
			setError(t("biometry.error"));
		}
	};

	window.checkBiometryStatusCallBack = (type) => {
		if (type === "none") handleClose();
		else setType(type);
	};

	useEffect(() => {
		if (isAndroidApp) {
			window.MedicinaAndroidWebView?.checkBiometryStatus?.();
		}

		if (isIOSApp) {
			window.checkBiometryStatus?.();
		}

		if (type !== "none" && isBiometryAllowed) {
			handleBiometryRecovery();
		}
	}, [type]);

	useEffect(() => {
		if (code) {
			if (code === shortCode) {
				dispatch(updateLastActivity());
				dispatch(setExpired(false));
			} else if (!shortCode) handleClose();
			else if (code !== shortCode) {
				setError(
					`${t("login.shortCodeLogin.left", true)} ${attempts - 1} ${t(
						"login.shortCodeLogin.attempts",
						true
					)}`
				);
			}

			setAttempts(attempts - 1);
		}
	}, [code]);

	useEffect(() => {
		if (attempts === 0) {
			handleClose();
		}
	}, [attempts]);

	return (
		<div className={cn(s.container, s.recovery)}>
			<button className={s.close} onClick={handleClose}>
				<CrossIcon className={s.icon} />
			</button>
			<button className={s.logout} onClick={handleClose}>
				{t("login.logoutBtn")}
			</button>
			<p className={s.title}>{t("biometry.recoveryTitle")}</p>
			<p className={s.text}>{t("biometry.recoverySubTitle")}</p>
			{error && <p className={s.error}>{error}</p>}
			<RicibV01
				onComplete={(code) => setCode(md5(code))}
				length={4}
				onChange={(code) => {
					setError(null);
					if (code.length < 4) {
						setCode(null);
					}
				}}
				type="password"
			/>
			{isBiometryAllowed && type === "touchId" && (
				<button className={s.recoveryButton} onClick={handleBiometryRecovery}>
					<TouchIDIcon className={s.icon} />
				</button>
			)}
			{isBiometryAllowed && type === "faceId" && (
				<button className={s.recoveryButton} onClick={handleBiometryRecovery}>
					<FaceIDIcon className={s.icon} />
				</button>
			)}
			{isBiometryAllowed && type === "biometry" && (
				<button className={s.recoveryButton} onClick={handleBiometryRecovery}>
					<BiometryIcon className={s.icon} />
				</button>
			)}
		</div>
	);
};

export default BiometryRecovery;
