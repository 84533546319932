import React, { useEffect, useState } from "react";
import Modal from "../../../../components/modal-safari-wrapper/modal";
import confirm from "./confirm-modal.module.css";
import { useTranslate } from "../../../../localization/translate";
import CloseModalButton from "../../../../components/modal-items/close";
import { useDispatch, useSelector } from "react-redux";
import {
	selectedDoctor,
	selectExaminationInfo,
	selectReceptionType,
	setReceptionType,
	setReceptionTypeState,
	setSelectedDoctor,
} from "../../services/appointment.slice";
import classNames from "classnames";
import { getValue } from "../doctor-modal/doctor-modal";
import moment from "moment";
import {
	useGetProfileQuery,
	useLazyGetCountersQuery,
} from "../../../profile/services/profile.api";
import Button from "../../../../components/button/button";
import {
	useAppointmentDiagnosticMutation,
	useAppointmentExaminationMutation,
	useExportCalendarEventMutation,
} from "../../services/appointment.api";
import Loader from "../../../../components/loader";
import ModalInfo from "../../../../components/custom-modal/info";
import {
	useExaminationRescheduleMutation,
	useDiagnosticRescheduleMutation,
} from "../../../events/services/events.api";
import {
	selectPatient,
	setPatientSelectorState,
	allowAddPatient,
	setCounters,
} from "../../../profile/services/profile.slice";
import { useLocation, useNavigate } from "react-router-dom";
import {downloader, getPlatform, getSiteSourceName, isInIframe} from "../../../../utils";
import { setPaymentData } from "../../../payments/services/payments.slice";
import { PAYMENT_TYPES } from "../../../payments/helpers/constants";
import SelectServices from "../select-services";
import CreateIssue from "../../../profile/modules/support/components/CreateIssue";
import { moscowTimeZone } from "../../../../utils/dates/timezone-converter";
import { isSafari } from "../../../../utils";
import { setRescheduleDoctor } from "../../../events/services/events.slice";
import { Metrics } from "../../../../utils/metrics";
import Checkbox from "../../../../components/input/checkbox/Checkbox";
import { CODES_ENUM } from "../../../agreements/constants";
import Agreements from "../../../agreements/agreements";
import {
	selectAccessToken,
	selectRefreshToken,
	selectShortCode,
	setAuthInfo,
	setShortCode,
} from "../../../login/services/login.slice";

const modalStyle = {
	overlay: {
		zIndex: 140,
	},
	content: {},
};

export default function OnlineExamination({
	isPublic = false,
	isWidget = false,
	cancelExamination,
	scrollToTopOfWidget,
}) {
	const { data: profile } = useGetProfileQuery(null);
	const [isOpen, setIsOpen] = useState(true);
	const [isOpenCreateIsuue, setIsOpenCreateIssue] = useState(false);
	const navigate = useNavigate();
	const storeddDoctor = useSelector(selectedDoctor);

	const [type, setType] = useState(null);
	const [privacyPolicyAcception, setPrivacyPolicyAcception] = useState(true);

	const onRequestClose = () => {
		cancelExamination();
		setIsOpen(false);
	};
	const dispatch = useDispatch();
	const receptionType = useSelector(selectReceptionType);
	const onRequestBackward = () => {
		//Если есть receptionType - значит это запись на прием к врачу.
		// Вернуться назад к выбору типа приема
		dispatch(setReceptionType(null));
		dispatch(setReceptionTypeState(true));
	};
	const translate = useTranslate();
	const examinationInfo = useSelector(selectExaminationInfo);
	const speciality =
		examinationInfo.specialityId &&
		(examinationInfo.doctor.specialities || []).find(
			(spec) => spec.id === examinationInfo.specialityId
		);

	const [
		appointmentDiagnostic,
		{
			data: appointmentDiagnosticResult,
			isLoading: appointmentDiagnosticLoading,
			error: appointmentDiagnosticError,
		},
	] = useAppointmentDiagnosticMutation();
	const [
		rescheduleDiagnostic,
		{
			data: rescheduleDiagnosticResult,
			isLoading: rescheduleDiagnosticLoading,
			error: rescheduleDiagnosticError,
		},
	] = useDiagnosticRescheduleMutation();
	const [
		appointmentExamination,
		{
			data: appointmentExaminationResult,
			isLoading: appointmentExaminationLoading,
			error: appointmentExaminationError,
		},
	] = useAppointmentExaminationMutation();
	const [
		rescheduleExamination,
		{
			data: rescheduleExaminationResult,
			isLoading: rescheduleExaminationLoading,
			error: rescheduleExaminationError,
		},
	] = useExaminationRescheduleMutation();
	const [failure, setFailure] = useState();
	const [success, setSuccess] = useState();
	const [selectServices, setSelectSerices] = useState(false);
	let receptionTypeName = "";
	const selectedPatient = useSelector(selectPatient);
	const currentPatient = examinationInfo?.patient || selectedPatient || profile;

	const medCardNumber =
		currentPatient?.medCardNumber !== profile?.medCardNumber
			? currentPatient?.medCardNumber
			: null;

	const successObj =
		appointmentExaminationResult || rescheduleExaminationResult;

	if (receptionType === "offline") {
		receptionTypeName = translate(`appointment.confirm.${receptionType}`, true);
	} else if (examinationInfo.receptionTypes) {
		receptionTypeName = examinationInfo.receptionTypes.find(
			(type) => type.id === receptionType
		)?.name;
	}

	useEffect(() => {
		if (
			appointmentExaminationError ||
			appointmentDiagnosticError ||
			rescheduleExaminationError ||
			rescheduleDiagnosticError
		) {
			setFailure(true);
		}
	}, [
		appointmentExaminationError,
		appointmentDiagnosticError,
		rescheduleExaminationError,
		rescheduleDiagnosticError,
	]);

	useEffect(() => {
		if (
			appointmentExaminationResult ||
			appointmentDiagnosticResult ||
			rescheduleDiagnosticResult ||
			rescheduleExaminationResult
		) {
			if (examinationInfo?.rescheduleInfo?.oldIsPaid) {
				if (!isWidget) {
					navigate("/events");
					dispatch(setSelectedDoctor(null));
				}
				dispatch(setRescheduleDoctor(null));
				onRequestClose();
			} else {
				setSuccess(true);
			}

			dispatch({
				type: "eventsApi/invalidateTags",
				payload: ["Planned"],
			});
			Metrics.gtmEvent("add_reserve", {
				doctorId: examinationInfo.doctor.id,
				date: examinationInfo.date,
				type: receptionType === "offline" ? "offline" : "online",
				source: isInIframe()
					? "WIDGET"
					: getPlatform() === "web"
					? "WEBAPP"
					: "MOBILEAPP",
				item_variant: speciality?.name??storeddDoctor?.specialityName,
				site_source_domain: getSiteSourceName(),
				site_source_path: window.location.pathname
			});
		}
	}, [
		appointmentExaminationResult,
		appointmentDiagnosticResult,
		rescheduleDiagnosticResult,
		rescheduleExaminationResult,
	]);

	useEffect(() => {
		setTimeout(() => scrollToTopOfWidget?.(), 100);
	}, [success, failure]);

	const makeAppointment = (medCardNumber) => {
		if (examinationInfo.serviceId) {
			const payload = {
				serviceId: examinationInfo.serviceId,
				dateTime: moment(examinationInfo.date).toISOString(true),
				doctorId: examinationInfo.doctor.id,
				medCardNumber,
				additionalSlots:
					examinationInfo.additionalSlots?.map((s) => s.idMis) || [],
				source: isInIframe()
					? "WIDGET"
					: getPlatform() === "web"
					? "WEBAPP"
					: "MOBILEAPP",
			};

			if (examinationInfo.rescheduleInfo) {
				payload.newSlotIdMis = examinationInfo.slotIdMis;
				payload.oldSlotIdMis = examinationInfo.rescheduleInfo.oldSlotIdMis;

				rescheduleDiagnostic(payload);
			} else {
				payload.slotIdMis = examinationInfo.slotIdMis;

				appointmentDiagnostic(payload);
			}
		} else {
			const body = {
				slotIdMis: examinationInfo.slotIdMis,
				dateTime: moment(examinationInfo.date).toISOString(true),
				doctorId: examinationInfo.doctor.id,
				medCardNumber,
				additionalSlots:
					examinationInfo.additionalSlots?.map((s) => s.idMis) || [],
				source: isInIframe()
					? "WIDGET"
					: getPlatform() === "web"
					? "WEBAPP"
					: "MOBILEAPP",
			};
			if (receptionType && receptionType !== "offline") {
				body.featureId = receptionType;
			}
			if (examinationInfo.rescheduleInfo) {
				body.newSlotIdMis = examinationInfo.slotIdMis;
				body.oldSlotIdMis = examinationInfo.rescheduleInfo.oldSlotIdMis;

				rescheduleExamination(body);
			} else {
				body.slotIdMis = examinationInfo.slotIdMis;
				appointmentExamination(body);
			}
		}
	};

	const onClick = () => {
		Metrics.gtmEvent("purchase", {
			transaction_id: examinationInfo?.slotIdMis?.toString(),
			value: examinationInfo?.doctor?.minPrice,
			currency: "RUB",
			items: [
				{
					item_id: examinationInfo?.doctor?.id,
					item_name: examinationInfo?.doctor?.shortname,
					price: examinationInfo?.doctor?.minPrice,
					item_variant: speciality?.name??storeddDoctor?.specialityName,
				},
			],
			type: receptionType === "offline" ? "offline" : "online",
			source: isInIframe()
				? "WIDGET"
				: getPlatform() === "web"
				? "WEBAPP"
				: "MOBILEAPP",
			site_source_domain: getSiteSourceName(),
      item_variant: speciality?.name??storeddDoctor?.specialityName,
			site_source_path: window.location.pathname
		});

		makeAppointment(currentPatient.medCardNumber);
	};

	const handleAgreement = (type) => {
		if ([CODES_ENUM.PD_POLICY].includes(type)) {
			setPrivacyPolicyAcception(true);
		}
		setType(null);
	};

	if (selectServices) {
		return (
			<SelectServices
				isOpen
				doctorId={examinationInfo?.doctor?.id}
				slotIdMis={examinationInfo?.slotIdMis}
				cancelExamination={cancelExamination}
				onCloseSelf={() => setSelectSerices(false)} // todo
				medCardNumber={medCardNumber}
				specialityId={examinationInfo?.specialityId}
			/>
		);
	}

	if (isOpenCreateIsuue) {
		return (
			<CreateIssue isOpen handleClose={() => setIsOpenCreateIssue(false)} />
		);
	}

	if (success) {
		return (
			<Success
				cancelExamination={cancelExamination}
				preparation={
					appointmentDiagnosticResult &&
					appointmentDiagnosticResult.data &&
					appointmentDiagnosticResult.data.preparation
				}
				serviceId={examinationInfo.serviceId}
				specialityId={examinationInfo.specialityId}
				slotIdMis={examinationInfo.slotIdMis}
				sourceDate={moment(examinationInfo.date).format(
					"YYYY-MM-DDTHH:mm:ss"
				)} /*convertDate()*/
				onCloseSelf={() => {
					setSuccess(false);
				}}
				medCardNumber={medCardNumber}
				noteForPatient={successObj?.data?.noteForPatient}
				onOpenSelecServices={() => {
					setSelectSerices(true);
					setIsOpen(false);
				}}
				doctorId={examinationInfo.doctor.id}
				isWidget={isWidget}
			/>
		);
	}

	if (failure) {
		return (
			<Failure
				cancelExamination={cancelExamination}
				onClick={() => setIsOpenCreateIssue(true)}
				backward={() => setFailure(false)}
				isWidget={isWidget}
			/>
		);
	}

	return (
		<Modal
			isOpen={isOpen}
			className={confirm.modal}
			onRequestClose={onRequestClose}
			style={modalStyle}
		>
			<CloseModalButton closeModal={onRequestClose} />
			<div className={confirm.title}>
				{receptionType && (
					<span
						className={confirm.backward}
						onClick={() => onRequestBackward()}
					>
						<svg
							width="10"
							height="18"
							viewBox="0 0 10 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M9.74156 0.255101C10.0861 0.595235 10.0861 1.1467 9.74156 1.48683L2.13019 9L9.74156 16.5132C10.0861 16.8533 10.0861 17.4048 9.74156 17.7449C9.39698 18.085 8.83831 18.085 8.49373 17.7449L0.258435 9.61587C-0.0861451 9.27573 -0.0861451 8.72427 0.258435 8.38413L8.49373 0.255101C8.83831 -0.0850335 9.39698 -0.0850335 9.74156 0.255101Z"
								fill="#7A8CA4"
							/>
						</svg>
					</span>
				)}
				{translate("appointment.confirm.title")}
			</div>
			<div
				className={classNames(confirm.content, {
					[confirm.padding_bottom]: !!examinationInfo.rescheduleInfo,
				})}
			>
				{examinationInfo.doctor.photoUrl ? (
					<div className={confirm.avatar}>
						<img src={examinationInfo.doctor.photoUrl} alt="" />
					</div>
				) : (
					<div className={classNames(confirm.avatar, confirm.empty)}>
						<svg
							width="26"
							height="30"
							viewBox="0 0 26 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17.509 8.20317C17.509 5.43587 15.3363 3.19504 12.6532 3.19504C9.97002 3.19504 7.79732 5.43587 7.79732 8.20317C7.79732 10.9705 9.97002 13.2113 12.6532 13.2113C15.3363 13.2113 17.509 10.9705 17.509 8.20317ZM20.2838 8.20317C20.2838 12.551 16.8688 16.0731 12.6532 16.0731C8.43755 16.0731 5.02254 12.551 5.02254 8.20317C5.02254 3.85535 8.43755 0.333252 12.6532 0.333252C16.8688 0.333252 20.2838 3.85535 20.2838 8.20317ZM2.94146 25.5783C2.94146 26.9282 2.83029 26.8048 3.57395 26.8048H22.4261C23.1697 26.8048 23.0586 26.9282 23.0586 25.5783C23.0586 22.3042 18.4746 20.3658 13 20.3658C7.52542 20.3658 2.94146 22.3042 2.94146 25.5783ZM0.166687 25.5783C0.166687 20.0261 6.13095 17.504 13 17.504C19.8691 17.504 25.8334 20.0261 25.8334 25.5783C25.8334 28.4636 24.7497 29.6666 22.4261 29.6666H3.57395C1.25039 29.6666 0.166687 28.4636 0.166687 25.5783Z"
								fill="#54A0FF"
							/>
						</svg>
					</div>
				)}
				<div className={classNames(confirm.name, "h3")}>
					{examinationInfo.doctor.surname
						? `${examinationInfo.doctor.surname} ${examinationInfo.doctor.name} ${examinationInfo.doctor.middleName}`
						: examinationInfo.doctor.shortname}
				</div>
				{speciality && (
					<div className={classNames(confirm.speciality, "text", confirm.row)}>
						{speciality.name}
					</div>
				)}
				{(!!examinationInfo.doctor.rating ||
					!!examinationInfo.doctor.experienceYearsAll ||
					!!examinationInfo.doctor.minPrice) && (
					<div className={classNames(confirm.rating_info, confirm.row)}>
						{examinationInfo.doctor.rating && (
							<span className={confirm.rating}>
								<svg
									width="12"
									height="12"
									viewBox="0 0 12 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M2.93108 11.3321C2.85496 11.3321 2.77933 11.3091 2.7152 11.2603C2.60212 11.1732 2.54558 11.027 2.56908 10.8834L3.12273 7.50082L0.77792 5.10817C0.677813 5.00559 0.641833 4.85172 0.684911 4.71324C0.728234 4.5722 0.843026 4.47218 0.981316 4.45167L4.22194 3.95672L5.67117 0.88192C5.7331 0.748567 5.86062 0.666504 6.00037 0.666504C6.14013 0.666504 6.26765 0.748567 6.32958 0.88192L7.7788 3.95672L11.0192 4.45167C11.1575 4.47218 11.2725 4.5722 11.3156 4.71324C11.3589 4.85172 11.3229 5.00559 11.2226 5.10817L8.87802 7.50082L9.43143 10.8834C9.45492 11.027 9.39838 11.1732 9.2853 11.2603C9.17222 11.345 9.02268 11.3578 8.89858 11.2886L6.00037 9.69345L3.10192 11.2886C3.04832 11.3193 2.98958 11.3321 2.93108 11.3321Z"
										fill="#F7236F"
									/>
								</svg>
								&nbsp;
								{getValue(examinationInfo.doctor.rating)}
							</span>
						)}

						{!!examinationInfo.doctor.experienceYearsAll && (
							<span>
								{translate("appointment.experience", true)}: &nbsp;
								{examinationInfo.doctor.experienceYearsAll
									? moment
											.duration(
												examinationInfo.doctor.experienceYearsAll,
												"years"
											)
											.humanize()
									: 0}
							</span>
						)}

						{!!examinationInfo.doctor.minPrice && (
							<span>
								{translate("appointment.minPrice", true)}:{" "}
								{getValue(examinationInfo.doctor.minPrice, true)}{" "}
								{translate("app.currencySign", true)}
							</span>
						)}
					</div>
				)}

				{!!examinationInfo.additionalSlots?.length && (
					<>
						<p className={confirm.helper}>
							{translate("appointment.slots.info1")}
							{examinationInfo.additionalSlots.length + 1}
							{!!examinationInfo.additionalSlots[0]?.time && (
								<>
									{translate("appointment.slots.info2")}
									{[
										moment(examinationInfo.date).format("HH:mm"),
										...examinationInfo.additionalSlots.map((sl) =>
											moment(sl.time).format("HH:mm")
										),
									]
										.sort((a, b) => a.localeCompare(b))
										.join(", ")}
								</>
							)}
						</p>
						{/* <p className={confirm.about}>
							{translate("appointment.slots.about")}
						</p> */}
					</>
				)}

				{currentPatient && (
					<div className={classNames(confirm.row, confirm.str)}>
						{translate("appointment.confirm.patient")}
						<span
							className={classNames(confirm.link, {
								[confirm.non_interact]: examinationInfo.rescheduleInfo,
							})}
							onClick={(e) => {
								if (!examinationInfo.rescheduleInfo) {
									if (!isPublic) dispatch(allowAddPatient());
									dispatch(setPatientSelectorState(true));
								}
							}}
						>
							{currentPatient.firstName} {currentPatient.lastName}
						</span>
					</div>
				)}
				{currentPatient && (
					<div className={classNames(confirm.row, confirm.str)}>
						{translate("profile.personalData.medCardNumber")}
						<span>{currentPatient.medCardNumber}</span>
					</div>
				)}
				<div className={classNames(confirm.row, confirm.str)}>
					{examinationInfo.serviceId ? (
						<>
							{translate("appointment.confirm.service")}
							<span>{examinationInfo.serviceName}</span>
						</>
					) : (
						<>
							{translate("appointment.confirm.visit")}
							<span>
								{translate("appointment.confirm.appointmentExamination", true)}
								&nbsp;
								{receptionTypeName}
							</span>
						</>
					)}
				</div>
				{examinationInfo.price && (
					<div className={classNames(confirm.row, confirm.str)}>
						{translate("appointment.confirm.cost")}
						<span>{examinationInfo.price}</span>
					</div>
				)}
				{examinationInfo.rescheduleInfo && (
					<div className={classNames("text", confirm.subtitle)}>
						{translate("events.oldAppointment", true)}
					</div>
				)}
				{examinationInfo.rescheduleInfo && (
					<>
						<div className={classNames(confirm.row, confirm.str)}>
							{translate("appointment.confirm.date")}
							<span>
								{moment(examinationInfo.rescheduleInfo.oldDate).format(
									`D MMMM ${translate(
										"appointment.confirm.atTheTime",
										true
									)} HH:mm`
								)}
								{moscowTimeZone() ? "" : translate("app.byMskTime")}
							</span>
							{/*convertDate()*/}
						</div>
						{!receptionTypeName?.includes("Онлайн") && (
							<div className={classNames(confirm.row, confirm.str)}>
								{translate("appointment.confirm.cabinet")}
								<span>{examinationInfo.rescheduleInfo.oldCabinet}</span>
							</div>
						)}
					</>
				)}
				{examinationInfo.rescheduleInfo && (
					<div className={classNames("text", confirm.subtitle)}>
						{translate("events.newAppointment", true)}
					</div>
				)}
				<div className={classNames(confirm.row, confirm.str)}>
					{translate("appointment.confirm.date")}
					<span>
						{!!examinationInfo.additionalSlots?.length ? (
							<>
								{moment(examinationInfo.date).format("D MMMM ")}
								{translate("appointment.confirm.atTheTime", true)}{" "}
								{
									[
										moment(examinationInfo.date).format("HH:mm"),
										...examinationInfo.additionalSlots.map((sl) =>
											moment(sl.time).format("HH:mm")
										),
									].sort((a, b) => a.localeCompare(b))[0]
								}
							</>
						) : (
							moment(examinationInfo.date).format(
								`D MMMM ${translate(
									"appointment.confirm.atTheTime",
									true
								)} HH:mm`
							)
						)}
						{/*convertDate()*/}
						{moscowTimeZone() ? "" : translate("app.byMskTime")}
					</span>
				</div>
				{!receptionTypeName?.includes("Онлайн") && (
					<div className={classNames(confirm.row, confirm.str)}>
						{translate("appointment.confirm.cabinet")}
						<span>{examinationInfo.cabinet}</span>
					</div>
				)}

				<div className={confirm.checkboxes}>
					<div className={confirm.checkbox}>
						<Checkbox
							// label={<>
							//     {translate("appointment.rate.agreePDA")}
							//     <a style={{cursor: 'pointer'}} onClick={() => setType(CODES_ENUM.PD_POLICY)}>{translate("appointment.rate.pda")}</a>
							// </>}
							label={
								<>
									{translate("appointment.confirm.confirmPda")}&nbsp;
									<a
										style={{ cursor: "pointer" }}
										onClick={() => setType(CODES_ENUM.PD_CONDITIONS)}
									>
										{translate("appointment.confirm.confirmPda2")}
									</a>
									&nbsp;
									{translate("appointment.confirm.confirmPda3")}
									&nbsp;
									<a
										style={{ cursor: "pointer" }}
										onClick={() => setType(CODES_ENUM.PD_POLICY)}
									>
										{translate("appointment.subjectOpinion.privacyPolicy")}
									</a>
								</>
							}
							onChange={setPrivacyPolicyAcception}
							checked={privacyPolicyAcception}
						/>
					</div>
				</div>

				<Button
					className={classNames(confirm.make_appointment, {
						[confirm.is_safari]: isSafari,
						[confirm.widget]: isWidget,
					})}
					disabled={
						appointmentDiagnosticLoading ||
						appointmentExaminationLoading ||
						rescheduleDiagnosticLoading ||
						rescheduleExaminationLoading ||
						!privacyPolicyAcception
					}
					onClick={onClick}
				>
					{appointmentDiagnosticLoading ||
					appointmentExaminationLoading ||
					rescheduleDiagnosticLoading ||
					rescheduleExaminationLoading ? (
						<Loader height={17} />
					) : (
						<>
							{translate(`appointment.doctorModal.makeAppointment`, true)}
							&nbsp;
							{translate("app.at", true)}
							&nbsp;
							{!!examinationInfo.additionalSlots?.length ? (
								<>
									{moment(examinationInfo.date).format("D MMMM ")}
									{translate("appointment.confirm.atTheTime", true)}{" "}
									{
										[
											moment(examinationInfo.date).format("HH:mm"),
											...examinationInfo.additionalSlots.map((sl) =>
												moment(sl.time).format("HH:mm")
											),
										].sort((a, b) => a.localeCompare(b))[0]
									}
								</>
							) : (
								moment(examinationInfo.date).format(
									`D MMMM ${translate(
										"appointment.confirm.atTheTime",
										true
									)} HH:mm`
								)
							)}
							{/*convertDate()*/}
							{moscowTimeZone() ? "" : translate("app.mskTime")}
						</>
					)}
				</Button>

				<Agreements
					isOpen={!!type}
					type={type}
					onClose={() => setType(null)}
					onAgree={handleAgreement}
				/>
			</div>
		</Modal>
	);
}

const Failure = ({ error, backward, cancelExamination, onClick, isWidget }) => {
	const translate = useTranslate();
	const location = useLocation();
	const modalStyle = {
		overlay: {
			zIndex: 140,
		},
	};

	const onClose = () => {
		if (location.search.includes("widget")) {
			window.location.reload();
		}
		cancelExamination();
	};

	return (
		<ModalInfo
			iconType={"failure"}
			isOpen
			modalStyle={modalStyle}
			modalClassName={confirm.modal}
			subtitle={translate("app.somethingWhenWrong")}
			bodyClass={classNames(confirm.content, confirm.failure)}
			onRequestClose={onClose}
		>
			<div className={confirm.contact_support} onClick={onClick}>
				{translate("login.sms.contactSupport", true)}
			</div>
			<Button
				className={classNames(confirm.make_appointment, {
					[confirm.widget]: isWidget,
				})}
				onClick={backward}
			>
				{translate("appointment.confirm.backToAppointment", true)}
			</Button>
		</ModalInfo>
	);
};

const Success = ({
	isWidget = false,
	cancelExamination,
	preparation,
	specialityId,
	serviceId,
	sourceDate,
	onCloseSelf,
	slotIdMis,
	onOpenSelecServices,
	medCardNumber = null,
	noteForPatient,
	doctorId,
	className,
}) => {
	const t = useTranslate();
	const [isLoadingLogout, setLoadingLogout] = useState(false);
	const [getCounters] = useLazyGetCountersQuery();
	const accessToken = useSelector(selectAccessToken);
	const refreshToken = useSelector(selectRefreshToken);
	const shortCode = useSelector(selectShortCode);

	const modalStyle = {
		overlay: {
			zIndex: 140,
		},
		content: {
			// maxHeight: "834px"
			// maxHeight: "78vh"
		},
	};
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const close = async () => {
		cancelExamination();
		dispatch(setRescheduleDoctor(null));

		if (isWidget) {
			setLoadingLogout(true);
			dispatch(setSelectedDoctor(null));

			if (window.location.pathname.startsWith("/schedule")) {
				navigate("/schedule?widget=1");
				dispatch(setShortCode(null));
				dispatch(setAuthInfo());
				window.location.reload();
			}

			onCloseSelf();
			setLoadingLogout(false);
		}
	};
	const [getICV, { data: icv, isLoading }] = useExportCalendarEventMutation();

	const payOnline = () => {
		onCloseSelf();
		if (!isWidget) dispatch(setSelectedDoctor(null));
		dispatch(setRescheduleDoctor(null));

		if (!!serviceId) {
			cancelExamination();

			dispatch(
				setPaymentData({
					PaymentModal: {
						type: PAYMENT_TYPES.service,
						purposeId: serviceId,
						scheduleIdMis: slotIdMis,
						medCardNumber,
						doctorId,
					},
					PaymentTarget: serviceId,
				})
			);
		} else {
			onOpenSelecServices();
		}
	};

	const authData = () => {
		const lsAccessToken = localStorage.getItem("accessToken");
		const lsRefreshToken = localStorage.getItem("refreshToken");
		const lsShortCode = localStorage.getItem("shortCode");

		return `?shortCode=${shortCode || lsShortCode}&accessToken=${
			accessToken || lsAccessToken
		}&refreshToken=${refreshToken || lsRefreshToken}`;
	};

	const payInCabinet = () => {
		close();
		window.open(`/events/${slotIdMis}/payment${authData()}`, "_blank");
	};

	const openCabinet = () => {
		close();
		window.open(`/events/${slotIdMis}${authData()}`, "_blank");
	};

	const updateCounters = async () => {
		if (!isWidget) {
			const { data } = await getCounters();

			if (data?.appointmentsCount) {
				dispatch(setCounters(data.appointmentsCount));
			}
		}
	};

	useEffect(() => {
		if (icv) {
			downloader(icv, "event.ics");
		}
	}, [icv]);

	useEffect(() => {
		updateCounters();
	}, []);

	return (
		<Modal
			iconType={"success"}
			isOpen
			style={modalStyle}
			className={classNames(confirm.modal, confirm.success_modal)}
			onRequestClose={close}
		>
			<CloseModalButton closeModal={close} />
			<div
				className={classNames(
					confirm.content,
					confirm.success,
					{ [confirm.widget]: isWidget },
					className
				)}
			>
				<div className={confirm.svg}>
					<svg
						width="81"
						height="80"
						viewBox="0 0 81 80"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="40.5" cy="40" r="40" fill="#54A0FF" />
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M63.4309 25.2779C64.9343 26.8965 64.8408 29.4275 63.2222 30.9309L37.3822 54.9309C35.8372 56.3658 33.4436 56.3551 31.9116 54.9062L19.7516 43.4062C18.1465 41.8883 18.0759 39.3566 19.5938 37.7516C21.1117 36.1465 23.6434 36.0759 25.2485 37.5938L34.6847 46.5179L57.7779 25.0692C59.3965 23.5658 61.9275 23.6592 63.4309 25.2779Z"
							fill="white"
						/>
					</svg>
				</div>
				<div className={`h3 ${confirm.title2}`}>
					{t("appointment.confirm.confirmed")}
				</div>
				<div
					className={classNames(
						confirm.contact_support,
						confirm.add_to_calendar
					)}
					onClick={() => {
						if (isLoading) {
							return;
						}
						let body = {
							slot: sourceDate,
						};
						if (specialityId) {
							body.specialityId = specialityId;
						}
						if (serviceId) {
							body.serviceId = serviceId;
						}
						getICV(body);
					}}
				>
					{t("app.addToCalendar", true)}
				</div>

				{noteForPatient && (
					<div
						className={confirm.warn}
						dangerouslySetInnerHTML={{ __html: noteForPatient }}
					/>
				)}

				{preparation && (
					<div className={confirm.information}>
						<div className={confirm.title3}>
							<div className={confirm.icon}>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M12 18.5C15.5899 18.5 18.5 15.5899 18.5 12C18.5 8.41015 15.5899 5.5 12 5.5C8.41015 5.5 5.5 8.41015 5.5 12C5.5 15.5899 8.41015 18.5 12 18.5ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
										fill="#54A0FF"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M12 10.25C12.4142 10.25 12.75 10.5858 12.75 11V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V11C11.25 10.5858 11.5858 10.25 12 10.25Z"
										fill="#54A0FF"
									/>
									<path
										d="M13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z"
										fill="#54A0FF"
									/>
								</svg>
							</div>
							<div className={classNames(confirm.block_title)}>
								{t("app.information", true)}
							</div>
						</div>
						<div dangerouslySetInnerHTML={{ __html: preparation }} />
					</div>
				)}
			</div>
			{isLoadingLogout ? (
				<Loader className={confirm.loader} />
			) : (
				<div
					className={classNames(confirm.make_appointment, {
						[confirm.widget]: isWidget,
					})}
				>
					{!isWidget && (
						<Button onClick={payOnline}>
							{t("appointment.confirm.payOnline", true)}
						</Button>
					)}
					{!isWidget && (
						<Button className={confirm.long_btn} light onClick={close}>
							{t("appointment.confirm.payLater", true)}
						</Button>
					)}
					{isWidget && (
						<Button className={confirm.long_btn} onClick={payInCabinet}>
							{t("appointment.confirm.payInCabinet", true)}
						</Button>
					)}
					{isWidget && (
						<Button className={confirm.long_btn} light onClick={openCabinet}>
							{t("appointment.confirm.goToCabinet", true)}
						</Button>
					)}
					{isWidget && (
						<Button className={confirm.long_btn} light onClick={close}>
							{t("appointment.confirm.makeAnotherAppointment", true)}
						</Button>
					)}
				</div>
			)}
		</Modal>
	);
};
