import { useSelector } from "react-redux";
import {
	linkButton,
	selectPatient,
} from "../../../profile/services/profile.slice";
import PatientModal from "./patient-selector";
import { useGetProfileQuery } from "../../../profile/services/profile.api";

export default function PatientSelectorContainer({ isWidget }) {
	const selectedPatient = useSelector(selectPatient);
	const canAdd = useSelector(linkButton);
	const { data: profile } = useGetProfileQuery();
	const currentPatientCard = selectedPatient
		? selectedPatient.medCardNumber
		: profile
		? profile.medCardNumber
		: null;

	if (!profile || !currentPatientCard) {
		return null;
	}

	return (
		<PatientModal
			canAdd={canAdd}
			selectedPatient={selectedPatient}
			profile={profile}
			currentPatientCard={currentPatientCard}
			isWidget={isWidget}
		/>
	);
}
