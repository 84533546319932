import styles from "./research.module.css";
import { useGetResearchQuery } from "../../services/appointment.api";
import Search from "../search";
import Loader from "../../../../components/loader";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslate } from "../../../../localization/translate";
import { useLocation, useNavigate } from "react-router-dom";
import { Metrics } from "../../../../utils/metrics";
import { useDispatch, useSelector } from "react-redux";
import {
	selectResearchId,
	setResearchId,
} from "../../services/appointment.slice";

export default function Research({ isPublic = false, isWidget = false }) {
	const location = useLocation();
	const researchId = useSelector(selectResearchId);
	const { data, isLoading } = useGetResearchQuery();
	const [breadcrumbs, setBreadcrumbs] = useState([]);
	const [services, setServices] = useState([]);
	const translate = useTranslate();

	const breadcrumbClick = (index) => {
		setSelectedPath(breadcrumbs.slice(0, index).map((item) => item.id));
		setBreadcrumbs([]);
		setServices([]);
	};
	const [selectedPath, setSelectedPath] = useState([]); // в этом массиве будет храниться кусок breadcrumbs от первого элемента до того, по которому кликнул юзер
	const navigate = useNavigate();

	useEffect(() => {
		if (location.state?.id) {
			const { id } = location.state;
			setSelectedPath([id]);
		}
	}, [location.state]);

	useEffect(() => {
		// Metrics.gtmEvent("view_item_list", { ItemListName: "research" });
	}, []);

	useEffect(() => {
		if (researchId && data) {
			const research = data.find((r) => r.id === parseInt(researchId));

			if (research) {
				setBreadcrumbs([{ id: research.id, name: research.name }]);
				setSelectedPath([research.id]);
			}

			const item = document.getElementById("research-" + researchId);

			if (item && !window.scrollY && !isWidget) {
				setTimeout(() => item.scrollIntoView(), 0);
			}
		}
	}, [data, researchId]);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [services]);

	return (
		<div className={styles.content}>
			<Search isPublic={isPublic} isWidget={isWidget} />
			{!data && isLoading && (
				<div className={styles.loader_container}>
					<Loader />
				</div>
			)}
			{data && services.length === 0 && (
				<div className={styles.researchs}>
					{data.map((research, i) => (
						<ResearchExpandable
							isRoot
							isLast={data.length === i + 1}
							setServices={setServices}
							selectedPath={selectedPath}
							setSelectedPath={setSelectedPath}
							breadcrumbs={breadcrumbs}
							key={JSON.stringify(research)}
							research={research}
							setBreadcrumbs={setBreadcrumbs}
						/>
					))}
				</div>
			)}
			{services.length > 0 && (
				<>
					<div className={styles.breadcrumbs}>
						{breadcrumbs.map((item, i) => (
							<span
								onClick={() => breadcrumbClick(i + 1)}
								className={classNames({
									[styles.root]: i !== breadcrumbs.length - 1,
									[styles.current]: i === breadcrumbs.length - 1,
								})}
								key={`breadcrumbs-${item.id}`}
								style={{ cursor: "pointer" }}
							>
								{item.name}
								{i !== breadcrumbs.length - 1 && <>&nbsp;&nbsp;/&nbsp;&nbsp;</>}
							</span>
						))}
					</div>

					<div className={classNames(styles.researchs, styles.services)}>
						<div className={classNames("h3", styles.title)}>
							{breadcrumbs[breadcrumbs.length - 1].name}
						</div>
						{services.map((service, i) => (
							<div
								className={styles.research}
								key={`service-${service.id}`}
								onClick={(event) => {
									event.stopPropagation();
									navigate(
										`/${
											isPublic || isWidget ? "schedule" : "appointment"
										}/services/${breadcrumbs[0].id}/${service.id}${
											location.search
										}`
									);
								}}
							>
								<div
									className={classNames(styles.wrapper, {
										[styles.last]: services.length - 1 === i,
									})}
									key={`service-${service.id}`}
								>
									<div className={classNames(styles.text, "text")}>
										{service.name}
									</div>
									<div className={classNames("text", styles.price)}>
										{service.price.toLocaleString()}
										&nbsp;
										{translate("app.currencySign")}
									</div>
									<div className={styles.expander}>
										<ExpandIcon />
									</div>
								</div>
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
}

const ResearchExpandable = ({
	isRoot = false,
	research,
	setBreadcrumbs,
	breadcrumbs = [],
	setServices,
	isLast,
	setSelectedPath,
	selectedPath,
}) => {
	const dispatch = useDispatch();
	const [expanded, setExpanded] = useState(selectedPath.includes(research.id));
	const ifAlreadyExist = !!breadcrumbs.find((item) => item.id === research.id);
	const canBeExpanded =
		((research.groups && research.groups.length > 0) ||
			(research.children && research.children.length > 0)) &&
		(!research.service || research.service.length === 0);
	const canViewServices = research.service && research.service.length > 0;

	const onExpand = () => {
		if (isRoot) {
			if (expanded) {
				dispatch(setResearchId(null));
				setBreadcrumbs([]);
				setSelectedPath([]);
			} else {
				dispatch(setResearchId(research.id));
				setBreadcrumbs([{ id: research.id, name: research.name }]);
				setSelectedPath([research.id]);
			}

			setExpanded(!expanded);
			return;
		}

		if (expanded) {
			setBreadcrumbs(breadcrumbs.filter((item) => item.id !== research.id));
		} else {
			!ifAlreadyExist &&
				setBreadcrumbs([
					...breadcrumbs,
					{ id: research.id, name: research.name },
				]);
		}
		setExpanded(!expanded);
	};

	const onViewService = () => {
		setBreadcrumbs([...breadcrumbs, { id: research.id, name: research.name }]);
		setServices(research.service);
	};

	const wrappedHandler = (event) => {
		event.stopPropagation();
		canBeExpanded && onExpand();
		canViewServices && onViewService();
	};

	useEffect(() => {
		if (selectedPath.includes(research.id)) {
			setExpanded(true);
			!ifAlreadyExist &&
				setBreadcrumbs([
					...breadcrumbs,
					{ id: research.id, name: research.name },
				]);
		} else {
			setExpanded(false);
		}
	}, [selectedPath]);

	return (
		<div
			id={"research-" + research.id}
			className={styles.research}
			onClick={wrappedHandler}
		>
			<div className={classNames(styles.wrapper, { [styles.last]: isLast })}>
				<div className={classNames(styles.text, "text")}>
					<div>{research.name}</div>
					{research.description && (
						<div
							dangerouslySetInnerHTML={{ __html: research.description }}
							className={styles.description}
						/>
					)}
				</div>
				{canBeExpanded && (
					<div
						className={classNames(styles.expander, {
							[styles.expand]: expanded,
						})}
					>
						<ExpandIcon />
					</div>
				)}
				{canViewServices && (
					<div className={styles.expander}>
						<ExpandIcon />
					</div>
				)}
			</div>
			{expanded && canBeExpanded && (
				<div className={classNames(styles.row)}>
					{(research.groups || research.children).map((group, i) => (
						<ResearchExpandable
							breadcrumbs={breadcrumbs}
							selectedPath={selectedPath}
							setSelectedPath={setSelectedPath}
							isLast={(research.groups || research.children).length === i + 1}
							key={JSON.stringify(group)}
							research={group}
							setServices={setServices}
							setBreadcrumbs={setBreadcrumbs}
						/>
					))}
				</div>
			)}
		</div>
	);
};

const ExpandIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.72676 7.73259C4.0291 7.42247 4.51929 7.42247 4.82163 7.73259L11.5 14.5828L18.1784 7.73259C18.4807 7.42247 18.9709 7.42247 19.2732 7.73259C19.5756 8.04271 19.5756 8.54552 19.2732 8.85564L12.0474 16.2674C11.7451 16.5775 11.2549 16.5775 10.9526 16.2674L3.72676 8.85564C3.42441 8.54552 3.42441 8.04271 3.72676 7.73259Z"
			fill="#7A8CA4"
		/>
	</svg>
);
