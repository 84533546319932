import { isRejectedWithValue } from "@reduxjs/toolkit";
import {
	pushSystemNotification,
	resetAllNotifications,
	setApplicationError,
} from "./app.slice";

/**
 * Handling response errors
 */
export const rtkQueryErrorHandler = (api) => (next) => (action) => {
	// document.write("https://medicina-backend.i.kingbird.ru/api/profile")
	// RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

	// if (!action.payload) {
	//     action.payload = {};
	// }
	if (isRejectedWithValue(action) && action.payload.status === "FETCH_ERROR") {
		api.dispatch(setApplicationError(action.payload));
	}
	if (
		isRejectedWithValue(action) &&
		(action.payload.status === 401 || action.payload.status === 403)
	) {
		//TODO: Actions must be plain objects ERR
		//     console.error(next)
		//     console.error(api)
		//     console.log(action)
		// const dispatch = useDispatch();
		// api.dispatch(setNeedRefresh(true))
		/* dispatch(вызываем action, который изменит state, поставив флаг needRefresh)
		 * и в App.js пройзодет вызов метода релогина, если needRefresh == true
		 * */
	}
	if (
		isRejectedWithValue(action) &&
		(action.payload.status === 400 || action.payload.status === 408)
	) {
		action.payload.data.messages.forEach((message) => {
			const { type, clientMessage, clarifications } = message;
			if (clientMessage && (!clarifications || clarifications.length === 0)) {
				api.dispatch(resetAllNotifications());
				api.dispatch(
					pushSystemNotification({
						type,
						clientMessage,
					})
				);
			}
		});
	}

	return next(action);
};

export const getErrorMessageFromError = ({
	fromClarifications = false,
	error,
	code = null,
	status = null,
}) => {
	if (!error || !error.data || !error.data.messages) {
		return null;
	}

	const getErr = () => {
		if (error.data.messages.length > 0) {
			const { clarifications, clientMessage } = error.data.messages[0];

			if (code && error.data.messages[0].internalMessageCode === code) {
				return fromClarifications ? clarifications : { msg: clientMessage };
			} else if (!code) {
				return fromClarifications ? clarifications : { msg: clientMessage };
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	if (status && error.status === status) {
		return getErr();
	} else if (!status) {
		return getErr();
	} else {
		return {};
	}
};

export const getFromClarificationByTarget = (clarifications, targetKey) =>
	clarifications && clarifications.find((obj) => obj.target === targetKey);
