import { Component } from "react";
import Error from "../errors/error";

export default class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error };
	}

	componentDidCatch(error, errorInfo) {
		console.error("Critical error in application:\n", error, errorInfo);
		window.logger.criticalErrorReport({error, errorInfo})
	}

	render() {
		if (this.state.hasError) {
			return (
				<Error
					status={"Critical error"}
					message={
						<div>
							Something went wrong!
							<br />
							<div>Error message: {this.state.error?.message}</div>
							{this.state.error?.fileName && (
								<div>In filename: {this.state.error?.fileName}</div>
							)}
							{this.state.error?.lineNumber && (
								<div>At: {this.state.error?.lineNumber}</div>
							)}
						</div>
					}
				/>
			);
		}

		return this.props.children;
	}
}
