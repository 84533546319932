import React, { useEffect, useMemo, useState } from "react";
import Button from "../../../../../../components/button/button";
import Loader from "../../../../../../components/loader";
import { useTranslate } from "../../../../../../localization/translate";
import { usePollAnswerMutation } from "../../../../services/events.api";
import {
	isAdditional,
	isAdditionalQuestion,
	isNextAvailable,
	isClose,
	buildAdditionalQuestion,
	isChildAnswer,
} from "../utils";
import { Answer } from "../Answer/Answer";
import styles from "./styles.module.css";

export const Question = ({
	pollId,
	counts,
	activeAnswer,
	question,
	setQuestion,
	backBtnConfig,
	isLoadingPoll,
}) => {
	const translate = useTranslate();
	const [active, setActive] = useState({ answer: null, additional: null });

	const [trigger, { isLoading }] = usePollAnswerMutation();

	const nextAvailable = useMemo(
		() =>
			isNextAvailable({
				question,
				answer: active.answer,
				additional: active.additional,
			}),
		[active, question]
	);

	const activeOrAdditional = useMemo(
		() =>
			question?.isAdditional
				? { answer: active.additional }
				: active?.answer
				? active
				: { answer: activeAnswer, additional: active.additional },
		[active, question, activeAnswer]
	);

	const handleChange = (answer) => {
		if (isAdditional(answer) && isClose(answer))
			trigger({
				pollId,
				answerId: answer.id,
				questionId: question.id,
				silent: true,
			});

		if (question?.isAdditional) handleChangeAdditional(answer);
		else
			setActive(
				active?.additional && isChildAnswer(answer, active?.additional)
					? { answer, additional: active.additional }
					: { answer, additional: null }
			);
	};

	const handleChangeAdditional = (additional) =>
		setActive((prev) => ({ ...prev, additional }));

	const handleSendAnswer = () => {
		const { id: questionId, isAdditional: isAdditionalQst } = question;
		const { answer } = active;

		const {
			answer: { id: answerId, value },
			additional,
		} = active;

		if (isAdditionalQuestion(answer) && !isAdditionalQst)
			return setQuestion(buildAdditionalQuestion(question, answer));

		if (isAdditional(answer) && isClose(answer))
			return trigger({
				pollId,
				questionId,
				answerId: additional.id,
				value: additional.value,
			});

		return trigger({
			pollId,
			answerId,
			questionId,
			value,
			silent: !!additional,
		}).then(
			() =>
				additional &&
				trigger({
					pollId,
					questionId,
					answerId: additional.id,
					value: additional.value,
				})
		);
	};

	useEffect(() => {
		setActive({ answer: activeAnswer, additional: null });
	}, [activeAnswer]);

	return (
		<div className={styles.poll_card}>
			<div className={styles.header}>
				<div className={styles.chip}>
					<span>
						{translate("polls.proactiveSurveillance.additionalTitle")}
					</span>
				</div>

				<div className={styles.title}>
					{translate("polls.selected.startPollTabBtn")}
				</div>
			</div>

			{counts && (
				<div className={styles.counters}>
					<span>
						{counts?.current}&nbsp;
						{translate("polls.outOf")}
						&nbsp;{counts?.length}&nbsp;
						{translate("polls.questions")}
					</span>
				</div>
			)}

			<div className={styles.question}>
				<span>{question?.title}</span>
			</div>

			<div className={styles.subtitle}>
				<span>{translate("polls.variants")}</span>
			</div>

			{question?.answers?.length > 0 && (
				<div className={styles.answers}>
					{question.answers.map((answer) => (
						<Answer
							key={answer.id}
							answer={answer}
							active={activeOrAdditional}
							onChange={handleChange}
							onChangeAdditional={handleChangeAdditional}
						/>
					))}
				</div>
			)}

			<div className={styles.actions}>
				{backBtnConfig?.show && (
					<Button
						light
						className={styles.back_btn}
						onClick={backBtnConfig?.onClick}
					>
						{translate("polls.back")}
					</Button>
				)}

				<Button
					className={styles.next_btn}
					disabled={!nextAvailable}
					onClick={handleSendAnswer}
				>
					{(isLoading || isLoadingPoll) && (
						<div className={styles.next_btn_loader}>
							<Loader white width={18} height={18} />
						</div>
					)}

					{!(isLoading || isLoadingPoll) && <>{translate("polls.next")}</>}
				</Button>
			</div>
		</div>
	);
};
