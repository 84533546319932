import ModalInfo from "../../../../components/custom-modal/info";
import css from "./share-access.module.css";
import classNames from "classnames";
import Button from "../../../../components/button/button";
import { useEffect, useState } from "react";
import { useTranslate } from "../../../../localization/translate";
import { useLocalStorage, useMediaQuery } from "beautiful-react-hooks";
import { useDelinkMeMutation } from "../../services/profile.api";
import { useNavigate } from "react-router-dom";

export default function Delink({ delinkVal, setDelinkVal, keyVal: key }) {
	const [isOpen, setIsOpen] = useState();
	const [delinkMeAction, { data: delinkSuccess }] = useDelinkMeMutation();
	const onRequestClose = () => {
		onRequestCancel();
		delinkMeAction({ medCardNumber: delinkVal, me: key === "delinkMe" });
	};
	const onRequestCancel = () => {
		setDelinkVal(null);
		setDelinkMeCard(null);
		setIsOpen(false);
	};
	const [delinkValStorage, setDelinkMeCard] = useLocalStorage(key);
	const translate = useTranslate();
	const isMobile = useMediaQuery("(max-width: 620px)");
	const navigate = useNavigate();

	useEffect(() => {
		if (delinkSuccess) {
			navigate("/profile/sharing");
		}
	}, [delinkSuccess]);

	useEffect(() => {
		if (delinkValStorage && !delinkVal) {
			delinkMeAction({
				medCardNumber: delinkValStorage,
				me: key === "delinkMe",
			});
		}
	}, [delinkValStorage]);

	useEffect(() => {
		if (delinkVal) {
			setDelinkMeCard(delinkVal);
			setIsOpen(true);
		} else {
			setDelinkMeCard(null);
		}
	}, [delinkVal]);

	return (
		<ModalInfo
			iconType={"success"}
			isOpen={isOpen}
			modalStyle={{
				overlay: {
					display: "flex",
					alignItems: "center",
				},
				content: isMobile
					? {}
					: {
							width: "455px !important",
							maxWidth: 455,
							minWidth: 455,
							minHeight: 600,
							borderRadius: 16,
					  },
			}}
			modalClassName={css.modal_error}
			subtitle={
				translate(
					`profile.shareAccess.title${key[0].toUpperCase()}${key.slice(
						(key.length - 1) * -1
					)}`,
					true
				) + "!"
			}
			bodyClass={classNames(css.content, css.failure)}
			onRequestClose={onRequestClose}
			textClass={css.modal_error_text}
			text={translate("profile.shareAccess.textCancel")}
		>
			<div className={css.modal_info_buttons}>
				<Button onClick={onRequestCancel}>{translate("app.cancel")}</Button>
				<Button light onClick={onRequestClose}>
					{translate("profile.shareAccess.backToProfile")}
				</Button>
			</div>
		</ModalInfo>
	);
}
