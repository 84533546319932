import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import useDebouncedCallback from "beautiful-react-hooks/useDebouncedCallback";
import { useTranslate } from "../../../../../../localization/translate";
import { useOnClickOutside } from "../../../../../../utils";
import SearchInput from "../../../../../../components/search-input/search-input";
import Button from "../../../../../../components/button/button";
import Loader from "../../../../../../components/loader";
import { useGetHintsMutation } from "../../../../services/profile.api";
import { useSearchHistory } from "../../hooks/useSearchHistory";
import SearchHistory from "./components/history";
import SearchResults from "./components/results";
import styles from "./style.module.css";
import { Metrics } from "../../../../../../utils/metrics";

const REQUEST_DELAY_MS = 500;

const Search = ({}) => {
	const navigate = useNavigate();
	const translate = useTranslate();
	const [search, { data: searchResults, isLoading }] = useGetHintsMutation();

	const [history, setHistory] = useSearchHistory(
		"program-history-search",
		searchResults
	);

	const [value, setValue] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const dropdown = useRef(null);

	const handleDebounceSearch = useCallback(
		(query) => {
			search(query);

			if (query?.length > 3) {
				Metrics.gtmEvent("search", { query });
				Metrics.gtmEvent("search_program", { query });
			}
		},
		[search]
	);

	const debouncedSearch = useDebouncedCallback(
		handleDebounceSearch,
		[handleDebounceSearch],
		REQUEST_DELAY_MS
	);

	const emptyResults = useMemo(
		() =>
			searchResults &&
			searchResults.programs?.length === 0 &&
			searchResults.services?.length === 0,
		[searchResults]
	);

	const isFilled = useMemo(() => value.length > 0, [value]);

	useEffect(() => {
		debouncedSearch(value);
	}, [value]);

	const handleClose = () => {
		setIsOpen(false);
		setValue("");
	};

	const handleFocus = () => setIsOpen(true);

	useOnClickOutside(dropdown, handleClose);

	return (
		<div className={styles.search}>
			<div
				ref={dropdown}
				className={classNames(styles.dropdown, { [styles.expanded]: isOpen })}
			>
				<div className={styles.header}>
					<SearchInput
						placeholder={translate("profile.programs.searchPlaceholder", true)}
						value={value}
						onChange={setValue}
						onFocus={handleFocus}
						className={styles.input_container}
					/>
					{isOpen && (
						<Button
							small
							light
							className={styles.close_btn}
							onClick={handleClose}
						>
							{translate("app.cancel", true)}
						</Button>
					)}
				</div>

				{isOpen && (
					<div className={styles.content}>
						{!isFilled && history?.length > 0 && (
							<SearchHistory
								history={history}
								onClear={() => setHistory([])}
								onClickItem={(item) =>
									navigate(
										`/profile/programs/catalog/${item.id || item?.program.id}`
									)
								}
							/>
						)}

						{searchResults && !isLoading && (
							<SearchResults
								results={searchResults}
								onClickItem={(item) => {
									navigate(
										`/profile/programs/catalog/${item.id || item?.program.id}`
									);
									Metrics.gtmEvent("select_content", {
										type: "program",
										ItemId: item.id || item?.program.id,
									});
								}}
							/>
						)}

						{emptyResults && (
							<div className={styles.empty}>
								{translate("profile.programs.emptyResults")}
							</div>
						)}

						{isLoading && (
							<div className={styles.loader}>
								<Loader />
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default Search;
