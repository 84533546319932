import { useContext, useEffect, useState } from "react";
import Loader from "../../../../components/loader";
import { useCreateInvoiceMutation } from "../../services/payments.api";
import PaymentWidget from "../../components/PaymentWidget";
import Results from "../../components/Results";
import PaymentContext from "../../contexts/payment-context";
import { PAYMENT_METHODS, PAYMENT_TYPES } from "../../helpers/constants";
import { Metrics } from "../../../../utils/metrics";

const TEST_AMOUNT = 10;

export default function UrlPayment({ type, purposeId, scheduleIdMis = null }) {
	const {
		payment: { invoice },
	} = useContext(PaymentContext);
	const [invoiceNumber, setInvoiceNumber] = useState(null);
	const [createInvoice, { isLoading: isCreatingInvoice, error }] =
		useCreateInvoiceMutation();

	const prepareInvoice = async () => {
		const { data } = await createInvoice({
			paymentPurpose: type,
			paymentMethod: PAYMENT_METHODS.url,
			amount: TEST_AMOUNT,
			purposeId,
			...(scheduleIdMis && { scheduleIdMis }),
		});

		Metrics.gtmEvent("add_payment_info");

		if (data && data.invoiceId) {
			setInvoiceNumber(data.invoiceId);
		}
	};

	useEffect(() => {
		if (!invoice && type === PAYMENT_TYPES.test) {
			prepareInvoice();
		}
	}, []);

	return (
		<>
			{isCreatingInvoice && (
				<Loader
					containerStyle={{ display: "flex", justifyContent: "center" }}
				/>
			)}

			{(invoiceNumber || invoice) && !error && (
				<PaymentWidget
					purposeId={purposeId}
					invoiceNumber={invoiceNumber || invoice}
				/>
			)}

			{error && <Results type="fail" />}
		</>
	);
}
